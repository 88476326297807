import QueueableRequest from './QueueableRequest';
import { CAPTCHA_INITIATE } from '../../config/api';
import { REQUEST_METHOD_POST } from '../../helpers/constants';
import { bindParamsToRoute } from '../../helpers/url';

/**
 *
 * For the EECC Requirements it is necessary to present the user some
 * contract-infos in ordering-process.
 *
 * This is the first of three needed requests for this feature
 *
 * https://confluence.db-n.com/pages/viewpage.action?pageId=48344534
 *
 */

export default class CaptchaInitiateRequest extends QueueableRequest {
  constructor() {
    super(bindParamsToRoute(CAPTCHA_INITIATE, {}), {
      method: REQUEST_METHOD_POST,
      payload: {
        api_key: process.Environment.getVariableAsString('DBN_CAPTCHA_SERVICE_API_KEY'),
      },
    });
  }
}
