import PageModule from './PageModule';
import component from '../../containers/content/ContentAccordion';

class ContentAccordionModule extends PageModule {
  constructor(moduleData) {
    super(component, moduleData);
  }
}

export default ContentAccordionModule;
