import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ValueSlider from 'rc-slider/lib/Slider';
import suitcss from '../../../helpers/suitcss';
import { hintShape, inputShape, metaShape } from '../../../propTypes/field';
import FieldWrapper from './FieldWrapper';
import Copy from '../text/TextCopy';

const componentName = 'FieldValueSlider';
class FieldValueSlider extends PureComponent {

  constructor(props, context) {
    super(props, context);
    this.onSliderChange = this.onSliderChange.bind(this);
  }

  onSliderChange(value) {
    const { input, options } = this.props;
    const valueIndex = Math.round((value / 100) * (options.length - 1));
    input.onChange(options[valueIndex].value);
  }

  getOptionValueIndex(value) {
    const { options } = this.props;
    const index = options.findIndex(el => el.value === value);
    return index >= 0 ? index : 0;
  }

  renderSlider() {
    const { input, options, withMarks, labelMin, labelMax } = this.props;

    if (options.length < 2) {
      return null;
    }

    const stepSize = 100 / (options.length - 1);
    const defaultValueIndex = this.getOptionValueIndex(input.defaultValue);
    const valueIndex = this.getOptionValueIndex(input.value) || defaultValueIndex;
    const valueLabel = !withMarks && options[valueIndex].label;
    const marks = withMarks && options.reduce((results, item, index) => ({
      ...results,
      [Math.round(stepSize * index)]: item.label,
    }), {});
    const sliderConfig = {
      onChange: this.onSliderChange,
      defaultValue: stepSize * defaultValueIndex,
      value: stepSize * valueIndex,
      step: !withMarks ? stepSize : undefined,
      steps: withMarks ? marks : undefined,
      marks: withMarks ? marks : undefined,
    };

    return (
      <div className={suitcss({ componentName, descendantName: 'input' })} >
        <ValueSlider {...sliderConfig} />
        <div className={suitcss({ componentName, descendantName: 'label' })} >
          {valueLabel && (<Copy embedded>{valueLabel}</Copy>)}
          {labelMin && (<Copy size="secondary" utilities={['colorGray100']} embedded>{labelMin}</Copy>)}
          {labelMax && (<Copy size="secondary" utilities={['colorGray100']} embedded>{labelMax}</Copy>)}
        </div>
      </div>
    );
  }

  render() {
    const { label } = this.props;
    return (
      <FieldWrapper componentName={componentName} {...this.props} >
        {wrapperProvider => (
          <div className={suitcss({ componentName, descendantName: 'inner' })} >
            {label && (
              <div className={suitcss({ componentName, descendantName: 'label' })} >
                {label}
                {!wrapperProvider.isRequired && wrapperProvider.getIsOptionalElement()}
              </div>
            )}
            {this.renderSlider()}
          </div>
        )}
      </FieldWrapper>
    );
  }
}

FieldValueSlider.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
  })),
  labelMin: PropTypes.string,
  labelMax: PropTypes.string,
  withMarks: PropTypes.bool,
  type: PropTypes.string,
  label: PropTypes.string,
  hint: hintShape,
  input: inputShape,
  meta: metaShape,
  raw: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  utitilies: PropTypes.array,
  theme: PropTypes.oneOf(['default', 'compact']),
};

FieldValueSlider.defaultProps = {
  meta: {},
  input: { value: '' },
  options: [],
};

export default FieldValueSlider;
