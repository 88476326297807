import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import GlobalSection from '../../components/basics/global/GlobalSection';

import { bindQueryParams } from '../../helpers/url';
import { getContextAction } from '../../helpers/context';
import { camelCase } from '../../helpers/str';
import matchMediaConnector from '../service/ServiceMatchMedia';
import Link from '../../model/Link';
import { ENTITY_TYPE_TARIFFOPTION, UI_COLLECTION_NBA } from '../../helpers/constants';
import Headline from '../../components/basics/text/TextHeadline';
import TeaserOffer from '../../components/compositions/teaser/TeaserOffer';
import TeaserNba from '../teaser/TeaserNba';
import suitcss from '../../helpers/suitcss';
import * as registryActions from '../../actions/request/registry';

const ITEM_TYPE_INFO = 'info';
const ITEM_TYPE_DETAILS = 'details';
const ITEM_TYPE_CONTEXT = 'context';
const ITEM_TYPE_PRODUCT = 'product';

class MyOfferTiles extends PureComponent {
  constructor(props) {
    super(props);
    this.isMediaL = this.props.isMediaL;
  }

  componentDidMount() {
    this.getNbaOffers();
  }

  componentDidUpdate(prevProps) {
    const {
      isTariffRenewable,
      nbaOffer,
      fetchHardwareEntity,
    } = this.props;
    if (prevProps.isTariffRenewable !== isTariffRenewable) {
      this.getNbaOffers();
    }
    if (nbaOffer && nbaOffer.orderableArticle && nbaOffer.orderableArticle.eid) {
      fetchHardwareEntity(nbaOffer.orderableArticle.eid);
    }
  }

  getNbaOffers() {
    const {
      contact,
      fetchNbaOffer,
      fetchUiElements,
      isTariffRenewable,
    } = this.props;
    if (!contact || !isTariffRenewable) return;
    fetchUiElements([UI_COLLECTION_NBA]);
    fetchNbaOffer();
  }

  /**
   * Generates the link of the teaser from the given item type.
   * @param item The item.
   * @return {Link} The generated link.
   */
  getGeneratedLinkByItem(item) {
    const { sitemap, dispatch } = this.props;
    switch (item.type) {
      case ITEM_TYPE_INFO:
        return new Link({ url: item.url, target: '_blank' });
      case ITEM_TYPE_DETAILS:
        return new Link({ url: item.promo.details });
      case ITEM_TYPE_CONTEXT:
        return new Link({
          onClick: () => {
            dispatch(getContextAction(item.promo.context));
          },
        });
      case ITEM_TYPE_PRODUCT: {
        const { targets } = item.promo;
        const target = targets[Object.keys(targets).find((key) => targets[key].length)][0];
        const targetType = camelCase(target.etype);
        return new Link({
          url: bindQueryParams(
            targetType === ENTITY_TYPE_TARIFFOPTION
              ? sitemap.MyTariffOptionDetailsRoute.url
              : sitemap.MyTariffDetailsRoute.url,
            { iid: target.iid },
          ),
        });
      }
      default:
        return new Link({ url: item.url });
    }
  }

  renderTile(item, index, hideForLarge = false) {
    const { ui } = this.props;
    const tile = {
      headline: item.tilesTxtHl,
      copy: item.tilesTxtSl,
      volume: item.tilesVolume,
      price: item.tilesPriceValue ? { unit: item.tilesPriceValue } : null,
      pricePostfix:
        item.tilesPriceType === 'monthly'
          ? `${ui.guiSymbolEuro} ${ui.guiWordMonthlyAbbr}`
          : `${ui.guiSymbolEuro} ${ui.guiWordSingularAbbr}`,
      pricePrefix: item.tilesPricePrefix,
      image: this.isMediaL || hideForLarge ? item.tilesImgLg : item.tilesImgSm,
      link: this.getGeneratedLinkByItem(item),
    };
    return (
      <TeaserOffer
        className="MyOfferTiles-tile"
        utilities={[
          hideForLarge && 'mlHidden',
          item.colorScheme === 'light' ? 'bgGradientSecondary' : 'bgGradientPrimary',
        ]}
        modifiers={[hideForLarge && 'large']}
        key={index}
        item={tile}
        layout="half"
      />
    );
  }

  renderNba(hideForLarge = false) {
    return (
      <TeaserNba
        className="MyOfferTiles-tileNba"
        utilities={[hideForLarge && 'mlHidden']}
        layout="half"
      />
    );
  }

  render() {
    const { banners, ui, id, nbaOffer } = this.props;
    const filteredBanners =
      banners && banners.filter((b) => b.sections && b.sections.includes('tile'));
    const orderedBanners =
      filteredBanners && filteredBanners.sort((a, b) => b.priority - a.priority);
    const showOfferTiles = orderedBanners.some((o) => o.tilesTxtHl);
    if ((!filteredBanners.length || !showOfferTiles) && !Object.keys(nbaOffer).length) {
      return null;
    }
    return (
      <GlobalSection className="MyOfferTiles" layout="contained" id={id}>
        <Headline
          className={suitcss({ componentName: 'MyOfferTiles', descendantName: 'headline' })}
          size="l"
          embedded
        >
          {ui.guiWordOffers}
        </Headline>
        {!!Object.keys(nbaOffer).length && this.renderNba(true)}
        {!!orderedBanners.length &&
          orderedBanners[0].tilesTxtHl &&
          this.renderTile(orderedBanners[0], 0, true)}
        <div className={suitcss({ componentName: 'MyOfferTiles', descendantName: 'grid' })}>
          {!!Object.keys(nbaOffer).length && this.renderNba()}
          {!!orderedBanners.length &&
            orderedBanners.map((item, index) => item.tilesTxtHl && this.renderTile(item, index))}
        </div>
      </GlobalSection>
    );
  }
}

MyOfferTiles.propTypes = {
  banners: PropTypes.array,
  nbaOffer: PropTypes.object,
  sitemap: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  ui: PropTypes.object,
  isMediaL: PropTypes.bool,
  id: PropTypes.string,
  fetchNbaOffer: PropTypes.func,
  fetchUiElements: PropTypes.func.isRequired,
  contact: PropTypes.bool,
  isTariffRenewable: PropTypes.bool,
  fetchHardwareEntity: PropTypes.func,
};

MyOfferTiles.defaultProps = {
  banners: [],
  nbaOffer: {},
};

const mapStateToProps = ({ user, site, ui }) => ({
  banners: user.banners,
  nbaOffer: user.nbaOffer,
  sitemap: site.sitemap,
  ui,
  contact: user.gdprPermissions['li-om'],
});

const mapDispatchToProps = {
  fetchNbaOffer: registryActions.fetchNbaOffer,
  fetchUiElements: registryActions.fetchUiElements,
  fetchHardwareEntity: (hardwareId) => registryActions.fetchEntityById(hardwareId),
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  matchMediaConnector(['isMediaL']),
)(MyOfferTiles);
