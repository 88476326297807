import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import RouterLink from 'react-router/lib/Link';

import { getPaymentFeeDuration } from '../../../helpers/entity';
import connectUI from '../../basics/ui/UIConnector';
import suitcss from '../../../helpers/suitcss';
import { shape as tariffShape } from '../../../propTypes/tariff';
import Headline from '../../basics/text/TextHeadline';
import Copy from '../../basics/text/TextCopy';
import Link from '../../basics/text/TextLink';
import Unit from '../../basics/text/TextUnit';
import Callout from '../../../containers/callout/Callout';
import FootnoteReference from '../../../containers/footnote/FootnoteReference';

class TariffItemFull extends PureComponent {

  constructor(...args) {
    super(...args);
    this.state = {};
  }

  renderMonthlyPrice(cssProps) {
    const { tariff, inverted, ui } = this.props;
    return (
      <div
        className={suitcss({
          descendantName: 'priceMonthly',
          className: cssProps.classNames,
        }, this)}
      >
        <div
          className={suitcss({
            descendantName: 'unit',
          }, this)}
        >
          <Unit
            postfix={[
              getPaymentFeeDuration(tariff, ui, true),
              <FootnoteReference id={tariff.eid} />,
            ]}
            price={tariff.paymentFee}
            prefixPrice={tariff.paymentFeeStrike}
            inverted={inverted}
            size="s"
          />
        </div>
      </div>
    );
  }

  renderHeader() {
    const {
      tariff,
      moduleId,
      inverted,
      withEqualizedHeader,
      linkPrimary,
      featureSet,
    } = this.props;

    const {
      type,
      name,
    } = tariff;
    const isFeatureSetInfo = featureSet === 'info';
    const equalizerGroup = `${moduleId}-tariffItemFullHeader`;
    const equlizerData = withEqualizedHeader
      ? { 'data-eq-group-l': equalizerGroup, 'data-eq-group-m': equalizerGroup }
      : {};
    const Element = !isFeatureSetInfo ? RouterLink : 'div';
    const linkProps = isFeatureSetInfo
      ? {}
      : {
        to: linkPrimary.to,
        onClick: linkPrimary.onClick,
      };

    return (
      <Element
        className={suitcss({
          descendantName: 'header',
        }, this)}
        {...equlizerData}
        {...linkProps}
      >
        <div
          className={suitcss({
            descendantName: 'title',
          }, this)}
        >
          <div
            className={suitcss({
              descendantName: 'type',
            }, this)}
          >
            <Headline
              size="xs"
              embedded
              raw
              utilities={['weightBold', 'lowercase']}
            >
              {type}
            </Headline>
          </div>
          <div
            className={suitcss({
              descendantName: 'name',
            }, this)}
          >
            <Headline
              size="m"
              embedded
              raw
              utilities={[inverted && 'colorInverted', 'weightBold', 'colorPrimary']}
            >
              {name}
            </Headline>
          </div>
          <Callout
            theme="content"
            targets={[tariff.eid]}
            inverted={inverted}
          />
        </div>
        {this.renderMonthlyPrice({ classNames: ['u-hidden', 'u-sBlock'] })}
      </Element>
    );
  }

  renderContent() {
    const { tariff, inverted, featureSet, linkSecondary, isMediaML } = this.props;
    const { copy } = tariff;
    const isFeatureSetInfo = featureSet === 'info';
    return (
      <div
        className={suitcss({
          descendantName: 'text',
        }, this)}
      >
        <div
          className={suitcss({
            descendantName: 'copy',
            className: [inverted && 'u-colorInverted'],
          }, this)}
        >
          <Copy
            size="secondary"
            utilities={[inverted && 'colorInverted']}
            embedded
            raw
          >
            {copy}
          </Copy>
          {!isFeatureSetInfo && isMediaML && (
            <div
              className={suitcss({
                descendantName: 'link',
                utilities: ['mCol12', 'col12', 'u-mlMarginBottomM'],
              }, this)}
            >
              <Link
                arrow
                to={linkSecondary.to}
              >
                {linkSecondary.label}
              </Link>
            </div>
          )}
        </div>
      </div>
    );
  }

  renderSubContent() {
    const {
      tariff,
      isSelected,
      linkPrimary,
      featureSet,
    } = this.props;
    const element = linkPrimary.onClick && !linkPrimary.to ? 'button' : null;
    const isSelectButton = typeof isSelected === 'boolean';
    const isFeatureSetInfo = featureSet === 'info';
    const docs = isFeatureSetInfo && tariff.documents ? tariff.documents : null;
    return (
      <div
        className={suitcss({
          descendantName: 'subcontent',
        }, this)}
      >
        {!isFeatureSetInfo && (
          <div
            className={suitcss({
              descendantName: 'button',
              utilities: ['sCol12'],
            }, this)}
          >
            <Link
              asButton
              buttonFilled={!isSelectButton || isSelected}
              element={element}
              to={linkPrimary.to}
              onClick={linkPrimary.onClick}
              utilities={['sCol12', 'smMarginTopXS']}
            >
              {linkPrimary.label}
            </Link>
          </div>
        )}
        {isFeatureSetInfo && docs && (
          <Copy
            element="div"
            utilities={['textSizeSmall', 'alignLeft']}
            embedded
          >
            {docs.map((doc, index) => (
              <p className={suitcss({ utilities: [index > 0 && 'marginTopXXS'] })} key={index}>
                <a href={doc.file.url} target="_blank" rel="noopener noreferrer" >
                  {doc.title}
                </a>
              </p>
            ))}
          </Copy>
        )}
      </div>
    );
  }

  renderProductInformation() {
    const { productInformationSheetLink, inverted } = this.props;

    return (
      <Link
        className={suitcss({
          descendantName: 'productInformation',
          modifiers: [inverted && 'colorInverted'],
        }, this)}
        href={productInformationSheetLink.file.url}
        withoutArrow
        element="a"
        target="_blank"
      >
        {productInformationSheetLink.copy}
      </Link>
    );
  }

  render() {
    const {
      withEqualizedContent,
      inverted,
      tariff,
      moduleId,
      linkSecondary,
      featureSet,
      isMediaS,
    } = this.props;
    const { iid } = tariff;
    const isFeatureSetInfo = featureSet === 'info';
    const equalizerGroup = `${moduleId}-tariffItemFullContent`;
    const equlizerData = withEqualizedContent && moduleId
      ? { 'data-eq-group-l': equalizerGroup, 'data-eq-group-m': equalizerGroup }
      : {};
    return (
      <section
        className={suitcss({}, this)}
        data-tracking="tariff"
        data-tariff-id={iid}
      >
        {this.renderHeader()}
        <div
          className={suitcss({
            descendantName: 'content',
          }, this)}
          {...equlizerData}
        >
          {this.renderContent()}
          <Callout
            theme="badge"
            targets={[tariff.eid]}
            inverted={inverted}
          />
          {this.renderMonthlyPrice({ classNames: ['u-sHidden', 'u-mlBlock'] })}
          {!isFeatureSetInfo && isMediaS && (
            <div
              className={suitcss({
                descendantName: 'link',
                utilities: ['mCol12', 'smMarginTopXS'],
              }, this)}
            >
              <Link
                arrow
                to={linkSecondary.to}
              >
                {linkSecondary.label}
              </Link>
            </div>
          )}
        </div>
        {this.renderSubContent()}
        {this.renderProductInformation()}
      </section>
    );
  }
}

TariffItemFull.propTypes = {
  tariff: tariffShape.isRequired,
  isSelected: PropTypes.bool,
  inverted: PropTypes.bool,
  linkPrimary: PropTypes.shape({
    label: PropTypes.string,
    to: PropTypes.string,
    onClick: PropTypes.func,
  }).isRequired,
  linkSecondary: PropTypes.shape({
    label: PropTypes.string,
    to: PropTypes.string,
  }).isRequired,
  urlSelect: PropTypes.string,
  moduleId: PropTypes.string,
  featureSet: PropTypes.string,
  withEqualizedHeader: PropTypes.bool,
  withEqualizedContent: PropTypes.bool,
  isMediaS: PropTypes.bool,
  isMediaML: PropTypes.bool,
  ui: PropTypes.shape({}),
  productInformationSheetLink: PropTypes.object,
};

TariffItemFull.defaultProps = {
  inverted: false,
  featureSet: 'full',
  withEqualizedHeader: true,
  withEqualizedContent: true,
};

export default connectUI()(TariffItemFull);
