/* global window */
import { connect } from 'react-redux';
import { compose } from 'redux';

import initForm from '../../form/FormInitializer';
import MyBelatedMnpFormMsisdn from '../../../components/compositions/account/belatedMnp/MyBelatedMnpFormMsisdn';
import MnpStatusRequest from '../../../model/requests/MnpStatusRequest';
import { createFieldMapBelatedMnpStepMsisdn } from '../../form/steps/formStepMnp';
import { send } from '../../../actions/request/send';
import { trackMyState } from '../../../actions/tracking/event';
import {
  MYTRACK_SERVICE_BMNP_STATUS,
  TRACK_REPLACE_BMNP_STATUS,
  FORM_ID_BMNP_MSISDN,
  FORM_ID_BMNP_CONTRACT,
  MYTRACK_SERVICE_BMNP_STEP1,
  MARKET_POSTPAID,
} from '../../../helpers/constants';
import {
  showSimpleDialog,
  showSimpleDialogWithCancelButtonAndAction,
} from '../../../actions/dialog/misc';

const MNP_STATUS_CREATED = 'created';
const MNP_STATUS_NEGOTIATION_STARTED = 'negotiation_started';
const MNP_STATUS_NEGOTIATION_CONCLUDED = 'negotiation_concluded';
const MNP_STATUS_COMPLETED = 'completed';
const MNP_STATUS_SENT = 'sent';
const MNP_STATUS_CANCELED = 'canceled';

/**
 * Every mnp status state triggers a different dialog,
 * this function shows the corresponding one.
 *
 * @todo dialogs should be outsourced and be build as actions!
 *
 * @param {string} mnpStatusState
 * @param {object} ui
 * @param {function} dispatch
 */
const handleMnpStatusRequestState = (mnpStatusState, ui, dispatch) => {
  // shortcut
  const showDialog = (title, copy, label) =>
    dispatch(showSimpleDialog(title, copy, label));

  const trackMsg = MYTRACK_SERVICE_BMNP_STATUS.replace(TRACK_REPLACE_BMNP_STATUS, mnpStatusState);
  dispatch(trackMyState(trackMsg));

  switch (mnpStatusState) {
    case MNP_STATUS_CREATED:
      showDialog(
        ui.mnpStatusConfirmedTitle,
        ui.guiOrderPending,
        ui.guiWordAllright,
      );
      break;
    case MNP_STATUS_NEGOTIATION_STARTED:
      showDialog(
        ui.mnpStatusPendingTitle,
        ui.guiOrderPending,
        ui.guiWordAllright,
      );
      break;
    case MNP_STATUS_NEGOTIATION_CONCLUDED:
    case MNP_STATUS_SENT:
      showDialog(
        ui.mnpStatusPendingTitle,
        ui.mnpStatusSuccessCopy,
        ui.guiWordAllright,
      );
      break;
    case MNP_STATUS_COMPLETED:
      showDialog(
        ui.mnpStatusFinishedTitle,
        ui.mnpStatusFinishedCopy,
        ui.guiWordAllright,
      );
      break;
    case MNP_STATUS_CANCELED: {
      // @todo this is very ugly, but the hotline number is
      // only available as html text currently (a href).
      const reg = new RegExp('tel:([0-9]+)', 'gm');
      const tel = reg.exec(ui.guiHotlineNumber)[1];
      dispatch(showSimpleDialogWithCancelButtonAndAction(
        ui.mnpStatusCancelledTitle,
        ui.mnpStatusCancelledCopy,
        ui.guiCallNow,
        ui.guiWordCancel,
        // trigger a tel: protocol link via js
        () => { window.location = `tel:${tel}`; },
      ));
      break;
    }
    default:
      break;
  }
};

const mapStateToProps = ({ user }, { ui, fieldMap }) => {

  const initialValues = {
    [fieldMap.mnp.name]: true,
    [fieldMap.mnpProviderKey.name]: fieldMap.mnpProviderKey.options[0].value,
    [fieldMap.mnpMsisdn.name]: '+49',
  };

  const isPuc = user.market === MARKET_POSTPAID;
  return {
    mnpHeadline: isPuc ? ui.mnpMydataMenuitemPuc : ui.mnpPortedNumberTitle,
    mnpCopy: isPuc ? ui.txtMnpFormCopyPuc : ui.txtMnpFormCopy,
    initialValues,
  };
};

const component = compose(
  connect(mapStateToProps),
  initForm(),
)(MyBelatedMnpFormMsisdn);

/**
 * If the user has more than one reset options, we will display a next step in which
 * he she is able to choose one of the possible reset options.
 *
 * Otherwise, we immediately tell the server to create a reset token.
 */
const makeSubmit = (stepConfig, props) => async (values, dispatch) => {
  const { fieldMap, stepProps, ui } = props;

  const portingMsisdn = values[fieldMap.mnpMsisdn.name]
    .replace('+', '')
    .replace(' ', '')
    .replace('-', '');

  let response = null;
  try {
    response = await dispatch(send(new MnpStatusRequest(stepProps.msisdn, portingMsisdn)));
    // a successfull response is actually an error for this form,
    // because it means an mnp for this number is already
    // in progress or has a state, we have to stay on this page and show an error dialog,
    // the response state is handled below.
  } catch (err) {
    // tricky, 404 is actually a success (the only one) for this form (no mnp found)
    // and we can move on to the next step
    if (err.fullResponse.status === 404) {
      return FORM_ID_BMNP_CONTRACT;
    }
    // every other case is handled by the global error handler and we
    // stay on this step.
    return FORM_ID_BMNP_MSISDN;
  }

  const mnpStatusState = response.body.data.state;
  handleMnpStatusRequestState(mnpStatusState, ui, dispatch);

  return FORM_ID_BMNP_MSISDN;
};

export const mapStateToFormConfig = (state, props) => ({
  id: FORM_ID_BMNP_MSISDN,
  label: props.ui.cfoNavMnp,
  component,
  fieldMap: createFieldMapBelatedMnpStepMsisdn(state, props),
  makeSubmit,
  onStepEnter: () => (dispatch) => dispatch(trackMyState(MYTRACK_SERVICE_BMNP_STEP1)),
});

export default mapStateToFormConfig;
