import { compose } from 'redux';
import {
  MYTRACK_SERVICE_SIMREP_STEP1,
} from '../../../helpers/constants';
import { trackMyState } from '../../../actions/tracking/event';
import SimReplacementFormActivation
  from '../../../components/compositions/account/simReplacement/SimReplacementFormActivation';
import initForm from '../../form/FormInitializer';
import FormConfigProps from '../../../model/form/FormConfigProps';

const component = compose(
  initForm(),
)(SimReplacementFormActivation);

export const id = 'simReplActivation';

/**
 * @return {FormConfig}
 */
export const mapStateToFormConfig = (state) => ({
  id,
  component,
  onStepEnter: () => (dispatch) => dispatch(trackMyState(MYTRACK_SERVICE_SIMREP_STEP1)),
  formConfigProps: new FormConfigProps({
    labelSubmit: state.ui.mySimrepActivationSubmit,
    withRequiredHint: false,
  }),
});
