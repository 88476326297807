import { ACTION_PREFIX } from '../../helpers/constants';

export const ADD_SEARCH_RESULTS = `${ACTION_PREFIX}/ADD_SEARCH_RESULTS`;
export const REMOVE_SEARCH_RESULTS = `${ACTION_PREFIX}/REMOVE_SEARCH_RESULTS`;

export const SET_LAST_USER_SEARCH_LOCATION = `${ACTION_PREFIX}/SET_LAST_USER_SEARCH_LOCATION`;

export const SET_ACTIVE_FACET = `${ACTION_PREFIX}/SET_ACTIVE_FACET`;

export function addSearchResults(searchQuery, results, searchFacet) {
  return {
    type: ADD_SEARCH_RESULTS,
    payload: {
      ...results,
      searchQuery,
      searchFacet,
    },
  };
}

export function removeSearchResults(results) {
  return {
    type: REMOVE_SEARCH_RESULTS,
    payload: results,
  };
}

export function setLastUserSearchLocation(location) {
  return {
    type: SET_LAST_USER_SEARCH_LOCATION,
    payload: { lastSearchLocation: location },
  };
}

export function setActiveFacet(facet) {
  return {
    type: SET_ACTIVE_FACET,
    payload: { searchFacet: facet },
  };
}
