import React from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';

import Headline from '../../basics/text/TextHeadline';
import Copy from '../../basics/text/TextCopy';

const componentName = 'TariffDetailsFeature';
function TariffDetailsFeature(props) {
  const { headline, subline, copy } = props;
  const withoutHeader = !headline && !subline;
  return (
    <div className={suitcss({ componentName })} >
      {headline &&
        <div className={suitcss({ componentName, descendantName: 'headline' })} >
          <Headline size="m" embedded raw utilities={['colorPrimary']}>
            {headline}
          </Headline>
        </div>
      }
      {subline &&
        <div className={suitcss({ componentName, descendantName: 'subline' })} >
          <Headline size="xs" embedded raw>
            {subline}
          </Headline>
        </div>
      }
      {copy &&
        <div
          className={suitcss({
            componentName,
            descendantName: 'copy',
            utilities: !withoutHeader && ['hidden lBlock'],
          })}
        >
          <Copy embedded raw>
            {copy}
          </Copy>
        </div>
      }
    </div>
  );
}

TariffDetailsFeature.propTypes = {
  headline: PropTypes.string,
  subline: PropTypes.string,
  copy: PropTypes.string,
};

TariffDetailsFeature.defaultProps = {};

export default TariffDetailsFeature;
