import oneLine from 'common-tags/lib/oneLine';

const local = 'de-DE';

const integerFormatter = () => new Intl.NumberFormat(local, {
  style: 'decimal',
  maximumFractionDigits: 0,
});

const fractionFormatter = () => new Intl.NumberFormat(local, {
  style: 'decimal',
  maximumFractionDigits: 0,
  minimumIntegerDigits: 2,
  maximumIntegerDigits: 2,
});

export const decimalFormatter = () => new Intl.NumberFormat(local, {
  style: 'decimal',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const unitToFloat = unit => unit / 100;

export const toDecimal = money => decimalFormatter().format(
  unitToFloat(money.unit),
);

export const toFullCurrency = ({ currency, unit }) => {
  const priceFormatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency,
  });

  return priceFormatter.format(unitToFloat(unit));
};

export const toCurrencySymbol = (money) => {
  switch (money.currency) {
    case 'EUR':
      return '€';
    default:
      throw new TypeError(`\`${money.currency}\` is not supported.`);
  }
};

export const toIntegerDigits = ({ unit }) =>
  String((unit >= 0 ? 1 : -1) * integerFormatter().format(Math.floor(unitToFloat(Math.abs(unit)))));

export const toFractionDigits = ({ unit }) => fractionFormatter().format(
  unit % 100,
);

// @todo use NumberFormat instead of static definition
export const toFractionSeperator = () => ',';

export const toNegative = (price) => ({
  ...price,
  unit: (price.unit * -1),
});

export const toAbsolute = (price) => ({
  ...price,
  unit: Math.abs(price.unit),
});

export const toPriceObject = (value) => ({
  unit: value,
  currency: 'EUR',
});

/**
 * expects two or more price objects as arguments and sum all up
 * @return {object} New price object with sum as unit
 */
export const sum = (...prices) => {
  // throw error if different currencies are provided
  prices.forEach((price, index, priceList) => {
    const nextPrice = priceList[index + 1];
    if (nextPrice && price.currency !== nextPrice.currency) {
      throw new Error(oneLine`
        Cannot sum different currencies.
        Tried to sum ´${price.currency}´ with ´${nextPrice.currency}´.
      `);
    }
  });

  const sumUnit = prices.reduce(
    (total, { unit }) => (total + unit),
    0,
  );

  return {
    unit: sumUnit,
    currency: prices[0].currency,
  };
};

export const calcNet = (gross, taxRate = 0.19) => gross / (1 + taxRate);
export const calcGross = (net, tax) => net + tax;
export const calcTax = (net, taxRate = 0.19) => net * taxRate;
export const calcTaxByGross = (gross, taxRate = 0.19) => calcTax(calcNet(gross, taxRate), taxRate);
