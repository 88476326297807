import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import suitcss from '../../../helpers/suitcss';
import connectSiteProvider from '../service/ServiceSiteConnector';
import Link from './TextLink';
import Headline from './TextHeadline';
import Copy from './TextCopy';

class TextInfo extends PureComponent {
  constructor(...args) {
    super(...args);
    this.state = { isOpen: false };
    this.onClick = this.onClick.bind(this);
  }
  onClick(ev) {
    const { dialog, asAccordion, siteProvider } = this.props;
    ev.preventDefault();

    if (asAccordion) {
      this.toggleContent();
      return;
    }

    siteProvider.showDialog(dialog);
  }

  toggleContent() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  renderContent() {
    const { headline, copy, size, isInverted } = this.props;
    return (
      <div className={suitcss({ descendantName: 'content' }, this)} >
        {headline && (<Headline size="xs" inverted={isInverted} embedded>{headline}</Headline>)}
        {copy &&
          <Copy
            size={size || 'secondary'}
            utilities={[isInverted && 'colorGray100']}
            embedded
            raw
          >
            {copy}
          </Copy>
        }
      </div>
    );
  }

  render() {
    const { label, size, isInverted, asAccordion, withContentBox, ariaLabel } = this.props;
    const Element = asAccordion ? 'div' : 'span';
    return (
      <Element
        className={suitcss({
          modifiers: [withContentBox && 'withContentBox', isInverted && 'inverted'],
          states: [this.state.isOpen && 'open'],
        }, this)}
      >
        <Link
          ariaLabel={ariaLabel}
          className={suitcss({ descendantName: 'link' }, this)}
          element="button"
          onClick={this.onClick}
          withoutArrow
          withoutStyle
          icon="/icons/content-info.svg"
          size={size}
        >
          {label && (<span>{label}</span>)}
        </Link>
        {asAccordion && this.renderContent()}
      </Element>
    );
  }
}

TextInfo.propTypes = {
  label: PropTypes.string,
  headline: PropTypes.string,
  copy: PropTypes.string,
  dialog: PropTypes.object,
  siteProvider: PropTypes.object.isRequired,
  size: PropTypes.oneOf(['secondary', 'small']),
  asAccordion: PropTypes.bool,
  withContentBox: PropTypes.bool,
  isInverted: PropTypes.bool,
  ariaLabel: PropTypes.string,
};

TextInfo.defaultProps = {
  type: 'dialog',
};

const mapStateToProps = (state, ownProps) => {
  const { headline, copy, siteProvider } = ownProps;
  const ui = siteProvider.getGlobalUi();
  const dialog = {
    headline,
    copy,
    actions: [{
      withoutArrow: true,
      label: ui.guiWordAllright,
    }],
  };

  return { dialog: ownProps.dialog || dialog };
};

export default compose(
  connectSiteProvider(),
  connect(mapStateToProps),
)(TextInfo);
