import { STOP_SUBMIT, START_SUBMIT } from 'redux-form/lib/actionTypes';
import { LOCATION_CHANGE_REQUESTED } from '../actions/page/location';
import {
  REQUEST_RECEIVED_ERROR,
  REQUEST_RECEIVED_RESPONSE,
  REQUEST_IN_PROGRESS,
} from '../actions/request/basic';
import { REQUEST_ID_PREFIX } from '../model/requests/QueueableRequest';

const removeFromQueue = (state = [], identifier) =>
  state.filter(id => id !== identifier);

// @todo loadingQueue contains a list of identifiers, its not verifierd that all identifiers
//       are unique values and not conflicted with each other. We should rethink if it would
//       be better to group them or create unique identifiers based of action types.
const updateLoadingQueue = (state = [], action = {}) => {
  switch (action.type) {
    case REQUEST_RECEIVED_ERROR:
    case REQUEST_RECEIVED_RESPONSE:
      return removeFromQueue(state, action.meta.identifier);

    case REQUEST_IN_PROGRESS:
      return [...state, action.meta.identifier];
    case LOCATION_CHANGE_REQUESTED:
      return []; // we force a reset of the loading bar on every location change!

    default:
      return state;
  }
};

const updateBlockingLoadingQueue = (state = [], action = {}) => {
  switch (action.type) {
    case REQUEST_RECEIVED_ERROR:
    case REQUEST_RECEIVED_RESPONSE: {
      const request = action.meta.request || action.payload.request;
      return request.isBlocking ? removeFromQueue(state, request.id) : state;
    }

    case REQUEST_IN_PROGRESS: {
      const request = action.meta.request || action.payload.request;
      return request.isBlocking ? [...state, request.id] : state;
    }

    case STOP_SUBMIT:
      return removeFromQueue(state, `form_${action.meta.form}`);

    case START_SUBMIT:
      return [...state, `form_${action.meta.form}`];

    default:
      return state;
  }
};

export const createDefaultState = () => ({
  isLoading: false,
  isIdle: true,
  isBlocking: false,
  isBlockingRequestInProgress: false,
  loadingQueue: [],
  blockingLoadingQueue: [],
  progressValue: 0,
  progressMax: 0,
});

/**
 * Lifecycle reducer is responsible for the state the application is in.
 * This state e.g. can define that the application is fetching data, compute intensive
 * operations or is in idle mode.
 */
function lifecycle(state = createDefaultState(), action = {}) {
  const nextBlockingQueue = updateBlockingLoadingQueue(state.blockingLoadingQueue, action);
  if (nextBlockingQueue !== state.blockingLoadingQueue) {
    return {
      ...state,
      isBlockingRequestInProgress: nextBlockingQueue.some(id => id.startsWith(REQUEST_ID_PREFIX)),
      isBlocking: !!nextBlockingQueue.length,
      blockingLoadingQueue: nextBlockingQueue,
    };
  }

  const nextLoadingQueueState = updateLoadingQueue(state.loadingQueue, action);
  if (nextLoadingQueueState !== state.loadingQueue) {
    const isIdle = nextLoadingQueueState.length === 0;
    const isLoading = nextLoadingQueueState.length > 0;
    // reset max if in idle state, otherwise update to have the max value since the last time
    // the idle state was reached
    const progressMax = isIdle ? 0 : Math.max(state.progressMax, nextLoadingQueueState.length);
    // the progress is defined by the max progress minor the current items in the queue
    const progressValue = progressMax - nextLoadingQueueState.length;
    return {
      ...state,
      loadingQueue: nextLoadingQueueState,
      isIdle,
      isLoading,
      progressValue,
      progressMax,
    };
  }

  return state;
}

export default lifecycle;
