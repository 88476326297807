import PageModule from './PageModule';
import component from '../../containers/campaignInfo/CampaignInfo';

/**
 * The CampaignInfo module visualizes the state of a campaignToken
 * @see https://confluence.db-n.com/x/HPTc
 */
class CampaignInfoModule extends PageModule {
  constructor(moduleData) {
    super(component, moduleData);
  }
}

export default CampaignInfoModule;
