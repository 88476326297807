import QueueableRequest from './QueueableRequest';
import { ACCOUNT_LOGOUT_USER_ENDPOINT } from '../../config/api';
import { bindParamsToRoute, bindQueryParams } from '../../helpers/url';

export default class LogoutRequest extends QueueableRequest {
  constructor(token) {
    let url = bindParamsToRoute(ACCOUNT_LOGOUT_USER_ENDPOINT, {});
    url = bindQueryParams(url, { id_token_hint: token });
    super(url, {});
  }
}
