import React from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';
import { capitalize } from '../../../helpers/str';

import Headline from './TextHeadline';
import Copy from './TextCopy';

function TextHeadlineCopyGroup(props) {
  return (
    <section
      className={suitcss({
        componentName: 'TextHeadlineCopyGroup',
        modifiers: [
          props.align && `align${capitalize(props.align)}`,
        ],
        utilities: [
          props.inverted && 'colorInverted',
          ...props.utitlity,
        ],
      })}
    >
      {props.item.headline &&
        <Headline
          size={props.primary ? 'xl' : 'm'}
          element={props.primaryModule ? 'h1' : 'h2'}
          utilities={[props.inverted && 'colorInverted']}
          embedded
        >
          {props.item.headline}
        </Headline>
      }
      {props.item.copy &&
        <Copy
          raw
          embedded
          inverted={props.inverted}
        >
          {props.item.copy}
        </Copy>
      }
    </section>
  );
}

TextHeadlineCopyGroup.propTypes = {
  item: PropTypes.shape({
    headline: PropTypes.string,
    copy: PropTypes.string,
  }).isRequired,
  align: PropTypes.oneOf(['left', 'right', 'center']),
  inverted: PropTypes.bool,
  primary: PropTypes.bool,
  primaryModule: PropTypes.bool,
  utitlity: PropTypes.array,
};

TextHeadlineCopyGroup.defaultProps = {
  utitlity: [],
};

export default TextHeadlineCopyGroup;
