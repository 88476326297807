import PageModule from './PageModule';
import component from '../../containers/service/SubscriptionManager';

/**
 * Module for displaying subscription management form
 * @see https://confluence.db-n.com/x/UgdHAQ
 */
class SubscriptionManagerModule extends PageModule {
  constructor(moduleData) {
    super(component, moduleData);
  }
}

export default SubscriptionManagerModule;
