import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import * as requests from '../../../actions/request/registry';
import EECCBMnpPortingConfirmRequest from '../../../model/requests/EECCBMnpPortingConfirmRequest';
import FormManager from '../../form/FormManager';
import GlobalSection from '../../../components/basics/global/GlobalSection';
import suitcss from '../../../helpers/suitcss';
import { trackMyState } from '../../../actions/tracking/event';
import {
  MYTRACK_SERVICE_BMNP_TIMING,
  TRACK_REPLACE_BMNP_TIMING,
  MYTRACK_SERVICE_SIMACT_UNAVAILABLE,
  MARKET_POSTPAID,
  FORM_ID_BMNP_MSISDN,
  FORM_ID_BMNP_CONFIRM,
  FORM_ID_BMNP_CONTRACT,
} from '../../../helpers/constants';
import * as msisdnConfig from './formConfigBelatedMnpMsisdn';
import * as contractConfig from './formConfigBelatedMnpContract';
import * as confirmConfig from './formConfigBelatedMnpConfirm';

import {
  getDialogByBelatedMnpState,
} from '../../../actions/dialog/myBelatedMnpActions';
import { fetchContractData, fetchCustomerData } from '../../../actions/request/registry';
import { send } from '../../../actions/request/send';
import PaymentDataRequest from '../../../model/requests/PaymentDataRequest';

class MyBelatedMnpForm extends Component {

  constructor(...args) {
    super(...args);
    this.onAfterSubmitSuccess = this.onAfterSubmitSuccess.bind(this);
    this.getSubmitRoute = this.getSubmitRoute.bind(this);
    this.onStepEnter = this.onStepEnter.bind(this);
    this.onStepLeave = this.onStepLeave.bind(this);
  }

  async componentDidMount() {
    const { dispatch, msisdn } = this.props;
    const contractData = await dispatch(fetchContractData());
    if (contractData.mnpPossible) {
      dispatch(send(new PaymentDataRequest(msisdn)));
      return;
    }
    dispatch(trackMyState(MYTRACK_SERVICE_SIMACT_UNAVAILABLE));
    if (contractData.belatedMnp) {
      const mnpState = contractData.belatedMnp.state;
      dispatch(getDialogByBelatedMnpState(mnpState));
      return;
    }
    dispatch(getDialogByBelatedMnpState());
  }

  async onStepEnter(step, fieldMap, formValues) {
    const { dispatch } = this.props;

    if (step === FORM_ID_BMNP_CONFIRM) {
      await dispatch(fetchCustomerData());

      const eeccInitialRequestBody = this.eeccBMnpPortingInitialRequestBody(formValues);
      await dispatch(requests.fetchEECCBMnpPortingInitialData(eeccInitialRequestBody));
    }

    if (step === FORM_ID_BMNP_CONTRACT) {
      const form = MyBelatedMnpForm.formName;
      dispatch(change(form, fieldMap.mnpMailOption.name, '01'));
    }
  }

  async onStepLeave(step) {
    const { dispatch } = this.props;

    if (step === FORM_ID_BMNP_MSISDN) {
      await dispatch(fetchCustomerData());
    }
  }

  onAfterSubmitSuccess({ finalizedValues }) {
    const { dispatch } = this.props;
    const { porting_date_type: type } = finalizedValues;

    const trackMsg = MYTRACK_SERVICE_BMNP_TIMING.replace(TRACK_REPLACE_BMNP_TIMING, type);
    dispatch(trackMyState(trackMsg));
  }

  getSubmitRoute() {
    const { msisdn, processId } = this.props;
    return new EECCBMnpPortingConfirmRequest(
      processId,
      msisdn,
      { contract_summary_confirmed: true },
    );
  }

  eeccBMnpPortingInitialRequestBody(formValues) {
    const { mobileProviders } = this.props;

    // add provider based on provider_key
    const mnpProvider = mobileProviders.find(
      ({ id }) => id === formValues.mnp_provider_key,
    );

    const portingDateType =
      formValues.mnp_porting_date_type;
    let finalPortingDateType = '';

    if (portingDateType === 'VTEN') {
      finalPortingDateType = 'CONTRACT_END';
    } else if (portingDateType === 'ASAP') {
      finalPortingDateType = 'AS_SOON_AS_POSSIBLE';
    }

    const salutation = formValues.mnp_salutation;

    let finalSalutation = '';
    if (salutation === '01') {
      finalSalutation = 'MALE';

    } else if (salutation === '02') {
      finalSalutation = 'FEMALE';
    }

    return {
      mobile_number_portability: {
        gender: finalSalutation,
        first_name: formValues.mnp_first_name,
        last_name: formValues.mnp_last_name,
        birth_date: formValues.mnp_birthday,
        porting_msisdn: formValues.mnp_msisdn,
        network_key: mnpProvider.network,
        provider_key: formValues.mnp_provider_key,
        porting_date_type: finalPortingDateType,
        porting_consent: true,
      },
      product: {
        sim_type: formValues.mnp_sim_type,
      },
    };
  }

  render() {
    const { location, sitemap, market } = this.props;
    const { MyBelatedMNPSuccessRoute, MyBelatedMNPPucSuccessRoute } = sitemap;
    const isPuc = market === MARKET_POSTPAID;
    return (
      <div className={suitcss({}, this)}>
        <GlobalSection>
          <FormManager
            form={MyBelatedMnpForm.formName}
            location={location}
            steps={[msisdnConfig, contractConfig, confirmConfig]}
            stepProps={this.props}
            onStepEnter={this.onStepEnter}
            onStepLeave={this.onStepLeave}
            submitRoute={this.getSubmitRoute}
            successRoute={isPuc ? MyBelatedMNPPucSuccessRoute.url : MyBelatedMNPSuccessRoute.url}
            onAfterSubmitSuccess={this.onAfterSubmitSuccess}
            withBlockError
            withFooter
          />
        </GlobalSection>
      </div>
    );
  }
}

/**
 * required by tracking!
 */
MyBelatedMnpForm.formName = 'belatedMnp';

MyBelatedMnpForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  msisdn: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  market: PropTypes.string.isRequired,
  sitemap: PropTypes.object.isRequired,
  mobileProviders: PropTypes.array.isRequired,
  processId: PropTypes.string,
  ui: PropTypes.shape({
    mnpSimTypeEsim: PropTypes.string,
    mnpSimTypeCard: PropTypes.string,
  }),
};

const mapStateToProps = ({ site, user }) => ({
  sitemap: site.sitemap,
  mobileProviders: site.mobileProviders,
  msisdn: user.credentials.msisdn,
  market: user.credentials.market,
  processId: user.EECC.bmnp.processId,
});

const mapDispatchToProps = dispatch => ({ dispatch });

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MyBelatedMnpForm);
