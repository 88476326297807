import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import suitcss from '../../../helpers/suitcss';
import Link from '../../basics/text/TextLink';
import Copy from '../../basics/text/TextCopy';
import connectUI from '../../basics/ui/UIConnector';
import { navLinkList } from '../../../propTypes/navigation';
import {
  MYTRACK_NAV_MY_DATA,
  MYTRACK_NAV_MY_INBOX,
} from '../../../helpers/constants';
import MediaImage from '../../basics/media/MediaImage';

const getLinkTrackingData = (item) => {
  switch (item.class) {
    case 'nav-myotelo-myinbox':
      return { mytracking: MYTRACK_NAV_MY_INBOX };
    case 'nav-myotelo-mydata':
      return { mytracking: MYTRACK_NAV_MY_DATA };
    default:
      return null;
  }
};

const componentName = 'MyNavigation';
class MyNavigation extends PureComponent {

  renderLink(item, key) {
    const { notificationCount } = this.props;
    const isExternalLink = item.external === true;
    const trackingData = getLinkTrackingData(item);
    return (
      <Link to={item.url} withoutArrow data={trackingData} key={key}>
        {item.class === 'nav-myotelo-myinbox' && (
          <span className={notificationCount > 0 ? 'u-elementCountAfter' : null} data-count={notificationCount}>
            {item.title}
          </span>
        )}
        {item.class !== 'nav-myotelo-myinbox' && !isExternalLink && (item.title)}
      </Link>
    );
  }

  renderAppStoreLogos(item, key) {
    const isExternalLink = item.external === true;
    const trackingData = getLinkTrackingData(item);
    return (
      <Link withoutStyle to={item.url} withoutArrow data={trackingData} key={key}>
        {item.class !== 'nav-my-otelo-myinbox' && isExternalLink && (
          <Link
            className={suitcss({ componentName, descendantName: 'logos' })}
            to={item.url}
            withoutArrow
            withoutStyle
            target={item.target}
          >
            <MediaImage src={item.icon} />
          </Link>
          )}
      </Link>
    );
  }

  render() {
    const { items, logout, user, ui } = this.props;
    return (
      <div className={suitcss({ componentName })}>
        <div className={suitcss({ componentName, descendantName: 'header' })}>
          <Copy embedded utilities={['weightBold']}>{user.name}</Copy>
          <Copy embedded size="secondary" utilities={['fontCondensed', 'colorGray100']}>{user.phoneNumber}</Copy>
        </div>
        <div className={suitcss({ componentName, descendantName: 'links' })}>
          {items.map(this.renderLink, this)}
        </div>
        <div className={suitcss({ componentName, descendantName: 'footer' })}>
          <Link onClick={logout} element="button" utilities={['weightBold']}>
            {ui.guiWordLogout}
          </Link>
        </div>
        {items.map(this.renderAppStoreLogos, this)}
      </div>
    );
  }
}

MyNavigation.propTypes = {
  items: navLinkList,
  notificationCount: PropTypes.number,
  ui: PropTypes.shape({
    guiWordLogout: PropTypes.string,
  }),
  user: PropTypes.shape({
    name: PropTypes.string,
    msisdn: PropTypes.string,
  }),
  logout: PropTypes.func.isRequired,
};

export default compose(connectUI())(MyNavigation);
