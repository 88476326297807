import { isBridgeEnabled } from '../../helpers/site';
import ApiError from '../../model/errors/ApiError';
import SessionExpiredError from '../../model/errors/SessionExpiredError';
import ReauthRequest from '../../model/requests/ReauthRequest';
import * as bridge from '../../services/bridge';
import { logout } from '../user/logout';
import {
  requestInProgess,
  receivedResponse,
  receivedError,
  fetch,
} from './basic';
import { sendDirect } from './sendDirect';
import { ACTION_PREFIX } from '../../helpers/constants';

export const REQUEST_QUEUE_REGISTER = `${ACTION_PREFIX}/REQUEST_QUEUE_REGISTER`;
export const REQUEST_QUEUE_PROCESSOR_LOCK = `${ACTION_PREFIX}/REQUEST_QUEUE_PROCESSOR_LOCK`;

/**
 * Informs the system about whether a processing thread is currently
 * active and processes the queue.
 *
 * Note: Even if no request is in the queue, the processing thread may still
 * be alive.
 *
 * @param {boolean} isProcessing
 */
export const setQueueProcessorLock = (isProcessing) => ({
  type: REQUEST_QUEUE_PROCESSOR_LOCK,
  payload: { isProcessing },
});

const sendNonceToApp = response => {
  if (response.headers) {
    const nonce = response.headers.get('X-Nonce');
    if (nonce) bridge.setNonce(nonce);
  }
};

/**
 * Action to dispatch in case a queued request failed.
 *
 * First all reducers are informed about the error,
 * then the all response promises are rejected.
 */
const queueError = (request, error) => (dispatch, getState) => {
  const { requestQueue } = getState();
  // note: we grab callbacks before they are removed from store
  const callbacks = requestQueue.callbacks[request];
  dispatch(receivedError(request, error));

  if (!callbacks) {
    // this happens when using redux-hot loading which results in
    // requests sometimes resolving twice. Will never happen on production.
    return;
  }

  callbacks.forEach(cb => cb.reject(error));
};

/**
 * Will remove all previously added request from the queue.
 */
export const unregisterAllRequests = error => (dispatch, getState) => {
  const { requests } = getState().requestQueue;
  requests.forEach(request => dispatch(queueError(request, error)));
};

/**
 * Adds the request and its callback to the list of existing requests
 * in the queue.
 *
 * @param {QueueableRequest} request
 * @param {Deferred} [callback]
 */
export const registerRequest = (request, callback) => ({
  type: REQUEST_QUEUE_REGISTER,
  meta: {
    identifier: request.id, // used for loading bar
  },
  payload: { request, callback },
});

/**
 * Action to dispatch once a request has been successfuly resolved.
 *
 * First all reducers are informed about the received response,
 * then the all pending promises are resolved.
 *
 * @param {QueueableRequest} request
 * @param {Object} response
 */
const queueSuccess = (request, response) => (dispatch, getState) => {
  const { user, site } = getState();
  // check login state, because we could have received a SessionExpiredError while executing
  // the request and we could run into trouble when we assume
  // a logged in user at this place.
  const isLoggedIn = user.credentials.msisdn;
  if (isLoggedIn) {
    const { requestQueue } = getState();
    // note: we grab callbacks before they are removed from store
    const callbacks = requestQueue.callbacks[request];
    dispatch(receivedResponse(request, response));

    if (!callbacks) {
      // this happens when using redux-hot loading which results in
      // requests sometimes resolving twice. Will never happen on production.
      return;
    }

    callbacks.forEach(cb => cb.resolve(response));
    if (isBridgeEnabled(site)) {
      // in bridge mode pass the nonce back to the app
      sendNonceToApp(response);
    }
  }
};

/**
 * @param {QueueableRequest} request
 * @param {Error} error
 * @return {Promise<boolean>|undefined} true, if error is recoverable, false if not.
 */
const handleQueueError = (request, error) => async (dispatch, getState) => {
  const { user, site } = getState();

  if (error instanceof ApiError) {
    if (error.fullResponse.status === 401) {
      const { msisdn } = user.credentials;
      if (msisdn) {
        try {
          // do not dispatch the error but try to re-authenticate first
          // note: "await" is necessary here to catch a rejected promise
          await dispatch(sendDirect(new ReauthRequest(msisdn, request)));
          return true;
        } catch (e) {
          // The reauthentication failed – this is a critical error that will cause
          // every account-related action to fail. therefore, we logout which will then
          // reject all remaining requests in the queue, including the reauth request.
          // moreover, we expect the "user" reducer to clear the existing credentials.
          dispatch(logout({ error: new SessionExpiredError() }));
          return false;
        }
      } else {
        // the user has never been authenticated; we reject all requests including the current one.
        dispatch(unregisterAllRequests(error));
        return false;
      }

    } else if (isBridgeEnabled(site)) {
      // in bridge mode pass the nonce back to the app
      sendNonceToApp(error.fullResponse);
    }
  }

  dispatch(queueError(request, error));
  return false;
};

/**
 * This function will load the request with the highest priority from
 * the store and initiate a synchronous request to the server.
 *
 * In case the request succeeds, it will dispatch an event, same for an
 * error.
 *
 * Regardless of whether the requests succeeds or not, the next
 * pending request in the queue is called.
 *
 * @todo move response success code to queueSuccess(), error code to queueError()
 * @todo add a counter variable as last resort to protect against endless loops!
 *
 * @returns {Promise}
 */
const processRequests = () => async (dispatch, getState) => {

  const { requestQueue } = getState();
  // all requests are sorted by prio in descending order, therefore,
  // we choose the first in the list, which is the most important one.
  const request = requestQueue.requests[0];

  if (!request) {
    dispatch(setQueueProcessorLock(false));
    return;
  }

  try {

    dispatch(requestInProgess(request));
    const response = await dispatch(fetch(request));
    await dispatch(queueSuccess(request, response));

  } catch (error) {

    await dispatch(handleQueueError(request, error));

  } finally {

    dispatch(processRequests());
  }
};

/**
 * Puts the request in the existing requests queue.
 *
 * The request will be fired once all remaining higher-prio requests
 * have been successfully processed.
 *
 * Once the request succeeded, a "response received" event is dispatched
 * which can be used in any reducer to store the response. It is
 * also possible to wait for the promise returned by this function,
 * which will contain the response.
 *
 * @param {QueueableRequest} request
 * @return {Promise}
 */
export const sendQueued = request => (dispatch, getState) =>
  new Promise((resolve, reject) => {

    // immediately register request, which will insert it in the request queue
    dispatch(registerRequest(request, { resolve, reject }));

    const { isProcessing } = getState().requestQueue;
    if (isProcessing) {
      // an earlier event already spawned a processing thread so we do not
      // need to start a new processing loop.
    } else {
      // no processing loop is currently active so we will spawn one.
      // note: we deliberately disconnect the callstack here because the
      // request processor might process other requests first and it makes
      // no sense waiting for this.
      dispatch(setQueueProcessorLock(true));
      setImmediate(() => dispatch(processRequests()));
    }
  });
