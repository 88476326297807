import QueueableRequest from './QueueableRequest';
import TopupStatusRequest from './TopupStatusRequest';
import { ACCOUNT_TOPUP_AUTO_ENDPOINT } from '../../config/api';
import { bindParamsToRoute } from '../../helpers/url';
import { REQUEST_METHOD_POST } from '../../helpers/constants';

export default class AutoTopupRequest extends QueueableRequest {
  constructor(msisdn, payload) {
    super(bindParamsToRoute(ACCOUNT_TOPUP_AUTO_ENDPOINT, { msisdn }), {
      method: REQUEST_METHOD_POST,
      payload,
    });
  }

  /**
   * @inheritDoc
   */
  getSubsequentRequests({ user }) {
    return [
      new TopupStatusRequest(user.credentials.msisdn),
    ];
  }
}
