import { connect } from 'react-redux';
import { compose } from 'redux';

import initForm from '../../form/FormInitializer';
import { getTokenRequestHandler } from './helpers';
import * as passwordStep from './formConfigPassword';
import PasswordResetFormSms
  from '../../../components/compositions/account/passwordReset/PasswordResetFormSms';
import FormConfigProps from '../../../model/form/FormConfigProps';

const createFieldMap = ({ ui }) => {
  return {
    smsCode: {
      name: 'token',
      label: ui.myPasswordLostSmsCodeLabel,
      validation: {
        isRequired: true,
      },
    },
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onRequestToken: getTokenRequestHandler('sms', dispatch, ownProps),
});

// init component once, outside
const component = compose(
  connect(null, mapDispatchToProps),
  initForm(),
)(PasswordResetFormSms);

export const id = 'sms';

/**
 * @return {FormConfig}
 */
export const mapStateToFormConfig = (state, props) => ({
  component,
  fieldMap: createFieldMap(props),
  next: passwordStep.id,
  formConfigProps: new FormConfigProps({
    withFooter: false,
  }),
});

export default mapStateToFormConfig;
