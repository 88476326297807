/* global window */
import { hideDialog, showDialog } from '../page/dialog';
import ConsentHandlingForm from '../../containers/consentHandling/ConsentHandlingForm';
import CookieConsent from '../../containers/consentHandling/CookieConsent';
import { send } from '../request/send';
import UmidInfoRequest from '../../model/requests/UmidInfoRequest';
import { QUERY_COOKIE_CONSENT } from '../../helpers/constants';

export const setCookieConsent = (finalizedValues) => {
  // updates the fcData object which is the interface for trakken
  window.fcData = {
    ...window.fcData,
    notificationStatus: {
      ...window.fcData.notificationStatus,
      'LI-OM': true,
      'LI-OPT': true,
    },
    permissions: {
      ...window.fcData.permissions,
      ...finalizedValues,
    },
  };
};

export const showCookieConsentSettingsDialog = () => (dispatch, getState) => {
  const { ui } = getState();
  dispatch(showDialog({
    theme: 'cookieconsentsettings',
    component: ConsentHandlingForm,
    props: {
      withoutBorder: true,
      isExpanded: true,
      label: ui.guiCookiesSettingsCta1,
    },
    withCloseAction: false,
    isExpanded: true,
    actions: [
      {
        label: ui.guiCookiesSettingsCta2,
        action: () => {
          // eslint-disable-next-line no-use-before-define
          dispatch(showCookieConsentDialog());
        },
        asLink: true,
      },
    ],
  }));
};

export const showCookieConsentDialog = () => (dispatch, getState) => {
  const { ui, user, site, routing } = getState();
  const { appView, isInboxView } = site;
  const isAppView = Boolean(appView);
  if (routing.locationBeforeTransitions.query[QUERY_COOKIE_CONSENT] === 'anonymous') {
    return null;
  } else if (user.gdprNotifications['li-om'] === false && !isAppView && !isInboxView) {
    dispatch(showDialog({
      theme: 'cookieconsent',
      component: CookieConsent,
      withoutLightboxFadeout: true,
      headline: ui.guiCookiesHintHeadline,
      props: {
        copy: ui.txtGuiCookiesHintCopyWeb,
        cookieSettings: ui.guiCookiesHintCta2,
        wordGdprNotes: ui.guiWordGdprNotes,
        wordGdprNotesUrl: ui.guiWordGdprNotesUrl,
        wordImprint: ui.guiWordImprint,
        wordImprintUrl: ui.guiWordImprintUrl,
        showCookieConsentSettings: () => dispatch(showCookieConsentSettingsDialog()),
      },
      withCloseAction: false,
      isExpanded: true,
      actions: [
        {
          label: ui.guiCookiesHintCta1,
          withoutArrow: true,
          action: async () => {
            await dispatch(send(new UmidInfoRequest({ 'LI-OM': true, 'LI-OPT': true }, { 'LI-OM': true, 'LI-OPT': true })));
            setCookieConsent({ 'LI-OM': true, 'LI-OPT': true });
            dispatch(hideDialog());
            window.adv_global && window.adv_global.registerEventListeners(); /*eslint-disable-line*/
          },
        },
        {
          label: ui.guiCookiesHintCta3,
          buttonFilled: true,
          withoutArrow: true,
          action: async () => {
            await dispatch(send(new UmidInfoRequest({ 'LI-OM': false, 'LI-OPT': false }, { 'LI-OM': true, 'LI-OPT': true })));
            setCookieConsent({ 'LI-OM': false, 'LI-OPT': false });
            dispatch(hideDialog());
            window.adv_global && window.adv_global.registerEventListeners(); /*eslint-disable-line*/
          },
        },
      ],
    }));
  }
};
