import React from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';

import Headline from '../../basics/text/TextHeadline';

function TeaserKeyFact(props) {
  const { label, value } = props;
  return (
    <div
      className={suitcss({
        componentName: 'TeaserKeyFact',
        modifiers: props.modifiers,
      })}
    >
      {value &&
        <Headline size="l" utilities={['colorPrimary']}>
          {value}
        </Headline>
      }
      {label &&
        <Headline size="xs" >
          {label}
        </Headline>
      }
    </div>
  );
}

TeaserKeyFact.propTypes = {
  modifiers: PropTypes.array,
  label: PropTypes.string,
  value: PropTypes.string,
};
TeaserKeyFact.defaultProps = {

};

export default TeaserKeyFact;
