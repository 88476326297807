import React from 'react';
import dates from '../../../helpers/dates';
import {
  MARKET_POSTPAID,
  FORM_FIELD_CHECKOUT_MNP,
  FORM_FIELD_MNP_PORTING_VTEN,
  FORM_FIELD_MNP_PORTING_ASAP,
  SIM_CARD,
  E_SIM,
} from '../../../helpers/constants';
import SimTypeToggleLabel from '../../../components/compositions/form/belatedMnpFormPart/SimTypeToggleLabel';

export const createFieldMapBelatedMnpStepMsisdn = (state, props) => {
  const { ui, mobileProviders, isMnpCheckout } = props;
  return {
    mnp: {
      name: FORM_FIELD_CHECKOUT_MNP,
      type: 'checkbox',
    },
    mnpMsisdn: {
      name: 'mnp_msisdn',
      label: isMnpCheckout ? ui.cfoMnpNumberToPort : ui.mnpFormMsisdnLabel,
      hint: isMnpCheckout ? ui.cfoMnpNumberToPortHint : ui.mnpFormMsisdnHint,
      type: 'tel',
      validation: {
        dependsOn: { mnp: true },
        isRequired: true,
        pattern: 'phone',
        range: [10, 14],
      },
    },
    mnpProviderKey: {
      name: 'mnp_provider_key',
      label: isMnpCheckout ? ui.cfoMnpPreviousNetwork : ui.mnpFormProviderLabel,
      options: mobileProviders.map(provider => ({
        label: provider.name,
        value: provider.id,
      })).sort((a, b) => a.label.localeCompare(b.label)),
      validation: {
        dependsOn: { mnp: true },
        isRequired: true,
      },
    },
  };
};

export const createFieldMapBelatedMnpStepContract = (state, props) => {
  const {
    ui,
    isMnpCheckout,
    cartTariff,
    isMyBelatedMnpForm,
  } = props;
  const { user } = state;
  const isPostPaid = (isMnpCheckout ? cartTariff.market : user.market) === MARKET_POSTPAID;
  const isContractSimTypeEsim = user.contractData
    && user.contractData.simCard.simType === E_SIM;

  const fieldMap = ({
    mnp: {
      name: FORM_FIELD_CHECKOUT_MNP,
      type: 'checkbox',
    },
    mnpPortingDateType: {
      name: 'mnp_porting_date_type',
      validation: {
        dependsOn: { mnp: true },
        isRequired: true,
      },
      options: [
        {
          label: ui.cfoMnpPortingBeforeEnd,
          value: FORM_FIELD_MNP_PORTING_ASAP,
          hint: isPostPaid ? ui.txtMnpFormBeforeCopyPuc : ui.txtMnpFormBeforeCopy,
        },
        {
          label: ui.cfoMnpPortingWithEnd,
          value: FORM_FIELD_MNP_PORTING_VTEN,
          hint: isPostPaid ? ui.txtMnpFormEndCopyPuc : ui.txtMnpFormEndCopy,
        },
      ],
    },
    mnpSalutation: {
      name: 'mnp_salutation',
      label: ui.guiFormSalutation,
      validation: {
        dependsOn: { mnp: true },
        isRequired: true,
      },
      options: [
        {
          label: ui.guiFormSalutationFemale,
          value: '02',
        },
        {
          label: ui.guiFormSalutationMale,
          value: '01',
        },
      ],
    },
    mnpFirstName: {
      name: 'mnp_first_name',
      label: ui.guiFormNameFirst,
      validation: {
        dependsOn: { mnp: true },
        isRequired: true,
        range: [null, 50],
      },
    },
    mnpLastName: {
      name: 'mnp_last_name',
      label: ui.guiFormNameLast,
      validation: {
        dependsOn: { mnp: true },
        isRequired: true,
        range: [null, 50],
      },
    },
    mnpBirthday: {
      name: 'mnp_birthday',
      label: ui.guiFormDob,
      labelDay: ui.guiFormDobDay,
      labelMonth: ui.guiFormDobMonth,
      labelYear: ui.guiFormDobYear,
      hint: isMnpCheckout ? ui.cfoDobHint : null,
      optionsDays: dates.days,
      optionsMonths: dates.months,
      optionsYears: dates.years,
      validation: {
        dependsOn: { mnp: true },
        isRequired: true,
        pattern: 'date',
      },
    },
  });

  if (isMnpCheckout) {
    return fieldMap;
  }

  if (isMyBelatedMnpForm) {
    return {
      ...fieldMap,
      mnpSimType: {
        name: 'mnp_sim_type',
        validation: {
          dependsOn: { mnp: true },
          isRequired: true,
        },
        options: [
          {
            name: 'sim',
            label: '',
            customLabelEnhancement:
              <SimTypeToggleLabel
                isSimCardLabel
                isContractSimTypeEsim={isContractSimTypeEsim}
              />,
            value: SIM_CARD,
          },
          {
            name: 'esim',
            label: '',
            customLabelEnhancement:
              <SimTypeToggleLabel
                isContractSimTypeEsim={isContractSimTypeEsim}
              />,
            value: E_SIM,
          },
        ],
      },
      mnpMailOption: {
        name: 'mnp_mail_option',
        options: [
          {
            label: ui.mnpSimEmailRadioYes,
            value: '01',
          },
          {
            label: ui.mnpSimEmailRadioNo,
            value: '02',
          },
        ],
      },
      mnpMailInput: {
        name: 'mnp_mail_input',
        label: ui.mnpSimEmailFormLabelPlaceholder,
        type: 'email',
        validation: {
          dependsOn: {
            mnpMailOption: [
              '02',
            ],
          },
          isRequired: true,
          pattern: 'email',
        },
      },
    };
  }

  return {
    ...fieldMap,
    mnpContactNumber: {
      name: 'mnp_contact_number',
      label: ui.guiFormPhone,
      hint: ui.guiFormPhoneHint,
      type: 'tel',
      validation: {
        isRequired: true,
        pattern: 'phone',
        range: [7, 25],
      },
    },
  };
};

export const createFieldMapMnpCheckout = (state, props) => ({
  ...createFieldMapBelatedMnpStepMsisdn(state, { ...props, isMnpCheckout: true }),
  ...createFieldMapBelatedMnpStepContract(state, { ...props, isMnpCheckout: true }),
});
