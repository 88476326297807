import QueueableRequest from './QueueableRequest';
import { NPS_SUBMIT_ENDPOINT } from '../../config/api';
import { REQUEST_METHOD_POST } from '../../helpers/constants';
import { bindParamsToRoute } from '../../helpers/url';

export default class NpsRequest extends QueueableRequest {
  constructor(optionType, payload) {
    super(bindParamsToRoute(NPS_SUBMIT_ENDPOINT, { optionType }), {
      method: REQUEST_METHOD_POST,
      payload,
    });
  }
}
