import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import suitcss from '../../helpers/suitcss';
import Lightbox from '../../components/basics/lightbox/Lightbox';
import UITransitionSpinner from '../../components/basics/ui/UITransitionSpinner';

const componentName = 'GlobalLoadingSpinner';
const GlobalLoadingSpinner = (props) => {
  const { ui, isVisible } = props;
  return (
    <Lightbox className={suitcss({ componentName })} isVisible={isVisible} withScrolling={false} >
      <UITransitionSpinner
        headline={ui.guiSpinnerMessage}
        copy={ui.guiSpinnerDuration}
        key={componentName}
      />
    </Lightbox>
  );
};

GlobalLoadingSpinner.propTypes = {
  isVisible: PropTypes.bool,
  ui: PropTypes.shape({
    guiSpinnerMessage: PropTypes.string,
    guiSpinnerDuration: PropTypes.string,
  }),
};

const mapStateToProps = ({ ui, site, lifecycle }) => {
  const isDialogOpen = site.error || site.dialog || site.dialogs.length;
  return {
    isVisible: (
      (lifecycle.isBlocking && !isDialogOpen) ||
      site.windowOpen ||
      lifecycle.isBlockingRequestInProgress
    ),
    ui,
  };
};

export default connect(mapStateToProps)(GlobalLoadingSpinner);
