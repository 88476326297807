import QueueableRequest from './QueueableRequest';
import {
  ACCOUNT_GDPR_CONSENTS_AGGREGATED_ENDPOINT,
} from '../../config/api';
import { bindParamsToRoute } from '../../helpers/url';
import { blockContentBasedOnUserScope } from '../../helpers/scope';
import {
  REQUEST_METHOD_POST,
  SCOPE_MYDATA,
  SCOPE_MYDATA_READ,
  SCOPE_MYDATA_READ_CONSENTS,
  SCOPE_MYDATA_READ_GDPR,
  SCOPE_MYDATA_WRITE,
  SCOPE_MYDATA_WRITE_CONSENTS,
  SCOPE_MYDATA_WRITE_GDPR,
} from '../../helpers/constants';
import AccountGdprConsentsRequest from './AccountGdprConsentsRequest';

export default class AccountGdprConsentsAggregatedRequest extends QueueableRequest {
  constructor(msisdn, options) {
    super(bindParamsToRoute(ACCOUNT_GDPR_CONSENTS_AGGREGATED_ENDPOINT, { msisdn }), options);
    this.isPostRequest = options && options.method === REQUEST_METHOD_POST;
  }

  getSubsequentRequests({ user }) {
    return [
      new AccountGdprConsentsAggregatedRequest(user.credentials.msisdn),
      new AccountGdprConsentsRequest(user.credentials.msisdn),
    ];
  }

  block(state) {
    if (this.isPostRequest) {
      return !blockContentBasedOnUserScope(
        state.user.scope,
        SCOPE_MYDATA || SCOPE_MYDATA_WRITE ||
        SCOPE_MYDATA_WRITE_CONSENTS || SCOPE_MYDATA_WRITE_GDPR,
      );
    }
    return !blockContentBasedOnUserScope(
      state.user.scope,
      SCOPE_MYDATA || SCOPE_MYDATA_READ ||
      SCOPE_MYDATA_READ_CONSENTS || SCOPE_MYDATA_READ_GDPR,
    );
  }
}
