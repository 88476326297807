import QueueableRequest from './QueueableRequest';
import { UMID_INFO_ENDPOINT } from '../../config/api';
import { bindParamsToRoute } from '../../helpers/url';
import { getItem, STORAGE_TYPE_COOKIE } from '../../helpers/storage';
import { REQUEST_METHOD_POST, STORAGE_KEY_UMID } from '../../helpers/constants';

export default class UmidInfoRequest extends QueueableRequest {
  constructor(permissions, notifications) {
    const timestamp = Date.now();
    const isPost = permissions || notifications;
    const identifier = getItem(STORAGE_TYPE_COOKIE, STORAGE_KEY_UMID);
    const options = isPost ? {
      method: REQUEST_METHOD_POST,
      isDecamelizePayload: false,
      payload: {
        timestamp,
        identifier,
        permissions: permissions || undefined,
        notifications: notifications || undefined,
      },
    } : null;

    const requestUrl = bindParamsToRoute(
      UMID_INFO_ENDPOINT,
      { identifier: isPost ? null : identifier },
    );
    super(requestUrl, options);
  }

  sendCredentials() {
    return true;
  }

  getSubsequentRequests() {
    return [
      new UmidInfoRequest(),
    ];
  }
}

