import PageModule from './PageModule';
import component from '../../containers/hardware/HardwareTariffs';
import { camelCase } from '../../helpers/str';

/**
 * The HardwareTariffs module shows the available tariffs to a preselected hardware.
 * @see https://confluence.db-n.com/x/qJ7c
 */
class HardwareTariffsModule extends PageModule {
  constructor(moduleData) {

    let modifiedModuleData = moduleData;
    let moduleTabs;
    let tabEntities;

    // due to server side rendering the tabs appear first in moduleData
    // but are then further located in params
    if (moduleData.tabs) {
      moduleTabs = moduleData.tabs;
    } else if (moduleData.params && moduleData.params.tabs) {
      moduleTabs = moduleData.params.tabs;
    }

    if (moduleData) {
      const temp = [];
      moduleTabs.map(tab =>
        tab.entities.map(entity => temp.push({ ...entity, etype: camelCase(entity.etype) })));
      tabEntities = {
        ...moduleData,
        entities: [
          ...temp,
        ],
      };
      modifiedModuleData = tabEntities;
    }

    super(component, modifiedModuleData);
  }
}

export default HardwareTariffsModule;
