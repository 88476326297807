import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import FormManager from '../form/FormManager';
import * as contactData from './formConfigContactData';
import GlobalSection from '../../components/basics/global/GlobalSection';
import { trackMyState } from '../../actions/tracking/event';
import { MYTRACK_CONTACT_SUBMIT_SUCCESS } from '../../helpers/constants';
import ContactRequest from '../../model/requests/ContactRequest';
import { fetchCaptchaData } from '../../actions/request/registry';

class ContactForm extends PureComponent {

  constructor(props, ...args) {
    super(props, ...args);
    this.onAfterSubmitSuccess = this.onAfterSubmitSuccess.bind(this);
    this.getSubmitRoute = this.getSubmitRoute.bind(this);
  }

  onAfterSubmitSuccess() {
    const { dispatch } = this.props;
    dispatch(trackMyState(MYTRACK_CONTACT_SUBMIT_SUCCESS));
  }

  getSubmitRoute(fieldMap, finalizedFormValues) {
    const { captchaToken } = this.props;

    return new ContactRequest({
      ...finalizedFormValues,
      captcha: {
        token: captchaToken,
        solution: finalizedFormValues.captcha ? finalizedFormValues.captcha : '',
      },
    });
  }

  render() {
    const { location, params, layoutSettings, dispatch } = this.props;
    const { urlSuccess } = params;

    return (
      <GlobalSection layoutSettings={layoutSettings}>
        <FormManager
          form={ContactForm.formName}
          location={location}
          stepProps={this.props}
          submitRoute={this.getSubmitRoute}
          successRoute={urlSuccess}
          onAfterSubmitSuccess={this.onAfterSubmitSuccess}
          onSubmitWillFail={async () => { await dispatch(fetchCaptchaData()); }}
          steps={[contactData]}
          withFooter
          fieldsToResetOnSubmitFail={
            {
              captcha: '',
            }
          }
        />
      </GlobalSection>
    );
  }
}

/**
 * required by tracking!
 */
ContactForm.formName = 'contact';

ContactForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  layoutSettings: PropTypes.object,
  params: PropTypes.shape({
    urlSuccess: PropTypes.string.isRequired,
    headline: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
};

const mapDispatchToProps = dispatch => ({ dispatch });

const mapStateToProps = (state) => {
  return {
    captchaToken: state.captcha.token,
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(ContactForm);
