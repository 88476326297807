import AbstractError from './AbstractError';
import { SESSION_EXPIRED_ERROR } from '../../config/errorCodes';

/**
 * Error to be thrown if the user's session cannot be recovered.
 */
class SessionExpiredError extends AbstractError {
  constructor() {
    super(SESSION_EXPIRED_ERROR);
  }
}

export default SessionExpiredError;
