/* global window, document */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { scrollToElement } from '../../helpers/navigation';

/**
 * This component will ensure that its children are first rendered as soon as all information
 * is loaded and the all components are ready to be rendered.
 */
class GlobalPageSwitcher extends Component {

  static getHashElement(hash) {
    const hashValue = hash.replace(/^#/, '');
    return document.getElementById(hashValue);
  }

  static getValidLocationHash(location) {
    const { hash } = location;
    return !hash || hash.includes('=') ? null : decodeURI(hash);
  }

  constructor(...args) {
    super(...args);
    this.findHashElementAndScrollTo = this.findHashElementAndScrollTo.bind(this);
    this.findHashElementTimeout = null;
    this.state = {
      isScrolled: false,
    };
  }

  componentDidMount() {
    this.checkHashAndScrollTo();
  }

  shouldComponentUpdate(nextProps) {
    // prevent the page from rendering before its content was loaded
    if (!nextProps.isReady) {
      return false;
    }
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!window) { return; }
    const { location, isReady } = this.props;
    const { isScrolled } = this.state;
    const shouldCheckHash = (location !== prevProps.location) ||
      (location === prevProps.location && isReady && isReady !== prevProps.isReady && !isScrolled);
    if (shouldCheckHash) {
      this.clearFindHashElementTimeout();
      this.checkHashAndScrollTo();
    }
  }

  clearFindHashElementTimeout() {
    if (this.findHashElementTimeout) {
      clearTimeout(this.findHashElementTimeout);
    }
  }

  checkHashAndScrollTo() {
    const { location } = this.props;
    if (!location.hash) { return; }
    const validHash = GlobalPageSwitcher.getValidLocationHash(location);
    if (validHash) {
      this.findHashElementAndScrollTo(validHash);
    }
  }

  findHashElementAndScrollTo(hash) {
    const el = GlobalPageSwitcher.getHashElement(hash);
    if (el) {
      scrollToElement(el);
      this.setState({ isScrolled: true });
      return;
    }
    this.findHashElementTimeout = setTimeout(() => this.findHashElementAndScrollTo(hash), 1000);
  }

  render() {
    if (this.props.isReady) {
      return this.props.children;
    }
    return null;
  }
}

GlobalPageSwitcher.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
  isReady: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ lifecycle }) => ({
  isReady: lifecycle.isIdle,
});

export default connect(mapStateToProps)(GlobalPageSwitcher);
