import PropTypes from 'prop-types';
import {
  INLINE_ERROR_VALUE_MISMATCH,
  INLINE_ERROR_VALUE_AGE_OVERFLOW,
  INLINE_ERROR_VALUE_AGE_UNDERFLOW,
  INLINE_ERROR_VALUE_EQUALITY_MISMATCH,
  INLINE_ERROR_VALUE_MISSING,
  INLINE_ERROR_VALUE_CUSTOM_RULE,
  INLINE_ERROR_VALUE_GENERIC,
  INLINE_ERROR_VALUE_PATTERN_MISMATCH,
  INLINE_ERROR_VALUE_RANGE_MISMATCH,
} from '../helpers/constants';

export const metaPropTypes = {
  active: PropTypes.bool,
  asyncValidating: PropTypes.bool,
  dirty: PropTypes.bool,
  error: PropTypes.shape({
    type: PropTypes.oneOf([
      INLINE_ERROR_VALUE_GENERIC,
      INLINE_ERROR_VALUE_MISSING,
      INLINE_ERROR_VALUE_AGE_OVERFLOW,
      INLINE_ERROR_VALUE_AGE_UNDERFLOW,
      INLINE_ERROR_VALUE_MISMATCH,
      INLINE_ERROR_VALUE_RANGE_MISMATCH,
      INLINE_ERROR_VALUE_PATTERN_MISMATCH,
      INLINE_ERROR_VALUE_EQUALITY_MISMATCH,
      INLINE_ERROR_VALUE_CUSTOM_RULE,
    ]).isRequired,
    message: PropTypes.string,
  }),
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
  touched: PropTypes.bool,
  valid: PropTypes.bool,
  visited: PropTypes.bool,
};

export const inputPropTypes = {
  name: PropTypes.string,
  value: PropTypes.any.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onDragStart: PropTypes.func,
  onDrop: PropTypes.func,
};

export const metaShape = PropTypes.shape(metaPropTypes);
export const inputShape = PropTypes.shape(inputPropTypes);

export const hintShape = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.element,
]);

export const groupPropTypes = {
  label: PropTypes.string,
  hint: hintShape,
  input: inputShape,
  meta: metaShape,
  isDateGroup: PropTypes.bool,
  asStack: PropTypes.bool,
  theme: PropTypes.oneOf(['default', 'compact']),
};

export const optionsShape = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }),
);

export const groupShape = PropTypes.shape(groupPropTypes);

export const FieldPropTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  hint: hintShape,
  input: inputShape,
  meta: metaShape,
  raw: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  utitilies: PropTypes.array,
  theme: PropTypes.oneOf(['default', 'compact']),
};

export const shape = PropTypes.shape(FieldPropTypes);
export const list = PropTypes.arrayOf(shape);
