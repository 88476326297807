import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import SubscriptionManagementForm from './SubscriptionManagementForm';
import { REQUEST_METHOD_DELETE, REQUEST_METHOD_POST } from '../../helpers/constants';


// const SUBSCRIPTION_TYPE_TNPS = 'tnps';
// const SUBSCRIPTION_TYPE_NEWSLETTER = 'ema';
// const PROCESS_TYPE_SUBSCRIBE = 'subscription';
const PROCESS_TYPE_CANCEL = 'cancellation';

class SubscriptionManager extends PureComponent {
  render() {
    const { params, location } = this.props;
    const { query } = location;

    return (
      <SubscriptionManagementForm
        solicitorId={query.solicitorId}
        requestMethod={
          params.type === PROCESS_TYPE_CANCEL
            ? REQUEST_METHOD_DELETE
            : REQUEST_METHOD_POST
        }
        {...params}
      />
    );
  }
}

SubscriptionManager.propTypes = {
  params: PropTypes.shape({
    type: PropTypes.string,
    subscriptionType: PropTypes.string,
    urlSuccess: PropTypes.string,
    headline: PropTypes.string,
    copy: PropTypes.string,
  }),
  location: PropTypes.object,
};

export default SubscriptionManager;
