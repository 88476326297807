/* global document */
/* eslint-disable react/sort-comp */
import React from 'react';
import PropTypes from 'prop-types';
import SearchResultItem from './SearchResultItem';
import suitcss from '../../../helpers/suitcss';
import TextCopy from '../../basics/text/TextCopy';
import MediaImage from '../../basics/media/MediaImage';
import TextHeadline from '../../basics/text/TextHeadline';
import Link from '../../basics/text/TextLink';

const componentName = 'SearchResults';
class SearchResultList extends React.Component {
  constructor(props) {
    super(props);
    const { searchResults, searchMetaInfo, preSelectedFacet } = props;
    this.baseResultsToDisplay = 5;
    const availableFacets = Object.keys(searchMetaInfo.counts.facets);
    const sortedSearchResults =
      SearchResultList.sortResultByFacet(availableFacets, searchResults);

    this.state = {
      hitOffset: this.baseResultsToDisplay,
      activeResults: sortedSearchResults[preSelectedFacet],
      availableFacets,
      sortedSearchResultsByFacet: sortedSearchResults,
      activeFacet: preSelectedFacet,
      appliedFilter: {
        action: 'add filter',
        updatedFilter: [],
        appliedFilters: [{ facet: 'search filter', value: preSelectedFacet }],
      },
    };
    this.setLoadMoreOffset = this.setLoadMoreOffset.bind(this);
    this.lineAnimation = this.lineAnimation.bind(this);
  }

  static sortResultByFacet(facets, data) {
    const sortedData = {};
    sortedData['all'] = data;
    for (let i = 0; i < facets.length; i++) {
      sortedData[facets[i]] = data.filter(result => result.facet === facets[i]);
    }
    return sortedData;
  }

  componentDidUpdate(prevProps) {
    const { searchResults, searchMetaInfo, preSelectedFacet } = this.props;

    if (prevProps.searchResults.length !== searchResults.length) {
      const availableFacets = Object.keys(searchMetaInfo.counts.facets);
      const sortedSearchResults =
        SearchResultList.sortResultByFacet(availableFacets, searchResults);

      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        hitOffset: this.baseResultsToDisplay,
        activeResults: sortedSearchResults[preSelectedFacet],
        availableFacets,
        sortedSearchResultsByFacet: sortedSearchResults,
        activeFacet: preSelectedFacet,
      });
    }

    if (prevProps.preSelectedFacet !== preSelectedFacet) {
      const availableFacets = Object.keys(searchMetaInfo.counts.facets);
      const sortedSearchResults =
        SearchResultList.sortResultByFacet(availableFacets, searchResults);
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(
        {
          activeFacet: preSelectedFacet,
          activeResults: sortedSearchResults[preSelectedFacet],
        },
      );
    }

    this.lineAnimation();
  }

  componentDidMount() {
    this.lineAnimation();
  }

  lineAnimation(event) {
    this.line = document.querySelector('.SearchResults-resultFilterLine');
    const activeItem = !event ? document.querySelector('[class*="resultFilterItem--active"]') : event.target;
    if (!this.line || !activeItem) return;
    this.line.style.width = `${activeItem.getBoundingClientRect().width}px`;
    this.line.style.left = `${activeItem.offsetLeft}px`;
  }
  //------------------------------------------------------------------------------------------------
  //  Helper
  //------------------------------------------------------------------------------------------------
  async setLoadMoreOffset() {
    this.setState(prevState => {
      return {
        ...prevState,
        hitOffset: prevState.hitOffset + this.baseResultsToDisplay,
      };
    });
  }

  renderNothingWasFound() {
    const { userInteraction, resultsSorry } = this.props;
    return (userInteraction ?
      <div
        className={suitcss({
          descendantName: 'noResults',
        }, this)}
      >
        <MediaImage src="/files/icons/icon-search-no-result.svg" alt="no results" />
        <TextHeadline bold element="h2" size="m" utilities={['marginTop']}>
          {resultsSorry}
        </TextHeadline>
      </div> : null);
  }

  renderResultFilter() {
    const { searchMetaInfo, trackClick } = this.props;
    const { counts } = searchMetaInfo;
    const availableFacets = Object.keys(counts.facets);
    // eslint-disable-next-line no-return-assign
    const prepareClickTracking = (facet) => {
      const updatedFilter = { facet: 'search filter', value: facet };
      const filterUpdate = this.state.appliedFilter;
      filterUpdate.appliedFilters.push(updatedFilter);
      trackClick({ ...this.state.appliedFilter, updatedFilter });
      this.setState({
        appliedFilter: { ...this.state.appliedFilter, ...filterUpdate },
      });
    };

    return (
      <div
        className={suitcss({
          componentName,
          descendantName: 'resultFilter',
        }, this)}
      >
        <div
          className={suitcss({
            componentName,
            descendantName: 'resultFilterInner',
          }, this)}
        >
          <span
            className={suitcss({
              componentName,
              descendantName: 'resultFilterLine',
            }, this)}
          />
          <TextCopy
            className={suitcss({
              componentName,
              descendantName: 'resultFilterItem',
              modifiers: [this.state.activeFacet === 'all' ? 'active' : ''],
            }, this)}
            onClick={(ev) => {
              this.lineAnimation(ev);
              prepareClickTracking('all');
              this.setState({
                activeFacet: 'all',
                activeResults: this.state.sortedSearchResultsByFacet.all,
              });
            }}
            withoutStyles
            withoutArrow
            element={'span'}
          >
            {`Alles (${counts.total})`}
          </TextCopy>
          {availableFacets.map((facet, index) =>
            (<TextCopy
              className={suitcss({
                componentName,
                descendantName: 'resultFilterItem',
                modifiers: [this.state.activeFacet === facet ? 'active' : ''],
              }, this)}
              onClick={(ev) => {
                this.lineAnimation(ev);
                prepareClickTracking(facet);
                this.setState({
                  activeFacet: facet,
                  activeResults: this.state.sortedSearchResultsByFacet[facet],
              });
              }}
              element={'span'}
              key={index}
            >
              {`${facet} (${counts.facets[facet]})`}
            </TextCopy>))}
        </div>
      </div>
    );
  }

  renderSearchResultItems() {
    const searchResultsToDisplay =
      this.state.activeResults.filter(
        (item, index) => index <= (this.state.hitOffset - 1));
    return (
      this.state.activeResults.length !== 0 ?
        searchResultsToDisplay.map((item, index) => <SearchResultItem {...item} key={index} />) :
        this.renderNothingWasFound()
    );
  }

  renderingPagination() {
    return (
      (this.state.hitOffset < this.state.activeResults.length) &&
        <div
          className={suitcss({
            componentName,
            descendantName: 'showMoreButton',
          }, this)}
        >
          <Link
            asButton
            withoutArrow
            element="button"
            buttonFilled
            onClick={this.setLoadMoreOffset}
          >
            {this.props.moreAction}
          </Link>
        </div>
    );
  }

  //------------------------------------------------------------------------------------------------
  //  render
  //------------------------------------------------------------------------------------------------
  render() {
    return (
      <section
        className={suitcss({
          componentName,
        })}
      >
        {this.renderResultFilter()}
        {this.renderSearchResultItems()}
        {this.renderingPagination()}
      </section>
    );
  }
}

//------------------------------------------------------------------------------------------------
//  Proptypes
//------------------------------------------------------------------------------------------------

SearchResultList.propTypes = {
  searchMetaInfo: PropTypes.object.isRequired,
  searchResults: PropTypes.array.isRequired,
  moreAction: PropTypes.string.isRequired,
  isGlobalSearch: PropTypes.bool.isRequired,
  preSelectedFacet: PropTypes.string.isRequired,
  userInteraction: PropTypes.bool,
  resultsSorry: PropTypes.string,
  trackClick: PropTypes.func,
};

export default SearchResultList;

