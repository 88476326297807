import PropTypes from 'prop-types';

export const ORDER_STATE_UNAVAILABLE = 0;
export const ORDER_STATE_AVAILABLE = 1;

export const DELIVERY_STATE_AVAILABILITY_IMMEDIATELY = 1;
// available within a certain amount of days, see deliveryTime
export const DELIVERY_STATE_AVAILABILITY_IN_TIME = 2;
export const DELIVERY_STATE_AVAILABILITY_UNKNOWN = 3;

export const orderState = PropTypes.oneOf([ORDER_STATE_UNAVAILABLE, ORDER_STATE_AVAILABLE]);
export const deliveryState = PropTypes.oneOf([
  DELIVERY_STATE_AVAILABILITY_IMMEDIATELY,
  DELIVERY_STATE_AVAILABILITY_IN_TIME,
  DELIVERY_STATE_AVAILABILITY_UNKNOWN,
]);
