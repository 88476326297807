import React, { Fragment, PureComponent } from 'react';
import FileSaver from 'file-saver';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { bindQueryParams } from '../../helpers/url';
import { getContextAction } from '../../helpers/context';
import {
  QUERY_INTERNAL_ID,
  QUERY_ENTITY_ID,
  CONTEXT_CONTRACT_RENEWAL,
  CONTEXT_CONTRACT_RENEWAL_SIM_PLUS,
} from '../../helpers/constants';
import * as registryActions from '../../actions/request/registry';
import * as statusTariffActions from '../../actions/dialog/statusTariffActions';
import * as trackingActions from '../../actions/tracking/event';
import GlobalSection from '../../components/basics/global/GlobalSection';
import Copy from '../../components/basics/text/TextCopy';
import MyPromoDetailsComposition from '../../components/compositions/account/MyPromoDetails';
import ContentLoader from '../../components/compositions/content/ContentLoader';
import MyPromotionRequest from '../../model/requests/MyPromotionRequest';
import GlobalModule from '../global/GlobalModule';
import { isWebView } from '../../helpers/site';
import * as bridge from '../../services/bridge';
import { updateCredentials } from '../../actions/user/credentials';
import { send, sendIfNecessary } from '../../actions/request/send';
import EECCPromotionsDocumentRequest from '../../model/requests/EECCPromotionsOrderDocumentRequest';

const ACTION_TYPE_INFO = 'info';
const ACTION_TYPE_REDEEM = 'redeem';
const ACTION_TYPE_CONTEXT = 'context';
const ACTION_TYPE_PRODUCT = 'product';

class MyPromoDetails extends PureComponent {
  constructor(...args) {
    super(...args);
    this.manageDownloadEECCPdf = this.manageDownloadEECCPdf.bind(this);
    this.fetchEECPromotionInitialData = this.fetchEECPromotionInitialData.bind(this);
    this.initialPromoCalled = false; // this is to control, if the promo entity has been initialized
  }

  componentDidMount() {
    const {
      entityId,
      entity,
      entityDetailsId,
      entityDetails,
      fetchEntityById,
    } = this.props;
    if (!entity && entityId) {
      this.fetchMyPromotion();
    }
    if (!entityDetails && entityDetailsId) {
      fetchEntityById(entityDetailsId);
    }

    this.fetchEECPromotionInitialData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.entity !== this.props.entity) this.fetchEECPromotionInitialData();
  }

  /**
   * Generates the link of the teaser from the given item type.
   * @param data The data.
   */
  getDetailsPrimaryAction() {
    const {
      entity,
      entityDetails,
      sitemap,
      bookPromotion,
      fireContextAction,
      isAppView,
    } = this.props;
    const { primaryButtonType, primaryButtonUrl } = entityDetails;
    const { user } = this.props;
    const { processId } = user.EECC.promotion;

    if (isAppView) {
      if (processId && primaryButtonType === ACTION_TYPE_REDEEM) {
        return {
          onClick: () => { bookPromotion(entity, entityDetails); },
        };
      }
      return {
        onClick: () => bridge.cta(
          bridge.CTA_MY_PROMO_DETAILS,
          { primaryButtonType, primaryButtonUrl },
        ),
      };
    }

    const getExtendedContext = ({ context, targets }) => {
      if (context === CONTEXT_CONTRACT_RENEWAL) {
        if ((targets?.hardwareClasses || []).length > 0) {
          return CONTEXT_CONTRACT_RENEWAL_SIM_PLUS;
        }
      }
      return context;
    };

    switch (primaryButtonType) {
      case ACTION_TYPE_INFO:
        return { url: primaryButtonUrl, target: '_blank' };
      case ACTION_TYPE_REDEEM:
        return { onClick: () => bookPromotion(entity, entityDetails) };
      case ACTION_TYPE_CONTEXT:
        return { onClick: () => fireContextAction(getExtendedContext(entity)) };
      case ACTION_TYPE_PRODUCT:
        return {
          url: bindQueryParams(
            sitemap.MyTariffDetailsRoute.url,
            { iid: entity.targets.tariffs[0] },
          ),
        };
      default:
        return {};
    }
  }

  getEntityDetails() {
    const { entityDetails } = this.props;

    if (!entityDetails) { return null; }

    const {
      primaryButtonType,
      primaryButtonText,
      primaryButtonHint,
      secondaryButtonText,
      secondaryButtonHint,
      secondaryButtonUrl,
      ...rest
    } = entityDetails;

    const buttonPrimary = {
      type: primaryButtonType,
      label: primaryButtonText,
      hint: primaryButtonHint,
      ...this.getDetailsPrimaryAction(entityDetails),
    };

    const buttonSecondary = secondaryButtonText && secondaryButtonUrl
      ? {
        label: secondaryButtonText,
        hint: secondaryButtonHint,
        url: secondaryButtonUrl,
      } : null;

    return {
      ...rest,
      buttonPrimary,
      buttonSecondary,
    };
  }

  async fetchMyPromotion() {
    const {
      entityId,
      user,
      dispatch,
    } = this.props;

    const request = new MyPromotionRequest(user.credentials.msisdn, entityId);
    try {
      await dispatch(sendIfNecessary(request));
    } catch (err) {
      // Handle 404 error without logging to Sentry, because in this case 404 is not an error.
      // 404 indicates that this promotion is not available or has ended.
      Error(err);
    }
  }

  fetchEECPromotionInitialData() {
    const { fetchEECCPromotionInitialData, entity, entityId } = this.props;
    if (!this.initialPromoCalled) {
      if (entity && entity.context && entity.context === 'incentiveContract') {
        fetchEECCPromotionInitialData({
          promotions: [{ promotion_id: entityId }],
          order_context: {},
        });
        this.initialPromoCalled = true;
      } else if (!entity) {
        this.initialPromoCalled = false;
      }
    }
  }

  async manageDownloadEECCPdf(ev) {
    const { dispatch, user } = this.props;
    const { processId } = user.EECC.promotion;
    ev.preventDefault();
    if (isWebView()) {
      await bridge.sendPromotionDocumentDownload({
        process_id: processId,
        nonce: user.credentials.nonce,
      })
        .then((response) => dispatch(updateCredentials(response)));
    } else {
      const format = 'pdf';
      const pdfData = await dispatch(send(new EECCPromotionsDocumentRequest(
        user.credentials.msisdn,
        processId,
        { format },
      )));

      if (pdfData.status === 200 && process.browser) {
        const blob = new window.Blob([pdfData.body.blob], { type: `application/${format};` }); //eslint-disable-line
        const fileName = 'Vertragszusammenfassung';
        FileSaver.saveAs(blob, `${fileName}.${format}`);
      }
    }
  }

  render() {
    const { entity, location } = this.props;
    const entityDetails = this.getEntityDetails();
    const isLoaded = !!entityDetails && !!entity;
    return (
      <>
        {entityDetails && entityDetails.modules && entityDetails.modules.map(module => (
          <GlobalModule
            module={module}
            location={location}
            primaryModule={false}
            key={module.eid}
          />
        ))}
        <GlobalSection layout="contained" adjacent>
          <ContentLoader isLoaded={isLoaded} height={380}>
            {entityDetails
              && (
              <MyPromoDetailsComposition
                {...entityDetails}
                entity={entity}
                onDownloadDocument={this.manageDownloadEECCPdf}
              />
)}
          </ContentLoader>
        </GlobalSection>
        {entityDetails && entityDetails.legal
          && (
          <GlobalSection>
            <Copy embedded raw>{entityDetails.legal}</Copy>
          </GlobalSection>
)}
      </>
    );
  }
}

MyPromoDetails.propTypes = {
  entityId: PropTypes.string,
  entityDetailsId: PropTypes.string,
  entity: PropTypes.object,
  entityDetails: PropTypes.object,
  sitemap: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  fetchEntityById: PropTypes.func.isRequired,
  bookPromotion: PropTypes.func.isRequired,
  fireContextAction: PropTypes.func.isRequired,
  isAppView: PropTypes.bool.isRequired,
  user: PropTypes.object,
  fetchEECCPromotionInitialData: PropTypes.func,
  dispatch: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => {
  const { entities, site, user } = state;
  const { location } = ownProps;
  const { sitemap } = site;
  const entityId = location.query[QUERY_INTERNAL_ID];
  const entityDetailsId = location.query[QUERY_ENTITY_ID];
  const entity = entityId
    ? Object.values(entities.promotionVO).find(entry => entry.id === entityId)
    : null;
  const entityDetails = entityDetailsId ? entities.myPromoDetails[entityDetailsId] : null;
  return {
    entityId,
    entity,
    entityDetailsId,
    entityDetails,
    sitemap,
    location,
    isAppView: !!site.appView,
    user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchEntityById: (id) => dispatch(registryActions.fetchEntityById(id)),
  bookPromotion: (promo) => dispatch(statusTariffActions.bookPromotion(promo)),
  trackMyState: () => dispatch(trackingActions.trackMyState()),
  fireContextAction: (name) => dispatch(getContextAction(name)),
  fetchEECCPromotionInitialData: (data) =>
    dispatch(registryActions.fetchEECCPromotionInitialData(data)),
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(MyPromoDetails);
