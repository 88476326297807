import QueueableRequest from './QueueableRequest';
import { ACCOUNT_NOTIFICATIONS_ENDPOINT } from '../../config/api';
import { bindParamsToRoute } from '../../helpers/url';
import { blockContentBasedOnUserScope } from '../../helpers/scope';
import { SCOPE_NOTIFICATIONS } from '../../helpers/constants';

export default class TariffNotificationRequest extends QueueableRequest {
  constructor(msisdn, notificationId) {
    const url = bindParamsToRoute(ACCOUNT_NOTIFICATIONS_ENDPOINT, { msisdn, notificationId });
    super(url, { priority: QueueableRequest.PRIO_0 });
    this.notificationId = notificationId;
  }

  block(state) {
    return !blockContentBasedOnUserScope(state.user.scope, SCOPE_NOTIFICATIONS);
  }
}
