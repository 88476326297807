/* global window */

import MaintenanceError from '../../model/errors/MaintenanceError';
import ApiError from '../../model/errors/ApiError';
import LoginRequest from '../../model/requests/LoginRequest';
import PingRequest from '../../model/requests/PingRequest';
import { send } from '../request/send';
import { logout } from '../user/logout';

/**
 * Depending on the criticalness of the error and where it occurred, we either
 * shut the whole page down or just the account area.
 *
 * In any case, the user is logged out.
 *
 * @param {AbstractError} error
 */
export const activateMaintenanceMode = (error) => async (dispatch, getState) => {

  const { user } = getState();
  if (user.credentials.msisdn) {
    // always(!) logout if user is logged in
    await dispatch(logout({ error: new MaintenanceError(error) }));
  }

  if (!error.request.isCritical() && error instanceof ApiError) {
    const { request, fullResponse } = error;
    const { status } = fullResponse;
    // note: the login response will return 503 if account is closed
    const isAccountMaintainanceMode = (
      status === 503 && (request instanceof LoginRequest || request.isSecure())
    );

    if (isAccountMaintainanceMode) {
      // send ping to determine whether non-secure area (=presales) is also in
      // maintenance, which would mean that we are in full maintenance mode.
      dispatch(send(new PingRequest()));
      return;
    }
  }

  if (process.browser) {
    // trigger the static maintenance page
    window.dbn.open('/503.html');
  }
};
