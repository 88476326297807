import RegistrationForm from '../../../components/compositions/account/registration/RegistrationForm';
import IllogicalResponseError from '../../../model/errors/IllogicalResponseError';
import initForm from '../../form/FormInitializer';
import { showAccountExists } from '../../../actions/dialog/registrationActions';
import PasswordResetOptionsRequest from '../../../model/requests/PasswordResetOptionsRequest';
import { MYTRACK_LOGIN_CHECK_MSISDN } from '../../../helpers/constants';
import { send } from '../../../actions/request/send';
import FormConfigProps from '../../../model/form/FormConfigProps';


const createFieldMap = ui => ({
  msisdn: {
    name: 'msisdn',
    shouldSubmit: false,
    label: ui.guiFormMsisdn,
    type: 'tel',
    validation: {
      isRequired: true,
      pattern: 'phone',
      range: [7, 25],
    },
  },
});

export const id = 'msisdn';

/**
 * If the user has more than one reset options, we will display a next step in which
 * he she is able to choose one of the possible reset options.
 *
 * Otherwise, we immediately tell the server to create a reset token.
 */
const makeSubmit = (stepConfig, props) => async (values, dispatch) => {
  const { fieldMap, formValues } = props;
  const msisdn = values[fieldMap.msisdn.name];
  const response = (await dispatch(send(new PasswordResetOptionsRequest(msisdn)))).body.data;

  const {
    sms,
    email,
  } = response;

  // we can only reset a password if email or sms is true (this should always be the case!).
  if (email || sms) {
    dispatch(showAccountExists(formValues[fieldMap.msisdn.name]));
  } else {
    throw new IllogicalResponseError();
  }
  // in every case we stay on this page, in case of account
  // exists we show a dialog from which the user can jump
  // to the password reset form, but it's not triggered here.
  return id;
};

// init component once, outside
const component = initForm()(RegistrationForm);

/**
 * @return {FormConfig}
 */
export const mapStateToFormConfig = (state, props) => ({
  component,
  fieldMap: createFieldMap(props.ui),
  makeSubmit,
  formConfigProps: new FormConfigProps({
    dataSubmit: { mytracking: MYTRACK_LOGIN_CHECK_MSISDN },
  }),
});
