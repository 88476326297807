import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

import suitcss from '../../../../helpers/suitcss';
import connectUI from '../../../basics/ui/UIConnector';
import Copy from '../../../basics/text/TextCopy';
import FormHeader from '../../form/FormHeader';
import * as constants from '../../../../helpers/constants';
import SvgLoader from '../../../basics/media/MediaSvgLoader';
import Link from '../../../basics/text/TextLink';
import { showDialog } from '../../../../actions/page/dialog';

class ESimReplacementFormActivationStyle extends PureComponent {

  render() {
    const {
      market,
      ui,
      dispatch,
      isPrepaid,
      sitemap,
      selectedSimOption,
    } = this.props;

    const isPostpaid = market === constants.MARKET_POSTPAID;
    const insufficientBalance = selectedSimOption.sufficientBalance === false;

    const dialogEsimProfile = (ev) => {
      ev.preventDefault();
      dispatch(showDialog({
        headline: ui.mySimrepEsimProfileHeadline,
        copy: ui.mySimrepEsimHowtoOrderDialogCopy,
        actions: [{ label: ui.guiWordAllright }],
      }));
    };

    return (
      <Fragment>
        {isPrepaid && insufficientBalance &&
        <div className={suitcss({ descendantName: 'warning', utilities: ['col12'] }, this)}>
          <Copy utilities={['colorError']} raw>{ui.txtMySimrepSimtypeBalanceTopup}</Copy>
          <Link to={sitemap.MyDashboardRoute.url} asButton>
            {ui.mySimrepBalanceTopupButton}
          </Link>
        </div>
        }
        {!insufficientBalance &&
          <div className={suitcss({}, this)}>
            <div>
              <FormHeader headline={ui.mySimrepEsimHowtoHeadline} utilities={['marginBottomS']} />
              <div className={suitcss({ utilities: ['row', 'marginTopXS'] }, this)}>
                <div className={suitcss({ utilities: ['col8', 'smCol12', 'flex', 'itemsCenter', 'marginBottom'] }, this)}>
                  <SvgLoader path="/files/icons/sim-replacement/icon-shoppingcart.svg" />
                  <div className={suitcss({ utilities: [''] }, this)}>
                    <Copy utilities={['weightBold', 'block']} embedded={isPostpaid} raw>
                      {ui.mySimrepEsimHowtoOrderHeadline}
                    </Copy>
                    <Copy utilities={['inline', 'marginRightXS']} embedded={isPostpaid} raw>
                      {ui.mySimrepEsimHowtoOrderCopy}
                    </Copy>
                    <Link
                      element="button"
                      withoutArrow
                      withoutStyle
                      onClick={dialogEsimProfile}
                      utilities={['alignMiddle']}
                    >
                      <img className={suitcss({ utilities: ['alignBaseline'] }, this)} src="/files/icons/info-i/info-icon.svg" alt="info-i" width="16px" height="16px" />
                    </Link>
                  </div>
                </div>
                <div className={suitcss({ utilities: ['col8', 'smCol12', 'flex', 'itemsCenter', 'marginBottom'] }, this)}>
                  <SvgLoader path="/files/icons/sim-replacement/email-received.svg" />
                  <div className={suitcss({ utilities: [''] }, this)}>
                    <Copy utilities={['weightBold', 'block']} embedded={isPostpaid} raw>
                      {ui.mySimrepEsimHowtoReadyHeadline}
                    </Copy>
                    <Copy embedded={isPostpaid} raw>
                      {ui.mySimrepEsimHowtoReadyCopy}
                    </Copy>
                  </div>
                </div>
                <div className={suitcss({ utilities: ['col8', 'smCol12', 'flex', 'itemsCenter'] }, this)}>
                  <SvgLoader path="/files/icons/sim-replacement/icon-login.svg" />
                  <div className={suitcss({ utilities: [''] }, this)}>
                    <Copy utilities={['weightBold', 'block']} embedded={isPostpaid} raw>
                      {ui.mySimrepEsimHowtoInstallHeadline}
                    </Copy>
                    <Copy embedded={isPostpaid} raw>
                      {ui.mySimrepEsimHowtoInstallCopy}
                    </Copy>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </Fragment>
    );
  }
}

ESimReplacementFormActivationStyle.propTypes = {
  fieldMap: PropTypes.shape({
    shipActivatedCard: PropTypes.object,
  }).isRequired,
  market: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  isPrepaid: PropTypes.bool,
  sitemap: PropTypes.object,
  selectedSimOption: PropTypes.object,
  ui: PropTypes.shape({
    mySimrepEsimHowtoInstallCopy: PropTypes.string,
    mySimrepEsimHowtoInstallHeadline: PropTypes.string,
    mySimrepEsimHowtoReadyCopy: PropTypes.string,
    mySimrepEsimHowtoReadyHeadline: PropTypes.string,
    mySimrepEsimHowtoOrderCopy: PropTypes.string,
    mySimrepEsimHowtoOrderHeadline: PropTypes.string,
    mySimrepEsimHowtoHeadline: PropTypes.string,
    mySimrepDeviceCheckerLinkname: PropTypes.string,
    mySimrepDeviceCheckerHeadline: PropTypes.string,
    mySimrepChooseSimtypeOptionEsim: PropTypes.string,
    mySimrepChooseSimtypeOptionClassic: PropTypes.string,
    mySimrepChooseSimtypeHeadline: PropTypes.string,
    mySimrepChooseSimtypeOptionCosts: PropTypes.string,
    mySimrepEsimProfileHeadline: PropTypes.string,
    mySimrepEsimHowtoOrderDialogCopy: PropTypes.string,
    guiWordAllright: PropTypes.string,
    txtMySimrepSimtypeBalanceTopup: PropTypes.string,
    mySimrepBalanceTopupButton: PropTypes.string,
  }).isRequired,
};

export default connectUI()(ESimReplacementFormActivationStyle);
