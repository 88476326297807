import PageModule from './PageModule';
import component from '../../containers/tariff/TariffDetail';

/**
 * Displays detailed information on a tariff product.
 * @see https://confluence.db-n.com/x/lpTc
 */
class TariffDetailModule extends PageModule {
  constructor(moduleData) {
    super(component, moduleData);
  }
}

export default TariffDetailModule;
