import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  registerFootnoteReference,
  unregisterFootnoteReference,
} from '../../actions/legal/footnote';
import { stripHTML } from '../../helpers/str';
import { getVisibleLegals } from '../../components/compositions/footer/FooterLegals';

/**
 * Returns the eid the legal text is targeting or null if the legal text is generic
 */
export const getLegalTarget = legal => legal.tariffEid || legal.tariffOptionEid;

/**
 * Returns the symbol that visually identifies the footnote.
 *
 * If a footnote reference with the specified id exists and a legal
 * text is present, the reference symbol will be an incremented number.
 * Otherwise, null is returned.
 *
 * @param {array} footnotes - list of footnotes visible on the page
 * @param {object} refLegal - the referenced footnote
 * @param id - an id, e.g. an entity id
 * @param {boolean} hasLegalText
 * @param {string} defaultSymbol
 * @returns {string}
 */
const getRefSymbol = (footnotes = [], refLegal, id, hasLegalText, defaultSymbol) => {
  const footnoteIndex = footnotes.findIndex(footnote =>
    !!getLegalTarget(footnote) && getLegalTarget(footnote) === id && footnote === refLegal);

  if (!hasLegalText || footnoteIndex === -1) {
    return defaultSymbol;
  }

  return String(footnoteIndex + 1);
};

/**
 * A footnote symbol is a symbol that represents and
 * identifies a footnote, e.g. an asterisk (*) or an index (1).
 *
 * The decision, which symbol to display is based on
 * the specified `id` prop.
 *
 * An id may be e.g. an entity's eid.
 *
 * For a usage example see the example provided in {@link FootnoteReference}.
 */
const FootnoteSymbol = props => {
  const { refSymbol, legalText } = props;

  return (
    <sup title={legalText}>({refSymbol})</sup>
  );
};

FootnoteSymbol.propTypes = {
  /**
   * The id used to identify the footnote reference in the store.
   */
  id: PropTypes.string,
  /**
   * The symbol that visually identifies the footnote. This is
   * either an incremented number or a default symbol.
   */
  refSymbol: PropTypes.string.isRequired,
  /**
   * The legal text that should be rendered on mouse over.
   */
  legalText: PropTypes.string,
};

/**
 * Returns a legal text for the footnote id.
 */
const getLegalText = (legals, refLegal) => {

  if (!legals) { // not been fetched yet.
    return '';
  }

  const legal = legals.find(l => l === refLegal);

  if (!legal || !legal.copyWeb) {
    return '';
  }

  return stripHTML(legal.copyWeb);
};

const mapStateToProps = (state, ownProps) => {
  const { id, refLegal } = ownProps;
  const { ui, legal } = state;
  const visibleLegals = getVisibleLegals(state);
  const legalText = getLegalText(legal.legals, refLegal);
  const hasLegalText = Boolean(legalText);
  const refSymbol = getRefSymbol(visibleLegals, refLegal, id, hasLegalText, ui.guiSymbolStar);

  return {
    refSymbol,
    legalText,
  };
};

const mapDispatchToProps = {
  registerFootnoteReference,
  unregisterFootnoteReference,
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(FootnoteSymbol);
