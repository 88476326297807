import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';

import Headline from '../../basics/text/TextHeadline';
import Copy from '../../basics/text/TextCopy';
import TeaserProduct from './TeaserProduct';

import Slider from '../../basics/slider/Slider';
import SliderSlide from '../../basics/slider/SliderSlide';

import matchMediaConnector from '../../../containers/service/ServiceMatchMedia';

class TeaserProducts extends PureComponent {

  static getSliderConfig(type, items, isMediaSM) {
    const maxDefault = type === 'bundle' ? 2 : 5;
    const bundleSpace = isMediaSM ? '13%' : '5%';
    const hardewareSpace = isMediaSM ? 10 : 30;
    const config = {
      CSSWidthAndHeight: true,
      slidesPerView: !isMediaSM ? maxDefault : 'auto',
      centeredSlides: isMediaSM,
      spaceBetween: type === 'bundle' ? bundleSpace : hardewareSpace,
      centeredContent: !isMediaSM && items.length < maxDefault,
    };
    return config;
  }

  renderItems() {
    const { type, items, inverted, isMediaSM } = this.props;
    const sliderConfig = TeaserProducts.getSliderConfig(type, items, isMediaSM);
    return (
      <Slider
        className={suitcss({ descendantName: 'slider', utilities: ['outerContainer', 'col12'] }, this)}
        theme={inverted ? 'dark' : 'light'}
        pagination
        config={sliderConfig}
        inverted={inverted}
        arrows={type === 'bundle' && items.length > 2}
      >
        {items.map((item, index) => (
          <SliderSlide key={index}>
            <div
              className={suitcss({ descendantName: 'product' }, this)}
              key={index}
            >
              <TeaserProduct
                type={type}
                name={item.name}
                hardware={item.hardware}
                tariff={item.tariff}
                inverted={inverted}
                isNew={item.hardwareGroup.new}
              />
            </div>
          </SliderSlide>
        ))}
      </Slider>
    );
  }

  render() {
    const { type, headline, footer, primaryModule, inverted } = this.props;
    return (
      <div className={suitcss({ modifiers: [type] }, this)} >
        {headline && (
          <Headline
            className={suitcss({ descendantName: 'headline' }, this)}
            utilities={['globalPaddingH', 'marginBottomXL', 'alignCenter']}
            element={primaryModule ? 'h1' : 'h2'}
            inverted={inverted}
            size="xl"
            embedded
            raw
          >
            {headline}
          </Headline>
        )}
        <div className={suitcss({ descendantName: 'content' }, this)} >
          {this.renderItems()}
        </div>
        {footer && (
          <Copy
            className={suitcss({ descendantName: 'footer' }, this)}
            utilities={['globalPaddingH', 'marginTopXL', 'alignCenter']}
            inverted={inverted}
            element="div"
            size="secondary"
            embedded
            raw
          >
            {footer}
          </Copy>
        )}
      </div>
    );
  }
}

TeaserProducts.propTypes = {
  type: PropTypes.oneOf(['hardware', 'bundle']).isRequired,
  headline: PropTypes.string,
  footer: PropTypes.string,
  primaryModule: PropTypes.bool,
  items: PropTypes.array.isRequired,
  inverted: PropTypes.bool,
  isMediaSM: PropTypes.bool,
};
TeaserProducts.defaultProps = {};

export default matchMediaConnector(['isMediaSM'])(TeaserProducts);
