import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import FormManager from '../form/FormManager';
import * as formConfig from './formConfigEmailRegistration';
import GlobalSection from '../../components/basics/global/GlobalSection';
import { trackMyState } from '../../actions/tracking/event';
import { MYTRACK_EMAIL_REGISTRATION_SUBMIT_SUCCESS } from '../../helpers/constants';
import EmailRegistrationRequest from '../../model/requests/EmailRegistrationRequest';

class EmailRegistrationForm extends PureComponent {

  constructor(props, ...args) {
    super(props, ...args);
    this.onAfterSubmitSuccess = this.onAfterSubmitSuccess.bind(this);
    this.getSubmitRoute = this.getSubmitRoute.bind(this);
    this.onBeforeSubmit = this.onBeforeSubmit.bind(this);
  }

  onAfterSubmitSuccess() {
    const { dispatch } = this.props;
    dispatch(trackMyState(MYTRACK_EMAIL_REGISTRATION_SUBMIT_SUCCESS));
  }

  onBeforeSubmit(fieldMap, normalizedValues) {
    const { location } = this.props;
    return {
      solicitor_id: location && location.query && location.query.solicitorId,
      ...normalizedValues,
    };
  }

  getSubmitRoute(fieldMap, finalizedFormValues) {
    return new EmailRegistrationRequest(finalizedFormValues);
  }

  render() {
    const { location, params, layoutSettings } = this.props;
    const { urlSuccess } = params;
    return (
      <GlobalSection layoutSettings={layoutSettings}>
        <FormManager
          form={EmailRegistrationForm.formName}
          location={location}
          stepProps={this.props}
          onBeforeSubmit={this.onBeforeSubmit}
          submitRoute={this.getSubmitRoute}
          successRoute={urlSuccess}
          onAfterSubmitSuccess={this.onAfterSubmitSuccess}
          steps={[formConfig]}
          withFooter
        />
      </GlobalSection>
    );
  }
}

/**
 * required by tracking!
 */
EmailRegistrationForm.formName = 'email-registration';

EmailRegistrationForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  layoutSettings: PropTypes.object,
  params: PropTypes.shape({
    urlSuccess: PropTypes.string.isRequired,
    headline: PropTypes.string.isRequired,
    copy: PropTypes.string.isRequired,
  }).isRequired,
};

const mapDispatchToProps = dispatch => ({ dispatch });

export default compose(
  connect(
    mapDispatchToProps,
  ),
)(EmailRegistrationForm);

