import PageModule from './PageModule';
import component from '../../containers/hardware/HardwareDetails';

/**
 * This module maps the content including tab navigation on the hardware details pages.
 * @see https://confluence.db-n.com/x/35zc
 */
class HardwareDetailsModule extends PageModule {
  constructor(moduleData) {
    super(component, moduleData);
  }
}

export default HardwareDetailsModule;
