import { compose } from 'redux';

import SubscriptionManagementForm from '../../components/compositions/service/SubscriptionManagementForm';
import initForm from '../form/FormInitializer';
import connectSiteProvider from '../../components/basics/service/ServiceSiteConnector';
import FormConfigProps from '../../model/form/FormConfigProps';

// init component once, outside
const component = compose(
  connectSiteProvider(),
  initForm(),
)(SubscriptionManagementForm);

export const id = 'subscriptionManagement';

/**
 * @return {FormConfig}
 */
export const mapStateToFormConfig = (state, props) => ({
  component,
  fieldMap: {},
  formConfigProps: new FormConfigProps({
    labelSubmit: props.action || state.ui.guiFormSubmit,
  }),
});

export default mapStateToFormConfig;
