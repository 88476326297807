import { initialize, destroy } from 'redux-form';
import { push } from 'react-router-redux';
import PasswordResetForm from '../../containers/account/passwordReset/PasswordResetForm';
import { INITIAL_AUTO_SUBMIT } from '../../containers/form/FormManager';


const initializePasswordResetForm = msisdn => initialize(
  PasswordResetForm.formName,
  {
    msisdn,
    forcedOption: 'sms',
    [INITIAL_AUTO_SUBMIT]: true,
  },
  true,
);
export const gotoPasswordResetAndAutoSubmitWithMsisdn = msisdn => async (dispatch, getState) => {
  const myPasswordReset = getState().site.sitemap.MyPasswordResetFormRoute.url;
  // force a location change if we are already on the password reset page,
  // because we want to trigger autoSubmit which is currently only
  // executed on componentDidMount.
  const location = getState().routing.locationBeforeTransitions;
  const isPasswordResetPageActive = location.pathname.includes(myPasswordReset);

  if (isPasswordResetPageActive) {
    // ugly hack, we have to switch the page because passwordReset autoSubmit (with msisdn)
    // is triggered on componentDidMount, but at this point
    // the passwordResetForm is already mounted. To trigger
    // a new mount we switch to another page '/' and
    // wait until it's loaded, clear the form, initialize it with a msisdn
    // and goto the passwordReset page

    dispatch(push('/'));
    const wait = (resolve, timeElapsed, maxTime) => {
      if (!getState().lifecycle.isLoading || timeElapsed > maxTime) {
        resolve(true);
      } else {
        const delay = 5;
        setTimeout(() => {
          wait(resolve, timeElapsed + delay, maxTime);
        }, delay);
      }
    };

    await dispatch(() => new Promise(resolve => wait(resolve, 0, 5000)));
  }
  dispatch(destroy(PasswordResetForm.formName));
  dispatch(initializePasswordResetForm(msisdn));
  dispatch(push(myPasswordReset));
};
