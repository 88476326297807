/* global document, window */
import React, { PureComponent } from 'react';
import raf from 'raf';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';

const getValidatedPosition = (pos, targetBounds, hintBounds) => {
  const { innerWidth } = window;
  const { left: targetLeft, width: targetWidth } = targetBounds;
  const { width: hintWidth } = hintBounds;
  switch (pos) {
    case 'left':
      if (targetLeft - hintWidth < 0) {
        if (targetLeft + targetWidth + hintWidth < innerWidth) {
          return 'right';
        }
        return 'top';
      }
      break;
    case 'right':
      if (targetLeft + targetWidth + hintWidth > innerWidth) {
        if (targetLeft - hintWidth > 0) {
          return 'left';
        }
        return 'top';
      }
      break;
    default:
      break;
  }
  return pos;
};

// ServiceToolTip - Modified Version of ReactHint https://github.com/slmgc/react-hint
class ServiceToolTip extends PureComponent {

  constructor(props, context) {
    super(props, context);

    this.state = { target: null };
    this._containerStyle = { position: 'relative' };
    this.toggleEvents = this.toggleEvents.bind(this);
    this.toggleHint = this.toggleHint.bind(this);
    this.getTarget = this.getTarget.bind(this);
    this.watchTarget = this.watchTarget.bind(this);
  }

  componentDidMount() {
    this.toggleEvents(this.props, true);
  }

  componentDidUpdate(prevProps, prevState) {
    const { id } = this.props;
    const { target } = this.state;
    const { id: prevTargetId, content: prevContent } = prevState;
    const targetId = target && target.getAttribute(`${id}-id`);
    const content = target && target.getAttribute(id);
    if (content !== prevContent || targetId !== prevTargetId) {
      clearTimeout(this._timeout);
      const hint = target ? this.getTargetData(target) : {};
      this.setState({ ...hint, isWatching: !!hint.id }, hint.id ? this.watchTarget : null); // eslint-disable-line
    }
  }

  componentWillUnmount() {
    this.toggleEvents(this.props, false);
    clearTimeout(this._timeout);
  }

  getTarget(el) {
    const { id } = this.props;
    while (el) {
      if (el === document) break;
      if (el.hasAttribute(id)) return el;
      el = el.parentNode; // eslint-disable-line
    }
    return null;
  }

  getTargetData(target) {
    const { id, position } = this.props;

    const targetBounds = target.getBoundingClientRect();
    const hintBounds = this._hint.getBoundingClientRect();
    const content = target.getAttribute(id) || '';
    const offset = Number(target.getAttribute(`${id}-offset`)) || 0;
    const targetId = target.getAttribute(`${id}-id`);

    const pos = getValidatedPosition(
      target.getAttribute(`${id}-pos`) || position,
      targetBounds,
      hintBounds,
    );

    const {
      top: containerTop,
      left: containerLeft,
    } = this._container.getBoundingClientRect();

    const {
      width: hintWidth,
      height: hintHeight,
    } = this._hint.getBoundingClientRect();

    const {
      top: targetTop,
      left: targetLeft,
      width: targetWidth,
      height: targetHeight,
    } = targetBounds;

    let top;
    let left;
    switch (pos) {
      case 'left':
        top = (targetHeight - hintHeight) >> 1;// eslint-disable-line
        left = -(hintWidth + offset);
        break;

      case 'right':
        top = (targetHeight - hintHeight) >> 1;// eslint-disable-line
        left = targetWidth + offset;
        break;

      case 'bottom':
        top = targetHeight + offset;
        left = (targetWidth - hintWidth) >> 1;// eslint-disable-line
        break;

      case 'top':
      default:
        top = -(hintHeight + offset);
        left = (targetWidth - hintWidth) >> 1;// eslint-disable-line
    }

    return {
      pos,
      content,
      id: targetId,
      top: (top + targetTop) - containerTop,
      left: (left + targetLeft) - containerLeft,
    };
  }

  toggleEvents({ events, events: { click, focus, hover } }, flag) {
    const action = flag ? 'addEventListener' : 'removeEventListener';
    const hasEvents = events === true;
    if (focus || hasEvents) document[action]('focusin', this.toggleHint);
    if (hover || hasEvents) document[action]('mouseover', this.toggleHint);
    if (click || hover || hasEvents) document[action]('touchend', this.toggleHint);
  }

  toggleHint({ target = null } = {}) {
    const nextTarget = this.getTarget(target);
    clearTimeout(this._timeout);
    if (nextTarget) {
      if (this.state.isWatching) {
        const { id } = this.props;
        const { target: prevTarget } = this.state;
        const idKey = `${id}-id`;
        if (!prevTarget || nextTarget.getAttribute(idKey) !== prevTarget.getAttribute(idKey)) {
          this.setState({ isWatching: false });
        }
      }
      this._timeout = setTimeout(() => this.setState(() => ({
        target: nextTarget,
      })), this.props.delay);
    } else {
      this.setState({ id: null, target: null, content: null, isWatching: false });
    }
  }

  watchTarget() {
    const { target, isWatching } = this.state;
    if (isWatching) {
      raf(() => {
        if (target) {
          this.updateTarget();
        }
        this.watchTarget();
      });
    }
  }

  updateTarget() {
    const { id } = this.props;
    const { id: targetId, content: prevContent } = this.state;
    const target = document.querySelector(`[${id}-id=${targetId}]`);
    const content = target && target.getAttribute(id);
    if (content !== prevContent) {
      this.setState({ target: content ? target : null, isWatching: false });
    }
  }

  render() {
    const { target, content, pos, top, left, maxWidth } = this.state;
    return (
      <div
        ref={(ref) => { this._container = ref; }}
        style={this._containerStyle}
      >
        {target &&
          <div
            className={suitcss({ modifiers: [pos] }, this)}
            ref={ref => { this._hint = ref; }}
            style={{ top, left, maxWidth }}
          >
            <div className={suitcss({ descendantName: 'inner' }, this)}>
              <div
                className={suitcss({ descendantName: 'content' }, this)}
                dangerouslySetInnerHTML={{ __html: content }}
              />
              <div className={suitcss({ descendantName: 'tip' }, this)} />
            </div>
          </div>
        }
      </div>
    );
  }
}

ServiceToolTip.propTypes = {
  id: PropTypes.string.isRequired,
  delay: PropTypes.number.isRequired,
  position: PropTypes.oneOf(['top', 'right', 'bottom', 'left']).isRequired,
  events: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object,
  ]).isRequired,
};

ServiceToolTip.defaultProps = {
  id: 'data-tip',
  delay: 0,
  position: 'top',
  events: true,
};

export default ServiceToolTip;
