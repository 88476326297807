import AbstractError from './AbstractError';
import { NOT_IMPLEMENTED_ERROR } from '../../config/errorCodes';

/**
 * Thrown if a subclass does not implement the abstract methods of
 * its parent class.
 */
class NotImplementedException extends AbstractError {
  constructor() {
    super(NOT_IMPLEMENTED_ERROR);
  }
}

export default NotImplementedException;
