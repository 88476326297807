/* global window */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { imagePropTypes } from '../../../propTypes/media';

import MediaImage from './MediaImage';
import IntersectionObserver from '../service/IntersectionObserver';

class MediaImageBackground extends PureComponent {

  constructor(props, context) {
    super(props, context);
    this.applySourceToState = this.applySourceToState.bind(this);
    this.registerImg = this.registerImg.bind(this);
    this.onChange = this.onChange.bind(this);
    this.state = {
      isIntersecting: false,
      src: this.getDefaultSource(),
    };
  }

  componentDidUpdate() {
    this.applyUpdates(true);
  }

  onChange(ev, unobserve) {
    const { isIntersecting } = ev;
    if (isIntersecting) {
      unobserve();
      this.setState({ isIntersecting: true });
    }
  }

  getDefaultSource() {
    // set default source as the smalles image in the list
    const { src, variations } = this.props;
    return MediaImage.getDefaultSource(variations, src);
  }

  registerImg(img) {
    this.img = img;
    if (img) {
      this.applyUpdates();
      this.applySourceToState();
    }
  }

  applyUpdates(reevaluate = false) {
    if (window.picturefill) {
      window.picturefill({ elements: [this.img], reevaluate });
    }
  }

  applySourceToState() {
    let nextSrc;
    // the polyfill will directly set the src, so in case it is set and not the
    // current state, we use it
    if (!this.img.currentSrc && this.img.src !== this.state.src) {
      nextSrc = this.img.src;
    } else {
      nextSrc = this.img.currentSrc;
    }
    if (!nextSrc) {
      return;
    }
    this.setState({
      src: nextSrc || this.getDefaultSource(),
    });
  }

  renderElement(isResponsiveImage) {
    const {
      src,
      sizes,
      variations,
      width,
      alt,
      style,
      className,
      isLazy,
      children,
    } = this.props;

    const { isIntersecting } = this.state;
    const isInView = !isLazy || isIntersecting;

    const extendedStyle = {
      backgroundImage: this.state.src ? `url('${this.state.src}')` : 'none',
      ...style,
    };

    return (
      <div className={className} style={extendedStyle}>
        {children}
        {isResponsiveImage &&
          <img
            style={{ display: 'none' }}
            srcSet={isInView ? MediaImage.getSrcSet(variations, src, width) : null}
            sizes={isInView ? MediaImage.getSizes(sizes) : null}
            src={this.getDefaultSource()}
            alt={alt}
            ref={this.registerImg}
            onLoad={this.applySourceToState}
          />
        }
      </div>
    );
  }

  render() {
    const { isLazy, variations } = this.props;
    const isResponsiveImage = Object.keys(variations).length > 0;
    if (!isLazy || !isResponsiveImage) {
      return this.renderElement(isResponsiveImage);
    }

    return (
      <IntersectionObserver onChange={this.onChange}>
        {this.renderElement(isResponsiveImage)}
      </IntersectionObserver>
    );
  }
}

MediaImageBackground.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.object,
  variations: imagePropTypes.variations,
  src: imagePropTypes.src,
  width: imagePropTypes.width,
  sizes: PropTypes.object,
  alt: PropTypes.string,
  isLazy: PropTypes.bool,
};

MediaImageBackground.defaultProps = {
  variations: {},
  style: {},
};

export default MediaImageBackground;
