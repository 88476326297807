import { compose } from 'redux';
import { connect } from 'react-redux';
import initForm from '../form/FormInitializer';
import { trackMyState } from '../../actions/tracking/event';
import { MYTRACK_CONTACT_SUBMIT_SUCCESS } from '../../helpers/constants';
import FormConfigProps from '../../model/form/FormConfigProps';
import ConsentHandlingForm from '../../components/compositions/consentHandling/ConsentHandlingForm';

const createFieldMap = (ui) => ({
  required: {
    name: 'required',
    label: ui['guiCookiesLi-reqTitle'],
    hint: ui['txtGuiCookiesLi-reqCopyWeb'],
  },
  analytics: {
    name: 'analytics',
    label: ui['guiCookiesLi-optTitle'],
    hint: ui['txtGuiCookiesLi-optCopyWeb'],
  },
  contact: {
    name: 'contact',
    label: ui['guiCookiesLi-omTitle'],
    hint: ui['txtGuiCookiesLi-omCopyWeb'],
  },
});

const mapStateToProps = ({ user }) => ({
  initialValues: {
    required: user.gdprPermissions['li-opt'],
    analytics: user.gdprPermissions['li-opt'],
    contact: user.gdprPermissions['li-om'],
  },
});

const component = compose(
  connect(mapStateToProps),
  initForm(),
)(ConsentHandlingForm);

export const id = 'consent';

const makeSubmit = () => async (values, dispatch) => {
  dispatch(trackMyState(MYTRACK_CONTACT_SUBMIT_SUCCESS));
};

/**
 * @return {FormConfig}
 */
export const mapStateToFormConfig = (state, props) => ({
  id,
  component,
  fieldMap: createFieldMap(props.ui),
  makeSubmit,
  formConfigProps: new FormConfigProps({
    labelSubmit: props.label || state.ui.guiWordSave,
  }),
});

export default mapStateToFormConfig;
