
import { OPEN_MENU, CLOSE_MENU } from '../actions/page/menu';
import { REQUEST_RECEIVED_RESPONSE } from '../actions/request/basic';
import {
  MENU_IDENTIFIER_MAIN,
  MENU_IDENTIFIER_ACCOUNT,
  MENU_IDENTIFIER_FOOTER,
  MENU_IDENTIFIER_META,
  MENU_IDENTIFIER_CART,
} from '../helpers/constants';
import MenuRequest from '../model/requests/MenuRequest';

const defaultState = {
  [MENU_IDENTIFIER_MAIN]: { isOpen: false, standalone: true, isBlocking: false },
  [MENU_IDENTIFIER_ACCOUNT]: { isOpen: false, standalone: true, isBlocking: false },
  [MENU_IDENTIFIER_CART]: { isOpen: false, standalone: true, isBlocking: false },
  [MENU_IDENTIFIER_FOOTER]: { isOpen: false, standalone: false, isBlocking: false },
  [MENU_IDENTIFIER_META]: { isOpen: false, standalone: false, isBlocking: false },

};

export default (state = defaultState, action) => {
  switch (action.type) {
    case REQUEST_RECEIVED_RESPONSE: {
      const { request } = action.meta;
      if (request instanceof MenuRequest) {
        return {
          ...state,
          [request.identifier]: {
            ...state[request.identifier],
            items: [...action.payload.response.body.data.tree],
          },
        };
      }
      return state;
    }
    case OPEN_MENU:
    case CLOSE_MENU:
      return {
        ...state,
        [action.meta.identifier]: {
          ...state[action.meta.identifier],
          ...action.payload,
        },
      };
    default:
      return state;
  }
};
