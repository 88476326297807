import QueueableRequest from './QueueableRequest';
import { PASSWORD_RESET_ENDPOINT } from '../../config/api';
import { REQUEST_METHOD_POST } from '../../helpers/constants';

export default class PasswordResetRequest extends QueueableRequest {
  constructor(payload) {
    super(PASSWORD_RESET_ENDPOINT, {
      method: REQUEST_METHOD_POST,
      payload,
    });
  }
}
