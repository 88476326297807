/* global window */
import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import mapObj from 'map-obj';
import traverse from 'traverse';

import { firedTracking } from '../../actions/tracking/event';
import { prefixNumber, decamelize } from '../../helpers/str';
import { devWarning, devInfo } from '../../helpers/meta';
import { sendTrackingData } from '../../services/bridge';

const reg = /<.*>/gm;

export const sanitizeValues = (pixel) => {
  traverse(pixel).forEach(function (val) {
    if (typeof val === 'string') {
      this.update(val.replace(reg, ''));
    }
  });
  return pixel;
};

const convertTrackingKeys = (pixel) => {
  const utagPixel = Object.assign({}, pixel);
  // keys that should not be send to the tracking service
  delete utagPixel.method;
  delete utagPixel.id;

  // convert keys to snakecase and additionally convert keys with prefixNumber transform
  return mapObj(
    utagPixel,
    (key, val) => [prefixNumber(decamelize(key), '_'), val],
    { deep: true },
  );
};

const prefixTrackingKeys = (obj, prefix) => {
  return Object.keys(obj).reduce(
    (acc, key) => ({
      ...acc,
      ...{ [prefix + key]: obj[key] },
    }),
    {});
};

const trackEvent = (type, pixel, isAppView) => {
  if (isAppView) {
    sendTrackingData({ type, pixel });
    return;
  }
  const advEvent = window.adv_event || (window.adv_event = []);
  advEvent.push([type, pixel]);
};

export default class TrackingFire extends PureComponent {
  componentDidMount() {
    this.fire();
  }

  fire() {
    const { dispatch, pplus, isAppView, pixel, doNotTrackPage } = this.props;
    const utagPixel = sanitizeValues(convertTrackingKeys(pixel));

    if (pplus || doNotTrackPage) {
      return;
    }
    switch (pixel.method) {
      case 'view': {
        trackEvent('pageview', utagPixel, isAppView);
        break;
      }
      case 'link': {
        trackEvent('link click', utagPixel, isAppView);
        break;
      }
      case 'dialog': {
        trackEvent('dialog', prefixTrackingKeys(utagPixel, 'dialog_'), isAppView);
        break;
      }
      case 'cart_remove': {
        trackEvent('remove from cart', utagPixel, isAppView);
        break;
      }
      case 'filter_products': {
        trackEvent('filter products', utagPixel, isAppView);
        break;
      }
      case 'error': {
        trackEvent('error', utagPixel, isAppView);
        break;
      }
      default: {
        devWarning('utag() uknown pixel method', pixel);
      }
    }
    dispatch(firedTracking(pixel.id));
    devInfo('Tracking:', pixel.method, utagPixel);
  }

  render() {
    return null;
  }
}

TrackingFire.propTypes = {
  pixel: PropTypes.shape({
    id: PropTypes.string,
    method: PropTypes.oneOf(['view', 'link', 'dialog', 'cart_remove', 'error', 'filter_products']),
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  pplus: PropTypes.bool.isRequired,
  doNotTrackPage: PropTypes.bool,
  isAppView: PropTypes.bool.isRequired,
};
