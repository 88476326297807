import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import * as nps from './formConfigNps';
import { DAY } from '../../helpers/date';
import {
  QUERY_NPS_CREATION_DATE,
  QUERY_NPS_OPTPARAM,
  QUERY_NPS_OPTPARAM_LEGACY,
  QUERY_NPS_SOLICITOR_ID,
} from '../../helpers/constants';
import NpsRequest from '../../model/requests/NpsRequest';
import GlobalSection from '../../components/basics/global/GlobalSection';
import FormManager from '../form/FormManager';
import { hideDialog, showDialog } from '../../actions/page/dialog';

const getAnswerReason = (field, formValues) => {
  if (formValues[field.name] !== 'Nein') { return null; }
  const reason = formValues[`${field.name}_reason`];
  const reasonText = reason === 'Sonstiges' ? formValues[`${field.name}_reason_text`] || 'Sonstiges' : null;
  return reasonText || reason;
};

/**
 * NetPromoterScore feedback form
 */
class NpsForm extends PureComponent {
  constructor(...args) {
    super(...args);
    // early bindings
    this.onBeforeSubmit = this.onBeforeSubmit.bind(this);
    this.getSubmitRoute = this.getSubmitRoute.bind(this);
    this.onAfterSubmitSuccess = this.onAfterSubmitSuccess.bind(this);
  }

  componentDidMount() {
    const { params, location, dispatch } = this.props;
    const { optionType, expirationDays, urlFailure: urlExpired } = params;

    if (optionType === 'tnps' && urlExpired && expirationDays) {
      const dateNow = Date.now().toString();
      const currentDate = Number(dateNow.substring(0, dateNow.length - 3));
      const expirationDate =
        Number(location.query[QUERY_NPS_CREATION_DATE]) + (expirationDays * DAY);
      if (expirationDate - currentDate < 0 || !location.query[QUERY_NPS_CREATION_DATE]) {
        dispatch(push(urlExpired));
      }
    }
  }

  async onBeforeSubmit(fieldMap, normalizedValues, formValues) {
    const { location } = this.props;

    return {
      optionParam: location.query[QUERY_NPS_OPTPARAM]
        || location.query[QUERY_NPS_OPTPARAM_LEGACY]
        || location.query[QUERY_NPS_SOLICITOR_ID],
      addDate: location.query[QUERY_NPS_CREATION_DATE],
      answer: Object.values(fieldMap).map(field => ({
        question: field.label,
        answer: formValues[field.name] || '',
        reason: getAnswerReason(field, formValues) || '',
      })),
    };
  }

  onAfterSubmitSuccess() {
    const { ui, sitemap, dispatch, location } = this.props;
    if (location.pathname === sitemap.NpsFormTnpsRoute.url) {
      dispatch(showDialog({
        headline: ui.npsFormSuccessHeadline,
        copy: ui.npsFormSuccessCopy,
        actions: [
          {
            label: ui.guiHomeButton,
            action: async () => {
              dispatch(push('/'));
              dispatch(hideDialog());
            },
          },
          {
            label: ui.guiWordBack,
            action: async () => {
              dispatch(push(sitemap.FaqRoute.url));
              dispatch(hideDialog());
            },
            withoutArrow: true,
          },
        ],
      }));
    }
  }

  getSubmitRoute(fieldMap, finalizedFormValues) {
    const { params } = this.props;
    return new NpsRequest(params.optionType, finalizedFormValues);
  }

  render() {
    const { params, dispatch, location, sitemap, previousLocation } = this.props;
    const onCancel = location.pathname === sitemap.NpsFormTnpsRoute.url
      ? () => dispatch(push('/'))
      : () => dispatch(push(previousLocation ? previousLocation.pathname : '/'));
    const successRoute = location.pathname === sitemap.NpsFormTnpsRoute.url
      ? sitemap.FaqRoute.url : params.urlSuccess;

    return (
      <GlobalSection>
        <FormManager
          form={NpsForm.formName}
          submitRoute={this.getSubmitRoute}
          successRoute={successRoute}
          onAfterSubmitSuccess={this.onAfterSubmitSuccess}
          onCancel={onCancel}
          onBeforeSubmit={this.onBeforeSubmit}
          steps={[nps]}
          stepProps={this.props}
          withFooter
        />
      </GlobalSection>
    );
  }
}

/**
 * required by tracking!
 */
NpsForm.formName = nps.id;

NpsForm.propTypes = {
  params: PropTypes.shape({
    fields: PropTypes.array.isRequired,
    urlSuccess: PropTypes.string.isRequired,
    urlFailure: PropTypes.string,
    expirationDays: PropTypes.number,
    optionType: PropTypes.string,
  }),
  location: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  ui: PropTypes.object.isRequired,
  sitemap: PropTypes.object.isRequired,
  previousLocation: PropTypes.object,
};

const mapDispatchToProps = dispatch => ({ dispatch });

const mapStateToProps = state => {
  const { site, routing } = state;
  return ({
    ui: state.ui,
    sitemap: site.sitemap,
    locationBeforeTransitions: routing.locationBeforeTransitions,
    previousLocation: site.routing.previousLocation,
  });
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NpsForm);
