import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';
import { capitalize } from '../../../helpers/str';

/**
 * @param  {['even', 'odd']} alternateBackground
 * describes if and how children backgrounds should alternate
 */
class Tab extends PureComponent {
  render() {
    return (
      <div
        className={suitcss({
          componentName: 'Tab',
          modifiers: [
            this.props.alternateBackground && `alternateBackground${capitalize(this.props.alternateBackground)}`,
          ],
        })}
      >
        {React.Children.map(this.props.children, child => (
          <div
            className={suitcss({
              componentName: 'Tab',
              descendantName: 'contentBlock',
            })}
          >
            {child}
          </div>
        ))}
      </div>
    );
  }
}

Tab.propTypes = {
  children: PropTypes.any,
  alternateBackground: PropTypes.oneOf(['even', 'odd']),
};

export default Tab;
