import PageModule from './PageModule';
import component from '../../containers/account/MyPromoBannerRotation';
import { fetchPromoBanners } from '../../actions/request/registry';

class MyPromoBannerRotationModule extends PageModule {
  constructor(moduleData) {
    super(component, moduleData);
  }

  prepare(pathname, page) {
    return async (dispatch) => {
      await dispatch(super.prepare(pathname, page));
      await dispatch(fetchPromoBanners());
    };
  }

  /**
   * @override
   */
  static isCritical() {
    return false;
  }
}

export default MyPromoBannerRotationModule;
