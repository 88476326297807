import QueueableRequest from './QueueableRequest';
import { ACCOUNT_EMAIL_VALIDATION_ENDPOINT } from '../../config/api';
import { bindParamsToRoute } from '../../helpers/url';

export default class EmailValidationRequest extends QueueableRequest {
  constructor(msisdn, options) {
    super(bindParamsToRoute(ACCOUNT_EMAIL_VALIDATION_ENDPOINT, { msisdn }), options);
  }

  getSubsequentRequests({ user }) {
    return [
      new EmailValidationRequest(user.credentials.msisdn),
    ];
  }
}
