import iban from 'iban';

import FormValidationResult from '../model/form/FormValidationResult';
import FieldErrorEntry from '../model/form/FieldErrorEntry';
import {
  INLINE_ERROR_VALUE_MISSING,
  INLINE_ERROR_VALUE_MISMATCH,
  INLINE_ERROR_VALUE_RANGE_MISMATCH,
  INLINE_ERROR_VALUE_PATTERN_MISMATCH,
  INLINE_ERROR_VALUE_EQUALITY_MISMATCH,
  INLINE_ERROR_VALUE_AGE_UNDERFLOW,
  INLINE_ERROR_VALUE_AGE_OVERFLOW,
  INLINE_ERROR_VALUE_CUSTOM_RULE,
} from './constants';

export const hasValue = value =>
  (value !== null) &&
  (value !== undefined) &&
  (String(value).trim() !== '');

export const isInRange = (value, [min, max]) => {
  if (min !== undefined && min !== null) {
    if (typeof value === 'number' && value < min) {
      return false;
    }
    if (value.length < min) {
      return false;
    }
  }
  if (max !== undefined && max !== null) {
    if (typeof value === 'number' && value > max) {
      return false;
    }
    if (value.length > max) {
      return false;
    }
  }
  return true;
};

// for pattern definition see https://confluence.db-n.com/display/OTELO/Businessregeln+der+neuen+otelo.de
const defaultPatterns = {
  // https://confluence.db-n.com/x/M5Ww
  // https://jira.db-n.com/browse/OT-6724
  // eslint-disable-next-line no-useless-escape
  // password: /^(?=.*[A-Za-z])(?=.*[\d\\\/\?\!\$\%\&\=\[\]\(\)\{\}\*\+\,\.\:\<\>\@\€\-\_])
  // [A-Za-z\d#\\\/\?\!\$\%\&\=\[\]\(\)\{\}\*\+\,\.\:\<\>\@\€\-\_]{8,32}$/,
  email: /^[A-Za-z0-9._%+-]{2,}@[A-Za-z0-9.-]{2,}\.[A-Za-z]{2,}$/,
  // YYYY-MM-DD
  date: /^[1-2][0-9]{3}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[1-2][0-9]|3[0-1])$/,
  iban: iban.isValid.bind(iban),
  bic: /^[a-zA-Z]{4}[a-zA-Z]{2}[a-zA-Z0-9]{2}([a-zA-Z0-9]{3})?$/,
  card: /^[A-Za-z0-9]+$/,
  // accept phone numbers starting with 0 / 0049 / +49 containing whitespaces
  phone: /^(\+?49|0049|0)[\d\s]+\d$/,
  // five numbers
  zip: /^\d{5}$/,
  simNumber: /^\d{14}$/,
};

export const matchesPattern = (value, p, state) => {
  const pattern = typeof p === 'string' ?
    defaultPatterns[p] :
    p;

  // if no default pattern exists check for the patterns from the endpoint
  if (!pattern) {
    const val = state.site.validation && state.site.validation.find(entry => entry.id === p);
    if (!val) {
      return true;
    }
    const re = new RegExp(val.constraints[0].pattern, 'i');
    return re.test(value);
  }
  if (Object.prototype.toString.call(pattern) === '[object RegExp]') {
    return pattern.test(value);
  }

  if (typeof pattern === 'function') {
    return pattern(value);
  }

  throw new TypeError(`Pattern \`${p}\` of type \`${typeof pattern}\` is not accepted.`);
};

const getInputDateUTC = (value) => {
  let date = value;
  if (typeof value === 'string') {
    date = new Date(value);
  }
  // reset timezone
  date.setHours(0, date.getTimezoneOffset() * -1, 0, 0);
  const inputYear = date.getFullYear();
  const inputMonth = date.getMonth();
  const inputDay = date.getDate();
  return Date.UTC(inputYear, inputMonth, inputDay);
};

const getAgeUTC = (age, now = new Date()) => {
  // reset timezone
  now.setHours(0, now.getTimezoneOffset() * -1, 0, 0);
  const ageYear = now.getFullYear() - parseInt(age, 10);
  const ageMonth = now.getMonth();
  const ageDay = now.getDate();
  return Date.UTC(ageYear, ageMonth, ageDay);
};

/**
 * Checks whether the given date is under a given min age or not
 * @param  {string|date}   value  If string is must be in the form of YYYY-MM-DD.
 * @param  {number|string} minAge
 * @param  {date}          now    The date to calculate the age from. Default is
 *                                the current date of the os.
 * @return {Boolean}
 */
export const matchesMinAge = (value, minAge, now = new Date()) =>
  getInputDateUTC(value) <= getAgeUTC(minAge, now);

/**
 * Checks whether the given date is above a given max age or not.
 * @param  {string|date}   value  If string is must be in the form of YYYY-MM-DD.
 * @param  {number|string} maxAge
 * @param  {date}          now    The date to calculate the age from. Default is
 *                                the current date of the os.
 * @return {Boolean}
 */
export const matchesMaxAge = (value, maxAge, now = new Date()) =>
  getInputDateUTC(value) > getAgeUTC(parseInt(maxAge, 10) + 1, now);

export const equals = (value, others) =>
  others.every(other => other === value);

/**
 * Compares two values and returns true if they are equal, false if not
 */
export const valueEquals = (value, equalValue) => value === equalValue;

/**
 * @return {null|string} Returns null if field value is valid or the error type
 *     in case validation failed.
 */
const validateOne = (field, fieldMap, formValues, state) => {
  const { validation } = field;
  if (!validation) {
    return;
  }

  const fieldValue = formValues[field.name];

  // when dependsOn is set, we only continue validatation if the dependency is met
  if (validation.dependsOn) {
    // quit validation if not at least one of the fields (and values) provided in dependsOn
    // are currently present in the form
    const matches = Object.entries(validation.dependsOn).some(
      ([dependentKey, dependentValue]) => (
        Array.isArray(dependentValue)
          ? dependentValue.some(val => formValues[fieldMap[dependentKey].name] === val)
          : formValues[fieldMap[dependentKey].name] === dependentValue
      ),
    );
    if (!matches) {
      return null;
    }
  }

  if (hasValue(fieldValue)) {

    if (validation.rule && !validation.rule(fieldValue)) {
      return INLINE_ERROR_VALUE_CUSTOM_RULE;
    }

    if (validation.range && !isInRange(fieldValue, validation.range)) {
      return INLINE_ERROR_VALUE_RANGE_MISMATCH;
    }

    if (validation.pattern && !matchesPattern(fieldValue, validation.pattern, state)) {
      return INLINE_ERROR_VALUE_PATTERN_MISMATCH;
    }

    if (validation.minAge && !matchesMinAge(fieldValue, validation.minAge)) {
      return INLINE_ERROR_VALUE_AGE_UNDERFLOW;
    }

    if (validation.maxAge && !matchesMaxAge(fieldValue, validation.maxAge)) {
      return INLINE_ERROR_VALUE_AGE_OVERFLOW;
    }

    if (validation.equals) {
      const otherValues = validation.equals.map(fieldName => formValues[fieldName]);
      if (!equals(fieldValue, otherValues)) {
        return INLINE_ERROR_VALUE_EQUALITY_MISMATCH;
      }
    }

    if (validation.valueEquals && !valueEquals(fieldValue, validation.valueEquals)) {
      return INLINE_ERROR_VALUE_MISMATCH;
    }

  } else { // no value

    if (validation.isRequired) {
      return INLINE_ERROR_VALUE_MISSING;
    }

  }

  return null;
};

/**
 * This maker function will create a validator function that is used by redux-form
 * for field validation.
 *
 * @see https://github.com/erikras/redux-form/blob/master/docs/api/ReduxForm.md
 *
 * @param {object} fieldMap - a combined fieldmap containing all fields of all form steps
 * @param {array<object>} stepFields - all fields in a form's step; if a form only
 *     consists of one step, then this would encompass all form fields.
 * @param {object} ui - a map of all ui elements required to verbalize errors.
 * @return {Function} Will return a validate function that will return a FormValidationResult.
 */
export const makeValidate = (fieldMap, stepFields, ui, state) => fieldValues => {
  const fieldErrors = [];
  stepFields.forEach(field => {
    const errorType = validateOne(field, fieldMap, fieldValues, state);
    if (errorType) {
      fieldErrors.push(new FieldErrorEntry(field, errorType, null, ui));
    }
  });

  return new FormValidationResult(ui, fieldErrors);
};
