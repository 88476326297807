import React from 'react';
import PropTypes from 'prop-types';
import Link from '../../basics/text/TextLink';

import suitcss from '../../../helpers/suitcss';
import ProcessHeaderPrice from './ProcessHeaderPrice';


function ProcessHeaderSummary(props) {
  const {
    paymentFee,
    singlePaymentFee,
    onSubmit,
    ui,
    url,
    secondarySelected,
    isDisabled,
    hardwareSelected,
    isContractRenewal,
    isHardwarePath,
    isConfirmed,
    shippingFee,
  } = props;
  const isHardwareSelected = !!hardwareSelected.selectedHardware;

  return (
    <div className={suitcss({ componentName: 'ProcessHeaderItem', modifiers: ['summary'] })}>
      <div className="ProcessItemHeader">
        <ProcessHeaderPrice
          modifiers={['list']}
          paymentFee={paymentFee}
          singlePaymentFee={singlePaymentFee}
          ui={ui}
          shippingFeeHint={isHardwareSelected && shippingFee.unit > 0}
        />
      </div>
      <div className="ProcessItemContent">
        <Link
          asButton
          buttonFilled={secondarySelected}
          utilities={['block']}
          to={!isConfirmed ? url : null}
          element={isConfirmed ? 'button' : null}
          isDisabled={isDisabled}
          onClick={onSubmit}
        >
          {(isContractRenewal && isHardwareSelected && !isHardwarePath)
            ? ui.continueLabel : ui.callToActionLabel}
        </Link>
      </div>
    </div>
  );
}

ProcessHeaderSummary.propTypes = {
  secondarySelected: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  paymentFee: PropTypes.object.isRequired,
  singlePaymentFee: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool,
  ui: PropTypes.object.isRequired,
  url: PropTypes.string.isRequired,
  hardwareSelected: PropTypes.object,
  isContractRenewal: PropTypes.bool,
  isHardwarePath: PropTypes.bool,
  isConfirmed: PropTypes.bool,
  shippingFee: PropTypes.object,
};

export default ProcessHeaderSummary;
