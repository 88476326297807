import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import suitcss from '../../helpers/suitcss';
import {
  getHeaderHeight,
  NAVIGATION_MAIN_SIZE,
  NAVIGATION_MAIN_FACELIFT_SIZE,
  NAVIGATION_MAIN_FACELIFT_SCROLLED_SIZE,
  NAVIGATION_MAIN_FACELIFT_TURNING_POINT,
} from '../../helpers/navigation';

import ServiceStickyContent from '../../components/basics/service/ServiceStickyContent';
import matchMediaConnector from '../service/ServiceMatchMedia';

import GlobalNotificationBar from './GlobalNotificationBar';
import NavigationMain from '../navigation/NavigationMain';

import { createCurrentPageSelector } from '../../selectors/page';

class GlobalHeader extends PureComponent {

  static mapScrollToTop(scroll, isFacelift, isMediaSM) {
    const headerHeight = getHeaderHeight() || NAVIGATION_MAIN_SIZE;
    const notificationsHeight = headerHeight - NAVIGATION_MAIN_SIZE;

    // we are leaving the top of the page, only the main area of the navigation will be visible
    // we are scrolling down, so the navigation should not be visible anymore unless we just
    // changed the scroll directory and need to fade the navigation out
    if (scroll.verticalDirection === 'down') {
      if (scroll.y <= notificationsHeight) {
        return 0;
      }
      if (isFacelift && scroll.y > NAVIGATION_MAIN_FACELIFT_TURNING_POINT) {
        return -headerHeight + NAVIGATION_MAIN_FACELIFT_SCROLLED_SIZE;
      }
      if (isFacelift && !isMediaSM && scroll.y >= notificationsHeight) {
        return -headerHeight + NAVIGATION_MAIN_FACELIFT_SIZE;
      }
      return Math.max(-scroll.turningPointDiffY, -notificationsHeight);
    }

    // same as the step before, but in the other direction. We assume the navigation is invisible
    // and must be faded in, based on the scroll diff
    if (scroll.verticalDirection === 'up') {
      if (isFacelift && scroll.y >= NAVIGATION_MAIN_FACELIFT_TURNING_POINT) {
        return -headerHeight + NAVIGATION_MAIN_FACELIFT_SCROLLED_SIZE;
      }
      if (isFacelift && !isMediaSM && scroll.y >= NAVIGATION_MAIN_SIZE) {
        return -headerHeight + NAVIGATION_MAIN_FACELIFT_SIZE;
      }
      if (scroll.y > NAVIGATION_MAIN_SIZE) {
        return -notificationsHeight;
      }
      return 0;
    }

    // we are still in the top position of the page, but the users scrolls down
    // the navigation must fade out from fully visible to invisible
    if (scroll.y <= NAVIGATION_MAIN_SIZE) {
      return 0;
    }
    // we do not assume that this case is possible, but in case it appears we like to get informed
    // about it so we throw an error.
    throw new Error(`
      An uncaught scroll type was given in getTranslateY.
      verticalDirection: ${scroll.verticalDirection};
      y: ${scroll.y};
      turningPointDiffY: ${scroll.turningPointDiffY};
    `);
  }

  // TODO: Due to brand refresh the NavigationTopBar should temporarily not be displayed OP-3642
  render() {
    const { fixed, identifierNavigation, isPoweredBy, isFacelift, isMediaSM } = this.props;
    const content = (
      <div className={suitcss({ descendantName: 'inner' }, this)}>
        {/* {!this.props.isBrandedSite && (
          <NavigationTopBar isLoggedIn={this.props.isLoggedIn} fixed />
        )} */}
        <GlobalNotificationBar />
        <NavigationMain identifier={identifierNavigation} />
      </div>
    );

    if (fixed) {
      return (
        <header className={suitcss({ modifiers: [isPoweredBy && 'poweredBy'] }, this)} >
          <ServiceStickyContent
            contextName="header"
            mapScrollToTop={GlobalHeader.mapScrollToTop}
            isFacelift={isFacelift}
            isMediaSM={isMediaSM}
          >
            {content}
          </ServiceStickyContent>
          <div className={suitcss({ descendantName: 'inner' }, this)} />
        </header>
      );
    }

    return content;
  }
}

GlobalHeader.propTypes = {
  identifierNavigation: PropTypes.string,
  fixed: PropTypes.bool,
  isMediaSM: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  isBrandedSite: PropTypes.bool,
  isPoweredBy: PropTypes.bool,
  isFacelift: PropTypes.bool,
};

GlobalHeader.defaultProps = {
  isMediaSM: false,
};

const mapStateToProps = (state, ownProps) => {
  const currentPageSelector = createCurrentPageSelector();
  const page = currentPageSelector(state);
  const isFacelift = page && page.settings.some((setting) => setting.type === 'NavigationFacelift');
  const menu = state.menu[ownProps.identifierNavigation] || {};
  const isPoweredBy = menu.items && menu.items.some(entry => entry.class === 'nav-main-poweredby');
  return {
    isPoweredBy,
    isLoggedIn: !!state.user.credentials.msisdn,
    isBrandedSite: !!state.site.brandName,
    isFacelift,
  };
};

const ConnectedComponent = compose(
  matchMediaConnector(['isMediaSM']),
  connect(mapStateToProps),
)(GlobalHeader);

ConnectedComponent.mapScrollToTop = GlobalHeader.mapScrollToTop;

export default ConnectedComponent;
