import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';
import Copy from '../../basics/text/TextCopy';
import TextLink from '../../basics/text/TextLink';

function HotlineOptionsDialogItem(props) {
  const { parent, headline, number, copy } = props;
  return (
    <li
      className={suitcss({ descendantName: 'option' }, parent)}
    >
        <Copy
          className={suitcss({ descendantName: 'headline' }, parent)}
          element="div"
          embedded
          utilities={['weightBold']}
        >
          {headline}
        </Copy>
        <TextLink
          className={suitcss({ descendantName: 'number' }, parent)}
          href={`tel:${number}`}
          embedded
          utilities={['weightBold']}
          withoutArrow
        >
          {number}
        </TextLink>
        <Copy
          className={suitcss({ descendantName: 'copy' }, parent)}
          element="div"
          embedded
        >
          {copy}
        </Copy>
      </li>
  );
}

HotlineOptionsDialogItem.propTypes = {
  parent: PropTypes.object.isRequired,
  headline: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
};

const mapStateToProps = ({ ui }) => ({ ui });

export default connect(mapStateToProps)(HotlineOptionsDialogItem);
