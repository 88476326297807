import AbstractError from '../model/errors/AbstractError';

/**
 * Calls a function for a given length of time for a certain interval.
 * If the function returns a non falsy value the promise is resolved.
 * Adapted from https://davidwalsh.name/javascript-polling
 *
 * @param {function} fn The function to poll
 * @param {number} timeout The time after which the promise is rejected.
 * @param {number} interval The time between the polling of the function
 * @returns {Promise}
 */
export const poll = (fn, timeout, interval) => {
  const endTime = Number(new Date()) + (timeout);

  const checkCondition = (resolve, reject) => {
    const result = fn();
    if (result) {
      resolve(result);
    } else if (Number(new Date()) < endTime) {
      setTimeout(checkCondition, interval, resolve, reject);
    } else {
      // @todo Wrong instantiation of AbstractError! Create a specific TimeoutError
      reject(new AbstractError(`timed out for ${fn}: ${timeout} + ${interval}`));
    }
  };
  return new Promise(checkCondition);
};

export const MILLISECONDS_TIME_FIFTEEN_MINUTES = 15 * 60 * 1000;
export const MILLISECONDS_TIME_ONE_HALF_SECOND = 5 * 1000;

const timeout = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

export const sleep = async (fn, delay, ...args) => {
  await timeout(delay);
  return fn(...args);
};
