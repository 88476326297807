import PageModule from './PageModule';
import component from '../../containers/account/login/LoginForm';

/**
 * The module MyLoginForm forms the login form for the My otelo area.
 * @see https://confluence.db-n.com/x/FPTc
 */
class LoginFormModule extends PageModule {
  constructor(moduleData) {
    super(component, moduleData);
  }
}

export default LoginFormModule;

