import QueueableRequest from './QueueableRequest';
import { SUBSCRIPTION_MANAGEMENT_ENDPOINT } from '../../config/api';
import { bindParamsToRoute } from '../../helpers/url';

export default class SubscriptionManagementRequest extends QueueableRequest {
  constructor(subscriptionType, solicitorId, options) {
    super(
      bindParamsToRoute(
        SUBSCRIPTION_MANAGEMENT_ENDPOINT,
        { subscriptionType, solicitorId },
      ),
      options,
    );
  }
}
