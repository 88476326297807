/* global window */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

/*
 * Onload iframe handler, sets the height of an iframe to it's document height
 */
const resizeIframe = target => {
  if (target && target.contentWindow && target.contentWindow.document) {
    const newHeight = `${target.contentWindow.document.body.scrollHeight}px`;
    if (target.style.height !== newHeight) {
      target.style.height = newHeight; // eslint-disable-line no-param-reassign
    }
  }
};

/**
 * Component for displaying IFrames
 * @param
 * @param  {string} options.src        Source path to a single image resource. This will only be
 *                                     used when no variants was found.
 */
class MediaIFrame extends PureComponent {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.receiveMessage = this.receiveMessage.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
    window.addEventListener('message', this.receiveMessage, false);
  }

  componentWillUnmount() {
    this.mounted = false;
    if (this.interval) clearInterval(this.interval);
    window.removeEventListener('message', this.receiveMessage);
  }

  onDocumentLoaded(target) {
    const { onLoad } = this.props;
    if (this.mounted) {
      resizeIframe(target);
      if (onLoad) onLoad();
      // the document is loaded, but the content may change, images etc. can increase the height.
      this.interval = setInterval(() => { if (this.mounted) resizeIframe(target); }, 100);
    }
  }

  receiveMessage(msg) {
    const { type, height } = msg.data;
    if (type === 'resize_iframe') {
      this.ref.current.style.height = `${height}px`;
    }
  }

  render() {
    const { autoHeight, ...iframeProps } = this.props;
    const extraProps = autoHeight ? { onLoad: (e) => this.onDocumentLoaded(e.target) } : {};
    const finalprops = {
      ...iframeProps,
      ...extraProps,
    };
    return (
      <iframe
        {...finalprops}
        ref={this.ref}
        scrolling="no"
      />
    );
  }
}

MediaIFrame.propTypes = {
  onLoad: PropTypes.func,
  autoHeight: PropTypes.bool,
};
MediaIFrame.defaultProps = {
  autoHeight: false,
};

export default MediaIFrame;
