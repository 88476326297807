import QueueableRequest from './QueueableRequest';
import { LOGGING_ENDPOINT } from '../../config/api';
import { REQUEST_METHOD_POST, LOGGING_SENSIBLE_DATA_KEYS } from '../../helpers/constants';
import { bindParamsToRoute } from '../../helpers/url';

export default class LoggingRequest extends QueueableRequest {
  /**
   * Sends a frontend log entry to the backend.
   *
   * @see https://confluence.db-n.com/x/HqL3
   * @param {LogEntry} logEntry
   */
  constructor(logEntry) {
    const { additionalData } = logEntry;
    const { payload } = additionalData;
    const cleanLogEntry = JSON.parse(JSON.stringify(logEntry));

    if (payload) {
      const payloadKeys = Object.keys(payload);
      const cleanPayload = {};
      for (let i = 0; i < payloadKeys.length; i++) {
        for (let j = 0; j < LOGGING_SENSIBLE_DATA_KEYS.length; j++) {
          if (payloadKeys[i] === LOGGING_SENSIBLE_DATA_KEYS[j]) {
            cleanPayload[payloadKeys[i]] = 'XXX';
            break;
          }
        }
      }
      cleanLogEntry.additionalData.payload = { ...payload, ...cleanPayload };
    }
    super(bindParamsToRoute(LOGGING_ENDPOINT, { level: logEntry.logLevel }), {
      isBlocking: false,
      method: REQUEST_METHOD_POST,
      payload: {
        ...cleanLogEntry,
        process: logEntry.getContext().toString(),
        context: undefined,
      },
    });
  }

  /**
   * {@inheritDoc}
   * Logging errors are never displayed to the user.
   */
  isPreventDefaultErrorHandling() {
    return true;
  }
}
