import { REQUEST_RECEIVED_RESPONSE } from '../actions/request/basic';
import FriendReferralHistoryRequest from '../model/requests/FriendReferralHistoryRequest';
import FriendReferralLinkRequest from '../model/requests/FriendReferralLinkRequest';
import FriendReferralStatusRequest from '../model/requests/FriendReferralStatusRequest';

const defaultState = {
  referralStatus: {},
  referralLink: {},
  referralHistory: null,
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case REQUEST_RECEIVED_RESPONSE: {
      const { request } = action.meta;
      const responseBody = action.payload.response.body.data;

      if (request instanceof FriendReferralStatusRequest) {
        return { ...state, referralStatus: responseBody };
      }

      if (request instanceof FriendReferralLinkRequest) {
        return { ...state, referralLink: responseBody };
      }

      if (request instanceof FriendReferralHistoryRequest) {
        return { ...state, referralHistory: responseBody };
      }

      return state;
    }

    default:
      return state;
  }
};
