import React from 'react';
import PropTypes from 'prop-types';
import matchMediaConnector from '../../../containers/service/ServiceMatchMedia';
import suitcss from '../../../helpers/suitcss';
import { capitalize } from '../../../helpers/str';
import MediaImage from '../../basics/media/MediaImage';

const componentName = 'TeaserFreeForm';
function TeaserFreeForm(props) {
  const {
    children,
    contentLeft,
    mobileContentTop,
    isMediaS,
    image,
    mobileImage,
    theme,
    className,
  } = props;

  return (
    <div
      className={suitcss({
        className,
        componentName,
        modifiers: [
          contentLeft && 'contentLeft',
          mobileContentTop && 'mobileContentTop',
          theme && `theme${capitalize(theme)}`,
        ],
      })}
    >
      <div
        className={suitcss({
          componentName,
          descendantName: 'inner',
          utilities: [
            'flex',
            'justifyCenter',
            contentLeft ? 'mlDirRowReverse' : 'row',
          ],
        })}
      >
        <div
          className={suitcss({
            componentName,
            descendantName: 'image',
            utilities: ['col6', 'sCol12'],
        })}
        >
          <MediaImage
            src={isMediaS ? mobileImage.src : image.src}
            alt={image.alt}
            width={100}
            isLazy
          />
        </div>
        <div
          className={suitcss({
            componentName,
            descendantName: 'content',
            utilities: ['col6', 'sCol12'],
          })}
        >
          {children}
        </div>
      </div>
    </div>
  );
}

TeaserFreeForm.propTypes = {
  theme: PropTypes.oneOf([null, 'light']),
  children: PropTypes.node,
  contentLeft: PropTypes.bool,
  mobileContentTop: PropTypes.bool,
  isMediaS: PropTypes.bool,
  image: PropTypes.object,
  mobileImage: PropTypes.object,
  className: PropTypes.string,
};

export default matchMediaConnector(['isMediaS'])(TeaserFreeForm);
