/**
 * @file
 *
 * This reducer stores data that is generic, global and not(!) user related.
 *
 * - global objects such as the sitemap, dialog templates or possible avatar items.
 * - data that defines the current state of the page, e.g. if the user started
 *   the contractRenewal, whether we are in a webview or not etc.
 */
import shallowEqual from 'react-pure-render/shallowEqual';
import { LOCATION_CHANGE } from 'react-router-redux';
import {
  REQUEST_RECEIVED_RESPONSE,
  REQUEST_HISTORY_REMOVE,
  REQUEST_RECEIVED_ERROR,
} from '../actions/request/basic';
import { getUrlPath } from '../helpers/url';
import * as storage from '../helpers/storage';
import {
  REQUEST_METHOD_GET,
  QUERY_APPVIEW,
  QUERY_APPVIEW_CONTEXT,
  STORAGE_KEY_DIALOG_STATES,
  NOTIFICATION_TYPE_ALERT,
  NOTIFICATION_TYPE_CUSTOM,
  QUERY_APP_DARK_MODE,
} from '../helpers/constants';
import {
  LOCATION_CHANGE_REQUESTED,
  LOCATION_ADD_PROMPT,
  LOCATION_RESET_PROMPT,
  LOCATION_CHANGE_PENDING,
} from '../actions/page/location';
import {
  CONTRACT_RENEWAL_START,
  CONTRACT_RENEWAL_STOP,
} from '../actions/dialog/contractRenewalActions';
import { SYNC_STORAGE } from '../actions/global/storage';
import SitemapRequest from '../model/requests/SitemapRequest';
import IdMapRequest from '../model/requests/IdMapRequest';
import DialogsRequest from '../model/requests/DialogsRequest';
import AvatarsRequest from '../model/requests/AvatarsRequest';
import TagsRequest from '../model/requests/TagsRequest';
import MobileProvidersRequest from '../model/requests/MobileProvidersRequest';
import DownloadsRequest from '../model/requests/DownloadsRequest';
import GdprConsentsRequest from '../model/requests/GdprConsentsRequest';
import PasswordValidationRulesRequest from '../model/requests/PasswordValidationRulesRequest';
import PromotionsRequest from '../model/requests/PromotionsRequest';
import {
  DISPLAY_DIALOG,
  SHOW_DIALOG,
  HIDE_DIALOG,
  ADD_QUEUED_DIALOG,
  HIDE_QUEUED_DIALOG,
  SHOW_NOTIFICATION,
  HIDE_NOTIFICATION,
  SHOW_ALERT,
  HIDE_ALERT,
  WINDOW_OPENED,
} from '../actions/page/dialog';
import {
  BLOCK_VIEWPORT,
  CHANGE_SCREEN_SIZE,
  TOGGLE_TOUCH_SCREEN_INPUT,
} from '../actions/site/viewport';
import { APP_VIEW_DETECTED } from '../actions/site/appView';
import { INBOX_VIEW_DETECTED } from '../actions/site/inboxView';
import { LOGOUT } from '../actions/user/logout';
import { TRACK_MNP_FLAG, TRACK_USERFLOW, DO_NOT_TRACK_PAGE } from '../actions/tracking/page';
import { HIDE_MNP } from '../actions/order/cart';
import {
  isCustomerActionPromotion,
  isGrantedCampaignPromotion,
  isGrantedPromotion,
} from '../helpers/promotions';
import PageRequest from '../model/requests/PageRequest';

const updateContractRenewalState = (isInProgress = false) => ({ isInProgress });
/**
 * Values that should be persistent after the logout of a user.
 */
const defaultStatePersistent = {
  appView: null,
  appContext: null,
  isAppDarkMode: false,
  avatars: null,
  tags: null,
  isInboxView: false,
  sitemap: null,
  internalIdMap: {},
  dialogTemplates: null,
  isBlockingViewport: false,
  isUsingTouchScreen: false,
  lastTrackedPage: {
    pathname: '',
    search: '',
    hash: '',
    state: undefined,
    action: '',
    key: '',
    query: {},
  },
  routing: {
    currentLocation: null,
    previousLocation: null,
    requestedLocation: null,
    pendingLocation: null,
  },
  // on initial render in browser(!), react-router will trigger directly which is not desired.
  // instead, we want react-router only to trigger if react-router-redux issued
  // a location change action. this flag will can be used to prevent rendering
  // in our toplevel route until react-router-redux has dispatched a location change.
  isBrowserReadyToRoute: false,
  consentTexts: [],
  mnpCheckout: false,
  bookablePromotions: [],
  optionalPromotions: [],
  doNotTrackPage: false,
};
/**
 * Values that should be resetted after the logout of a user.
 */
const defaultStateSession = {
  dialogShown: null,
  dialog: null,
  dialogs: [],
  notification: null,
  contractRenewal: updateContractRenewalState(false),
  errors: [],
  requestHistory: [],
  windowOpen: false,
};

const defaultState = { ...defaultStatePersistent, ...defaultStateSession };

export default (state = defaultState, action = {}) => {
  if (!state.hydrated) {
    state = { ...defaultState, ...state, hydrated: true }; // eslint-disable-line
  }
  switch (action.type) {
    case TRACK_MNP_FLAG: {
      return { ...state, mnpCheckout: action.payload };
    }
    case HIDE_MNP: {
      return { ...state, ...action.payload };
    }
    case CHANGE_SCREEN_SIZE: {
      return { ...state, clientWidth: action.payload.width };
    }
    case TRACK_USERFLOW: {
      return { ...state, lastTrackedPage: action.payload };
    }
    case DO_NOT_TRACK_PAGE: {
      return { ...state, ...action.payload };
    }
    case BLOCK_VIEWPORT: {
      const { blocking } = action.payload;
      if (blocking !== state.isBlockingViewport) {
        return { ...state, isBlockingViewport: blocking };
      }
      return state;
    }
    case TOGGLE_TOUCH_SCREEN_INPUT:
      return { ...state, ...action.payload };
    case LOGOUT:
      return { ...state, ...defaultStateSession };
    case APP_VIEW_DETECTED: {
      const { query } = action.payload.location;
      return {
        ...state,
        appView: query[QUERY_APPVIEW],
        appContext: query[QUERY_APPVIEW_CONTEXT],
        isAppDarkMode: !!query[QUERY_APP_DARK_MODE],
      };
    }
    case INBOX_VIEW_DETECTED:
      return { ...state, isInboxView: true };
    case LOCATION_CHANGE_PENDING:
      return {
        ...state,
        routing: {
          ...state.routing,
          pendingLocation: action.payload.location,
        },
      };
    case LOCATION_CHANGE_REQUESTED:
      return {
        ...state,
        routing: {
          ...state.routing,
          requestedLocation: action.payload.location,
        },
      };
    case LOCATION_CHANGE:
      return {
        ...state,
        isBrowserReadyToRoute: true,
        locationPrompt: state.isBrowserReadyToRoute
          ? state.locationPrompt
          : null,
        routing: {
          ...state.routing,
          currentLocation: state.routing.requestedLocation,
          previousLocation: state.routing.currentLocation,
          pendingLocation: null,
        },
      };
    case LOCATION_ADD_PROMPT:
      return { ...state, locationPrompt: action.payload };
    case LOCATION_RESET_PROMPT:
      return { ...state, locationPrompt: null };
    case REQUEST_HISTORY_REMOVE: {
      const { id } = action.payload;
      return {
        ...state,
        requestHistory: state.requestHistory.filter(entry => entry !== id),
      };
    }
    case REQUEST_RECEIVED_ERROR: {
      const { meta } = action;
      const { request } = meta;
      const { requestHistory } = state;
      if (request instanceof PageRequest) {
        return {
          ...state,
          requestHistory: requestHistory.includes(request.id)
            ? requestHistory
            : [...requestHistory, request.id],
        };
      }
      return state;
    }
    case REQUEST_RECEIVED_RESPONSE: {
      const { meta, payload } = action;
      const { request } = meta;

      if (request.method !== REQUEST_METHOD_GET) {
        return state;
      }

      const { body } = payload.response;
      const updates = { ...state };

      if (!updates.requestHistory.includes(request.id)) {
        updates.requestHistory = [...updates.requestHistory, request.id];
      }

      if (request instanceof SitemapRequest) {
        const sitemap = {};
        body.data.filter(entry => entry.alias).forEach(entry => {
          const { alias, ...data } = entry;
          sitemap[alias] = data;
        });
        updates.sitemap = sitemap;

      } else if (request instanceof IdMapRequest) {
        updates.internalIdMap = body.data;

      } else if (request instanceof MobileProvidersRequest) {
        updates.mobileProviders = body.data;

      } else if (request instanceof DialogsRequest) {
        updates.dialogTemplates = body.data;

      } else if (request instanceof DownloadsRequest) {
        const downloads = {};
        Object.values(body.data).forEach(entry => {
          downloads[entry.id] = { url: entry.file.url };
        });
        updates.downloads = downloads;
      } else if (request instanceof AvatarsRequest) {
        updates.avatars = body.data.avatars.map(item => ({
          ...item,
          url: getUrlPath(`${item.url}.svg`),
        }));
      } else if (request instanceof TagsRequest) {
        updates.tags = body.data.tags;
      } else if (request instanceof GdprConsentsRequest) {
        updates.consentTexts = body.data.texts;
        updates.consentVersion = body.data.termsVersion;
      } else if (request instanceof PasswordValidationRulesRequest) {
        updates.validation = body.data;
      } else if (request instanceof PromotionsRequest) {
        updates.bookablePromotions = body.data
          .filter(promo => !isGrantedPromotion(promo))
          .filter(promo => !isGrantedCampaignPromotion(promo))
          .filter((promo) => !isCustomerActionPromotion(promo))
          .map(vo => vo.eid);
        updates.optionalPromotions = body.data
          .filter(promo => !isGrantedPromotion(promo))
          .filter(promo => !isGrantedCampaignPromotion(promo))
          .filter((promo) => isCustomerActionPromotion(promo))
          .map(vo => vo.eid);
      }
      return updates;
    }
    case SYNC_STORAGE:
      return {
        ...state,
        dialogStates: storage.getItem(
          storage.STORAGE_TYPE_COOKIE,
          STORAGE_KEY_DIALOG_STATES,
        ) || {},
      };
    case SHOW_NOTIFICATION: {
      if (
        !!state.appView &&
        action.payload.notification.type !== (NOTIFICATION_TYPE_ALERT || NOTIFICATION_TYPE_CUSTOM)
      ) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        ...action.payload,
      };
    }
    case WINDOW_OPENED:
    case HIDE_NOTIFICATION:
    case SHOW_DIALOG:
    case HIDE_DIALOG: {
      return {
        ...state,
        ...action.payload,
        dialogShown: action.type === HIDE_DIALOG && shallowEqual(state.dialog, state.dialogShown)
          ? null
          : state.dialogShown,
      };
    }
    case DISPLAY_DIALOG:
      return { ...state, ...action.payload };
    case ADD_QUEUED_DIALOG: {
      const queuedDialog = action.payload;
      const isAlreadyQueued = queuedDialog.id !== undefined && state.dialogs.some(
        item => item.id === queuedDialog.id,
      );
      return isAlreadyQueued ? state : { ...state, dialogs: [...state.dialogs, queuedDialog] };
    }
    case HIDE_QUEUED_DIALOG: {
      return {
        ...state,
        dialogs: state.dialogs.filter((item, index) => index > 0),
        dialogShown: shallowEqual(state.dialogs[0], state.dialogShown) ? null : state.dialogShown,
      };
    }
    case SHOW_ALERT: {
      const isAlreadyQueued = state.errors.some(
        alert => alert.error.fullCode === action.payload.error.fullCode,
      );
      return isAlreadyQueued ? state : { ...state, errors: [...state.errors, action.payload] };
    }
    case HIDE_ALERT:
      return { ...state, errors: state.errors.filter((item, index) => index > 0) };
    case CONTRACT_RENEWAL_START: {
      return { ...state, contractRenewal: updateContractRenewalState(true) };
    }
    case CONTRACT_RENEWAL_STOP:
      return { ...state, contractRenewal: updateContractRenewalState(false) };
    default:
      return state;
  }
};
