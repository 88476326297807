import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';

import SvgLoader from '../media/MediaSvgLoader';

class FormSelectBox extends PureComponent {

  constructor(props, context) {
    super(props, context);
    this.state = {
      open: false,
    };
  }

  render() {
    const {
      size,
      inverted,
      underlined,
      valueAsBulletColor,
      selectedValue,
      onChange,
    } = this.props;

    const modifiers = [
      size,
      inverted && 'inverted',
      underlined && 'underlined',
      valueAsBulletColor && 'valueAsBulletColor',
    ];

    return (
      <div className={suitcss({ modifiers }, this)}>
        <div
          className={suitcss({
            descendantName: 'select',
          }, this)}
        >
          <select
            className={suitcss({
              descendantName: 'input',
            }, this)}
            value={selectedValue}
            onChange={onChange}
          >
            {this.props.children}
          </select>
          <div
            className={suitcss({
              descendantName: 'bullet',
            }, this)}
            style={{ backgroundColor: (valueAsBulletColor && selectedValue) || null }}
          />
          <div
            className={suitcss({
              descendantName: 'arrow',
            }, this)}
          >
            <SvgLoader path="/icons/content-arrow.svg" />
          </div>
        </div>
      </div>
    );
  }
}

FormSelectBox.propTypes = {
  selectedValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.object,
  ]),
  onChange: PropTypes.func,
  children: PropTypes.arrayOf(PropTypes.element),
  size: PropTypes.oneOf(['', 's', 'm']),
  inverted: PropTypes.bool,
  underlined: PropTypes.bool,
  valueAsBulletColor: PropTypes.bool,
};

FormSelectBox.defaultProps = {
  valueAsBulletColor: false,
  size: '',
};

export default FormSelectBox;

