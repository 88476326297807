import React from 'react';

import suitcss from '../../../helpers/suitcss';

import { groupPropTypes } from '../../../propTypes/field';
import FieldWrapper from './FieldWrapper';

const componentName = 'FieldGroup';
function FieldGroup(props) {
  const { label, children, asStack } = props;
  return (
    <FieldWrapper
      componentName={componentName}
      {...props}
    >
      {wrapperProvider => (
        <div>
          {label && (
            <div className={suitcss({ componentName, descendantName: 'label' })}>
              {label}
              {!wrapperProvider.isRequired && wrapperProvider.getIsOptionalElement()}
            </div>
          )}
          <div className={suitcss({ componentName, descendantName: 'inner', utilities: ['grid', asStack && 'collapse'] })}>
            {children}
          </div>
        </div>
      )}
    </FieldWrapper>
  );
}

FieldGroup.propTypes = groupPropTypes;

export default FieldGroup;
