import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';
import Link from '../../basics/text/TextLink';
import Unit from '../../basics/text/TextUnit';
import Copy from '../../basics/text/TextCopy';
import MediaImage from '../../basics/media/MediaImage';
import Callout from '../../../containers/callout/Callout';
import {
  CONTEXT_CONTRACT_RENEWAL,
  CONTEXT_CONTRACT_RENEWAL_SIM_PLUS,
} from '../../../helpers/constants';

class TeaserNba extends PureComponent {
  renderProductSection() {
    const {
      headline,
      subline,
      copy,
      isSimOnly,
      tariff,
      hardware,
      image,
    } = this.props;
    return (
      <Fragment>
        <div className={suitcss({ descendantName: 'product' }, this)}>
          <div className={suitcss({ descendantName: 'productSticker' }, this)}>
            <Callout
              utilities={['marginBottomS']}
              theme="sticker"
              context={isSimOnly ? CONTEXT_CONTRACT_RENEWAL : CONTEXT_CONTRACT_RENEWAL_SIM_PLUS}
              targets={[tariff.eid]}
              allowMultiple
            />
          </div>
          <Copy
            className={suitcss({ descendantName: 'headline', utilities: ['weightBold'] }, this)}
            condensed
            raw
          >
            {headline}
          </Copy>
          {copy && (
            <Copy
              className={suitcss({ descendantName: 'copy' }, this)}
              raw
            >
              {copy}
            </Copy>
          )}
          {subline && (
            <Copy
              className={suitcss({ descendantName: 'subline', utilities: ['weightBold', 'colorPrimary'] }, this)}
              condensed
              raw
            >
              {subline}
            </Copy>
          )}
        </div>
        <div className={suitcss({ descendantName: 'productImage', utilities: ['paddingHS'] }, this)}>
          {!isSimOnly && (
            <div className={suitcss({ descendantName: 'productBadge' }, this)}>
              <Callout
                theme="badge"
                targets={[hardware.groupId]}
                size="xs"
              />
              <Callout
                theme="sticker"
                targets={[hardware.groupId]}
                asBadge
                size="xs"
              />
            </div>
          )}
          <MediaImage
            alt={headline}
            src={image}
          />
        </div>
      </Fragment>
    );
  }

  renderPaymentSection() {
    const {
      isSimOnly,
      ui,
      totalPayments,
    } = this.props;
    const {
      totalMonthlyPaymentFeeStrike,
      totalMonthlyPaymentFee,
      totalSinglePaymentFeeStrike,
      totalSinglePaymentFee,
    } = totalPayments;
    const monthlyPaymentCopy = isSimOnly ? ui.nbaSumMonthlyCopy : ui.guiFeeExtraMonthly;
    return (
      <Fragment>
        {!isSimOnly && (
          <div className={suitcss({
            descendantName: 'paymentInfo',
            utilities: ['flex', 'itemsCenter', 'justifyBetween', 'marginBottomS'],
          }, this)}
          >
            <Copy className={suitcss({ descendantName: 'paymentSingleCopy' }, this)} embedded>
              {ui.guiFeeExtraSingular}
            </Copy>
            <Unit
              prefixPrice={totalSinglePaymentFeeStrike}
              postfix={ui.guiSymbolEuro}
              price={totalSinglePaymentFee}
              showCurrency={false}
              inline
            />
          </div>
        )}
        <div className={suitcss({
          descendantName: 'paymentInfo',
          utilities: ['flex', 'itemsCenter', 'justifyBetween'],
        }, this)}
        >
          <div className={suitcss({ descendantName: 'paymentMonthly' }, this)}>
            <Copy className={suitcss({ descendantName: 'paymentMonthlyCopy' }, this)} embedded>
              {monthlyPaymentCopy}
            </Copy>
            {!isSimOnly && (
              <Copy className={suitcss({ descendantName: 'paymentMonthlyBundleCopy' }, this)} embedded>
                {ui.nbaBundleCopy}
              </Copy>
            )}
          </div>
          <Unit
            prefixPrice={totalMonthlyPaymentFeeStrike}
            postfix={ui.guiSymbolEuro}
            price={totalMonthlyPaymentFee}
            showCurrency={false}
            inline
          />
        </div>
      </Fragment>
    );
  }

  renderButton() {
    const { onClick } = this.props;
    return (
      <div
        className={suitcss({
          descendantName: 'button',
          utilities: ['mlBlock', 'paddingLeftM'],
        }, this)}
      >
        <Link
          onClick={onClick}
          asButton
          buttonFilled
          buttonWithoutLabel
        />
      </div>
    );
  }

  renderBottom() {
    const {
      productInformationSheetLink,
      availableConditionText,
    } = this.props;
    return (
      <Fragment>
        {availableConditionText && (
          <Copy className={suitcss({ descendantName: 'productInformation' }, this)} embedded>
            {availableConditionText}
          </Copy>
        )}
        {productInformationSheetLink && productInformationSheetLink.url && (
          <Link
            className={suitcss({ descendantName: 'productInformation' }, this)}
            href={productInformationSheetLink.url}
            withoutArrow
            element="a"
            target="_blank"
          >
            {productInformationSheetLink.name}
          </Link>
        )}
      </Fragment>
    );
  }

  render() {
    const { className, utilities } = this.props;
    return (
      <div className={suitcss({ className, utilities }, this)}>
        <div className={suitcss({
          descendantName: 'productSection',
          utilities: ['flex', 'paddingBottom'],
        }, this)}
        >
          {this.renderProductSection()}
        </div>
        <div className={suitcss({
          descendantName: 'paymentSection',
          utilities: ['flex', 'paddingV', 'itemsCenter'],
        }, this)}
        >
          <div className={suitcss({
            descendantName: 'payment',
            utilities: ['flex', 'dirColumn', 'grow'],
          }, this)}
          >
            {this.renderPaymentSection()}
          </div>
          {this.renderButton()}
        </div>
        <div className={suitcss({ descendantName: 'bottom', utilities: ['alignCenter'] }, this)}>
          {this.renderBottom()}
        </div>
      </div>
    );
  }
}

TeaserNba.propTypes = {
  headline: PropTypes.string.isRequired,
  subline: PropTypes.string,
  copy: PropTypes.string,
  image: PropTypes.string,
  isSimOnly: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  utilities: PropTypes.array,
  ui: PropTypes.object,
  hardware: PropTypes.object,
  tariff: PropTypes.object.isRequired,
  totalPayments: PropTypes.object,
  productInformationSheetLink: PropTypes.object,
  availableConditionText: PropTypes.string,
};

export default TeaserNba;
