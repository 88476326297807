import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import * as subscriptionManagement from './formConfigSubscriptionManagementForm';
import SubscriptionManagementRequest from '../../model/requests/SubscriptionManagementRequest';
import GlobalSection from '../../components/basics/global/GlobalSection';
import FormManager from '../form/FormManager';
import { REQUEST_METHOD_DELETE, REQUEST_METHOD_POST } from '../../helpers/constants';

const steps = [subscriptionManagement];


class SubscriptionManagementForm extends PureComponent {

  getSubmitRoute() {
    const { subscriptionType, solicitorId, requestMethod } = this.props;
    return new SubscriptionManagementRequest(
      subscriptionType,
      solicitorId,
      { method: requestMethod },
    );
  }

  getSuccessRoute() {
    const { urlSuccess } = this.props;
    return urlSuccess;
  }

  render() {
    return (
      <GlobalSection>
        <FormManager
          form={SubscriptionManagementForm.formName}
          submitRoute={this.getSubmitRoute()}
          successRoute={this.getSuccessRoute()}
          steps={steps}
          stepProps={this.props}
          withFooter
        />
      </GlobalSection>
    );
  }
}

/**
 * required by tracking!
 */
SubscriptionManagementForm.formName = subscriptionManagement.id;

SubscriptionManagementForm.propTypes = {
  solicitorId: PropTypes.string,
  subscriptionType: PropTypes.string,
  urlSuccess: PropTypes.string,
  requestMethod: PropTypes.oneOf([
    REQUEST_METHOD_DELETE,
    REQUEST_METHOD_POST,
  ]),
};

SubscriptionManagementForm.defaultProps = {
  requestMethod: REQUEST_METHOD_POST,
};

export default SubscriptionManagementForm;
