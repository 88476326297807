/* global window */
import React from 'react';
import PropTypes from 'prop-types';
import oneLine from 'common-tags/lib/oneLine';
import { closest } from '../../../helpers/dom';
import { stripHTML } from '../../../helpers/str';
import { getTargetType } from './TextLink';
import { getBrowserInfo, openUrl } from '../../../services/bridge';

const stripBlacklist = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p'];
const shouldStripPTag = (element) => (
  typeof element === 'string' &&
  stripBlacklist.includes(element)
);

const TextRaw = ({
  element,
  children,
  className,
  stripPTag = shouldStripPTag(element),
  onClick,
}, { router }) => {
  const Element = element;
  const html = stripPTag ? stripHTML(children, 'p') : children;

  return (
    <Element
      className={className}
      dangerouslySetInnerHTML={{
        // online is required because react does not know about the transformation
        // made in server/renderer/template.js:3 and will assume that something was
        // changed and rerender the hole document. oneLining raw content anyway fixes
        // this
        __html: oneLine`${html}`,
      }}
      onClick={onClick || TextRaw.onClick(router && router.push)}
    />
  );
};

TextRaw.onClick = push => (ev) => {
  // ctrl key will always open in new window
  if (ev.ctrlKey) {
    return;
  }

  const anchor = closest(ev.target, 'a', true);
  if (!anchor) {
    return;
  }
  const { href, target, pathname } = anchor;

  if (getTargetType(href, target) === '_blank') {
    ev.preventDefault();
    if (getBrowserInfo().isWebView) {
      openUrl(href);
    } else {
      window.open(href, '_blank', 'noopener,noreferrer');
    }
    return;
  }

  // handle internal links by react router
  // href on anchor tags will always return absolute urls so we do not have to
  // check for relative paths
  const origin = `${window.location.protocol}//${window.location.hostname}`;
  if (push && href.startsWith(origin)) {
    ev.preventDefault();
    push({
      pathname,
      search: anchor.search,
      hash: anchor.hash,
    });
  }
};

TextRaw.propTypes = {
  children: PropTypes.node.isRequired,
  element: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  className: PropTypes.string,
  // will define if all p tags should be removed. This might be required when
  // the element is a h1 to fulfill html standard. if its not defined and the element
  // prop is a string, the component will try to determine the correct algorithm
  // on its own.
  stripPTag: PropTypes.bool,
  onClick: PropTypes.func,
};

TextRaw.defaultProps = {
  element: 'span',
};

// we are working with context here to not import redux and action dispatchers,
// that will require tests to instantiate a store and so on...
TextRaw.contextTypes = {
  router: PropTypes.object,
};

export default TextRaw;
