/*

All error codes are documented at https://confluence.db-n.com/x/f93c
Please update the confluence documentation when adding new codes.

*/

/* REASONS – describe the shape of an error object */

export const ERROR_TYPE_INLINE_FORM_ERROR = Symbol('validation_error');
export const ERROR_TYPE_FORM_ERROR = 'form_error';
export const ERROR_TYPE_ERROR = 'error';

/* FULL ERROR CODES – correspond to an error message */

/* eslint-disable no-multi-spaces */
export const CLIENT_PREFIX                     = '01-01';
export const GENERIC_FRONTEND_ERROR            = `${CLIENT_PREFIX}-0000-0000-00`;
export const MAINTENANCE_MODE_ERROR            = `${CLIENT_PREFIX}-0000-0000-01`;
export const SESSION_EXPIRED_ERROR             = `${CLIENT_PREFIX}-0000-0000-02`;
export const POPUP_BLOCKED_ERROR               = `${CLIENT_PREFIX}-0000-0000-04`;
export const NOT_IMPLEMENTED_ERROR             = `${CLIENT_PREFIX}-0000-0000-05`;
export const RUNTIME_ERROR                     = `${CLIENT_PREFIX}-0000-0000-06`;
export const REDIRECT_ERROR                    = `${CLIENT_PREFIX}-0000-0000-07`;
export const SKIP_ERROR                        = `${CLIENT_PREFIX}-0000-0000-08`;
export const FETCH_ERROR                       = `${CLIENT_PREFIX}-0000-0000-09`;

export const SIM_CARD_ASSIGNED_ERROR           = `${CLIENT_PREFIX}-0001-0001-01`;
export const SIM_CARD_ACTIVE_ERROR             = `${CLIENT_PREFIX}-0001-0001-02`;
export const SIM_CARD_PASSIVE_ERROR            = `${CLIENT_PREFIX}-0001-0001-03`;
export const SIM_CARD_SUSPENDED_ERROR          = `${CLIENT_PREFIX}-0001-0001-04`;
export const SIM_CARD_INACTIVE_ERROR           = `${CLIENT_PREFIX}-0001-0001-05`;
export const SIM_CARD_MARKET_ERROR             = `${CLIENT_PREFIX}-0001-0001-06`;

export const CREDIT_CARD_GENERATE_TOKEN_ERROR  = `${CLIENT_PREFIX}-0001-0002-01`;
export const PAYPAL_CANCELED_ERROR             = `${CLIENT_PREFIX}-0001-0002-02`;

export const FORM_VALIDATION_ERROR             = `${CLIENT_PREFIX}-0001-0003-01`;
export const FORM_UNKNOWN_SUBMIT_ERROR         = `${CLIENT_PREFIX}-0001-0003-02`;
export const FORM_UNKNOWN_STEP_ERROR           = `${CLIENT_PREFIX}-0001-0003-03`;
export const ILLOGICAL_RESPONSE_ERROR          = `${CLIENT_PREFIX}-0001-0003-04`;
export const FORM_PAYPAL_AND_MNP_CHOSEN_ERROR  = `${CLIENT_PREFIX}-0001-0003-05`;

export const TRACKING_MISSING_FULLCODE_ERROR   = `${CLIENT_PREFIX}-0001-0005-01`;

export const createFallbackErrorCode = status => `${CLIENT_PREFIX}-0001-0004-${status}`;

