import PropTypes from 'prop-types';
import { priceShape } from './unit';
import { imageShape } from './media';

export const CATEGORY_POSTPAID = 'postpaid';
export const CATEGORY_PREPAID = 'prepaid';
export const CATEGORY_SINGLE_PRODUCT = 'einzelprodukt';

export const propTypes = {
  eid: PropTypes.string.isRequired,
  etype: PropTypes.string.isRequired,
  iid: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string,
  headline: PropTypes.string,
  subline: PropTypes.string,
  copy: PropTypes.string,
  urlDetails: PropTypes.string.isRequired,
  paymentFee: priceShape,
  singlePaymentFee: priceShape,
  icon: PropTypes.string,
  backgroundImage: imageShape,
  market: PropTypes.oneOf(['PUA', 'PUC']).isRequired,
  category: PropTypes.oneOf([
    CATEGORY_POSTPAID,
    CATEGORY_PREPAID,
    CATEGORY_SINGLE_PRODUCT,
  ]).isRequired,
};

export const shape = PropTypes.shape(propTypes);
export const list = PropTypes.arrayOf(shape);
