import PageModule from './PageModule';
import component from '../../containers/download/DownloadList';

/**
 * List of download content.
 * @see https://confluence.db-n.com/x/_r-c
 */
class DownloadListModule extends PageModule {
  constructor(moduleData) {
    super(component, moduleData);
  }
}

export default DownloadListModule;

