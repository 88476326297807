import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field, change } from 'redux-form';

import suitcss from '../../../helpers/suitcss';
import connectUI from '../../basics/ui/UIConnector';
import FieldInput from '../../basics/form/FieldInput';
import FieldTextarea from '../../basics/form/FieldTextarea';
import FormSection from '../form/FormSection';
import FormHeader from '../form/FormHeader';
import Copy from '../../basics/text/TextCopy';
import Captcha from '../../../containers/captcha/CaptchaService';

const componentName = 'PresalesCancellationForm';

class PresalesCancellationForm extends PureComponent {
  constructor(props, context) {
    super(props, context);
    this.resetInputTerminationDate = this.resetInputTerminationDate.bind(this);
  }

  resetInputTerminationDate(event) {
    const { dispatch, form, fieldMap, ui } = this.props;

    if (event.target.value === '') {
      dispatch(change(form, fieldMap.terminationDate.name, ui.guiWordAsap));
    }
    event.preventDefault();
  }

  render() {
    const { fieldMap, params, ui } = this.props;
    const {
      disclaimerHeadline,
      disclaimerCopy,
      noticeHeadline,
      noticeCopy,
      emailInfo,
      customerIdInfo,
      contractIdInfo,
      terminationDateInfo,
      terminationDetailsInfo,
    } = params;

    return (
      <FormSection className={suitcss({ componentName, utilities: ['paddingXL', 'smPaddingXS'] })}>
        <FormHeader
          headline={disclaimerHeadline}
          copy={disclaimerCopy}
          ui={ui}
          utilities={['col12']}
          isExpanded
        />
        <FormHeader
          headline={noticeHeadline}
          copy={noticeCopy}
          ui={ui}
          utilities={['col12']}
          isExpanded
        />
        <div
          className={suitcss({
            utilities: ['grid', 'lCol8', 'marginBottomL'],
          })}
        >
          <Field
            {...fieldMap.firstName}
            component={FieldInput}
            utilities={['col10', 'smCol12']}
            withToolTip
          />
          <Field
            {...fieldMap.lastName}
            component={FieldInput}
            utilities={['col10', 'smCol12']}
            withToolTip
          />
          <Field
            {...fieldMap.email}
            component={FieldInput}
            utilities={['col10', 'smCol12']}
            withToolTip
          />
          <Copy raw utilities={['col10', 'smCol12', 'colorGray100', 'marginTopXS']}>
            {emailInfo.copy}
          </Copy>
          <Field
            {...fieldMap.addressStreet}
            component={FieldInput}
            utilities={['col7', 'smCol8']}
            withToolTip
          />
          <Field
            {...fieldMap.addressNumber}
            component={FieldInput}
            utilities={['col3', 'smCol4']}
            withToolTip
          />
          <Field
            {...fieldMap.addressZip}
            component={FieldInput}
            utilities={['col3', 'smCol4']}
            withToolTip
          />
          <Field
            {...fieldMap.addressCity}
            component={FieldInput}
            utilities={['col7', 'smCol8']}
            withToolTip
          />
          <Field
            {...fieldMap.customerId}
            component={FieldInput}
            utilities={['col10', 'smCol12']}
            withToolTip
          />
          <Copy raw utilities={['col10', 'smCol12', 'colorGray100', 'marginTopXS']}>
            {customerIdInfo.copy}
          </Copy>
          <Field
            {...fieldMap.contractIdent}
            component={FieldInput}
            utilities={['col10', 'smCol12']}
            withToolTip
          />
          <Copy raw utilities={['col10', 'smCol12', 'colorGray100', 'marginTopXS']}>
            {contractIdInfo.copy}
          </Copy>
          <Field
            {...fieldMap.terminationDate}
            component={FieldInput}
            utilities={['col10', 'smCol12']}
            withToolTip
            onBlur={this.resetInputTerminationDate}
            props={{ value: this.props.formValues[fieldMap.terminationDate.name] || '' }}
          />
          <Copy raw utilities={['col10', 'smCol12', 'colorGray100', 'marginTopXS']}>
            {terminationDateInfo.copy}
          </Copy>
        </div>
        <div className={suitcss({ utilities: ['grid', 'lCol12'] })}>
          <Field
            {...fieldMap.terminationDetails}
            component={FieldTextarea}
            placeholder={fieldMap.terminationDetails.placeholder}
            utilities={['col12', 'marginTopXS']}
            withToolTip
          />
          <Copy raw utilities={['col12', 'colorGray100', 'marginTopXS']}>
            {terminationDetailsInfo.copy}
          </Copy>
        </div>
        <Captcha lineLength={['col12', 'mlCol5']} />
      </FormSection>
    );
  }
}

PresalesCancellationForm.propTypes = {
  // form logic
  fieldMap: PropTypes.object.isRequired,
  // cms wordings
  params: PropTypes.shape({
    headline: PropTypes.string,
    copy: PropTypes.string,
    disclaimerHeadline: PropTypes.string,
    disclaimerCopy: PropTypes.string,
    noticeHeadline: PropTypes.string,
    noticeCopy: PropTypes.string,
    emailInfo: PropTypes.object,
    customerIdInfo: PropTypes.object,
    contractIdInfo: PropTypes.object,
    terminationDateInfo: PropTypes.object,
    terminationDetailsInfo: PropTypes.object,
  }),
  ui: PropTypes.shape({
    guiFormSubmit: PropTypes.string.isRequired,
    guiWordAsap: PropTypes.string.isRequired,
  }),
  dispatch: PropTypes.func,
  form: PropTypes.string,
  formValues: PropTypes.object,
};

export default connectUI()(PresalesCancellationForm);
