import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import suitcss from '../../../../helpers/suitcss';
import FieldInput from '../../../basics/form/FieldInput';
import FormSection from '../../form/FormSection';

const componentName = 'LoginFormStep';

const LoginFormSms = memo(({ fieldMap }) => {
  return (
    <FormSection
      className={suitcss({ componentName, modifiers: ['stepSms'] })}
      withoutBorder
      asGrid
    >
      <Field
        {...fieldMap.msisdn}
        component={FieldInput}
        utilities={['col12']}
        theme="compact"
        withToolTip
      />
    </FormSection>
  );
}, (prevProps, nextProps) => {
  // Only re-render if the fieldMap prop has changed
  return JSON.stringify(prevProps.fieldMap) === JSON.stringify(nextProps.fieldMap);
});

LoginFormSms.propTypes = {
  // form logic
  fieldMap: PropTypes.shape({
    msisdn: PropTypes.object.isRequired,
  }),
};

export default LoginFormSms;
