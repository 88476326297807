import React from 'react';
import PropTypes from 'prop-types';

import Headline from '../../basics/text/TextHeadline';
import ProcessHeaderDefinitionList from './ProcessHeaderDefinitionList';

import suitcss from '../../../helpers/suitcss';

const componentName = 'ProcessHeaderItem';

function ProcessHeaderList(props) {
  const { headline, items, modifiers } = props;

  return (
    <section
      className={suitcss({
        componentName,
        modifiers,
      })}
    >
      <div className={suitcss({ componentName, descendantName: 'header' })}>
        {headline &&
          <Headline size="s" raw embedded>
            {headline}
          </Headline>
        }
      </div>
      <div className={suitcss({ componentName, descendantName: 'content' })}>
        <ProcessHeaderDefinitionList
          items={items.map(item => ({
            eid: item.eid,
            label: item.headline,
            price: item.paymentFee,
          }))}
          modifiers={['list']}
        />
      </div>
    </section>
  );
}

ProcessHeaderList.propTypes = {
  headline: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    eid: PropTypes.string.isRequired,
    etype: PropTypes.oneOf(['tariffOptionEntity']).isRequired,
    headline: PropTypes.string.isRequired,
    paymentFee: PropTypes.object.isRequired,
  })),
  modifiers: PropTypes.array,
};

ProcessHeaderList.defaultProps = {
  modifiers: [],
};

export default ProcessHeaderList;
