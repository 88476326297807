import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';
import TextRaw from '../../basics/text/TextRaw';
import SvgLoader from '../../basics/media/MediaSvgLoader';
import UIOverlayWrapper from '../../basics/ui/UIOverlayWrapper';

const ANIMATION_FADE = 'fade';
const ANIMATION_FALL = 'fall';
const ANIMATION_FLIP = 'flip';
const ANIMATION_PIVOT = 'pivot';
const ANIMATION_SCALE = 'scale';
const ANIMATION_SLIDE = 'slide';

class TeaserLayerDialog extends PureComponent {

  renderContent() {
    const { content } = this.props;
    return (
      <TextRaw className={suitcss({ descendantName: 'content' }, this)} element="div">
        {content}
      </TextRaw>
    );
  }

  renderContentWithFaces(animation) {
    const iconPath = `/images/avatar/${Math.ceil(9 * Math.random())}.svg`;
    return [
      <div className={suitcss({ descendantName: 'front' }, this)} key="front">
        {this.renderContent()}
      </div>,
      animation === ANIMATION_FLIP && (<div className={suitcss({ descendantName: 'back' }, this)} key="back">
        <SvgLoader path={iconPath} />
      </div>),
    ];
  }

  render() {
    const { onClose, animation, colorScheme } = this.props;
    const withFaces = animation === ANIMATION_FLIP || animation === ANIMATION_PIVOT;
    return (
      <div
        className={suitcss({}, this)}
        data-animation={animation || ANIMATION_FADE}
      >
        <UIOverlayWrapper className={suitcss({ descendantName: 'inner' }, this)} onClose={onClose} theme={colorScheme}>
          {!withFaces && this.renderContent()}
          {withFaces && this.renderContentWithFaces(animation)}
        </UIOverlayWrapper>
      </div>
    );
  }
}

TeaserLayerDialog.propTypes = {
  content: PropTypes.string.isRequired,
  animation: PropTypes.oneOf([
    ANIMATION_FADE,
    ANIMATION_FALL,
    ANIMATION_FLIP,
    ANIMATION_PIVOT,
    ANIMATION_SCALE,
    ANIMATION_SLIDE,
  ]),
  onClose: PropTypes.func.isRequired,
  ui: PropTypes.shape({
    guiWordClose: PropTypes.string.isRequired,
  }).isRequired,
  colorScheme: PropTypes.string,
};

export default TeaserLayerDialog;
