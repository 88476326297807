import React from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';
import connectUniqueId from '../service/UniqueIdConnector';
import { hintShape, inputShape, metaShape } from '../../../propTypes/field';
import FieldWrapper from './FieldWrapper';

const componentName = 'FieldTextarea';

function FieldTextarea(props) {
  const {
    input,
    label,
    rows,
    uniqueId,
  } = props;

  return (
    <FieldWrapper componentName={componentName} {...props}>
      {wrapperProvider => (
        <div className={suitcss({ componentName, descendantName: 'inner' })} >
          {label && (
            <label className={suitcss({ componentName, descendantName: 'label' })} htmlFor={uniqueId}>
              {label}
              {!wrapperProvider.isRequired && wrapperProvider.getIsOptionalElement()}
            </label>
          )}
          <div className={suitcss({ componentName, descendantName: 'field' })}>
            <textarea
              className={suitcss({
                componentName,
                descendantName: 'input',
              })}
              id={uniqueId}
              rows={rows}
              {...input}
            />
            <div className={suitcss({ componentName, descendantName: 'border' })} />
          </div>
        </div>
      )}
    </FieldWrapper>
  );
}

FieldTextarea.propTypes = {
  uniqueId: PropTypes.string.isRequired,
  rows: PropTypes.number,
  type: PropTypes.string,
  label: PropTypes.string,
  hint: hintShape,
  input: inputShape,
  meta: metaShape,
  raw: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  utitilies: PropTypes.array,
  theme: PropTypes.oneOf(['default', 'compact']),
};

FieldTextarea.defaultProps = {
  meta: {},
  input: {},
  rows: 6,
  theme: 'default',
};

export default connectUniqueId('fi')(FieldTextarea);
