import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import connectUI from '../../basics/ui/UIConnector';
import suitcss from '../../../helpers/suitcss';
import FieldInput from '../../basics/form/FieldInput';
import FieldRadioGroup from '../../basics/form/FieldRadioGroup';
import FieldDate from '../../basics/form/FieldDate';
import FieldCheckbox from '../../basics/form/FieldCheckbox';
import FormSection from '../form/FormSection';
import FormHeader from '../form/FormHeader';

const componentName = 'FormWrapper';

/**
 * @deprecated this module is not in use anymore and shall be removed at one point
 */
class FreeSimFormOrdering extends Component {
  renderFormShipping(fieldMap) {
    return (
      <div>
        <Field {...fieldMap.salutation} component={FieldRadioGroup} utilities={['col12']} />

        <Field {...fieldMap.firstName} component={FieldInput} utilities={['col6']} />
        <Field {...fieldMap.lastName} component={FieldInput} utilities={['col6']} />

        <Field {...fieldMap.birthday} component={FieldDate} isDateGroup utilities={['col12']} />

        <Field {...fieldMap.addressStreet} component={FieldInput} utilities={['col9']} />
        <Field {...fieldMap.addressNumber} component={FieldInput} utilities={['col3']} />

        <Field {...fieldMap.addressZip} component={FieldInput} utilities={['col9']} />
        <Field {...fieldMap.addressCity} component={FieldInput} utilities={['col3']} />

        <Field {...fieldMap.addressExtra} component={FieldInput} utilities={['col6']} />
        <div className={suitcss({ utilities: ['col6'] })} />

        <Field {...fieldMap.eMail} component={FieldInput} utilities={['col6']} />
        <Field {...fieldMap.eMailRepeat} component={FieldInput} utilities={['col6']} />
      </div>
    );
  }

  renderTerms(fieldMap) {
    return (
      <div className={suitcss({ componentName, descendantName: 'container' })}>
        <div className={suitcss({ componentName, descendantName: 'formFull' })}>
          <div className={suitcss({ componentName, descendantName: 'formThreeFourths' })}>
            <Field {...fieldMap.agb} component={FieldCheckbox} utilities={['uppercase']} raw />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { params, fieldMap } = this.props;
    const { shippingHeadline, shippingCopy, termsHeadline, termsCopy } = params;

    return (
      <div className={suitcss({}, this)}>
        <FormSection>
          <FormHeader headline={shippingHeadline} copy={shippingCopy}>
            {this.renderFormShipping(fieldMap)}
          </FormHeader>
          <FormHeader headline={termsHeadline} copy={termsCopy}>
            {this.renderTerms(fieldMap)}
          </FormHeader>
        </FormSection>
      </div>
    );
  }
}

FreeSimFormOrdering.propTypes = {
  fieldMap: PropTypes.object.isRequired,
  params: PropTypes.shape({
    shippingHeadline: PropTypes.string.isRequired,
    shippingCopy: PropTypes.string.isRequired,
    termsHeadline: PropTypes.string.isRequired,
    termsCopy: PropTypes.string.isRequired,
    termsAgbLabel: PropTypes.string.isRequired,
    termsAgbHint: PropTypes.string.isRequired,
    tariffIdentifier: PropTypes.number.isRequired,
    urlSuccess: PropTypes.string.isRequired,
  }).isRequired,
};

export default connectUI()(FreeSimFormOrdering);
