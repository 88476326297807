import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { oneLine } from 'common-tags';
import suitcss from '../../../helpers/suitcss';
import { pascalCase } from '../../../helpers/str';
import {
  CONSUMPTION_TYPE_DATA,
  CONSUMPTION_TYPE_SMS,
  CONSUMPTION_TYPE_MIN,
  CONSUMPTION_TYPE_UNIT,
  CONSUMPTION_TYPE_VOLUME,
} from '../../../helpers/constants';
import { decimalFormatter } from '../../../helpers/money';
import { formatDate } from '../../../helpers/date';
import DataList from '../../basics/text/TextDataList';

const componentName = 'ConsumptionDetailsDialog';

class ConsumptionDetailsDialog extends PureComponent {

  getPostfix(unit) {
    const { ui } = this.props;
    switch (unit) {
      case 'mb':
        return ` ${ui.guiSymbolMegabyte}`;
      case 'gb':
        return ` ${ui.guiSymbolGigabyte}`;
      default:
        return '';
    }
  }

  getTotal() {
    const { unit, consumptions } = this.props;
    const total = consumptions.reduce((sum, item) => sum + item.max, 0);
    return `${unit === 'gb' ? decimalFormatter().format(total / 1024) : total}${this.getPostfix(unit)}`;
  }

  getKeyValueObject(item) {
    const { ui } = this.props;
    const { expirationDate } = item;
    const expirationDateString = expirationDate ? `<span class="u-colorGray100 u-textSizeSmall">${ui.consumptionsDetailsExpiration.replace('{DATE}', formatDate(expirationDate))}</span>` : '';
    return {
      value: `${item.max}${this.getPostfix(item.unit)}`,
      key: oneLine`
        <span>
          <span>${item.title}</span>
          ${expirationDateString}
        </span>
        `,
    };
  }

  getSummary() {
    const { type, ui } = this.props;
    return {
      key: ui[`consumptionsDetailsTotal${pascalCase(type)}`],
      value: this.getTotal(),
    };
  }

  render() {
    const { consumptions } = this.props;
    const items = consumptions.map(this.getKeyValueObject, this);
    const summary = this.getSummary();
    return (
      <div className={suitcss({ componentName }, this)}>
        <DataList items={items} withoutBreakpoints />
        <DataList items={[summary]} withoutBreakpoints />
      </div>
    );
  }
}

ConsumptionDetailsDialog.propTypes = {
  type: PropTypes.oneOf([
    CONSUMPTION_TYPE_DATA,
    CONSUMPTION_TYPE_SMS,
    CONSUMPTION_TYPE_MIN,
    CONSUMPTION_TYPE_UNIT,
    CONSUMPTION_TYPE_VOLUME,
    'phone_sms',
  ]).isRequired,
  unit: PropTypes.oneOf(['', 'mb', 'gb', 'quantity']),
  consumptions: PropTypes.array.isRequired,
  ui: PropTypes.object.isRequired,
};

export default ConsumptionDetailsDialog;
