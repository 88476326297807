import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as voucherTopup from './formConfigVoucherTopup';
import FormManager from '../../form/FormManager';
import VoucherTopupRequest from '../../../model/requests/VoucherTopupRequest';
import { hideDialog, showNotification } from '../../../actions/page/dialog';
import { trackMyState } from '../../../actions/tracking/event';
import { MYTRACK_NOTIFY_CODE_TOPUP_SUCCESS } from '../../../helpers/constants';

export const FORM_NAME_VOUCHER_TOPUP = 'voucher_topup';

/**
 * Form guides the user through the process of voucher topup.
 * @see https://confluence.db-n.com/x/OI3r
 */
class VoucherTopupForm extends PureComponent {
  constructor(...args) {
    super(...args);
    this.getSubmitRoute = this.getSubmitRoute.bind(this);
    this.onAfterSubmitSuccess = this.onAfterSubmitSuccess.bind(this);
  }

  onAfterSubmitSuccess() {
    const { dispatch, ui } = this.props;
    dispatch(hideDialog()); // this form is rendered in a dialog!
    dispatch(showNotification(ui.myVoucherTopupRepsonseSuccess));
    dispatch(trackMyState(MYTRACK_NOTIFY_CODE_TOPUP_SUCCESS));
  }

  getSubmitRoute(fieldMap, finalizedValues) {
    const { msisdn } = this.props.user.credentials;
    return new VoucherTopupRequest(msisdn, finalizedValues.code);
  }

  render() {
    return (
      <FormManager
        form={VoucherTopupForm.formName}
        submitRoute={this.getSubmitRoute}
        onAfterSubmitSuccess={this.onAfterSubmitSuccess}
        steps={[voucherTopup]}
        stepProps={this.props}
      />
    );
  }
}

/**
 * required by tracking!
 */
VoucherTopupForm.formName = FORM_NAME_VOUCHER_TOPUP;

VoucherTopupForm.propTypes = {
  user: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => ({ dispatch });

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VoucherTopupForm);
