import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { change, Field, touch } from 'redux-form';
import { showNotification } from '../../../../actions/page/dialog';
import { send } from '../../../../actions/request/send';
import { REQUEST_METHOD_PUT } from '../../../../helpers/constants';
import suitcss from '../../../../helpers/suitcss';
import FieldInput from '../../../basics/form/FieldInput';
import Link from '../../../basics/text/TextLink';
import FormHeader from '../FormHeader';
import FieldRadioGroup from '../../../basics/form/FieldRadioGroup';
import CustomerDataEmailRequest from '../../../../model/requests/CustomerDataEmailRequest';

class ContactMailFormPart extends PureComponent {
  onResetMailOption(ev) {
    const { dispatch, form, fieldMap } = this.props;
    ev.preventDefault();
    dispatch(change(form, fieldMap.mnpMailOption.name, '01'));
    dispatch(change(form, fieldMap.mnpMailInput.name, null));
  }

  async onSendCustomerData(ev) {
    const {
      dispatch,
      user,
      mailInputValue,
      ui,
      form,
      fieldMap,
    } = this.props;
    ev.preventDefault();
    if (!mailInputValue || mailInputValue === '') {
      dispatch(touch(form, fieldMap.mnpMailInput.name));
      return;
    }
    const request = new CustomerDataEmailRequest(user.credentials.msisdn, {
      payload: { email: mailInputValue },
      method: REQUEST_METHOD_PUT,
    });
    const response = await dispatch(send(request));
    if (response && response.isSuccess) {
      dispatch(showNotification(ui.myProfileContactEditSuccess));
      dispatch(change(form, fieldMap.mnpMailOption.name, '01'));
      dispatch(change(form, fieldMap.mnpMailInput.name, ''));
    }
  }

  renderCurrentMailCopy() {
    const { ui, user } = this.props;
    return `<div>${ui.mnpSimEmailQuestion} <span class="u-marginLeftM">${user.customerData ? user.customerData.email : ''}</span></div>`;
  }

  render() {
    const { ui, fieldMap, activeMailOption } = this.props;
    const showMailInput = activeMailOption === '02';
    return (
      <div className={suitcss({ utilities: ['marginBottomXL', 'sMarginVM'] })}>
        <FormHeader headline={ui.mnpSimEmailHeadline} copy={this.renderCurrentMailCopy()} />
        <Field {...fieldMap.mnpMailOption} component={FieldRadioGroup} utilities={['col12']} withToolTip asStack />
        {showMailInput && (
          <div className={suitcss({ utilities: ['marginTopS'] })}>
            <Field {...fieldMap.mnpMailInput} component={FieldInput} utilities={['marginV', 'col12', 'mlCol6']} withToolTip />
            <div className={suitcss({ utilities: ['grid', 'justifyBetween', 'sDirColumnReverse'] })}>
              <div className={suitcss({ utilities: ['flex', 'justifyCenter'] })}>
                <Link
                  utilities={['arrowLeft']}
                  element="button"
                  onClick={(ev) => this.onResetMailOption(ev, this)}
                >
                  {ui.mnpSimEmailButtonAbort}
                </Link>
              </div>
              <div className={suitcss({ utilities: ['flex', 'justifyCenter', 'sCol12', 'mCol5', 'lCol4'] })}>
                <Link
                  utilities={['col12']}
                  element="button"
                  buttonFilled
                  asButton
                  onClick={(ev) => this.onSendCustomerData(ev, this)}
                >
                  {ui.mnpSimEmailButtonSafe}
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

ContactMailFormPart.propTypes = {
  fieldMap: PropTypes.object.isRequired,
  formValues: PropTypes.object,
  ui: PropTypes.shape({
    myProfileContactEditSuccess: PropTypes.string,
    mnpSimEmailHeadline: PropTypes.string,
    mnpSimEmailQuestion: PropTypes.string,
    mnpSimEmailButtonSafe: PropTypes.string,
    mnpSimEmailButtonAbort: PropTypes.string,
  }),
  dispatch: PropTypes.func,
  form: PropTypes.string,
  activeMailOption: PropTypes.oneOf([
    '01',
    '02',
  ]),
  user: PropTypes.object,
  mailInputValue: PropTypes.string,
};

export default ContactMailFormPart;
