import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import ContentAccordionComposition from '../../components/compositions/content/ContentAccordion';
import { getModuleInstance } from '../../helpers/pageParser';
import GlobalSection from '../../components/basics/global/GlobalSection';

class ContentAccordion extends PureComponent {

  constructor(props, context) {
    super(props, context);
    this.state = {
      modules: [],
    };
  }

  async componentDidMount() {
    const { modules } = this.props.params;
    const instancesPromises = modules.map(module => getModuleInstance(module.etype, module));
    const instances = await Promise.all(instancesPromises);
    this.setState({
      modules: instances,
    });
  }

  render() {
    const { location, params, layoutSettings } = this.props;
    const { modules } = this.state;
    const { colorScheme, buttonTextExpanded, buttonTextInitial } = params;
    return (
      <GlobalSection
        theme={colorScheme}
        layoutSettings={layoutSettings}
        layout="expanded"
      >
        <ContentAccordionComposition
          theme={colorScheme}
          location={location}
          modules={modules}
          labelExpanded={buttonTextExpanded}
          label={buttonTextInitial}
          expanded
        />
      </GlobalSection>
    );
  }
}

ContentAccordion.propTypes = {
  params: PropTypes.shape({
    colorScheme: PropTypes.oneOf(['none', 'light', 'dark']),
    align: PropTypes.oneOf(['left', 'center', 'right']),
    buttonTextInitial: PropTypes.string,
    buttonTextExpanded: PropTypes.string,
    modules: PropTypes.arrayOf(PropTypes.object),
    expanded: PropTypes.bool,
  }).isRequired,
  layoutSettings: PropTypes.object,
  location: PropTypes.object.isRequired,
};

ContentAccordion.defaultProps = {
  params: { theme: 'light' },
};

export default ContentAccordion;
