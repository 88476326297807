import * as bridge from '../services/bridge';
import { QUERY_APPVIEW } from './constants';

export const isAppContextContractRenewal = site =>
  !!(site.appView && site.appContext === bridge.APP_CONTEXT_CONTRACT_RENEWAL);

export const isBridgeEnabled = site => !!site.appView;

export const isAppView = (site, location) => (
  (site && !!site.appView) || (location && !!location.query[QUERY_APPVIEW])
);

export const isWebView = () => bridge.getBrowserInfo().isWebView;
