import initForm from '../../form/FormInitializer';
import VoucherTopupForm from '../../../components/compositions/account/VoucherTopupForm';
import { desegmentize, segmentize4Digits } from '../../../helpers/str';

const createFieldMap = ({ ui }) => ({
  code: {
    name: 'code',
    label: ui.myVoucherTopupLabel,
    validation: {
      isRequired: true,
      pattern: /^\d{15,50}$/,

    },
    normalize: desegmentize,
    format: segmentize4Digits,
  },
});

// init component once, outside
const component = initForm()(VoucherTopupForm);

export const id = 'voucherTopup';

/**
 * @return {FormConfig}
 */
export const mapStateToFormConfig = (state) => ({
  component,
  fieldMap: createFieldMap(state),
});

export default mapStateToFormConfig;
