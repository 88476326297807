import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import suitcss from '../../../../helpers/suitcss';

import MnpFormPart from '../../form/belatedMnpFormPart/MnpFormPart';
import FormHeader from '../../form/FormHeader';
import FormSection from '../../form/FormSection';

class MyBelatedMnpFormContract extends PureComponent {

  render() {
    const { mnpHeadline } = this.props;

    return (
      <FormSection className={suitcss({}, this)} asGrid>
        <FormHeader headline={mnpHeadline} />
        <MnpFormPart {...this.props} />
      </FormSection>
    );
  }
}

MyBelatedMnpFormContract.propTypes = {
  mnpHeadline: PropTypes.string.isRequired,
};

export default MyBelatedMnpFormContract;
