import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';
import Link from '../../basics/text/TextLink';
import Chat from '../chat/Chat';

class GiveMeYourPriceChatDialog extends PureComponent {
  render() {
    const { user, chatBot, actions } = this.props;
    return (
      <div className={suitcss({}, this)}>
        <header className={suitcss({ descendantName: 'header' }, this)}>
          {actions.map((item, index) => (
            <Link
              onClick={item.action}
              element="button"
              utilities={['arrowLeft']}
              key={index}
            >
              {item.label}
            </Link>
          ))}
        </header>
        <Chat
          className={suitcss({ descendantName: 'chat' }, this)}
          chatBot={chatBot}
          user={user}
        />
      </div>
    );
  }
}

GiveMeYourPriceChatDialog.propTypes = {
  user: PropTypes.object.isRequired,
  chatBot: PropTypes.object.isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      action: PropTypes.function,
    }),
  ).isRequired,
};

GiveMeYourPriceChatDialog.defaultProps = {
  actions: [],
};

export default GiveMeYourPriceChatDialog;
