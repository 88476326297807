import PageModule from './PageModule';
import component from '../../containers/account/cancellation/CancellationForm';
import { UI_COLLECTION_MY_CANCELLATION_FORM } from '../../helpers/constants';
import { sendIfNecessary } from '../../actions/request/send';
import { fetchGdprConsentsAggregatedData } from '../../actions/request/registry';
import GdprConsentsRequest from '../requests/GdprConsentsRequest';

/**
 * Module to guide the user through the checkout process.
 * @see https://confluence.db-n.com/x/RqDc
 */
class CancellationFormModule extends PageModule {
  constructor(moduleData) {
    super(component, moduleData);
  }

  /**
   * @override
   */
  getRequiredUi() {
    return [UI_COLLECTION_MY_CANCELLATION_FORM];
  }

  prepare(pathname, page) {
    return async dispatch => {
      await dispatch(super.prepare(pathname, page));
      await dispatch(sendIfNecessary(new GdprConsentsRequest()));
      await dispatch(fetchGdprConsentsAggregatedData());
    };
  }
}

export default CancellationFormModule;
