import QueueableRequest from './QueueableRequest';
import { ACCOUNT_BOOKING_OVERVIEW_ENDPOINT } from '../../config/api';
import { bindParamsToRoute } from '../../helpers/url';
import { blockContentBasedOnUserScope } from '../../helpers/scope';
import { SCOPE_MYDASHBOARD } from '../../helpers/constants';

/**
 * Request to fetch the overview of the booked contract items
 */

export default class BookingOverviewRequest extends QueueableRequest {
  constructor(msisdn, options) {
    super(bindParamsToRoute(ACCOUNT_BOOKING_OVERVIEW_ENDPOINT, { msisdn }), options);
  }

  block(state) {
    return !blockContentBasedOnUserScope(state.user.scope, SCOPE_MYDASHBOARD);
  }
}
