import PageModule from './PageModule';
import component from '../../containers/contact/ContactForm';

/**
 * The ContactForm module forms a one-step contact form.
 * @see https://confluence.db-n.com/x/Ysjc
 */
class ContactFormModule extends PageModule {
  constructor(moduleData) {
    super(component, moduleData);
  }
}

export default ContactFormModule;

