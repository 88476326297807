import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { showFriendReferralHistoryDialog } from '../../actions/dialog/misc';
import connectUI from '../../components/basics/ui/UIConnector';
import FriendReferralStatusContainerComposition
  from '../../components/compositions/friendReferralStatus/FriendReferralStatusContainer';
import reducedReferralStatusItems from '../../helpers/friendReferralStatus';

function FriendReferralStatusContainer(props) {
  const {
    referralStatusItems,
    className,
    ui,
    showHistoryDialog,
  } = props;

  const statusItems = referralStatusItems && reducedReferralStatusItems(referralStatusItems, ui);

  return (
    <FriendReferralStatusContainerComposition
      className={className}
      title={ui.myFrdStatusHeadline}
      subline={ui.myFrdStatusSubline}
      referralStatusItems={statusItems}
      onBottomLinkClick={showHistoryDialog}
      bottomText={ui.myFrdHistoryLinkHint}
      bottomTextPlaceholder={ui.myFrdHistoryLinkPlaceholder}
    />
  );
}


FriendReferralStatusContainer.propTypes = {
  ui: PropTypes.object,
  className: PropTypes.string,
  referralStatusItems: PropTypes.array,
  showHistoryDialog: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  showHistoryDialog: () => dispatch(showFriendReferralHistoryDialog()),
});

export default compose(
  connectUI(),
  connect(null, mapDispatchToProps),
)(FriendReferralStatusContainer);

