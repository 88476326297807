import QueueableRequest from './QueueableRequest';
import { ACCOUNT_EVN_SETTING_ENDPOINT } from '../../config/api';
import { bindParamsToRoute } from '../../helpers/url';

export default class EvnSettingRequest extends QueueableRequest {
  constructor(msisdn, options) {
    super(bindParamsToRoute(ACCOUNT_EVN_SETTING_ENDPOINT, { msisdn }), options);
  }

  getSubsequentRequests({ user }) {
    return [
      new EvnSettingRequest(user.credentials.msisdn),
    ];
  }
}
