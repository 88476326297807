import PageModule from './PageModule';
import component from '../../containers/teaser/TeaserProducts';

/**
 * The module offers the possibility to display 1 - X teaser.
 * @see https://confluence.db-n.com/x/e6zc
 */
class TeaserProductsModule extends PageModule {
  constructor(moduleData) {
    super(component, moduleData);
  }
}

export default TeaserProductsModule;

