import React, { Component } from 'react';
import PropTypes from 'prop-types';
import GlobalSection from '../../basics/global/GlobalSection';
import ContentLoader from '../content/ContentLoader';

class DeferredContractSummaryForm extends Component {
  componentDidMount() {
    this.props.callContractSummaryEndpoint();
  }

  render() {
    this.props.triggerRedirect();

    return (
      <GlobalSection>
        <ContentLoader isLoaded={false} height={200} />
      </GlobalSection>
    );
  }
}

//------------------------------------------------------------------------------------------------
//  Deferred ContractSummary Form Proptypes
//------------------------------------------------------------------------------------------------

DeferredContractSummaryForm.propTypes = {
  callContractSummaryEndpoint: PropTypes.func.isRequired,
  triggerRedirect: PropTypes.func.isRequired,
};

export default DeferredContractSummaryForm;
