import PasswordResetFormPassword
  from '../../../components/compositions/account/passwordReset/PasswordResetFormPassword';

import initForm from '../../form/FormInitializer';
import {
  FORM_FIELD_PASSWORD_CONFIRM,
  MYTRACK_LOGIN_SAVE_NEW_PASSWORD,
} from '../../../helpers/constants';
import FormConfigProps from '../../../model/form/FormConfigProps';
import dates from '../../../helpers/dates';

const createFieldMap = ({ ui }) => {
  return {
    password: {
      name: 'password',
      label: ui.guiFormPassword,
      hint: ui.guiFormPasswordHint,
      type: 'password',
      validation: {
        isRequired: true,
        pattern: 'password',
      },
    },
    passwordRepeat: {
      name: FORM_FIELD_PASSWORD_CONFIRM,
      label: ui.guiFormPasswordRepeat,
      type: 'password',
      shouldSubmit: false,
      validation: {
        isRequired: true,
        equals: ['password'],
      },
    },
    token: {
      name: 'token',
      validation: {
        isRequired: true,
      },
    },
    birthday: {
      name: 'birthday',
      label: ui.guiFormDob,
      labelDay: ui.guiFormDobDay,
      labelMonth: ui.guiFormDobMonth,
      labelYear: ui.guiFormDobYear,
      optionsDays: dates.days,
      optionsMonths: dates.months,
      optionsYears: dates.years,
      type: 'dob',
      validation: {
        isRequired: true,
        pattern: 'date',
      },
    },
  };
};

// init component once, outside
const component = initForm()(PasswordResetFormPassword);

export const id = 'password';

/**
 * @return {FormConfig}
 */
export const mapStateToFormConfig = (state, props) => ({
  component,
  fieldMap: createFieldMap(props),
  formConfigProps: new FormConfigProps({
    labelSubmit: state.ui.myPasswordLostNewSubmit,
    dataSubmit: { mytracking: MYTRACK_LOGIN_SAVE_NEW_PASSWORD },
  }),
});
