import QueueableRequest from './QueueableRequest';
import { EECC_TARIFFOPTIONS_ORDER_INITIATE } from '../../config/api';
import { REQUEST_METHOD_PUT } from '../../helpers/constants';
import { bindParamsToRoute } from '../../helpers/url';

/**
 *
 *
 */

export default class EECCTariffOptionsOrderInitiateRequest extends QueueableRequest {
  constructor(payload, msisdn) {
    super(bindParamsToRoute(EECC_TARIFFOPTIONS_ORDER_INITIATE, { msisdn }), {
      method: REQUEST_METHOD_PUT,
      payload,
    });
  }
}
