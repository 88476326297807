/* global window, navigator */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field } from 'redux-form';

import FormSection from '../form/FormSection';
import FormHeader from '../form/FormHeader';
import FieldCheckbox from '../../basics/form/FieldCheckbox';

class ConsentHandlingForm extends PureComponent {
  isDntEnabled() {
    return window.doNotTrack || navigator.doNotTrack;
  }

  render() {
    const { ui, fieldMap, withoutBorder, isExpanded } = this.props;
    return (
      <FormSection
        asGrid
        withoutBorder={withoutBorder}
      >
        <FormHeader
          headline={ui.guiCookiesSettingsHeadline}
          copy={ui.txtGuiCookiesSettingsCopyWeb}
          ui={ui}
          utilities={!isExpanded && ['col12', 'lCol8']}
          isExpanded={isExpanded}
        />
        <Field
          isPreChecked
          isInactive
          isControlled
          {...fieldMap.required}
          component={FieldCheckbox}
          utilities={!isExpanded && ['col12', 'mlCol8']}
        />
        { /*
       {this.isDntEnabled() &&
        <TextCopy className="u-elementWarningBox" utilities={['col12', 'mlCol8']} raw>
          {ui.txtGdprConsentSettingsDntWarning}
        </TextCopy>
        } */ }
        <Field {...fieldMap.analytics} component={FieldCheckbox} utilities={!isExpanded && ['col12', 'mlCol8']} />
        { /* {!formValues.analytics &&
          <TextCopy className="u-elementWarningBox" utilities={['col12', 'mlCol8']} raw>
            {ui['txtGdprConsentSettingsLi-optWarningWeb']}
          </TextCopy>
        } */ }
        <Field {...fieldMap.contact} component={FieldCheckbox} utilities={!isExpanded && ['col12', 'mlCol8']} />
        { /* {!formValues.contact &&
        <TextCopy className="u-elementWarningBox" utilities={['col12', 'mlCol8']} raw>
          {ui['txtGdprConsentSettingsLi-omWarningWeb']}
        </TextCopy>
        } */ }
      </FormSection>
    );
  }
}

ConsentHandlingForm.propTypes = {
  ui: PropTypes.object.isRequired,
  fieldMap: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
  withoutBorder: PropTypes.bool,
  isExpanded: PropTypes.bool,
  isControlled: PropTypes.bool,
};

const mapStateToProps = state => ({
  ui: state.ui,
});

export default connect(mapStateToProps)(ConsentHandlingForm);
