import SuitCssify from 'react-suitcssify';

const getClassName = SuitCssify.utility;

export default (options, context) => {
  const suitCSSKeys = ['modifiers', 'states', 'utilities'];
  // iterate over all allowed keys and convert them from array to string
  const modifiedSuitcssObj = suitCSSKeys.reduce((obj, key) => {
    const classList = obj[key];

    // remove empty class list
    if (!classList) {
      const strippedObj = Object.assign({}, obj);
      delete strippedObj[key];
      return strippedObj;
    }

    if (process.env.NODE_ENV !== 'production') {
      if (!Array.isArray(classList)) {
        throw new TypeError(`suitcss expects ${key} to be an array`);
      }
    }

    // remove empty entries from array to allow usage of '&&' in components
    const filteredClassList = classList.filter(item => item);

    return Object.assign({}, obj, { [key]: filteredClassList.join(' ') });
  }, options);

  // add component name from context if defined
  if (context) {
    modifiedSuitcssObj.componentName = context.constructor.name;
  }

  if (process.env.NODE_ENV !== 'production') {
    if (!{}.hasOwnProperty.call(modifiedSuitcssObj, 'componentName')
      && {}.hasOwnProperty.call(modifiedSuitcssObj, 'descendantName')) {
      throw new TypeError('suitcss expects "componentName"');
    }
  }

  return getClassName(modifiedSuitcssObj);
};
