import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Field } from 'redux-form';
import { connect } from 'react-redux';
import suitcss from '../../../../helpers/suitcss';
import FieldInput from '../../../basics/form/FieldInput';
import FieldRadioGroup from '../../../basics/form/FieldRadioGroup';
import FieldDate from '../../../basics/form/FieldDate';
import Copy from '../../../basics/text/TextCopy';
import FormHeader from '../FormHeader';
import FieldAccordion from '../../../basics/form/FieldAccordion';
import SimTypeToggle from './SimTypeToggleFormPart';
import ContactMailFormPart from './ContactMailFormPart';
import { MARKET_PREPAID } from '../../../../helpers/constants';

class MnpFormPart extends PureComponent {
  renderOptions() {
    const { form, fieldMap } = this.props;
    return (
      <div className={suitcss({ utilities: ['grid', 'lCol10'] })}>
        {fieldMap.mnpPortingDateType.options.map((option, index) => (
          <FieldAccordion
            name={fieldMap.mnpPortingDateType.name}
            label={option.label}
            value={option.value}
            formIdentifier={form}
            utilities={['col12']}
            key={index}
            withToolTip
          >
            <Copy size="small" utilities={['colorGray100']} embedded raw>
              {option.hint}
            </Copy>
          </FieldAccordion>
        ))}
      </div>
    );
  }

  render() {
    const {
      mnpConfirmDataCopy,
      fieldMap,
      showContactNumber,
      mnpCancelationCopy,
      mnpCurrentDataHeadline,
      dispatch,
      ui,
      showSimArticleList,
      user,
      activeMailOption,
      form,
      formValues,
      isPUA,
    } = this.props;
    return (
      <div>
        {this.renderOptions()}
        <Copy utilities={['marginV']} embedded raw>{mnpCancelationCopy}</Copy>
        {!isPUA && (
          <SimTypeToggle
            fieldMap={fieldMap}
            dispatch={dispatch}
            showSimArticleList={showSimArticleList}
            ui={ui}
          />
        )}
        <ContactMailFormPart
          fieldMap={fieldMap}
          form={form}
          mailInputValue={formValues[fieldMap.mnpMailInput.name]}
          activeMailOption={activeMailOption}
          dispatch={dispatch}
          ui={ui}
          user={user}
        />
        <div className={suitcss({ utilities: ['grid', 'sCollapse'] })}>
          <FormHeader headline={mnpCurrentDataHeadline} copy={mnpConfirmDataCopy} />
          <Field {...fieldMap.mnpSalutation} component={FieldRadioGroup} utilities={['col12']} withToolTip />
          <Field {...fieldMap.mnpFirstName} component={FieldInput} utilities={['col12', 'mlCol6']} withToolTip />
          <Field {...fieldMap.mnpLastName} component={FieldInput} utilities={['col12', 'mlCol6']} withToolTip />
          <Field {...fieldMap.mnpBirthday} component={FieldDate} isDateGroup utilities={['col12', 'mlCol6']} withToolTip />
          <div className={suitcss({ utilities: ['col6', 'sHidden'] })} />
          {showContactNumber &&
            <Field {...fieldMap.mnpContactNumber} component={FieldInput} utilities={['col12', 'mlCol6']} withToolTip />
          }
        </div>
      </div>
    );
  }
}

const PropComponentOrString = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.element,
]);

MnpFormPart.propTypes = {
  showMsisdnAndProvider: PropTypes.bool,
  showContactNumber: PropTypes.bool,
  mnpBeforeEndCopy: PropComponentOrString,
  mnpWithEndCopy: PropComponentOrString,
  mnpCancelationCopy: PropTypes.string,
  mnpCancelationLinkLabel: PropTypes.string,
  mnpCurrentDataHeadline: PropTypes.string,
  mnpConfirmDataCopy: PropTypes.string.isRequired,
  activeTab: PropTypes.oneOf([
    'VTEN',
    'ASAP',
  ]),
  fieldMap: PropTypes.object.isRequired,
  ui: PropTypes.shape({
    mnpSimTypeDeviceCheckerCta: PropTypes.string,
    mnpSimTypeDeviceCheckerHint: PropTypes.string,
    mnpSimTypeHeadline: PropTypes.string,
    mnpSimTypeCopy: PropTypes.string,
  }),
  form: PropTypes.string,
  dispatch: PropTypes.func,
  showSimArticleList: PropTypes.func,
  isContractSimTypeEsim: PropTypes.bool,
  activeMailOption: PropTypes.oneOf([
    '01',
    '02',
  ]),
  user: PropTypes.object,
  formValues: PropTypes.object,
};

MnpFormPart.defaultProps = {
  activeTab: 'VTEN',
  showMsisdnAndProvider: true,
  showContactNumber: false,
  activeMailOption: '01',
  // remove this later, when fields are added in cms
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

const mapStateToProps = ({ user }) => {
  return {
    isPUA: user.market === MARKET_PREPAID,
  };
};


export default compose(connect(
  mapStateToProps,
  mapDispatchToProps,
))(MnpFormPart);
