import PageModule from './PageModule';
import component from '../../containers/checkout/CheckoutSummary';
import { checkoutNavCatcher } from '../../helpers/browserNavigation';

/**
 * The module is displayed on the Checkout Success page
 * and shows an overview of the purchased products.
 * @see https://confluence.db-n.com/x/EKbc
 */
class CheckoutSummaryModule extends PageModule {
  constructor(moduleData) {
    super(component, moduleData);
  }

  /**
   * @override
   */
  prepareBeforeMount() {
    return async (dispatch, getState) => {
      checkoutNavCatcher(dispatch, getState);
    };
  }
}

export default CheckoutSummaryModule;
