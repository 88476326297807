import QueueableRequest from './QueueableRequest';
import { REAUTHENTICATE_ENDPOINT } from '../../config/api';
import { REQUEST_METHOD_POST } from '../../helpers/constants';
import { bindParamsToRoute } from '../../helpers/url';
import { isBridgeEnabled } from '../../helpers/site';
import { reauthenticate } from '../../services/bridge';
import { createFakeResponse } from '../ApiResponse';

/**
 * Will reauthenticate the user via the refresh token.
 */
export default class ReauthRequest extends QueueableRequest {
  /**
   * @param {string} msisdn
   * @param {QueueableRequest} [previous] - The prior request whose failure,
   *     triggered this reauthentication.
   */
  constructor(msisdn, previous) {
    super(bindParamsToRoute(REAUTHENTICATE_ENDPOINT, { msisdn }), {
      method: REQUEST_METHOD_POST,
      payload: { },
      isDecamelizePayload: false, // sic!
    });
    this.previous = previous;
  }

  /**
   * Reauth is more important than any other request.
   * {@inheritDoc}
   */
  compare() {
    return true;
  }

  getFakeResponse(state) {
    const { site } = state;
    if (isBridgeEnabled(site)) {
      // we have to delegate the reauthentication in a webview the native app
      // and convert the result in a response-like object so that we don't
      // have to change the response handling
      return reauthenticate().then(appResponse => (
        createFakeResponse({
          data: {
            token: appResponse.token,
            scope: appResponse.scope,
          },
        }, { 'X-Nonce': appResponse.nonce })
      ));
    }
    return undefined;
  }
}
