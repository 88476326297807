import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  MYTRACK_SERVICE_SIMREP_STEP1,
} from '../../../helpers/constants';
import SimReplacementFormActivationStyle
  from '../../../components/compositions/account/simReplacement/SimReplacementFormActivationStyle';
import initForm from '../../form/FormInitializer';
import { trackMyState } from '../../../actions/tracking/event';
import FormConfigProps from '../../../model/form/FormConfigProps';

export const SHIP_ACTIVE = 'active';
export const SHIP_INACTIVE = 'inactive';

const createFieldMap = ({ ui }) => ({
  shipActivatedCard: {
    name: 'activated',
    options: [
      {
        label: ui.txtMySimrepActiveYesLabel,
        hint: ui.txtMySimrepActiveYesHint,
        value: SHIP_ACTIVE,
      },
      {
        label: ui.txtMySimrepActiveNoLabel,
        hint: ui.txtMySimrepActiveNoHint,
        value: SHIP_INACTIVE,
      },
    ],
    validation: {
      isRequired: true,
    },
  },
});

const mapStateToProps = (state, { fieldMap }) => ({
  initialValues: {
    [fieldMap.shipActivatedCard.name]: SHIP_ACTIVE,
  },
});

const component = compose(
  connect(mapStateToProps),
  initForm(),
)(SimReplacementFormActivationStyle);

export const id = 'simReplActivationStyle';

/**
 * @return {FormConfig}
 */
export const mapStateToFormConfig = (state, props) => ({
  id,
  component,
  fieldMap: createFieldMap(props),
  onStepEnter: () => (dispatch) => dispatch(trackMyState(MYTRACK_SERVICE_SIMREP_STEP1)),
  formConfigProps: new FormConfigProps({
    isSubmitDisabled: props.selectedSimOption &&
        !props.selectedSimOption.sufficientBalance,
    withRequiredHint: false,
  }),
});
