import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import oneLine from 'common-tags/lib/oneLine';
import suitcss from '../../../helpers/suitcss';
import { toDecimal } from '../../../helpers/money';
import { formatDate, getTariffVoDate } from '../../../helpers/date';
import Copy from '../../basics/text/TextCopy';
import Link from '../../basics/text/TextLink';

class ContractRenewalOptionsDialog extends PureComponent {
  constructor(...args) {
    super(...args);
    this.state = { isComparing: false };
    this.toggleComparison = this.toggleComparison.bind(this);
  }

  getItemName(item) {
    return item.subline ? `${item.headline} ${item.subline}` : item.headline;
  }

  getItemExtras(item) {
    const { ui, renewalType } = this.props;
    const basicFee = toDecimal({ currency: 'EUR', unit: item.basicFee });
    const transferFee = toDecimal({ currency: 'EUR', unit: item.transferFee });
    return oneLine`
      <p>${ui.myVvlDetailsPriceMonthly.replace('{VALUE}', basicFee)}</p>
      <p class="u-colorSuccess">${ui.myVvlDetailsPriceChange.replace('{VALUE}', transferFee)}</p>
      <p>${ui.myVvlDetailsFromDate.replace('{DATE}', formatDate(getTariffVoDate(item, renewalType)))}</p>
    `;
  }

  getItemCopy(item, withExtras) {
    const extras = withExtras ? this.getItemExtras(item) : '';
    return `<b>${this.getItemName(item)}</b>${item.shortCopy}${extras}`;
  }

  toggleComparison() {
    this.setState({ isComparing: !this.state.isComparing });
  }

  render() {
    const { bookedTariffVO, selectedTariffVO, ui, copy, withoutComparison } = this.props;
    return (
      <div className={suitcss({}, this)}>
        {!withoutComparison && (
          <div className={suitcss({ descendantName: 'link' }, this)} >
            <Link onClick={this.toggleComparison} element="button" withoutArrow>
              {ui.myVvlTariffChangeCompare}
            </Link>
          </div>
        )}
        <div
          className={suitcss({
            descendantName: 'comparison',
            states: [this.state.isComparing && 'open'],
          }, this)}
        >
          <div className={suitcss({ descendantName: 'item' }, this)} >
            <Copy
              className={suitcss({ descendantName: 'headline' }, this)}
              size="secondary"
              embedded
              raw
            >
              {ui.myVvlTariffChangeCurrentTariff}
            </Copy>
            <Copy
              className={suitcss({ descendantName: 'copy' }, this)}
              size="secondary"
              embedded
              raw
            >
              {this.getItemCopy(bookedTariffVO)}
            </Copy>
          </div>
          <div className={suitcss({ descendantName: 'item' }, this)} >
            <Copy
              className={suitcss({ descendantName: 'headline' }, this)}
              size="secondary"
              embedded
              raw
            >
              {ui.myVvlTariffChangeNewTariff}
            </Copy>
            <Copy
              className={suitcss({ descendantName: 'copy' }, this)}
              size="secondary"
              embedded
              raw
            >
              {this.getItemCopy(selectedTariffVO, true)}
            </Copy>
          </div>
        </div>
        <Copy
          className={suitcss({ descendantName: 'copy' }, this)}
          size="secondary"
          embedded
          raw
        >
          {copy}
        </Copy>
      </div>
    );
  }
}

ContractRenewalOptionsDialog.propTypes = {
  bookedTariffVO: PropTypes.object.isRequired,
  selectedTariffVO: PropTypes.object.isRequired,
  copy: PropTypes.object.isRequired,
  withoutComparison: PropTypes.bool,
  ui: PropTypes.object.isRequired,
  renewalType: PropTypes.string.isRequired,
};

export default ContractRenewalOptionsDialog;
