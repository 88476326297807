import React from 'react';
import PropTypes from 'prop-types';
import connectUI from '../ui/UIConnector';

import {
  shape as tariffShape,
  CATEGORY_SINGLE_PRODUCT,
  CATEGORY_PREPAID,
  CATEGORY_POSTPAID,
} from '../../../propTypes/tariff';

const getText = (tariff, ui) => {
  switch (tariff.category) {
    case CATEGORY_SINGLE_PRODUCT:
      return ui.guiFeeSingular;
    case CATEGORY_PREPAID:
      return ui.guiFeeSingularPayment;
    case CATEGORY_POSTPAID:
      return ui.guiFeeConnection;
    default:
      console.log(`StringTariffSingleFee // Unexpected tariff category \`${tariff.category}\`.`);
      return '';
  }
};

const StringTariffSingleFee = ({ tariff, ui, ...props }) =>
  (<span {...props}>{getText(tariff, ui)}</span>);

StringTariffSingleFee.propTypes = {
  tariff: tariffShape.isRequired,
  ui: PropTypes.shape({
    guiFeeSingular: PropTypes.string.isRequired,
    guiFeeSingularPayment: PropTypes.string.isRequired,
    guiFeeConnection: PropTypes.string.isRequired,
  }),
};

const component = connectUI()(StringTariffSingleFee);

// add static method
component.getText = getText;

export default component;
