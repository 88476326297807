import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import * as contractRenewalActions from '../../actions/dialog/contractRenewalActions';
import connectUI from '../../components/basics/ui/UIConnector';
import ContractRenewalFeeInfoComposition from '../../components/compositions/contractRenewal/ContractRenewalFeeInfo';
import { toFullCurrency } from '../../helpers/money';

class ContractRenewalFeeInfo extends PureComponent {
  getInfoCopy(contractRenewalFee) {
    const {
      ui,
      global,
    } = this.props;

    return (global ? ui.tariffsEarlyFeeGlobalHint : ui.tariffsEarlyFeeConditionHint).replace('{PRICE}', contractRenewalFee);
  }

  render() {
    const {
      inverted,
      showContractRenewalFeeInfoDialog,
      global,
      renewalFee,
    } = this.props;
    const contractRenewalFee = toFullCurrency({ unit: renewalFee, currency: 'EUR' });
    const copy = this.getInfoCopy(contractRenewalFee);

    return (
      <ContractRenewalFeeInfoComposition
        global={global}
        onContractRenewalFeeInfo={showContractRenewalFeeInfoDialog}
        contractRenewalFee={contractRenewalFee}
        inverted={inverted}
        copy={copy}
      />
    );
  }
}

ContractRenewalFeeInfo.propTypes = {
  renewalFee: PropTypes.number,
  showContractRenewalFeeInfoDialog: PropTypes.func.isRequired,
  global: PropTypes.bool,
  inverted: PropTypes.bool,
  ui: PropTypes.shape({
    tariffsEarlyFeeGlobalHint: PropTypes.string.isRequired,
    tariffsEarlyFeeConditionHint: PropTypes.string.isRequired,
  }),
};

function mapStateToProps(state) {
  const { user } = state;
  return {
    contractData: user.contractData,
  };
}

const mapDispatchToProps = {
  showContractRenewalFeeInfoDialog: contractRenewalActions.showContractRenewalFeeInfoDialog,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  connectUI(),
)(ContractRenewalFeeInfo);
