import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import connectUI from '../../basics/ui/UIConnector';
import Copy from '../../basics/text/TextCopy';
import Link from '../../basics/text/TextLink';


function PromotionCodeFormLink(props) {
  const { className, ui, isCompact, onClick } = props;
  return (
    <div className={className}>
      <Copy
        element="span"
        size={isCompact ? 'tertiary' : null}
        embedded
      >
        {ui.promotionCodeTitle}&nbsp;
      </Copy>
      <Link
        element="button"
        utilities={[
          'alignLeft',
          isCompact && 'fontCondensed',
          isCompact && 'textSizeTertiary',
        ]}
        onClick={onClick}
      >
        {ui.promotionCodeEnterShort}
      </Link>
    </div>
  );
}

PromotionCodeFormLink.propTypes = {
  className: PropTypes.string,
  ui: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  isCompact: PropTypes.bool,
};

export default compose(
  connectUI(),
)(PromotionCodeFormLink);
