import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  BILLING_MODE_POST,
  QUERY_ORDER_ID,
  QUERY_IDENT_METHOD,
  QUERY_IDENT_PROVIDER,
} from '../../helpers/constants';
import { sanitizeLocationParams } from '../../helpers/url';
import GlobalSection from '../../components/basics/global/GlobalSection';
import
SuccessInfoComposition,
{
  IDENT_METHOD_VIDEO,
  IDENT_METHOD_STORE,
  IDENT_PROVIDER_DEUTSCHE_POST,
  IDENT_PROVIDER_WEBID,
}
  from '../../components/compositions/form/SuccessInfo';
import * as registryActions from '../../actions/request/registry';
import * as videoIdentActions from '../../actions/dialog/videoIdentActions';

const getPrepareDialogCopy = (identMethod, identProvider, ui) => {
  switch (identMethod) {
    case IDENT_METHOD_VIDEO:
      switch (identProvider) {
        case IDENT_PROVIDER_WEBID:
          return ui.txtVididentPrepareWebidCopy;
        case IDENT_PROVIDER_DEUTSCHE_POST:
          return ui.txtVididentPreparePostCopy;
        default:
          return ui.txtVididentPrepareIdentificationCopy;
      }
    case IDENT_METHOD_STORE:
      switch (identProvider) {
        case IDENT_PROVIDER_DEUTSCHE_POST:
          return ui.txtVididentPrepareWebidCopy;
        default:
          return ui.txtVididentPrepareIdentificationCopy;
      }
    default:
      return ui.txtVididentPrepareIdentificationCopy;
  }
};

const getPostponeDialogCopy = (identMethod, identProvider, ui) => {
  switch (identMethod) {
    case IDENT_METHOD_VIDEO:
      switch (identProvider) {
        case IDENT_PROVIDER_WEBID:
          return ui.txtVididentPostponeIdentificationWebidCopy;
        case IDENT_PROVIDER_DEUTSCHE_POST:
          return ui.txtVididentPostponeIdentificationPostCopy;
        default:
          return ui.txtVididentPostponeIdentificationCopy;
      }
    case IDENT_METHOD_STORE:
      switch (identProvider) {
        case IDENT_PROVIDER_DEUTSCHE_POST:
          return ui.txtStoreidentPostponeIdentificationPostCopy;
        default:
          return ui.txtVididentPostponeIdentificationCopy;
      }
    default:
      return ui.txtVididentPostponeIdentificationCopy;
  }
};

const getPrepareDialogHeadline = (identMethod, identProvider, ui) => {
  switch (identMethod) {
    case IDENT_METHOD_STORE:
      switch (identProvider) {
        case IDENT_PROVIDER_DEUTSCHE_POST:
          return ui.storeidentPostponeIdentificationPostHeadline;
        default: return ui.vididentPrepareHeadline;
      }
    default: return ui.vididentPrepareHeadline;
  }
};

const getPostponeDialogHeadline = (identMethod, identProvider, ui) => {
  switch (identMethod) {
    case IDENT_METHOD_STORE:
      switch (identProvider) {
        case IDENT_PROVIDER_DEUTSCHE_POST:
          return ui.storeidentPostponeIdentificationPostHeadline;
        default: return ui.vididentPostponeIdentificationHeadline;
      }
    default: return ui.vididentPostponeIdentificationHeadline;
  }
};

class SuccessInfo extends PureComponent {

  componentDidMount() {
    const { isCustomerDataRequired } = this.props;
    if (isCustomerDataRequired) {
      this.props.fetchContractData();
    }
  }

  render() {
    const {
      type,
      contractId,
      onConfirm,
      onCancel,
      user,
      identMethod,
      identProvider,
    } = this.props;
    if (!type) { return null; }

    return (
      <GlobalSection>
        <SuccessInfoComposition
          type={type}
          contractId={contractId}
          user={user}
          onConfirm={onConfirm}
          onCancel={onCancel}
          identMethod={identMethod}
          identProvider={identProvider}
        />
      </GlobalSection>
    );
  }
}

SuccessInfo.propTypes = {
  type: PropTypes.string,
  contractId: PropTypes.string,
  isCustomerDataRequired: PropTypes.bool,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  fetchContractData: PropTypes.func,
  user: PropTypes.object,
  identMethod: PropTypes.string,
  identProvider: PropTypes.string,
};

const getType = (pathname, user, sitemap) => {
  switch (pathname) {
    case sitemap.CheckoutVidIdentPrepaidSuccessRoute.url:
      return 'checkoutPrepaidSuccess';
    case sitemap.CheckoutVidIdentPrepaidFailureRoute.url:
      return 'checkoutPrepaidFailure';
    case sitemap.CheckoutSuccessPrepaidRoute.url:
      return 'checkoutPrepaid';
    case sitemap.CheckoutSuccessESimOnlyRoute.url:
      return 'checkoutESimOnly';
    case sitemap.ActivationVidIdentSuccessRoute.url:
    case sitemap.ActivationVidIdentRossmanSuccessRoute.url:
      return 'activationPrepaidSuccess';
    case sitemap.ActivationVidIdentFailureRoute.url:
    case sitemap.ActivationVidIdentRossmanFailureRoute.url:
      return 'activationPrepaidFailure';
    case sitemap.RossmannSimActivationSuccessRoute.url:
    case sitemap.SimActivationSuccessRoute.url:
      return 'activationPrepaid';
    case sitemap.MyCancellationSuccessRoute.url:
      return 'canceled';
    case sitemap.MyCancellationWithoutABFSuccessRoute.url:
      return 'canceledWithout';
    case sitemap.MyTariffChangeSuccessRoute.url:
      if (!user.contractData) {
        return null;
      } else if (user.contractData.billingMode === BILLING_MODE_POST) {
        return 'exists';
      }
      return 'new';
    case sitemap.MyContractRenewalSuccessRoute.url:
    case sitemap.MyContractRenewalSuccessRoutePre.url:
      return 'contractRenewal';
    default:
      return null;
  }
};

const shouldFetchCustomerData = (pathname, sitemap, user) => (
  (
    pathname === sitemap.MyContractRenewalSuccessRoute.url ||
    pathname === sitemap.MyContractRenewalSuccessRoutePre.url ||
    pathname === sitemap.MyTariffChangeSuccessRoute.url
  ) && !!user.credentials.msisdn
);

const shouldShowVideoIdentData = (pathname, sitemap) => (
  pathname === sitemap.CheckoutSuccessPrepaidRoute.url ||
  pathname === sitemap.CheckoutSuccessESimOnlyRoute.url ||
  pathname === sitemap.SimActivationSuccessRoute.url ||
  pathname === sitemap.RossmannSimActivationSuccessRoute.url
);

const mapStateToProps = ({
 user, site, order, ui,
}, { location }) => {
  const orderId = sanitizeLocationParams(location, QUERY_ORDER_ID);
  const response = (order[orderId] && order[orderId].response) || {};
  return {
    contractId: response.contractId,
    orderId,
    query: location.query,
    type: getType(location.pathname, user, site.sitemap),
    isCustomerDataRequired: shouldFetchCustomerData(location.pathname, site.sitemap, user),
    isVideoIdentDataRequired: shouldShowVideoIdentData(location.pathname, site.sitemap),
    identMethod: sanitizeLocationParams(location, QUERY_IDENT_METHOD),
    identProvider: sanitizeLocationParams(location, QUERY_IDENT_PROVIDER),
    user,
    ui,
  };
};

const mapDispatchToProps = {
  fetchContractData: registryActions.fetchContractData,
  startVideoIdent: videoIdentActions.videoIdentPrepareDialog,
  postponeVideoIdent: videoIdentActions.videoIdentPostponeDialog,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const {
    isVideoIdentDataRequired,
    orderId,
    identMethod,
    identProvider,
    ui,
  } = stateProps;
  const prepareCopy = getPrepareDialogCopy(identMethod, identProvider, ui);
  const postponeCopy = getPostponeDialogCopy(identMethod, identProvider, ui);
  const prepareHeadline = getPrepareDialogHeadline(identMethod, identProvider, ui);
  const postponeHeadline = getPostponeDialogHeadline(identMethod, identProvider, ui);
  const skipPrepareDialog = identMethod === IDENT_METHOD_STORE;
  const {
    startVideoIdent,
    postponeVideoIdent,
  } = dispatchProps;
  return {
    ...stateProps,
    ...ownProps,
    fetchContractData: dispatchProps.fetchContractData,
    onConfirm: isVideoIdentDataRequired
      ? () => startVideoIdent(prepareHeadline, prepareCopy, orderId, skipPrepareDialog)
      : null,
    onCancel: isVideoIdentDataRequired
      ? () => postponeVideoIdent(postponeHeadline, postponeCopy)
      : null,
  };
};


export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(SuccessInfo);
