/**
 * @file
 *
 * This reducer stores texts and wordings as they have been defined in the cms.
 *
 * Since we do not store any texts in our js-code, we have to download most texts
 * and wordings before any of our components or dialogs can be rendered.
 */

import { REQUEST_RECEIVED_RESPONSE } from '../actions/request/basic';
import TextsRequest from '../model/requests/TextsRequest';
import UiElementsRequest from '../model/requests/UiElementsRequest';
import { capitalize } from '../helpers/str';

export default (state = {}, action) => {
  switch (action.type) {
    case REQUEST_RECEIVED_RESPONSE: {
      const { request } = action.meta;
      const responseBody = action.payload.response.body.data;

      if (request instanceof UiElementsRequest) {
        return { ...state, ...responseBody };
      }

      if (request instanceof TextsRequest) {
        const texts = {};
        // we prefix text elements with 'txt' to prevent collisions with uiElements
        Object.keys(responseBody).forEach(id => { texts[`txt${capitalize(id)}`] = responseBody[id].copy; });
        return { ...state, ...texts };
      }

      return state;
    }
    default:
      return state;
  }
};
