import React from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';

import Copy from '../text/TextCopy';

const componentName = 'UIAlertBar';
function UIAlertBar(props) {
  const { theme, content } = props;
  const isStringContent = typeof content === 'string';
  const isTextSizeSmall = isStringContent && (theme === 'primary' || theme === 'secondary' || theme === 'tertiary');
  return (
    <div className={suitcss({ componentName, modifiers: [theme] })}>
      <div className={suitcss({ componentName, descendantName: 'inner' })}>
        {!isStringContent && props.content}
        {isStringContent &&
          <Copy
            element="div"
            size={isTextSizeSmall ? 'secondary' : null}
            embedded
            raw
          >
            {props.content}
          </Copy>
        }
      </div>
    </div>
  );
}

UIAlertBar.propTypes = {
  theme: PropTypes.oneOf(['default', 'primary', 'secondary', 'tertiary', 'highlighted']),
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.array,
  ]).isRequired,
};

UIAlertBar.defaultProps = {
  theme: 'default',
};

export default UIAlertBar;
