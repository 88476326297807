import QueueableRequest from './QueueableRequest';
import { CREATE_FLEXMODE_TOKEN_ENDPOINT } from '../../config/api';
import { bindParamsToRoute } from '../../helpers/url';
import { REQUEST_METHOD_POST } from '../../helpers/constants';

export default class FlexmodeTokenRequest extends QueueableRequest {
  constructor(market, paymentType, payload) {
    super(bindParamsToRoute(CREATE_FLEXMODE_TOKEN_ENDPOINT, { market, paymentType }), {
      payload,
      method: REQUEST_METHOD_POST,
    });
  }
}
