/* global window */
import React from 'react';
import PropTypes from 'prop-types';
import oneLine from 'common-tags/lib/oneLine';

const TextPopup = (props) => (
  <a
    className={props.className}
    href={props.href}
    target="_blank"
    rel="noopener noreferrer"
    title={props.title}
    onClick={(ev) => {
      ev.preventDefault();
      TextPopup.openPopup(props.href, props.title);
    }}
    aria-label={props.ariaLabel}
  >
    {props.children}
  </a>
);

TextPopup.openPopup = (url, title) => {
  const width = 500;
  const height = 500;
  const screenWidth = window.screen.availWidth || 1280;
  const screenHeight = window.screen.availHeight || 900;

  const posY = Math.floor((screenHeight / 2) - (height / 2));
  const posX = Math.floor((screenWidth / 2) - (width / 2));

  const popup = window.open(
    url,
    title,
    oneLine`
      width=${width},
      height=${height},
      left=${posX},
      top=${posY},
      location=0,
      menubar=0,
      toolbar=0,
      status=0,
      scrollbars=1,
      resizable=1
    `,
  );
  popup.focus();
  return popup;
};

TextPopup.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  className: PropTypes.string,
  ariaLabel: PropTypes.string,
};

TextPopup.defaultProps = {
  className: 'TextPopup',
};

export default TextPopup;
