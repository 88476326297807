import QueueableRequest from './QueueableRequest';
import { ACCOUNT_PROMOTION_ENDPOINT } from '../../config/api';
import { bindParamsToRoute } from '../../helpers/url';
import { blockContentBasedOnUserScope } from '../../helpers/scope';
import { SCOPE_MYDASHBOARD } from '../../helpers/constants';

/**
 * Fetches a single promotion.
 *
 * Note: Make sure beforehand whether this promotion is actually available
 * for the specified msisdn.
 */
export default class MyPromotionRequest extends QueueableRequest {
  constructor(msisdn, promotionId, options) {
    super(bindParamsToRoute(ACCOUNT_PROMOTION_ENDPOINT, { msisdn, promotionId }), options);
  }

  block(state) {
    return !blockContentBasedOnUserScope(state.user.scope, SCOPE_MYDASHBOARD);
  }
}
