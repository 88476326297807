import { connect } from 'react-redux';
import { compose } from 'redux';
import { isSubmitting } from 'redux-form';

import initForm from '../../form/FormInitializer';
import MyBelatedMnpFormConfirm from '../../../components/compositions/account/belatedMnp/MyBelatedMnpFormConfirm';
import * as dateHelper from '../../../helpers/date';
import { trackMyState } from '../../../actions/tracking/event';
import {
  E_SIM,
  FORM_ID_BMNP_CONFIRM,
  MYTRACK_SERVICE_BMNP_SUMMARY,
} from '../../../helpers/constants';
import FormConfigProps from '../../../model/form/FormConfigProps';

const createFieldMap = ({ ui }) => ({
  mnpConsent: {
    name: 'porting_consent',
    label: ui.txtMnpFormConsent,
    shortLabel: ui.mnpFormConsentShort,
    validation: {
      isRequired: true,
      valueEquals: true,
    },
  },
});

const mapStateToProps = ({ user }, { ui, mobileProviders, params, fieldMap, formValues }) => {
  const {
    mnpHeadline,
    mnpCopy,
    mnpDisabledHeadline,
    mnpDisabledCopy,
    mnpLabel,
    mnpBeforeEndCopy,
    mnpWithEndCopy,
    mnpConfirmDataCopy,
  } = params;

  const { paymentData, credentials } = user;
  const { msisdn } = credentials;

  const mnpProvider = mobileProviders.find(
    ({ id }) => id === formValues[fieldMap.mnpProviderKey.name],
  );
  let finalSalutation = '';
  if (formValues[fieldMap.mnpSalutation.name] === '01') {
    finalSalutation = ui.guiFormSalutationMale;
  } else if (formValues[fieldMap.mnpSalutation.name] === '02') {
    finalSalutation = ui.guiFormSalutationFemale;
  }

  const simType = formValues.mnp_sim_type;
  const finalSimType = simType === E_SIM ? ui.mnpSimTypeEsim : ui.mnpSimTypeCard;

  const data = {
    msisdn: formValues[fieldMap.mnpMsisdn.name],
    provider: mnpProvider.name,
    salutation: finalSalutation,
    firstName: formValues[fieldMap.mnpFirstName.name],
    lastName: formValues[fieldMap.mnpLastName.name],
    birthday: dateHelper.formatDate(formValues[fieldMap.mnpBirthday.name]),
    email: user.customerData && user.customerData.email,
    simTypeFormer: user.contractData && user.contractData.simCard.simType === E_SIM
      ? ui.mnpSimTypeEsim : ui.mnpSimTypeCard,
    simTypeNew: finalSimType,
  };

  return {
    paymentData,
    data,
    mnpHeadline,
    mnpCopy,
    mnpDisabledHeadline,
    mnpDisabledCopy,
    mnpLabel,
    mnpBeforeEndCopy,
    mnpWithEndCopy,
    mnpConfirmDataCopy,
    processId: user.EECC.bmnp.processId,
    msisdn,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const component = compose(
  connect(mapStateToProps, mapDispatchToProps),
  initForm(),
)(MyBelatedMnpFormConfirm);

/**
 * @return {FormConfig}
 */
export const mapStateToFormConfig = (state, { ui, form }) => {
  const fieldMap = createFieldMap(state);
  const { mnpConsent } = fieldMap;
  const isConsentUnChecked = state.form[form]
    && state.form[form].syncErrors && !!state.form[form].syncErrors[mnpConsent.name];
  return {
    id: FORM_ID_BMNP_CONFIRM,
    label: ui.cfoNavMnp,
    component,
    onStepEnter: () => (dispatch) => dispatch(trackMyState(MYTRACK_SERVICE_BMNP_SUMMARY)),
    fieldMap,
    formConfigProps: new FormConfigProps({
      labelSubmit: state.ui.guiOrderFree,
      withRequiredHint: false,
      isSubmitDisabled: isConsentUnChecked || isSubmitting(form)(state),
    }),
  };
};
export default mapStateToFormConfig;
