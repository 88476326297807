import { fetchUiElements } from '../../actions/request/registry';
import { camelCase, pascalCase } from '../../helpers/str';

/**
 * All modules that are included in a page ought to extend this class
 */
class PageModule {
  /**
   * @param {React.Component} component
   * @param {Object} moduleData - raw module data as received from server
   *     the moduleData is a server-side rendered serialized instance
   *     of PageModule, use this option to dehydrate the model.
   * @param {string} moduleData.eid
   * @param {string} moduleData.etype
   * @param {string} moduleData.etitle
   * @param {object} moduleData.params
   * @param {object} moduleData.entities
   * @param {object} [moduleData.uiElements]
   */
  constructor(component, moduleData) {

    if (moduleData.isHydratable) {

      // has been rendered on server side, moduleData is not raw but already fits our model
      Object.assign(this, moduleData);

    } else {

      // map data to instance variables
      const dataMapper = {
        eid: { name: 'eid', normalize: data => data },
        etype: { name: 'etype', normalize: data => pascalCase(data) },
        etitle: { name: 'etitle', normalize: data => data },
        uiElements: { name: 'ui', normalize: data => data, default: {} },
        params: { name: 'params', normalize: data => data, default: {} },
        entities: {
          name: 'entities',
          normalize: data => data.map(e => ({ eid: e.eid, etype: camelCase(e.etype) })),
          default: [],
        },
      };

      Object.values(dataMapper).forEach(entry => {
        this[entry.name] = (entry.default || null);
      });

      this.params = {};
      Object.entries(moduleData).forEach(([key, data]) => {
        if (dataMapper[key]) {
          this[dataMapper[key].name] = dataMapper[key].normalize(data);
        } else {
          // we store everything else under params
          this.params[key] = data;
        }
      });
    }
    this.component = component;
    this.isHydratable = true;
    this.shouldShowLoader = false;
  }

  async loadComponent() {
    if (this.component instanceof Promise) {
      this.component = (await this.component).default;
    }
  }

  /**
   * In the context of SEO, primary modules are leading modules in a page.
   *
   * Override this method to force a module never to be primary.
   * Per default, all modules can be potentially primary.
   *
   * @return {boolean}
   */
  canBePrimary() {
    return true;
  }

  /**
   * Returns true if the module's index in the page matches the first module that can be primary.
   *
   * @param  {array<PageModule>} modules - all modules contained in the page
   * @param  {number} index - index of the module in the page
   * @return {boolean}
   */
  isPrimaryModule(modules, index) {
    return index === modules.findIndex(m => m.canBePrimary());
  }

  /**
   * Called after a page that includes this module has been newly fetched from the server.
   * Allows us to initialize the module, which typically means that we fetch module-related data
   * from the server.
   *
   * PLEASE NOTE:
   * override this function in a module to achieve a more individual preparation, but don't
   * forget to call super.prepare() first.
   *
   * @param {string} pathname - the url pathname of the requested page
   * @param {object} page - the page that includes this module
   * @return {Function} a redux-thunk action
   */
  prepare(pathname, page) { // eslint-disable-line no-unused-vars
    return dispatch => dispatch(fetchUiElements(this.getRequiredUi()));
  }

  /**
   * Returns an action creator (e.g. a redux-thunk action) that is called each time a
   * module is about to be mounted or `null` if nothing needs to be prepared.
   *
   * Override this function where needed! Per default, this action creator doesn't create
   * any action.
   *
   * @param {object} props - the modules props
   * @return {function|null} Either return an action creator that will do something or null
   *     if nothing needs to be prepared. In case an action creator is returned, it may
   *     return a thunk action that returns an object (or a promise that resolves in an object)
   *     whose properties will be injected into the component that is about to be rendered
   */
  prepareBeforeMount(props) { // eslint-disable-line no-unused-vars
    // overide me
  }

  /**
   * Override this function to specify which ui elements are needed to render a module.
   * @return Array
   */
  getRequiredUi() {
    return [];
  }

  /**
   * Per default, all modules are critical, meaning if it crashes, the whole page should crash.
   * @return {boolean}
   */
  static isCritical() {
    return true;
  }
}


export default PageModule;
