import { createSelector } from 'reselect';
import oneLine from 'common-tags/lib/oneLine';

import { devWarning } from '../helpers/meta';
import { getContext, getMatchingPromotions, etypePromoTargetGroupMap, getBookablePromotions } from '../helpers/promotions';
import { getRealmByContext } from '../helpers/context';
import { createCurrentPageSelector } from './page';
import { getEntityById } from './misc';
import { getEid, hasEntity, mapEntitiesToStoreObjects } from '../helpers/entity';
import { AVAILABILITY_ACCOUNT } from '../helpers/constants';
import { isAccountUrl } from '../config/api';

/**
 * Selects matching callouts for the bookable promotions associated with the account.
 */
const getMatchingAccountCallouts = (
  callouts,
  targets,
  bookablePromotions,
  theme,
  location,
  context,
) => {
  if (!bookablePromotions || !location || !isAccountUrl(location)) {
    return [];
  }

  return callouts.filter(callout =>
    callout.availability && callout.targets && callout.promoIds
    && callout.availability.includes(AVAILABILITY_ACCOUNT)
    && callout.targets.includes(targets[0])
    // checks if there are promoIds in the bookablePromotions array
    && callout.promoIds.some(id => bookablePromotions.some(entry => entry.id === id && (entry.context === context || (context && context.includes(entry.context) && entry.hardwareRequirement !== 'sim_only'))))
    && callout.theme === theme);
};

/**
 * This selector will consider the current environment and state
 * of the application to decide which callout entity to return to
 * the caller. If no callout matches, undefined will be returned.
 */
const getMatchingCallout =
  (context, targets, callouts, theme = 'badge', orderProcess, entities, bookablePromotions, curPage, location, internalIdMap, allowMultiple) => {
    // first we turn the provided targets into entity objects
    const targetEntities = Object
      .values(mapEntitiesToStoreObjects(targets, {
        ...entities,
        // note: callouts can also be attached to page modules, e.g. a headline, so we need to
        // include modules (https://jira.db-n.com/browse/OT-6163)
        page: curPage.modules.reduce((obj, module) => ({ ...obj, [module.eid]: module }), {}),
      }, internalIdMap))
      .filter(Boolean); // some entities might not have been fetched yet; simply ignore

    // next we try to figure out whether the user is in the process of selecting a product.
    // if so, we will add the selected entity to the targets so promos matching
    // tariff-hardware combos can be displayed. (@see https://jira.db-n.com/browse/OP-198)
    const promoTargetEntities = [...targetEntities];
    const hasProcessHeader = !!curPage.modules.find(module => module.etype === 'NavProcess');
    const processEntities = orderProcess.entities
      .map(entityStub => getEntityById(entities, entityStub.eid))
      .filter(Boolean); // entities might not have been fetched yet; ignore
    /**
     * @TODO deprecated?
     * confirmedEntityStub in orderProcess always only one element (first chosen),
     * can't consider second element, promoTargetEntities was used in getMatchingPromotions
     */
    if (hasProcessHeader && processEntities.length && orderProcess.confirmedEntityStub) {
      const confirmedEntity = getEntityById(entities, orderProcess.confirmedEntityStub.eid);
      if (confirmedEntity) {
        // note: the tariff entity should only be considered when a callout is triggered by a promo,
        // hence, we need to use a different list for that case so it does not mess up non-promo
        // callout targets. this solves @see https://jira.db-n.com/browse/OT-6306
        promoTargetEntities.push(confirmedEntity);
      }
    }

    const accountCallouts = getMatchingAccountCallouts(
      callouts,
      targets,
      bookablePromotions,
      theme,
      location,
      context,
    );

    if (accountCallouts.length && !allowMultiple) {
      return accountCallouts[0];
    }

    // filter callouts that do not match the etype, theme, realm or target definition
    const realm = getRealmByContext(context);
    const matchingCallouts = callouts.filter(callout => (
      callout.etype === 'callout_badge'
      && (callout.theme || 'badge') === theme
      && (!callout.availability.length || callout.availability.includes(realm))
      && targetEntities.some(target => callout.targets.includes(target.eid))
    ));
    const matchingPromos = getMatchingPromotions(
      bookablePromotions,
      context,
      // check processEntities and also hasProcessHeader
      // to set the correct targets containing the current page
      // otherwise the targets are still the ones from the orderProcess
      // when we go back to tariffs page
      (processEntities.length && hasProcessHeader ? processEntities : promoTargetEntities)
        .filter(entity => !!etypePromoTargetGroupMap[entity.etype]),
    );

    // get all callouts that are related to a matching promotion
    const matchingPromoCallouts = matchingCallouts.filter(callout => (
      matchingPromos.some(promo => callout.promoIds.includes(promo.id))
    ));

    if (matchingPromoCallouts.length && !allowMultiple) {
      return matchingPromoCallouts[0];
    }

    // get all callouts related to a bookable promotion with a different target than the promo
    const matchingTargetCallouts = matchingCallouts.filter(callout => (
      bookablePromotions.some(promo => callout.promoIds.includes(promo.id))
      && promoTargetEntities.filter(entity => !etypePromoTargetGroupMap[entity.etype])
        .some(entity => callout.targets.includes(entity.eid))
    ));

    if (matchingTargetCallouts.length && !allowMultiple) {
      return matchingTargetCallouts[0];
    }

    // if some of the promo callouts match, we choose one of them,
    // otherwise, we use the list of regular non promo-related batches
    const canditates = matchingCallouts.filter(callout => (
      !callout.promoIds.length // ignore callouts that are related to promos
      && callout.targets.some(calloutTarget => hasEntity(targetEntities, getEid(calloutTarget)))
    ));

    const matchingCalloutsGroup = accountCallouts.concat(
      matchingPromoCallouts,
      matchingTargetCallouts,
      canditates,
    );
    const uniqueMatchingCallouts = [...new Set(matchingCalloutsGroup)];

    // return all matching callouts
    if (allowMultiple) {
      return targetEntities.map(entry => (
        uniqueMatchingCallouts.filter(c => c.targets.some(target => getEid(target) === entry.eid))
      )).filter(entry => !!entry)[0];
    }

    // return the first callout that matches the first target in the order it has been passed
    // for each target try to find the first match and return it
    // while ensuring that the target order will be respected

    return targetEntities.map(entry => (
      canditates.find(c => c.targets.some(target => getEid(target) === entry.eid))
    )).filter(entry => !!entry)[0];
  };

export const getLabelCallout = (eid, state) => getMatchingCallout(
  getContext(state),
  [eid],
  state.callout.callouts,
  'label',
  state.orderProcess,
  state.entities,
  getBookablePromotions(state),
  createCurrentPageSelector()(state),
  state.site.routing.requestedLocation,
  state.site.internalIdMap,
);

export const getAllLabelCallouts = (list, state) => list.reduce((obj, e) => {
  const calloutLabel = getLabelCallout(e.eid, state);
  if (calloutLabel) {
    obj[e.eid] = calloutLabel; // eslint-disable-line
  }
  return obj;
}, {});

export const createCalloutSelector = () => createSelector(
  (state, ownProps) => {
    const context = ownProps.context || getContext(state);
    if (!context) {
      devWarning(oneLine`
          Context missing for the Callout targeting ${ownProps.targets}.
          Please supply a valid context so callouts can be matched against promotions.
        `);
    }
    return context;
  },
  (state, ownProps) => ownProps.targets,
  state => state.callout.callouts,
  (state, ownProps) => ownProps.theme,
  state => state.orderProcess,
  state => state.entities,
  (state) => getBookablePromotions(state),
  createCurrentPageSelector(),
  state => state.site.routing.requestedLocation,
  state => state.site.internalIdMap,
  (state, ownProps) => ownProps.allowMultiple,
  getMatchingCallout,
);
