import {
  requestInProgess,
  receivedResponse,
  receivedError,
  fetch,
} from './basic';

/**
 * Will immediately execute the specified request.
 *
 * @param {QueueableRequest} request
 * @returns {Promise}
 */
export const sendDirect = request => async dispatch => {
  try {
    dispatch(requestInProgess(request));
    const response = await dispatch(fetch(request));
    dispatch(receivedResponse(request, response));
    return response;
  } catch (error) {
    dispatch(receivedError(request, error));
    throw error;
  }
};
