import PageModule from './PageModule';
import component from '../../containers/faq/FAQ';

/**
 * Module to display FAQs on the page
 * @see https://confluence.db-n.com/x/tKvc
 */
class FaqModule extends PageModule {
  constructor(moduleData) {
    super(component, moduleData);
  }
}

export default FaqModule;

