import { createSelector } from 'reselect';
import {
  getBookablePromotions,
  getContext,
  getMatchingPromotions,
  getOptionalPromotions,
  getSelectedOptionalPromotions,
  isCustomerActionPromotion,
} from '../helpers/promotions';

export const createMatchingPromosSelector = ({ style, context }) => createSelector(
  state => getBookablePromotions(state).filter(promo => (!style || promo.style === style)),
  (state) => context || getContext(state),
  (state, targets) => targets.filter(target => target),
  getMatchingPromotions,
);

// get all matching optional promotions (customer action needed)
export const createMatchingOptionalPromosSelector = ({ style, context }) => createSelector(
  state => getOptionalPromotions(state)
    .filter(promo => (!style || promo.style === style) && isCustomerActionPromotion(promo)),
  (state) => context || getContext(state),
  (state, targets) => targets.filter(target => target),
  getMatchingPromotions,
);

// get all matching selected optional promotions
export const createMatchingSelectedOptionalPromosSelector = ({ style, context }) => createSelector(
  state => getSelectedOptionalPromotions(state).filter(promo => (!style || promo.style === style)),
  (state) => context || getContext(state),
  (state, targets) => targets.filter(target => target),
  getMatchingPromotions,
);
