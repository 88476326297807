import PropTypes from 'prop-types';
import { priceShape } from './unit';

export const propTypes = {
  eid: PropTypes.string.isRequired,
  etype: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  copy: PropTypes.string.isRequired,
  paymentFee: priceShape,
  notBookableWith: PropTypes.array.isRequired,
  presales: PropTypes.bool.isRequired,
  virtual: PropTypes.bool.isRequired,
};

export const shape = PropTypes.shape(propTypes);
export const list = PropTypes.arrayOf(shape);
