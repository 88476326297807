import React from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';
import { capitalize } from '../../../helpers/str';
import imageSizes from '../../../config/imageSizes';

import { imageShape } from '../../../propTypes/media';
import MediaImageBackground from '../media/MediaImageBackground';

const componentName = 'GlobalSection';
function GlobalSection(props) {
  const {
    id,
    adjacent,
    image,
    imageAlign,
    centered,
    className,
    hasNoGlobalInner,
    layoutSettings,
  } = props;

  const { adjacentTop, adjacentBottom, borderTop, borderBottom } = layoutSettings;
  const theme = layoutSettings.theme || props.theme;
  const layout = layoutSettings.layout || props.layout;
  const modifiers = [
    theme && `theme${capitalize(theme)}`,
    layout !== 'default' && layout,
    centered && 'centered',
    !adjacentTop && !adjacentBottom && adjacent && 'adjacent',
    adjacentTop && 'adjacentTop',
    adjacentBottom && 'adjacentBottom',
    borderTop && 'borderTop',
    borderBottom && 'borderBottom',
    image && `image${capitalize(imageAlign)}`,
  ];

  const content = (
    <div id={id} className={suitcss({ componentName, descendantName: 'inner' })}>
      {props.children}
    </div>
  );

  const backgroundImage = image && {
    ...image,
    sizes: props.imageSizes || (imageSizes[layout !== 'default' ? 'globalSectionExpanded' : 'globalSection']),
  };

  if (!image) {
    return (
      <div className={suitcss({ componentName, modifiers, className })}>
        {hasNoGlobalInner ? props.children : content}
      </div>
    );
  }

  return (
    <MediaImageBackground
      className={suitcss({ componentName, modifiers, className })}
      {...backgroundImage}
      isLazy
    >
      {hasNoGlobalInner ? props.children : content}
    </MediaImageBackground>
  );
}

GlobalSection.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.array,
  ]),
  id: PropTypes.string,
  className: PropTypes.string,
  theme: PropTypes.oneOf(['', 'dark', 'medium', 'light']),
  layout: PropTypes.oneOf(['default', 'contained', 'expanded']),
  image: imageShape,
  imageAlign: PropTypes.oneOf(['left', 'right', 'center']),
  imageSizes: PropTypes.objectOf(PropTypes.string),
  adjacent: PropTypes.bool,
  centered: PropTypes.bool,
  layoutSettings: PropTypes.object.isRequired,
  hasNoGlobalInner: PropTypes.bool,
};

GlobalSection.defaultProps = {
  layoutSettings: {},
  imageAlign: 'center',
  maxWidth: true,
};

export default GlobalSection;
