import QueueableRequest from './QueueableRequest';
import { ACCOUNT_TARIFF_OPTION_ENDPOINT } from '../../config/api';
import { bindParamsToRoute } from '../../helpers/url';
import TariffSummaryRequest from './TariffSummaryRequest';
import TariffOptionsRequest from './TariffOptionsRequest';
import { getMsisdn } from '../../helpers/accessors';

/**
 * Fetches a single bookable (or booked) option from the api.
 */
export default class TariffOptionRequest extends QueueableRequest {
  constructor(msisdn, tariffOptionId, options = {}) {
    const url = bindParamsToRoute(ACCOUNT_TARIFF_OPTION_ENDPOINT, { msisdn, tariffOptionId });
    super(url, options);
    this.tariffOptionId = tariffOptionId;
  }

  getSubsequentRequests(state) {
    const msisdn = getMsisdn(state);
    return [
      new TariffSummaryRequest(msisdn),
      new TariffOptionRequest(msisdn, this.tariffOptionId),
      new TariffOptionsRequest(msisdn),
    ];
  }
}

