import * as moduleMap from '../config/moduleMap';
import { pascalCase } from './str';

/**
 * Returns an instance of the module corresponding to the given etype.
 *
 * @param {string} etype
 * @param {object} data
 * @return {PageModule}
 */
export const getModuleInstance = async (etype, data) => {
  const ModuleClass = moduleMap[pascalCase(etype)];
  const instance = new ModuleClass(data);
  await instance.loadComponent();
  return instance;
};
