import { LOCATION_CHANGE, replace } from 'react-router-redux';
import { SET_SUBMIT_FAILED } from 'redux-form/lib/actionTypes';

import { fetchPage, triggerUrlActions, validateTriggerUrlActions } from '../actions/page/website';
import {
  pendingLocation,
  requestedLocation,
  locationChangeBlockedByPrompt,
  showLocationChangePrompt,
  resetLocationChangePrompt,
  addLocationPrompts,
} from '../actions/page/location';

import { trackPage } from '../actions/tracking/page';
import { patchOrder } from '../actions/order/process';
import {
  REGISTER_GRANTED_PROMOTIONS,
  REGISTER_PROMOTION_CODES,
  REGISTER_VOID,
} from '../actions/user/marketing';
import orderProcessModuleConfig from '../config/orderProcessModule';
import { getDeepestProp } from '../helpers/identifier';
import viewportActions from '../actions/site/viewport';
import { SYNC_STORAGE, updateShoppingBag } from '../actions/global/storage';
import { RECEIVED_CREDENTIALS } from '../actions/user/login';
import { send } from '../actions/request/send';
import { fetchPromotions, fetchEntitiesByIds } from '../actions/request/registry';
import DocumentsRequest from '../model/requests/DocumentsRequest';
import { isAccountUrl } from '../config/api';
import { getPage } from '../selectors/page';
import { isWebView } from '../helpers/site';
import { computeTriggerEvent } from '../services/triggerEvents';
import { showCookieConsentDialog } from '../actions/dialog/cookieConsentActions';

// @todo this function needs a makeover; it is too hard to read and understand
const setupOrderProcessState = async (store, page, location) => {
  const { dispatch, getState } = store;
  const modules = page.modules
    .map(module => [module, module.component])
    // filter all modules that are not defined or do not have a getSelectedProduct
    // function
    .filter(([, component]) =>
      component && typeof getDeepestProp(component, 'WrappedComponent').getSelectedProduct === 'function',
    );

  if (modules.length === 0) {
    return;
  }

  if (modules.length >= 2) {
    console.warn('This page contains more than one modules that convert the orderProcess state!');
  }

  // first module wins
  const module = modules[0][0];

  // resolve entities to get access to all information
  const { getSelectedProduct } = getDeepestProp(modules[0][1], 'WrappedComponent');
  const entities = await dispatch(fetchEntitiesByIds(module.entities.map(entity => entity.eid)));

  const selectedProducts = getSelectedProduct(
    getState(),
    Object.assign({ location }, module, { entities }),
  );
  // flatten items
  const items = []
    .concat(...Object.values(selectedProducts))
    .filter(item => item);

  const config = orderProcessModuleConfig[module.etype] || {};
  const progressState = config.progressState ? config.progressState(items) : 'EMPTY';
  dispatch(patchOrder({
    progressState,
    items: items.sort(config.sortItems({ progressState })),
  }));
};

const isValidTrackingUrl = (dispatch, page, location, site) => {
  const { routing } = site;

  const isETypeAvailable = (currentPage, eType) => currentPage.modules.some(module =>
    module.etype.toLowerCase().includes(eType));

  const containsModuleType = (currentPage, moduleName) =>
    currentPage.modules.some(module =>
      module.constructor.name.toLowerCase() === moduleName.toLowerCase());

  const isSameLocationAsLastPageTrack = (currentLocation, newLocation) => {
    const trackingProps = Object.entries(page.tracking).toString();
    const orderPath = currentLocation && currentLocation.pathname.includes('bestellen');

    if ((newLocation.pathname.includes('vertragsverlaengerung/post/vielen-dank') ||
      newLocation.pathname.includes('vertragsverlaengerung/pre/vielen-dank')) &&
      (newLocation.action === 'REPLACE')) {
      return true;
    }

    if (orderPath && (trackingProps.includes('mein otelo') ||
      trackingProps.includes('dashboard'))) {
      return true;
    }

    return false;
  };

  //------------------------------------------------------------------------------
  // Tracking whitelist
  //
  // The Search-functionality on otelo needs some special properties to be tracked correctly.
  // So we need to remove the Search-result page from the normal Tracking process.
  //------------------------------------------------------------------------------

  if (isETypeAvailable(page, 'form') && location.search === '' &&
    !site.contractRenewal.isInProgress &&
    !routing.pendingLocation.pathname.includes('ersatzsimkarte') &&
    (routing.pendingLocation.pathname && !(routing.pendingLocation.pathname === '/service')) &&
    !routing.pendingLocation.pathname.includes('/service/kontakt') &&
    !routing.pendingLocation.pathname.includes('/datenschutz') &&
    !routing.pendingLocation.pathname.includes('/abmeldung/newsletter') &&
    !routing.pendingLocation.pathname.includes('/abmeldung/tnps') &&
    !routing.pendingLocation.pathname.includes('/email-validierung') &&
    !routing.pendingLocation.pathname.includes('/email') &&
    !routing.pendingLocation.pathname.includes('/login') &&
    !routing.pendingLocation.pathname.includes('/online-feedback/tnps') &&
    !routing.pendingLocation.pathname.includes('/nutzerkonto-einrichten')) {
    return false;
  } else if (
    containsModuleType(page, 'FaqModule') &&
    location.hash !== '' &&
    routing.currentLocation &&
    routing.currentLocation.pathname === routing.pendingLocation.pathname
  ) {
    return false;
  } else if (
    (
      containsModuleType(page, 'HardwareListModule') ||
      containsModuleType(page, 'HardwareDetailsModule')
    ) &&
    routing.currentLocation &&
    routing.currentLocation.pathname === routing.pendingLocation.pathname
  ) {
    return false;
  } else if (isSameLocationAsLastPageTrack(routing.currentLocation, location)) {
    return false;
  }
  return true;
};

/**
 * Requests page related data in the context of the initial page loading phase
 * or because the user triggered a url change, e.g. by clicking on a link.
 *
 * Returns a promise that fulfills as soon as the page and all
 * its dependencies are loaded.
 *
 * @todo turn into redux-thunk action instead of passing the store
 */
export const loadInitial = async (store, location) => {
  const { dispatch, getState } = store;
  const { user, site } = getState();

  computeTriggerEvent(location, store);

  if (isAccountUrl(location) && !isWebView() && !user.credentials.msisdn) {
    // we simply exit here and expect the redux router to handle the redirect
    // to the login page.
    if (process.browser) {
      dispatch(replace({
        pathname: site.sitemap.MyLoginFormRoute.url,
        state: { nextPathname: location.pathname, nextLocation: location },
      }));
    }
    return;
  }

  await dispatch(triggerUrlActions(location));
  dispatch(showCookieConsentDialog());
  const page = await dispatch(fetchPage(`${location.pathname}`));

  // setup order process depends on page because it parses its modules and entities
  await setupOrderProcessState(store, page, location);
  if (process.browser) {
    if (isValidTrackingUrl(dispatch, page, location, site) && !site.doNotTrackPage) {
      dispatch(trackPage(page, location));
    }
  }
  return page;
};

/**
 * Loads the user documents when the user is loggedIn and the documents are not already loaded.
 * Required to correctly show the document indicators.
 */
const loadDocumentsDelayedWhenLoggedIn = (store) => {
  const load = () => {

    const { user, site } = store.getState();
    const { msisdn } = user.credentials;
    const { documents } = user.inbox;
    const { isInboxView, appView } = site;
    if (msisdn && !documents && !isInboxView && !appView) {
      store.dispatch(send(new DocumentsRequest(msisdn, -100)));
    }
  };
  setTimeout(load, 3000);
};

const loaderMiddleware = store => next => async (action) => {
  switch (action.type) {
    case LOCATION_CHANGE: {
      const location = action.payload;
      const { site } = store.getState();
      store.dispatch(validateTriggerUrlActions(location, store));
      if (!site.routing.pendingLocation) {
        await store.dispatch(addLocationPrompts(location));
      }
      store.dispatch(pendingLocation(location));
      const isLocationChangeBlock = store.dispatch(locationChangeBlockedByPrompt(location));
      if (isLocationChangeBlock) {
        store.dispatch(showLocationChangePrompt(location));
        return;
      }
      store.dispatch(resetLocationChangePrompt());
      store.dispatch(requestedLocation(location));
      const page = await loadInitial(store, location);
      // the user may have requested another url while we were still waiting for the previous
      // one to be loaded. Hence, we need to check whether the page we just loaded equals the
      // latest page the user requested. Only then we propagate LOCATION_CHANGE to the system
      // via next(action).
      if (page && location === store.getState().site.routing.requestedLocation) {
        store.dispatch(viewportActions.onLocationChange());
        return next(action);
      }
      // do not propagate
      return;
    }
    case RECEIVED_CREDENTIALS:
    case SYNC_STORAGE: {
      const dispatchResult = next(action);
      // we need to show the inbox documents indicator in the UserNavigation and MyNavigation
      // even when we are not in MyOtelo areas
      loadDocumentsDelayedWhenLoggedIn(store);
      return dispatchResult;
    }
    case REGISTER_VOID:
    case REGISTER_PROMOTION_CODES: {
      const dispatchResult = next(action);
      if (action.meta.middleware) {
        await store.dispatch(
          fetchPromotions({ isBlocking: action.type === REGISTER_PROMOTION_CODES }),
        );
      }
      return dispatchResult;
    }

    case REGISTER_GRANTED_PROMOTIONS: {
      const dispatchResult = next(action);
      const { grantedPromotions } = action.payload;
      // store only promoCode if it is valid
      store.dispatch(updateShoppingBag({ promoCodes: grantedPromotions.promoCodes }));
      return dispatchResult;
    }

    case SET_SUBMIT_FAILED: { // @todo shouldn't this go in the tracking middleware?
      // A form submit that fails should also trigger the page tracking.
      // Normally this should have been triggered by the appearance of a block error
      // but the form logic is at the moment not fully consequent.
      // @todo Fix form step handling to fire this action elsewhere and remove this work around
      const dispatchResult = next(action);
      const state = store.getState();
      const location = state.routing.locationBeforeTransitions;
      const page = getPage(state.pages, location.pathname);
      store.dispatch(await trackPage(page, location));
      return dispatchResult;
    }

    default:
      return next(action);
  }
};

export default loaderMiddleware;
