import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import suitcss from '../../../../helpers/suitcss';
import { getAppEntityName } from '../../../../helpers/entity';
import { formatDate } from '../../../../helpers/date';
import FieldSelectbox from '../../../basics/form/FieldSelectbox';
import FieldTextarea from '../../../basics/form/FieldTextarea';
import Header from './CancellationHeader';
import FormSection from '../../form/FormSection';
import FormHeader from '../../form/FormHeader';

class FormStepCancellationReason extends PureComponent {
  render() {
    const { fieldMap, ui, bookedTariff, formValues } = this.props;
    const cancellationDateCopy = ui.myCancellationFormCopy
      .replace('{DATE}', `<b>${formatDate(bookedTariff.possibleCancellationDate)}</b>`)
      .replace('{TARIF}', `<b>${getAppEntityName(bookedTariff)}</b>`);

    // show text area for other reason when cancelReasonKey value is null or SON = other
    const showReasonInputField = formValues && (!formValues[fieldMap.cancelReasonKey.name] ||
        formValues[fieldMap.cancelReasonKey.name] === 'SON');

    return (
      <div className={suitcss({}, this)}>
        <Header
          headline={ui.myCancellationFormCancellationReasonHeadline}
          imageLink={ui.myCancellationFormImageJson.replace('%gui.baseurl%', '')}
        />
        <FormSection asGrid>
          <FormHeader headline={ui.myCancellationFormTarifHeadline} copy={cancellationDateCopy} />
          <FormHeader
            headline={ui.myCancellationFormReasonHeadline}
            copy={ui.myCancellationFormReasonCopy}
          />
          <Field {...fieldMap.cancelReasonKey} component={FieldSelectbox} utilities={['col12', 'mlCol6']} />
          <div className={suitcss({ utilities: ['col6', 'sHidden'] })} />
          {showReasonInputField &&
          <Field {...fieldMap.cancelReasonText} component={FieldTextarea} utilities={['col12', 'mlCol6']} />
          }
        </FormSection>
      </div>
    );
  }
}

FormStepCancellationReason.propTypes = {
  bookedTariff: PropTypes.object.isRequired,
  fieldMap: PropTypes.object.isRequired,
  formValues: PropTypes.object,
  ui: PropTypes.object.isRequired,
};

export default FormStepCancellationReason;
