import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import FormWrapper from '../form/FormWrapper';
import suitcss from '../../../helpers/suitcss';
import FieldInput from '../../basics/form/FieldInput';
import Form from '../../basics/form/Form';

const componentName = 'FormWrapper';

/**
 * Note: form buttons are excluded, submit is triggered by dialog
 */
function VoucherTopupForm(props) {
  const { fieldMap } = props;
  return (
    <div className={suitcss({}, this)}>
      <Form {...props}>
        <FormWrapper>
          <div className={suitcss({ componentName, descendantName: 'container' })}>
            <div className={suitcss({ componentName, descendantName: 'formFull' })}>
              <Field
                {...fieldMap.code}
                component={FieldInput}
                format={fieldMap.code.format}
                normalize={fieldMap.code.normalize}
              />
            </div>
          </div>
        </FormWrapper>
      </Form>
    </div>
  );
}

VoucherTopupForm.propTypes = {
  fieldMap: PropTypes.object.isRequired,
};

export default VoucherTopupForm;
