import React, { Component } from 'react';
import PropTypes from 'prop-types';

class MediaBodyMovin extends Component {

  componentDidMount() {
    if (process.browser) {
      require.ensure([], require => {
        const Lottie = require('lottie-web/build/player/lottie_light.min');
        const { renderer, loop, autoplay, prerender, animationData } = this.props;
        const options = {
          renderer,
          loop,
          autoplay,
          animationData,
          wrapper: this.wrapper,
          prerender,
        };
        this.animation = Lottie.loadAnimation(options);
      }, 'lottie');
    }
  }

  shouldComponentUpdate() {
    return false;
  }

  componentWillUnmount() {
    this.animation.destroy();
  }


  render() {
    const storeWrapper = (el) => {
      this.wrapper = el;
    };
    return <div className="Bodymovin-container" ref={storeWrapper} />;
  }
}

MediaBodyMovin.propTypes = {
  renderer: PropTypes.oneOf(['svg', 'canvas']),
  loop: PropTypes.bool,
  autoplay: PropTypes.bool,
  prerender: PropTypes.bool,
  animationData: PropTypes.object.isRequired,
};

MediaBodyMovin.defaultProps = {
  renderer: 'svg',
  loop: true,
  autoplay: true,
  prerender: true,
};

export default MediaBodyMovin;
