import React from 'react';
import PropTypes from 'prop-types';

import { hintShape, inputShape, metaShape } from '../../../propTypes/field';

import FieldGroup from './FieldGroup';
import FieldRadio from './FieldRadio';

function FieldRadioGroup(props) {
  const {
    input,
    options,
    inverted,
    asToggleButtonGroup,
    theme,
    withToolTip,
    meta,
    validation,
  } = props;

  return (
    <FieldGroup {...props} withToolTip={false} withMessenger={false}>
      {options.map((option, index) => (
        <FieldRadio
          label={option.label}
          hint={option.hint}
          input={{ ...input, value: option.value }}
          meta={meta}
          checked={input.value === option.value}
          inverted={inverted}
          asToggleButton={asToggleButtonGroup}
          theme={theme}
          withToolTip={withToolTip}
          key={index}
          validation={validation}
          customLabelEnhancement={option.customLabelEnhancement}
        />
      ))}
    </FieldGroup>
  );
}

FieldRadioGroup.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      hint: hintShape,
      input: inputShape,
      meta: metaShape,
      value: PropTypes.any.isRequired,
    })),
  input: PropTypes.object,
  meta: metaShape,
  theme: PropTypes.oneOf(['default', 'compact']),
  asToggleButtonGroup: PropTypes.bool,
  withToolTip: PropTypes.bool,
  inverted: PropTypes.bool,
  validation: PropTypes.object,
  isDateGroup: PropTypes.bool,
  asStack: PropTypes.bool,
};

FieldRadioGroup.defaultProps = {
  theme: 'default',
};

export default FieldRadioGroup;
