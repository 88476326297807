import PageModule from './PageModule';
import component from '../../containers/account/MyMarketingBanner';

/**
 * MyPromoBanner maps the configurational data
 * and contents for the banner area to "My otelo" (Web & App)
 * @see https://confluence.db-n.com/x/p5Hr
 */
class MyMarketingBannerModule extends PageModule {
  constructor(moduleData) {
    super(component, moduleData);
  }
}

export default MyMarketingBannerModule;

