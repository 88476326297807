import { push } from 'react-router-redux';

import { hideDialog, hideQueuedDialog } from '../actions/page/dialog';

/**
 * Common dialog model
 */
export default class Dialog {
  constructor(headline, copy, withCloseAction, actions, id, title) {
    this.headline = headline;
    this.copy = copy;
    this.withCloseAction = withCloseAction;
    this.actions = actions;
    this.id = id;
    this.title = title;
  }
}

/**
 * Redux thunk action that will return a Dialog instance that is
 * created from a dialog entity received from the MVC/CMS.
 *
 * @see https://confluence.db-n.com/x/3pHr
 * @param {WebEntity} dialogEntity
 */
Dialog.createFromApiModel = (dialogEntity) => (dispatch, getState) => {

  const { ui } = getState();
  const { headline, copy, buttons, cancelable, eid, etitle } = dialogEntity;

  const actions = buttons.map(button => ({
    label: button.title,
    withoutArrow: !button.url,
    action: () => {
      if (button.url) {
        dispatch(push(button.url));
      }
      // @todo we should not have to dispatch two different hide actions here
      dispatch(hideQueuedDialog());
      dispatch(hideDialog());
    },
  }));

  if (cancelable) {
    actions.push({
      label: ui.guiWordCancel,
      asLink: true,
    });
  }

  return new Dialog(headline, copy, !cancelable, actions, eid, etitle);
};
