import React from 'react';

import suitcss from '../../../helpers/suitcss';
import { headlinePropTypes } from '../../../propTypes/typography';

import TextRaw from './TextRaw';

function TextHeadline({
  raw,
  element,
  lowercase,
  highlight,
  inverted,
  bold,
  utilities,
  size,
  embedded,
  children,
  className,
  ...restProps
}) {
  const Element = raw ? TextRaw : element;
  const delegateProps = { ...restProps };
  if (raw) {
    delegateProps.element = element;
  }

  return (
    <Element
      className={suitcss({
        className,
        componentName: 'TextHeadline',
        modifiers: [size, (embedded && 'embedded')],
        // styling only per utility classes -
        // props are only to get sure, that unrefactored elements will work
        utilities: [
          lowercase && 'lowercase',
          highlight && 'colorPrimary',
          inverted && 'colorInverted',
          bold && 'weightBold',
          ...utilities,
        ],
      })}
      {...delegateProps}
    >
      {children}
    </Element>
  );
}

TextHeadline.propTypes = headlinePropTypes;

TextHeadline.defaultProps = {
  element: 'p',
  size: 'm',
  raw: false,
  highlight: false,
  bold: false,
  utilities: [],
};

export default TextHeadline;
