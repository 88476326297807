import React, { Component } from 'react';
import PropTypes from 'prop-types';
import shallowEqual from 'react-pure-render/shallowEqual';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import * as cartActions from '../../actions/order/cart';
import { isHardwareEntity } from '../../helpers/entity';
import { ORDER_STATE_UNAVAILABLE } from '../../propTypes/common';

import ServiceStickyContent from '../../components/basics/service/ServiceStickyContent';
import TextMessage from '../../components/basics/text/TextMessage';
import GlobalHeader from '../global/GlobalHeader';

import enrichEntities from '../preloader/PreloaderEntity';
import matchMediaConnector from '../service/ServiceMatchMedia';
import SinglePending from './NavProcessSinglePending';
import { fetchEntitiesByIds } from '../../actions/request/registry';

class NavProcess extends Component {
  shouldComponentUpdate(nextProps) {
    const propsHasOwnProperty = Object.prototype.hasOwnProperty.bind(this.props);
    return !Object.keys(nextProps).every(key => (
      propsHasOwnProperty(key) && shallowEqual(nextProps[key], this.props[key])
    ));
  }

  render() {
    if (this.props.progressState === 'EMPTY') {
      return null;
    }

    const node = (() => {
      const hardwareEntity = this.props.entities.find(isHardwareEntity);
      if (hardwareEntity && hardwareEntity.orderState === ORDER_STATE_UNAVAILABLE) {
        return (
          <TextMessage standalone>
            {this.props.ui.guiOrderState0}
          </TextMessage>
        );
      }

      switch (this.props.progressState) {
        case 'SINGLE_PENDING':
        case 'SINGLE_CONFIRMED':
        case 'BUNDLE':
          return <SinglePending {...this.props} hardwareEntity={hardwareEntity} />;

        default:
          return null;
      }
    })();

    if (this.props.params.standalone) {
      return node;
    }

    return (
      <ServiceStickyContent
        disabled={this.props.isMediaSM}
        mapScrollToTop={GlobalHeader.mapScrollToTop}
        contextName="processHeader"
      >
        {node}
      </ServiceStickyContent>
    );
  }
}
NavProcess.propTypes = {
  // @todo add constants
  progressState: PropTypes.oneOf([
    'EMPTY',
    'SINGLE_PENDING',
    'SINGLE_CONFIRMED',
    'BUNDLE',
  ]).isRequired,
  params: PropTypes.shape({
    standalone: PropTypes.bool,
  }).isRequired,
  isMediaSM: PropTypes.bool,
  entities: PropTypes.array.isRequired,
  ui: PropTypes.shape({
    guiOrderState0: PropTypes.string.isRequired,
  }),
  activeType: PropTypes.string,
};

NavProcess.defaultProps = {
  isMediaSM: false,
};

function mapStateToProps({ orderProcess }) {
  const { progressState, entities, activeType } = orderProcess;
  return { progressState, entities, activeType };
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: async (items) => {
    const { urlShoppingCart } = ownProps.params;
    // the items we get passed are not real entities, they were transformed any may not
    // contain all properties of the original entities. hence we need to fetch the real
    // entities again (or retrieve them from the store).
    const entities = await dispatch(fetchEntitiesByIds(items.map(item => item.eid)));
    dispatch(cartActions.replaceCart(entities));
    dispatch(push(urlShoppingCart));
  },
});

const mapStateToItems = (state, ownProps) => ({
  entities: ownProps.entities,
});

const mapStateToHardwareGroup = (state, { entities }) => {
  const hardware = entities.find(isHardwareEntity);
  if (!hardware) {
    return {};
  }
  const hardwareGroupId = hardware.groupId;
  return {
    hardwareGroup: {
      etype: 'hardwareGroup',
      eid: hardwareGroupId,
    },
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    {
      areStatesEqual: (next, prev) => {
        return prev.orderProcess.entities === next.orderProcess.entities &&
          prev.orderProcess.progressState === next.orderProcess.progressState &&
          prev.orderProcess.activeType === next.orderProcess.activeType;
      },
    },
  ),
  enrichEntities(mapStateToItems),
  enrichEntities(mapStateToHardwareGroup),
  matchMediaConnector(['isMediaSM']),
)(NavProcess);
