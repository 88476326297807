import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MediaBodyMovin from './MediaBodyMovin';
import MediaSvgData from './MediaSvgData';
import { send } from '../../../actions/request/send';
import SvgRequest from '../../../model/requests/SvgRequest';

const cache = new Map();
const MEDIA_TYPE_ANIMATION = 'animation';
const MEDIA_TYPE_SVG = 'svg';

class MediaSvgLoader extends PureComponent {

  constructor() {
    super();
    this.mounted = false;
    this.state = {
      data: null,
    };
  }

  componentDidMount() {
    this.mounted = true;
    this.fetchDataCache();
  }

  componentDidUpdate(prevProps) {
    if (this.props.path !== prevProps.path) {
      this.fetchDataCache();
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getType() {
    const { path } = this.props;
    if (path.toLowerCase().includes('.json')) {
      return MEDIA_TYPE_ANIMATION;
    }
    return MEDIA_TYPE_SVG;
  }

  async fetchDataCache() {
    const { path } = this.props;
    let data;
    if (cache.has(path)) {
      data = await cache.get(path);
    } else {
      const req = await this.fetchData();
      cache.set(path, req);
      data = req;
    }
    if (this.mounted) {
      this.setState({ data });
    }
  }

  async fetchData() {
    const type = this.getType();
    const { dispatch, path } = this.props;
    const response = await dispatch(send(new SvgRequest(path,
      type === MEDIA_TYPE_SVG ?
        SvgRequest.RESPONSE_DATA_TYPE_TEXT :
        SvgRequest.RESPONSE_DATA_TYPE_JSON,
    )));
    if (response.isSuccess) {
      return response.body.data;
    }
    return null;
  }

  render() {
    const { animationOptions, inverted, utilities } = this.props;
    const { data } = this.state;
    if (!data) {
      return null;
    }
    const type = this.getType();
    if (type === MEDIA_TYPE_ANIMATION) {
      return <MediaBodyMovin animationData={data} {...animationOptions} />;
    }
    if (type === MEDIA_TYPE_SVG) {
      return <MediaSvgData data={data} inverted={inverted} utilities={utilities} />;
    }
    return null;
  }
}

MediaSvgLoader.propTypes = {
  path: PropTypes.string.isRequired,
  inverted: PropTypes.bool,
  utilities: PropTypes.array,
  animationOptions: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
};

MediaSvgLoader.defaultProps = {
  inverted: false,
};

const mapDispatchToProps = dispatch => ({ dispatch });

export default connect(
  null,
  mapDispatchToProps,
)(MediaSvgLoader);

