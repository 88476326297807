import QueueableRequest from './QueueableRequest';
import { ACCOUNT_DATA_PORTABILTY_ENDPOINT } from '../../config/api';
import { REQUEST_METHOD_POST } from '../../helpers/constants';
import { bindParamsToRoute } from '../../helpers/url';

export default class AccountDataPortabilityRequest extends QueueableRequest {
  constructor(msisdn, options = {}) {
    super(
      bindParamsToRoute(ACCOUNT_DATA_PORTABILTY_ENDPOINT, { msisdn, format: options.format ? `.${options.format}` : '' }),
      {
        ...options,
        ...(options.password ? {
          method: REQUEST_METHOD_POST,
          payload: { password: options.password },
        } : {}),
        responseDataType: options.format
        && options.format !== QueueableRequest.RESPONSE_DATA_TYPE_JSON
          ? QueueableRequest.RESPONSE_DATA_TYPE_TEXT
          : QueueableRequest.RESPONSE_DATA_TYPE_JSON,
      },
    );
  }
}
