import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  MYTRACK_SERVICE_SIMREP_STEP1,
} from '../../../helpers/constants';
import initForm from '../../form/FormInitializer';
import { trackMyState } from '../../../actions/tracking/event';
import FormConfigProps from '../../../model/form/FormConfigProps';
import ESimReplacementFormActivationStyle
  from '../../../components/compositions/account/simReplacement/ESimReplacementFormActivationStyle';

export const SHIP_ACTIVE = 'active';

const createFieldMap = () => ({
  shipActivatedCard: {
    name: 'activated',
  },
});

const mapStateToProps = (state, { fieldMap }) => ({
  initialValues: {
    [fieldMap.shipActivatedCard.name]: SHIP_ACTIVE,
  },
});

const component = compose(
  connect(mapStateToProps),
  initForm(),
)(ESimReplacementFormActivationStyle);

export const id = 'simReplActivationStyle';

/**
 * @return {FormConfig}
 */
export const mapStateToFormConfig = (state, props) => ({
  id,
  component,
  fieldMap: createFieldMap(props),
  onStepEnter: () => (dispatch) => dispatch(trackMyState(MYTRACK_SERVICE_SIMREP_STEP1)),
  formConfigProps: new FormConfigProps({
    isSubmitDisabled: props.selectedSimOption &&
      !props.selectedSimOption.sufficientBalance,
    withRequiredHint: false,
  }),
});
