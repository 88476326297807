/* global document */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Field } from 'redux-form';

import connectUI from '../../basics/ui/UIConnector';
import suitcss from '../../../helpers/suitcss';
import FieldInput from '../../basics/form/FieldInput';
import Link from '../../basics/text/TextLink';
import TextMessage from '../../basics/text/TextMessage';
import Copy from '../../basics/text/TextCopy';
import PromotionCodeFormLink from './PromotionCodeFormLink';
import {
  registerDeniedPromotions,
  removePromoCodes,
} from '../../../actions/user/marketing';

export const CODE_STATE_PENDING = Symbol('CODE_STATE_PENDING');
export const CODE_STATE_PRIVATE = Symbol('CODE_STATE_PRIVATE');
export const CODE_STATE_VALID = Symbol('CODE_STATE_VALID');
export const CODE_STATE_NOT_VALID = Symbol('CODE_STATE_NOT_VALID');
export const CODE_STATE_NOT_VALID_FOR_SELECTION = Symbol('CODE_STATE_NOT_VALID_FOR_SELECTION');

const renderMessage = (level, message, promoCode) => (
  <TextMessage Component="div" level={level}>
    {message.replace('{CODE}', promoCode)}
  </TextMessage>
);

class PromotionCodeForm extends PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isFormVisible: !!(props.promoCode || props.deniedPromoCode || props.isFormVisible),
      userInteraction: false,
    };
    this.onToggleForm = this.onToggleForm.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDown, false);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.promoCode !== nextProps.promoCode ||
    this.props.deniedPromoCode !== nextProps.deniedPromoCode) {
      this.setState({ isFormVisible: !!nextProps.promoCode || !!nextProps.deniedPromoCode });
    }
  }

  componentWillUnmount() {
    const { deniedPromoCode, promoCode } = this.props;
    document.removeEventListener('keydown', this.onKeyDown, false);
    if (deniedPromoCode === promoCode) {
      this.props.dispatch(removePromoCodes());
    }
    this.props.dispatch(registerDeniedPromotions([]));
  }

  onToggleForm() {
    this.setState({ isFormVisible: !this.state.isFormVisible });
  }

  onKeyDown(key) {
    if (key.code === 'Enter') {
      this.props.addPromoCode(key);
    }
  }

  getCodeInfo = (ui) => ({
    [CODE_STATE_VALID]: {
      level: 'success',
      message: ui.promotionCodeSuccess,
    },
    [CODE_STATE_NOT_VALID_FOR_SELECTION]: {
      level: 'warning',
      message: ui.promotionCodeNotValidForSelection,
    },
    [CODE_STATE_NOT_VALID]: {
      level: 'error',
      message: ui.promotionCodeFailure,
    },
  });

  addPromoCode = (e) => {
    const { ui, codeState, fieldMap, formValues } = this.props;
    const codeInfo = this.getCodeInfo(ui)[codeState];
    const fieldName = fieldMap.promoCode.name;
    const promoCode = formValues[fieldName];
    this.setState({ userInteraction: true });
    this.props.addPromoCode(
      e,
      codeInfo && promoCode ? codeInfo.message.replace('{CODE}', promoCode) : undefined,
      codeState === CODE_STATE_NOT_VALID_FOR_SELECTION || codeState === CODE_STATE_NOT_VALID,
    );
  };

  render() {
    const {
      fieldMap,
      ui,
      codeState,
      promoCode,
      asDialog,
      deniedPromoCode,
    } = this.props;
    const { isFormVisible, userInteraction } = this.state;

    const codeInfo = this.getCodeInfo(ui)[codeState];
    const errorCodeInfo = this.getCodeInfo(ui)[CODE_STATE_NOT_VALID];
    const message =
      codeInfo && promoCode ? renderMessage(codeInfo.level, codeInfo.message, promoCode) : null;

    const errorMessage =
      errorCodeInfo && deniedPromoCode && (userInteraction || (!userInteraction && !message))
        ? renderMessage(errorCodeInfo.level, errorCodeInfo.message, deniedPromoCode)
        : null;

    return (
      <div
        className={suitcss({
          modifiers: [asDialog && 'asDialog'],
          states: [isFormVisible && 'active'],
        }, this)}
      >
        {!isFormVisible &&
          <PromotionCodeFormLink
            className={suitcss({ descendantName: 'content' }, this)}
            onClick={this.onToggleForm}
          />
        }
        {isFormVisible && message}
        {isFormVisible && errorMessage}
        {isFormVisible &&
          <div className={suitcss({ descendantName: 'content' }, this)}>
            <Field {...fieldMap.promoCode} component={FieldInput} />
            <Link onClick={this.addPromoCode} asButton buttonFilled utilities={[!asDialog && 'sHidden']}>
              {ui.promotionCodeSubmit}
            </Link>
            {!asDialog &&
              <Link onClick={this.addPromoCode} utilities={['mlHidden']}>{ui.promotionCodeSubmit}</Link>
            }
          </div>
        }
        {isFormVisible && (
          <Copy size="small" utilities={['colorGray100', 'marginTopS']}>{ui.promotionCodeInputHint}</Copy>
        )}
      </div>
    );
  }
}

PromotionCodeForm.propTypes = {
  addPromoCode: PropTypes.func.isRequired,
  fieldMap: PropTypes.object.isRequired,
  ui: PropTypes.shape({
    promotionCodeSuccess: PropTypes.string.isRequired,
    promotionCodeNotValidForSelection: PropTypes.string.isRequired,
    promotionCodeFailure: PropTypes.string.isRequired,
    promotionCodeSubmit: PropTypes.string.isRequired,
    promotionCodeInputHint: PropTypes.string.isRequired,
  }),
  codeState: PropTypes.symbol,
  promoCode: PropTypes.string,
  deniedPromoCode: PropTypes.string,
  isFormVisible: PropTypes.bool,
  asDialog: PropTypes.bool,
  formValues: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
};

PromotionCodeForm.defaultProps = {
  isFormVisible: false,
  asDialog: false,
};

export default compose(connectUI())(PromotionCodeForm);
