let chatMessageCount = 0;
const getUniqueId = () => {
  chatMessageCount++;
  return (`msg${chatMessageCount}`);
};

class Message {
  /**
   * @param {string} sender
   * @param {*} payload
   */
  constructor(sender, payload = {}) {
    this.sender = sender;
    this.payload = payload;
    this.isTyping = false;
    this.id = getUniqueId();
  }

  setIsTyping(bool) {
    this.isTyping = bool;
  }

  getText() {
    return this.payload.text;
  }
}

export default Message;
