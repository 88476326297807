import { ACTION_PREFIX } from '../../helpers/constants';

export const STORE_PASSWORD = `${ACTION_PREFIX}/STORE_PASSWORD`;
export const DOCUMENT_READ = `${ACTION_PREFIX}/DOCUMENT_READ`;
export const DOCUMENT_OPEN = `${ACTION_PREFIX}/DOCUMENT_OPEN`;

export const storePassword = password => ({
  type: STORE_PASSWORD,
  payload: { password },
});

export const documentRead = id => ({
  type: DOCUMENT_READ,
  payload: { id },
});

export const openDocument = doc => ({
  type: DOCUMENT_OPEN,
  payload: doc,
});
