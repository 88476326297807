/* global document */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import RouterLink from 'react-router/lib/Link';
import suitcss from '../../../helpers/suitcss';
import Copy from '../text/TextCopy';
import Headline from '../text/TextHeadline';
import Tag from '../text/Tag';
import { CUSTOM_EVENT_EQUAL_RESIZE } from '../../../helpers/constants';

class Callout extends PureComponent {

  constructor(props, context) {
    super(props, context);
    this.copyPrimary = React.createRef();
    this.copySecondary = React.createRef();
    this.timeout = null;
    this.state = {
      sizePrimary: null,
      sizeSecondary: null,
    };
  }

  componentDidMount() {
    if (this.props.theme === 'badge' || this.props.asBadge) {
      this.timeout = setTimeout(() => {
        this.setState({ // eslint-disable-line react/no-did-mount-set-state
          sizePrimary: this.copyPrimary.current
            ? Math.max(
              this.copyPrimary.current.clientWidth,
              this.copyPrimary.current.clientHeight,
            ) + 2 // Add slight padding to avoid line breaks
            : null,
          sizeSecondary: this.copySecondary.current
            ? Math.max(
              this.copySecondary.current.clientWidth,
              this.copySecondary.current.clientHeight,
            ) + 2 // Add slight padding to avoid line breaks
            : null,
        });
        if (!process.env.KARMA) {
          const event = document.createEvent('Event');
          event.initEvent(CUSTOM_EVENT_EQUAL_RESIZE, true, true);
          this.copyPrimary.current.dispatchEvent(event);
        }
      }, 100);
    }
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  getLinkProps() {
    const { link } = this.props;
    return link ? { to: link.url, target: link.target || '_self' } : {};
  }

  renderLabelCallout(Element) {
    const { content } = this.props;
    return (
      <Element className={suitcss({ descendantName: 'label' }, this)} {...this.getLinkProps()} >
        <Copy size="tertiary" embedded raw>{content}</Copy>
      </Element>
    );
  }

  renderContentCallout(Element) {
    const { content, size } = this.props;
    return (
      <Element className={suitcss({ descendantName: 'label' }, this)} {...this.getLinkProps()} >
        <Headline size={size || 'xs'} utilities={['weightBold']} embedded raw>{content}</Headline>
      </Element>
    );
  }

  renderBadgeCallout(Element) {
    const { content } = this.props;
    const { sizePrimary } = this.state;
    return (
      <Element
        className={suitcss({ descendantName: 'badge' }, this)}
        style={sizePrimary ? { width: sizePrimary, height: sizePrimary } : null}
        {...this.getLinkProps()}
      >
        <span
          className={suitcss({ descendantName: 'copy' }, this)}
          ref={this.copyPrimary}
        >
          <Copy size="tertiary" embedded raw>{content}</Copy>
        </span>
      </Element>
    );
  }

  renderStickerCallout(Element) {
    const { content } = this.props;
    return (
      <Element className={suitcss({ descendantName: 'sticker' }, this)}>
        <Tag label={content} />
      </Element>
    );
  }

  renderSecondaryCallout(Element) {
    const { subcontent } = this.props;
    const { sizeSecondary } = this.state;
    return (
      <Element
        className={suitcss({ descendantName: 'badge' }, this)}
        style={sizeSecondary ? { width: sizeSecondary, height: sizeSecondary } : null}
        {...this.getLinkProps()}
      >
        <span
          className={suitcss({ descendantName: 'copy' }, this)}
          ref={this.copySecondary}
        >
          <Copy size="tertiary" embedded raw>
            {subcontent}
          </Copy>
        </span>
      </Element>
    );
  }

  render() {
    const { sizePrimary } = this.state;
    const {
      subcontent,
      theme,
      color,
      align,
      inverted,
      bordered,
      expanded,
      link,
      utilities,
      callout,
      onShowSimpleDialog,
      asBadge,
    } = this.props;
    const { textInfo } = callout;

    const Element = link ? RouterLink : 'div';
    const isThemeBadge = theme === 'badge' || asBadge;
    const isThemeLabel = theme === 'label';
    const isThemeContent = theme === 'content';
    const isThemeSticker = theme === 'sticker';
    const modifiers = [
      asBadge ? 'badge' : theme,
      align,
      color,
      inverted && 'inverted',
      bordered && 'bordered',
      expanded && 'expanded',
      link && 'cta',
      textInfo && 'info',
    ];
    const states = [!sizePrimary && isThemeBadge && 'hidden'];
    const showInfoDialog = () => (
      onShowSimpleDialog(textInfo.headline, textInfo.copy, textInfo.label)
    );
    const onClickEvent = textInfo ? showInfoDialog : null;

    return (
      <aside className={suitcss({ modifiers, states, utilities }, this)} >
        <div className={suitcss({ descendantName: 'inner' }, this)} >
          <div className={suitcss({ descendantName: 'content' }, this)} onClick={onClickEvent} >
            {isThemeBadge && this.renderBadgeCallout(Element)}
            {isThemeLabel && this.renderLabelCallout(Element)}
            {isThemeContent && this.renderContentCallout(Element)}
            {isThemeSticker && !asBadge && this.renderStickerCallout(Element)}
          </div>
          {subcontent && isThemeBadge &&
            <div className={suitcss({ descendantName: 'subcontent' }, this)} >
              {this.renderSecondaryCallout(Element)}
            </div>
          }
        </div>
      </aside>
    );
  }
}

Callout.propTypes = {
  callout: PropTypes.shape({
    textInfo: PropTypes.object,
  }),
  content: PropTypes.string.isRequired,
  subcontent: PropTypes.string,
  theme: PropTypes.oneOf(['badge', 'label', 'content', 'sticker']).isRequired,
  color: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
  align: PropTypes.oneOf(['left', 'center', 'right']),
  size: PropTypes.string,
  inverted: PropTypes.bool,
  bordered: PropTypes.bool,
  expanded: PropTypes.bool,
  link: PropTypes.object,
  utilities: PropTypes.arrayOf(PropTypes.string),
  onShowSimpleDialog: PropTypes.func,
  asBadge: PropTypes.bool,
};

Callout.defaultProps = {
  color: 'primary',
  align: 'right',
  callout: {},
  asBadge: false,
};

export default Callout;
