import AbstractError from './AbstractError';
import { REDIRECT_ERROR } from '../../config/errorCodes';

/**
 * Thrown if the received response contains redirect directives
 */
class RedirectError extends AbstractError {
  constructor(details) {
    super(REDIRECT_ERROR);
    this._details = details;
  }

  getDetails() {
    return this._details;
  }
}

export default RedirectError;
