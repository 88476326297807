import QueueableRequest from './QueueableRequest';
import { FREE_SIM_ORDERING_ENDPOINT } from '../../config/api';
import { bindParamsToRoute } from '../../helpers/url';
import { REQUEST_METHOD_POST } from '../../helpers/constants';

export default class FreeSimOrderingRequest extends QueueableRequest {
  constructor(market, payload) {
    super(bindParamsToRoute(FREE_SIM_ORDERING_ENDPOINT, { market }), {
      method: REQUEST_METHOD_POST,
      payload,
    });
  }
}
