import PageModule from './PageModule';
import component from '../../containers/search/SearchForm';

/**
 * Handles all search related functionality.
 * @see https://confluence.db-n.com/pages/viewpage.action?pageId=46597331
 */
class SearchFormModule extends PageModule {
  constructor(moduleData) {
    super(component, moduleData);
  }
}

export default SearchFormModule;
