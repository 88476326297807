import React from 'react';
import PropTypes from 'prop-types';

import suitcss from '../../../helpers/suitcss';
import Headline from '../text/TextHeadline';
import Copy from '../text/TextCopy';

const componentName = 'UITransitionSpinner';
function UITransitionSpinner(props) {
  const { headline, copy } = props;
  return (
    <div className={suitcss({ componentName })}>
      <div className={suitcss({ componentName, descendantName: 'inner' })} >
        <div className={suitcss({ componentName, descendantName: 'animation' })} >
          <span /><span /><span /><span /><span />
        </div>
        <div className={suitcss({ componentName, descendantName: 'content' })} >
          {headline &&
            <div className={suitcss({ componentName, descendantName: 'headline' })} >
              <Headline element="h4" embedded raw >
                {headline}
              </Headline>
            </div>
          }
          {copy &&
            <div className={suitcss({ componentName, descendantName: 'copy' })} >
              <Copy inverted embeded raw >
                {copy}
              </Copy>
            </div>
          }
        </div>
      </div>
    </div>
  );
}

UITransitionSpinner.propTypes = {
  headline: PropTypes.string,
  copy: PropTypes.string,
};

export default UITransitionSpinner;
