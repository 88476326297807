import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';
import UIOverlayWrapper from '../../basics/ui/UIOverlayWrapper';
import CustomerServiceDialogItem from './CustomerServiceDialogItem';

class CustomerServiceDialog extends PureComponent {
  render() {
    const { items, actions } = this.props;
    return (
      <UIOverlayWrapper
        className={suitcss({}, this)}
        theme="light"
        withoutPadding
        onClose={actions && actions[0].action}
      >
        <ul
          className={suitcss({ descendantName: 'contactOptions' }, this)}
        >
          {items.map((item, index) => (
            <CustomerServiceDialogItem
              key={index}
              parent={this}
              headline={item.headline}
              buttonText={item.buttonText}
              buttonAction={item.actions && item.actions[0].action}
              isChatLauncher={item.isChatLauncher}
            />))
          }
        </ul>
      </UIOverlayWrapper>
    );
  }
}

CustomerServiceDialog.propTypes = {
  items: PropTypes.array.isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      action: PropTypes.func,
    }),
  ).isRequired,
};

export default CustomerServiceDialog;
