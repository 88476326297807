import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import suitcss from '../../../helpers/suitcss';
import connectUI from '../../basics/ui/UIConnector';

import FieldCheckbox from '../../basics/form/FieldCheckbox';
import Headline from '../../basics/text/TextHeadline';
import TextCopy from '../../basics/text/TextCopy';


class AdConsentUpdateForm extends PureComponent {

  render() {
    const {
      fieldMap, ui, adConsent, isInverted, entity, subline,
    } = this.props;
    const { adConsentHeadline } = entity;
    return (
      <div className={suitcss({ utilities: [isInverted && 'inverted'], descendantName: 'smallCheckboxLabel' }, this)}>
        <Headline size="m" utilities={['alignCenter', 'marginBottom']} embedded>
          { adConsentHeadline === undefined ?
            ui.myPromotionAdConsentHeadline :
            entity.adConsentHeadline
          }
        </Headline>
        <TextCopy utilities={['alignCenter', 'copySecondary', 'marginBottom']}>
          { subline !== '' ? entity.adConsentSubline : ''}
        </TextCopy>
        {!adConsent.adConsent &&
        <Field
          {...fieldMap.adConsent}
          component={FieldCheckbox}
          utilities={['marginBottom']}
          raw
        />
        }
        {!adConsent.passData &&
        <Field
          {...fieldMap.passData}
          component={FieldCheckbox}
          utilities={['marginBottom']}
          raw
        />
        }
        {(!adConsent.adConsent || !adConsent.passData) &&
        <TextCopy>
          {ui.guiAdConsentConditionalHint}
        </TextCopy>
        }
      </div>
    );
  }
}

AdConsentUpdateForm.propTypes = {
  fieldMap: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  adConsent: PropTypes.object.isRequired,
  isInverted: PropTypes.bool,
  entity: PropTypes.object.isRequired,
  subline: PropTypes.string,
};

export default connectUI()(AdConsentUpdateForm);
