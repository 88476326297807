import PageModule from './PageModule';

/**
 * The HardwareList module displays hardware products in a grid.
 * @see https://confluence.db-n.com/x/NZzc
 */
class HardwareListModule extends PageModule {
  constructor(moduleData) {
    super(import(/* webpackChunkName:"HardwareList" */ '../../containers/hardware/HardwareList'), moduleData);
  }
}

export default HardwareListModule;
