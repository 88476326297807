import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { createSelector } from 'reselect';

import MultiObjectProxy from '../../../model/MultiObjectProxy';
import { getDisplayName } from '../../../helpers/component';

/**
 * Returns an object with the ui-values listed under the keys
 * defined by the alias map.
 *
 * @param  {Object} ui - The ui list that contains the original ui key-value pairs
 * @param  {Object} uiAliasMap - Filters the originally provided ui keys.
 *
 * @return {object}
 */
export function getResolvedUiAliasMap(ui = {}, uiAliasMap = {}) {
  const renamedUi = {};
  Object.keys(uiAliasMap).forEach(newKey => {
    renamedUi[newKey] = ui[uiAliasMap[newKey]];
  });
  return renamedUi;
}

/**
 * connectUI allows us to access ui elements in deep nested components
 * without having to pass all data via props.
 *
 * @param  {object} [uiAliasMap] - an optional key-value map that defines aliases for ui-keys
 *     Note: Avoid this if you can as it will increase the overhead of resolving ui-keys.
 * @param {component} WrappedComponent The component the ui property should be passed to.
 * @return {component} A new component that wrapps the given one and makes the ui elements
 *     available via `this.props.ui`.
 */
const connectUI = uiAliasMap => WrappedComponent => {
  const uiSelector = createSelector(
    (context) => context.ui,
    (context, props) => props.ui,
    (contextUi, ownPropsUi) => {
      let ui;
      if (ownPropsUi || uiAliasMap) {
        ui = new MultiObjectProxy([
          uiAliasMap && getResolvedUiAliasMap(contextUi, uiAliasMap),
          ownPropsUi,
          contextUi,
        ]);
      } else {
        ui = contextUi;
      }
      return ui;
    },
  );
  class ConnectUI extends PureComponent {
    render() {
      return (
        <WrappedComponent
          {...this.props}
          ui={uiSelector(this.context, this.props)}
        />
      );
    }
  }

  ConnectUI.displayName = `connectUI(${getDisplayName(WrappedComponent)})`;
  ConnectUI.WrappedComponent = WrappedComponent;
  ConnectUI.propTypes = { ui: PropTypes.object };
  ConnectUI.contextTypes = { ui: PropTypes.object };

  return ConnectUI;
};

export default connectUI;
