/* global window */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import suitcss from '../../../helpers/suitcss';
import { hasMouseLeftTarget } from '../../../helpers/navigation';
import { MENU_IDENTIFIER_ACCOUNT, MENU_IDENTIFIER_CART, ROUTE_PREFIX_MY_OTELO } from '../../../helpers/constants';
import menuActions from '../../../actions/page/menu';
import viewportActions from '../../../actions/site/viewport';
import * as logoutActions from '../../../actions/user/logout';
import { linkShape } from '../../../propTypes/typography';
import { getDocumentUnreadCount, getUserLoyaltySettings } from '../../../helpers/user';
import UIOverlayWrapper from '../../basics/ui/UIOverlayWrapper';
import Link from '../../basics/text/TextLink';
import AccountLink from './AccountLink';
import MyNavigation from './MyNavigation';
import connectViewport from '../../basics/service/ServiceViewportConnector';
import { navLinkList } from '../../../propTypes/navigation';

class AccountNavigation extends PureComponent {

  constructor(...args) {
    super(...args);
    this.timeout = null;
    this.onOpenMenu = this.onOpenMenu.bind(this);
    this.onCloseMenu = this.onCloseMenu.bind(this);
    this.toggleFlyout = this.toggleFlyout.bind(this);
  }

  componentWillUpdate(nextProps) {
    if (nextProps.shouldClose && this.timeout) {
      this.clearTimeout();
      this.props.closeMenu(nextProps.isMediaS);
    }
  }

  componentDidUpdate(prevProps) {
    const { isOpen, isMediaSM, closeMenu } = this.props;
    if (isOpen && isMediaSM !== prevProps.isMediaSM) {
      closeMenu(prevProps.isMediaSM);
    }
  }

  componentWillUnmount() {
    this.clearTimeout();
  }

  onOpenMenu() {
    const { isOpen, openMenu } = this.props;
    this.clearTimeout();
    if (!isOpen) {
      openMenu();
    }
  }

  onCloseMenu(evt) {
    const { closeMenu } = this.props;

    if (!hasMouseLeftTarget(evt)) {
      return;
    }

    this.clearTimeout();
    this.timeout = window.setTimeout(() => {
      closeMenu();
    }, 300);
  }

  toggleFlyout() {
    const { isOpen, openMenu, closeMenu } = this.props;
    if (!isOpen) {
      openMenu();
    } else {
      closeMenu();
    }
  }

  clearTimeout() {
    if (this.timeout) {
      window.clearTimeout(this.timeout);
    }
  }

  renderUserContent() {
    const {
     items, user, logout, notificationCount,
    } = this.props;
    return (
      <MyNavigation
        items={items}
        user={user}
        logout={logout}
        notificationCount={notificationCount}
      />
    );
  }

  renderLink() {
    const {
      isLoggedIn,
      link,
      urlDashboard,
      isOpen,
      notificationCount,
      ui,
      user,
      isUsingTouchScreen,
      location,
    } = this.props;

    const loyaltyLevel = getUserLoyaltySettings(user, ui);
    const isMyOteloLocation = location.pathname.includes(ROUTE_PREFIX_MY_OTELO);

    if (!isLoggedIn) {
      return (
        <Link
          to={link.url}
          icon={link.icon}
          target={link.target}
          withoutArrow
          isActive={isOpen}
          ariaLabel={link.ariaLabel}
        >
          {!link.icon && link.title}
        </Link>
      );
    }

    return (
      <AccountLink
        loyaltyLevel={loyaltyLevel && loyaltyLevel.level}
        to={!isUsingTouchScreen || !isMyOteloLocation ? urlDashboard : null}
        onClick={isUsingTouchScreen && isMyOteloLocation ? this.toggleFlyout : null}
        element={isUsingTouchScreen && isMyOteloLocation ? 'div' : null}
        icon={link.icon}
        isActive={isOpen}
        notificationCount={notificationCount}
        ariaLabel={link.ariaLabel || ''}
      />
    );
  }

  render() {
    const {
     link, isOpen, isLoggedIn, withoutLogin, isUsingTouchScreen,
    } = this.props;

    if (withoutLogin && !isLoggedIn) {
      return null;
    }
    return (
      <div className={suitcss({ states: [isLoggedIn && 'loggedIn'] }, this)}>
        <div
          className={suitcss({ descendantName: 'inner' }, this)}
          onMouseOver={!isUsingTouchScreen && isLoggedIn ? this.onOpenMenu : null}
          onMouseOut={!isUsingTouchScreen && isLoggedIn ? this.onCloseMenu : null}
        >
          {(isLoggedIn || link) && this.renderLink()}
          {isLoggedIn
            && (
            <div className={suitcss({ descendantName: 'flyout' }, this)}>
              <UIOverlayWrapper
                theme="light"
                alignH="right"
                alignV="bottom"
                alignContent="outside"
                isScrollbar
                visible={isOpen}
              >
                {isLoggedIn && this.renderUserContent()}
              </UIOverlayWrapper>
            </div>
)}
        </div>
      </div>
    );
  }
}

AccountNavigation.propTypes = {
  user: PropTypes.object,
  items: navLinkList,
  isOpen: PropTypes.bool.isRequired,
  openMenu: PropTypes.func.isRequired,
  closeMenu: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool,
  urlDashboard: PropTypes.string,
  notificationCount: PropTypes.number,
  avatar: PropTypes.object,
  link: linkShape,
  withoutLogin: PropTypes.bool,
  isMediaS: PropTypes.bool,
  isMediaSM: PropTypes.bool,
  isUsingTouchScreen: PropTypes.bool,
  shouldClose: PropTypes.bool,
  location: PropTypes.object,
  ui: PropTypes.object,
  client: PropTypes.object.isRequired,
};

AccountNavigation.defaultProps = {
  isMediaS: false,
  isMediaSM: false,
};

const mapStateToProps = ({
 menu, user, routing, site, ui,
}, ownProps) => {
  const isLoggedIn = !!user.credentials.msisdn || ownProps.isLoggedIn;
  const menuConfig = menu[MENU_IDENTIFIER_ACCOUNT] || {};
  return {
    user,
    items: menuConfig.items,
    isOpen: menuConfig.isOpen || !!ownProps.isOpen,
    location: routing.locationBeforeTransitions,
    urlDashboard: site.sitemap.MyDashboardRoute.url,
    notificationCount: getDocumentUnreadCount(user),
    avatar: user.avatar,
    isUsingTouchScreen: site.isUsingTouchScreen,
    shouldClose: !ownProps.isMediaS
      && menu[MENU_IDENTIFIER_CART] && menu[MENU_IDENTIFIER_CART].isOpen,
    ui,
    isLoggedIn,
  };
};

const mapDispatchToProps = (dispatch) => ({
  logout: () => {
    dispatch(logoutActions.logout());
    dispatch(menuActions.closeMenu(MENU_IDENTIFIER_ACCOUNT));
  },
  openMenu: (blockScrolling) => {
    dispatch(menuActions.openMenu(MENU_IDENTIFIER_ACCOUNT, blockScrolling));
    if (blockScrolling) {
      dispatch(viewportActions.toggleViewportBlocking());
    }
  },
  closeMenu: (blockScrolling) => {
    dispatch(menuActions.closeMenu(MENU_IDENTIFIER_ACCOUNT));
    if (blockScrolling) {
      dispatch(viewportActions.toggleViewportBlocking());
    }
  },
});

export default compose(
  connectViewport({ listenTo: ['client'] }),
  connect(mapStateToProps, mapDispatchToProps),
)(AccountNavigation);
