import { compose } from 'redux';
import { connect } from 'react-redux';

import HoldOnForm
  from '../../../components/compositions/account/cancellation/FutureContactForm';
import initForm from '../../form/FormInitializer';
import FormConfigProps from '../../../model/form/FormConfigProps';
import { getConsentTextById } from '../../../helpers/misc';
import {
  FORM_FIELD_GDPR_CONSENT_DAT,
  FORM_FIELD_GDPR_CONSENT_BEW,
  FORM_FIELD_ADCONSENT_INFO,
  FORM_FIELD_ADCONSENT_CONTACT,
} from '../../../helpers/constants';

const createFieldMap = ({ consentTexts }) => ({
  adConsent: {
    name: FORM_FIELD_ADCONSENT_INFO,
    label: getConsentTextById(consentTexts, FORM_FIELD_GDPR_CONSENT_BEW),
    type: 'checkbox',
  },
  passData: {
    name: FORM_FIELD_ADCONSENT_CONTACT,
    label: getConsentTextById(consentTexts, FORM_FIELD_GDPR_CONSENT_DAT),
    type: 'checkbox',
  },
});

const mapStateToProps = (state, { fieldMap }) => {
  const initialValues = {
    [fieldMap.adConsent.name]: state.user.adConsent.adConsent,
    [fieldMap.passData.name]: state.user.adConsent.passData,
  };
  return { initialValues };
};

const component = compose(
  connect(mapStateToProps),
  initForm(),
)(HoldOnForm);

export const id = 'futureContact';

/**
 * @return {FormConfig}
 */
export const mapStateToFormConfig = (state, props) => ({
  id,
  component,
  fieldMap: createFieldMap(props),
  label: id,
  formConfigProps: new FormConfigProps({
    labelSubmit: state.ui.myCancellationFormSubmit,
    withRequiredHint: false,
  }),
});

export default mapStateToFormConfig;
