import React from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../../helpers/suitcss';
import Headline from '../../../basics/text/TextHeadline';
import SVGLoader from '../../../basics/media/MediaSvgLoader';

const componentName = 'CancellationHeader';

function CancellationHeader(props) {
  const { headline, imageLink } = props;
  return (
    <div className={suitcss({ componentName })}>
      <div className={suitcss({ componentName, descendantName: 'icon' })}>
        <SVGLoader path={imageLink} />
      </div>
      <div className={suitcss({ componentName, descendantName: 'headline' })}>
        <Headline size="l">{headline}</Headline>
      </div>
    </div>
  );
}

CancellationHeader.propTypes = {
  headline: PropTypes.string.isRequired,
  imageLink: PropTypes.string.isRequired,
};

export default CancellationHeader;
