import { ACTION_PREFIX } from '../../helpers/constants';

export const REGISTER_CAPTCHA = `${ACTION_PREFIX}/REGISTER_CAPTCHA`;
export const UNREGISTER_CAPTCHA = `${ACTION_PREFIX}/UNREGISTER_CAPTCHA`;

export const registerCaptcha = payload => ({
  type: REGISTER_CAPTCHA,
  payload,
});

export const unregisterCaptcha = () => ({
  type: UNREGISTER_CAPTCHA,
});
