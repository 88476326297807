import PageModule from './PageModule';
import component from '../../containers/iframe/Iframe';

/**
 * Used to include content via an iframe in the page
 * @see https://confluence.db-n.com/x/x6rr
 */
class IframeModule extends PageModule {
  constructor(moduleData) {
    super(component, moduleData);
  }
}

export default IframeModule;

