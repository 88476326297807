import {
  CLEAR_SHOPPINGBAG,
  UPDATE_SHOPPINGBAG,
  SYNC_STORAGE,
  UPDATE_CAMPAIGN_TOKEN,
  CLEAR_CAMPAIGN_TOKEN,
} from '../actions/global/storage';

const defaultState = {
  shoppingBag: {
    bId: null,
    cId: null,
    entities: null,
    promoCodes: null,
    hideMnp: false,
    orderByAgent: false,
  },
  isSynced: false,
  campaignToken: null,
};

export default (state = defaultState, action = {}) => {
  const { payload } = action;

  switch (action.type) {
    case UPDATE_SHOPPINGBAG:
      return { ...state, shoppingBag: { ...state.shoppingBag, ...payload } };

    case CLEAR_SHOPPINGBAG:
      return { ...state, shoppingBag: defaultState.shoppingBag };

    case UPDATE_CAMPAIGN_TOKEN: {
      return { ...state, campaignToken: { ...state.campaignToken, ...payload } };
    }

    case CLEAR_CAMPAIGN_TOKEN:
      return { ...state, campaignToken: defaultState.campaignToken };

    case SYNC_STORAGE:
      return { ...state, isSynced: true };

    default:
      return state;
  }
};

