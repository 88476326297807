import { push } from 'react-router-redux';
import MyESimInfoDialog from '../../components/compositions/dialog/MyESimInfoDialog';
import { STORAGE_KEY_ESIM } from '../../helpers/constants';
import { isWebView } from '../../helpers/site';
import { openAppDeepLink } from '../../services/bridge';
import {
  hideDialog,
  hideQueuedDialog,
  showDialog,
  showQueuedDialogOnce,
} from '../page/dialog';

export const showESimReadyToPairDialog = () => (dispatch, getState) => {
  const { site, ui } = getState();

  const actions = [
    {
      label: ui.mySimcardDataEsimActivationDialogAction,
      withoutArrow: true,
      action: () => {
        dispatch(push(site.sitemap.MyESimActivationRoute.url));
        dispatch(hideQueuedDialog());
      },
    },
    {
      label: ui.mySimcardDataEsimActivationDialogLater,
      withoutArrow: true,
    },
  ];
  dispatch(showQueuedDialogOnce(STORAGE_KEY_ESIM, {
    headline: ui.mySimcardDataEsimActivationDialogHeadline,
    copy: ui.mySimcardDataEsimActivationDialogCopy,
    actions,
    shouldCloseOnTransition: true,
  }));
};

export const showMyESimInfoDialog = (type) => async (dispatch, getState) => {
  const state = getState();
  const { ui } = state;
  const subHl1 = ui.myEsimActivationHowtoDialogSubHl1;
  const subHl2 = ui.myEsimActivationHowtoDialogSubHl2;
  let headline = ui.myEsimActivationHowtoDialogManuallyHl;
  let copy1 = ui.txtMyEsimActivationInstructionsManuallyAndroid;
  let copy2 = ui.txtMyEsimActivationInstructionsManuallyIos;
  if (type === 'qr') {
    headline = ui.myEsimActivationHowtoDialogQrHl;
    copy1 = ui.txtMyEsimActivationInstructionsQrAndroid;
    copy2 = ui.txtMyEsimActivationInstructionsQrIos;
  } else if (type === 'push') {
    headline = ui.myEsimActivationHowtoDialogPushHl;
    copy1 = ui.txtMyEsimActivationInstructionsPushAndroid;
    copy2 = ui.txtMyEsimActivationInstructionsPushIos;
  }
  const dialogConfig = {
    id: 'MyESimInfoDialog',
    headline,
    component: MyESimInfoDialog,
    props: {
      ui,
      copy1,
      copy2,
      subHl1,
      subHl2,
    },
    onClose: () => dispatch(hideDialog()),
    withCloseAction: true,
    actions: [
      {
        label: ui.guiWordAllright,
        withoutArrow: true,
      },
    ],
  };

  dispatch(showDialog(dialogConfig));
};

export const showMyESimStatusDialog = (status = '', withCloseAction = false) =>
  async (dispatch, getState) => {
    const state = getState();
    const { ui, site } = state;
    let headline = ui.myEsimActivationUneligibleDialogHl;
    let copy = ui.myEsimActivationUneligibleDialogCopy;
    let label = ui.myEsimActivationUneligibleDialogLabel;
    let targetUrl = site.sitemap.MySimReplacementFormRoute.url;
    let targetAppUrl = site.sitemap.MyServicesRoute.url;
    if (status === 'IN_PROGRESS') {
      headline = ui.myEsimActivationProgressDialogHl;
      copy = ui.myEsimActivationProgressDialogCopy;
      label = ui.guiWordAllright;
      targetUrl = site.sitemap.MyDataRoute.url;
      targetAppUrl = targetUrl;
    } else if (status === 'INSTALLED') {
      headline = ui.myEsimActivationActivatedDialogHl;
      copy = ui.myEsimActivationActivatedDialogCopy;
      label = ui.guiWordAllright;
      targetUrl = `${site.sitemap.MyDataRoute.url}#sim`;
      targetAppUrl = `${targetUrl}`;
    }
    const dialogConfig = {
      headline,
      copy,
      withCloseAction,
      actions: [
        {
          label,
          withoutArrow: true,
          action: () => {
            if (isWebView()) {
              openAppDeepLink(targetAppUrl);
            } else {
              dispatch(push(targetUrl));
            }
            dispatch(hideDialog());
          },
        },
      ],
    };

    dispatch(showDialog(dialogConfig));
  };

