import React, { PureComponent } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import oneLine from 'common-tags/lib/oneLine';
import { push } from 'react-router-redux';
import suitcss from '../../../helpers/suitcss';
import connectUI from '../../basics/ui/UIConnector';
import { toDecimal, toFullCurrency } from '../../../helpers/money';
import { createWhatsAppShareLink } from '../../../helpers/socialMediaLinkShare';

import {
  shape as tariffShape,
  CATEGORY_SINGLE_PRODUCT,
} from '../../../propTypes/tariff';

import Headline from '../../basics/text/TextHeadline';
import StringTariffSingleFee from '../../basics/string/StringTariffSingleFee';
import Copy from '../../basics/text/TextCopy';
import Link from '../../basics/text/TextLink';
import Unit from '../../basics/text/TextUnit';
import Feature from './TariffDetailsFeature';
import Callout from '../../../containers/callout/Callout';
import FootnoteReference from '../../../containers/footnote/FootnoteReference';
import {
  ICON_WHATSAPP_SHARE_BUTTON,
  MARKET_POSTPAID,
  MARKETING_LOCATION_TARIFF_DETAILS,
  PAYMENT_FEE_SINGLE,
  PAYMENT_FEE_MONTHLY,
} from '../../../helpers/constants';
import { getPaymentFeeDuration, getTariffDuration } from '../../../helpers/entity';
import TariffOptionTeaser from './TariffOptionTeaser';

class TariffDetails extends PureComponent {

  static getPriceText(entity, paymentType) {
    const paymentTypeStrike = `${paymentType}Strike`;
    return entity[paymentTypeStrike]
      ? oneLine`<span class="u-strikethrough">${toDecimal(entity[paymentTypeStrike])}</span>&nbsp;${toFullCurrency(entity[paymentType])}`
      : toFullCurrency(entity[paymentType]);
  }

  constructor(props, context) {
    super(props, context);
    this.onBeforeSelect = this.onBeforeSelect.bind(this);
    this.onSelect = this.onSelect.bind(this);
  }

  onBeforeSelect() {
    const { tariff, onBeforeSelect, shoppingCartRoute, dispatch, urlSelect } = this.props;
    if (!onBeforeSelect) {
      dispatch(push(urlSelect));
      return;
    }
    onBeforeSelect(tariff);
    dispatch(push(shoppingCartRoute.url));
  }

  onSelect() {
    const { tariff, onSelect, shoppingCartRoute, dispatch } = this.props;
    if (!onSelect) {
      return;
    }
    onSelect(tariff);
    dispatch(push(shoppingCartRoute.url));
  }

  renderHeader() {
    const { headline, tariff, primaryModule } = this.props;
    return (
      <div
        className={suitcss({
          descendantName: 'header',
        }, this)}
      >
        {headline &&
          <div
            className={suitcss({
              descendantName: 'headline',
            }, this)}
          >
            <Headline size="s" embedded raw element={primaryModule ? 'h1' : 'h2'}>
              {headline}
            </Headline>
          </div>
        }
        {tariff.type &&
          <div
            className={suitcss({
              descendantName: 'type',
            }, this)}
          >
            <Headline size="xl" embedded raw>
              {tariff.type}
            </Headline>
          </div>
        }
        {tariff.name &&
          <div
            className={suitcss({
              descendantName: 'name',
            }, this)}
          >
            <Headline
              size="xl"
              embedded
              raw
              utilities={['colorInverted', 'colorPrimary', 'weightBold']}
            >
              {tariff.name}
            </Headline>
          </div>
        }
        <Callout
          theme="content"
          targets={[tariff.eid]}
          size="xl"
          inverted
        />
      </div>
    );
  }

  renderFeatures() {
    const { features } = this.props;
    return (
      <div
        className={suitcss({
          descendantName: 'features',
        }, this)}
      >
        {features.map((props, index) => (
          <div
            className={suitcss({
              descendantName: 'feature',
            }, this)}
            key={index}
          >
            <Feature {...props} />
          </div>
        ))}
      </div>
    );
  }

  renderPrices() {
    const { tariff, isContractRenewal, asTeaser, ui } = this.props;
    const unitPostfix = [
      getPaymentFeeDuration(tariff, ui, true),
      <FootnoteReference id={tariff.eid} />,
    ];
    const singlePaymentFeeCopy = oneLine`
      <p>
        ${StringTariffSingleFee.getText(tariff, ui)}
        ${TariffDetails.getPriceText(tariff, PAYMENT_FEE_SINGLE)}
      </p>
    `;
    return (
      <div
        className={suitcss({
          descendantName: 'prices',
        }, this)}
      >
        <div
          className={suitcss({
            descendantName: 'priceMonthly',

          }, this)}
        >
          <div
            className={suitcss({
              descendantName: 'unit',
              utilities: ['hidden', 'smBlock'],
            }, this)}
          >
            <Unit
              postfix={unitPostfix}
              price={tariff.paymentFee}
              prefixPrice={tariff.paymentFeeStrike}
              size="m"
            />
          </div>
          <div
            className={suitcss({
              descendantName: 'unit',
              utilities: ['hidden', 'lBlock'],
            }, this)}
          >
            <Unit
              postfix={unitPostfix}
              price={tariff.paymentFee}
              prefixPrice={tariff.paymentFeeStrike}
              size="l"
            />
          </div>
        </div>
        <div
          className={suitcss({
            descendantName: 'copy',
          }, this)}
        >
          {asTeaser && (
            <Copy embedded inverted>{ui.guiRegularPrice}</Copy>
          )}
          <Copy
            utilities={['colorGray100']}
            embedded
            raw
          >
            {`${getTariffDuration(tariff, ui)}
            ${!isContractRenewal ? singlePaymentFeeCopy : ''}`}
          </Copy>
          <Copy
            utilities={['colorGray100']}
            embedded
            raw
          >
            {ui.guiWordFreeShipping}
          </Copy>
          <Copy
            utilities={['colorGray100']}
            embedded
            raw
          >
            {ui.guiPucDeliveryTimeHint}
          </Copy>
        </div>
      </div>
    );
  }

  renderButtons() {
    const {
      tariff,
      ui,
      isHardwareSelected,
    } = this.props;
    const showCartLabel = (
      isHardwareSelected ||
      tariff.category === CATEGORY_SINGLE_PRODUCT ||
      (tariff.simOnly && tariff.market === MARKET_POSTPAID)
    );
    const whatAppLink = createWhatsAppShareLink();
    return (
      <div
        className={suitcss({
          descendantName: 'buttons',
        }, this)}
      >
        <div
          className={suitcss({
            descendantName: 'button',
          }, this)}
        >
          <Link
            asButton
            buttonFilled
            element={'button'}
            onClick={this.onBeforeSelect}
          >
            {showCartLabel && ui.guiAddCart}
            {!showCartLabel && ui.guiContinueSimPlus}
          </Link>
        </div>
        <div
          className={suitcss({
            descendantName: 'button',
          }, this)}
        >
          <Link
            onClick={this.onSelect}
            element={'button'}
            asButton
          >
            {ui.guiContinueSimOnly}
          </Link>
        </div>
        {/* removed button for OP-
        {tariff.availability.includes(AVAILABILITY_ACCOUNT) && tariff.market === MARKET_POSTPAID &&
        <div
          className={suitcss({
            descendantName: 'button',
          }, this)}
        >
          <Link onClick={e => { e.preventDefault(); onExtendContract(); }} asButton>
            {ui.myVvlButtonStart}
          </Link>
        </div>
        } */}
        <div
          className={suitcss({
            descendantName: 'button',
          }, this)}
        >
          <Link
            icon={ICON_WHATSAPP_SHARE_BUTTON}
            asShareButton
            utilities={['marginTop']}
            href={whatAppLink}
          >
            {ui.guiButtonWhatsapp}
          </Link>
        </div>
      </div>
    );
  }

  renderFooter() {
    const { tariff, footer } = this.props;
    return (
      <div
        className={suitcss({
          descendantName: 'footer',
        }, this)}
      >
        {footer && (
          <Copy size="secondary" embedded raw >
            {footer}
          </Copy>
        )}
        {tariff.documents && tariff.documents.length && (
          <div className={suitcss({ descendantName: 'links' }, this)} >
            {tariff.documents.map((doc, index) => (
              <div className={suitcss({ descendantName: 'link' }, this)} key={index} >
                <Link to={doc.file.url} utilities={['textSizeSmall']} withoutArrow >
                  {doc.title}
                </Link>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }

  renderPromotedTariffOptions() {
    const {
      tariffOptions,
      addOrderItem,
      removeOrderItem,
      selectedTariffOptionIds,
    } = this.props;

    return tariffOptions
      .filter(option =>
        option.marketingLocation &&
        option.marketingLocation.includes(MARKETING_LOCATION_TARIFF_DETAILS))
      .map(option =>
        (<TariffOptionTeaser
          key={option.eid}
          uniqueId={option.eid}
          headline={`${option.type} ${option.name}`}
          copy={option.infotext && option.infotext.replace(
            '{PRICE}',
            TariffDetails.getPriceText(option, PAYMENT_FEE_MONTHLY),
          )}
          removeItem={removeOrderItem}
          addItem={addOrderItem}
          active={selectedTariffOptionIds.includes(option.eid)}
          optionId={option.eid}
          image={option.image}
          info={option.copy}
        />));
  }

  render() {
    const {
      tariff,
      footer,
      isContractRenewal,
      asTeaser,
    } = this.props;

    return (
      <section
        className={suitcss({}, this)}
        data-tracking="tariff"
        data-tariff-id={tariff.iid}
      >
        {this.renderHeader()}
        <div
          className={suitcss({
            descendantName: 'content',
          }, this)}
        >
          {this.renderFeatures()}
          <div
            className={suitcss({
              descendantName: 'subcontent',
            }, this)}
          >
            {this.renderPrices()}
            {this.renderPromotedTariffOptions()}
            {!asTeaser && !isContractRenewal && this.renderButtons()}
          </div>
        </div>
        {(footer || tariff.documents) && this.renderFooter()}
        <Callout
          theme="badge"
          targets={[tariff.eid]}
          inverted
        />
      </section>
    );
  }
}


TariffDetails.propTypes = {
  headline: PropTypes.string.isRequired,
  footer: PropTypes.string,
  tariff: tariffShape.isRequired,
  isHardwareSelected: PropTypes.bool,
  isContractRenewal: PropTypes.bool,
  primaryModule: PropTypes.bool,
  features: PropTypes.arrayOf(
    PropTypes.shape({
      headline: PropTypes.string,
      subline: PropTypes.string,
      copy: PropTypes.string,
    }),
  ).isRequired,
  onBeforeSelect: PropTypes.func,
  dispatch: PropTypes.func,
  onSelect: PropTypes.func,
  onExtendContract: PropTypes.func,
  urlSelect: PropTypes.string,
  ui: PropTypes.shape({
    guiAddCart: PropTypes.string.isRequired,
    guiWordContinue: PropTypes.string.isRequired,
    guiWordMonthlyAbbr: PropTypes.string.isRequired,
    guiFeeSingular: PropTypes.string.isRequired,
    guiFeeSingularPayment: PropTypes.string.isRequired,
    guiFeeConnection: PropTypes.string.isRequired,
    myVvlButtonStart: PropTypes.string.isRequired,
    guiPucDeliveryTimeHint: PropTypes.string.isRequired,
  }),
  asTeaser: PropTypes.bool,
  tariffOptions: PropTypes.array,
  addOrderItem: PropTypes.func.isRequired,
  removeOrderItem: PropTypes.func.isRequired,
  orderProcess: PropTypes.object,
  shoppingCartRoute: PropTypes.object,
  selectedTariffOptionIds: PropTypes.array,
};

TariffDetails.defaultProps = {
  tariffOptions: [],
};

const mapStateToProps = ({ entities }) => ({
  amount: entities.duration && entities.duration.amount,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  connectUI(),
)(TariffDetails);

