import { camelCase } from '../../helpers/str';

class FieldErrorEntry {
  /**
   * @param {object|string} field - the field as specified in the field map.
   *     If only a string is supplied, it is assumed to be the field name.
   * @param {string} errorType - the name of the inline error e.g. 'valueMismatch'
   * @param {string} [correction] - suggested field value correction.
   */
  constructor(field, errorType, correction, ui) {
    this.field = typeof field === 'string' ? { name: field } : field;
    this.type = errorType;
    this.correction = correction;
    this.ui = ui;
  }

  /**
   * Verbalizes the inline error.
   *
   * An inline error ui consists of a prefix ('fer') an optional field type and
   * an inline error code: e.g. fer[MyFieldName]ValueMismatch.
   *
   * Note: It is on purpose that the message string is calculated "lazy"
   * once the property is accessed. This is because the message is often not
   * needed.
   */
  get message() {
    const { type: fieldType, name, validation = {} } = this.field;
    return (
      this.ui[camelCase(`fer_${fieldType}_${this.type}`)] ||
      this.ui[camelCase(`fer_${name}_${this.type}`)] ||
      this.ui[camelCase(`fer_${validation.pattern}_${this.type}`)] ||
      this.ui[camelCase(`fer_${this.type}`)] ||
      this.ui.ferGeneric
    );
  }

  toString() {
    return `${this.field.name}:${this.type}[${this.message}]`;
  }
}

export default FieldErrorEntry;
