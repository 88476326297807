/* global document */
import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import * as requestActions from '../../actions/request/registry';
import Headline from '../../components/basics/text/TextHeadline';
import Copy from '../../components/basics/text/TextCopy';
import Link from '../../components/basics/text/TextLink';
import GlobalSection from '../../components/basics/global/GlobalSection';
import ContentLoader from '../../components/compositions/content/ContentLoader';
import { scrollToElement } from '../../helpers/navigation';
import TeaserFreeform from '../teaser/TeaserFreeForm';
import ShareLinkContainer from '../shareLinkContainer/ShareLinkContainer';
import FriendReferralStatusContainer from '../friendReferralStatus/FriendReferralStatusContainer';
import { hideDialog, showDialog, showTemplateDialog } from '../../actions/page/dialog';
import {
  DIALOG_ID_FWF_DASHBOARD_INFO,
  DIALOG_ID_FWF_DASHBOARD_LEGAL,
  SCOPE_MYFRIEND,
  SCOPE_MYFRIEND_READ,
} from '../../helpers/constants';
import suitcss from '../../helpers/suitcss';
import { isScopeAllowed } from '../../helpers/scope';

class MyFriendReferralDashboard extends PureComponent {
  constructor(...props) {
    super(...props);
    this.showLegalDialog = this.showLegalDialog.bind(this);
  }

  componentDidMount() {
    const {
      isUserReferFriendsEnabled,
      dispatch,
      sitemap,
      ui,
      fetchFriendReferralStatus,
    } = this.props;

    if (!isUserReferFriendsEnabled) {
      dispatch(showDialog({
        headline: ui.myFrdGlobalDeniedDialogHeadline,
        copy: ui.myFrdGlobalDeniedDialogCopy,
        withCloseAction: false,
        actions: [
          {
            label: ui.myFrdGlobalDeniedDialogToDashboard,
            action: () => {
              dispatch(push(sitemap.MyDashboardRoute.url));
              dispatch(hideDialog());
            },
            withoutArrow: true,
          },
        ],
      }));
    }

    if (isUserReferFriendsEnabled) {
      fetchFriendReferralStatus();
    }
  }

  componentDidUpdate() {
    if (this.props.isEligibleForReferral) this.props.fetchFriendReferralLink();
  }

  scrollToInfoStage() {
    const infoStage = document.querySelector('#infoStage');
    if (!infoStage) return;
    scrollToElement(infoStage);
  }

  showLegalDialog(id) {
    const { dispatch } = this.props;
    if (!id) return;
    dispatch(showTemplateDialog(id));
  }

  renderHeader() {
    const { ui } = this.props;
    const headerSubline = ui.myFrdSubline;
    const headerSublineInfoLink = ui.myFrdSublineInfoLink;
    return (
      <GlobalSection
        className={suitcss({ descendantName: 'header' }, this)}
        layout="contained"
        centered
      >
        <Headline size="l" utilities={['weightBold']}>
          {ui.myFrdHeadline}
        </Headline>
        {headerSubline && (
          <Copy embedded>{ui.myFrdSubline}</Copy>
        )}
        {headerSublineInfoLink && (
          <Link
            className={suitcss({ descendantName: 'headerLink' }, this)}
            utilities={['alignCenter']}
            element="button"
            withoutArrow
            onClick={this.scrollToInfoStage}
          >
            {ui.myFrdSublineInfoLink}
          </Link>
        )}
      </GlobalSection>
    );
  }

  renderShareLinkContainer() {
    const {
      ui,
      referralLink,
      isEligibleForReferral,
      ineligibilityReasonText,
    } = this.props;
    const { referralLink: referralShareLink } = referralLink;
    const referralShareLinks = [
      {
        type: 'whatsApp',
        label: ui.myFrdLinkCtaWhatsapp,
        shareText: ui.myFrdLinkShareText.replace(
          '{URL}',
          encodeURIComponent(`${referralShareLink}&c_id=${ui.myFrdShareParamCidWebWhatsapp}`),
        ),
      },
      {
        type: 'mail',
        label: ui.myFrdLinkCtaMail,
        shareText: ui.myFrdLinkShareText.replace(
          '{URL}',
          encodeURIComponent(`${referralShareLink}&c_id=${ui.myFrdShareParamCidWebMail}`),
        ),
      },
      {
        type: 'copyLink',
        label: ui.myFrdLinkCtaCopy,
        shareText: `${referralShareLink}&c_id=${ui.myFrdShareParamCidWebCopy}`,
      },
    ];

    const referralShareLinksMobile = [
      {
        type: 'nativeShare',
        label: ui.myFrdLinkCtaShare,
        shareText: ui.myFrdLinkShareText.replace(
          '{URL}',
          encodeURI(`${referralShareLink}&c_id=${ui.myFrdShareParamCidWebShare}`),
        ),
      },
    ];

    return (
      <ContentLoader
        className={suitcss({ utilities: ['flex', 'itemsCenter', 'justifyCenter'] }, this)}
        isLoaded={isEligibleForReferral || !!ineligibilityReasonText}
      >
        <ShareLinkContainer
          utilities={['sMarginBottomS']}
          bottomHintDialog={{ id: DIALOG_ID_FWF_DASHBOARD_INFO }}
          title={ui.myFrdLinkHeadline}
          subline={!isEligibleForReferral ? ineligibilityReasonText : null}
          bottomHint={isEligibleForReferral ? ui.myFrdLinkInfosLink : null}
          showLinks={isEligibleForReferral && !!referralShareLink}
          shareItems={referralShareLinks}
          mobileShareItems={referralShareLinksMobile}
        />
      </ContentLoader>
    );
  }

  renderDeniedContent() {
    const { ui } = this.props;
    return (
      <div className={suitcss({ utilities: ['sMarginTopM'] }, this)}>
        <Headline utilities={['weightBold']}>{ui.myFrdGlobalDeniedDialogHeadline}</Headline>
        <Copy>
          {ui.myFrdGlobalDeniedDialogCopy}
        </Copy>
      </div>
    );
  }

  renderLegalHint() {
    const { ui } = this.props;
    return (
      <div
        className={suitcss({
          descendantName: 'legalHint',
          utilities: ['alignCenter', 'marginTopS', 'marginBottomM'],
          }, this)}
      >
        <Link
          utilities={['alignCenter']}
          element="button"
          withoutArrow
          onClick={() => {
            this.showLegalDialog(DIALOG_ID_FWF_DASHBOARD_LEGAL);
          }}
        >
          {ui.myFrdLegalHint}
        </Link>
      </div>
    );
  }

  render() {
    const { ui, isUserReferFriendsEnabled, referralStatusItems } = this.props;
    return (
      <Fragment>
        {this.renderHeader()}
        <TeaserFreeform
          className={suitcss({ descendantName: 'sharingTeaser' }, this)}
          image={{ src: ui.myFrdSharingImageLarge, alt: ui.myFrdHeadline }}
          mobileImage={{ src: ui.myFrdSharingImage, alt: ui.myFrdHeadline }}
          mobileContentTop={!isUserReferFriendsEnabled}
        >
          {
            isUserReferFriendsEnabled ?
            (this.renderShareLinkContainer()) :
            (this.renderDeniedContent())
          }
        </TeaserFreeform>
        {isUserReferFriendsEnabled && (
          <GlobalSection adjacent>
            <FriendReferralStatusContainer
              className={suitcss({ descendantName: 'statusTeaser', utilities: ['marginTopM'] }, this)}
              referralStatusItems={referralStatusItems}
            />
          </GlobalSection>
        )}
        {isUserReferFriendsEnabled && (this.renderLegalHint())}
      </Fragment>
    );
  }
}

MyFriendReferralDashboard.propTypes = {
  ui: PropTypes.object,
  dispatch: PropTypes.func,
  isUserReferFriendsEnabled: PropTypes.bool.isRequired,
  sitemap: PropTypes.object,
  referralLink: PropTypes.object,
  referralStatusItems: PropTypes.array,
  fetchFriendReferralLink: PropTypes.func,
  fetchFriendReferralStatus: PropTypes.func,
  isEligibleForReferral: PropTypes.bool,
  ineligibilityReasonText: PropTypes.string,
};

const ineligibilityReason = (referralStatus, ui) => {
  if (referralStatus.friendReferralEligibility &&
    referralStatus.friendReferralEligibility.ineligibilityReason) {
    if (referralStatus.friendReferralEligibility.ineligibilityReason === 'MAX_REFERRAL_COUNT_REACHED') {
      return ui.myFrdSharingLimit;
    }
    if (referralStatus.friendReferralEligibility.ineligibilityReason === 'DUNNING_LEVEL_TOO_HIGH') {
      return ui.myFrdSharingWarning;
    }
  }
};

const mapStateToProps = (state) => {
  const { ui, user, site, friendReferral } = state;
  const { referralLink, referralStatus } = friendReferral;
  const isEligibleForReferral = referralStatus.friendReferralEligibility &&
    referralStatus.friendReferralEligibility.isEligibleForReferral;

  const ineligibilityReasonText = ineligibilityReason(referralStatus, ui);

  return {
    ui,
    isUserReferFriendsEnabled: isScopeAllowed(user.scope, SCOPE_MYFRIEND) ||
      isScopeAllowed(user.scope, SCOPE_MYFRIEND_READ),
    sitemap: site.sitemap,
    referralLink,
    referralStatusItems: referralStatus.currentReferralBookings,
    ineligibilityReasonText,
    isEligibleForReferral,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchFriendReferralLink: () => dispatch(requestActions.fetchFriendReferralLink()),
  fetchFriendReferralStatus: () => dispatch(requestActions.fetchFriendReferralStatus()),
  dispatch,
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(MyFriendReferralDashboard);
