/**
 * Returns a random number withing the given interval
 *
 * @param {number} min (inclusive)
 * @param {number} max (exclusive)
 * @return {number}
 */
export const getRandomInt = (min, max) => Math.floor((Math.random() * (max - min)) + min);

/**
 * Returns a random value picked from the provided array.
 *
 * @param {Array} arr
 * @return {*}
 */
export const pickRandom = arr => arr[getRandomInt(0, arr.length)];
