import React, { PureComponent } from 'react';
import suitcss from '../../../helpers/suitcss';

import { navLinkList } from '../../../propTypes/navigation';

import Link from '../../basics/text/TextLink';

class SitemapLinkNavigation extends PureComponent {
  constructor(...args) {
    super(...args);
    this.state = {};
  }

  getColumnSectionIds() {
    const { items } = this.props;
    const columns = [];
    items.map((section, index) => {
      const col = section.class && (section.class.slice(section.class.length - 1) - 1);

      if (isNaN(col)) {
        return false;
      }

      if (!columns[col]) {
        columns[col] = [];
      }

      columns[col].push(index);
      return true;
    });

    return columns;
  }

  renderSection(item, key) {
    return (
      <div
        className={suitcss({
          componentName: 'SitemapLinkNavigation',
          descendantName: 'section',
        })}
        key={key}
      >
        <div
          className={suitcss({
            componentName: 'SitemapLinkNavigation',
            descendantName: 'sectionLink',
          })}
        >
          <Link
            to={item.url}
            withoutArrow
            utilities={['uppercase', 'colorDefault', 'fontCondensed']}
          >
            {item.title.toUpperCase()}
          </Link>
        </div>
        {item.children.map((childItem, index) => (
          <div
            className={suitcss({
              componentName: 'SitemapLinkNavigation',
              descendantName: 'link',
            })}
            key={index}
          >
            <Link
              to={childItem.url}
              withoutArrow
              utilities={['colorDefault']}
            >
              { childItem.title}
            </Link>
          </div>
        ))}
      </div>
    );
  }

  render() {
    const { items } = this.props;
    const columnSectionIds = this.getColumnSectionIds();
    return (
      <nav
        className={suitcss({
          componentName: 'SitemapLinkNavigation',
        })}
      >
        {columnSectionIds.map((column, index) => (
          <div
            className={suitcss({
              componentName: 'SitemapLinkNavigation',
              descendantName: 'column',
            })}
            key={index}
          >
            {column.map((id, key) => this.renderSection(items[id], key))}
          </div>
        ))}
      </nav>
    );
  }
}

SitemapLinkNavigation.propTypes = {
  items: navLinkList.isRequired,
};

export default SitemapLinkNavigation;
