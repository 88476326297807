import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import suitcss from '../../../helpers/suitcss';
import TextRaw from '../../basics/text/TextRaw';
import AbstractChatBot from './AbstractChatBot';
import AbstractChatUser from './AbstractChatUser';
import { MESSAGE_SENDER_SYSTEM, MESSAGE_TYPE_INIT, MESSAGE_TYPE_WAKE_UP_LOOP } from './constants';
import Message from './Message';

/**
 * Generic chat component that should be agnostic about the
 * specific chat implementation. It is only responsible for
 * displaying messages and input panels, as well as directing
 * user input to the chatbot.
 */
const componentName = 'Chat';
class Chat extends PureComponent {

  constructor(props) {
    super(props);
    // note: the bot is only registered once on mount
    this.chatBot = props.chatBot;
    this.user = props.user;
    this.state = {
      messages: [],
      botResponseQueue: [],
      ChatInputPanelClass: this.chatBot.chatInputPanelClass,
    };
    this.sendMessage = this.sendMessage.bind(this);
  }

  componentDidMount() {
    this.sendMessage(new Message(MESSAGE_SENDER_SYSTEM, { type: MESSAGE_TYPE_INIT }));
    this.interval = setInterval(() => {
      const { botResponseQueue, messages } = this.state;
      if (!botResponseQueue.length && !messages.some(msg => msg.isTyping)) {
        this.sendMessage(new Message(MESSAGE_SENDER_SYSTEM, { type: MESSAGE_TYPE_WAKE_UP_LOOP }));
      }
    }, 10000);
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }

    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    if (this.timeoutBot) {
      clearTimeout(this.timeoutBot);
    }
  }

  /**
   * Displays the message in the chat's messages window.
   *
   * Only messages that contain a text property in their payload are displayed;
   * otherwise it is assumed that the message is only meant to invisibly communicate data
   *
   * @param {Message} message
   * @param {number} delay - postpones the message (in milliseconds)
   */
  displayMessage(message, delay) {
    if (!message || !message.getText || !message.getText()) {
      return;
    }

    if (delay) {
      this.timeout = setTimeout(() => this.displayMessage(message), delay);
      return;
    }

    if (message.sender === this.chatBot.getName()) {
      message.setIsTyping(true);
      this.timeoutBot = setTimeout(() => {
        const { messages } = this.state;
        const msgIndex = messages.findIndex(msg => msg.id === message.id);
        messages[msgIndex].setIsTyping(false);
        this.setState({
          messages: [...messages],
          ChatInputPanelClass: this.state.botResponseQueue.length
            ? this.state.ChatInputPanelClass
            : this.chatBot.chatInputPanelClass,
        }, () => {
          const { botResponseQueue } = this.state;
          this.messagesDiv.scrollTop = this.messagesDiv.scrollHeight;
          if (botResponseQueue.length) {
            this.displayMessage(botResponseQueue[0], 500);
          }
        });
      }, message.getText().length * 20);
    }

    this.setState(
      // note: Subsequent calls will override values from previous calls in the same cycle,
      // hence the need for the state updater function
      (prevState) => ({
        messages: [...prevState.messages, message],
        botResponseQueue: prevState.botResponseQueue.filter(msg => msg.id !== message.id),
      }),
      () => {
        this.messagesDiv.scrollTop = this.messagesDiv.scrollHeight;
      },
    );
  }

  /**
   * Handles a user message comming from the chat input panel.
   * If the message contains a text, it will be displayed in the chat's
   * messages window.
   *
   * The chatbot is given the chance to inspect and react to the message
   * by returning a single or a set of messages.
   *
   * @param {Message} message
   */
  async sendMessage(message) {
    const { botResponseQueue } = this.state;
    const isChatBot = message.sender === this.chatBot.getName();
    if (!isChatBot) {
      this.displayMessage(message);
    }

    let reply = await this.chatBot.handle(message);
    if (reply) {
      if (!Array.isArray(reply)) {
        // turn single message into array of messages
        reply = [reply];
      }
      // Filter text only messages
      reply = reply.filter(msg => msg.getText && msg.getText());
      this.setState({ botResponseQueue: botResponseQueue.concat(reply) },
        () => {
          if (!this.state.messages.some(msg => msg.isTyping)) {
            this.displayMessage(this.state.botResponseQueue[0], 1000);
          }
        },
      );
    }
  }

  renderMessage(message, key) {
    const isChatBot = message.sender === this.chatBot.getName();
    const icon = isChatBot ? this.chatBot.getIcon() : this.user.getIcon();
    return (
      <div
        className={suitcss({
          descendantName: 'message',
          modifiers: [
            isChatBot && 'chatbot',
            message.isTyping && 'isTyping',
          ],
        }, this)}
        key={key}
      >
        {icon && isChatBot && (
          <img
            className={suitcss({ descendantName: 'messageIcon' }, this)}
            src={icon}
            alt="icon"
          />
        )}
        <span className={suitcss({ descendantName: 'messageText' }, this)}>
          <TextRaw>{message.getText()}</TextRaw>
          <span>
            <b>.</b><b>.</b><b>.</b>
          </span>
        </span>
        {icon && !isChatBot && (
          <img
            className={suitcss({ descendantName: 'messageIcon' }, this)}
            src={icon}
            alt="icon"
          />
        )}
      </div>
    );
  }

  render() {
    const { className } = this.props;
    const { messages, botResponseQueue, ChatInputPanelClass } = this.state;
    const botResponseQueueEmpty = botResponseQueue.length > 0;
    const isDisabled = messages.some((message) => message.isTyping) || botResponseQueueEmpty;
    return (
      <div className={suitcss({ className }, this)}>
        <div
          ref={domElement => { this.messagesDiv = domElement; }}
          className={suitcss({ descendantName: 'content' }, this)}
        >
          <div className={suitcss({ descendantName: 'messages' }, this)} >
            {messages.map(this.renderMessage, this)}
          </div>
        </div>
        {ChatInputPanelClass && (
          <ChatInputPanelClass
            componentName={componentName}
            sendMessage={this.sendMessage}
            params={this.chatBot.params}
            isDisabled={isDisabled}
          />
        )}
      </div>
    );
  }
}

Chat.propTypes = {
  className: PropTypes.string.isRequired,
  chatBot: PropTypes.instanceOf(AbstractChatBot),
  user: PropTypes.instanceOf(AbstractChatUser),
};

export default Chat;
