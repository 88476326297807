import { createFallbackErrorCode } from '../../config/errorCodes';
import AbstractError from './AbstractError';
import { copyObj } from '../../helpers/misc';

class ApiError extends AbstractError {
  /**
   * @param {Response} fullResponse
   * @param {QueueableRequest} request - the original request that failed
   */
  constructor(fullResponse, request) {
    const details = (fullResponse.body.error || {});
    const fullCode = details.fullCode || createFallbackErrorCode(fullResponse.status);
    super(fullCode, request.getContext());

    this.name = 'ApiError'; // @todo remove after careful search
    this.request = request;
    this.fullResponse = fullResponse;
    this._details = details;

    /**
     * @deprecated use getDetails()
     */
    this.response = details;
  }

  /**
   * Returns the part of the response body that resides inside the top-level `error` property
   * @return {Object}
   */
  getDetails() {
    return this._details;
  }

  getLoggingData() {
    return {
      ...super.getLoggingData(),
      ...copyObj(this.request, ['id', 'headers', 'payload', 'transactionId']),
    };
  }
}

export default ApiError;
