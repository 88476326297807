import PropTypes from 'prop-types';

export const eSimInformation = {
  smdpPlus: PropTypes.string,
  activationCode: PropTypes.string,
  confirmationCode: PropTypes.string,
};

export const eSimInformationShape = PropTypes.shape(eSimInformation);

export const eSimQr = {
  smdpPlus: PropTypes.string,
  activationCode: PropTypes.string,
  confirmationCode: PropTypes.string,
  quickResponseCode: PropTypes.string,
};

export const eSimQrShape = PropTypes.shape(eSimQr);
