import React, { Component as ReactComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import shallowEqual from 'react-pure-render/shallowEqual';
import ContentLoader from '../../components/compositions/content/ContentLoader';

import PageModule from '../../model/modules/PageModule';
import connectUI from '../../components/basics/ui/UIConnector';
import GlobalSection from '../../components/basics/global/GlobalSection';
import SkipException from '../../model/errors/SkipException';
import { devWarning } from '../../helpers/meta';
import { MODULE_CONFIG_LAYOUT, MODULE_CONFIG_BEHAVIOR } from '../../helpers/constants';
import LazyShowElement from '../../components/basics/element/LazyShowElement';

/**
 * GlobalModule is responbsile to resolves the module types from backend into react components
 * to render them on the page
 */

class GlobalModule extends ReactComponent {

  constructor(props, context) {
    super(props, context);
    // note: this is not were the prepare action is actually dispatched,
    // it is just created at this point and will be dispatched in componentDidMount
    this.modulePrepareAction = props.module.prepareBeforeMount(props);
    this.state = {
      // if module has no prepareBeforeMount function we set is loaded to true;
      // otherwise we set it to false as we expect the module to do some preparations
      isLoaded: !this.modulePrepareAction,
      // indicates that the module decided to be skipped; we do not use the isLoaded flag
      // for this because if a module wants to be skipped, we do not want a loading spinner
      // to be displayed.
      isSkipped: false,
      preparedProps: {},
    };
  }

  async componentDidMount() {
    const { dispatch } = this.props;
    if (this.modulePrepareAction) {
      try {
        const preparedProps = (await dispatch(this.modulePrepareAction)) || {};
        this.setState({ // eslint-disable-line react/no-did-mount-set-state
          isLoaded: true,
          preparedProps,
        });
      } catch (e) {
        if (e instanceof SkipException) {
          devWarning(e);
          // mark this module as skipped
          this.setState({ isSkipped: true }); // eslint-disable-line react/no-did-mount-set-state
        }
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState !== this.state) {
      return true;
    }
    if (shallowEqual(nextProps, this.props)) {
      return false;
    }

    // as far as we are not working with immutable data and some instances
    // will always change we need to check its props all time
    const propsHasOwnProperty = Object.prototype.hasOwnProperty.bind(this.props);
    return !Object.keys(nextProps).every(key => (
      propsHasOwnProperty(key) && shallowEqual(nextProps[key], this.props[key])
    ));
  }

  render() {
    const { entities, location, module, primaryModule, ui } = this.props;
    const Component = module.component;

    if (this.state.isSkipped) {
      return null;
    }

    const moduleConfig = module.params.config || {};

    if (!this.state.isLoaded) {
      return !module.shouldShowLoader
        ? null
        : (
          <GlobalSection
            theme={module.params.colorScheme}
            layout="contained"
            layoutSettings={moduleConfig[MODULE_CONFIG_LAYOUT]}
          >
            <ContentLoader isLoaded={false} height={150} />
          </GlobalSection>
        );
    }
    return (
      <LazyShowElement
        id={module.eid}
        data-tracking="module"
        data-module-id={module.eid}
        data-module-type={module.etype}
        data-module-title={module.etitle}
        data-module-theme={module.params.colorScheme}
        isDisabled={module.etype === 'NavProcess'}
        isParent
      >
        <Component
          {...this.state.preparedProps}
          moduleId={module.eid}
          moduleTitle={module.etitle}
          primaryModule={primaryModule}
          params={module.params}
          layoutSettings={moduleConfig[MODULE_CONFIG_LAYOUT]}
          behaviorSettings={moduleConfig[MODULE_CONFIG_BEHAVIOR]}
          entities={entities}
          ui={ui}
          location={location}
        />
      </LazyShowElement>
    );
  }
}

GlobalModule.propTypes = {
  module: PropTypes.instanceOf(PageModule),
  entities: PropTypes.array.isRequired,
  ui: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  primaryModule: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
};

const makeMapStateToProps = () => {
  // @todo outsource selector to file
  // @todo this selector should ideally replace the overcomplex "PreloaderEntity"
  const selectModuleEntities = createSelector(
    (state, ownProps) => ownProps.module.entities,
    (state) => state.entities,
    (moduleEntities, allEntities) => moduleEntities.map(e => allEntities[e.etype][e.eid]),
  );
  return (state, props) => ({
    ui: state.ui,
    entities: selectModuleEntities(state, props),
  });
};

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default compose(
  connect(makeMapStateToProps, mapDispatchToProps),
  // @todo to be removed in the future;
  // moreover, each container should decide for itself whether it actually needs any ui
  // and then get it via mapStateToProps
  connectUI(),
)(GlobalModule);
