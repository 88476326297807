import NotImplementedException from '../../../model/errors/NotImplementedException';

/**
 * Abstract implementation of a chat bot.
 */
class AbstractChatBot {
  /**
   * @param {string} name - the name of the bot
   */
  constructor(name = 'bot', icon) {
    this.name = name;
    this.icon = icon;
  }

  /**
   * Returns the name of the bot
   * @return {string}
   */
  getName() {
    return this.name;
  }

  /**
   * Returns the icon of the bot
   * @return {string}
   */
  getIcon() {
    return this.icon;
  }

  /**
   * Returns the input panel component that will be rendered below the
   * chat window and will allow the user to communicate with the chatbot.
   *
   * @return {Component}
   */
  getChatInputPanel() {
    throw new NotImplementedException();
  }

  /**
   * Handles an incoming message. The bot is given the chance
   * to respond to the incoming message by returning a message or
   * a set of messages.
   *
   * @abstract
   * @param {Message} message - incoming message
   * @return {Message|Array<Message>}
   */
  handle(message) { // eslint-disable-line
    throw new NotImplementedException();
  }
}

export default AbstractChatBot;
