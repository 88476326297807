import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import suitcss from '../../../helpers/suitcss';
import { capitalize } from '../../../helpers/str';
import { preventDefault } from '../../../helpers/functional';

import Copy from '../../basics/text/TextCopy';
import Link from '../../basics/text/TextLink';

const componentName = 'FormFooter';
function FormFooter(props) {
  const {
    labelSubmit,
    labelCancel,
    dataSubmit,
    dataCancel,
    customSubmitButton,
    customCancelButton,
    onSubmit,
    onCancel,
    isSubmitDisabled,
    isSubmitOptional,
    hintSubmit,
    withRequiredHint,
    align,
    asStack,
    ui,
    additionalButton,
    isContractRenewal,
  } = props;
  return (
    <div
      className={suitcss({
        componentName,
        utilities: ['col12'],
        modifiers: [
          !asStack && `align${capitalize(align)}`,
          asStack && 'asStack',
        ],
      })}
    >
      {withRequiredHint &&
        <Copy
          className={suitcss({ componentName, descendantName: 'hint', utilities: ['colorGray100'] }, this)}
          element="div"
          size="secondary"
        >
          {ui.guiFormRequiredFields}
        </Copy>
      }
      <div className={suitcss({ componentName, descendantName: 'buttons' })}>
        <div
          className={suitcss({
            componentName,
            descendantName: 'link',
            modifiers: ['primary'],
            utilities: [additionalButton ? 'mCol10' : 'mCol5', additionalButton ? 'lCol8' : 'lCol4'],
          })}
        >
          { additionalButton }
          <div className={suitcss({ componentName, descendantName: 'submit' })}>
            { customSubmitButton }
            {!customSubmitButton &&
              <Link
                element="button"
                onClick={onSubmit}
                isDisabled={isSubmitDisabled}
                buttonFilled={!isSubmitOptional}
                asButton
                data={dataSubmit}
              >
                {isContractRenewal ? ui.cfoPlaceRenewal : labelSubmit || ui.guiWordContinue}
              </Link>
            }
            {hintSubmit &&
              <Copy
                className={suitcss({
                  componentName,
                  descendantName: 'hint',
                  utilities: ['alignCenter', 'colorGray50'],
                }, this)}
                element="span"
                size="small"
                embedded
                raw
              >
                {hintSubmit}
              </Copy>
            }
          </div>
        </div>
        {(onCancel || customCancelButton) &&
          <div className={suitcss({ componentName, descendantName: 'link', modifiers: ['secondary'] })}>
            { customCancelButton }
            {!customCancelButton &&
              <Link
                element="button"
                onClick={preventDefault(onCancel)}
                utilities={['arrowLeft', 'uppercase', 'fontCondensed']}
                data={dataCancel}
              >
                {labelCancel || ui.guiWordBack}
              </Link>
            }
          </div>
        }
      </div>
    </div>
  );
}

FormFooter.propTypes = {
  labelSubmit: PropTypes.string,
  labelCancel: PropTypes.string,
  hintSubmit: PropTypes.string,

  dataSubmit: PropTypes.object,
  dataCancel: PropTypes.object,

  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  // Custom buttons for cases where default
  // methods need overriding
  additionalButton: PropTypes.element,
  customSubmitButton: PropTypes.element,
  customCancelButton: PropTypes.element,

  isSubmitDisabled: PropTypes.bool,
  isSubmitOptional: PropTypes.bool,

  withRequiredHint: PropTypes.bool,
  asStack: PropTypes.bool,
  align: PropTypes.oneOf(['between', 'left', 'right']),
  // Fallback ui for button labels + Required text
  ui: PropTypes.shape({
    guiFormRequiredFields: PropTypes.string.isRequired,
    guiWordBack: PropTypes.string.isRequired,
    guiWordContinue: PropTypes.string.isRequired,
  }),
  isContractRenewal: PropTypes.bool,
};

FormFooter.defaultProps = {
  align: 'between',
};

const mapStateToProps = (state) => {
  return {
    ui: state.ui,
    isContractRenewal: state.site.contractRenewal.isInProgress,
  };
};

export default connect(mapStateToProps)(FormFooter);

