import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import FooterMain from '../../components/compositions/footer/FooterMain';
import { navLinkList } from '../../propTypes/navigation';

const GlobalFooter = ({ footerItems, metaItems, isBranded, isContractRenewal }) => {
  if (!footerItems || !metaItems) {
    return null;
  }

  return (
    <FooterMain
      sitemapItems={footerItems}
      metaItems={metaItems}
      withoutSitemap={isBranded}
      isContractRenewal={isContractRenewal}
    />
  );
};

GlobalFooter.propTypes = {
  footerItems: navLinkList,
  metaItems: navLinkList,
  isBranded: PropTypes.bool,
  isContractRenewal: PropTypes.bool,
};

const makeMapStateToProps = () => (state, ownProps) => {
  const { site } = state;
  const footerMenu = state.menu[ownProps.identifierFooter] || {};
  const metaMenu = state.menu[ownProps.identifierMeta] || {};
  const isBranded = !!site.brandName;
  return {
    footerItems: footerMenu.items,
    metaItems: metaMenu.items,
    isContractRenewal: site.contractRenewal.isInProgress,
    isBranded,
  };
};

export default connect(makeMapStateToProps)(GlobalFooter);
