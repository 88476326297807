import PageModule from './PageModule';

/**
 * Module to display a set of tariffs in a list.
 * @see https://confluence.db-n.com/x/XI3c
 */
class TariffListModule extends PageModule {
  constructor(moduleData) {
    super(import('../../containers/tariff/TariffList'), moduleData);
  }
}

export default TariffListModule;
