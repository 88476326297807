import { push } from 'react-router-redux';

import { addDialogToQueue, showDialog } from '../page/dialog';

export const MNP_STATUS_NSE = 'negotiation_sent';
export const MNP_STATUS_EIN = 'negotiation_error';
export const MNP_STATUS_PNS = 'negotiation_started';
export const MNP_STATUS_CPN = 'negotiation_concluded';
export const MNP_STATUS_PRS = 'sent';
export const MNP_STATUS_CRE = 'created';
export const MNP_STATUS_CAN = 'canceled';
export const MNP_STATUS_CPL = 'completed';

const showMnpDialogAndLinkToMyServices = (headline, copy) => (dispatch, getState) => {
  const { site, ui } = getState();
  dispatch(showDialog({
    headline: ui[headline],
    copy: ui[copy],
    actions: [{ label: ui.guiWordAllright }],
  }));
  dispatch(push(site.sitemap.MyServicesRoute.url));
};

const showBelatedMnpNotAvailableDialog = () =>
  showMnpDialogAndLinkToMyServices('mnpMydataMenuitem', 'errServiceUnavailable');

const showBelatedMnpPnsDialog = () =>
  showMnpDialogAndLinkToMyServices('mnpStatusPendingTitle', 'guiOrderPending');

const showBelatedMnpCpnPrsDialog = () =>
  showMnpDialogAndLinkToMyServices('mnpStatusPendingTitle', 'mnpStatusSuccessCopy');

const showBelatedMnpCplDialog = () =>
  showMnpDialogAndLinkToMyServices('mnpStatusFinishedTitle', 'mnpStatusFinishedCopy');

const showBelatedMnpCreDialog = () =>
  showMnpDialogAndLinkToMyServices('mnpStatusConfirmedTitle', 'guiOrderPending');

const showBelatedMnpCanDialog = () =>
  showMnpDialogAndLinkToMyServices('mnpStatusCancelledTitle', 'mnpStatusCancelledCopy');

const showBelatedMnpNseDialog = () =>
  showMnpDialogAndLinkToMyServices('mnpStatusPendingTitle', 'mnpStatusPendingNegotiationSentCopy');

const showBelatedMnpEinDialog = () =>
  showMnpDialogAndLinkToMyServices('mnpStatusPendingTitle', 'mnpStatusPendingNegotiationErrorCopy');

export const isMnpState = (mnpState) =>
  [
    MNP_STATUS_PNS,
    MNP_STATUS_PRS,
    MNP_STATUS_CPN,
    MNP_STATUS_CRE,
    MNP_STATUS_EIN,
    MNP_STATUS_NSE,
  ].some((element) => element === mnpState);


export const showBelatedMnpInProgressDialog = () => (dispatch, getState) => {
  const { ui } = getState();
  dispatch(addDialogToQueue({
    headline: ui.mnpStatusInProgess,
    copy: ui.txtMnpStatusInProgessCopyPuc,
    actions: [{ label: ui.guiWordAllright }],
  }));
};

export const getDialogByBelatedMnpState = mnpState => {
  switch (mnpState) {
    case MNP_STATUS_PNS:
      return showBelatedMnpPnsDialog();
    case MNP_STATUS_CPN:
    case MNP_STATUS_PRS:
      return showBelatedMnpCpnPrsDialog();
    case MNP_STATUS_CRE:
      return showBelatedMnpCreDialog();
    case MNP_STATUS_CAN:
      return showBelatedMnpCanDialog();
    case MNP_STATUS_CPL:
      return showBelatedMnpCplDialog();
    case MNP_STATUS_NSE:
      return showBelatedMnpNseDialog();
    case MNP_STATUS_EIN:
      return showBelatedMnpEinDialog();
    default:
      return showBelatedMnpNotAvailableDialog();
  }
};
