import { connect } from 'react-redux';
import { compose } from 'redux';
import { change, reset } from 'redux-form';
import * as requestActions from '../../../actions/request/registry';
import initForm from '../../form/FormInitializer';
import MyESimPushForm from '../../../components/compositions/account/eSim/MyESimPushForm';

const createFieldMap = ({ ui }) => ({
  eSimEid: {
    name: 'eid',
    label: ui.myEsimActivationEId,
    validation: {
      range: [32],
      isRequired: true,
      pattern: /^\d{32}$/,
    },
  },
});

const mapStateToProps = (state) => {
  const { user } = state;
  const { contractData } = user;
  const { simCard } = contractData;
  return {
    msisdn: state.user.credentials.msisdn,
    eSimData: simCard.esimStatus,
    isESimReadyToDeAssign: simCard.esimStatus.possibleActions.includes('PUSH_DEASSIGN'),
    isESimReadyToPush: simCard.esimStatus.possibleActions.includes('PUSH_ASSIGN'),
    isESimUndefinedProgress: simCard.esimStatus.esimProvisionStatus === 'IN_PROGRESS'
      || simCard.esimStatus.esimProvisionStatus === 'DOWNLOAD_IN_PROGRESS',
    form: state.form,
    statusRefresh: state.user.eSim.statusRefresh,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchESimStatus: () =>
    dispatch(requestActions.fetchSimCardESimStatus(null, true)),
  dispatch,
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { form, fieldMap } = ownProps;
  const { dispatch } = dispatchProps;
  const isInputValidated = stateProps.form[form] && stateProps.form[form].values
     && stateProps.form[form].values.eid && !stateProps.form[form].syncErrors;
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    isInputValidated: !!isInputValidated,
    onClearInput: () => {
      dispatch(change(form, fieldMap.eSimEid.name, null));
      dispatch(reset(form));
    },
  };
};

// init component once, outside
const component = compose(
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  initForm(),
)(MyESimPushForm);

export const id = 'MyESimPushForm';

/**
 * @return {FormConfig}
 */
export const mapStateToFormConfig = (state, props) => {
  return {
    component,
    fieldMap: createFieldMap(props),
  };
};

export default mapStateToFormConfig;
