import QueueableRequest from './QueueableRequest';
import { ACCOUNT_CONSUMPTIONS_FLATRATES_ENDPOINT } from '../../config/api';
import { bindParamsToRoute } from '../../helpers/url';
import { blockContentBasedOnUserScope } from '../../helpers/scope';
import { SCOPE_MYDASHBOARD } from '../../helpers/constants';

export default class FlatratesRequest extends QueueableRequest {
  constructor(msisdn, options) {
    super(bindParamsToRoute(ACCOUNT_CONSUMPTIONS_FLATRATES_ENDPOINT, { msisdn }), options);
  }

  block(state) {
    return !blockContentBasedOnUserScope(state.user.scope, SCOPE_MYDASHBOARD);
  }
}
