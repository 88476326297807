import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import FormSection from '../form/FormSection';
import FormHeader from '../form/FormHeader';

class SubscriptionManagementForm extends PureComponent {
  render() {
    const { headline, copy } = this.props;

    return (
      <FormSection>
        <FormHeader headline={headline} copy={copy} />
      </FormSection>
    );
  }
}

SubscriptionManagementForm.propTypes = {
  headline: PropTypes.string,
  copy: PropTypes.string,
};

export default SubscriptionManagementForm;
