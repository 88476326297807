import { devWarning } from './meta';
import BlankModule from '../model/modules/BlankModule';
import * as moduleMap from '../config/moduleMap';
import { pascalCase } from './str';
import ModuleSwapperModule from '../model/modules/ModuleSwapperModule';
import ContentAccordionModule from '../model/modules/ContentAccordionModule';
import SkipException from '../model/errors/SkipException';

/**
 * Returns all entities of a page object
 * @param  {object} page Page object to get entities of
 * @return {array}       List of all entities of a page
 */
export const getEntitiesFromPage = (page) => {
  const { modules } = page;
  const flatEntities = modules
    .map(({ entities = [] }) => entities)
    // flatten arrays
    .reduce((entities, entity) => entities.concat(entity), []);

  // remove doubles
  return flatEntities.filter((entity, index) => {
    // find the first entity with the same id and type
    const firstIndex = flatEntities.findIndex(
      ({ eid, etype }) => eid === entity.eid && etype === entity.etype,
    );
    // only include the first entities in the array
    return firstIndex === index;
  });
};

/**
 * Returns the module class (not instance) by its etype. Putting the ModuleSwapper into the module
 * map separateley solves a circular dependency. It prevents the module swapper from rendering a
 * module swapper itself.
 *
 * @param {string} etype
 * @return {PageModule.constructor}
 */
export const getPageModuleClass = (etype) => {
  const extendedModuleMap = {
    ...moduleMap,
    ModuleSwapper: ModuleSwapperModule,
    ContentAccordion: ContentAccordionModule,
  };
  const Module = extendedModuleMap[pascalCase(etype)];

  if (!Module) {
    devWarning(`Module "${etype}" is not defined in module map and thus cannot be rendered`);
    return BlankModule;
  }

  return Module;
};

/**
 * Returns an instance of the module corresponding to the given etype.
 *
 * @param {string} etype
 * @param {object} data
 * @return {PageModule}
 */
export const getModuleInstance = async (etype, data) => {
  const ModuleClass = getPageModuleClass(pascalCase(etype));
  const instance = new ModuleClass(data);
  await instance.loadComponent();
  return instance;
};

/**
 * Returns a list of raw module objects based on the location and case's provided
 */
export const getLocationModules = (location, sitemap) => {
  const createLocationModule = (etype, params = {}) => (
    { eid: etype, etype, etitle: `LocationModule: ${etype}`, entities: [], params }
  );
  switch (location.pathname) {
    case sitemap.MyDashboardRoute.url:
      return [createLocationModule('MyPromoBannerRotation')];
    default:
      return [];
  }
};

export const instantiatePage = async (pages) => {
  const hydratedPages = {};
  const promises = Object.entries(pages).map(async ([key, page]) => {
    hydratedPages[key] = {
      ...page,
      modules:
        await Promise.all(page.modules.map(module => getModuleInstance(module.etype, module))),
    };
  });
  await Promise.all(promises);
  return hydratedPages;
};

export const moduleConfigsToComponents = async (configs, location, dispatch) => {
  const components = (await Promise.all(
    configs.map(async dataModule => {
      try {
        const module = await getModuleInstance(dataModule.etype, dataModule);
        await dispatch(module.prepare(location.pathname, configs));
        return module;
      } catch (e) {
        if (!getPageModuleClass(dataModule.etype).isCritical() || e instanceof SkipException) {
          devWarning(e);
          // silently skip this module as it is non-critical or deliberately skipped and thus
          // shouldn't cause the whole page (and all included page-components) to break
          return null;
        }
        throw e;
      }
    }))).filter(m => m !== null); // filter all broken components;

  return components;
};
