import PageModule from './PageModule';
import component from '../../containers/form/SuccessInfo';

class SuccessInfoModule extends PageModule {
  constructor(moduleData) {
    super(component, moduleData);
  }
}

export default SuccessInfoModule;

