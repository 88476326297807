import TopupStatusRequest from './TopupStatusRequest';
import BalanceRequest from './BalanceRequest';
import QueueableRequest from './QueueableRequest';
import { ACCOUNT_TOPUP_SINGLE_ENDPOINT } from '../../config/api';
import { bindParamsToRoute } from '../../helpers/url';
import { REQUEST_METHOD_POST } from '../../helpers/constants';

export default class SingleTopupRequest extends QueueableRequest {
  constructor(msisdn, amount, paypalOptions) {
    const url = bindParamsToRoute(ACCOUNT_TOPUP_SINGLE_ENDPOINT, { msisdn });
    super(url, {
      method: REQUEST_METHOD_POST,
      payload: {
        amount,
        ...paypalOptions,
      },
    });
  }

  /**
   * @inheritDoc
   */
  getSubsequentRequests({ user }) {
    return [
      new BalanceRequest(user.credentials.msisdn),
      new TopupStatusRequest(user.credentials.msisdn),
    ];
  }
}
