import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import FileSaver from 'file-saver';

import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';
import connectUI from '../../basics/ui/UIConnector';
import SvgLoader from '../../basics/media/MediaSvgLoader';
import Copy from '../../basics/text/TextCopy';
import Link from '../../basics/text/TextLink';
import Info from '../../basics/text/TextInfo';

import { formatDate } from '../../../helpers/date';
import { send } from '../../../actions/request/send';
import EECCOrderingDocumentRequest from '../../../model/requests/EECCOrderingDocumentRequest';

const ICON_MAIL = '/icons/icon-mail.svg';
const ICON_MAIL2 = '/icons/icon-mail02.svg';
const ICON_BILL_FIRST = '/icons/icon-bill-first.svg';
const ICON_BILL_LAST = '/icons/icon-bill-last.svg';
const ICON_BILL_MONTHLY = '/icons/icon-bill-monthly.svg';
const ICON_BILL = '/icons/icon-bill.svg';
const ICON_DELIVERY = '/icons/icon-delivery.svg';
const ICON_DELIVERY2 = '/icons/icon-delivery02.svg';
const ICON_VID = '/icons/icon-videoident.svg';
const ICON_VID_SUCCESS = '/icons/icon-videoident-success.svg';
const ICON_STORE = '/icons/icon-ident-filiale.svg';
const ICON_POST = '/icons/logo_post.svg';
const ICON_STATUS_SIM = '/icons/icon-status-sim.svg';
const ICON_WEBID = '/images/icons/webid_solutions_logo.svg';

export const IDENT_PROVIDER_DEUTSCHE_POST = 'Deutsche_Post';
export const IDENT_PROVIDER_WEBID = 'WebId';
export const IDENT_METHOD_STORE = 'store';
export const IDENT_METHOD_VIDEO = 'video';

class SuccessInfo extends PureComponent {
  constructor(...args) {
    super(...args);
    this.manageDownloadEECCPdf = this.manageDownloadEECCPdf.bind(this);
  }

  getCopyText() {
    const {
      type, contractId, ui, user, identMethod,
    } = this.props;
    switch (type) {
      case 'checkoutPrepaid':
      case 'checkoutESimOnly':
        if (!this.getIdentParamsProvided()) {
          return ui.txtIdentConfirmationCheckoutCopy.replace('{CONTRACT_ID}', contractId);
        }
        switch (identMethod) {
          case IDENT_METHOD_STORE:
            return ui.txtStoreidentConfirmationCheckoutPostCopy.replace('{CONTRACT_ID}', contractId);
          case IDENT_METHOD_VIDEO:
            return ui.txtVididentConfirmationCheckoutWebidCopy.replace('{CONTRACT_ID}', contractId);
          default:
            return ui.txtVididentConfirmationCheckoutCopy.replace('{CONTRACT_ID}', contractId);
        }
      case 'checkoutPrepaidSuccess':
        return ui.txtVididentCheckoutIdentificationSuccessCopy.replace('{CONTRACT_ID}', contractId);
      case 'checkoutPrepaidFailure':
        return ui.txtVididentCheckoutIdentificationFailureCopy.replace('{CONTRACT_ID}', contractId);
      case 'activationPrepaid':
        if (!this.getIdentParamsProvided()) {
          return ui.txtIdentConfirmationActivationCopy.replace('{CONTRACT_ID}', contractId);
        }
        switch (identMethod) {
          case IDENT_METHOD_STORE:
            return ui.txtStoreidentConfirmationActivationPostCopy.replace('{CONTRACT_ID}', contractId);
          case IDENT_METHOD_VIDEO:
            return ui.txtVididentConfirmationActivationWebidCopy.replace('{CONTRACT_ID}', contractId);
          default:
            return ui.txtVididentConfirmationActivationCopy.replace('{CONTRACT_ID}', contractId);
        }
      case 'activationPrepaidSuccess':
        return ui.txtVididentActivationIdentificationSuccessCopy.replace('{CONTRACT_ID}', contractId);
      case 'activationPrepaidFailure':
        return ui.txtVididentActivationIdentificationFailureCopy.replace('{CONTRACT_ID}', contractId);
      case 'canceled':
      case 'canceledWithout':
        return ui.txtMyCancellationFormSuccessCopy.replace('{DATE}', formatDate(user.contractData ? user.contractData.possibleCancellationDate : ''));
      default:
        return '';
    }
  }

  getSteps() {
    const {
      type, ui, identMethod, identProvider,
    } = this.props;
    switch (type) {
      case 'new':
        return [
          { icon: ICON_MAIL, label: ui.abfOrderLabelStep1 },
          { icon: ICON_DELIVERY, label: ui.abfOrderLabelStep2 },
          {
            icon: ICON_BILL_FIRST,
            label: ui.abfOrderLabelStep3,
            info: {
              isInline: true,
              headline: ui.abfBillingModeNewBusinessTitle,
              copy: ui.txtAbfBillingModeNewBusinessCopy,
            },
          },
        ];
      case 'exists':
        return [
          { icon: ICON_MAIL, label: ui.abfOrderLabelStep1 },
          { icon: ICON_DELIVERY, label: ui.abfOrderLabelStep2 },
          {
            icon: ICON_BILL_MONTHLY,
            label: ui.abfTariffSwapLabelStep3,
            info: {
              label: ui.abfTariffSwapHintStep3,
              headline: ui.abfBillingModeCustomerTitle,
              copy: ui.txtAbfBillingModeCustomerCopy,
            },
          },
        ];
      case 'contractRenewal':
        return [
          {
            icon: ICON_MAIL2,
            label: ui.myVvlContractRenewalSuccessStep1Label,
          },
          {
            icon: ICON_DELIVERY2,
            label: ui.myVvlContractRenewalSuccessStep2Label,
          },
          {
            icon: ICON_BILL,
            label: ui.myVvlContractRenewalSuccessStep3Label,
          },
        ];
      case 'canceled':
        return [
          { icon: ICON_MAIL, label: ui.abfOrderLabelStep1 },
          {
            icon: ICON_BILL_MONTHLY,
            label: ui.abfCancellationInvoiceLastMonthTitle,
            info: {
              isInline: true,
              headline: ui.abfCancellationInvoiceLastMonthTitle,
              copy: ui.abfCancellationInvoiceLastMonthCopy,
            },
          },
          {
            icon: ICON_BILL_LAST,
            label: ui.abfCancellationLabelStep3,
            info: {
              isInline: true,
              headline: ui.abfCancellationFinalInvoiceTitle,
              copy: ui.abfCancellationFinalInvoiceCopy,
            },
          },
        ];
      case 'canceledWithout':
        return [
          { icon: ICON_MAIL, label: ui.abfOrderLabelStep1 },
          {
            icon: ICON_BILL_MONTHLY,
            label: ui.abfCancellationInvoiceLastMonthTitle,
          },
        ];
      case 'checkoutPrepaid':
      case 'checkoutESimOnly':
        switch (identMethod) {
          case IDENT_METHOD_VIDEO:
            switch (identProvider) {
              case IDENT_PROVIDER_DEUTSCHE_POST:
                return [
                  {
                    icon: ICON_MAIL2,
                    label: ui.vididentOnboardingCheckoutPostStep1Label,
                  },
                  {
                    icon: ICON_VID,
                    label: ui.vididentOnboardingCheckoutPostStep2Label,
                    info: {
                      headline: ui.vididentDescriptionPostHeadline,
                      copy: ui.txtVididentDescriptionPostCopy,
                    },
                  },
                  {
                    icon: ICON_DELIVERY2,
                    label: ui.vididentOnboardingCheckoutPostStep3Label,
                  },
                ];
              case IDENT_PROVIDER_WEBID:
                return [
                  {
                    icon: ICON_MAIL2,
                    label: ui.vididentOnboardingCheckoutWebidStep1Label,
                  },
                  {
                    icon: ICON_VID,
                    label: ui.vididentOnboardingCheckoutWebidStep2Label,
                    info: {
                      headline: ui.vididentDescriptionWebidHeadline,
                      copy: ui.txtVididentDescriptionWebidCopy,
                    },
                  },
                  {
                    icon: ICON_DELIVERY2,
                    label: ui.vididentOnboardingCheckoutWebidStep3Label,
                  },
                ];
              default: return [];
            }
          case IDENT_METHOD_STORE:
            return [
              {
                icon: ICON_MAIL2,
                label: ui.storeidentOnboardingCheckoutPostStep1Label,
              },
              {
                icon: ICON_STORE,
                label: ui.storeidentOnboardingCheckoutPostStep2Label,
                info: {
                  headline: ui.storeidentDescriptionHeadline,
                  copy: ui.txtStoreidentDescriptionPostCopy,
                },
              },
              {
                icon: ICON_DELIVERY2,
                label: ui.storeidentOnboardingCheckoutPostStep3Label,
              },
            ];
          default: return [];
        }
      case 'activationPrepaid':
        switch (identMethod) {
          case IDENT_METHOD_VIDEO:
            switch (identProvider) {
              case IDENT_PROVIDER_DEUTSCHE_POST:
                return [
                  {
                    icon: ICON_MAIL2,
                    label: ui.vididentOnboardingActivationPostStep1Label,
                  },
                  {
                    icon: ICON_VID,
                    label: ui.vididentOnboardingActivationPostStep2Label,
                    info: {
                      headline: ui.vididentDescriptionPostHeadline,
                      copy: ui.txtVididentDescriptionPostCopy,
                    },
                  },
                  {
                    icon: ICON_STATUS_SIM,
                    label: ui.vididentOnboardingActivationPostStep3Label,
                  },
                ];
              case IDENT_PROVIDER_WEBID:
                return [
                  {
                    icon: ICON_MAIL2,
                    label: ui.vididentOnboardingActivationWebidStep1Label,
                  },
                  {
                    icon: ICON_VID,
                    label: ui.vididentOnboardingActivationWebidStep2Label,
                    info: {
                      headline: ui.vididentDescriptionWebidHeadline,
                      copy: ui.txtVididentDescriptionWebidCopy,
                    },
                  },
                  {
                    icon: ICON_STATUS_SIM,
                    label: ui.vididentOnboardingActivationWebidStep3Label,
                  },
                ];
              default: return [];
            }
          case IDENT_METHOD_STORE:
            return [
              {
                icon: ICON_MAIL2,
                label: ui.storeidentOnboardingActivationPostStep1Label,
              },
              {
                icon: ICON_STORE,
                label: ui.storeidentOnboardingActivationPostStep2Label,
                info: {
                  headline: ui.storeidentDescriptionHeadline,
                  copy: ui.txtStoreidentDescriptionPostCopy,
                },
              },
              {
                icon: ICON_STATUS_SIM,
                label: ui.storeidentOnboardingActivationPostStep3Label,
              },
            ];
          default: return [];
        }
      default:
        return [];
    }
  }

  getIdentParamsProvided() {
    const { identProvider, identMethod } = this.props;
    return (
      identProvider === IDENT_PROVIDER_DEUTSCHE_POST ||
      identProvider === IDENT_PROVIDER_WEBID) && (
      identMethod === IDENT_METHOD_STORE ||
      identMethod === IDENT_METHOD_VIDEO);
  }

  async manageDownloadEECCPdf(ev) {
    ev.preventDefault();
    const format = 'pdf';
    const pdfData = await
    this.props.dispatch(send(new EECCOrderingDocumentRequest(this.props.processId, { format })));

    if (pdfData.status === 200 && process.browser) {
      const blob = new window.Blob([pdfData.body.blob], { type: `application/${format};` }); //eslint-disable-line
      const fileName = 'Vertragszusammenfassung.pdf';
      FileSaver.saveAs(blob, `${fileName}.${format}`);
    }
  }

  renderStep(item, index) {
    return (
      <div className={suitcss({ descendantName: 'step' }, this)} key={index} data-index={index + 1}>
        <SvgLoader path={item.icon} />
        <div className={suitcss({ descendantName: 'content' }, this)}>
          <Copy embedded utilities={['colorGray50']} element="div">
            <span>{item.label}</span>
            {item.info && item.info.isInline &&
              <Info label={item.info.label} headline={item.info.headline} copy={item.info.copy} />
            }
          </Copy>
          {item.info && !item.info.isInline &&
            <Info label={item.info.label} headline={item.info.headline} copy={item.info.copy} />
          }
        </div>
      </div>
    );
  }

  renderStoreIdent() {
    const {
      ui, onConfirm, type, onCancel,
    } = this.props;
    return (
      <div className={suitcss({ descendantName: 'content' }, this)} >
        <div className={suitcss({ descendantName: 'info' }, this)} >
          <div className="u-alignCenter"><SvgLoader path={ICON_POST} /></div>
          <Copy utilities={['weightBold']} embedded>{ui.storeidentRequirementsPostHeadline}</Copy>
          <Copy element="div" embedded>
            <ul>
              <li>{ui.storeidentRequirementsPostBulletpoint1}</li>
              <li>{ui.storeidentRequirementsPostBulletpoint2}</li>
            </ul>
          </Copy>
          <Copy size="secondary" raw embedded>{ui.txtVididentRequirementsCopy}</Copy>
        </div>
        <div className={suitcss({ descendantName: 'links' }, this)} >
          <div className={suitcss({ descendantName: 'link' }, this)} >
            <Link
              onClick={onConfirm}
              element="button"
              asButton
              buttonFilled
            >
              {type === 'checkoutPrepaid' || type === 'checkoutESimOnly'
                ? ui.storeidentCheckoutPostStartButton
                : ui.storeidentActivationStartButton}
            </Link>
          </div>
          <div className={suitcss({ descendantName: 'link' }, this)} >
            <Link
              element="span"
              onClick={onCancel}
              withoutArrow
            >
              {ui.storeidentPostponePostIdentificationButton}
            </Link>
          </div>
        </div>
        <Copy size="secondary" utilities={['alignCenter', 'colorGray50']}>
          {ui.storeidentServicePostInformationCopy}
        </Copy>
      </div>
    );
  }

  renderVideoIdent() {
    const {
      ui, onConfirm, type, onCancel, identProvider,
    } = this.props;
    return (
      <div className={suitcss({ descendantName: 'content' }, this)} >
        <div className={suitcss({ descendantName: 'info' }, this)} >
          <div className="u-alignCenter">
            <SvgLoader path={identProvider === IDENT_PROVIDER_WEBID ? ICON_WEBID : ICON_POST} />
          </div>
          <Copy utilities={['weightBold']} embedded>{ui.vididentRequirementsHeadline}</Copy>
          <Copy element="div" embedded>
            <ul>
              <li>{ui.vididentRequirementsBulletpoint1}</li>
              <li>{ui.vididentRequirementsBulletpoint2}</li>
              <li>{ui.vididentRequirementsBulletpoint3}</li>
              <li>{ui.vididentRequirementsBulletpoint4}</li>
            </ul>
          </Copy>
          <Copy size="secondary" raw embedded>{ui.txtVididentRequirementsCopy}</Copy>
        </div>
        <div className={suitcss({ descendantName: 'links' }, this)} >
          <div className={suitcss({ descendantName: 'link' }, this)} >
            <Link
              onClick={onConfirm}
              element="button"
              asButton
              buttonFilled
            >
              {type === 'checkoutPrepaid' || type === 'checkoutESimOnly'
                ? ui.vididentCheckoutStartButton
                : ui.vididentActivationStartButton}
            </Link>
          </div>
          <div className={suitcss({ descendantName: 'link' }, this)} >
            <Link
              element="span"
              onClick={onCancel}
              withoutArrow
            >
              {ui.vididentPostponeIdentificationButton}
            </Link>
          </div>
        </div>
        <Copy size="secondary" utilities={['alignCenter', 'colorGray50']}>
          {identProvider === IDENT_PROVIDER_DEUTSCHE_POST
            ? ui.vididentServiceInformationPostCopy
            : ui.vididentServiceInformationCopy
          }
        </Copy>
      </div>
    );
  }

  renderProcessContent() {
    /** @todo reactivate ui for EECC link * */
    const { type, identMethod/* , ui */ } = this.props;
    const steps = this.getSteps();
    const isPrepaid = type === 'checkoutPrepaid' || type === 'activationPrepaid';
    const isESimOnly = type === 'checkoutESimOnly';
    const isCancellation = type === 'canceled' || type === 'canceledWithout';
    const identParamsProvided = this.getIdentParamsProvided();
    return (
      <div data-mf-replace="" className={suitcss({ modifiers: [type] }, this)} >
        {(isPrepaid || isCancellation || isESimOnly) && (
          // disable Link until EECC is official released
          /*
          <span>
          <Link
              className={suitcss({ descendantName: 'pdfLink' }, this)}
              asTextLink
              withoutArrow
              withoutStyle
              utilities={['alignCenter']}
              onClick={this.manageDownloadEECCPdf}
            >
              {ui.guiContractSummary}
            </Link> */
          <Copy utilities={['alignCenter']} raw>{this.getCopyText()}</Copy>
          // </span>
        )}
        {<div className={suitcss({ descendantName: 'diagram' }, this)} >
          <div className={suitcss({ descendantName: 'line' }, this)} />
          <div className={suitcss({ descendantName: 'steps' }, this)} >
            {steps.map(this.renderStep, this)}
          </div>
        </div>}
        {identParamsProvided && (isPrepaid || isESimOnly) && identMethod === IDENT_METHOD_VIDEO &&
        this.renderVideoIdent()}
        {identParamsProvided && (isPrepaid || isESimOnly) && identMethod === IDENT_METHOD_STORE &&
        this.renderStoreIdent()}
      </div>
    );
  }

  renderRedirectContent() {
    const { type } = this.props;
    return (
      <div data-mf-replace="" className={suitcss({ modifiers: [type] }, this)} >
        <div className={suitcss({ descendantName: 'icon', utilities: ['alignCenter'] }, this)}>
          <SvgLoader path={ICON_VID_SUCCESS} />
        </div>
        <Copy utilities={['alignCenter']} raw>{this.getCopyText()}</Copy>
      </div>
    );
  }

  render() {
    const { type } = this.props;
    const isRedirectContent = type === 'checkoutPrepaidSuccess' ||
      type === 'checkoutPrepaidFailure' ||
      type === 'activationPrepaidSuccess' ||
      type === 'activationPrepaidFailure';
    return isRedirectContent ?
      this.renderRedirectContent() :
      this.renderProcessContent();
  }
}

SuccessInfo.propTypes = {
  type: PropTypes.oneOf([
    'new',
    'exists',
    'canceled',
    'canceledWithout',
    'checkoutPrepaid',
    'checkoutESimOnly',
    'checkoutPrepaidSuccess',
    'checkoutPrepaidFailure',
    'activationPrepaid',
    'activationPrepaidSuccess',
    'activationPrepaidFailure',
    'contractRenewal',
  ]),
  identMethod: PropTypes.string,
  identProvider: PropTypes.string,
  contractId: PropTypes.string,
  user: PropTypes.object,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  ui: PropTypes.shape({
    abfOrderLabelStep1: PropTypes.string.isRequired,
    abfOrderLabelStep2: PropTypes.string.isRequired,
    abfOrderLabelStep3: PropTypes.string.isRequired,
    abfTariffSwapLabelStep3: PropTypes.string.isRequired,
    abfTariffSwapHintStep3: PropTypes.string.isRequired,
    abfCancellationInvoiceLastMonthTitle: PropTypes.string.isRequired,
    abfCancellationLabelStep3: PropTypes.string.isRequired,
    txtAbfBillingModeNewBusinessCopy: PropTypes.string.isRequired,
    txtAbfBillingModeCustomerCopy: PropTypes.string.isRequired,
    abfCancellationInvoiceLastMonthCopy: PropTypes.string.isRequired,
    abfBillingModeNewBusinessTitle: PropTypes.string.isRequired,
    abfBillingModeCustomerTitle: PropTypes.string.isRequired,
    vididentDescriptionHeadline: PropTypes.string.isRequired,
    txtVididentDescriptionCopy: PropTypes.string.isRequired,
    vididentProviderInfoHeadline: PropTypes.string.isRequired,
    txtVididentProviderInfoCopy: PropTypes.string.isRequired,
    vididentIdCardOptionsHeadline: PropTypes.string.isRequired,
    txtVididentIdCardOptionsCopy: PropTypes.string.isRequired,
    txtVididentConfirmationCheckoutCopy: PropTypes.string.isRequired,
    txtVididentRequirementsCopy: PropTypes.string.isRequired,
    txtVididentConfirmationActivationCopy: PropTypes.string.isRequired,
    vididentOnboardingCheckoutStep1Label: PropTypes.string.isRequired,
    vididentOnboardingCheckoutStep2Label: PropTypes.string.isRequired,
    vididentOnboardingCheckoutStep3Label: PropTypes.string.isRequired,
    vididentOnboardingActivationStep1Label: PropTypes.string.isRequired,
    vididentOnboardingActivationStep2Label: PropTypes.string.isRequired,
    vididentOnboardingActivationStep3Label: PropTypes.string.isRequired,
    txtVididentCheckoutIdentificationSuccessCopy: PropTypes.string.isRequired,
    txtVididentCheckoutIdentificationFailureCopy: PropTypes.string.isRequired,
    txtVididentActivationIdentificationSuccessCopy: PropTypes.string.isRequired,
    txtVididentActivationIdentificationFailureCopy: PropTypes.string.isRequired,
  }),
};

SuccessInfo.defaultProps = {
  type: 'new',
};

const mapStateToProps = (state) => {
  const { user } = state;
  return {
    processId: user.EECC.activation.processId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default compose(
  connectUI(),
  connect(mapStateToProps, mapDispatchToProps),
)(SuccessInfo);
