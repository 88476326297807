import { sendIfNecessary } from './send';
import {
  UI_COLLECTION_GLOBAL,
  UI_COLLECTION_MY_OTELO,
  UI_COLLECTION_SHARED_BUTTONS,
  UI_COLLECTION_TARIFFS,
  MENU_IDENTIFIER_MAIN,
  MENU_IDENTIFIER_ACCOUNT,
  MENU_IDENTIFIER_MY_OTELO,
  MENU_IDENTIFIER_FOOTER,
  MENU_IDENTIFIER_META,
  MENU_CMS_SEARCH_SUGGESTIONS,
  MENU_CMS_SEARCH_SUGGESTIONS_SERVICE,
  MENU_IDENTIFIER_MY_OTELO_PUA,
} from '../../helpers/constants';
import TextsRequest from '../../model/requests/TextsRequest';
import IdMapRequest from '../../model/requests/IdMapRequest';
import SitemapRequest from '../../model/requests/SitemapRequest';
import DialogsRequest from '../../model/requests/DialogsRequest';
import DownloadsRequest from '../../model/requests/DownloadsRequest';
import CalloutRequest from '../../model/requests/CalloutRequest';
import TeaserBarsRequest from '../../model/requests/TeaserBarsRequest';
import { fetchUiElements, fetchMenuElements } from './registry';
import { fetchPage } from '../page/website';

/**
 * fetches stuff that needs to be present right from the start,
 * directly after the store has been created before any component is rendered.
 */
export const fetchInitial = () => async (dispatch, getState) => {
  const actions = [
    sendIfNecessary(new SitemapRequest()),
    sendIfNecessary(new IdMapRequest()),
    sendIfNecessary(new DownloadsRequest()),
    sendIfNecessary(new DialogsRequest()),
    sendIfNecessary(new TextsRequest()),
    sendIfNecessary(new CalloutRequest()),
    sendIfNecessary(new TeaserBarsRequest()),
    fetchUiElements(
      [
        UI_COLLECTION_GLOBAL,
        UI_COLLECTION_MY_OTELO,
        UI_COLLECTION_SHARED_BUTTONS,
        UI_COLLECTION_TARIFFS,
      ],
      { isCritical: true },
    ),
    fetchMenuElements([
      MENU_IDENTIFIER_MAIN,
      MENU_IDENTIFIER_ACCOUNT,
      MENU_IDENTIFIER_MY_OTELO,
      MENU_IDENTIFIER_FOOTER,
      MENU_IDENTIFIER_META,
      MENU_CMS_SEARCH_SUGGESTIONS,
      MENU_CMS_SEARCH_SUGGESTIONS_SERVICE,
      MENU_IDENTIFIER_MY_OTELO_PUA,
    ]),
  ];

  await Promise.all(actions.map(dispatch));

  const { site } = getState();
  // preload login page at this point to ensure it is present when login fails and we immediately
  // need to transition from flyout form to the login page while also displaying the error
  dispatch(fetchPage(site.sitemap.MyLoginFormRoute.url));
};
