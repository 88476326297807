import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../../helpers/suitcss';
import Copy from '../../../basics/text/TextCopy';
import Headline from '../../../basics/text/TextHeadline';

const componentName = 'MyESimActivation';

function MyESimQRContent(props) {
  const { ui, qr, methodDescription, children } = props;
  const qrCode = `data:image/png;base64,${qr.quickResponseCode}`;
  return (
    <Fragment>
      <div
        className={suitcss(
          {
            componentName,
            descendantName: 'sectionTop',
            utilities: ['flex', 'sDirColumn', 'itemsCenter', 'paddingBottom', 'sPaddingBottomS'],
          },
        )}
      >
        <img
          className={suitcss({ utilities: ['sMarginAuto'] })}
          width={100}
          height={100}
          src={qrCode}
          alt="qr"
        />
        <div className={suitcss({ utilities: ['sMarginInit', 'marginLeftM', 'sPaddingTop'] })}>
          <Headline embedded size="s" utilities={['weightBold']}>
            {ui.myEsimActivationQrHeadline}
          </Headline>
          {methodDescription()}
        </div>
      </div>
      <div
        className={suitcss(
          { componentName, descendantName: 'sectionBottom', utilities: ['paddingTop', 'sPaddingTopS'] },
        )}
      >
        <Copy utilities={['weightBold']}>{ui.myEsimActivationConfirmationCode}</Copy>
        {children}
      </div>
    </Fragment>
  );
}

MyESimQRContent.propTypes = {
  ui: PropTypes.object.isRequired,
  qr: PropTypes.object.isRequired,
  methodDescription: PropTypes.func.isRequired,
  children: PropTypes.node,
};

export default MyESimQRContent;
