import React from 'react';
import PropTypes from 'prop-types';
import { capitalize } from '../../../helpers/str';
import { formatDate } from '../../../helpers/date';
import connectUI from '../ui/UIConnector';
import { subscriptionItemProps } from '../../../propTypes/account';
import {
  VO_STATE_PAUSED,
} from '../../../helpers/constants';

const getText = (type, state, date, ui) => {
  const stateCopy = state && ui[`${type}State${capitalize(state)}`];
  return stateCopy && stateCopy.replace('{DATE}', date ? formatDate(date) : '');
};

const StringSubscriptionState = ({ type, state, date, ui, element: Element }) => (
  <Element className={state === VO_STATE_PAUSED ? 'u-elementWarningBefore' : null}>
    {getText(type, state, date, ui)}
  </Element>
);

StringSubscriptionState.propTypes = {
  type: PropTypes.oneOf(['tariff', 'tariff_option']).isRequired,
  state: subscriptionItemProps.state,
  date: subscriptionItemProps.stateDate,
  element: PropTypes.oneOf(['span', 'p', 'div']),
  ui: PropTypes.shape({
    tariffStateActive: PropTypes.string,
    tariffStateIn_change: PropTypes.string,
    tariffStateIn_process: PropTypes.string,
    tariffStateTerminated: PropTypes.string,
    tariff_optionStateBooked: PropTypes.string,
    tariff_optionStateBooked_single: PropTypes.string,
    tariff_optionStateCanceled: PropTypes.string,
    tariff_optionStateIn_process: PropTypes.string,
    tariff_optionStatePaused: PropTypes.string,
  }),
};

StringSubscriptionState.defaultProps = {
  element: 'p',
};

const component = connectUI({
  // careful changing these ui keys! they are renamed for some magic that happens above.
  tariffStateActive: 'myTariffStateTariffActive',
  tariffStateIn_change: 'myTariffStateTariffChange',
  tariffStateIn_process: 'myTariffStateTariffOptionInProcess',
  tariffStateTerminated: 'myTariffStateTariffTerminated',
  tariff_optionStateBooked: 'myTariffoptionStateBooked',
  tariff_optionStateBooked_single: 'myTariffoptionStateBookedSingle',
  tariff_optionStateCanceled: 'myTariffoptionStateCanceled',
  tariff_optionStateIn_process: 'myTariffoptionStateInProcess',
  tariff_optionStatePaused: 'myTariffStateTariffOptionPaused',
})(StringSubscriptionState);

export default component;
