import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import GenericLandingPage from '../../components/compositions/header/LandingPage';
import GlobalSection from '../../components/basics/global/GlobalSection';

class LandingPage extends PureComponent {

  render() {
    const { headline, copy, hint, link, backgroundImage } = this.props;

    return (
      <GlobalSection
        image={backgroundImage}
        theme="dark"
        layout="contained"
        centered
      >
        <GenericLandingPage
          headline={headline}
          copy={copy}
          hint={hint}
          link={link}
        />
      </GlobalSection>
    );
  }
}

LandingPage.propTypes = {
  headline: PropTypes.string.isRequired,
  copy: PropTypes.string.isRequired,
  hint: PropTypes.string.isRequired,
  backgroundImage: PropTypes.object.isRequired,
  link: PropTypes.object.isRequired,
};

const mapStateToProps = (state, { params }) => ({
  headline: params.headline,
  copy: params.copy,
  hint: params.hint,
  backgroundImage: params.backgroundImage,
  link: params.link,
});

export default connect(mapStateToProps)(LandingPage);
