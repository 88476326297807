import React from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';

const componentName = 'TextMessage';
const TextMessage = ({ Component, children, level, standalone }) => (
  <Component
    className={suitcss({
      componentName,
      modifiers: [
        level,
        standalone && 'standalone',
      ],
    })}
  >
    {standalone &&
      <span className={suitcss({ componentName, descendantName: 'standaloneInner' })}>
        <span className={suitcss({ componentName, descendantName: 'standaloneText' })}>
          {children}
        </span>
      </span>
    }
    {!standalone && children}
  </Component>
);

TextMessage.propTypes = {
  Component: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  children: PropTypes.node.isRequired,
  level: PropTypes.oneOf(['info', 'error', 'success', 'warning']),
  standalone: PropTypes.bool,
};

TextMessage.defaultProps = {
  Component: 'p',
  level: 'info',
  standalone: false,
};

export default TextMessage;
