import initForm from '../form/FormInitializer';
import ContactFormComposition from '../../components/compositions/email/EmailRegistrationForm';
import { trackMyState } from '../../actions/tracking/event';
import { MARKET_POSTPAID, MYTRACK_EMAIL_REGISTRATION_SUBMIT_SUCCESS } from '../../helpers/constants';
import FormConfigProps from '../../model/form/FormConfigProps';
import dates from '../../helpers/dates';

const createFieldMap = (state, { ui }) => ({
  birthday: {
    name: 'birthdate',
    label: ui.guiFormDob,
    labelDay: ui.guiFormDobDay,
    labelMonth: ui.guiFormDobMonth,
    labelYear: ui.guiFormDobYear,
    optionsDays: dates.days,
    optionsMonths: dates.months,
    optionsYears: dates.getMarketYears(MARKET_POSTPAID),
    type: 'dobPostpaid',
    validation: {
      isRequired: true,
      pattern: 'date',
    },
  },
  email: {
    name: 'email',
    label: ui.guiFormEmail,
    hint: ui.guiFormEmailHint,
    type: 'email',
    validation: {
      isRequired: true,
      pattern: 'email',
    },
  },
});

// init component once, outside
const component = initForm()(ContactFormComposition);

export const id = 'data-email-registration';

const makeSubmit = () => async (values, dispatch) => {
  dispatch(trackMyState(MYTRACK_EMAIL_REGISTRATION_SUBMIT_SUCCESS));
};

/**
 * @return {FormConfig}
 */
export const mapStateToFormConfig = (state, props) => ({
  id,
  component,
  fieldMap: createFieldMap(state, props),
  makeSubmit,
  formConfigProps: new FormConfigProps({
    labelSubmit: state.ui.guiWordContinue,
  }),
});

export default mapStateToFormConfig;
