import React from 'react';

import Copy from '../components/basics/text/TextCopy';
import TextRaw from '../components/basics/text/TextRaw';
import Info from '../components/basics/text/TextInfo';

const infoComponentRegex = /{info.+?}/g;
const elementRegex = /<(\w+)[^>]*>[\s\S]*?<\/\1>/g;
/**
 * Extracts the info tags of a given text and turns them into info components.
 * These are described like {info "headline"="ui.headline" "copy"="ui.txtCopy"}
 * @param text The given text
 * @param ui to fetch the texts of the info elements
 * @returns {Array} The info components
 */
function extractInfoComponents(text, ui) {
  const matches = text.match(infoComponentRegex);
  if (ui && matches) {
    return matches.reduce((arr, match) => {
      try {
        const data = JSON.parse(match.replace('info', ''));
        if (data.headline || data.copy) {
          arr.push(
            <Info
              headline={ui[data.headline]}
              copy={ui[data.copy]}
            />,
          );
        }
      } catch (e) {
        console.warn('extractInfoComponents: Failed while extracting info component data', match);
        arr.push(null);
      }
      return arr;
    }, []);
  }

  return [];
}

/**
 * Takes a copy string and parses the infos out of it and turns the infos and copies
 * into react components
 * @param text The given text
 * @param ui To resolve the info texts
 * @returns {JSX.Element|unknown[]}
 */
export function textToCopyComponents(text, ui, element = 'p') {
  const elements = text.match(elementRegex);
  // extract info i markup / config and return a list of Info Components
  const infoComponents = extractInfoComponents(text, ui);
  // Split / Strip info i markup / config and return a list on strings
  const copyParagraphs = text.split(infoComponentRegex);
  // return a list: a raw default TextCopy component if no info components are found
  // otherwise a list of formated TextCopy components (TextRaw and Info Component)
  return !infoComponents.length && copyParagraphs.length === 1
    ? <Copy element={element} raw embedded>{text}</Copy>
    : elements.map((str, index) => (
      <Copy element={element} key={index} embedded={index === elements.length - 1}>
        <TextRaw stripPTag="p">
          {str.match(infoComponentRegex) ? `${str.replace(infoComponentRegex, '')}&nbsp;` : str}
        </TextRaw>
        {str.match(infoComponentRegex) && extractInfoComponents(str, ui)}
      </Copy>
    ));
}
