import AbstractError from './AbstractError';
import { POPUP_BLOCKED_ERROR } from '../../config/errorCodes';

/**
 * Thrown if the browser prevented a popup window to be opened.
 */
class PopupBlockedError extends AbstractError {
  constructor() {
    super(POPUP_BLOCKED_ERROR);
  }
}

export default PopupBlockedError;
