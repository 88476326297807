import QueueableRequest from './QueueableRequest';
import { bindParamsToRoute } from '../../helpers/url';
import { EECC_TARIFF_CHANGE_DOCUMENT } from '../../config/api';

export default class EECCActivationDocumentRequest extends QueueableRequest {
  constructor(msisdn, processId, options) {
    super(bindParamsToRoute(EECC_TARIFF_CHANGE_DOCUMENT, { msisdn, processId, format: options.format ? `.${options.format}` : '' }), {
      ...options,
      responseDataType: options.format
      && options.format !== QueueableRequest.RESPONSE_DATA_TYPE_JSON
        ? QueueableRequest.RESPONSE_DATA_TYPE_BINARY
        : QueueableRequest.RESPONSE_DATA_TYPE_JSON,
    });
  }
}
