import React from 'react';
import PropTypes from 'prop-types';

import TextHeadline from '../../basics/text/TextHeadline';
import TextCopy from '../../basics/text/TextCopy';
import Link from '../../basics/text/TextLink';
import suitcss from '../../../helpers/suitcss';
import MediaImage from '../../basics/media/MediaImage';
import MediaSvgLoader from '../../basics/media/MediaSvgLoader';

const renderSearchResultIcon = (icon) => {
  if (icon.includes('icons/')) {
    return <MediaSvgLoader path={icon.replace(/^(?:\/\/|[^/]+)*\//, '')} />;
  }
  return <MediaImage src={icon} alt="search result icon" />;
};

const componentName = 'SearchResultItem';
function SearchResultList(props) {
  //------------------------------------------------------------------------------------------------
  //  Render
  //------------------------------------------------------------------------------------------------

  const { title, description, url, icon } = props;
  return (
    <article
      className={suitcss({
        componentName,
      })}
    >
      <Link
        href={url}
        raw
        withoutArrow
        withoutStyle
        element={'a'}
      >
        <div
          className={suitcss({
            componentName,
            descendantName: 'icon',
          })}
        >
          {renderSearchResultIcon(icon)}
        </div>
        <div
          className={suitcss({
            componentName,
            descendantName: 'textcontent',
          })}
        >
          <TextHeadline element="h2" size="s">{title}</TextHeadline>
          <TextCopy
            className={suitcss({
              componentName,
              descendantName: 'description',
            })}
            raw
          >
            {description}
          </TextCopy>
          <TextCopy
            className={suitcss({
              componentName,
              descendantName: 'url',
            })}
            raw
          >
            {url}
          </TextCopy>
        </div>
      </Link>
    </article>
  );
}

//------------------------------------------------------------------------------------------------
//  Proptypes
//------------------------------------------------------------------------------------------------

SearchResultList.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  url: PropTypes.string,
  icon: PropTypes.string,
};

export default SearchResultList;
