/* global document */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import GlobalSection from '../../components/basics/global/GlobalSection';

const componentName = 'FreeCodeWidget';

class FreeCodeWidget extends Component {

  constructor() {
    super();
    this.id = Date.now().toString();
    this.injectCode = this.injectCode.bind(this);
    this.adjustIframe = this.adjustIframe.bind(this);
    this.handleMsg = this.handleMsg.bind(this);
    this.oldHeight = 0;
    this.olderHeight = 0;
    this.cssLoaded = false;
    this.jsLoaded = false;
  }

  componentDidMount() {
    // this is also known as cooperative multi-tasking and fixes a bug that occurs in Firefox
    // https://stackoverflow.com/questions/779379/why-is-settimeoutfn-0-sometimes-useful
    setImmediate(this.injectCode);
  }

  /**
   * The state is updated on new props. Rerender will not happen!
   */
  componentWillReceiveProps(props) {
    this.injectVariable('state', props.state);
  }

  /**
   * Always returns false to prevent a rerender of the iframe
   */
  shouldComponentUpdate() {
    return false;
  }

  /**
   * Searches the document for a specific string in a stylesheet href and
   * and returns the full href.
   * @param searchString The string to search for in the url
   * @returns {string} The full url or '' if nothing is found
   */
  getStyleSheetUrlFromDocumentStyleSheets(searchString) {
    // functional array methods can not be applied to stylesheets
    // includes can not be applied to href
    const styleSheets = document.styleSheets;
    for (let i = 0; i < styleSheets.length; i++) {
      if (styleSheets[i].href.indexOf(searchString) !== -1) {
        return styleSheets[i].href;
      }
    }
    return '';
  }


  injectCode() {
    const { params, state } = this.props;
    const { html, css, javascript, externalJsResources, externalCssResources } = params;
    this.window = this.frame.contentWindow;
    this.head = this.window.document.head;
    externalCssResources.push(this.getStyleSheetUrlFromDocumentStyleSheets('css/app'));
    this.numberOfExternalJsResources = externalJsResources.length;
    this.numberOfExternalCssResources = externalCssResources.length;

    this.injectCSS(css);
    externalCssResources.forEach(r => this.injectExternalCSSResource(r));
    this.injectHTML(html);
    this.injectVariable('state', state);
    this.injectVariable('iframeId', this.id);
    if (this.numberOfExternalJsResources) {
      externalJsResources.forEach(r => this.injectExternalJSResource(r));
    } else {
      this.injectJS(javascript);
    }
  }

  /**
   * Injects external resources into the iframe
   */
  injectExternalJSResource(link) {
    const script = document.createElement('script');
    script.src = link;
    script.onload = () => { this.checkIfAllJsResourcesLoaded(); };
    this.head.appendChild(script);
  }

  /**
   * Injects a variable into the iframe
   */
  injectVariable(name, value) {
    if (this.window) {
      this.window[name] = value;
    }
  }

  /**
   * Appends the given html to the iframe body
   */
  injectHTML(html) {
    this.frame.contentWindow.document.body.innerHTML = html;
  }

  /**
   * Creates a script tag and injects the given JavaScript code
   */
  injectJS(js) {
    const script = document.createElement('script');
    script.innerHTML = js;
    this.head.appendChild(script);
    this.jsLoaded = true;
    this.checkIfAllResourcesLoaded();
  }

  /**
   * Creates a link tag with css properties and inserts the given link to the sheet
   */
  injectExternalCSSResource(link) {
    const cssLink = document.createElement('link');
    cssLink.href = link;
    cssLink.rel = 'stylesheet';
    cssLink.type = 'text/css';
    cssLink.onload = () => { this.checkIfAllCssResourcesLoaded(); };
    this.head.appendChild(cssLink);
  }

  /**
   * Creates a style tag and injects the css into the iframe
   */
  injectCSS(css) {
    const style = document.createElement('style');
    style.innerHTML = css;
    this.head.appendChild(style);
  }

  /**
   * Is needed to ensure that all external js resources are completely loaded before the
   * js is injected and executed. Methods like window.onload can't be used because the
   * window is already loaded before the external resources get injected.
   */
  checkIfAllJsResourcesLoaded() {
    this.numberOfExternalJsResources--;
    if (this.numberOfExternalJsResources === 0) {
      this.injectJS(this.props.params.javascript);
    }
  }

  /**
   * Is needed to ensure that all external css resources are completely loaded before the
   * frame can be made responsive.
   */
  checkIfAllCssResourcesLoaded() {
    this.numberOfExternalCssResources--;
    if (this.numberOfExternalCssResources === 0) {
      this.cssLoaded = true;
      this.checkIfAllResourcesLoaded();
    }
  }

  /**
   * checks if the css and the js are loaded to ensure that the responsiveness can be
   * applied.
   */
  checkIfAllResourcesLoaded() {
    if (this.cssLoaded && this.jsLoaded) {
      this.makeResponsive();
    }
  }

  /**
   * Adjusts the iframe once and listens for resizes to adjust if necessary
   */
  makeResponsive() {
    this.adjustIframe();
    this.window.addEventListener('resize', this.adjustIframe);
    this.window.addEventListener('message', this.handleMsg);
  }

  /**
   * If we need to embedd an iframe as content.
   * This handler trigger the adjustIframe method again
   * after the inner iframe is completely loaded.
   *
   * use this template in the cms on the freecode widget:
   *
   * document.addEventListener("DOMContentLoaded", function(event) {
   *  if (window.parent && window.parent.parent){
   *    window.parent.parent.postMessage({type: "iframe_loaded"}, "*")
   *  }
   * });
   *
   *
   *
   */
  handleMsg(ev) {

    if (process.env.NODE_ENV !== 'production') {
      console.log('handle iframe message', ev);
    }

    switch (ev.data.type) {
      case 'iframe_ready':
      case 'iframe_loaded':
      case 'resize_iframe':
        this.adjustIframe();
        break;
      default:
        break;
    }
  }

  /**
   * Calculates the height of the iframe depending on the sizes of the child elements.
   * Then it searches itself in the document and sets the size.
   */
  adjustIframe() {
    let height = 0;
    const children = this.window.document.body.children;
    for (let i = 0; i < children.length; i++) {
      height += children[i].offsetHeight;
    }
    // prevents an endless loop that occurs on a resize event triggered by the resize
    if (this.olderHeight !== height) {
      document.getElementById(this.id).style.height = `${Math.max(height, this.oldHeight)}px`;
      this.olderHeight = this.oldHeight;
      this.oldHeight = height;
    }
  }

  render() {
    const { params, layoutSettings } = this.props;

    return (
      <GlobalSection
        theme={params.colorScheme || 'dark'}
        layoutSettings={layoutSettings}
        centered
      >
        <div className={componentName} id={this.id}>
          <iframe
            scrolling="no"
            ref={(f) => { this.frame = f; }}
          />
        </div>
      </GlobalSection>
    );
  }
}

FreeCodeWidget.propTypes = {
  params: PropTypes.shape({
    html: PropTypes.string,
    javascript: PropTypes.string,
    css: PropTypes.string,
    externalJsResources: PropTypes.array,
    externalCssResources: PropTypes.array,
    colorScheme: PropTypes.string,
  }).isRequired,
  layoutSettings: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
};

FreeCodeWidget.defaultProps = {
  params: {
    html: '',
    javascript: '',
    css: '',
    externalJsResources: [],
    externalCssResources: [],
  },
};

const mapStateToProps = (state) => ({ state });

export default connect(mapStateToProps)(FreeCodeWidget);
