import PageModule from './PageModule';
import SkipException from '../errors/SkipException';
import { MARKET_PREPAID } from '../../helpers/constants';
import { isTariffEntity } from '../../helpers/entity';

/**
 * The module offers the possibility to display 1 - X teaser.
 * @see https://confluence.db-n.com/x/S6zc
 */
class TeaserInfoModule extends PageModule {
  constructor(moduleData) {
    super(import('../../containers/teaser/TeaserInfo'), moduleData);
  }

  /**
   * @override
   */
  prepareBeforeMount() {
    return async (dispatch, getState) => {
      // <OP-293>
      // remove once purpose of OP-293 and OT-5934 are obsolete
      const { cart, site, routing } = getState();
      const tariff = cart.find(isTariffEntity) || {};

      const isCartPageShown =
        routing.locationBeforeTransitions.pathname === site.sitemap.ShoppingCartRoute.url;
      const isContractRenewal = site.contractRenewal.isInProgress;
      const isPrepaid = tariff.market === MARKET_PREPAID;
      const isBlacklisted = ['N45', 'N46'].includes(tariff.eid);
      if (isCartPageShown && (isContractRenewal || isPrepaid || isBlacklisted)) {
        // do not show/include the teaser
        throw new SkipException();
      }
      // </OP-293>

    };
  }
}

export default TeaserInfoModule;

