/**
 * This class allows us two channel access to a key to several objects in a list.
 *
 * @example
 * const objA = { key1: 'hello from objA' };
 * const objB = { key2: 'hello from objB' };
 * const objC = { key1: 'hello from objC' };
 * const proxyObj = new MultiObjectProxy([objA, objB, objC]);
 *
 * // will output 'hello from objA' as objA has the key and comes first in the list
 * console.log(proxyObj.key1)
 *
 * // will output 'hello from objC' as objC has the key
 * console.log(proxyObj.key2)
 *
 * // will output all keys of all objects
 * console.log(Object.keys(proxyObj))
 *
 */
class MultiObjectProxy {
  /**
   * @param {array<Object>} a list of objects to proxy
   * @return {Proxy} Note: this constructor does not return an instance of MultiObjectProxy.
   */
  constructor(objects = []) {

    // eslint-disable-next-line no-param-reassign
    objects = objects.filter(obj => obj != null && Object.keys(obj).length);

    if (typeof Proxy !== 'function') {
      // as a fallback, we simply merge the provided objects instead of proxying them
      return Object.assign({}, ...objects);
    }

    return new Proxy({}, {
      getOwnPropertyDescriptor: (target, key) => {
        for (let i = 0; i < objects.length; i++) {
          const descriptor = Object.getOwnPropertyDescriptor(objects[i], key);
          if (descriptor) {
            return descriptor;
          }
        }
      },
      get: (target, key) => {
        for (let i = 0; i < objects.length; i++) {
          if (objects[i][key]) {
            return objects[i][key];
          }
        }
      },
      has: (target, key) =>
        objects.some(obj => ({}.hasOwnProperty.call(obj, key))),
      ownKeys: () =>
        objects.reduce((keys, obj) => ([...keys, ...Object.keys(obj)]), [])
          .filter((key, index, arr) => arr.indexOf(key) === index),
    });
  }
}

export default MultiObjectProxy;
