import { PAGE_ERROR_ENDPOINT } from '../../config/api';
import { bindParamsToRoute } from '../../helpers/url';
import ApiError from '../errors/ApiError';
import AbstractPageRequest from './AbstractPageRequest';

export default class ErrorPageRequest extends AbstractPageRequest {
  /**
   * @param {ApiError} error - the error that resulted from the previous request
   * @param {PageRequest} previousRequest - the failed page request.
   */
  constructor(error, previousRequest) {
    const httpStatus = (error instanceof ApiError && error.fullResponse.status) || 500;
    const resource = bindParamsToRoute(PAGE_ERROR_ENDPOINT, { httpStatus });
    // the error page is stored under the pathname of the previous request.
    super(previousRequest.pathname, resource, {
      isCritical: true,
    });
  }
}
