import QueueableRequest from './QueueableRequest';
import { PROMOTION_ENDPOINT } from '../../config/api';
import { bindQueryParamsWithComplexData } from '../../helpers/url';


export default class PromotionsRequest extends QueueableRequest {
  /**
   * @param {object} context
   * @param {string} voId - the void or bid
   * @param {string} promoCode
   * @param {string} campaignToken
   * @param {object} options
   */
  constructor(context, voId, promoCode, campaignToken, options = {}) {
    const cleanContext = Array.isArray(context) ? context : [context];

    super(
      bindQueryParamsWithComplexData(PROMOTION_ENDPOINT, {
        contexts: cleanContext,
        void: voId,
        promoCode,
        campaignToken,
      }),
      { ...options, camelizeResponse: true },
    );
  }
}
