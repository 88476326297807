import { compose } from 'redux';
import { connect } from 'react-redux';
import { camelCase } from '../../helpers/str';
import NpsForm from '../../components/compositions/nps/NpsForm';
import initForm from '../form/FormInitializer';
import FormConfigProps from '../../model/form/FormConfigProps';

const SLIDER_OPTIONS = [...new Array(11)].map((value, index) => ({
  value: index,
  label: `${index}`,
}));

const getFieldTypeProps = (item, ui) => {
  const props = {
    type: item.type,
    name: item.name,
    label: item.label,
  };

  if (item.type === 'bool') {
    props.options = [
      { label: item.hintReject, value: item.hintReject },
      { label: item.hintApprove, value: item.hintApprove },
    ];
    props.rejectLabel = item.rejectLabel;
    props.rejectChoices = item.rejectChoices ?
      item.rejectChoices.map(value => ({ label: value, value })) :
      null;
    if (props.rejectChoices) {
      props.rejectChoices.push({ label: ui.guiWordOther, value: ui.guiWordOther });
    }
  }

  if (item.type === 'scale') {
    props.options = SLIDER_OPTIONS;
    props.labelMin = item.hintReject;
    props.labelMax = item.hintApprove;
  }

  return props;
};

const createFieldMap = ({ params, ui }) => params.fields.reduce((results, item) => ({
  ...results,
  [camelCase(item.name)]: getFieldTypeProps(item, ui),
}), {});


const mapStateToProps = (state, { fieldMap }) => ({
  initialValues: Object.values(fieldMap).reduce((results, item) => {
    if (item.type === 'scale') {
      return {
        ...results,
        [item.name]: 5,
      };
    }
    return results;
  }, {}),
});

const component = compose(
  connect(mapStateToProps),
  initForm(),
)(NpsForm);

export const id = 'nps';

/**
 * @return {FormConfig}
 */
export const mapStateToFormConfig = (state, props) => ({
  component,
  fieldMap: createFieldMap(props),
  formConfigProps: new FormConfigProps({
    labelSubmit: state.ui.guiFormSubmit,
  }),
});

export default mapStateToFormConfig;
