import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, formValueSelector, change } from 'redux-form';

import suitcss from '../../../helpers/suitcss';
import FieldRadio from './FieldRadio';

const componentName = 'FieldAccordion';
class FieldAccordion extends PureComponent {

  componentDidUpdate(prevProps) {
    if (prevProps.isSelected && !this.props.isSelected) {
      this.resetDependantFields();
    }
  }

  resetDependantFields() {
    const { formIdentifier, dependantFieldNames, dispatch } = this.props;
    if (dependantFieldNames) {
      dependantFieldNames.forEach(id => {
        dispatch(change(formIdentifier, id, null));
      });
    }
  }

  render() {
    const {
      name,
      label,
      value,
      isSelected,
      children,
      inverted,
      utilities,
      withToolTip,
      validation,
    } = this.props;
    return (
      <div className={suitcss({ componentName, states: [isSelected && 'open'], utilities })} >
        <div className={suitcss({ componentName, descendantName: 'input' })} >
          <Field
            name={name}
            label={label}
            inputValue={value}
            component={FieldRadio}
            checked={isSelected}
            inverted={inverted}
            withToolTip={withToolTip}
            validation={validation}
            raw
          />
        </div>
        {children && isSelected && (
          <div className={suitcss({ componentName, descendantName: 'content' })} >
            <div className={suitcss({ componentName, descendantName: 'inner' })} >
              {children}
            </div>
          </div>
        )}
      </div>
    );
  }

}

FieldAccordion.propTypes = {
  formIdentifier: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  inverted: PropTypes.bool,
  dependantFieldNames: PropTypes.array,
  utilities: PropTypes.array,
  withToolTip: PropTypes.bool,
  dispatch: PropTypes.func,
  validation: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array,
  ]),
};

function mapStateToProps(state, ownProps) {
  const { name, value, formIdentifier } = ownProps;
  const selector = formValueSelector(formIdentifier);
  const selectedValue = selector(state, name);
  const isSelected = value === selectedValue;
  return {
    isSelected,
  };
}

const mapDispatchToProps = dispatch => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(FieldAccordion);
