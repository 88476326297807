import { PAGE_WEBSITE_ENDPOINT } from '../../config/api';
import { bindParamsToRoute } from '../../helpers/url';
import AbstractPageRequest from './AbstractPageRequest';

export default class PageRequest extends AbstractPageRequest {
  constructor(url) {
    const resource = bindParamsToRoute(PAGE_WEBSITE_ENDPOINT, { fragment: url.slice(1) });
    const pathname = url.split('?')[0];
    super(pathname, resource);
  }
}
