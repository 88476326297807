import QueueableRequest from './QueueableRequest';
import { ACCOUNT_PROMOTIONS_ENDPOINT } from '../../config/api';
import {
  bindParamsToRoute,
  bindQueryParamsWithComplexData,
} from '../../helpers/url';
import BannerRotationRequest from './BannerRotationRequest';
import { getMsisdn } from '../../helpers/accessors';
import { blockContentBasedOnUserScope } from '../../helpers/scope';
import { SCOPE_MYDASHBOARD } from '../../helpers/constants';

/**
 * Used to book or fetch user-specific promotions.
 */
export default class MyPromotionsRequest extends QueueableRequest {
  constructor(msisdn, context, promoCode, options) {
    const cleanContext = Array.isArray(context) ? context : [context];
    let url = bindParamsToRoute(ACCOUNT_PROMOTIONS_ENDPOINT, { msisdn });
    url = bindQueryParamsWithComplexData(url, {
      context: cleanContext,
      promoCode,
    });
    super(url, options);
  }

  /**
   * @inheritDoc
   */
  getSubsequentRequests(state) {
    const msisdn = getMsisdn(state);
    return [
      new BannerRotationRequest(msisdn),
      new MyPromotionsRequest(msisdn),
    ];
  }

  block(state) {
    return !blockContentBasedOnUserScope(state.user.scope, SCOPE_MYDASHBOARD);
  }
}
