import React from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';

import { shape as hardwareShape, list as hardwareListShape } from '../../../propTypes/hardware';

import HardwareItemFull from './HardwareItemFull';
import ContentSocialSharing from '../../../containers/content/ContentSocialSharing';

// @todo is component necessary? selectedHardware is figured out in HWItemFull + markup is minimal
function HardwareSelection(props) {
  const selectedHardware = props.selectedHardware || props.hardwareList[0];
  return (
    <div
      className={suitcss({
        componentName: 'HardwareSelection',
      })}
    >
      <div
        className={suitcss({
          componentName: 'HardwareSelection',
          descendantName: 'item',
        })}
      >
        <HardwareItemFull
          selectedHardware={selectedHardware}
          {...props}
        />
      </div>
      <div
        className={suitcss({
          componentName: 'HardwareSelection',
          descendantName: 'socialLinks',
          utilities: ['sHidden'],
        })}
      >
        <ContentSocialSharing
          location={props.location}
          modifiers={['vertical']}
        />
      </div>
    </div>
  );
}

HardwareSelection.propTypes = {
  hardwareList: hardwareListShape.isRequired,
  selectedHardware: hardwareShape.isRequired,
  location: PropTypes.object,
  reviewActivated: PropTypes.bool,
};

export default HardwareSelection;
