/* global document, window */
import React, { Component } from 'react';
import oneLineTrim from 'common-tags/lib/oneLineTrim';
import Script from 'react-load-script';

/**
 * Aklamio is a referral marketing service, that rewards
 * recommending products to friends.
 *
 * Component listens for updates on the page and will load or update the aklamio
 * scripts when new elements have to be initialized
 * @todo  add error strategy
 */
class ServiceAklamio extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      requested: false,
      received: false,
    };
    this.onScriptLoad = this.onScriptLoad.bind(this);
    this.onScriptError = this.onScriptError.bind(this);
    this.initializedWidgets = new WeakSet();
  }

  componentDidMount() {
    this.initializeAklamio();
  }

  componentDidUpdate() {
    this.initializeAklamio();
  }

  onScriptLoad() {
    this.setState({ received: true, error: false });
  }

  onScriptError() {
    this.setState({ received: false, error: true });
  }

  initializeAklamio() {
    // @todo check whether this is an performance issue
    const referralElements = ServiceAklamio.getReferralElements();
    // do not do anything when there are no referrer elements on the page
    if (!referralElements.length) {
      return;
    }

    // check whether all elements are initialized jet
    // if they are do not go on
    const allInitialized = referralElements.every(
      el => this.initializedWidgets.has(el),
    );
    if (allInitialized) {
      return;
    }

    // on this point the elements aren't really initialized, but we assume
    // that any further events will trigger the initialization so we already
    // update thie list
    referralElements.forEach(el => this.initializedWidgets.add(el));

    if (!this.state.requested) {
      // toggling requested state will be the trigger to load the scripts
      this.setState({ requested: true });
      return;
    }

    if (this.state.received) {
      // when the script was already loaded we need to initialize new widgets
      // that might have appeared on the page
      window.aklamio && window.aklamio.referral_widget(); // eslint-disable-line
    }
  }

  render() {
    // when the component state change to requested, render the component an thus
    // load the script
    if (this.state.requested) {
      return (
        <Script
          url={oneLineTrim`
            ${document.location.protocol}//
            b.aklamio.com/widget-integration.js?
            ${(new Date()).getTime()}
          `}
          onError={this.onScriptError}
          onLoad={this.onScriptLoad}
        />
      );
    }
    return null;
  }
}

ServiceAklamio.getReferralElements = () => [...document.querySelectorAll('.aklamio-referral-widget')];

export default ServiceAklamio;
