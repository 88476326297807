import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';

class TabsWrapper extends PureComponent {

  constructor(...args) {
    super(...args);
    this.state = {
      selected: this.props.selected,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(index) {
    this.setState({
      selected: index,
    });
  }

  renderLabels(child, index) {
    const { inverted } = this.props;
    return (
      <li
        className={suitcss({
          descendantName: 'label',
          states: [
            (this.state.selected === index) && 'active',
          ],
        }, this)}
        key={index}
      >
        <button
          className={suitcss({
            descendantName: 'labelButton',
            utilities: [inverted && 'colorInverted'],
          }, this)}
          onClick={() => {
            this.handleClick(index);
          }}
        >
          {child.props.label}
        </button>
      </li>
    );
  }

  renderTitles() {
    return (
      <ul
        className={suitcss({
          descendantName: 'labels',
        }, this)}
      >
        {this.props.children.map(this.renderLabels, this)}
      </ul>
    );
  }
  renderContent() {
    return (
      <div
        className={suitcss({
          descendantName: 'content',
        }, this)}
      >
        {this.props.children[this.state.selected]}
      </div>
    );
  }
  render() {
    const { inverted, centered, withoutBorder } = this.props;
    return (
      <div className={suitcss({
        modifiers: [inverted && 'inverted', centered && 'centered', withoutBorder && 'withoutBorder'],
      }, this)}
      >
        {this.renderTitles()}
        {this.renderContent()}
      </div>
    );
  }

}

TabsWrapper.propTypes = {
  inverted: PropTypes.bool,
  centered: PropTypes.bool,
  withoutBorder: PropTypes.bool,
  selected: PropTypes.number,
  children: PropTypes.arrayOf(
    PropTypes.element,
  ).isRequired,
};
TabsWrapper.defaultProps = {
  selected: 0,
  centered: true,
};

export default TabsWrapper;

