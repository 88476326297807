import PageModule from './PageModule';

/**
 * Build the "stage".
 * @see https://confluence.db-n.com/x/-Lfc
 */
class HeaderStageModule extends PageModule {
  constructor(moduleData) {
    super(import(/* webpackChunkName:"HeaderStage" */ '../../containers/header/HeaderStage'), moduleData);
  }
}

export default HeaderStageModule;
