/* global navigator, window, document, performance */
import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toggleTouchScreenInput } from '../../../actions/site/viewport';

const EVENT_ACTION_TOUCH = 'touchstart';
const EVENT_ACTION_MOUSE = 'mousemove';
const EVENT_ACTION_POINTER = 'pointerdown';

class ServiceScreenInputDetection extends PureComponent {

  constructor(props, context) {
    super(props, context);
    this.isTouchSupported = false;
    this.tick = 0;
    this.touchHandler = this.touchHandler.bind(this);
    this.mouseHandler = this.mouseHandler.bind(this);
  }

  componentDidMount() {
    this.isTouchSupported = 'ontouchstart' in window;
    this.toggleHandlers(EVENT_ACTION_TOUCH, true);
  }

  componentDidUpdate() {
    if (this.props.isUsingTouchScreen) {
      this.toggleHandlers(EVENT_ACTION_TOUCH, false);
      this.toggleHandlers(EVENT_ACTION_MOUSE, true);
    } else {
      this.toggleHandlers(EVENT_ACTION_MOUSE, false);
      this.toggleHandlers(EVENT_ACTION_TOUCH, true);
    }
  }

  componentWillUnmount() {
    if (this.props.isUsingTouchScreen) {
      this.toggleHandlers(EVENT_ACTION_MOUSE, false);
    } else {
      this.toggleHandlers(EVENT_ACTION_TOUCH, true);
    }
  }

  toggleHandlers(type, isAdding = false) {
    const action = isAdding ? 'addEventListener' : 'removeEventListener';
    switch (type) {
      case EVENT_ACTION_TOUCH: {
        if (this.isTouchSupported) {
          document[action](type, this.touchHandler, { passive: true });
        } else if (navigator.maxTouchPoints || navigator.msMaxTouchPoints) {
          document[action](EVENT_ACTION_POINTER, this.touchHandler);
        }
        break;
      }
      case EVENT_ACTION_MOUSE:
        document[action](type, this.mouseHandler);
        break;
      default:
    }
  }

  mouseHandler() {
    const now = performance.now();
    if (now - this.tick < 20) {
      this.props.onChangeInput(false);
    }
    this.tick = now;
  }

  touchHandler() {
    this.props.onChangeInput(true);
  }

  render() {
    return null;
  }
}

ServiceScreenInputDetection.propTypes = {
  isUsingTouchScreen: PropTypes.bool,
  onChangeInput: PropTypes.func.isRequired,
};

const mapStateToProps = ({ site }) => ({
  isUsingTouchScreen: site.isUsingTouchScreen,
});

const mapDispatchToProps = (dispatch) => ({
  onChangeInput: (isUsingTouchScreen) => dispatch(toggleTouchScreenInput(isUsingTouchScreen)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ServiceScreenInputDetection);
