import React from 'react';
import PropTypes from 'prop-types';
import { ID_MY_OFFERS_OPTIONS } from '../../../../helpers/constants';
import { scrollToHash } from '../../../../helpers/navigation';
import { subscriptionItemShape } from '../../../../propTypes/account';
import suitcss from '../../../../helpers/suitcss';
import AccordionSection from '../../../basics/accordion/AccordionSection';
import Copy from '../../../basics/text/TextCopy';
import Link from '../../../basics/text/TextLink';
import DashboardSummaryItem from './DashboardSummaryItem';

const componentName = 'DashboardSummary';
const DashboardSummarySection = (props) => {
  const {
    label,
    items,
    emptyText,
    emptyCta,
    type,
    asAccordion,
    bookedTariff,
    withLinkToOptions,
    isAccordionOpen,
  } = props;
  const Element = asAccordion ? AccordionSection : 'div';
  const elementProps = asAccordion
    ? {
      label,
      highlighted: !!type,
      open: isAccordionOpen,
    }
    : {};
  return (
    <div className={suitcss({ componentName, descendantName: 'section' })}>
      <Element
        className={suitcss({
          utilities: asAccordion ? [] : ['paddingHXS', 'paddingBottomXS'],
        })}
        {...elementProps}
      >
        {!asAccordion &&
          <Copy embedded utilities={['weightBold', 'paddingVXS']}>
            {label}
          </Copy>
        }
        <div className={suitcss({ componentName, descendantName: 'items' })}>
          {items.length
            ? items.map((item, index) => (
              <DashboardSummaryItem
                key={index}
                item={item}
                type={type}
                isBaseTariff={item.id === bookedTariff.id}
              />
            ))
            : (emptyText &&
              <div>
                <Copy size="secondary" embedded>{emptyText}</Copy>
                {withLinkToOptions &&
                  <Link
                    size="secondary"
                    utilities={['weightBold', 'marginTopS']}
                    onClick={() => scrollToHash(`#${ID_MY_OFFERS_OPTIONS}`, {
                      contentAware: false,
                      offset: 0,
                      delay: 0,
                    })}
                    element="button"
                  >
                    {emptyCta}
                  </Link>
                }
              </div>
            )
          }
        </div>
      </Element>
    </div>
  );
};

DashboardSummarySection.propTypes = {
  items: PropTypes.arrayOf(subscriptionItemShape),
  label: PropTypes.string,
  emptyText: PropTypes.string,
  emptyCta: PropTypes.string,
  type: PropTypes.string,
  asAccordion: PropTypes.bool,
  withLinkToOptions: PropTypes.bool,
  bookedTariff: PropTypes.object,
  isAccordionOpen: PropTypes.bool,
};

DashboardSummarySection.defaultProps = {
  asAccordion: true,
  isAccordionOpen: false,
};

export default DashboardSummarySection;
