import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { isAccountUrl } from '../../config/api';
import UIAlertBar from '../../components/basics/ui/UIAlertBar';
import { formatDate } from '../../helpers/date';
import {
  MNP_STATUS_PRS,
  MNP_STATUS_CPN,
  MNP_STATUS_PNS,
  MNP_STATUS_CRE,
  MNP_STATUS_EIN,
  MNP_STATUS_NSE,
} from '../../actions/dialog/myBelatedMnpActions';

import {
  CONTRACT_CANCELED,
  CONTRACT_DISMISSED,
  CONTRACT_TERMINATED,
} from '../../helpers/constants';

function GlobalInfoBar(props) {
  const { items } = props;
  if (!items) { return null; }
  return (
    <Fragment>
      {items.map((item, key) => (
        <UIAlertBar theme={item.theme} content={item.content} key={`infoBar${key}`} />
      ))}
    </Fragment>
  );
}

GlobalInfoBar.propTypes = {
  items: PropTypes.array,
};

const getBelatedMnpStatusCopy = (status, ui) => {
  switch (status) {
    case MNP_STATUS_CRE:
      return ui.mnpStatusConfirmedCopyShort;
    case MNP_STATUS_PNS:
      return ui.mnpStatusPendingCopyShort;
    case MNP_STATUS_NSE:
      return ui.mnpStatusPendingNegotiationSentCopyShort;
    case MNP_STATUS_EIN:
      return ui.mnpStatusPendingNegotiationErrorCopyShort;
    case MNP_STATUS_CPN:
      return ui.mnpStatusPendingNegotiationSuccessCopyShort;
    case MNP_STATUS_PRS:
      return ui.mnpStatusPendingPortingRequestSentCopyShort;
    default:
      return ui.mnpStatusInProgessCopyShort;
  }
};

const mapStateToProps = ({ ui, user, routing }) => {
  const { locationBeforeTransitions } = routing;

  if (!locationBeforeTransitions || !isAccountUrl(locationBeforeTransitions)) {
    return {};
  }

  const { customerData, contractData } = user;

  const belatedMnp = contractData && contractData.belatedMnp;
  const belatedMnpState = belatedMnp && belatedMnp.state;
  const isBelatedMnp = !!belatedMnpState && [
    MNP_STATUS_PNS,
    MNP_STATUS_PRS,
    MNP_STATUS_CPN,
    MNP_STATUS_CRE,
    MNP_STATUS_EIN,
    MNP_STATUS_NSE,
  ].some((element) => element === belatedMnpState);
  const belatedMnpStateCopy = isBelatedMnp && getBelatedMnpStatusCopy(belatedMnpState, ui)
    .replace('{DATE}', belatedMnp.portingDate ? formatDate(belatedMnp.portingDate) : '');

  const isSimCardBlocked = contractData && contractData.simCard && contractData.simCard.passivated;

  const isContractEnding = customerData && [
    CONTRACT_TERMINATED,
    CONTRACT_DISMISSED,
    CONTRACT_CANCELED,
  ].some((elem) => elem === customerData.contractState);

  const dunningLevel = customerData && customerData.dunningLevel;
  const isDunningLevel = dunningLevel && dunningLevel !== 3 && dunningLevel !== 4;
  const dunningLevelCopy = isDunningLevel ? ui[`contractStatusDunning${dunningLevel}Copy`] : null;
  const items = [
    isContractEnding && {
      theme: 'primary',
      content: ui.contractStatusTerminatedCopy,
      isValid: isContractEnding,
    },
    dunningLevelCopy && !isContractEnding && {
      theme: 'primary',
      content: dunningLevelCopy,
    },
    isSimCardBlocked && !isContractEnding && {
      theme: 'primary',
      content: ui.myProfileSimPasBar,
    },
    isBelatedMnp && {
      theme: 'secondary',
      content: belatedMnpStateCopy,
    },
  ].filter(item => !!item);

  return {
    items,
  };
};

export default connect(mapStateToProps)(GlobalInfoBar);
