import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import oneline from 'common-tags/lib/oneLine';
import * as optionsSelect
  from '../../../../containers/account/passwordReset/formConfigOptionsSelect';
import suitcss from '../../../../helpers/suitcss';
import connectUI from '../../../basics/ui/UIConnector';
import FieldInput from '../../../basics/form/FieldInput';
import FormFooter from '../../form/FormFooter';
import {
  MYTRACK_LOGIN_RESEND_SMS_TOKEN,
  MYTRACK_LOGIN_CONFIRM_SMS_TOKEN,
} from '../../../../helpers/constants';
import PasswordResetCooldownButton from './PasswordResetCooldownButton';
import PasswordResetCooldownCopy from './PasswordResetCooldownCopy';
import {
  getEmailTimestamp,
  getSmsTimestamp,
} from '../../../../containers/account/passwordReset/helpers';
import * as date from '../../../../helpers/date';
import FormHeader from '../../form/FormHeader';
import FormSection from '../../form/FormSection';


const componentName = 'PasswordResetFormSms';

function PasswordResetFormSms(props) {
  const { fieldMap, ui, formValues, onStepClick } = props;
  // const isBirthdayRequired = formValues[FORM_FIELD_PW_RESET_BIRTHDAY_REQUIRED];
  const isSmsOptionSelected = formValues[fieldMap.selectedOption.name] === 'sms';
  const cooldown = isSmsOptionSelected
    ? getSmsTimestamp(fieldMap, formValues) : getEmailTimestamp(fieldMap, formValues);
  const validDate = new Date(cooldown);
  const msisdn = formValues[fieldMap.msisdn.name];
  const copyText = oneline`
    <p>${ui.myPasswordLostSmsSent.replace('{MSISDN}', `<strong>${msisdn}</strong>`)} </p>
    <p class="u-marginTopS">${ui.myPasswordLostSmsValidDate
    .replace('{DATE}', `<strong>${date.formatDate(validDate)}</strong>`)
    .replace('{TIME}', `<strong>${date.formatTime(validDate)}</strong>`)}</p>
  `;

  const extraButton = (
    <PasswordResetCooldownButton
      onClick={() => { onStepClick(optionsSelect.id); }}
      label={ui.guiWordBack}
      timestamp={cooldown}
      data={{ mytracking: MYTRACK_LOGIN_RESEND_SMS_TOKEN }}
    />
  );
  return (
    <div className={suitcss({ componentName })}>
      <FormSection asGrid>
        <FormHeader headline={ui.myPasswordLostSmsHeadline} copy={copyText} />
        <Field {...fieldMap.smsCode} component={FieldInput} utilities={['col6']} withToolTip />
        <div className={suitcss({
          descendantName: 'cooldown',
          utilities: ['col12'],
        }, this)}
        >
          <PasswordResetCooldownCopy
            timestamp={cooldown}
            copy={ui.myPasswordLostSmsCooldown}
          />
        </div>
      </FormSection>
      <FormFooter
        {...props}
        additionalButton={extraButton}
        // @todo refactor: should go into trackStep() action
        dataSubmit={{ mytracking: MYTRACK_LOGIN_CONFIRM_SMS_TOKEN }}
        labelSubmit={ui.myPasswordLostSmsSubmit}
        onCancel={null}
      />
    </div>
  );
}

PasswordResetFormSms.propTypes = {
  onStepClick: PropTypes.func.isRequired,
  formValues: PropTypes.object.isRequired,
  // form logic
  fieldMap: PropTypes.shape({
  }),

  ui: PropTypes.shape({
    myPasswordLostSmsCodeLabel: PropTypes.string.isRequired,
    myPasswordLostSmsValidDate: PropTypes.string.isRequired,
    myPasswordLostSmsSent: PropTypes.string.isRequired,
    myPasswordLostSmsSubmit: PropTypes.string.isRequired,
    myPasswordLostSmsHeadline: PropTypes.string.isRequired,
    myPasswordLostSmsCooldown: PropTypes.string.isRequired,
    myPasswordLostSmsResend: PropTypes.string.isRequired,
  }),
};

export default connectUI()(PasswordResetFormSms);
