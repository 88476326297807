import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import suitcss from '../../../../helpers/suitcss';
import FieldCheckbox from '../../../basics/form/FieldCheckbox';
import Header from './CancellationHeader';
import FormSection from '../../form/FormSection';
import FormHeader from '../../form/FormHeader';
import TextCopy from '../../../basics/text/TextCopy';

class FormStepFutureContact extends PureComponent {
  render() {
    const { fieldMap, adConsent, ui } = this.props;
    return (
      <div className={suitcss({}, this)}>
        <Header
          headline={ui.myCancellationFormTitle}
          imageLink={ui.myCancellationFormImageJson.replace('%gui.baseurl%', '')}
        />
        <FormSection asGrid>
          <FormHeader headline={ui.myCancellationFormContactHeadline} />
          {!adConsent.adConsent &&
            <Field {...fieldMap.adConsent} component={FieldCheckbox} utilities={['col12', 'mlCol8']} />
          }
          {!adConsent.passData &&
            <Field {...fieldMap.passData} component={FieldCheckbox} utilities={['col12', 'mlCol8']} />
          }
          {(!adConsent.adConsent || !adConsent.passData) &&
            <TextCopy utilities={['col12', 'mlCol8']}>
              {ui.guiAdConsentConditionalHint}
            </TextCopy>
          }
        </FormSection>
      </div>
    );
  }
}

FormStepFutureContact.propTypes = {
  fieldMap: PropTypes.object.isRequired,
  adConsent: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
};

export default FormStepFutureContact;
