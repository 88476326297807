import { gotoPasswordResetAndAutoSubmitWithMsisdn } from '../user/passwordReset';
import { MYTRACK_LOGIN_DIALOG_GIVE_PASSWORD } from '../../helpers/constants';
import { showDialog, hideDialog } from '../page/dialog';

export const showAccountExists = (msisdn) => (dispatch, getState) => {
  const { ui } = getState();
  const headline = ui.userRegistrationAlreadyExistsHeadline || 'userRegistrationAlreadyExistsHeadline';
  const copy = (ui.userRegistrationAlreadyExistsCopy || 'userRegistrationAlreadyExistsCopy')
    .replace('{MSISDN}', msisdn);
  const label = ui.guiGetPassword;
  const callback = () => dispatch(gotoPasswordResetAndAutoSubmitWithMsisdn(msisdn));
  const trackingVal = MYTRACK_LOGIN_DIALOG_GIVE_PASSWORD;
  const actions = [
    {
      label,
      action: () => {
        dispatch(hideDialog());
        callback();
      },
      track: trackingVal,
    },
  ];
  dispatch(showDialog({
    headline,
    copy,
    actions,
  }));
};
