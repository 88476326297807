import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { createCurrentPageSelector } from '../../selectors/page';

const makeMapStateToProps = () => {
  const pageSelector = createCurrentPageSelector();
  const scriptSelector = createSelector(
    pageSelector,
    (state) => state.environment,
    (page = { metatags: {} }) => [
      ...page.metatags.script || [],
    ],
  );

  const metatagsSelector = createSelector(
    scriptSelector,
    pageSelector,
    (script, page = { metatags: { link: [{}] } }) => {
      const canoLink = page.metatags.link[0].href;
      const linkTag = {};

      if (process.browser) {
        linkTag.link = [{
          ...page.metatags.link[0],
          href: canoLink && canoLink.endsWith('/') && canoLink.length > 1 ? canoLink.substring(0, canoLink.length - 1) : canoLink,
        }];
      }

      return {
        ...page.metatags,
        ...linkTag,
        script,
      };
    },
  );

  // @todo reactivate script and ensure that all tracking informationen are
  //       available on the server. This is deactivated because the server is
  //       cached and the tracking must have user specific content.
  // // tracking information
  // script.push({
  //   innerHTML: `
  //     var utag_data = ${
  //       JSON.stringify(
  //         convertTrackingKeys(tracking || {}),
  //         null,
  //         '  '
  //       )
  //     };
  //   `,
  // });
  return (state, ownProps) => metatagsSelector(state, ownProps);
};

export default connect(makeMapStateToProps)(Helmet);
