import React from 'react';
import PropTypes from 'prop-types';

import Unit from '../../basics/text/TextUnit';
import Copy from '../../basics/text/TextCopy';

import suitcss from '../../../helpers/suitcss';

const componentName = 'ProcessHeaderPrice';
function ProcessHeaderDefinitionList({ items, modifiers, utilities }) {
  const unitModifiers = ['default', 'inline'].concat(modifiers);
  return (
    <dl className={suitcss({ componentName, modifiers, utilities })}>
      {[].concat(...items.map(({ eid, label, price }) => ([
        <dt key={`${eid}dt`} className={suitcss({ componentName, descendantName: 'key' })}>
          <Copy size="tertiary" embedded>
            {label}
          </Copy>
        </dt>,
        <dd key={`${eid}dd`} className={suitcss({ componentName, descendantName: 'value' })}>
          <Unit
            modifiers={unitModifiers}
            price={price}
          />
        </dd>,
      ])))}
    </dl>
  );
}

ProcessHeaderDefinitionList.propTypes = {
  items: PropTypes.array.isRequired,
  modifiers: PropTypes.array,
  utilities: PropTypes.array,
};

ProcessHeaderDefinitionList.defaultProps = {
  modifiers: [],
};

export default ProcessHeaderDefinitionList;
