/**
 * 8 specific scopes have been defined, in the format {generalLocation : permission : section}
 * e.g. mydata:write:password or even mydata:write:contact:email
 * at specific points in the code, we will pass this helper our loc:per:sec identifier
 * and check if the user.scope array includes the specified scope passed in the arguments.
 *
 * expected argument values
 * location: [notifications, mydashboard, myinbox, mydata]
 * permission: [write, read] // null
 * section: [avatar, contact:email, gdpr, password] //null
 * const args = {
    location: 'mydata',
    permission: 'write',
    section: 'contact',
    field: 'email',
  };
 */
import { setItem, STORAGE_TYPE_SESSION_STORAGE } from './storage';
import { STORAGE_KEY_SCOPE } from './constants';

export function parseScope(scope) {
  const splittedScope = scope ? scope.split(' ') : [];
  setItem(STORAGE_TYPE_SESSION_STORAGE, STORAGE_KEY_SCOPE, splittedScope);
  return splittedScope;
}

/**
 *
 * @param allowedScopes
 * @param scope
 * @return {boolean}
 */
export function blockContentBasedOnUserScope(allowedScopes, scopeString) {
  if (!allowedScopes) return false;
  if (allowedScopes.includes(scopeString)) {
    return true;
  }
  const indexOfLastDelimiter = scopeString.lastIndexOf(':');
  if (indexOfLastDelimiter === -1) {
    return false;
  }
  return blockContentBasedOnUserScope(allowedScopes, scopeString.substr(0, indexOfLastDelimiter));
}

/**
 *
 * @param allowedScopes
 * @param scope
 * @return {boolean}
 */
export function isScopeAllowed(allowedScopes, scopeString) {
  if (!allowedScopes) return false;
  if (allowedScopes.includes(scopeString)) {
    return true;
  }
  const indexOfLastDelimiter = scopeString.lastIndexOf(':');
  if (indexOfLastDelimiter === -1) {
    return false;
  }
  return isScopeAllowed(allowedScopes, scopeString.substr(0, indexOfLastDelimiter));
}
