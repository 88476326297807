import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import matchMediaConnector from '../service/ServiceMatchMedia';
import SearchFormComposition from '../../components/compositions/search/SearchForm';
import { fetchSearchResults, fetchSearchSuggestions } from '../../actions/request/registry';
import { trackCurrentPage } from '../../actions/tracking/page';
import {
  addSearchResults,
  removeSearchResults,
  setLastUserSearchLocation,
  setActiveFacet,
} from '../../actions/search/search';
import { isEmptyObject } from '../../helpers/misc';
import {
  MENU_CMS_SEARCH_SUGGESTIONS,
  MENU_CMS_SEARCH_SUGGESTIONS_SERVICE,
  MENU_IDENTIFIER_MAIN,
} from '../../helpers/constants';
import { trackFilterChange } from '../../actions/tracking/event';


class SearchForm extends React.Component {
  constructor(props) {
    super(props);
    // TODO search Url is hardcoded need search Result page in the Sitemap
    this.isSearchResultPage = !this.props.params.resultsPageUrl || (this.props.currentLocation === '/suche');
    this.isInitializedWithResults = !!this.props.searchMetaInfo;
    this.isGlobalSearch = this.props.params.facet === 'none';
    this.isCMSConfigured = !this.props.params.classForHtmlBind;
    this.showNoResults = !!(this.props.params.classForHtmlBind);
    this.colorScheme = this.props.params.colorScheme;

    this.state = {
      searchQuery: this.props.searchQuery,
      searchResults: this.props.searchResults,
      searchMetaInfo: this.props.searchMetaInfo,
      userInteraction: this.isInitializedWithResults,
      searchSuggestionsData: [],
      searchSuggestionsMeta: { counts: { facets: { service: 0 } } },
      showSearchSuggestions: true,
      preSelectedFacet: this.props.params.facet === 'none' ? 'all' : 'service',
      searchTypeForTracking: this.isSearchResultPage ? 'serp' :
        this.props.params.facet === 'none' ? 'eglobal' : 'eservice',
      appliedSearchFilterTracking: [
        {
          facet: 'search filter',
          value: (this.isGlobalSearch ? 'all' : 'service'),
        }],
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setState = this.setState.bind(this);
    this.flushSearchQuery = this.flushSearchQuery.bind(this);
    this.selectSearchTypeForTracking = this.selectSearchTypeForTracking.bind(this);
    this.triggerPageTrackingForLinkClick = this.triggerPageTrackingForLinkClick.bind(this);
    this.setPreSelectedFacet = this.setPreSelectedFacet.bind(this);
    this.trackClick = this.trackClick.bind(this);
  }

  //------------------------------------------------------------------------------------------------
  //  Component Lifecycle
  //------------------------------------------------------------------------------------------------

  async componentDidMount() {
    const { externalSearchQuery } = this.props;

    // Clear up last search results in case user see the Searchform on a
    // none Search Result page again.
    if (!this.isSearchResultPage && this.isInitializedWithResults) {
      await this.props.dispatch(removeSearchResults());
      this.setState({
        userInteraction: false,
        searchQuery: '',
        searchResults: [],
        searchMetaInfo: null,
      });
    }

    if (!isEmptyObject(externalSearchQuery) &&
      (externalSearchQuery.sfo_q || externalSearchQuery.sfo_facet)) {
      if (externalSearchQuery.sfo_q) {
        await this.setState({
          searchQuery: externalSearchQuery.sfo_q,
          userInteraction: true,
        });
      }

      if (externalSearchQuery.sfo_facet) {
        await this.setState({
          preSelectedFacet: externalSearchQuery.sfo_facet,
          userInteraction: true,
        });
      }
      this.isInitializedWithResults = true;
      await this.handleSubmit();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.searchResults.length !== prevProps.searchResults.length) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        searchQuery: this.props.searchQuery,
        searchResults: this.props.searchResults,
        searchMetaInfo: this.props.searchMetaInfo,
        userInteraction: true,
      });
    }
  }

  //------------------------------------------------------------------------------------------------
  //  Searchform Helper
  //------------------------------------------------------------------------------------------------

  // eslint-disable-next-line react/sort-comp
  async handleChange(event) {
    this.setState({ searchQuery: event.target.value });
    if (event.target.value.length >= 2) {
      let isSearchWithResults = false;
      const searchSuggestions = await this.props.dispatch(
        fetchSearchSuggestions({ q: event.target.value }));
      if ((searchSuggestions.meta.counts.facets.service !== 0) ||
        (searchSuggestions.meta.counts.facets.shop !== 0)) {
        isSearchWithResults = true;
      }
      this.setState({
        showSearchSuggestions: !isSearchWithResults,
        searchSuggestionsData: isSearchWithResults ? searchSuggestions.data : [],
        searchSuggestionsMeta:
          isSearchWithResults ? searchSuggestions.meta : { counts: { facets: { service: 0 } } },
      });
    } else {
      this.setState({
        showSearchSuggestions: true,
        searchSuggestionsData: [],
        searchSuggestionsMeta: { counts: { facets: { service: 0 } } },
      });
    }
  }

  resetTrackingSearchType() {
    this.setState({
      searchTypeForTracking: this.isSearchResultPage ? 'serp' :
        this.props.params.facet === 'none' ? 'eglobal' : 'eservice',
    });
  }

  async handleSubmit(event) {
    if (event) {
      event.preventDefault();
    }
    const { dispatch, params, currentLocation } = this.props;
    const { resultsPageUrl } = params;
    const searchResults = await this.props.dispatch(
      fetchSearchResults({ q: this.state.searchQuery }));

    await dispatch(
      addSearchResults(
        this.state.searchQuery,
        searchResults,
        this.state.preSelectedFacet,
      ));

    await dispatch(trackCurrentPage({
      search_type: this.state.searchTypeForTracking,
      search_terms: this.state.searchQuery,
      search_results: this.state.searchSuggestionsMeta.counts.total ?
        this.isGlobalSearch ?
          this.state.searchSuggestionsMeta.counts.total :
          this.state.searchSuggestionsMeta.counts.facets.service :
        0,
      appliedFilters: this.state.appliedSearchFilterTracking,
    }, 'search'));

    await this.resetTrackingSearchType();
    await dispatch(setLastUserSearchLocation(currentLocation));

    if ((this.props.lastSearchLocation === '/suche') && (this.props.currentLocation === '/suche')) {
      if ((this.searchFacet !== 'all') &&
        !this.props.searchResults.some(
          resultItem => resultItem.facet === this.props.searchFacet,
        )
      ) {
        dispatch(setActiveFacet('all'));
        this.setPreSelectedFacet('all');
      }
    }

    if (this.isSearchResultPage) {
      this.setState({
        searchResults: searchResults.data,
        searchMetaInfo: searchResults.meta,
        userInteraction: true,
      });
    } else {
      dispatch(push(resultsPageUrl));
    }
  }

  async triggerPageTrackingForLinkClick(linkType) {
    const { dispatch } = this.props;

    await dispatch(trackCurrentPage({
      search_type: linkType !== 'CMS' ?
        `autosuggest ${this.state.preSelectedFacet}` :
        `${linkType} quick Links`,
      search_terms: this.state.searchQuery,
      search_results:
        this.state.searchSuggestionsMeta.counts.total ?
          this.isGlobalSearch ?
            this.state.searchSuggestionsMeta.counts.total :
            this.state.searchSuggestionsMeta.counts.facets.service :
          0,
      appliedFilters: [{
        facet: 'search filter',
        value: this.state.preSelectedFacet,
      }],
    }));
  }

  setPreSelectedFacet(facet) {
    this.setState({
      preSelectedFacet: facet,
      appliedSearchFilterTracking: [
        {
          facet: 'search filter',
          value: facet,
        }],
    });
  }

  flushSearchQuery() {
    this.setState({
      searchQuery: '',
      showSearchSuggestions: true,
      searchSuggestionsData: [],
      searchSuggestionsMeta: { counts: { facets: { service: 0 } } },
    });
  }

  selectSearchTypeForTracking(flowType) {
    this.setState({ searchTypeForTracking: flowType });
  }

  trackClick(pixel) {
    this.props.dispatch(trackFilterChange(pixel));
  }


  //------------------------------------------------------------------------------------------------
  //  Component render
  //------------------------------------------------------------------------------------------------

  render() {
    return (
      <SearchFormComposition
        onInputChange={this.handleChange}
        onSubmit={this.handleSubmit}
        inputValue={this.state.searchQuery}
        params={this.props.params}
        searchQuery={this.state.searchQuery}
        searchResults={this.state.searchResults}
        searchMetaInfo={this.state.searchMetaInfo}
        userInteraction={this.state.userInteraction}
        searchSuggestionsData={this.state.searchSuggestionsData}
        searchSuggestionsMeta={this.state.searchSuggestionsMeta}
        cmsSearchSuggestions={this.isGlobalSearch ?
          this.props.cmsSearchSuggestions :
          this.props.cmsSearchSuggestionsService}
        showCmsSearchSuggestions={this.state.showSearchSuggestions}
        isGlobalSearch={this.isGlobalSearch}
        mainNavItems={this.props.mainNavItems}
        colorScheme={this.colorScheme}
        flushSearchQuery={this.flushSearchQuery}
        isMediaS={this.props.isMediaS}
        isCMSConfigured={this.isCMSConfigured}
        preSelectedFacet={this.props.searchFacet}
        showNoSearchResults={this.showNoResults}
        selectSearchTypeForTracking={this.selectSearchTypeForTracking}
        triggerPageTrackingForLinkClick={this.triggerPageTrackingForLinkClick}
        setPreSelectedFacet={this.setPreSelectedFacet}
        trackClick={this.trackClick}
        isSearchResultPage={
        !this.props.params.resultsPageUrl ||
        (this.props.currentLocation === '/suche') ||
        (this.props.currentLocation.endsWith('/service'))
        }
      />
    );
  }
}

//------------------------------------------------------------------------------------------------
//  Searchform Proptypes
//------------------------------------------------------------------------------------------------

SearchForm.propTypes = {
  params: PropTypes.shape({
    config: PropTypes.array.isRequired,
    inputAction: PropTypes.string.isRequired,
    inputPlaceholder: PropTypes.string.isRequired,
    moreAction: PropTypes.string.isRequired,
    resultsNone: PropTypes.string.isRequired,
    resultsPageUrl: PropTypes.string.isRequired,
    resultsSorry: PropTypes.string.isRequired,
    resultsTotal: PropTypes.string.isRequired,
    colorScheme: PropTypes.string.isRequired,
    facet: PropTypes.string,
    classForHtmlBind: PropTypes.string,
  }),
  mainNavItems: PropTypes.array,
  currentLocation: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  searchFacet: PropTypes.string,
  searchMetaInfo: PropTypes.object,
  searchQuery: PropTypes.string,
  searchResults: PropTypes.array,
  externalSearchQuery: PropTypes.object,
  lastSearchLocation: PropTypes.string,
  cmsSearchSuggestionsService: PropTypes.array.isRequired,
  cmsSearchSuggestions: PropTypes.array.isRequired,
  isMediaS: PropTypes.bool,
};

const mapStateToProps = (state, { location }) => ({
  locationHash: location ? decodeURI(location.hash).replace('#', '') : '',
  externalSearchQuery: state.routing.locationBeforeTransitions.query,
  searchResults: state.search.searchResults,
  searchMetaInfo: state.search.searchMetaInfo,
  searchQuery: state.search.searchQuery,
  searchFacet: state.search.searchFacet,
  lastSearchLocation: state.search.lastSearchLocation,
  userFlowForTracking: state.search.searchFlowToResultPage,
  cmsSearchSuggestions: state.menu[MENU_CMS_SEARCH_SUGGESTIONS].items,
  cmsSearchSuggestionsService: state.menu[MENU_CMS_SEARCH_SUGGESTIONS_SERVICE].items,
  mainNavItems: state.menu[MENU_IDENTIFIER_MAIN].items,
  currentLocation: state.routing.locationBeforeTransitions.pathname,
});

export default compose(
  matchMediaConnector(['isMediaS']),
  connect(mapStateToProps))(SearchForm);

