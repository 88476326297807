import PageModule from './PageModule';
import component from '../../containers/account/simReplacement/MySimReplacementForm';
import { fetchBalance } from '../../actions/request/registry';
/**
 * Displays the process of the replacement SIM order
 * @see https://confluence.db-n.com/x/2YPr
 */
class MySimReplacementFormModule extends PageModule {
  constructor(moduleData) {
    super(component, moduleData);
  }

  prepare(pathname, page) {
    return async dispatch => {
      await dispatch(super.prepare(pathname, page));
      await dispatch(fetchBalance());
    };
  }
}

export default MySimReplacementFormModule;

