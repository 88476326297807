import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as birthdayValidation from './formConfigEmailValidation';
import FormManager from '../form/FormManager';
import GlobalSection from '../../components/basics/global/GlobalSection';
import EmailValidationConfirmRequest from '../../model/requests/EmailValidationConfirmRequest';

const steps = [birthdayValidation];

class EmailValidationForm extends PureComponent {
  constructor(props, context) {
    super(props, context);
    this.getSubmitRoute = this.getSubmitRoute.bind(this);
    this.getSuccessRoute = this.getSuccessRoute.bind(this);
  }

  /**
   * Called By the form handler to determine where the form should
   * be submitted to.
   *
   * @param {Object} fieldMap
   * @param {Object} finalizedValues
   * @param {Object} formValues - original form values
   * @returns {string}
   */
  getSubmitRoute(fieldMap, finalizedValues, formValues) {
    const birthday = formValues[fieldMap.birthday.name];
    return new EmailValidationConfirmRequest({
      ...this.props.query,
      birthday,
    });
  }

  getSuccessRoute() {
    const { sitemap } = this.props;
    return sitemap.EmailValidationSuccessRoute.url;
  }

  render() {
    const { location } = this.props;

    return (
      <GlobalSection>
        <FormManager
          form={EmailValidationForm.formName}
          submitRoute={this.getSubmitRoute}
          successRoute={this.getSuccessRoute}
          onAfterSubmitSuccess={this.onAfterSubmitSuccess}
          steps={steps}
          location={location}
          stepProps={this.props}
          className="EmailValidationForm"
          withBlockError
          withFooter
        />
      </GlobalSection>
    );
  }
}

/**
 * required by tracking!
 */
EmailValidationForm.formName = 'emailValidation';

EmailValidationForm.propTypes = {
  location: PropTypes.object.isRequired,
  query: PropTypes.object.isRequired,
  sitemap: PropTypes.object.isRequired,
};

const mapStateToProps = ({ routing, site }) => {
  const { email, token, clientCustomerID, solicitorID } = routing.locationBeforeTransitions.query;
  if (solicitorID) {
    return {
      query: {
        token,
        solicitorId: solicitorID,
      },
      sitemap: site.sitemap,
    };
  }
  return {
    query: {
      email,
      token,
      customerId: clientCustomerID,
    },
    sitemap: site.sitemap,
  };
};

export default connect(
  mapStateToProps,
)(EmailValidationForm);
