/* global document, window */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import shallowEqual from 'react-pure-render/shallowEqual';

import TextMessage from '../../basics/text/TextMessage';
import Link from '../../basics/text/TextLink';
import Copy from '../../basics/text/TextCopy';

import suitcss from '../../../helpers/suitcss';
import { stripHTML } from '../../../helpers/str';
import { scrollToPos } from '../../../helpers/navigation';
import { preventDefault } from '../../../helpers/functional';
import FormValidationError from '../../../model/errors/FormValidationError';
import AbstractError from '../../../model/errors/AbstractError';

const componentName = 'FormBlockError';

const renderAdressCorrectionMessage = ({ error, ui, applyCorrections }) => {
  const corrections = error.getCorrections();
  return [
    <span key="0">
      {ui.ferBlockAddressCorrection.replace(
        '{CORRECTED_ADDRESS}',
        `${corrections.street} ${corrections.house_number}, ${corrections.zip} ${corrections.city}`,
      )}
    </span>,
    <div className={suitcss({ componentName, descendantName: 'button' })} key="1">
      <Link asButton buttonFilled onClick={preventDefault(() => applyCorrections())}>
        {ui.guiWordApply}
      </Link>
    </div>,
  ];
};

const renderInvalidFieldsMessage = ({ error, ui }) => {
  const fieldErrors = error.getFieldErrors();
  return [
    ui.ferBlockValidation,
    <Copy element="div" key="copy-ul">
      <ul>
        {Object.values(fieldErrors).map((fieldError) => (
          <li key={fieldError.field.name}>
            {stripHTML(
              fieldError.field.shortLabel ||
              fieldError.field.label ||
              fieldError.field.name, // last resort fallback (ultima ratio)
            )}
          </li>))
        }
      </ul>
    </Copy>,
  ];
};

class FormBlockError extends Component {

  componentDidMount() {
    this.scrollToPos();
  }

  shouldComponentUpdate(nextProps) {
    return Boolean(
      nextProps.error ||
      nextProps.hasSubmitSucceeded ||
      (this.props.hasSubmitFailed && this.props.hasSubmitFailed !== nextProps.hasSubmitFailed),
    );
  }

  componentDidUpdate(prevProps) {
    if (!shallowEqual(prevProps.error, this.props.error)) {
      this.scrollToPos();
    }
  }

  getScrollToPos() {
    const { error, ui } = this.props;

    if (!error) { return null; }

    const containerPos = this.container
      ? this.container.getBoundingClientRect().top + window.pageYOffset
      : null;

    if (error instanceof FormValidationError) {
      // const corrections = error.getCorrections();
      if (this.container) {
        // scrollTo Block Error
        return containerPos;
      }
      // scrollTo Field Error
      return Object.keys(error.errors).reduce((pos, key) => {
        const el = document.querySelector(`[data-field-name="${key}"]`);
        const bounds = el && el.getBoundingClientRect();
        if (!pos && bounds) { return bounds.top; }
        return bounds && bounds.top < pos ? bounds.top : pos;
      }, 0) + window.pageYOffset;
    }
    // scrollTo Block Error if shown
    return error.getVerbalization(ui) ? containerPos : null;
  }

  scrollToPos() {
    const pos = this.getScrollToPos();
    if (pos) {
      scrollToPos(pos, { contentAware: false, delay: 0 });
    }
  }

  render() {
    const { error, fieldMap, ui } = this.props;
    if (!error) {
      return null;
    }
    // default message
    let message = error.getVerbalization(ui);
    if (error instanceof FormValidationError) {
      const corrections = error.getCorrections();
      if (corrections.city) {
        message = renderAdressCorrectionMessage(this.props);
      } else if (!Object.values(fieldMap).some(
        ({ name }) => Object.keys(error.errors).some(errorKey => name === errorKey))
      ) {
        message = renderInvalidFieldsMessage({ error, fieldMap, ui });
      } else {
        return null;
      }
    }

    return (
      <TextMessage Component="div" level="error" >
        <div className={suitcss({ componentName })} ref={ref => { this.container = ref; }}>
          <div>{message}</div>
        </div>
      </TextMessage>
    );
  }
}
FormBlockError.propTypes = {
  error: PropTypes.instanceOf(AbstractError),
  hasSubmitFailed: PropTypes.bool,
  hasSubmitSucceeded: PropTypes.bool,
  fieldMap: PropTypes.object,
  ui: PropTypes.object.isRequired,
};

FormBlockError.defaultProps = {
  fieldMap: {},
};

export default FormBlockError;
