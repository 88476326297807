import { ACTION_PREFIX } from '../../helpers/constants';

export const SYNC_STORAGE = `${ACTION_PREFIX}/SYNC_STORAGE`;
export const UPDATE_SHOPPINGBAG = `${ACTION_PREFIX}/UPDATE_SHOPPINGBAG`;
export const CLEAR_SHOPPINGBAG = `${ACTION_PREFIX}/CLEAR_SHOPPINGBAG`;
export const UPDATE_CAMPAIGN_TOKEN = `${ACTION_PREFIX}/UPDATE_CAMPAIGN_TOKEN`;
export const CLEAR_CAMPAIGN_TOKEN = `${ACTION_PREFIX}/CLEAR_CAMPAIGN_TOKEN`;

export const syncStorage = () => ({
  type: SYNC_STORAGE,
  payload: null,
});

/**
 * updateShoppingBag
 * @param  {Object} payload
 * @param  {Array} payload.entities
 * @param  {Array} payload.promoCodes
 * @return {Action Object}
 */
export const updateShoppingBag = (payload) => ({
  type: UPDATE_SHOPPINGBAG,
  payload,
});

export const updateCampaignToken = (payload) => {
  return {
    type: UPDATE_CAMPAIGN_TOKEN,
    payload,
  };
};

/**
 * clearShoppingBag
 * @return {Action Object}
 */
export const clearShoppingBag = () => ({
  type: CLEAR_SHOPPINGBAG,
  payload: null,
});

export const clearCampaignToken = () => ({
  type: CLEAR_CAMPAIGN_TOKEN,
  payload: null,
});
