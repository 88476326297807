import QueueableRequest from './QueueableRequest';
import { ACCOUNT_ACR_SETTINGS_ENDPOINT } from '../../config/api';
import { bindParamsToRoute } from '../../helpers/url';

export default class ACRSettingsRequest extends QueueableRequest {
  constructor(msisdn, payload) {
    super(bindParamsToRoute(ACCOUNT_ACR_SETTINGS_ENDPOINT, { msisdn }), {
      ...payload,
    });
  }
}
