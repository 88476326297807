import PageModule from './PageModule';

/**
 * Displays all available tariff options at a prepaid rate.
 * @see https://confluence.db-n.com/x/x5Pc
 */
class TariffOptionsModule extends PageModule {
  constructor(moduleData) {
    super(import('../../containers/tariff/TariffOptions'), moduleData);
  }
}

export default TariffOptionsModule;
