import PageModule from './PageModule';
import component from '../../containers/moduleSwapper/ModuleSwapper';
import { fetchEntityById } from '../../actions/request/registry';

/**
 * ModuleSwapperModule
 */
class ModuleSwapperModule extends PageModule {
  constructor(moduleData) {
    super(component, moduleData);
  }

  prepare(pathname, page) {
    return async dispatch => {
      await dispatch(super.prepare(pathname, page));
      await dispatch(fetchEntityById(this.params.fallbackContentId));
    };
  }
}

export default ModuleSwapperModule;

