import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import {
  BRAND_OTELO,
  ID_MY_OFFERS_TARIFFS,
  MENU_IDENTIFIER_MY_OTELO,
  MENU_IDENTIFIER_MY_OTELO_PUA,
  SCOPE_MYFRIEND,
  SCOPE_MYFRIEND_READ,
} from '../../helpers/constants';
import { isAccountUrl } from '../../config/api';
import { isScopeAllowed } from '../../helpers/scope';
import { navLinkList } from '../../propTypes/navigation';

import menuActions from '../../actions/page/menu';
import viewportActions from '../../actions/site/viewport';

import NavigationMainComposition from '../../components/compositions/navigation/NavigationMain';
import matchMediaConnector from '../service/ServiceMatchMedia';

import { createCurrentPageSelector } from '../../selectors/page';

class NavigationMain extends PureComponent {

  componentDidUpdate(prevProps) {
    const { isOpen, isMediaSM, onClose } = this.props;
    if (isOpen && isMediaSM !== prevProps.isMediaSM) {
      onClose(prevProps.isMediaSM);
    }
  }

  render() {
    const { items } = this.props;
    return items ? (<NavigationMainComposition {...this.props} />) : null;
  }
}

NavigationMain.propTypes = {
  items: navLinkList,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  isMediaS: PropTypes.bool,
  isMediaSM: PropTypes.bool,
  isFacelift: PropTypes.bool,
  display: PropTypes.string,
  isBrandedSite: PropTypes.bool,
  isBrandedUser: PropTypes.bool,
  activeCategory: PropTypes.string,
  identifier: PropTypes.string,
  isUserReferFriendsEnabled: PropTypes.bool,
};

NavigationMain.defaultProps = {
  isMediaS: false,
  isMediaSM: false,
};

const menuSelector = state => state.menu;
const siteSelector = state => state.site;
const userMarketSelector = state => state.user.market;
const userBrandNameSelector = state => state.user.brandName;
const siteRoutingSelector = state => state.site.routing;
const routingSelector = state => state.routing;

// Selector for currentLocation
const currentLocationSelector = createSelector(
  [siteRoutingSelector],
  (routing) => routing.currentLocation && routing.currentLocation.pathname,
);

// Selector for display
const displaySelector = (forceHide) => createSelector(
  [siteSelector],
  (site) =>
    // display = null means navigation is on, any other value means hide it.
    (forceHide || site.contractRenewal.isInProgress || !!site.brandName) ? 'minimal' : null,
);

// Selector for isBranded
const isBrandedSelector = createSelector(
  [siteSelector, userBrandNameSelector, routingSelector],
  (site, userBrandName, routing) => {
    const isBrandedSite = !!site.brandName;
    const isBrandedUser = !!userBrandName && userBrandName !== BRAND_OTELO
      && isAccountUrl(routing.locationBeforeTransitions);
    return isBrandedSite || isBrandedUser;
  },
);

// Selector for brandName
const brandNameSelector = createSelector(
  [siteSelector, userBrandNameSelector],
  (site, userBrandName) =>
    userBrandName || site.brandName,

);

// Selector for brandBasePath
const brandBasePathSelector = createSelector(
  [siteSelector],
  (site) => site.brandBasePath,
);

// Selector for MyDashboardRoute
const myDashboardRouteSelector = createSelector(
  [siteSelector],
  (site) => site.sitemap.MyDashboardRoute.url,
);

// Selector for ShoppingCartRoute
const shoppingCartRouteSelector = createSelector(
  [siteSelector],
  (site) => (site.sitemap.ShoppingCartRoute && site.sitemap.ShoppingCartRoute.url) || '/warenkorb',
);


// Selector for activeCategory
const activeCategorySelector = (filteredMenu) => createSelector(
  [siteSelector],
  (site) => site.routing.currentLocation && filteredMenu.map(item => {
    const { routing: { currentLocation } } = site;
    if (currentLocation.hash && item.url.includes(currentLocation.hash)
      && item.url.includes(currentLocation.pathname)) {
      return item.url;
    } else if (currentLocation.hash === `#${ID_MY_OFFERS_TARIFFS}` && item.url === site.sitemap.MyDashboardRoute.url) {
      return `${site.sitemap.MyDashboardRoute.url}#${ID_MY_OFFERS_TARIFFS}`;
    } else if (currentLocation.pathname === item.url) {
      return item.url;
    } else if (currentLocation.pathname.includes(item.url)
      && item.url !==
        (site.sitemap.TariffOverviewRoute.url || site.sitemap.HardwareOverviewRoute.url)) {
      return item.url;
    } else if (currentLocation.pathname.includes('/hardware')) {
      return site.sitemap.HardwareOverviewRoute.url;
    } else if (currentLocation.pathname.includes('/tarife')) {
      return site.sitemap.TariffOverviewRoute.url;
    }
    return null;
  }).filter(item => item)[0],
);

const modifiedIdentifierSelector = createSelector(
  [
    siteSelector,
    userMarketSelector,
    (state, ownProps) => ownProps.identifier,
  ],
  (site, userMarket, identifier) => {
    const { routing: { currentLocation } } = site;
    let modifiedIdentifier;
    if (
      userMarket === 'PUA' &&
      currentLocation &&
      currentLocation.pathname.includes('/mein-otelo/')
    ) {
      modifiedIdentifier = MENU_IDENTIFIER_MY_OTELO_PUA;
    } else if (currentLocation && currentLocation.pathname.includes('/mein-otelo/')) {
      modifiedIdentifier = MENU_IDENTIFIER_MY_OTELO;
    } else {
      modifiedIdentifier = identifier;
    }
    return modifiedIdentifier;
  },
);

const modifiedMenuSelector = createSelector(
  [menuSelector, modifiedIdentifierSelector],
  (menu, modifiedIdentifier) => menu[modifiedIdentifier] || {},
);

const makeMapStateToProps = () => {
  const currentPageSelector = createCurrentPageSelector();
  return (state, ownProps) => {
    const menu = modifiedMenuSelector(state, ownProps);
    const page = currentPageSelector(state);
    const forceHide = page && page.settings.some((setting) => setting.type === 'NavigationOff');
    const isFacelift = page && page.settings.some((setting) => setting.type === 'NavigationFacelift');
    const filteredMenu = menu.items.filter((item, index) => index > 0);

    return {
      items: menu.items,
      isOpen: menu.isOpen || false,
      display: displaySelector(forceHide)(state),
      isBranded: isBrandedSelector(state),
      brandName: brandNameSelector(state),
      brandBasePath: brandBasePathSelector(state),
      identifier: modifiedIdentifierSelector(state, ownProps),
      isFacelift,
      activeCategory: activeCategorySelector(filteredMenu)(state, ownProps),
      currentLocation: currentLocationSelector(state),
      MyDashboardRoute: myDashboardRouteSelector(state),
      ShoppingCartRoute: shoppingCartRouteSelector(state),
      isUserReferFriendsEnabled: isScopeAllowed(state.user.scope, SCOPE_MYFRIEND) ||
        isScopeAllowed(state.user.scope, SCOPE_MYFRIEND_READ),
    };
  };
};

const mapDispatchToProps = (state, ownProps) => (dispatch) => ({
  onOpen: (identifier, blockScrolling) => {
    dispatch(menuActions.openMenu((identifier || ownProps.identifier), blockScrolling));
    if (blockScrolling) {
      dispatch(viewportActions.toggleViewportBlocking());
    }
  },
  onClose: (identifier, blockScrolling) => {
    dispatch(menuActions.closeMenu(identifier || ownProps.identifier));
    if (blockScrolling) {
      dispatch(viewportActions.toggleViewportBlocking());
    }
  },
});

const areStatesEqual = (next, prev) => {
  return JSON.stringify(prev.menu) === JSON.stringify(next.menu) &&
    prev.site.contractRenewal.isInProgress === next.site.contractRenewal.isInProgress &&
    prev.site.brandName === next.site.brandName &&
    prev.user.brandName === next.user.brandName &&
    prev.site.brandBasePath === next.site.brandBasePath &&
    prev.routing.locationBeforeTransitions === next.routing.locationBeforeTransitions &&
    JSON.stringify(prev.site.sitemap) === JSON.stringify(next.site.sitemap) &&
    JSON.stringify(prev.site.routing.currentLocation)
    === JSON.stringify(next.site.routing.currentLocation);
};


export default compose(
  matchMediaConnector(['isMediaS', 'isMediaSM']),
  connect(
    makeMapStateToProps,
    mapDispatchToProps,
    null,
    { areStatesEqual },
  ),
)(NavigationMain);
