import React from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';

const componentName = 'FormSection';
const FormSection = (props) => {
  const { className, children, withoutBorder, asGrid, asRow } = props;
  return (
    <section className={suitcss({ componentName, className, modifiers: [!withoutBorder && 'border'] })}>
      <div
        className={suitcss({
          componentName,
          descendantName: 'inner',
          utilities: [
            asGrid && 'grid',
            asGrid && 'sCollapse',
            asRow && 'row',
          ],
        })}
      >
        {children}
      </div>
    </section>
  );
};

FormSection.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.array,
  ]),
  asRow: PropTypes.bool,
  asGrid: PropTypes.bool,
  withoutBorder: PropTypes.bool,
};

export default FormSection;

