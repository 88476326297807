/**
 *
 * Redux-form expects field errors to be an object, with the fieldname being
 * the key and the value being the message.
 */
import FieldErrorEntry from '../form/FieldErrorEntry';
import ApiError from './ApiError';
import AbstractError from './AbstractError';
import {
  FORM_VALIDATION_ERROR,
  ERROR_TYPE_FORM_ERROR,
} from '../../config/errorCodes';

/**
 * Thrown if the received response has inapplicable data
 */
class FormValidationError extends AbstractError {
  /**
   * @param {object} ui - container containing all "fer_" ui elements
   * @param {FieldErrorEntry[]} [fieldErrorEntries] - an optional list of errors
   */
  constructor(ui, fieldErrorEntries = []) {
    super(FORM_VALIDATION_ERROR);
    this.ui = ui;
    this.errors = {};

    fieldErrorEntries.forEach(e => this.addError(e));
  }

  /**
   * Creates a FormValidationError from a server's form error response or null if creation failed.
   * @see https://confluence.db-n.com/x/ga3c
   *
   * @param {ApiError} apiError
   * @param {object} ui - container containing all "fer_" ui elements
   * @return {FormValidationError|null}
   */
  static createFromApiError(apiError, ui) {
    const { type, formFieldErrors } = apiError.fullResponse.body.error;
    if (!(apiError instanceof ApiError) || type !== ERROR_TYPE_FORM_ERROR) {
      return null;
    }

    const error = new FormValidationError(ui);
    error.setFullCode(error.fullCode);
    error.setContext(apiError.getContext());

    formFieldErrors.forEach(({ field, type: inlineErrorType, corrected }) => {
      error.addError(new FieldErrorEntry(field, inlineErrorType, corrected, ui));
    });

    return error;
  }

  /**
   * Adds an error to the list of errors.
   *
   * @param {FieldErrorEntry} error
   */
  addError(error) {
    this.errors[error.field.name] = error;
  }

  /**
   * @return {Array<string>} Returns the names of all invalid fields
   */
  getAffectedFields() {
    return Object.keys(this.errors);
  }

  /**
   * Returns a list of corrections for fields, (if corrections exist).
   * @return {object} a key-value map where key is the name of the field
   *     and value being the correction string.
   */
  getCorrections() {
    const corrections = {};
    Object.entries(this.errors).forEach(([fieldName, error]) => {
      if (error.correction) {
        corrections[fieldName] = error.correction;
      }
    });
    return corrections;
  }

  /**
   * Output is interpretable by redux-form
   * @see https://redux-form.com/7.0.4/examples/syncvalidation/
   */
  getFieldErrors() {
    return this.errors;
  }

  /**
   * {@inheritDoc}
   * @override
   */
  getLoggingData() {
    return {
      ...super.getLoggingData(),
      fieldErrors: Object.values(this.errors).map(e => e.toString()),
    };
  }
}

export default FormValidationError;
