/**
 * @file
 *
 * This file should tracks click actions and special events
 * occuring in components.
 */

import {
  getFreeSimPixel,
  setCheckoutFormTracking,
  setContractExtension,
} from '../../helpers/tracking';

import { getCartPixel } from './page';
import { uniqueId } from '../../helpers/identifier';
import {
  MYTRACKING_ATTRIBUTE,
  ACTION_PREFIX,
} from '../../helpers/constants';
import { getEntityById } from '../../selectors/misc';
import { devInfo } from '../../helpers/meta';
import { isHardwareEntity, isTariffEntity } from '../../helpers/entity';

export const TRACK_CLICK = `${ACTION_PREFIX}/TRACK_CLICK`;
export const TRACK_DIALOG = `${ACTION_PREFIX}/TRACK_DIALOG`;
export const TRACK_FILTER_INTERACTION = `${ACTION_PREFIX}/TRACK_FILTER_INTERACTION`;
export const TRACK_FILTER_PRODUCTS = `${ACTION_PREFIX}/TRACK_FILTER_PRODUCTS`;
export const TRACK_ERROR = `${ACTION_PREFIX}/TRACK_ERROR`;
export const TRACK_REMOVE_ITEM = `${ACTION_PREFIX}/TRACK_REMOVE_ITEM`;
export const TRACK_FIRED = `${ACTION_PREFIX}/TRACK_FIRED`;
export const AGGREGATE_PAGE_TRACKING = `${ACTION_PREFIX}/AGGREGATE_PAGE_TRACKING`;
export const LOG_ENTRY = `${ACTION_PREFIX}/LOG_ENTRY`;
export const TRACK_CONTACT = `${ACTION_PREFIX}/TRACK_CONTACT`;

export const trackError = (onPage, type, message, code, field) => {
  const id = uniqueId('tracking');
  return {
    type: onPage ? AGGREGATE_PAGE_TRACKING : TRACK_ERROR,
    meta: { identifier: id },
    payload: {
      id,
      method: 'error',
      error_type: type,
      error_message: message,
      error_field: field,
      error_code: code,
    },
  };
};

export const trackClick = (pixel) => {
  const id = uniqueId('tracking');
  return {
    type: TRACK_CLICK,
    meta: { identifier: id },
    payload: {
      id,
      method: 'link',
      ...pixel,
    },
  };
};

export const trackDialog = (pixel) => {
  const id = uniqueId('tracking');
  return {
    type: TRACK_DIALOG,
    meta: { identifier: id },
    payload: {
      id,
      method: 'dialog',
      ...pixel,
    },
  };
};

export const trackFilterChange = (pixel) => {
  const id = uniqueId('tracking');
  return {
    type: TRACK_FILTER_INTERACTION,
    meta: { identifier: id },
    payload: {
      id,
      method: 'search filter interaction',
      ...pixel,
    },
  };
};

export const trackFilterProducts = (pixel) => {
  const id = uniqueId('tracking');
  return {
    type: TRACK_FILTER_PRODUCTS,
    meta: { identifier: id },
    payload: {
      id,
      method: 'filter_products',
      ...pixel,
    },
  };
};

export const trackContact = (pixel) => {
  const id = uniqueId('tracking');
  return {
    type: TRACK_CONTACT,
    meta: { identifier: id },
    payload: {
      id,
      method: 'contact',
      ...pixel,
    },
  };
};

export const trackRemoveItem = (productId) => (dispatch, getState) => {
  const { entities, cart } = getState();
  const entity = getEntityById(entities, productId);
  let priceOnce;
  let priceMonthly;
  const isHardware = isHardwareEntity(entity);
  const isTariff = isTariffEntity(entity);

  if (isHardware) {
    try {
      const tariffEntity = cart.find((item) => item.etype === 'tariffEntity');
      const tariffId = tariffEntity.eid.toLowerCase();
      const { singlePaymentFee, paymentFee } = entity.tariffMap[tariffId];
      priceOnce = singlePaymentFee.unit;
      priceMonthly = paymentFee.unit;
    } catch (e) {
      devInfo('Tracking: cart_remove error', e);
    }
  }

  const id = uniqueId('tracking');
  dispatch({
    type: TRACK_REMOVE_ITEM,
    meta: { identifier: id },
    payload: {
      id,
      method: 'cart_remove',
      product: [
        {
          id: entity.iid,
          ...(isHardware ? { quantity: 1 } : {}),
          name: isTariff ? entity.type : entity.name,
          ...(isHardware ? { type: 'device' } : {}),
          ...(isTariff ? { type: 'tariff' } : {}),
          priceOnce,
          priceMonthly,
        },
      ],
    },
  });
};

export const trackMyState = stateName => {
  const pixel = {};
  pixel[MYTRACKING_ATTRIBUTE] = stateName;
  return trackClick(pixel);
};

export const firedTracking = (id) => ({
  type: TRACK_FIRED,
  meta: { identifier: id },
  payload: null,
});

/**
 * Add product data to tracking queue so it will be tracked on the success page
 */
export const trackCheckoutSubmit = (responseBody, formValues, cart, isContractRenewal = false) =>
  async (dispatch, getState) => {
    const { site } = getState();
    const id = uniqueId('tracking');
    const { contractId } = responseBody;
    let pixel = {
      ...(await getCartPixel('Checkout', getState, { dispatch })),
      ...(site.mnpCheckout ? { order_option: ['mnpCheckbox'] } : {}),
      ...(isContractRenewal ?
        setContractExtension(getState, true) :
        setCheckoutFormTracking(getState, true)),
    };

    if (isContractRenewal) {
      pixel = {
        ...pixel,
        app_view: site.appView,
      };
    } else {
      pixel = {
        ...pixel,
        order_id: contractId,
      };
    }
    return dispatch({
      type: AGGREGATE_PAGE_TRACKING,
      meta: { identifier: id },
      payload: pixel,
    });
  };

export const trackFreeSimSubmit = ({ tariffIdentifier, orderId }) => {
  const id = uniqueId('tracking');
  const payload = getFreeSimPixel(tariffIdentifier);
  return {
    type: AGGREGATE_PAGE_TRACKING,
    meta: { identifier: id },
    payload: {
      ...payload,
      order_id: orderId,
    },
  };
};

/**
 * Sends a log entry to the server.
 *
 * @param logEntry
 */
export const log = (logEntry) => ({
  type: LOG_ENTRY,
  payload: logEntry,
});
