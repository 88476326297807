import PageModule from './PageModule';
import component from '../../containers/account/MyDashboard';
import {
  UI_BOOKING_OVERVIEW,
  UI_COLLECTION_CONSUMPTIONS,
  UI_COLLECTION_GDPR,
  UI_COLLECTION_SELFCARE_CONSENT_LAYER,
  UI_MY_FRIEND_REFERRAL_GLOBAL,
  UI_MY_SIMCARD_DATA,
} from '../../helpers/constants';

/**
 * The My Dashboard module maps the dashboard on
 * which the logged in user accesses the most important information.
 * @see https://confluence.db-n.com/x/HPTc
 */
class MyDashboardModule extends PageModule {
  constructor(moduleData) {
    super(component, moduleData);
  }
  getRequiredUi() {
    return [
      UI_COLLECTION_GDPR,
      UI_COLLECTION_CONSUMPTIONS,
      UI_COLLECTION_SELFCARE_CONSENT_LAYER,
      UI_MY_FRIEND_REFERRAL_GLOBAL,
      UI_MY_SIMCARD_DATA,
      UI_BOOKING_OVERVIEW,
    ];
  }
}

export default MyDashboardModule;

