import PropTypes from 'prop-types';

import { imageShape } from './media';

export const propTypes = {
  eid: PropTypes.string.isRequired,
  etype: PropTypes.oneOf(['downloadEntity', 'downloadSublist']),
  category: PropTypes.string,
  copy: PropTypes.string,
  date: PropTypes.string,
  file: PropTypes.shape({
    extension: PropTypes.string.isRequired,
    mimeType: PropTypes.string,
    size: PropTypes.number,
    url: PropTypes.string.isRequired,
  }),
  thumbnail: imageShape,
  type: PropTypes.oneOf(['pdf', 'image', 'text']),
};

export const shape = PropTypes.shape(propTypes);
export const list = PropTypes.arrayOf(shape);
