import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import applyRouterMiddleware from 'react-router/lib/applyRouterMiddleware';
import Router from 'react-router/lib/Router';

import scrollMiddleware from './routes/scrollMiddleware';
import routes from './routes/routes';

const App = ({ store, history }) => (
  <Provider store={store}>
    <Router
      key={
        // avoid warning when hot reloading by always set a different key
        // @see https://github.com/ReactTraining/react-router/issues/2704#issuecomment-253716411
        module.hot ? new Date() : 'router'
      }
      history={history}
      routes={routes(store)}
      render={applyRouterMiddleware(scrollMiddleware)}
    />
  </Provider>
);

App.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default App;
