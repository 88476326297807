import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  showDialog,
  addDialogToQueue,
  hideDialog,
  hideQueuedDialog,
  showAlert,
  hideAlert,
  showNotification,
  hideNotification,
} from '../../../actions/page/dialog';

class ServiceSiteProvider extends PureComponent {
  constructor(...args) {
    super(...args);
    this.getGlobalUi = this.getGlobalUi.bind(this);
    this.getInternalId = this.getInternalId.bind(this);
    this.getEntityId = this.getEntityId.bind(this);
    this.showDialog = this.showDialog.bind(this);
    this.addDialogToQueue = this.addDialogToQueue.bind(this);
    this.hideQueuedDialog = this.hideQueuedDialog.bind(this);
    this.hideDialog = this.hideDialog.bind(this);
    this.showAlert = this.showAlert.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.showNotification = this.showNotification.bind(this);
    this.hideNotification = this.hideNotification.bind(this);
    this.hasFeature = this.hasFeature.bind(this);
  }

  getChildContext() {
    const { sitemap, user, internalIdMap, ui } = this.props;
    return {
      siteProvider: {
        ui,
        user,
        sitemap,
        internalIdMap,
        getGlobalUi: this.getGlobalUi,
        getInternalId: this.getInternalId,
        getEntityId: this.getEntityId,
        showDialog: this.showDialog,
        addDialogToQueue: this.addDialogToQueue,
        hideDialog: this.hideDialog,
        hideQueuedDialog: this.hideQueuedDialog,
        showAlert: this.showAlert,
        hideAlert: this.hideAlert,
        showNotification: this.showNotification,
        hideNotification: this.hideNotification,
        hasFeature: this.hasFeature,
      },
    };
  }

  getGlobalUi() {
    return this.props.ui;
  }

  getInternalId(eid) {
    const { internalIdMap } = this.props;
    return Object.keys(internalIdMap).find(key => internalIdMap[key] === eid);
  }

  getEntityId(iid) {
    const { internalIdMap } = this.props;
    return internalIdMap[iid];
  }

  hasFeature(featureName) {
    const { features = {} } = this.props;
    return !!features[featureName];
  }

  showDialog(dialog) {
    this.props.showDialog(dialog);
  }

  addDialogToQueue(dialog) {
    this.props.addDialogToQueue(dialog);
  }

  hideDialog() {
    this.props.hideDialog();
  }

  hideQueuedDialog() {
    this.props.hideQueuedDialog();
  }

  showNotification(notification) {
    this.props.showNotification(notification);
  }

  hideNotification() {
    this.props.hideNotification();
  }

  showAlert(error) {
    this.props.showAlert(error);
  }

  hideAlert() {
    this.props.hideAlert();
  }

  render() {
    return this.props.children;
  }
}

ServiceSiteProvider.propTypes = {
  children: PropTypes.node.isRequired,
  features: PropTypes.object,
  user: PropTypes.object,
  sitemap: PropTypes.object,
  internalIdMap: PropTypes.object,
  ui: PropTypes.object.isRequired,
  showDialog: PropTypes.func.isRequired,
  addDialogToQueue: PropTypes.func.isRequired,
  hideDialog: PropTypes.func.isRequired,
  hideQueuedDialog: PropTypes.func.isRequired,
  showAlert: PropTypes.func.isRequired,
  hideAlert: PropTypes.func.isRequired,
  showNotification: PropTypes.func.isRequired,
  hideNotification: PropTypes.func.isRequired,
};

ServiceSiteProvider.childContextTypes = {
  siteProvider: PropTypes.shape({
    user: PropTypes.object.isRequired,
    sitemap: PropTypes.object.isRequired,
    internalIdMap: PropTypes.object.isRequired,
    ui: PropTypes.object.isRequired,
    getGlobalUi: PropTypes.func.isRequired,
    getInternalId: PropTypes.func.isRequired,
    getEntityId: PropTypes.func.isRequired,
    showDialog: PropTypes.func.isRequired,
    addDialogToQueue: PropTypes.func.isRequired,
    hideDialog: PropTypes.func.isRequired,
    hideQueuedDialog: PropTypes.func.isRequired,
    showAlert: PropTypes.func.isRequired,
    hideAlert: PropTypes.func.isRequired,
    showNotification: PropTypes.func.isRequired,
    hideNotification: PropTypes.func.isRequired,
  }),

};

const mapStateToProps = ({ environment, ui, site, user }) => ({
  features: (environment && environment.feature),
  sitemap: site && site.sitemap,
  internalIdMap: site && site.internalIdMap,
  ui,
  user: {
    isLoggedIn: !!user.credentials.msisdn,
    market: user.credentials.market,
    isContractRenewal: site.contractRenewal && site.contractRenewal.isInProgress,
    brandName: user.brandName,
  },
});

const mapDispatchToProps = {
  showDialog,
  addDialogToQueue,
  hideDialog,
  hideQueuedDialog,
  showAlert,
  hideAlert,
  showNotification,
  hideNotification,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ServiceSiteProvider);
