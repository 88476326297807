import { connect } from 'react-redux';

export default function createMatchMediaConnect({ breakPointSM, breakPointML }) {

  const mediaMapping = width => ({
    isMediaS: width < breakPointSM,
    isMediaSM: width < breakPointML,
    isMediaM: width >= breakPointSM && width < breakPointML,
    isMediaML: width >= breakPointSM,
    isMediaL: width >= breakPointML,
  });

  return function connectMatchMedia() {
    return function wrapWithConnect(WrappedComponent) {
      const mapStateToProps = ({ site }) => mediaMapping(site.clientWidth);
      return connect(mapStateToProps)(WrappedComponent);
    };
  };
}
