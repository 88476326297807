import { ACTION_PREFIX } from '../../helpers/constants';
import { trackRemoveItem } from '../tracking/event';

export const ADD_ORDER_ITEM = `${ACTION_PREFIX}/ADD_ORDER_ITEM`;
export const REMOVE_ORDER_ITEM = `${ACTION_PREFIX}/REMOVE_ORDER_ITEM`;
export const PATCH_ORDER = `${ACTION_PREFIX}/PATCH_ORDER`;
export const REGISTER_TARIFF_MODE = `${ACTION_PREFIX}/REGISTER_TARIFF_MODE`;

export function patchOrder({ ensureOrder = true, progressState, items }) {
  return {
    type: PATCH_ORDER,
    meta: { ensureOrder },
    payload: {
      progressState,
      entities: items,
    },
  };
}

export function addItem(item, persistent = false) {
  return {
    type: ADD_ORDER_ITEM,
    payload: {
      entity: item,
      persistent,
    },
  };
}

export const removeItem = (item) => (dispatch) => {
  dispatch(trackRemoveItem((item && item[0] && item[0].eid) || (item && item.eid)));
  dispatch({
    type: REMOVE_ORDER_ITEM,
    payload: {
      entity: item,
    },
  });
};

export const registerTariffMode = (tariffMode) => (dispatch) => {
  dispatch({
    type: REGISTER_TARIFF_MODE,
    payload: { tariffMode },
  });
};
