import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import shallowEqual from 'react-pure-render/shallowEqual';
import { connect } from 'react-redux';

import viewportActions from '../../actions/site/viewport';
import * as dialogActions from '../../actions/page/dialog';
import Lightbox from '../../components/basics/lightbox/Lightbox';
import GlobalDialog from '../../components/basics/global/GlobalDialog';
import { devWarning } from '../../helpers/meta';
import { DIALOG_TYPE_QUEUED, DIALOG_TYPE_CUSTOM } from '../../helpers/constants';
import AbstractError from '../../model/errors/AbstractError';
import suitcss from '../../helpers/suitcss';

class GlobalDialogs extends PureComponent {

  static getDerivedStateFromProps(props, state) {
    const { dialog } = props;
    const { dialog: currentDialog, isVisible, nextDialog } = state;
    if (nextDialog || shallowEqual(dialog, currentDialog)) {
      return null;
    }
    const delay = (dialog && dialog.props && dialog.props.delay) || 0;
    return {
      nextDialog: dialog,
      delay,
      isVisible: delay > 0 ? false : isVisible,
    };
  }

  constructor(props, context) {
    super(props, context);
    this.state = {
      isBusy: false,
      isVisible: false,
      dialog: null,
      nextDialog: null,
      dialogProps: {},
      delay: 0,
    };
    this.timeout = null;
    this.onClose = this.onClose.bind(this);
    this.onError = this.onError.bind(this);
    this.showCurrentDialog = this.showCurrentDialog.bind(this);
    this.onHideLightbox = this.onHideLightbox.bind(this);
    this.onHideDialog = this.onHideDialog.bind(this);
  }

  componentDidUpdate() {
    const { dialog } = this.props;
    const { dialog: currentDialog, nextDialog, delay, isBusy } = this.state;
    if (isBusy) { return; }

    if (!currentDialog && nextDialog) {
      this.queueNextDialog(delay);
    } else if (currentDialog && !shallowEqual(dialog, currentDialog)) {
      this.hideCurrentDialog();
    }
  }

  componentWillUnmount() {
    this.clearDelayTimeout();
  }

  /**
   * In case an exception is thrown in a dialog, we hide the dialog.
   * Moreover, if the error contains an error code, we will show this
   * in an alert.
   *
   * @param error
   */
  onError(error) {
    const { hideDialog, showAlert } = this.props;
    const { dialog } = this.state;
    hideDialog();
    this.props.toggleViewportBlocking();
    if (error instanceof AbstractError) {
      showAlert(error);
    }
    devWarning('Dialog crashed', dialog, error);
  }

  onClose() {
    const { hideDialog, hideQueuedDialog } = this.props;
    const { dialog } = this.state;

    if (!dialog) {
      return;
    }

    if (dialog.onClose) {
      dialog.onClose();
    }

    if (dialog.type === DIALOG_TYPE_QUEUED) {
      hideQueuedDialog();
    } else {
      hideDialog();
    }
  }

  onHideLightbox() {
    const { displayDialog, toggleViewportBlocking } = this.props;
    displayDialog(null);
    toggleViewportBlocking();
  }

  onHideDialog() {
    const { displayDialog, toggleViewportBlocking } = this.props;
    const { nextDialog } = this.state;
    if (nextDialog) {
      const { delay } = this.state;
      this.queueNextDialog(delay);
    } else {
      displayDialog(null);
      toggleViewportBlocking();
      this.setState({ isVisible: false, isBusy: false });
    }
  }

  clearDelayTimeout() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  queueNextDialog(delay) {
    if (!process.browser) {
      return;
    }
    this.clearDelayTimeout();
    this.setState({ isBusy: true }, () => {
      this.timeout = setTimeout(this.showCurrentDialog, delay);
    });
  }

  hideCurrentDialog() {
    this.setState({
      dialog: null,
      isBusy: true,
    });
  }

  showCurrentDialog() {
    const { nextDialog } = this.state;
    const { displayDialog, toggleViewportBlocking } = this.props;
    displayDialog(nextDialog);
    toggleViewportBlocking();
    this.setState({
      isVisible: true,
      dialog: nextDialog,
      dialogProps: (nextDialog && nextDialog.props) || {},
      dialogKey: Date.now(),
      nextDialog: null,
      isBusy: false,
    });
  }

  renderDialog(dialog, dialogProps, key) {
    const {
      theme,
      type,
      headline,
      copy,
      component: Component,
      withCloseAction,
      isExpanded,
    } = dialog;

    if (type === DIALOG_TYPE_CUSTOM) {
      return Component
        ? (
          <Component
            {...dialogProps}
            asGlobalDialogs
            key={key}
          />
        )
        : null;
    }

    const actions = (dialog.actions && dialog.actions.map(item => (
      !item.action ? { ...item, action: this.onClose } : item
    ))) || [];

    return (
      <GlobalDialog
        theme={theme}
        type={type}
        headline={headline}
        copy={copy}
        actions={actions}
        onError={this.onError}
        onClose={withCloseAction ? this.onClose : null}
        key={key}
        isExpanded={isExpanded}
      >
        {Component && (
          <Component
            {...dialogProps}
            asGlobalDialogs
          />
        )}
      </GlobalDialog>
    );
  }

  render() {
    const {
      isVisible,
      dialog,
      dialogProps,
      dialogKey,
    } = this.state;
    return (
      <Lightbox
        className={suitcss({}, this)}
        isVisible={isVisible}
        isVisibleWithoutChildren={false}
        alignH={dialogProps.alignH}
        alignV={dialogProps.alignV}
        eyecandy={dialogProps.eyecandy}
        withContainer={dialogProps.withContainer}
        onClose={dialog && dialog.withCloseAction ? this.onClose : null}
        onHideChild={this.onHideDialog}
        onHide={this.onHideLightbox}
        onHideDelay={0}
        withoutLightboxFadeout={dialog && dialog.withoutLightboxFadeout}
      >
        {dialog && this.renderDialog(dialog, dialogProps, dialogKey)}
      </Lightbox>
    );
  }
}

GlobalDialogs.propTypes = {
  dialog: PropTypes.object,
  showAlert: PropTypes.func.isRequired,
  hideDialog: PropTypes.func.isRequired,
  hideQueuedDialog: PropTypes.func.isRequired,
  toggleViewportBlocking: PropTypes.func.isRequired,
  displayDialog: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({ site }) => ({
  dialog: site.error || site.dialog || site.dialogs[0],
});

const mapDispatchToProps = {
  showAlert: dialogActions.showAlert,
  hideDialog: dialogActions.hideDialog,
  hideQueuedDialog: dialogActions.hideQueuedDialog,
  displayDialog: dialogActions.displayDialog,
  toggleViewportBlocking: viewportActions.toggleViewportBlocking,
};

export default connect(mapStateToProps, mapDispatchToProps)(GlobalDialogs);
