import { ACTION_PREFIX } from '../../helpers/constants';

export const REGISTER_FOOTNOTE_REFERENCE = `${ACTION_PREFIX}/REGISTER_FOOTNOTE_REFERENCE`;
export const UNREGISTER_FOOTNOTE_REFERENCE = `${ACTION_PREFIX}/UNREGISTER_FOOTNOTE_REFERENCE`;

export const registerFootnoteReference = id => ({
  type: REGISTER_FOOTNOTE_REFERENCE,
  payload: { id },
});

export const unregisterFootnoteReference = id => ({
  type: UNREGISTER_FOOTNOTE_REFERENCE,
  payload: { id },
});
