import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import suitcss from '../../../helpers/suitcss';
import { capitalize } from '../../../helpers/str';
import connectUI from '../ui/UIConnector';
import { hintShape, inputShape, metaShape } from '../../../propTypes/field';
import FieldMessenger from './FieldMessenger';

class FieldWrapper extends PureComponent {

  constructor(props, context) {
    super(props, context);
    this.getIsOptionalElement = this.getIsOptionalElement.bind(this);
    const isRequired = props.validation ? props.validation.isRequired : false;
    this.state = {
      isRequired,
      getIsOptionalElement: this.getIsOptionalElement,
    };
  }

  componentWillReceiveProps() {
    const { validation } = this.props;
    const isRequired = validation ? validation.isRequired : false;
    if (isRequired !== this.state.isRequired) {
      this.setState({ isRequired });
    }
  }

  getIsOptionalElement() {
    const { componentName, ui } = this.props;
    return (
      <span
        className={suitcss({
          componentName,
          descendantName: 'formOptional',
        })}
      >
        &nbsp;&#40;{ui.guiWordOptional}&#41;
      </span>
    );
  }

  getToolTipConfig() {
    const { input, meta, hint } = this.props;
    const errorMsg = (meta.touched && meta.error && meta.error.message) || '';
    const toolTipContent = errorMsg || (hint && !meta.valid)
      ? `${errorMsg ? `<p class="u-colorError">${errorMsg}</p>` : ''}${hint ? `<p>${hint}</p>` : ''}`
      : null;
    return {
      'data-tip': toolTipContent,
      'data-tip-pos': 'right',
      'data-tip-offset': 5,
      'data-tip-id': input.name,
    };
  }

  render() {
    const {
      componentName,
      meta,
      input,
      children,
      inverted,
      layout,
      theme,
      utilities,
      withToolTip,
      withMessenger,
      isReadOnly,
    } = this.props;

    const modifiers = [
      layout && `${layout}Layout`,
      theme && `theme${capitalize(theme)}`,
      meta.valid && meta.dirty && 'valid',
      meta.error && meta.touched && 'error',
      (meta.active || meta.dirty || input.value || meta.autofilled) && 'dirty',
      inverted && 'inverted',
      inverted && 'inverted',
      isReadOnly && 'readOnly',
      ...this.props.modifiers,
    ];

    const isHydrater = typeof children === 'function';
    const renderedChildren = isHydrater && children(this.state);
    const toolTipConfig = !isReadOnly && withToolTip ? this.getToolTipConfig() : {};
    return (
      <div
        className={suitcss({ componentName, modifiers, utilities })}
        data-field-name={input.name}
      >
        <div
          className={suitcss({ componentName, descendantName: 'wrapper' })}
          {...toolTipConfig}
        >
          {isHydrater && React.Children.only(renderedChildren)}
          {!isHydrater && children}
        </div>
        {!withToolTip && withMessenger && <FieldMessenger {...this.props} />}
      </div>
    );
  }
}

FieldWrapper.propTypes = {
  layout: PropTypes.string,
  componentName: PropTypes.string.isRequired,
  modifiers: PropTypes.array,
  validation: PropTypes.object,
  withToolTip: PropTypes.bool,
  withMessenger: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,
  ui: PropTypes.shape({
    guiSymbolStar: PropTypes.string.isRequired,
  }),
  label: PropTypes.string,
  hint: hintShape,
  input: inputShape,
  meta: metaShape,
  isDateGroup: PropTypes.bool,
  asStack: PropTypes.bool,
  theme: PropTypes.oneOf(['default', 'compact']),
  utilities: PropTypes.array,
  inverted: PropTypes.bool,
};

FieldWrapper.defaultProps = {
  modifiers: [],
  withMessenger: true,
};


export default connectUI()(FieldWrapper);
