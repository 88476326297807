import QueueableRequest from './QueueableRequest';
import TariffSummaryRequest from './TariffSummaryRequest';
import TariffsRequest from './TariffsRequest';
import { ACCOUNT_TARIFF_OPTIONS_ENDPOINT } from '../../config/api';
import { bindParamsToRoute } from '../../helpers/url';
import { blockContentBasedOnUserScope } from '../../helpers/scope';
import { SCOPE_MYDASHBOARD } from '../../helpers/constants';

/**
 * Fetches all bookable (or booked) options from the api.
 */
export default class TariffOptionsRequest extends QueueableRequest {
  constructor(msisdn, options = {}) {
    const url = bindParamsToRoute(ACCOUNT_TARIFF_OPTIONS_ENDPOINT, { msisdn });
    super(url, options);
  }

  /**
   * @inheritDoc
   */
  getSubsequentRequests({ user }) {
    return [
      new TariffSummaryRequest(user.credentials.msisdn),
      new TariffOptionsRequest(user.credentials.msisdn),
      new TariffsRequest(user.credentials.msisdn),
    ];
  }

  block(state) {
    return !blockContentBasedOnUserScope(state.user.scope, SCOPE_MYDASHBOARD);
  }
}

