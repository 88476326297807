import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import suitcss from '../../../../helpers/suitcss';
import { toDecimal, toFullCurrency, unitToFloat } from '../../../../helpers/money';
import Message from '../Message';
import Link from '../../../basics/text/TextLink';
import {
  MESSAGE_TYPE_OFFER,
  MESSAGE_SENDER_USER,
} from '../constants';

class BasarChatInputPanelPrice extends PureComponent {

  constructor(props, context) {
    super(props, context);
    this.onInputKeyUp = this.onInputKeyUp.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.submitOffer = this.submitOffer.bind(this);
  }

  onInputKeyUp(e) {
    const keyCode = e.keyCode || e.which;
    if (keyCode === 13) {
      // enter (13) has been pressed
      this.submitOffer();
    }
  }

  onInputChange() {
    const value = String(this.inputField.value).replace(/[^\d]/g, '').replace(/^0+/, '').substring(0, 5);
    this.inputField.value = value !== '' ? toDecimal({ unit: value }) : '';
    setTimeout(() => {
      this.inputField.setSelectionRange(this.inputField.value.length, this.inputField.value.length);
    }, 0);
  }

  submitOffer() {
    const { sendMessage, params, isDisabled } = this.props;
    const value = this.inputField.value.replace(/[^\d]/g, '').replace(/^0+/, '');
    if (!isDisabled && value.length) {
      const offerValue = unitToFloat(value);
      const price = toFullCurrency({ unit: value, currency: 'EUR' });
      sendMessage(
        new Message(MESSAGE_SENDER_USER, {
          type: MESSAGE_TYPE_OFFER,
          offerValue,
          text: `${params.ui.myOffer}: <strong><em>${price}</em></strong>`,
        }),
      );
      this.inputField.value = '';
    }
  }

  render() {
    const { componentName, params, placeHolder, isDisabled } = this.props;
    return (
      <div className={suitcss({ componentName, descendantName: 'footer' }, this)} >
        <div className={suitcss({ componentName, descendantName: 'form' }, this)} >
          <div className={suitcss({ componentName, descendantName: 'field' }, this)} >
            <label
              className={suitcss({
                componentName,
                descendantName: 'label',
              })}
              htmlFor={`${componentName}-input`}
            >
              {params.ui.btnLabelUserOffer}:
            </label>
            <input
              className={suitcss({
                componentName,
                descendantName: 'input',
              })}
              ref={domElement => { this.inputField = domElement; }}
              id={`${componentName}-input`}
              type="tel"
              placeholder={placeHolder}
              onKeyUp={this.onInputKeyUp}
              onInput={this.onInputChange}
              disabled={isDisabled}
              autoComplete="off"
            />
          </div>
          <Link
            asButton
            buttonFilled
            buttonWithoutLabel
            withArrow
            onClick={this.submitOffer}
            isDisabled={isDisabled}
            element="button"
          />
        </div>
      </div>
    );
  }
}

BasarChatInputPanelPrice.propTypes = {
  componentName: PropTypes.string.isRequired,
  sendMessage: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  placeHolder: PropTypes.string,
  isDisabled: PropTypes.bool,
};

BasarChatInputPanelPrice.defaultProps = {
  placeHolder: '00,00',
};

export default BasarChatInputPanelPrice;
