import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { hintShape, inputShape, metaShape } from '../../../propTypes/field';
import suitcss from '../../../helpers/suitcss';
import { capitalize } from '../../../helpers/str';
import FieldGroup from './FieldGroup';
import FieldSelectbox from './FieldSelectbox';

/**
 * @todo  We should replace selectboxIndex with proper constants like YEAR, MONTH and DAY
 * @todo  We should think about passing functions like onBlur and onFocus from input property
 *        into the select boxes
 * @todo  The default values for select boxes may be defined internally in this component because
 *        it expect a certain format and will not work without it. It also may get more comfortable
 *        to use the component.
 */
const DEFAULT_INPUT_VALUES = '0000-00-00'.split('-');
class FieldDate extends PureComponent {

  onSelectboxChange(evt, { selectboxIndex }) {
    const { input, meta } = this.props;
    const splitDate = input.value.split('-').length === 3 ?
      input.value.split('-') :
      DEFAULT_INPUT_VALUES.slice();

    splitDate[selectboxIndex] = evt.target.value;
    const isUnset = splitDate.some(value => parseInt(value, 10) === 0);
    if (!meta.touched && !isUnset) {
      input.onBlur();
    }
    input.onChange(splitDate.join('-'));
  }

  getSelectboxAdditionalProps({ selectboxIndex }) {
    const isDirty = this.isSelectboxDirty({ selectboxIndex });
    return {
      input: {
        value: this.getSelectboxValue({ selectboxIndex }),
        onChange: evt => this.onSelectboxChange(evt, { selectboxIndex }),
      },
      meta: {
        dirty: isDirty,
        error: this.props.meta.error,
        touched: this.props.meta.touched,
      },
    };
  }

  getSelectboxValue({ selectboxIndex }) {
    const { input } = this.props;
    if (this.isSelectboxDirty({ selectboxIndex })) {
      return input.value.split('-')[selectboxIndex];
    }
    return '';
  }

  isSelectboxDirty({ selectboxIndex }) {
    const { input } = this.props;
    const value = input.value.split('-')[selectboxIndex];
    return !!value && value !== DEFAULT_INPUT_VALUES[selectboxIndex];
  }

  render() {
    const {
      labelDay,
      labelMonth,
      labelYear,
      optionsDays,
      optionsMonths,
      optionsYears,
      inverted,
      theme,
      utilities,
    } = this.props;
    return (
      <div className={suitcss({ modifiers: [theme && `theme${capitalize(theme)}`], utilities }, this)} >
        <FieldGroup {...this.props} utilities={null} asGrid>
          <FieldSelectbox
            sublabel={labelDay}
            options={optionsDays}
            inverted={inverted}
            theme={theme}
            utilities={['col3']}
            {...this.getSelectboxAdditionalProps({ selectboxIndex: 2 })}
          />
          <FieldSelectbox
            sublabel={labelMonth}
            options={optionsMonths}
            inverted={inverted}
            theme={theme}
            utilities={['col5']}
            {...this.getSelectboxAdditionalProps({ selectboxIndex: 1 })}
          />
          <FieldSelectbox
            sublabel={labelYear}
            options={optionsYears}
            inverted={inverted}
            theme={theme}
            utilities={['col4']}
            {...this.getSelectboxAdditionalProps({ selectboxIndex: 0 })}
          />
        </FieldGroup>
      </div>
    );
  }
}

FieldDate.propTypes = {
  optionsDays: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  optionsMonths: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  optionsYears: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  labelDay: PropTypes.string.isRequired,
  labelMonth: PropTypes.string.isRequired,
  labelYear: PropTypes.string.isRequired,
  inverted: PropTypes.bool,
  label: PropTypes.string,
  hint: hintShape,
  input: inputShape,
  meta: metaShape,
  isDateGroup: PropTypes.bool,
  asStack: PropTypes.bool,
  theme: PropTypes.oneOf(['default', 'compact']),
  utilities: PropTypes.array,
};

FieldDate.defaultProps = {
  meta: {},
  input: { value: '' },
  theme: 'default',
};

export default FieldDate;
