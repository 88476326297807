import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';

import UIOverlayWrapper from '../ui/UIOverlayWrapper';
import Headline from '../text/TextHeadline';
import Copy from '../text/TextCopy';
import Link from '../text/TextLink';

class GlobalDialog extends PureComponent {
  renderLink(item, key) {
    const { onError } = this.props;
    const isUrl = typeof item.action === 'string';
    const data = item.track ? { mytracking: item.track } : {};
    const onClick = isUrl ? null : async () => {
      try {
        await item.action();
      } catch (e) {
        onError(e);
      }
    };
    return (
      <div
        className={suitcss({
          descendantName: 'link',
          modifiers: [!item.asLink && 'asButton'],
        }, this)}
        key={key}
      >
        <Link
          to={isUrl ? item.action : ''}
          onClick={onClick}
          element={!isUrl ? 'button' : null}
          asButton={!item.asLink}
          buttonFilled={!item.asLink && (item.buttonFilled || key === 0)}
          withoutArrow={item.withoutArrow || item.asLink}
          data={data}
        >
          {item.label}
        </Link>
      </div>
    );
  }
  renderCopy() {
    const { copy, theme } = this.props;
    const inverted = theme === 'dark';
    const isElement = typeof copy !== 'string';
    return (
      <div className={suitcss({ descendantName: 'copy' }, this)} >
        {isElement && copy}
        {!isElement && (<Copy inverted={inverted} embedded raw >{copy}</Copy>)}
      </div>
    );
  }
  render() {
    const { theme, headline, copy, actions, onClose, children, isExpanded } = this.props;
    const inverted = theme === 'dark';
    const withHorizontalNav = actions && actions.length <= 2 && !actions.some(
      action => action.expanded,
    );
    return (
      <UIOverlayWrapper
        className={suitcss({ modifiers: [withHorizontalNav && 'horizontalNav', isExpanded && 'expanded'] }, this)}
        theme={theme}
        onClose={onClose}
        withOverflow
      >
        <div className={suitcss({ descendantName: 'content' }, this)}>
          {headline && (
            <Headline inverted={inverted} embedded raw>{headline}</Headline>
          )}
          {copy && this.renderCopy()}
          {children && (
            <div className={suitcss({ descendantName: 'content' }, this)} >
              {children}
            </div>
          )}
          {actions.length > 0 && (
            <div className={suitcss({ descendantName: 'footer' }, this)} >
              {actions.map((item, index) => this.renderLink(item, index))}
            </div>
          )}
        </div>
      </UIOverlayWrapper>
    );
  }
}

GlobalDialog.propTypes = {
  theme: PropTypes.oneOf([
    'light',
    'medium',
    'dark',
    'cookieconsent',
    'cookieconsentsettings',
    'consentinquiry',
    'simarticlelist',
  ]),
  headline: PropTypes.string,
  copy: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      action: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
      ]),
      asLink: PropTypes.bool,
      track: PropTypes.string,
    }),
  ),
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
  ]),
  onError: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  isExpanded: PropTypes.bool,
};

GlobalDialog.defaultProps = {
  actions: [],
  theme: 'light',
};

export default GlobalDialog;
