import { ACTION_PREFIX } from '../../helpers/constants';

export const CONFIRM_ORDER = `${ACTION_PREFIX}/CONFIRM_ORDER`;

export function confirmOrder(id, payload) {
  return {
    type: CONFIRM_ORDER,
    meta: { identifier: id },
    payload,
  };
}
