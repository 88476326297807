import React from 'react';
import PropTypes from 'prop-types';

import GlobalSection from '../../components/basics/global/GlobalSection';
import HotlineDealsComposition from '../../components/compositions/modules/HotlineDeals';
import imageSizes from '../../config/imageSizes';

const HotlineDeals = ({ params }) => (
  <GlobalSection
    layout="contained"
    theme="dark"
    image={params.image}
    imageSizes={imageSizes.tariffDetailsBackground}
    imageAlign="left"
  >
    <HotlineDealsComposition
      headline={params.headline}
      copy={params.copy}
      items={params.questions}
      link={params.link}
      trackingId={params.trackingId}
      ui={params.ui}
    />
  </GlobalSection>
);


HotlineDeals.propTypes = {
  params: PropTypes.shape({
    headline: PropTypes.string.isRequired,
    copy: PropTypes.string.isRequired,
    questions: PropTypes.array.isRequired,
    image: PropTypes.object.isRequired,
    link: PropTypes.object.isRequired,
    trackingId: PropTypes.string.isRequired,
    ui: PropTypes.object.isRequired,
  }),
};

export default HotlineDeals;
