import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';

import TextDataList from '../../basics/text/TextDataList';

class ContractRenewalTariffInfo extends PureComponent {

  render() {
    const {
      items,
      inverted,
    } = this.props;

    return (
      <div className={suitcss({ utilities: [inverted && 'colorInverted'] }, this)}>
        <TextDataList items={items} withoutBreakpoints size="secondary" />
      </div>
    );
  }
}


ContractRenewalTariffInfo.propTypes = {
  items: PropTypes.array.isRequired,
  inverted: PropTypes.bool,
};

export default ContractRenewalTariffInfo;

