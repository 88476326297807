import React from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';

function SliderSlide(props) {
  return (
    <div
      className={suitcss({
        componentName: 'Slider',
        descendantName: 'slide',
        className: 'swiper-slide',
        modifiers: props.modifiers,
      })}
    >
      {props.children}
    </div>
  );
}

SliderSlide.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.array,
  ]),
  modifiers: PropTypes.array,
};

SliderSlide.defaultProps = {};

export default SliderSlide;
