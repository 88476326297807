import QueueableRequest from './QueueableRequest';
import SimReplStatusRequest from './SimReplStatusRequest';
import * as endpoints from '../../config/api';
import { bindParamsToRoute } from '../../helpers/url';
import { REQUEST_METHOD_POST } from '../../helpers/constants';

export default class SimReplActivationRequest extends QueueableRequest {
  constructor(msisdn) {
    super(
      bindParamsToRoute(endpoints.ACCOUNT_SIM_REPL_ACTIVATION_ENDPOINT, { msisdn }),
      { method: REQUEST_METHOD_POST },
    );
  }

  /**
   * @inheritDoc
   */
  getSubsequentRequests({ user }) {
    return [
      new SimReplStatusRequest(user.credentials.msisdn),
    ];
  }
}
