import { compose } from 'redux';

import EmailValidation
  from '../../components/compositions/birthdayValidation/EmailValidation';
import initForm from '../form/FormInitializer';
import connectSiteProvider from '../../components/basics/service/ServiceSiteConnector';
import dates from '../../helpers/dates';

const createFieldMap = (state, { ui }) => ({
  birthday: {
    name: 'birthday',
    label: ui.guiFormDob,
    labelDay: ui.guiFormDobDay,
    labelMonth: ui.guiFormDobMonth,
    labelYear: ui.guiFormDobYear,
    optionsDays: dates.days,
    optionsMonths: dates.months,
    optionsYears: dates.years,
    type: 'dob',
    validation: {
      isRequired: true,
      pattern: 'date',
    },
  },
});

// init component once, outside
const component = compose(
  connectSiteProvider(),
  initForm(),
)(EmailValidation);

export const id = 'validation';

/**
 * @return {FormConfig}
 */
export const mapStateToFormConfig = (state, props) => ({
  component,
  fieldMap: createFieldMap(state, props),
});

export default mapStateToFormConfig;
