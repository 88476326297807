import React, { PureComponent } from 'react';
import suitcss from '../../../helpers/suitcss';

import { navLinkList } from '../../../propTypes/navigation';

import Link from '../../basics/text/TextLink';
import Accordion from '../../basics/accordion/Accordion';
import AccordionSection from '../../basics/accordion/AccordionSection';

class SitemapAccordionNavigation extends PureComponent {
  constructor(...args) {
    super(...args);
    this.state = {};
  }

  renderItem(item, key) {
    return (
      <div
        className={suitcss({
          componentName: 'SitemapLinkNavigation',
          descendantName: 'section',
        })}
        key={key}
      >
        {this.renderLink(item)}
        {item.children.map((childItem, index) => this.renderLink(childItem, index))}
      </div>
    );
  }

  renderLink(item, index) {
    return (
      <div
        className={suitcss({
          componentName: 'SitemapLinkNavigation',
          descendantName: 'link',
        })}
        key={index}
      >
        <Link to={item.url} >
          {item.title}
        </Link>
      </div>
    );
  }

  render() {
    const { items } = this.props;
    return (
      <nav
        className={suitcss({
          componentName: 'SitemapLinkNavigation',
        })}
      >
        <Accordion>
          {items.map((item, index) => (
            <AccordionSection
              label={item.title.toUpperCase()}
              underlined
              key={index}
            >
              {this.renderItem(item)}
            </AccordionSection>
          ))}
        </Accordion>
      </nav>
    );
  }
}

SitemapAccordionNavigation.propTypes = {
  items: navLinkList.isRequired,
};

export default SitemapAccordionNavigation;
