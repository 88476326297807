import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import shallowEqual from 'react-pure-render/shallowEqual';
import raf from 'raf';
import { getDisplayName } from '../../../helpers/component';

const connectViewport = ({ listenTo }) => (WrappedComponent) => {
  const updateScrollState = listenTo.includes('scroll');
  const updateClientState = listenTo.includes('client');
  class ServiceViewportConnector extends PureComponent {
    constructor(...args) {
      super(...args);
      this.state = {
        scroll: updateScrollState && this.context.scroll
          ? this.context.scroll()
          : { x: 0, y: 0 },
        client: updateClientState && this.context.client
          ? this.context.client()
          : { width: 0, height: 0 },
      };
      this.updateState = this.updateState.bind(this);
      this.updateScrollState = this.updateScrollState.bind(this);
      this.updateClientState = this.updateClientState.bind(this);
    }

    componentDidMount() {
      this.updateState();
    }

    componentWillUnmount() {
      if (this.rafId) {
        raf.cancel(this.rafId);
      }
    }

    tick(updater) {
      this.rafId = raf(() => {
        updater();
        this.tick(updater);
      });
    }

    updateState() {
      this.tick(() => {
        if (updateScrollState) { this.updateScrollState(); }
        if (updateClientState) { this.updateClientState(); }
      });
    }

    updateClientState() {
      const client = this.context.client();
      if (shallowEqual(client, this.state.client)) {
        return;
      }
      this.setState({ client });
    }

    updateScrollState() {
      const scroll = this.context.scroll();
      if (shallowEqual(scroll, this.state.scroll)) {
        return;
      }
      this.setState({ scroll });
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          scroll={this.state.scroll}
          client={this.state.client}
        />
      );
    }
  }

  ServiceViewportConnector.displayName = `ServiceViewportConnector(${getDisplayName(WrappedComponent)})`;
  ServiceViewportConnector.WrappedComponent = WrappedComponent;
  ServiceViewportConnector.contextTypes = {
    scroll: PropTypes.func.isRequired,
    client: PropTypes.func.isRequired,
  };

  return ServiceViewportConnector;
};

export default connectViewport;
