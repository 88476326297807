/* global window */

import { replace, push } from 'react-router-redux';

import { fetchMyPromotions, fetchPromoBanners } from '../request/registry';
import { addDialogToQueue } from '../page/dialog';
import Dialog from '../../model/Dialog';
import {
  EVENT_PAGE_REDIRECT,
  EVENT_PROMO_BOOKED_SUCCESS,
  META_MESSAGE_TYPE_DIALOG,
  META_MESSAGE_TYPE_PAGE,
} from '../../helpers/constants';
import RedirectError from '../../model/errors/RedirectError';
import PageRequest from '../../model/requests/PageRequest';

/**
 * Handles the response's meta before the response is further processed.
 *
 * @param {Object} meta
 * @param {QueueableRequest} request
 * @return {Promise}
 * @throws RedirectError
 */
export const handleMeta = (meta, request) => async (dispatch, getState) => {
  const { messages = [], events = [] } = meta;

  // https://jira.db-n.com/browse/OT-5666
  const redirectEvent = events.find(e => e.type === EVENT_PAGE_REDIRECT);
  if (redirectEvent) {
    const { location } = redirectEvent.payload;
    if (!/^https?:\/\//i.test(location) || (process.browser && location.includes(window.location.hostname))) {
      if (process.browser) {
        // assuming redirect within same domain
        await dispatch(replace(location));
      }
      if (request instanceof PageRequest) {
        throw new RedirectError(redirectEvent.payload); // will abort further page processing
      }
    } else {
      if (process.browser) {
        window.dbn.open(location);
      } else {
        throw new RedirectError(redirectEvent.payload);
      }
    }
  }

  messages.forEach(message => {
    switch (message.type) {
      case META_MESSAGE_TYPE_PAGE:
        dispatch(push(message.uri));
        break;
      case META_MESSAGE_TYPE_DIALOG: {
        const dialog = dispatch(Dialog.createFromApiModel(message));
        dispatch(addDialogToQueue(dialog));
        break;
      }
      default:
        break;
    }
  });

  if (events.some(e => e.type === EVENT_PROMO_BOOKED_SUCCESS)) {
    // some promos were booked transparently in the backend,
    // hence we need to update our dashboard.
    const { site } = getState();
    if (!messages.some(msg => msg.type === META_MESSAGE_TYPE_PAGE)) {
      dispatch(push(site.sitemap.MyDashboardRoute.url));
    }
    dispatch(fetchMyPromotions({}, true));
    dispatch(fetchPromoBanners({}, true));
  }
};
