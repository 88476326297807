/* global document, window */
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { removePrefixFromKeys } from '../../../helpers/identifier';
import { changeScreenSize } from '../../../actions/site/viewport';

/**
 * Collects information in the browser to define the current state of the window
 * and stores in context to update child components
 */
class ServiceViewportProvider extends PureComponent {
  constructor(...args) {
    super(...args);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.state = {
      scrollX: 0,
      scrollY: 0,
      scrollTurningPointX: 0,
      scrollTurningPointY: 0,
      scrollTurningPointDiffX: 0,
      scrollTurningPointDiffY: 0,
      scrollHorizontalDirection: null,
      scrollVerticalDirection: null,
      clientWidth: 0,
      clientHeight: 0,
    };
  }

  getChildContext() {
    const scroll = () => removePrefixFromKeys(this.state, 'scroll');
    scroll.x = () => this.state.scrollX;
    scroll.y = () => this.state.scrollY;
    scroll.turningPointX = () => this.state.scrollTurningPointX;
    scroll.turningPointY = () => this.state.scrollTurningPointY;
    scroll.turningPointDiffX = () => this.state.scrollTurningPointDiffX;
    scroll.turningPointDiffY = () => this.state.scrollTurningPointDiffY;
    scroll.horizontalDirection = () => this.state.scrollHorizontalDirection;
    scroll.verticalDirection = () => this.state.scrollVerticalDirection;

    const client = () => removePrefixFromKeys(this.state, 'client');
    client.width = () => this.state.clientWidth;
    client.height = () => this.state.clientHeight;
    return { scroll, client };
  }

  componentDidMount() {
    this.handleScroll();
    this.handleResize();
    // @see https://developers.google.com/web/updates/2016/06/passive-event-listeners
    document.addEventListener('scroll', this.handleScroll, { passive: true });
    window.addEventListener('resize', this.handleResize, false);
    window.addEventListener('orientationchange', this.handleResize, false);
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.handleScroll, { passive: true });
    window.removeEventListener('resize', this.handleResize, false);
    window.removeEventListener('orientationchange', this.handleResize, false);
  }

  handleResize() {
    this.props.dispatch(changeScreenSize({
      width: window.innerWidth,
    }));
    this.setState({
      clientWidth: window.innerWidth,
      clientHeight: window.innerHeight,
    });
  }

  handleScroll() {
    const { scrollX, scrollY } = ServiceViewportProvider.getClientScroll();
    const {
      scrollX: lastScrollX,
      scrollY: lastScrollY,
      scrollHorizontalDirection: lastScrollHorizontalDirection,
      scrollVerticalDirection: lastScrollVerticalDirection,
    } = this.state;

    let scrollHorizontalDirection = scrollX < lastScrollX ? 'left' : 'right';
    if (scrollY === lastScrollY) {
      scrollHorizontalDirection = lastScrollHorizontalDirection === 'left' ? 'right' : 'left';
    }
    let scrollVerticalDirection = scrollY < lastScrollY ? 'up' : 'down';
    if (scrollY === lastScrollY) {
      scrollVerticalDirection = lastScrollVerticalDirection === 'up' ? 'down' : 'up';
    }

    let scrollTurningPointX = this.state.scrollTurningPointX;
    if (scrollHorizontalDirection !== lastScrollHorizontalDirection) {
      scrollTurningPointX = scrollX;
    }
    let scrollTurningPointY = this.state.scrollTurningPointY;
    if (scrollVerticalDirection !== lastScrollVerticalDirection) {
      scrollTurningPointY = scrollY;
    }

    const scrollTurningPointDiffX = scrollX - scrollTurningPointX;
    const scrollTurningPointDiffY = scrollY - scrollTurningPointY;

    this.setState({
      scrollX,
      scrollY,
      scrollHorizontalDirection,
      scrollVerticalDirection,
      scrollTurningPointX,
      scrollTurningPointY,
      scrollTurningPointDiffX,
      scrollTurningPointDiffY,
    });
  }

  render() {
    return this.props.children;
  }
}

ServiceViewportProvider.getClientScroll = () => {
  let { scrollX, scrollY } = window;
  if (scrollX === undefined) {
    scrollX = window.pageXOffset;
  }
  if (scrollY === undefined) {
    scrollY = window.pageYOffset;
  }

  return {
    scrollX: parseInt(scrollX, 10),
    scrollY: parseInt(scrollY, 10),
  };
};

ServiceViewportProvider.propTypes = {
  children: PropTypes.node.isRequired,
  dispatch: PropTypes.func.isRequired,
};

ServiceViewportProvider.childContextTypes = {
  scroll: PropTypes.func.isRequired,
  client: PropTypes.func.isRequired,
};

export default connect()(ServiceViewportProvider);
