import React from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';

import Copy from '../../basics/text/TextCopy';
import Link from '../../basics/text/TextLink';

const componentName = 'LandingPage';

function LandingPage(props) {
  const {
    headline,
    link,
    copy,
    hint,
  } = props;

  return (
    <div className={suitcss({ componentName })}>
      <div className={suitcss({ componentName, descendantName: 'headline' })}>
        <Copy raw inverted>{headline}</Copy>
      </div>
      <div className={suitcss({ componentName, descendantName: 'copy' })}>
        <Copy raw embedded inverted>{copy}</Copy>
      </div>
      <div className={suitcss({ componentName, descendantName: 'footer' })}>
        <div className={suitcss({ componentName, descendantName: 'hint' })}>
          <Copy raw embedded inverted>{hint}</Copy>
        </div>
        <div className={suitcss({ componentName, descendantName: 'link' })}>
          <Link to={link.url} asButton buttonFilled>{link.title}</Link>
        </div>
      </div>
    </div>
  );
}

LandingPage.propTypes = {
  headline: PropTypes.string.isRequired,
  copy: PropTypes.string.isRequired,
  link: PropTypes.object.isRequired,
  hint: PropTypes.string.isRequired,
};

export default LandingPage;
