import { push } from 'react-router-redux';

import { showDialog, hideDialog } from '../page/dialog';
import { trackMyState } from '../tracking/event';
import { QUERY_EDIT, QUERY_CUSTOMER_DATA, MYTRACK_SERVICE_SIMACT_UNAVAILABLE } from '../../helpers/constants';
import { bindQueryParams } from '../../helpers/url';

export const showServiceUnavailableDialog = () => (dispatch, getState) => {
  const { site, ui } = getState();
  dispatch(showDialog({
    headline: ui.mySimrepOrderfailTitle,
    copy: ui.txtMySimrepOrderfailCopy,
    actions: [
      {
        label: ui.guiWordAllright,
        withoutArrow: true,
      }],
  }));
  dispatch(push(site.sitemap.MyDashboardRoute.url));
  dispatch(trackMyState(MYTRACK_SERVICE_SIMACT_UNAVAILABLE));
};

export const showLeavingProcessDialog = () => (dispatch, getState) => {
  const { site, ui } = getState();
  dispatch(showDialog({
    headline: ui.mySimrepEarlyExitHeadline,
    copy: ui.mySimrepEarlyExitCopy,
    actions: [
      {
        label: ui.mySimrepEarlyExitButtonOk,
        action: () => {
          dispatch(push(`${bindQueryParams(site.sitemap.MyDataRoute.url, { [QUERY_EDIT]: true })}#${QUERY_CUSTOMER_DATA}`));
          dispatch(hideDialog());
        },
      },
      {
        label: ui.guiWordCancel,
        asLink: true,
      },
    ],
  }));
};

