import QueueableRequest from './QueueableRequest';
import { EECC_TARIFFOPTIONS_DELETE_CONFIRM } from '../../config/api';
import { REQUEST_METHOD_POST } from '../../helpers/constants';
import { bindParamsToRoute } from '../../helpers/url';

/**
 *
 *
 */

export default class EECCTariffOptionsDeleteConfirmRequest extends QueueableRequest {
  constructor(msisdn, processId, payload) {
    super(bindParamsToRoute(EECC_TARIFFOPTIONS_DELETE_CONFIRM, { msisdn, processId }), {
      method: REQUEST_METHOD_POST,
      payload,
    });
  }
}
