import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';

import { textToCopyComponents } from '../../../helpers/parser';
import Headline from '../../basics/text/TextHeadline';
import Copy from '../../basics/text/TextCopy';
import Info from '../../basics/text/TextInfo';


class FormHeader extends PureComponent {
  render() {
    const { headline, subline, copy, info, utilities, ui, isExpanded, onClick } = this.props;
    return (
      <header className={suitcss({ utilities, modifiers: [!isExpanded && 'maxwidth'] }, this)} onClick={onClick} >
        {subline && (
          <Copy className={suitcss({ descendantName: 'subline' }, this)} embedded raw >
            {subline}
          </Copy>
        )}
        {headline && (
          <Headline utilities={['colorPrimary']} size="s" embedded raw >
            {headline}
          </Headline>
        )}
        {(copy || info) && (
          <div className={suitcss({ descendantName: 'copy' }, this)} >
            {copy && textToCopyComponents(copy, ui)}
            {info && (<Info {...info} />)}
          </div>
        )}
      </header>
    );
  }
}

FormHeader.propTypes = {
  headline: PropTypes.string,
  subline: PropTypes.string,
  copy: PropTypes.string,
  info: PropTypes.shape({
    label: PropTypes.string,
    headline: PropTypes.string,
    copy: PropTypes.string,
  }),
  utilities: PropTypes.array,
  onClick: PropTypes.func,
  ui: PropTypes.object,
  isExpanded: PropTypes.bool,
};

export default FormHeader;
