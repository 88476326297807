import React from 'react';
import PropTypes from 'prop-types';

import suitcss from '../../../../helpers/suitcss';
import { pickRandom } from '../../../../helpers/random';
import Message from '../Message';
import Link from '../../../basics/text/TextLink';
import {
  MESSAGE_TYPE_DECLINE_OFFER,
  MESSAGE_TYPE_ACCEPT_OFFER,
  MESSAGE_SENDER_USER,
} from '../constants';


const ChatControlButtons = (props) => {
  const { componentName, sendMessage, params } = props;
  return (
    <div className={suitcss({ componentName, descendantName: 'footer' }, this)} >
      <Link
        withoutArrow
        element="button"
        asButton
        onClick={() => {
          // this message will signal the chatbot that the user wants to continue;
          // it also carries a "user" message that will be displayed in the chat's
          // messages window
          sendMessage(
            new Message(MESSAGE_SENDER_USER, {
              type: MESSAGE_TYPE_DECLINE_OFFER,
              text: pickRandom(params.messages.decline),
            }),
          );
        }}
      >
        {params.ui.btnLabelDecline}
      </Link>
      <Link
        element="button"
        asButton
        buttonFilled
        onClick={() => {
          // message does not include a text; its purpose is to signal
          // the chatbot that the user accepted the offer
          sendMessage(
            new Message(MESSAGE_SENDER_USER, { type: MESSAGE_TYPE_ACCEPT_OFFER }),
          );
        }}
      >
        {params.ui.btnLabelAccept}
      </Link>
    </div>
  );
};

ChatControlButtons.propTypes = {
  componentName: PropTypes.string.isRequired,
  sendMessage: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
};

export default ChatControlButtons;
