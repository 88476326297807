import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';
import UIOverlayWrapper from '../../basics/ui/UIOverlayWrapper';
import HotlineOptionsDialogItem from './HotlineOptionsDialogItem';

class HotlineOptionsDialog extends PureComponent {
  render() {
    const { ui, actions } = this.props;
    return (
      <UIOverlayWrapper
        className={suitcss({}, this)}
        theme="light"
        withoutPadding
        onClose={actions[0].action}
      >
        <ul className={suitcss({ descendantName: 'hotlineOptions' }, this)}>
          <HotlineOptionsDialogItem
            className={suitcss({ descendantName: 'options' }, this)}
            parent={this}
            headline={ui.fcbOrderHotlineHeadline}
            number={ui.fcbOrderHotlineNumber}
            copy={ui.fcbOrderHotlineCopy}
          />
          <HotlineOptionsDialogItem
            parent={this}
            headline={ui.fcbCustomerHotlineHeadline}
            number={ui.fcbCustomerHotlineNumber}
            copy={ui.fcbCustomerHotlineCopy}
          />
        </ul>
      </UIOverlayWrapper>
    );
  }
}

HotlineOptionsDialog.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      action: PropTypes.function,
    }),
  ).isRequired,
  onClose: PropTypes.func,
  ui: PropTypes.shape({
    txtServiceLayerCopy: PropTypes.string.isRequired,
  }).isRequired,
};

HotlineOptionsDialog.defaultProps = {
  actions: [],
};

const mapStateToProps = ({ ui }) => ({ ui });

export default connect(mapStateToProps)(HotlineOptionsDialog);
