import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import RouterLink from 'react-router/lib/Link';

import Headline from '../../basics/text/TextHeadline';
import Copy from '../../basics/text/TextCopy';
import ProcessHeaderPrice from './ProcessHeaderPrice';
import TextLink from '../../basics/text/TextLink';

import connectUI from '../../basics/ui/UIConnector';
import matchMediaConnector from '../../../containers/service/ServiceMatchMedia';
import suitcss from '../../../helpers/suitcss';
import {
  ENTITY_TYPE_HARDWARE,
  ENTITY_TYPE_TARIFF,
  PAYMENT_FEE_MONTHLY,
  PAYMENT_FEE_MONTHLY_STRIKE,
} from '../../../helpers/constants';
import { sum } from '../../../helpers/money';
import Callout from '../../../containers/callout/Callout';

function getStrikePrice(paymentFees, additionalFees) {
  if (additionalFees.paymentFeeStrike) {
    if (paymentFees.paymentFeeStrike) {
      return (
        sum(paymentFees.paymentFeeStrike, additionalFees.paymentFeeStrike)
      );
    }
    return sum(additionalFees.paymentFeeStrike, paymentFees.paymentFee);
  } else if (paymentFees.paymentFeeStrike) {
    if (additionalFees.paymentFee) {
      return sum(paymentFees.paymentFeeStrike, additionalFees.paymentFee);
    }
    return paymentFees.paymentFeeStrike;
  }
  return null;
}

const componentName = 'ProcessHeaderItem';

function ProcessHeaderItem(props) {
  const {
    ui,
    shouldDisplayLink,
    additionalPaymentFee,
    hardwareGroup,
    item,
    isMediaS,
    isMediaM,
    removeItem,
    isHardwareTariffPath,
    hardwareSelected,
    isTariffPath,
    isContractRenewal,
    context,
    isSelected,
    modifiers,
  } = props;

  const {
    etype,
    headline,
    subline,
    urlChange,
    eid,
  } = item;

  const isHardwareSelected = !!hardwareSelected.selectedHardware;
  const isTariffEntity = etype === ENTITY_TYPE_TARIFF;
  const isHardwareEntity = etype === ENTITY_TYPE_HARDWARE;
  const paymentFee = additionalPaymentFee.paymentFee
    ? sum(item.paymentFee, additionalPaymentFee.paymentFee)
    : item.paymentFee;

  const paymentFeeStrike = (
    getStrikePrice(
      {
        [PAYMENT_FEE_MONTHLY]: item.paymentFee,
        [PAYMENT_FEE_MONTHLY_STRIKE]: item.paymentFeeStrike,
      },
      { ...additionalPaymentFee },
    )
  );

  const cutUrlQuery = (url) => (url.includes('?') ? urlChange.split('?')[0] : url);

  return (
    <div
      className={suitcss({
        componentName,
        modifiers,
        states: [shouldDisplayLink && (!isContractRenewal || isHardwareEntity) && 'hoverable', isSelected && 'selected'],
      })}
      data-type={isTariffEntity ? 'tariff' : 'hardware'}
    >
      <Callout
        utilities={['block', 'smCol12']}
        targets={[isTariffEntity ? eid : hardwareGroup.eid]}
        theme="sticker"
        inverted
        isHardwareSelected={isHardwareSelected}
        context={context}
      />
      <div className={suitcss({ componentName, descendantName: 'header' })}>
        <Headline size="s" raw embedded element="h2" bold>
          {headline}
        </Headline>
        {isTariffEntity && subline &&
          <Headline
            size="xs"
            element="p"
            embedded
            utilities={[
              'colorPrimary',
              'elementIndentBefore',
            ]}
          >
            {subline}
          </Headline>
        }
        {!isTariffEntity && subline &&
          <Copy embedded >
            {subline}
          </Copy>
        }
      </div>
      <div className={suitcss({ componentName, descendantName: 'content' })}>
        <ProcessHeaderPrice
          paymentFee={paymentFee}
          singlePaymentFee={item.singlePaymentFee}
          paymentFeeStrike={paymentFeeStrike}
          singlePaymentFeeStrike={item.singlePaymentFeeStrike}
          modifiers={['list']}
          ui={ui}
        />
      </div>
      {shouldDisplayLink && (!isContractRenewal || isHardwareEntity) &&
        <RouterLink
          onClick={isHardwareEntity && !isTariffPath ? removeItem : null}
          to={isHardwareEntity ? cutUrlQuery(urlChange) : urlChange}
          className={suitcss({ componentName, descendantName: 'cta' })}
        >
          {isHardwareTariffPath && isHardwareSelected &&
          <TextLink
            element="span"
            utilities={['colorInverted']}
          >
            {isHardwareEntity ? ui.nprRemoveHardware : ui.callToActionLabel}
          </TextLink>}
          {!isHardwareTariffPath &&
          <TextLink
            element="span"
            utilities={['colorInverted']}
          >
            {isHardwareEntity ? ui.nprChangeHardware : ui.callToActionLabel}
          </TextLink>}
          {isTariffEntity && !isHardwareSelected && isHardwareTariffPath &&
          <TextLink
            element="span"
            utilities={['colorInverted']}
          >{ui.myVvlChooseDifferentTariff}
          </TextLink>}
        </RouterLink>
      }
      {(isMediaS || isMediaM) && (!isContractRenewal || isHardwareEntity) &&
      <RouterLink
        onClick={isHardwareEntity && !isTariffPath ? removeItem : null}
        to={isHardwareEntity ? cutUrlQuery(urlChange) : urlChange}
      >
        {isHardwareTariffPath &&
        <TextLink
          element="span"
          className={suitcss({ componentName, descendantName: 'mobileLink' })}
        >{isHardwareEntity ? ui.nprRemoveHardware : ui.callToActionLabel}
        </TextLink>}
        {!isHardwareTariffPath &&
        <TextLink
          element="span"
        >
          {isHardwareEntity ? ui.nprChangeHardware : ui.callToActionLabel}
        </TextLink>}
      </RouterLink>
      }
    </div>
  );
}

ProcessHeaderItem.propTypes = {
  item: PropTypes.shape({
    eid: PropTypes.string.isRequired,
    etype: PropTypes.oneOf([
      'hardwareEntity',
      'tariffEntity',
      'tariffOptionEntity',
    ]).isRequired,
    headline: PropTypes.string.isRequired,
    subline: PropTypes.string,
    paymentFee: PropTypes.object.isRequired,
    paymentFeeStrike: PropTypes.object,
    singlePaymentFee: PropTypes.object.isRequired,
    singlePaymentFeeStrike: PropTypes.object,
    urlChange: PropTypes.string,
  }),
  ui: PropTypes.object.isRequired,
  modifiers: PropTypes.array,
  shouldDisplayLink: PropTypes.bool,
  additionalPaymentFee: PropTypes.object,
  hardwareGroup: PropTypes.object,
  isMediaS: PropTypes.bool.isRequired,
  isMediaM: PropTypes.bool.isRequired,
  removeItem: PropTypes.func.isRequired,
  isHardwareTariffPath: PropTypes.bool,
  hardwareSelected: PropTypes.object,
  isTariffPath: PropTypes.bool,
  isContractRenewal: PropTypes.bool,
  context: PropTypes.string,
  isSelected: PropTypes.bool,
};

ProcessHeaderItem.defaultProps = {
  modifiers: [],
  shouldDisplayLink: false,
  additionalPaymentFee: {},
};

export default compose(
  connectUI(),
  matchMediaConnector(['isMediaS', 'isMediaM']),
)(ProcessHeaderItem);
