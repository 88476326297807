import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import RouterLink from 'react-router/lib/Link';
import oneLine from 'common-tags/lib/oneLine';
import suitcss from '../../../helpers/suitcss';
import connectUI from '../../basics/ui/UIConnector';
import {
  getHardwareTariffSelectedLink,
  getTariffHardwareRelation,
  getBundleSinglePaymentFee,
  getBundlePaymentFee,
} from '../../../helpers/entity';
import { shape as hardwareShape } from '../../../propTypes/hardware';
import { shape as tariffShape } from '../../../propTypes/tariff';
import Headline from '../../basics/text/TextHeadline';
import Link from '../../basics/text/TextLink';
import Unit from '../../basics/text/TextUnit';
import MediaImage from '../../basics/media/MediaImage';
import SvgLoader from '../../basics/media/MediaSvgLoader';
import Callout from '../../../containers/callout/Callout';
import imageSizes from '../../../config/imageSizes';

import HardwarePaymentUnit from '../../../containers/hardware/HardwarePaymentUnit';
import Tag from '../../basics/text/Tag';
import { PAYMENT_FEE_SINGLE } from '../../../helpers/constants';


class TeaserProduct extends PureComponent {

  renderBundle() {
    const { name, hardware, tariff, ui, inverted, isNew } = this.props;
    const { teaserProduct: sizes } = imageSizes;
    const paymentFee = getBundlePaymentFee({ hardware, tariff });
    const singlePaymentFee = getBundleSinglePaymentFee({ hardware, tariff });
    const singlePaymentPrefix = tariff && oneLine`
      ${ui.guiSymbolPlus} ${ui.guiWordSingular}
    `;
    const linkTo = getHardwareTariffSelectedLink({
      title: ui.guiWordDetails,
      hardware,
      tariff,
    });
    const hardwareImage = {
      ...hardware.image,
      sizes: sizes.bundle || {},
    };

    return (
      <div
        className={suitcss({
          descendantName: 'inner',
          utilities: ['flex', 'sDirColumn', 'sJustifyCenter', 'sAlignCenter'],
        }, this)}
      >
        <div className={suitcss({ descendantName: 'image', utilities: ['mlSelfEnd', 'mlMarginRight'] }, this)} >
          <Callout
            theme="sticker"
            inverted
            targets={[
              tariff && tariff.eid,
              hardware.groupId,
            ]}
          />
          <RouterLink className={suitcss({ utilities: ['relative', 'inlineBlock'] })} to={linkTo.url}>
            <MediaImage {...hardwareImage} isLazy />
            {this.renderCallout()}
          </RouterLink>
        </div>
        <div className={suitcss({ descendantName: 'content', utilities: ['flex', 'dirColumn', 'paddingTop'] }, this)} >
          <div className={suitcss({ utilities: ['block'] })} >
            <Headline
              className={suitcss({ descendantName: 'hardwareName' }, this)}
              size="s"
              embedded
              inverted={inverted}
            >
              {name}
              {isNew &&
                <React.Fragment>
                  &nbsp;
                  <Tag theme="tertiary" label={ui.guiWordNew} />
                </React.Fragment>
              }
            </Headline>
          </div>
          <div className={suitcss({ descendantName: 'tariff', utilities: ['marginBottomS'] }, this)} >
            <div className={suitcss({ descendantName: 'title', utilities: ['inlineBlock'] }, this)} >
              <div className={suitcss({ descendantName: 'iconPlus', utilities: ['marginVS'] }, this)} >
                <SvgLoader path="/icons/content-plus.svg" />
              </div>
              <Headline
                className={suitcss({ descendantName: 'type', utilities: ['marginBottomXXS'] }, this)}
                size="s"
                embedded
                inverted={inverted}
                raw
              >
                {tariff.type}
              </Headline>
            </div>
            <Headline
              className={suitcss({ descendantName: 'name' }, this)}
              size="m"
              embedded
              utilities={['weightBold', 'colorPrimary']}
              inverted={inverted}
              raw
            >
              {tariff.name}
            </Headline>
          </div>
          <div className={suitcss({ descendantName: 'subcontent', utilities: ['marginTopAuto'] }, this)} >
            <div className={suitcss({ descendantName: 'paymentFee' }, this)} >
              <Unit
                size="m"
                price={paymentFee}
                postfix={ui.guiWordMonthlyAbbr}
                inverted={inverted}
              />
            </div>
            <div className={suitcss({ descendantName: 'singlePaymentFee', utilities: ['marginTopS'] }, this)} >
              <Unit
                price={singlePaymentFee}
                prefix={singlePaymentPrefix}
                inverted={inverted}
              />
            </div>
            <Link
              className={suitcss({ descendantName: 'link', utilities: ['marginTopS'] }, this)}
              to={linkTo.url}
              asButton
              buttonFilled
            >
              {linkTo.title}
            </Link>
          </div>
        </div>
      </div>
    );
  }

  renderHardware() {
    const { name, hardware, inverted } = this.props;
    const { teaserProduct: sizes } = imageSizes;
    const tariffRelation = getTariffHardwareRelation(hardware);
    const hardwareImage = {
      ...hardware.image,
      sizes: sizes.hardware || {},
    };

    return (
      <div
        className={suitcss({
          descendantName: 'inner',
          utilities: ['relative', 'alignCenter'],
        }, this)}
      >
        <div className={suitcss({ descendantName: 'content', utilities: ['paddingTopXXL'] }, this)} >
          <Headline
            className={suitcss({ descendantName: 'headline' }, this)}
            size="s"
            embedded
            inverted={inverted}
          >
            {name}
          </Headline>
          <div className={suitcss({ descendantName: 'image' }, this)} >
            <RouterLink to={hardware.urlDetails}>
              <MediaImage {...hardwareImage} isLazy />
            </RouterLink>
          </div>
          {tariffRelation &&
            <div className={suitcss({ descendantName: 'singlePaymentFee', utilities: ['marginTop'] }, this)} >
              <HardwarePaymentUnit
                hardware={hardware}
                paymentType={PAYMENT_FEE_SINGLE}
                inverted={inverted}
              />
            </div>
          }
        </div>
        {this.renderCallout()}
      </div>
    );
  }

  renderCallout() {
    const { tariff, hardware, inverted } = this.props;
    return (
      <Callout
        theme="badge"
        inverted={inverted}
        targets={[
          tariff && tariff.eid,
          hardware.eid,
          hardware.groupId,
        ]}
      />
    );
  }

  render() {
    const { type } = this.props;
    return (
      <div className={suitcss({ modifiers: [type], utilities: ['relative', 'marginHAuto'] }, this)} >
        {type === 'hardware' && this.renderHardware()}
        {type === 'bundle' && this.renderBundle()}
      </div>
    );
  }
}

TeaserProduct.propTypes = {
  type: PropTypes.oneOf(['hardware', 'bundle']).isRequired,
  name: PropTypes.string,
  hardware: hardwareShape,
  tariff: tariffShape,
  inverted: PropTypes.bool.isRequired,
  ui: PropTypes.shape({
    guiWordDetails: PropTypes.string.isRequired,
    guiWordMonthlyAbbr: PropTypes.string.isRequired,
    guiWordSingular: PropTypes.string.isRequired,
    guiContract24Months: PropTypes.string.isRequired,
    guiWordStartingFrom: PropTypes.string.isRequired,
    guiSymbolPlus: PropTypes.string.isRequired,
    guiSymbolEuro: PropTypes.string.isRequired,
  }),
  isNew: PropTypes.bool,
};

TeaserProduct.defaultProps = {};

export default connectUI()(TeaserProduct);
