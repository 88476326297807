import oneLineTrim from 'common-tags/lib/oneLineTrim';
import FileSaver from 'file-saver';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { send } from '../../../../actions/request/send';
// import { savePdf } from '../../../../helpers/misc';
import EECCBMnpPortingDocumentRequest
  from '../../../../model/requests/EECCBMnpPortingDocumentRequest';

import Copy from '../../../basics/text/TextCopy';
import Link from '../../../basics/text/TextLink';
import DataList from '../../../basics/text/TextDataList';
import suitcss from '../../../../helpers/suitcss';
import { preventDefault } from '../../../../helpers/functional';
import FieldCheckbox from '../../../basics/form/FieldCheckbox';

import {
  FORM_ID_BMNP_MSISDN,
  FORM_ID_BMNP_CONTRACT,
  FORM_FIELD_MNP_PORTING_ASAP,
  MARKET_POSTPAID,
} from '../../../../helpers/constants';
import FormSection from '../../form/FormSection';
import FormHeader from '../../form/FormHeader';

class MyBelatedMnpFormConfirm extends PureComponent {

  async loadContractSummaryPdf(ev) {
    const {
      dispatch, msisdn, ui, processId,
    } = this.props;

    ev.preventDefault();

    const format = 'pdf';
    const pdfData = await dispatch(send(
      new EECCBMnpPortingDocumentRequest(msisdn, processId, { format }),
    ));

    if (pdfData.status === 200 && process.browser) {
      const blob = new window.Blob([pdfData.body.blob], { type: `application/${format};` }); //eslint-disable-line
      const fileName = ui.guiContractSummary;
      FileSaver.saveAs(blob, `${fileName}.${format}`);
    }
  }

  renderLink(stepId, utilities) {
    const { ui, onStepClick } = this.props;
    return (
      <div
        className={suitcss({
          descendantName: 'link',
          utilities,
        }, this)}
      >
        <Link
          embedded
          element="button"
          onClick={preventDefault(() => onStepClick(stepId))}
        >
          {ui.guiWordEdit}
        </Link>
      </div>
    );
  }


  renderConfirm() {
    const { ui, data } = this.props;

    const items = [
      {
        key: ui.guiFormSalutation,
        value: data.salutation,
      },
      {
        key: ui.guiFormNameFirst,
        value: data.firstName,
      },
      {
        key: ui.guiFormNameLast,
        value: data.lastName,
      },
      {
        key: ui.guiFormDob,
        value: data.birthday,
      },
      {
        key: ui.mnpSimEmail,
        value: data.email,
      },
      {
        key: ui.mnpSimTypeFormerHint,
        value: data.simTypeFormer,
      },
      {
        key: ui.mnpSimTypeNewHint,
        value: data.simTypeNew,
      },
    ];

    return (
      <div className={suitcss({ utilities: ['row', 'col12'] }, this)} >
        <DataList withoutEllipsis items={items} utilities={['col9']} />
        {this.renderLink(FORM_ID_BMNP_CONTRACT, ['col3'])}
      </div>
    );
  }

  renderMsisdn() {
    const { ui, data } = this.props;
    const items = [
      { key: ui.mnpFormMsisdnLabel, value: data.msisdn },
      { key: ui.mnpFormProviderLabel, value: data.provider },
    ];
    return (
      <div className={suitcss({ descendantName: 'summeryContainer', utilities: ['row', 'col12'] }, this)} >
        <div className={suitcss({ descendantName: 'msisdn', utilities: ['col9'] }, this)}>
          <DataList withoutEllipsis items={items} />
        </div>
        {this.renderLink(FORM_ID_BMNP_MSISDN, ['col3'])}
      </div>
    );
  }

  render() {
    const { ui, market, formValues, fieldMap } = this.props;
    const headline = formValues[fieldMap.mnpPortingDateType.name] === FORM_FIELD_MNP_PORTING_ASAP
      ? ui.mnpConfirmTitleBefore
      : ui.mnpConfirmTitleEnd;
    const isPuc = market === MARKET_POSTPAID;
    const infoCopy = isPuc ? ui.txtMnpConfirmCopyPuc : ui.txtMnpConfirmCopy;
    const contractSummaryHint = ui.mnpSimCsHint;
    const contractSummaryLink = contractSummaryHint && contractSummaryHint.replace('{CONTRACT_SUMMARY_PDF}', oneLineTrim`
      <button class="TextLink">${ui.mnpSimCsPlaceholder}</button>`);
    return (
      <FormSection className={suitcss({}, this)} asGrid>
        <FormHeader headline={headline} copy={infoCopy} />
        {this.renderMsisdn()}

        <FormHeader headline={ui.mnpConfirmTitleData} />
        {this.renderConfirm()}
        <Copy
          className={suitcss({ descendantName: 'contractSummary' }, this)}
          utilities={['col12']}
          embedded
          raw
          onClick={(ev) => this.loadContractSummaryPdf(ev)}
        >
          {contractSummaryLink}
        </Copy>
        <Copy
          className={suitcss({ descendantName: 'simNote' }, this)}
          utilities={['col12']}
          embedded
          raw
        >
          {ui.mnpSimNote}
        </Copy>
        <Copy
          className={suitcss({ descendantName: 'noChargeHint' }, this)}
          utilities={['col12', 'weightBold']}
          embedded
          raw
        >
          {ui.mnpNochargeHint}
        </Copy>
        <Field
          {...fieldMap.mnpConsent}
          component={FieldCheckbox}
          withToolTip
        />
      </FormSection>
    );
  }
}

MyBelatedMnpFormConfirm.propTypes = {
  paymentData: PropTypes.object.isRequired,
  onStepClick: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
  fieldMap: PropTypes.object.isRequired,
  ui: PropTypes.shape({
    guiWordEdit: PropTypes.string.isRequired,
    guiOrderFree: PropTypes.string.isRequired,
  }),
  market: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  msisdn: PropTypes.string,
  processId: PropTypes.string,
};

export default MyBelatedMnpFormConfirm;
