import PropTypes from 'prop-types';
import { imageShape } from './media';
import { orderState, deliveryState } from './common';

export const propTypes = {
  eid: PropTypes.string.isRequired,
  etype: PropTypes.string.isRequired,
  iid: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  image: imageShape.isRequired,
  capacity: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  colorCode: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
  mnp: PropTypes.bool,
  priority: PropTypes.number.isRequired,
  vvl: PropTypes.bool.isRequired,
  orderState: orderState.isRequired,
  // deliveryTime is only required when deliveryState is DELIVERY_STATE_AVAILABLE and represents
  // the delivery time in days
  deliveryTime: PropTypes.number,
  deliveryState: deliveryState.isRequired,
  cheapestHardwareWithTariff: PropTypes.string,
  tariffMap: PropTypes.object.isRequired,
  urlDetails: PropTypes.string.isRequired,
  urlOverview: PropTypes.string.isRequired,
  urlSelect: PropTypes.string.isRequired,
};

export const shape = PropTypes.shape(propTypes);
export const list = PropTypes.arrayOf(shape);
