import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ContentTabsItem from './ContentTabItem';
import GlobalModule from '../../../containers/global/GlobalModule';
import suitcss from '../../../helpers/suitcss';
import { capitalize } from '../../../helpers/str';

class ContentTabs extends Component {
  static getDerivedStateFromProps(props) {
    const { tabs, location } = props;
    if (tabs.length && location.hash) {
      const locationHash = location.hash;
      const hash = locationHash && locationHash.substring(1);
      const index = tabs.findIndex((tab) => tab.hash === hash);
      if (index >= 0) {
        return {
          activeTab: index,
        };
      }
    }
    return {};
  }

  constructor(props) {
    super(props);

    this.state = {
      activeTab: 0,
    };

    this.onClickTabItem = this.onClickTabItem.bind(this);
  }

  onClickTabItem = (tabIndex) => {
    const { location, tabs, onClickTabItem } = this.props;
    if (location.pathname && tabs[tabIndex].hash) {
      onClickTabItem(tabs[tabIndex].hash);
    }
    this.setState({ activeTab: tabIndex });
  };

  renderTabsList() {
    const { tabs, theme, isMediaSM } = this.props;
    const tabTheme = theme === 'icons';
    return (
      <div className={suitcss({
          descendantName: 'list',
        modifiers: [
          (isMediaSM && 'alignCenter') || (this.props.align && `align${capitalize(this.props.align)}`),
        ],
        }, this)}
      >
        {tabs.length && tabs.map((tab, index) => (
          <ContentTabsItem
            activeTab={tabs[this.state.activeTab].label}
            identifier={index}
            label={tab.label}
            onClick={this.onClickTabItem}
            key={index}
            colorScheme={this.props.colorScheme}
            showCheck={tabTheme}
          />))}
      </div>);
  }

  renderTabContent() {
    const { tabs, location } = this.props;
    if (!tabs.length) {
      return null;
    }

    const modules = tabs[this.state.activeTab].modules;
    return (modules.map(module => (
      <div
        className={suitcss({
          descendantName: 'content',
        }, this)}
        key={module.eid}
      >
        <GlobalModule
          module={module}
          location={location}
          primaryModule={false}
        />
      </div>
    )));
  }

  render() {
    const { tabs } = this.props;

    if (!tabs.length) return null;

    return (
      <div className={suitcss({
      }, this)}
      >
        {this.renderTabsList()}
        {tabs.length && this.renderTabContent()}
      </div>
    );
  }
}

ContentTabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.object),
  location: PropTypes.object,
  align: PropTypes.oneOf(['left', 'center', 'right']),
  colorScheme: PropTypes.oneOf(['dark', 'medium', 'light']),
  onClickTabItem: PropTypes.func.isRequired,
  theme: PropTypes.string,
};

export default ContentTabs;
