/**
 * Abstract implementation of a chat user.
 */
class AbstractChatUser {
  /**
   * @param {string} name - the name of the bot
   */
  constructor(name = 'user1', icon) {
    this.name = name;
    this.icon = icon;
  }

  /**
   * Returns the name of the bot
   * @return {string}
   */
  getName() {
    return this.name;
  }

  /**
   * Returns the icon of the bot
   * @return {string}
   */
  getIcon() {
    return this.icon;
  }
}

export default AbstractChatUser;
