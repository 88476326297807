import oneLine from 'common-tags/lib/oneLine';
import {
  CONTEXT_REGISTRATION,
  CONTEXT_ACTIVATION,
  CONTEXT_CONTRACT_RENEWAL,
  ENTITY_TYPE_HARDWARE,
  ENTITY_TYPE_TARIFF,
  ENTITY_TYPE_TARIFFOPTION,
  ENTITY_TYPE_HARDWAREGROUP,
  ENTITY_TYPE_TARIFF_VO,
  ENTITY_TYPE_TARIFFOPTION_VO,
  CONTEXT_CONTRACT_RENEWAL_SIM_PLUS,
} from './constants';

import { isAccountUrl } from '../config/api';
import { toNegative } from './money';
import { hasEntity } from './entity';
import { devWarning } from './meta';
import { camelCase } from './str';

export const hasCreditIncentive = (promo) => promo.incentives.find(({ type }) => type === 'credit');
export const getCreditIncentives = (promotions = []) => {
  const creditPromos = Object.values(promotions).filter(hasCreditIncentive);
  return [].concat(...creditPromos.map(promo => promo.incentives));
};

// excluded functionality from shoppingcart
export const getPromotionIncentives = (promos) =>
  promos
    .reduce((list, entry) => (
      list.concat(...entry.incentives.filter(incentive => (
        incentive.type === 'credit' || incentive.type === 'service' || incentive.type === 'volume' || incentive.type === 'other'
      )))
    ), []);

// check if promotions have included products like e.g. tariff options
export const hasPromotionsIncludesProduct = (promos, includesProductEid) =>
  promos
    .filter((promo) => promo.includesProducts)
    .map((promo) => promo.includesProducts.map((includesProduct) => includesProduct.eid))
    .reduce((prev, curr) => (
      prev.concat(...curr)
    ), [])
    .includes(includesProductEid);

// get included products of promotion like e.g. tariff options
export const getPromotionIncludedProducts = (promo) => {
  if (promo.includesProducts) {
    return promo.includesProducts.map((includesProduct) => includesProduct.eid);
  }
};

export const getPaymentFeeReduction = (tariff, creditIncentive, paymentFeeType) => {
  const credit = paymentFeeType === camelCase(creditIncentive.targetProperty)
    ? creditIncentive.discount
    : null;
  if (!credit) {
    return null;
  }
  return toNegative(credit);
};

export const getPaymentFeeReductions = (tariff, creditIncentives, paymentFeeType) =>
  creditIncentives
    .filter(incentive => incentive.type === 'credit' && camelCase(incentive.targetProperty) === paymentFeeType)
    .map(creditIncentive => ({
      [paymentFeeType]: getPaymentFeeReduction(tariff, creditIncentive, paymentFeeType),
    }));

/**
 *
 * Checks if promotions contains a promotion for the passed tariffIid by
 * using the eIdToIidLookup.
 *
 * @deprecated this is completely legacy, don't use it!
 * @todo delete after search
 *
 */
export const hasPromotionForTariff = (promotions, tariffEntityId) => {
  const validPromos = Object.values(promotions).filter(o => {
    const tariffs = Object.values(o.targets.tariffs);
    return tariffs.filter(value => value === tariffEntityId).length > 0;
  });
  return validPromos.length > 0;
};

/**
 * Returns the main context the site is currently in.
 *
 * Note: returned identifiers correspond to identifiers in the promo engine
 * @see https://confluence.db-n.com/x/WODc
 *
 * @param {Object} state - the current state fo the redux store
 * @return {string|undefined} - if the context is identifiable, the context
 *     identifier is returned, undefined otherwise
 */
export const getContext = state => {

  const { site, routing, cart } = state;
  const { sitemap, contractRenewal } = site;

  if (contractRenewal.isInProgress) {
    if ((cart || []).some((e) => e.etype === 'hardwareEntity')) {
      return CONTEXT_CONTRACT_RENEWAL_SIM_PLUS;
    }
    return CONTEXT_CONTRACT_RENEWAL;
  }

  const location = routing.locationBeforeTransitions;
  if (!isAccountUrl(location)) {
    if (location.pathname === sitemap.ActivationFormRoute.url) {
      return CONTEXT_ACTIVATION;
    }
    return CONTEXT_REGISTRATION;
  }

};

/**
 * Object to map etypes to promo target keys
 */
export const etypePromoTargetGroupMap = {
  [ENTITY_TYPE_HARDWARE]: 'hardware',
  [ENTITY_TYPE_HARDWAREGROUP]: 'hardwareGroup',
  [ENTITY_TYPE_TARIFF]: 'tariffs',
  [ENTITY_TYPE_TARIFF_VO]: 'tariffVO',
  [ENTITY_TYPE_TARIFFOPTION]: 'tariffOptions',
  [ENTITY_TYPE_TARIFFOPTION_VO]: 'tariffOptionVO',
};

/**
 * Returns all promotions that match the current set of criteria.
 *
 * @param {Object|Array<WebEntity>} promotions - promotion entities as available in the store
 * @param {string} context
 * @param {array<WebEntity>} targets - list of selected web entities (e.g. hardware, tariff etc.)
 *     that will be compared with the targets included in the speficied promotions.
 * @return {Array<WebEntity>} A filtered set of promotions
 */
export const getMatchingPromotions = (promotions, context, targets) => {
  if (!promotions.length || !targets.length) return [];
  // group targets by their type so we can match them against promo-target keys
  const groupedTargets = {
    tariffs: [],
    hardware: [],
    hardwareGroup: [],
    tariffOptions: [],
    tariffVO: [],
    tariffOptionVO: [],
  };
  targets.forEach(target => {
    const group = groupedTargets[etypePromoTargetGroupMap[target.etype]];
    if (!group) {
      devWarning(oneLine`
        Type "${target.etype}" could not be be mapped while trying
        to match promotions and is ignored.
      `);
      return;
    }
    group.push(target);
  });

  // subLevel type 'sim_only' is only valid when there are no hardware or hardwareGroup targets
  const withHardware = !!groupedTargets.hardware.length || !!groupedTargets.hardwareGroup.length;

  return promotions.filter(promo => {
    // first we need to check which criteria is needed for the promotion to be valid
    const isTariffRequired = !!promo.targets.tariffs.length;
    const isHardwareRequired = (promo.hardwareRequirement && promo.hardwareRequirement !== 'sim_only') || !!promo.targets.hardware.length;
    const isHardwareSubLevelRequired = !!promo.targets.hardwareSubLevels.length;
    const isOptionRequired = !!promo.targets.tariffOptions.length;
    const isRenewSimPlusRequired = promo.context === 'renewContract' && !!promo.targets?.hardwareClasses?.length;

    const matchesTariff = groupedTargets.tariffs.length
      ? promo.targets.tariffs.some(entry => hasEntity(groupedTargets.tariffs, entry.eid))
      : promo.targets.tariffs.some(entry => hasEntity(groupedTargets.tariffVO, entry.eid));
    const matchesHardware = promo.targets.hardware.some(
      entry => hasEntity(groupedTargets.hardware, entry.eid),
    );
    const matchesOption = groupedTargets.tariffOptions.length
      ? promo.targets.tariffOptions.some(
        entry => hasEntity(groupedTargets.tariffOptions, entry.eid),
      )
      : promo.targets.tariffOptions.some(
        entry => hasEntity(groupedTargets.tariffOptionVO, entry.eid),
      );
    const matchesContext = promo.context === context
      || (context && context.includes(promo.context));
    const matchesSubLevel = promo.targets.hardwareSubLevels.some(subLevel =>
      (subLevel.value === 'sim_only' && !withHardware && (context && context !== CONTEXT_CONTRACT_RENEWAL_SIM_PLUS))
      || groupedTargets.tariffs.some(tariff =>
        groupedTargets.hardware.some(hw => {
          const price = hw.tariffMap[tariff.eid.toLowerCase()];
          return price
            // e.g. a paymentFee of 5Euro (=500) will match sub10
            ? (price.paymentFee.unit / 100) === parseInt(subLevel.value.substr('sub'.length), 10)
            : false;
        })));
    const matchesSimPlus = context === CONTEXT_CONTRACT_RENEWAL_SIM_PLUS
      && (
        groupedTargets.hardware.length === 0 // when no hardware is selected, we apply the filter
        // otherwise we check, if the hardware class for the selected hardware
        // matches the requirements in promotion
        || groupedTargets.hardware.some(
          entry => (promo.targets.hardwareClasses || [])
            .map(hw => hw.value).includes(entry.hardwareClassName),
        )
      );

    return (
      matchesContext
      && (!isHardwareSubLevelRequired || matchesSubLevel)
      && (!isTariffRequired || matchesTariff)
      && (!isHardwareRequired || matchesHardware)
      && (!isOptionRequired || matchesOption)
      && (!isRenewSimPlusRequired || matchesSimPlus)
    );
  });
};

export const grantedPromotionRequirements = ['promoCode'];
export const isGrantedPromotion = (promo) => grantedPromotionRequirements
  .some(requirement => promo.requiredParameters.includes(requirement));

export const grantedCampaignPromotionRequirements = ['campaignToken'];
export const isGrantedCampaignPromotion = (promo) => grantedCampaignPromotionRequirements
  .some(requirement => promo.requiredParameters.includes(requirement));

export const getBookablePromotions = (state) => (
  [
    ...state.site.bookablePromotions,
    ...state.user.bookablePromotions,
    ...state.user.grantedPromotions.promotions,
    ...state.user.grantedCampaignPromotions,
    ...state.user.selectedOptionalPromotions,
  ]
    .map(eid => state.entities.promotionVO[eid])
);

export const getOptionalPromotions = (state) => (
  state.site.optionalPromotions.map(eid => state.entities.promotionVO[eid])
);

export const getGrantedPromotions = (state) => (
  state.user.grantedPromotions.promotions
    .map(eid => state.entities.promotionVO[eid])
);

export const getGrantedCampaignPromotions = (state) => (
  state.user.grantedCampaignPromotions
    .map(eid => state.entities.promotionVO[eid])
);

// check for optional promotions (requiredParameters include customerAction)
export const customerActionPromotion = ['customerAction'];
export const isCustomerActionPromotion = (promo) => customerActionPromotion
  .some(requirement => promo.requiredParameters.includes(requirement));

// filter required promotions that are not optional
export const getRequiredPromotions = (promotions) =>
  promotions.filter((promo) => !isCustomerActionPromotion(promo));

// optional promotions that are selected by customer
export const getSelectedOptionalPromotions = (state) => (
  state.user.selectedOptionalPromotions
    .map(eid => state.entities.promotionVO[eid])
);
