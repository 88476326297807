import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import posed, { PoseGroup } from 'react-pose';
import shallowEqual from 'react-pure-render/shallowEqual';

import suitcss from '../../helpers/suitcss';
import GlobalNotification from '../../components/basics/global/GlobalNotification';
import * as dialogActions from '../../actions/page/dialog';
import { NOTIFICATION_TYPE_ALERT, NOTIFICATION_TYPE_CUSTOM } from '../../helpers/constants';

const PosedNotification = posed.div({
  enter: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 250,
      type: 'spring',
      stiffness: 150,
      damping: 15,
    },
  },
  exit: {
    y: 100,
    opacity: 0,
    transition: {
      duration: 400,
      ease: 'backIn',
    },
  },
});

class GlobalNotifications extends PureComponent {

  static getDerivedStateFromProps(props, state) {
    const { notification } = props;
    const { notification: currentNotification, lastNotification } = state;
    return !shallowEqual(notification, currentNotification) && !lastNotification
      ? {
        notification: null,
        nextNotification: notification,
        lastNotification: currentNotification,
      }
      : null;
  }

  constructor(props, context) {
    super(props, context);
    this.state = {
      notification: null,
      notificationKey: null,
      nextNotification: null,
      lastNotification: null,
    };
    this.onHideNotification = this.onHideNotification.bind(this);
  }

  componentDidUpdate() {
    const { lastNotification, nextNotification } = this.state;
    if (!lastNotification && nextNotification) {
      this.showNotification();
    }
  }

  onHideNotification(pose) {
    if (pose === 'exit') {
      this.showNotification();
    }
  }

  showNotification() {
    const { nextNotification } = this.state;
    this.setState({
      notification: nextNotification,
      notificationKey: Date.now(),
      nextNotification: null,
      lastNotification: null,
    });
  }

  renderNotification(notification) {
    const { hideNotification, hideAlert, ui } = this.props;

    if (notification.type === NOTIFICATION_TYPE_ALERT) {
      const text = notification.error.getVerbalization(ui);
      return (
        <GlobalNotification
          theme={'dark'}
          copy={text}
          onClose={hideAlert}
          alignH="center"
          ui={{ labelConfirm: ui.guiWordAllright }}
        />
      );
    }

    const isCustom = notification.type === NOTIFICATION_TYPE_CUSTOM;

    return (
      <GlobalNotification
        theme={isCustom ? 'custom' : 'dark'}
        copy={notification.props.copy}
        onClose={hideNotification}
        alignH="center"
        autoClose={notification.autoClose}
        ui={{ labelConfirm: ui.guiWordAllright }}
      />
    );
  }

  render() {
    const { notification, notificationKey } = this.state;
    return (
      <PoseGroup flipMove={false}>
        {notification &&
          <PosedNotification
            className={suitcss({}, this)}
            onPoseComplete={this.onHideNotification}
            key={notificationKey}
          >
            {this.renderNotification(notification)}
          </PosedNotification>
        }
      </PoseGroup>
    );
  }
}

GlobalNotifications.propTypes = {
  ui: PropTypes.object.isRequired,
  hideAlert: PropTypes.func.isRequired,
  hideNotification: PropTypes.func.isRequired,
};

const mapStateToProps = ({ menu, site, ui, lifecycle }) => {
  const isMenuBlocking = Object.keys(menu).some(key => (
    menu[key].isOpen && menu[key].blockViewport
  ));
  const error = site.errors[0];
  const notification = !lifecycle.isBlocking
    ? site.notification
    : null;

  return {
    notification: !isMenuBlocking ? (error || notification) : null,
    ui,
  };
};

const mapDispatchToProps = {
  hideAlert: dialogActions.hideAlert,
  hideNotification: dialogActions.hideNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(GlobalNotifications);
