import { push } from 'react-router-redux';
import PageRequest from '../../model/requests/PageRequest';
import ErrorPageRequest from '../../model/requests/ErrorPageRequest';
import { sendIfNecessary } from '../request/send';
import { log } from '../tracking/event';
import ErrorLogEntry from '../../model/logging/ErrorLogEntry';
import {
  QUERY_VOID,
  QUERY_AFFILIATE_ID,
  QUERY_APPVIEW,
  QUERY_INBOXVIEW,
  QUERY_ORDERING_HOTLINE,
  QUERY_PPLUS,
  QUERY_ACTIVATION_MSISDN,
  QUERY_SELECTED_HARDWARE,
  QUERY_SELECTED_TARIFF,
  QUERY_PROMO_CODE,
  QUERY_FILL_CART,
  ERROR_DISPLAY_STYLE_PAGE,
  QUERY_CID,
  QUERY_SELECTED_TARIFF_OPTION,
  QUERY_ORDER_BY_AGENT,
  ENTITY_TYPE_TARIFFOPTION,
  QUERY_INTERNAL_ID,
  QUERY_HIDE_MNP,
  QUERY_TARIFF_MODE,
  QUERY_DO_NOT_TRACK,
} from '../../helpers/constants';

import {
  registerVoId,
  registerAffiliateId,
  receiveAffiliatePartnerLink,
  receivePPlusData,
  registerCId,
  orderByAgent,
  registerPromoCodes,
} from '../user/marketing';
import { appViewDetected } from '../site/appView';
import { inboxViewDetected } from '../site/inboxView';
import { doNotTrackPage } from '../tracking/page';
import { fillCart, hideMnp } from '../order/cart';
import { getPage } from '../../selectors/page';
import RedirectError from '../../model/errors/RedirectError';
import { addItem, registerTariffMode } from '../order/process';
import { fetchMyPromotion } from '../request/registry';
import { stringIsBoolTrue } from '../../helpers/str';

/**
 * Checks the url for parameters that leads to actions or Siterequests the user
 * is not permitted to execute.
 *
 * @param location
 *
 */

export const validateTriggerUrlActions = (location) => async (dispatch, getState) => {
  const { pathname, query } = location;
  const { user, site } = getState();

  switch (pathname) {
    case site.sitemap.MyPromotionDetailsRoute.url: {
      const iid = query[QUERY_INTERNAL_ID];
      if (user.credentials.msisdn && iid) {
        try {
          const promotion = await dispatch(fetchMyPromotion(iid, {}, true));
          if (!promotion) {
            dispatch(push('/404'));
          }
        } catch (err) {
          // Handle 404 error without logging to Sentry, because in this case 404 is not an error.
          // 404 indicates that this promotion is not available or has ended.
          Error(err);
        }
      }
      break;
    }
    default:
  }
};

/**
 * Checks the url for parameters that influence the site, e.g marketing flags
 * and triggers related actions.
 *
 * @param location
 * @returns {Promise}
 */


export const triggerUrlActions = location => async (dispatch) => {
  const { query } = location;
  const actions = [];
  if (query[QUERY_VOID]) {
    const voId = query[QUERY_VOID];
    actions.push(registerVoId(Array.isArray(voId) ? voId[voId.length - 1] : voId));
  }
  if (query[QUERY_CID]) {
    // takes the query string (affl_content_cic_12647:...) and parse the cic value out of it.
    const matches = query[QUERY_CID].split(',');
    actions.push(registerCId(Array.isArray(matches) ? matches[0] : undefined));
  }
  if (query[QUERY_AFFILIATE_ID]) {
    actions.push(registerAffiliateId(query[QUERY_AFFILIATE_ID]));
  }
  if (query[QUERY_APPVIEW]) {
    actions.push(appViewDetected(location));
  }
  if (query[QUERY_INBOXVIEW]) {
    actions.push(inboxViewDetected());
  }
  if (query[QUERY_ORDERING_HOTLINE]) {
    actions.push(receiveAffiliatePartnerLink(query[QUERY_ORDERING_HOTLINE]));
  }
  if (query[QUERY_PPLUS]) {
    actions.push(receivePPlusData(query[QUERY_ACTIVATION_MSISDN]));
  }
  if (query[QUERY_DO_NOT_TRACK]) {
    const dnt = query[QUERY_DO_NOT_TRACK];
    actions.push(doNotTrackPage(dnt === '1'));
  }
  if (query[QUERY_FILL_CART]) {
    actions.push(fillCart(
      query[QUERY_PROMO_CODE],
      query[QUERY_SELECTED_TARIFF],
      query[QUERY_SELECTED_HARDWARE],
      query[QUERY_SELECTED_TARIFF_OPTION],
    ));
  }
  if (!query[QUERY_FILL_CART] && query[QUERY_PROMO_CODE]) {
    actions.push(registerPromoCodes([query[QUERY_PROMO_CODE]], true));
  }
  if (query[QUERY_ORDER_BY_AGENT]) {
    actions.push(orderByAgent(stringIsBoolTrue(query[QUERY_ORDER_BY_AGENT])));
  }
  if (query[QUERY_SELECTED_TARIFF_OPTION] && !query[QUERY_FILL_CART] && process.browser) {
    const item = {
      eid: query[QUERY_SELECTED_TARIFF_OPTION],
      etype: ENTITY_TYPE_TARIFFOPTION,
    };
    dispatch(addItem(item, true));
  }
  if (query[QUERY_HIDE_MNP]) {
    actions.push(hideMnp(stringIsBoolTrue(query[QUERY_HIDE_MNP])));
  }
  if (query[QUERY_TARIFF_MODE]) {
    actions.push(registerTariffMode(query[QUERY_TARIFF_MODE]));
  }
  return Promise.all(actions.map(dispatch));
};

/**
 * Tries to fetch the page (from store or server) and returns either the
 * requested page or an error page in case an error occurred.
 * @param {string} pathname
 */
export const fetchPage = pathname => async (dispatch, getState) => {
  const request = new PageRequest(pathname);
  try {
    await dispatch(sendIfNecessary(request));
  } catch (error) {
    if (error instanceof RedirectError) {
      throw error;
    }
    await dispatch(sendIfNecessary(new ErrorPageRequest(error, request)));
    dispatch(log(new ErrorLogEntry(
      error,
      getPage(getState().pages, pathname).etitle,
      ERROR_DISPLAY_STYLE_PAGE,
    )));
  }

  // refetch page from new state
  return getPage(getState().pages, pathname);
};
