import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { createSelector } from 'reselect';
import { compose } from 'redux';
import { connect } from 'react-redux';

import FootnoteSymbol from '../../../containers/footnote/FootnoteSymbol';
import { getLegalTarget } from '../../../containers/footnote/FootnoteReference';
import suitcss from '../../../helpers/suitcss';
import { scrollToHash } from '../../../helpers/navigation';
import Accordion from '../../basics/accordion/Accordion';
import AccordionSection from '../../basics/accordion/AccordionSection';
import Headline from '../../basics/text/TextHeadline';
import Copy from '../../basics/text/TextCopy';
import { sendIfNecessary } from '../../../actions/request/send';
import { devWarning } from '../../../helpers/meta';
import LegalsRequest from '../../../model/requests/LegalsRequest';
import { ID_FOOTER_LEGALS } from '../../../helpers/constants';

class FooterLegals extends PureComponent {

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(sendIfNecessary(new LegalsRequest()));
  }

  render() {
    const { legals, ui } = this.props;

    if (!legals) {
      return null;
    }

    return (
      <div
        className={suitcss({
          componentName: 'FooterLegals',
        })}
        id={ID_FOOTER_LEGALS}
      >
        <Accordion>
          <AccordionSection
            label={ui.guiWordLegalNotes}
            onClick={(open => open && scrollToHash(`#${ID_FOOTER_LEGALS}`))}
            theme="light"
            toggleContent
            centered
          >
            {legals.map((legal, index) => (
              <div
                className={suitcss({
                  componentName: 'FooterLegals',
                  descendantName: 'item',
                })}
                key={index}
              >
                <div
                  className={suitcss({
                    componentName: 'FooterLegals',
                    descendantName: 'headline',
                  })}
                >
                  <FootnoteSymbol id={getLegalTarget(legal)} refLegal={legal} />
                  <Headline size="xs" embedded raw>
                    {legal.headline}
                  </Headline>
                </div>
                <div
                  className={suitcss({
                    componentName: 'FooterLegals',
                    descendantName: 'copy',
                  })}
                >
                  <Copy embedded raw>{legal.copyWeb}</Copy>
                </div>
              </div>
            ))}
          </AccordionSection>
        </Accordion>
      </div>
    );
  }
}

FooterLegals.propTypes = {
  legals: PropTypes.arrayOf(
    PropTypes.shape({
      headline: PropTypes.string.isRequired,
      copyWeb: PropTypes.string,
    }),
  ),
  ui: PropTypes.shape({
    guiWordLegalNotes: PropTypes.string.isRequired,
  }),
  dispatch: PropTypes.func.isRequired,
};

/**
 * A legal text without a target is considered to be generic.
 */
const isGeneric = legal => legal && !getLegalTarget(legal);

/**
 * Returns a list of visible legal entities in correct order.
 * A legal text is shown if it is referenced by a footnote or
 * is a generic legal text.
 *
 * If legal texts have not been fetched yet, null is returned
 *
 * @return null|array
 */
export const getVisibleLegals = createSelector(
  state => state.legal.legals,
  state => state.legal.references,
  state => state.entities.promotionVO,
  (legals, references, promotions) => {
    if (!legals) {
      return null;
    }
    const legalsVisible = [];
    const legalEids = Object.keys(legals);

    // first add all legals referenced by footnotes in the order
    // they occur within the footnotes list
    references.forEach(reference => {
      const referencedLegalEids = legalEids.filter(lEid =>
        reference.id === getLegalTarget(legals[lEid]));

      if (referencedLegalEids.length === 0) {
        devWarning('Missing legal text for reference', reference.id);
        return;
      }
      referencedLegalEids.forEach(legalEid => legalsVisible.push(legals[legalEid]));
    });

    // then add all generic legals
    legalEids.forEach(eid => {
      const legal = legals[eid];

      if (isGeneric(legal)) {
        legalsVisible.push(legal);
      }
    });

    // remove legals tied to promotions that aren't valid
    for (let i = legalsVisible.length - 1; i >= 0; i--) {
      const isWithPromo = Object.values(promotions)
      // eslint-disable-next-line no-loop-func
        .some(promo => legalsVisible[i].promotionIds.includes(promo.id));
      if (legalsVisible[i].promotionIds.length && !isWithPromo) {
        legalsVisible.splice(legalsVisible.indexOf(legalsVisible[i]), 1);
      }
    }

    // only include legals with a long copy
    return legalsVisible.filter(l => l.copyWeb);
  },
);

const mapStateToProps = state => ({
  legals: getVisibleLegals(state),
  ui: state.ui,
});

const mapDispatchToProps = dispatch => ({ dispatch });

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(FooterLegals);
