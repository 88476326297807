import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import suitcss from '../../../helpers/suitcss';
import connectUI from '../../basics/ui/UIConnector';
import MediaSvgLoader from '../../basics/media/MediaSvgLoader';
import TextCopy from '../../basics/text/TextCopy';

class ContractRenewalFeeInfo extends PureComponent {

  render() {
    const {
      copy,
      inverted,
      onContractRenewalFeeInfo,
      global,
      contractRenewalFee,
    } = this.props;

    return (
      <div className={suitcss({ utilities: [inverted && 'colorInverted'] }, this)} onClick={() => onContractRenewalFeeInfo(contractRenewalFee, global)}>
        <TextCopy className={suitcss({ descendantName: 'copy' }, this)} size="secondary">
          {copy}
        </TextCopy>
        <div className={suitcss({ descendantName: 'info' }, this)}>
          <MediaSvgLoader path="/icons/content-info.svg" />
        </div>
      </div>
    );
  }
}

ContractRenewalFeeInfo.propTypes = {
  copy: PropTypes.string,
  contractRenewalFee: PropTypes.string,
  onContractRenewalFeeInfo: PropTypes.func.isRequired,
  global: PropTypes.bool,
  inverted: PropTypes.bool,
};

export default connectUI()(ContractRenewalFeeInfo);
