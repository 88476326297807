import PageModule from './PageModule';

/**
 * This module is used to display "normal" content such as headlines, texts, or pictures as a group.
 * @see https://confluence.db-n.com/x/FaXc
 */
class ContentGroupModule extends PageModule {
  constructor(moduleData) {
    super(import('../../containers/content/ContentGroup'), moduleData);
  }

  /**
   * @override
   */
  static isCritical() {
    return false;
  }
}

export default ContentGroupModule;
