import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import DeferredContractSummaryFormComposition from '../../components/compositions/deferredContractSummaryForm/DeferredContractSummaryForm';
import { fetchWithErrors } from '../../actions/request/basic';
import ContractSummaryRequest from '../../model/requests/ContractSummaryRequest';

class DeferredContractSummaryForm extends Component {
  constructor(props) {
    super(props);
    this.sendContractSummaryRequest = this.sendContractSummaryRequest.bind(this);
    this.triggerRedirect = this.triggerRedirect.bind(this);
    this.setState = this.setState.bind(this);

    this.state = {
      backendResponseCode: 100,
      backendResponseError: null,
    };
  }

  async sendContractSummaryRequest() {
    const { location } = this.props;

    const response = await this.props.dispatch(fetchWithErrors(new ContractSummaryRequest({
      processId: location.query.processId,
      contract_summary_confirmed: true,
    })));

    this.setState({
      backendResponseCode: response.status,
      backendResponseError: response.body.error && response.body.error.code,
    });

    return response;
  }

  triggerRedirect() {
    const { params, dispatch } = this.props;
    const { urlFailure, urlSuccess, urlConfirmed } = params;

    if (this.state.backendResponseCode === 100) {
      return;
    }

    if (this.state.backendResponseCode === 200 || this.state.backendResponseCode === 204) {
      dispatch(push(urlSuccess));
    } else if (this.state.backendResponseCode === 400) {
      if (this.state.backendResponseError === '9429') {
        dispatch(push(urlConfirmed));
      } else {
        dispatch(push(urlFailure));
      }
    } else if (this.state.backendResponseCode === 422) {
      dispatch(push(urlFailure));
    }
  }

  render() {
    return (
      <DeferredContractSummaryFormComposition
        callContractSummaryEndpoint={this.sendContractSummaryRequest}
        triggerRedirect={this.triggerRedirect}
      />
    );
  }
}

DeferredContractSummaryForm.propTypes = {
  location: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
};

const mapStateToProps = (state, { location }) => ({
  location,
});

export default connect(mapStateToProps)(DeferredContractSummaryForm);
