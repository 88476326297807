import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FormManager from '../form/FormManager';
import {
  FORM_FIELD_GDPR_CONSENT_BEW,
  FORM_FIELD_GDPR_CONSENT_DAT,
  REQUEST_METHOD_POST,
} from '../../helpers/constants';
import { getConsentHashById } from '../../helpers/misc';
import AccountGdprConsentsAggregatedRequest from '../../model/requests/AccountGdprConsentsAggregatedRequest';
import * as formConfigAdConsent from './formConfigAdConsentUpdate';

class AdConsentUpdateForm extends Component {

  render() {
    const { shouldRender, onSubmit, user, consentTexts } = this.props;
    if (!shouldRender) {
      return null;
    }

    const getSubmitRoute = (fieldMap, finalizedFormValues) =>
      new AccountGdprConsentsAggregatedRequest(user.credentials.msisdn, {
        method: REQUEST_METHOD_POST,
        payload: {
          ...finalizedFormValues,
          ...!!finalizedFormValues.ad_consent && {
            adConsentTextHash:
              getConsentHashById(consentTexts, FORM_FIELD_GDPR_CONSENT_BEW),
          },
          ...!!finalizedFormValues.pass_data && {
            passDataTextHash:
              getConsentHashById(consentTexts, FORM_FIELD_GDPR_CONSENT_DAT),
          },
        },
      });

    const onBeforeSubmit = (fieldMap, normValues) => {
      const values = { ...normValues };
      if (!normValues[fieldMap.adConsent.name]) {
        values[fieldMap.adConsent.name] = false;
      }
      if (!normValues[fieldMap.passData.name]) {
        values[fieldMap.passData.name] = false;
      }
      return values;
    };

    const onAfterSubmitSuccess = () => {
      if (onSubmit) {
        onSubmit();
      }
    };

    return (
      <FormManager
        form={AdConsentUpdateForm.formName}
        onBeforeSubmit={onBeforeSubmit}
        onAfterSubmitSuccess={onAfterSubmitSuccess}
        submitRoute={getSubmitRoute}
        steps={[formConfigAdConsent]}
        stepProps={this.props}
        isInlineForm
      />
    );
  }
}

/**
 * required by tracking!
 */
AdConsentUpdateForm.formName = 'adConsentForm';

AdConsentUpdateForm.propTypes = {
  user: PropTypes.object,
  shouldRender: PropTypes.bool,
  // Used in form config
  onSubmit: PropTypes.func.isRequired,
  consentTexts: PropTypes.array,
};

const mapStateToProps = ({ user, site }, ownProps) => {
  const adConsent = ownProps.adConsent || user.adConsent;
  const shouldRender = adConsent && !adConsent.blacklisted;
  return {
    user,
    adConsent,
    consentTexts: site.consentTexts,
    shouldRender,
  };
};

const mapDispatchToProps = dispatch => ({ dispatch });

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdConsentUpdateForm);
