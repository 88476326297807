import QueueableRequest from './QueueableRequest';
import { EECC_PROMOTIONS_ORDER_INITIATE } from '../../config/api';
import { REQUEST_METHOD_POST } from '../../helpers/constants';
import { bindParamsToRoute } from '../../helpers/url';

/**
 *
 *
 */

export default class EECCPromotionsOrderInitiateRequest extends QueueableRequest {
  constructor(payload, msisdn) {
    super(bindParamsToRoute(EECC_PROMOTIONS_ORDER_INITIATE, { msisdn }), {
      method: REQUEST_METHOD_POST,
      payload,
    });
  }
}
