import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import GlobalSection from '../../components/basics/global/GlobalSection';
import MyPromoConfirmComposition from '../../components/compositions/account/MyPromoConfirm';
import { QUERY_ENTITY_ID } from '../../helpers/constants';
import * as registryActions from '../../actions/request/registry';
import * as bridge from '../../services/bridge';
import GlobalModule from '../global/GlobalModule';
import ContentLoader from '../../components/compositions/content/ContentLoader';

class MyPromoConfirm extends PureComponent {

  componentDidMount() {
    const {
      entityConfirmId,
      entityConfirm,
      fetchPromotionConfirm,
    } = this.props;
    if (!entityConfirm && entityConfirmId) {
      fetchPromotionConfirm(entityConfirmId);
    }
  }

  getEntityConfirm() {
    const {
      entityConfirm,
      isAppView,
      sitemap,
    } = this.props;

    if (!entityConfirm) { return null; }

    const { buttonText, buttonHint } = entityConfirm;

    const link = {
      label: buttonText,
      hint: buttonHint,
      url: !isAppView ? sitemap.MyDashboardRoute.url : null,
      onClick: isAppView ? () => bridge.cta(bridge.CTA_MY_PROMO_CONFIRM) : null,
    };

    return {
      link,
    };
  }

  render() {
    const { entityConfirm, location, isAppView } = this.props;
    const entityConfirmConfig = this.getEntityConfirm();
    return (
      <Fragment>
        {entityConfirm && entityConfirm.modules && entityConfirm.modules.map(module => (
          <GlobalModule
            module={module}
            location={location}
            primaryModule={false}
            key={module.eid}
          />
        ))}
        <GlobalSection layout="contained" adjacent>
          <ContentLoader isLoaded={!!entityConfirm} height={380} >
            {entityConfirm &&
              <MyPromoConfirmComposition
                {...entityConfirmConfig}
                isAppView={isAppView}
              />
            }
          </ContentLoader>
        </GlobalSection>
      </Fragment>
    );
  }
}

MyPromoConfirm.propTypes = {
  entityId: PropTypes.string,
  entityConfirmId: PropTypes.string,
  entity: PropTypes.object,
  entityConfirm: PropTypes.object,
  sitemap: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  fetchPromotionConfirm: PropTypes.func.isRequired,
  isAppView: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const { entities, site } = state;
  const { location } = ownProps;
  const { sitemap } = site;
  const entityConfirmId = location.query[QUERY_ENTITY_ID];
  const entityConfirm = entityConfirmId ? entities.myPromoConfirm[entityConfirmId] : null;
  return {
    entityConfirmId,
    entityConfirm,
    sitemap,
    location,
    isAppView: !!site.appView,
  };
};

const mapDispatchToProps = {
  fetchPromotionConfirm: registryActions.fetchEntityById,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyPromoConfirm);
