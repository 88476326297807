import QueueableRequest from './QueueableRequest';
import * as endpoints from '../../config/api';
import { bindParamsToRoute } from '../../helpers/url';
import { REQUEST_METHOD_POST } from '../../helpers/constants';

export default class SimReplOrderRequest extends QueueableRequest {
  constructor(msisdn, payload) {
    super(bindParamsToRoute(endpoints.ACCOUNT_SIM_REPL_ORDER_ENDPOINT, { msisdn }), {
      method: REQUEST_METHOD_POST,
      payload,
    });
  }
}
