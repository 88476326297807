import { isTariffEntity, isHardwareEntity } from '../helpers/entity';

const processStatePending = items => (items.length >= 2 ? 'BUNDLE' : 'SINGLE_PENDING');
const processStateConfirmed = items => (items.length >= 2 ? 'BUNDLE' : 'SINGLE_CONFIRMED');

const tariffFirst = itemA => (itemA.etype === 'tariffEntity' ? -1 : 1);
const hardwareFirst = itemA => (itemA.etype === 'hardwareEntity' ? -1 : 1);

const singleTariffFirst = ({ progressState }) => (
  progressState === 'BUNDLE' ? hardwareFirst : tariffFirst
);
const singleHardwareFirst = ({ progressState }) => (
  progressState === 'BUNDLE' ? tariffFirst : hardwareFirst
);

export default {
  TariffDetail: {
    progressState: processStatePending,
    sortItems: () => tariffFirst,
  },
  TariffOptions: {
    progressState: processStateConfirmed,
    sortItems: () => tariffFirst,
  },
  HardwareDetails: {
    progressState: processStatePending,
    sortItems: () => hardwareFirst,
  },
  HardwareTariffs: {
    progressState: processStateConfirmed,
    sortItems: singleHardwareFirst,
  },
  HardwareList: {
    progressState: (items) => {
      const tariff = items.find(isTariffEntity);
      const hardware = items.find(isHardwareEntity);
      if (!tariff) {
        return 'EMPTY';
      }
      if (!hardware) {
        return 'SINGLE_CONFIRMED';
      }
      return 'BUNDLE';
    },
    sortItems: singleTariffFirst,
  },
};
