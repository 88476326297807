/**
 * @file
 *
 * This reducers stores fundamental data objects such as
 * tariffs, tariff options and hardware objects.
 */

import mapObj from 'map-obj';
import { RECEIVE_ENTITY_LIST } from '../actions/page/entity';
import * as constants from '../helpers/constants';
import { REQUEST_RECEIVED_RESPONSE } from '../actions/request/basic';
import TariffRequest from '../model/requests/TariffRequest';
import TariffsRequest from '../model/requests/TariffsRequest';
import TariffOptionRequest from '../model/requests/TariffOptionRequest';
import TariffOptionsRequest from '../model/requests/TariffOptionsRequest';
import PromotionsRequest from '../model/requests/PromotionsRequest';
import MyPromotionRequest from '../model/requests/MyPromotionRequest';
import MyPromotionsRequest from '../model/requests/MyPromotionsRequest';
import TeaserBarsRequest from '../model/requests/TeaserBarsRequest';
import EntityRequest from '../model/requests/EntityRequest';
import { groupBy } from '../helpers/functional';
import { camelCase } from '../helpers/str';
import { SET_PAYMENT_FEE } from '../actions/abtest/abtest';


const defaultState = {
  hardwareGroup: {},
  hardwareEntity: {},
  tariffEntity: {},
  tariffOptionEntity: {},
  tariffVO: {},
  tariffOptionVO: {},
  promotionVO: {},
  myPromoDetails: {},
  myPromoConfirm: {},
  teaserBar: {},
};

/**
 * Web entities have an "eid" while VO objects have an "id" property.
 */
const getEntityId = entity => entity.eid || entity.id;

/**
 * Integrates a series of downloaded entities into the store.
 *
 * @param {Object} state - the state handled by this reducer
 * @param {string} etype - e.g. "tariffVO" or "hardwareEntity"
 * @param {Array} entities - the objects to integrate
 * @param {Object} [options]
 */
const integrateEntitiesByType = (state, etype, entities, options = { camelizeType: false }) => {
  if (options.camelizeType) {
    // note: be careful not to camelcase the etype of app entities, which
    // would turn the "VO" to "Vo" which is not desired.
    etype = camelCase(etype); // eslint-disable-line no-param-reassign
  }

  return {
    ...state,
    [etype]: Object.assign(
      {},
      state[etype],
      ...entities.map(entity => ({
        [getEntityId(entity)]: { ...entity, etype },
      })),
    ),
  };
};
/**
 * Convenience function.
 * @see integrateEntitiesByType
 */
const integrateEntity = (state, type, entity, options) =>
  integrateEntitiesByType(state, type, [entity], options);

/**
 * Integrates an array of entities of all types
 * @param {Object} state
 * @param {Array} entities
 * @param {Object} [options]
 */
const integrateEntities = (state, entities, options) => Object
  .entries(groupBy(entities, 'etype'))
  .reduce(
    (nextState, [etype, group]) => integrateEntitiesByType(nextState, etype, group, options),
    { ...state },
  );

/**
 * This function gives us control over how to integrates the received
 * response data in the store.
 *
 * For example a response received from the balance endpoint is stored
 * in the store under `user.balance`.
 */
const integrateResponseData = (state, action) => {
  const { meta, payload } = action;
  const { data } = payload.response.body;
  const { request } = meta;

  if (request.method !== constants.REQUEST_METHOD_GET) {
    return state;
  }

  if (request instanceof TariffRequest) {
    return integrateEntity(state, 'tariffVO', data);
  } else if (request instanceof TariffOptionRequest) {
    return integrateEntity(state, 'tariffOptionVO', data);
  } else if (request instanceof MyPromotionRequest) {
    return integrateEntity(state, 'promotionVO', data);
  } else if (request instanceof TariffsRequest) {
    return integrateEntitiesByType(state, 'tariffVO', data);
  } else if (request instanceof TariffOptionsRequest) {
    return integrateEntitiesByType(state, 'tariffOptionVO', data);
  } else if (request instanceof MyPromotionsRequest
    || request instanceof PromotionsRequest) {
    return integrateEntitiesByType(state, 'promotionVO', data);
  } else if (request instanceof TeaserBarsRequest) {
    return integrateEntities(state, data, { camelizeType: true });
  } else if (request instanceof EntityRequest) {
    return integrateEntity(state, data.etype, data, { camelizeType: true });
  }

  return state;
};

const setPaymentFee = (state, action) => {
  const { eids, amount } = action.payload;
  return {
    ...state,
    tariffEntity: mapObj(state.tariffEntity, (key, entity) => {
      if (eids.includes(key)) {
        return [key, {
          ...entity,
          singlePaymentFee: {
            ...entity.singlePaymentFee,
            unit: amount,
          },
        }];
      }
      return [key, entity];
    }),
  };
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case RECEIVE_ENTITY_LIST:
      return integrateEntities(state, action.payload, { camelizeType: true });

    case REQUEST_RECEIVED_RESPONSE:
      return integrateResponseData(state, action);

    case SET_PAYMENT_FEE:
      return setPaymentFee(state, action);
    default:
      return state;
  }
};
