import {
  REPLACE_CART,
  CHANGE_TARIFF_OPTIONS,
  REMOVE_CART_ITEM,
  CLEAR_CART,
} from '../actions/order/cart';
import { LOGOUT } from '../actions/user/logout';
import { CONFIRM_ORDER } from '../actions/order/completed';

import { isTariffOptionEntity } from '../helpers/entity';

export default (state = [], action) => {
  switch (action.type) {
    case REPLACE_CART:
      return [...action.payload];

    case CHANGE_TARIFF_OPTIONS:
      return [
        ...state.filter(entity => !isTariffOptionEntity(entity)),
        ...action.payload,
      ];

    case REMOVE_CART_ITEM:
      return state.filter((entity) => action.payload !== entity.eid);

    // remove all entries from cart when order is submitted
    case CONFIRM_ORDER:
    case CLEAR_CART:
      return [];

    case LOGOUT:
      return [];

    default:
      return state;
  }
};
