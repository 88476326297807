import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import AccordionSection from '../../basics/accordion/AccordionSection';
import suitcss from '../../../helpers/suitcss';
import Accordion from '../../basics/accordion/Accordion';
import GlobalModule from '../../../containers/global/GlobalModule';
import { capitalize } from '../../../helpers/str';

class ContentAccordion extends PureComponent {

  constructor(props, context) {
    super(props, context);
    this.state = {
      isOpen: false,
    };
  }

  handleClick = (isOpen) => {
    this.setState({
      isOpen,
    });
  };

  render() {
    const { theme, location, label, labelExpanded, modules } = this.props;
    const { isOpen } = this.state;
    const labelState = isOpen ? labelExpanded : label;

    return (
      <section className={suitcss({
        modifiers: [
          theme && `theme${capitalize(theme)}`,
        ],
      }, this)}
      >
        <Accordion>
          <AccordionSection
            label={labelState.toUpperCase()}
            centered
            onClick={this.handleClick}
            open={isOpen}
            expanded
            theme={theme}
          >
            {modules.map(module => (
              <GlobalModule
                module={module}
                location={location}
                primaryModule={false}
                key={module.eid}
              />
            ))}
          </AccordionSection>
        </Accordion>
      </section>
    );
  }
}

ContentAccordion.propTypes = {
  theme: PropTypes.oneOf(['dark', 'medium', 'light']),
  label: PropTypes.string,
  labelExpanded: PropTypes.string,
  modules: PropTypes.arrayOf(PropTypes.object),
  location: PropTypes.object.isRequired,
};

export default ContentAccordion;
