import PropTypes from 'prop-types';

class FormConfigProps {

  constructor({
    key,
    labelSubmit,
    labelCancel,
    dataSubmit,
    dataCancel,
    isSubmitDisabled,
    isSubmitOptional,
    asideComponent,
    footerComponent,
    asideComponentProps,
    footerComponentProps,
    hintSubmit,
    withRequiredHint,
    withStackedFooter,
    additionalButton,
    customSubmitButton,
    customCancelButton,
    withFooter,
  }) {
    this.key = key;
    this.labelSubmit = labelSubmit;
    this.labelCancel = labelCancel;
    this.dataSubmit = dataSubmit;
    this.dataCancel = dataCancel;
    this.isSubmitDisabled = isSubmitDisabled || false;
    this.isSubmitOptional = isSubmitOptional || false;
    this.asideComponent = asideComponent;
    this.footerComponent = footerComponent;
    this.asideComponentProps = asideComponentProps || {};
    this.footerComponentProps = footerComponentProps || {};
    this.hintSubmit = hintSubmit;
    this.withRequiredHint = withRequiredHint;
    this.withStackedFooter = withStackedFooter;
    this.withFooter = withFooter;
    this.additionalButton = additionalButton;
    this.customSubmitButton = customSubmitButton;
    this.customCancelButton = customCancelButton;
  }

  static propTypes() {
    return {
      key: PropTypes.string,
      labelSubmit: PropTypes.string,
      labelCancel: PropTypes.string,
      hintSubmit: PropTypes.string,
      dataSubmit: PropTypes.object,
      dataCancel: PropTypes.object,
      isSubmitDisabled: PropTypes.bool,
      isSubmitOptional: PropTypes.bool,
      asideComponent: PropTypes.func,
      footerComponent: PropTypes.func,
      asideComponentProps: PropTypes.object,
      footerComponentProps: PropTypes.object,
      withRequiredHint: PropTypes.bool,
      withStackedFooter: PropTypes.bool,
      withFooter: PropTypes.bool,
      additionalButton: PropTypes.element,
      customSubmitButton: PropTypes.element,
      customCancelButton: PropTypes.element,
    };
  }
}

export default FormConfigProps;
