import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as ordering from './formConfigFreeSimOrdering';
import FormManager from '../form/FormManager';
import GlobalSection from '../../components/basics/global/GlobalSection';
import { MARKET_PREPAID } from '../../helpers/constants';
import { trackFreeSimSubmit } from '../../actions/tracking/event';
import FreeSimOrderingRequest from '../../model/requests/FreeSimOrderingRequest';

class FreeSimForm extends PureComponent {

  constructor(...args) {
    super(...args);
    this.onBeforeSubmit = this.onBeforeSubmit.bind(this);
    this.getSuccessRoute = this.getSuccessRoute.bind(this);
    this.onAfterSubmitSuccess = this.onAfterSubmitSuccess.bind(this);
  }

  onAfterSubmitSuccess({ responseBody }) {
    const { dispatch } = this.props;

    dispatch(trackFreeSimSubmit(responseBody));
  }

  /**
   * We use the finalize function to enrich the normalized values
   * with the bId for marketing tracking and pass it as
   * the void (void is the internal id for "Vertriebspartnerkennung")
   *
   * Note: this function is called by the {@link FormManager}'s submit handler.
   *
   * @param {Object} fieldMap
   * @param {Object} normalizedValues
   * @returns {Object}
   */
  onBeforeSubmit(fieldMap, normalizedValues) {
    const { bId, affId } = this.props.user;
    const finalized = { ...normalizedValues };

    if (bId) {
      finalized.void = bId;
    }
    if (affId) {
      finalized.publisher_id = affId;
    }

    return finalized;
  }

  getSuccessRoute() {
    const { params } = this.props;
    return params.urlSuccess;
  }

  getSubmitRoute(fieldMap, finalizedValues) {
    return new FreeSimOrderingRequest(MARKET_PREPAID, finalizedValues);
  }

  render() {
    const { location } = this.props;
    const { headline } = this.props.params;
    return (
      <GlobalSection>
        <FormManager
          form={FreeSimForm.formName}
          location={location}
          stepProps={this.props}
          headline={headline}
          submitRoute={this.getSubmitRoute}
          onAfterSubmitSuccess={this.onAfterSubmitSuccess}
          onBeforeSubmit={this.onBeforeSubmit}
          successRoute={this.getSuccessRoute}
          steps={[ordering]}
        />
      </GlobalSection>
    );
  }
}

/**
 * required by tracking!
 */
FreeSimForm.formName = 'freesim';

FreeSimForm.propTypes = {
  location: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  params: PropTypes.shape({
    urlSuccess: PropTypes.string.required,
    headline: PropTypes.string.required,
  }).isRequired,
  user: PropTypes.shape({
    bId: PropTypes.string,
    affId: PropTypes.string,
  }),
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(FreeSimForm);
