import { compose } from 'redux';
import { connect } from 'react-redux';

import CancellationForm
  from '../../../components/compositions/account/cancellation/CancellationReasonForm';
import initForm from '../../form/FormInitializer';
import { fetchGdprConsentsAggregatedData } from '../../../actions/request/registry';
import FormConfigProps from '../../../model/form/FormConfigProps';

const createFieldMap = ({ ui, cancellationReasons }) => ({
  cancelReasonKey: {
    name: 'cancel_reason_key',
    label: ui.myCancellationFormReasonDropdown,
    options: cancellationReasons.map(reason => ({
      label: reason.description,
      value: reason.reasonKey,
    })),
  },
  cancelReasonText: {
    name: 'cancel_reason_text',
    label: ui.myCancellationFormReasonTextarea,
  },
});

const mapStateToProps = (state, { fieldMap }) => {
  const initialValues = {
    [fieldMap.cancelReasonKey.name]: fieldMap.cancelReasonKey.options[0].value,
  };
  return {
    initialValues,
  };
};

const component = compose(
  connect(mapStateToProps),
  initForm(),
)(CancellationForm);

export const id = 'cancellation';

const makeSubmit = (step) => async (values, dispatch) => {
  const adConsent = await dispatch(fetchGdprConsentsAggregatedData());
  if (adConsent.adConsent && adConsent.passData) {
    return undefined;
  }
  return step.next;
};

const normalize = (formConfig, stepFormValues) => {
  const { fieldMap } = formConfig;
  const normValues = { ...stepFormValues };
  normValues.cancel_reason_key =
    stepFormValues[fieldMap.cancelReasonKey.name] || fieldMap.cancelReasonKey.options[0].value;
  return normValues;
};

/**
 * @return {FormConfig}
 */
export const mapStateToFormConfig = (state, props) => ({
  id,
  component,
  fieldMap: createFieldMap(props),
  normalize,
  label: id,
  makeSubmit,
  formConfigProps: new FormConfigProps({
    labelSubmit: (state.user.adConsent.adConsent && state.user.adConsent.passData)
    || state.user.adConsent.blacklisted
      ? state.ui.myCancellationFormSubmit
      : state.ui.btnContinue,
    withRequiredHint: false,
  }),
});

export default mapStateToFormConfig;
