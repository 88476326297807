import QueueableRequest from './QueueableRequest';
import { ACCOUNT_SIM_ENDPOINT } from '../../config/api';
import { bindParamsToRoute } from '../../helpers/url';

export default class SimCardRequest extends QueueableRequest {
  constructor(msisdn, options) {
    super(bindParamsToRoute(ACCOUNT_SIM_ENDPOINT, { msisdn }), options);
    this.msisdn = msisdn;
  }
}
