import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import FormSection from '../../form/FormSection';
import FormHeader from '../../form/FormHeader';
import Copy from '../../../basics/text/TextCopy';
import Link from '../../../basics/text/TextLink';
import DataList from '../../../basics/text/TextDataList';
import { formatDate } from '../../../../helpers/date';
import suitcss from '../../../../helpers/suitcss';
import * as constants from '../../../../helpers/constants';
// import { calcTaxByGross } from '../../../../helpers/money';
import Unit from '../../../basics/text/TextUnit';
import * as activationStyle
  from '../../../../containers/account/simReplacement/formConfigSimReplActivationStyle';

class SimReplacementFormConfirm extends PureComponent {

  getCardStateCopy() {
    const { ui, fieldMap, formValues, isEsim } = this.props;

    const eSimStatusHint = ui.txtMySimrepEsimStatusHint.replace(/<[^>]*>?/gm, '');
    return formValues[fieldMap.shipActivatedCard.name] === activationStyle.SHIP_ACTIVE ?
      (
        <Copy element="div" embedded>
          {ui.guiWordActive}
          <small className="u-block u-colorError u-textSizeSecondary">
            {isEsim ? eSimStatusHint : ui.mySimrepSimstatusActiveHint}
          </small>
        </Copy>
      ) : (
        <Copy element="div" embedded>
          {ui.guiWordInactive}
          <small className="u-block u-colorError">{ui.mySimrepSimstatusNonactiveHint}</small>
        </Copy>
      );
  }

  renderShippingData(showEmailOnly) {
    const { ui, customerData, onUpdateMyData } = this.props;

    const salutation = customerData.salutation === 'mr' ? ui.guiFormSalutationMale : ui.guiFormSalutationFemale;

    const items = [
      ...(!showEmailOnly
        ? [
          { key: ui.guiFormSalutation, value: salutation },
          { key: ui.guiFormNameFirst, value: customerData.firstName },
          { key: ui.guiFormNameLast, value: customerData.lastName },
          { key: ui.guiFormDob, value: formatDate(customerData.birthdate) },
          { key: ui.guiFormAddressStreet, value: `${customerData.street} ${customerData.houseNumber}` },
          { key: ui.myProfileContactAddressAddon, value: customerData.addressSupplement },
          { key: ui.myProfileContactCity, value: `${customerData.zip} ${customerData.city}` },
        ]
        : []
      ),
      { key: ui.myProfileContactEmail, value: customerData.email },
    ].filter(e => e.value != null && e.value !== '');

    return (
      <div className={suitcss({ utilities: ['col12', 'grid', 'sCollapse', showEmailOnly && 'marginTop'] })}>
        <DataList
          withoutEllipsis
          items={items}
          utilities={['col12']}
        />
        <div className={suitcss({ descendantName: 'link', utilities: ['col12'] }, this)}>
          <Link
            element="button"
            onClick={(ev) => { ev.preventDefault(); onUpdateMyData(); }}
          >
            {ui.guiWordEdit}
          </Link>
        </div>
      </div>
    );
  }

  renderPurchaseDetails() {
    const { ui, market, onStepClick, selectedSimOption, isEsim } = this.props;
    const { basicFee, shippingFee } = selectedSimOption;

    const totalPrice = basicFee + shippingFee;
    // const tax = <Unit price={{ currency: 'EUR', unit: calcTaxByGross(totalPrice) }}
    // bold={false} />; // newline to avoid warning

    const items = [
      {
        key: ui.mySimrepSimtypeLabel,
        value: isEsim ? ui.mySimrepChooseSimtypeOptionEsim : ui.mySimrepChooseSimtypeOptionClassic,
      },
      { key: ui.mySimrepSimstatusLabel, value: this.getCardStateCopy() },
      { key: ui.mySimrepCostSim, value: <Unit price={{ currency: 'EUR', unit: basicFee }} bold={false} /> },
      { key: ui.guiShippingCosts, value: <Unit price={{ currency: 'EUR', unit: shippingFee }} bold={false} /> },
      { key: `<b>${ui.guiWordSum}</b>`, value: <Unit price={{ currency: 'EUR', unit: totalPrice }} />, withMarginTop: true },
      // { key: ui.mySimrepCostVat, value: tax },
    ].filter(e => e.value != null && e.value !== '');

    return (
      <div className={suitcss({ utilities: ['col12', 'grid', 'sCollapse'] })}>
        <DataList withoutEllipsis items={items} />
        <div className={suitcss({ descendantName: 'link', utilities: ['col12'] }, this)}>
          <Link
            element="button"
            onClick={(ev) => { ev.preventDefault(); onStepClick(activationStyle.id); }}
          >
            {ui.guiWordEdit}
          </Link>
        </div>
        <Copy size="secondary" utilities={['colorGray100']} raw embedded>
          {market === constants.MARKET_POSTPAID
            ? ui.mySimrepPaymentHintPost
            : ui.mySimrepPaymentHintPre
          }
        </Copy>
      </div>
    );
  }

  render() {
    const { ui, phoneNumber, isEsim } = this.props;
    const showEmailOnly = isEsim;
    return (
      <FormSection className={suitcss({}, this)} asGrid>
        <FormHeader
          headline={ui.guiWordSummary}
          copy={isEsim ? ui.mySimrepSummaryCopyEsim : ui.txtMySimrepSummaryCopy}
        />
        <div className={suitcss({ utilities: ['col12', 'grid', 'sCollapse'] })}>
          <DataList
            withoutEllipsis
            items={[{
              key: `<b>${ui.guiWordPhonenumber}</b>`,
              value: `<b>${phoneNumber}</b>`,
              rawValue: true,
            }]}
            utilities={['col12']}
          />
        </div>
        {!showEmailOnly && (
          <FormHeader headline={ui.guiWordAddressdata} />
        )}
        {this.renderShippingData(showEmailOnly)}
        <FormHeader headline={ui.guiWordOrder} />
        {this.renderPurchaseDetails()}
      </FormSection>
    );
  }
}

SimReplacementFormConfirm.propTypes = {
  onStepClick: PropTypes.func.isRequired,
  onUpdateMyData: PropTypes.func.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  market: PropTypes.string.isRequired,
  formValues: PropTypes.object.isRequired,
  fieldMap: PropTypes.object.isRequired,
  customerData: PropTypes.object.isRequired,
  selectedSimOption: PropTypes.object,
  isEsim: PropTypes.bool,
  name: PropTypes.string,
  basicFee: PropTypes.number,
  shippingFee: PropTypes.number,
  ui: PropTypes.shape({
    txtMySimrepSummaryCopy: PropTypes.string,
    txtMySimrepEsimStatusHint: PropTypes.string,
    guiWordActive: PropTypes.string,
    mySimrepSimstatusActiveHint: PropTypes.string,
    guiWordInactive: PropTypes.string,
    mySimrepSimstatusNonactiveHint: PropTypes.string,
    guiFormSalutationMale: PropTypes.string,
    guiFormSalutationFemale: PropTypes.string,
    guiFormSalutation: PropTypes.string,
    guiFormNameFirst: PropTypes.string,
    guiFormNameLast: PropTypes.string,
    guiFormDob: PropTypes.string,
    guiFormAddressStreet: PropTypes.string,
    myProfileContactAddressAddon: PropTypes.string,
    myProfileContactCity: PropTypes.string,
    myProfileContactEmail: PropTypes.string,
    guiWordSummary: PropTypes.string,
    mySimrepSummaryCopyEsim: PropTypes.string,
    guiWordPhonenumber: PropTypes.string,
    guiWordAddressdata: PropTypes.string,
    guiWordOrder: PropTypes.string,
    mySimrepPaymentHintPost: PropTypes.string,
    mySimrepPaymentHintPre: PropTypes.string,
    mySimrepSimtypeLabel: PropTypes.string,
    mySimrepSimstatusLabel: PropTypes.string,
    mySimrepCostSim: PropTypes.string,
    guiShippingCosts: PropTypes.string,
    guiWordSum: PropTypes.string,
    guiWordEdit: PropTypes.string,
    mySimrepChooseSimtypeOptionEsim: PropTypes.string,
    mySimrepChooseSimtypeOptionClassic: PropTypes.string,
  }).isRequired,
};

export default SimReplacementFormConfirm;
