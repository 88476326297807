import QueueableRequest from './QueueableRequest';
import { ACCOUNT_MNP_STATUS_ENDPOINT } from '../../config/api';
import { bindParamsToRoute } from '../../helpers/url';

export default class MnpStatusRequest extends QueueableRequest {

  constructor(msisdn, portingMsisdn) {
    super(bindParamsToRoute(ACCOUNT_MNP_STATUS_ENDPOINT, { msisdn, portingMsisdn }));
  }

  isPreventDefaultErrorHandling(error) {
    return (error.fullResponse && error.fullResponse.status === 404)
      || super.isPreventDefaultErrorHandling(error);
  }
}
