import PageModule from './PageModule';
import component from '../../containers/iframe/FreeCodeWidget';

/**
 * The Free Content Widget is a component for integrating
 * HTML, CSS, and JS into the Web page using an iFrame.
 * @see https://confluence.db-n.com/x/1oT3
 */
class FreeCodeWidgetModule extends PageModule {
  constructor(moduleData) {
    super(component, moduleData);
  }
}

export default FreeCodeWidgetModule;
