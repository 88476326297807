import React from 'react';
import PropTypes from 'prop-types';

import suitcss from '../../../../helpers/suitcss';
import Link from '../../../basics/text/TextLink';
import Message from '../Message';
import { MESSAGE_SENDER_USER, MESSAGE_TYPE_EXIT } from '../constants';

const BasarChatInputPanelGameOver = (props) => {
  const { componentName, sendMessage, params } = props;
  return (
    <div className={suitcss({ componentName, descendantName: 'footer' }, this)} >
      <Link
        element="button"
        asButton
        buttonFilled
        onClick={() => {
          // this message will signal the chatbot that the user wants to exit the deal
          sendMessage(
            new Message(MESSAGE_SENDER_USER, { type: MESSAGE_TYPE_EXIT }),
          );
        }}
      >
        {params.ui.btnLabelExit}
      </Link>
    </div>
  );
};

BasarChatInputPanelGameOver.propTypes = {
  componentName: PropTypes.string.isRequired,
  params: PropTypes.object.isRequired,
  sendMessage: PropTypes.func.isRequired,
};

export default BasarChatInputPanelGameOver;
