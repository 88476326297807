import intlDt from 'intl-dt';
import { MARKET_POSTPAID } from './constants';

const toIntegerString = value => `${value < 10 ? 0 : ''}${value}`;
const currentYear = new Date().getUTCFullYear();

const getDays = () => {
  const days = [];
  while (days.length <= 30) {
    days.push({
      label: toIntegerString(days.length + 1),
      value: toIntegerString(days.length + 1),
    });
  }
  return days;
};

const getMonths = () => intlDt.months('de-DE').map((label, index) => ({
  value: toIntegerString(index + 1),
  label,
}));


const getYears = (yearPointer) => {
  const years = [];
  for (yearPointer; yearPointer >= 1920; yearPointer -= 1) { // eslint-disable-line
    years.push({
      label: toIntegerString(yearPointer),
      value: toIntegerString(yearPointer),
    });
  }
  return years;
};

const dates = {
  days: getDays(),
  years: getYears(currentYear),
  // months requires the intl polyfill to be loaded in some clients so it should
  // be generated when requested the first time but not on load
  get months() {
    return getMonths();
  },
  getMarketYears: (market) => (
    getYears(market === MARKET_POSTPAID ? currentYear - 18 : currentYear - 16)
  ),
};

export default dates;
