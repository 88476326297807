import QueueableRequest from './QueueableRequest';
import { PASSWORD_RESET_VIA_SMS_ENDPOINT } from '../../config/api';
import { REQUEST_METHOD_POST } from '../../helpers/constants';

export default class PasswordResetSmsRequest extends QueueableRequest {

  constructor(msisdn) {
    super(PASSWORD_RESET_VIA_SMS_ENDPOINT, {
      method: REQUEST_METHOD_POST,
      payload: { msisdn },
    });
  }
}
