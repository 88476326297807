/* global Blob, Response */
import { HEADER_KEY_TRANSACTION_ID } from '../helpers/constants';
import { camelizeObjectKeys } from '../helpers/str';
import QueueableRequest from './requests/QueueableRequest';

/**
 * Creates a response from an object.
 * @param body
 * @param {Object} headers
 * @param {int} status - httpstatus
 * @return {Response}
 */
export const createFakeResponse = (body, headers, status) => {
  const enhancedData = {
    meta: {
      messages: [],
      notifications: [],
      failures: [],
      events: [],
    },
    ...body,
  };
  const init = {
    type: 'application/json',
    status: status || 200,
    statusText: 'OK',
    headers,
  };
  const blob = new Blob([JSON.stringify(enhancedData, null, 2)]);
  return new Response(blob, init);
};

export const isSuccess = status => status >= 200 && status < 300;

/**
 * Recurses over the properties of the response body to turn
 * all snake-case keys into camleized keys.
 *
 * Adds meta and/or data if not existent.
 * For example, app success responses deviate from our expected response format in that
 * they neither feature a "data" nor a "meta" top level attribute.
 * Moreover SVG responses only come as a string without any json structure.
 *
 * @param {Response} rawResponse
 * @param {string} dataType
 * @returns {Promise}
 */
const getSerializedResponseBody = async (rawResponse, dataType, camelizeResponse) => {
  let respBody;
  const isBinaryResponse = dataType === QueueableRequest.RESPONSE_DATA_TYPE_BINARY;
  try {
    /*
     * We need to ensure that the response was successful if the dataType is equal to
     * QueueableRequest.RESPONSE_DATA_TYPE_TEXT. Otherwise the response error is not
     * parsed correctly.
    */
    const isTextResponse = dataType === QueueableRequest.RESPONSE_DATA_TYPE_TEXT;

    /*
    * TODO: Need for refactoring: a lot of stuff happens
    *  here indirectly without proper responseType validation
    */
    if (isBinaryResponse && isSuccess(rawResponse.status)) {
      respBody = {
        blob: await rawResponse.blob(),
        data: {},
      };
    } else {
      respBody = isTextResponse && isSuccess(rawResponse.status)
        ? await rawResponse.text()
        : await rawResponse.json();
      respBody = camelizeResponse || (isTextResponse && !isSuccess(rawResponse.status))
        ? camelizeObjectKeys(respBody)
        : respBody;
    }

  } catch (exc) {
    // @todo we need to ensure there is at least a blank meta and data
    respBody = {};
  }
  if (!respBody.error && !respBody.data) {
    respBody = {
      data: respBody,
    };
  }
  if (!respBody.meta) {
    respBody.meta = {
      messages: [],
      notifications: [],
      failures: [],
    };
  }

  return respBody;
};

/**
 * Response object
 */
export default class ApiResponse {

  /**
   * Construct new response object from browser's fetch response
   * @param response
   * @param dataType
   * @return {ApiResponse}
   */
  static async createFromResponse(response, dataType, camelizeResponse) {
    const body = await getSerializedResponseBody(response, dataType, camelizeResponse);
    const instance = new ApiResponse(body, response.headers, response.status, response.url);

    instance.redirected = response.redirected;
    return instance;
  }

  constructor(body, headers, status, url = '') {
    this.url = url;
    this.body = body;
    // headers is a param bag; @see https://github.com/github/fetch#response-metadata
    this.headers = headers;
    this.status = status;

    this.isSuccess = (
      isSuccess(status) &&
      // note: the presence of an error property would mean something went wrong,
      // regardless of whether the http status is 2xx
      !body.error
    );

  }

  /**
   * Retrieve transactionId from header
   * @return {string}
   */
  get transactionId() {
    if (this.headers && typeof this.headers.get === 'function') {
      return this.headers.get(HEADER_KEY_TRANSACTION_ID);
    }

    return '';
  }
}
