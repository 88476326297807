import PageModule from './PageModule';
import component from '../../containers/account/belatedMnp/MyBelatedMnpForm';
import MobileProvidersRequest from '../requests/MobileProvidersRequest';
import { sendIfNecessary } from '../../actions/request/send';

/**
 * Module to allow a registered user to port his/her mobile number in hindsight.
 * @see https://confluence.db-n.com/x/r4rr
 */
class MyBelatedMnpFormModule extends PageModule {
  constructor(moduleData) {
    super(component, moduleData);
  }

  /**
   * @override
   */
  prepare(pathname, page) {
    return async dispatch => {
      await dispatch(super.prepare(pathname, page));
      await dispatch(sendIfNecessary(new MobileProvidersRequest()));
    };
  }
}

export default MyBelatedMnpFormModule;
