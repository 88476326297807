import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { updateCartSimType } from '../../../actions/order/cart';
import { bindQueryParams } from '../../../helpers/url';
import connectUI from '../../basics/ui/UIConnector';

import ProcessHeaderItem from './ProcessHeaderItem';
import ProcessHeaderList from './ProcessHeaderList';
import ProcessHeaderPlaceholder from './ProcessHeaderPlaceholder';
import ProcessHeaderSummary from './ProcessHeaderSummary';

import suitcss from '../../../helpers/suitcss';
import { sum } from '../../../helpers/money';
import {
  MARKET_POSTPAID,
  MARKET_PREPAID,
  MARKET_MMO,
  MARKETING_LOCATION_TARIFF_DETAILS,
  PAYMENT_FEE_MONTHLY,
  PAYMENT_FEE_MONTHLY_STRIKE,
  QUERY_SELECTED_TARIFF,
} from '../../../helpers/constants';
import matchMediaConnector from '../../../containers/service/ServiceMatchMedia';
import {
  isTariffOptionEntity,
  isTariffEntity,
  getCheapestHardwareWithTariff,
} from '../../../helpers/entity';
import { removeItem } from '../../../actions/order/process';

const componentName = 'ProcessHeader';

class ProcessHeader extends PureComponent {
  constructor(props) {
    super(props);
    this.removeHardwareItemFromOrderProcess = this.removeHardwareItemFromOrderProcess.bind(this);
  }
  removeHardwareItemFromOrderProcess() {
    const { hardwareEntity, dispatch, isContractRenewal } = this.props;
    dispatch(removeItem(hardwareEntity));
    /**
     * Presales order set updateCartSimType null
     * before continuing to ShoppingCart without hardware
     */
    if (!isContractRenewal) dispatch(updateCartSimType(null));
  }

  render() {
    const {
      routing,
      items,
      progressState,
      activeType,
      validAsBundleOnly,
      onSubmit,
      urlSubmit,
      standalone,
      ui,
      isMediaSM,
      isMediaS,
      hardwareGroup,
      isContractRenewal,
      context,
      shippingFee,
      hardwareEntity,
    } = this.props;

    const currentPath = routing.locationBeforeTransitions.pathname;
    const isTariffPath = currentPath && currentPath.includes('tarife?');
    const isHardwareTariffPath = currentPath && currentPath.includes('smartphone-tarife');
    const hardwareSelected = routing.locationBeforeTransitions.query;
    const isHardwarePath = currentPath && currentPath.includes('hardware' || 'Hardware');
    const isDataTariff = currentPath && currentPath.includes('daten-tarife');
    const isHardwareSelected = activeType === 'hardwareEntity';
    const isTariffSelected = activeType === 'tariffEntity';
    const paymentFeeList = items.map(item => item.paymentFee);
    const singlePaymentFeeList = items.map(item => item.singlePaymentFee);

    const sumMonthly = sum(...paymentFeeList);
    const sumOnce = sum(...singlePaymentFeeList);

    const isBundle = progressState === 'BUNDLE';
    const isSingleConfirmed = progressState === 'SINGLE_PENDING' || 'SINGLE_CONFIRMED';

    const isConfirmed = progressState === 'SINGLE_CONFIRMED' || isBundle;
    const selectedFirst = (
      progressState === 'SINGLE_PENDING' ||
      (progressState === 'BUNDLE' && activeType === items[0].etype)
    );
    const selectedSecond = isConfirmed && !selectedFirst;
    const states = [
      selectedFirst && 'selectedFirst',
      selectedSecond && 'selectedSecond',
    ];
    const [primaryItem] = items;
    // if the item has marketing location don't set it as secondary item
    const secondaryItem = items[1] && isTariffOptionEntity(items[1]) &&
    items[1].marketingLocation.includes(MARKETING_LOCATION_TARIFF_DETAILS) ? undefined : items[1];
    // filter the tariff detail marketing options out of the tariff options
    const tariffOptions = items.filter((entity) => isTariffOptionEntity(entity) &&
      !entity.marketingLocation.includes(MARKETING_LOCATION_TARIFF_DETAILS));
    // filter the first marketed option to add up the paymentfee
    const marketedOption = items.filter(entity => isTariffOptionEntity(entity) &&
      entity.marketingLocation.includes(MARKETING_LOCATION_TARIFF_DETAILS))[0];

    const additionalPaymentFee = marketedOption && {
      [PAYMENT_FEE_MONTHLY]: marketedOption.paymentFee,
      [PAYMENT_FEE_MONTHLY_STRIKE]: marketedOption.paymentFeeStrike,
    };

    const containsTariffOptions = !!tariffOptions.length;
    const btnState = selectedFirst || isBundle || items.length > 1;

    const selectedHardware = hardwareEntity.find(entity => entity.etype === 'hardwareEntity');
    const cheapestHardwareWithTariff = selectedHardware && isHardwareSelected
      && isSingleConfirmed && getCheapestHardwareWithTariff(selectedHardware, null, ui);

    const urlSelect = bindQueryParams(primaryItem.urlSelect, {
      ...(cheapestHardwareWithTariff && {
        [QUERY_SELECTED_TARIFF]: primaryItem.cheapestHardwareWithTariff,
      }),
    });

    const checkoutUrl = isConfirmed ? urlSubmit : urlSelect;
    const { simOnly } = primaryItem;
    const isSimOnlyAndPostpaid = simOnly && primaryItem.market === MARKET_POSTPAID;
    const isPrepaid = primaryItem.market === MARKET_PREPAID || primaryItem.market === MARKET_MMO;
    const label = isHardwareSelected || isHardwarePath ||
      isSingleConfirmed || isTariffSelected || isSimOnlyAndPostpaid
      || isBundle || isContractRenewal;

    const getSummaryItemLabelSecond = () => {
      switch (label) {
        case !isHardwarePath && isSingleConfirmed && isTariffSelected:
          return ui.guiContinueSimPlus;
        case isHardwareSelected && isSingleConfirmed:
          return ui.guiContinueTariff;
        case isHardwareSelected && !isHardwarePath:
          return ui.summaryCallToActionLabel;
        case isContractRenewal && isHardwareSelected:
          return ui.guiWordContinue;
        case isSimOnlyAndPostpaid || isDataTariff:
          return ui.guiAddCart;
        default:
          return ui.summaryCallToActionLabel;
      }
    };

    return (
      <aside className={suitcss({ componentName, states })}>
        <div className={suitcss({ componentName, descendantName: 'inner' })}>
          <section className={suitcss({ componentName, descendantName: 'item' })}>
            <ProcessHeaderItem
              isTariffPath={isTariffPath}
              hardwareSelected={hardwareSelected}
              isHardwareTariffPath={isHardwareTariffPath}
              removeItem={this.removeHardwareItemFromOrderProcess}
              modifiers={[(isBundle || isConfirmed) && 'icon-addition']}
              shouldDisplayLink={!isMediaSM && !standalone && (isBundle || !!primaryItem.urlChange)}
              isContractRenewal={isContractRenewal}
              isMediaS={isMediaS}
              item={primaryItem}
              hardwareGroup={hardwareGroup}
              context={context}
              additionalPaymentFee={
                isTariffEntity(primaryItem) ? additionalPaymentFee : undefined
              }
              ui={{
                callToActionLabel: ui.option1CallToActionLabel,
                singlePaymentPrefix: ui.option1SinglePaymentPrefix,
                singlePaymentFee: ui.option1SinglePaymentFee,
                paymentFee: ui.option1PaymentFee,
              }}
              isSelected={selectedFirst}
            />
          </section>
          <section className={suitcss({ componentName, descendantName: 'item' })}>
            {!secondaryItem &&
            <ProcessHeaderPlaceholder
              headline={isSimOnlyAndPostpaid ? ui.nprNoHardwareBookable : ui.placeholderLabel}
              shouldDisplayLink={!standalone && !isConfirmed}
              url={primaryItem.urlSelect}
              modifiers={[isConfirmed && 'selected']}
            />
            }

            {secondaryItem && !containsTariffOptions &&
            <ProcessHeaderItem
              isTariffPath={isTariffPath}
              hardwareSelected={hardwareSelected}
              isHardwareTariffPath={isHardwareTariffPath}
              removeItem={this.removeHardwareItemFromOrderProcess}
              item={secondaryItem}
              hardwareGroup={hardwareGroup}
              shouldDisplayLink={!isMediaSM && !standalone}
              isContractRenewal={isContractRenewal}
              additionalPaymentFee={
                isTariffEntity(secondaryItem) ? additionalPaymentFee : undefined
              }
              ui={{
                callToActionLabel: ui.option2CallToActionLabel,
                singlePaymentPrefix: ui.option2SinglePaymentPrefix,
                singlePaymentFee: ui.option2SinglePaymentFee,
                paymentFee: ui.option2PaymentFee,
              }}
              isSelected={selectedSecond}
            />
            }
            {secondaryItem && containsTariffOptions &&
            <ProcessHeaderList
              headline={ui.options2ListHeadline}
              items={tariffOptions}
            />
            }
          </section>
          <footer className={suitcss({ componentName, descendantName: 'item' })}>
            <ProcessHeaderSummary
              hardwareSelected={hardwareSelected}
              isContractRenewal={isContractRenewal}
              isHardwarePath={isHardwarePath}
              paymentFee={sumMonthly}
              singlePaymentFee={sumOnce}
              onSubmit={isConfirmed || isSimOnlyAndPostpaid ? () => onSubmit(items) : () => null}
              isDisabled={validAsBundleOnly && !isBundle}
              isConfirmed={isConfirmed || isSimOnlyAndPostpaid}
              url={isSimOnlyAndPostpaid ? urlSubmit : checkoutUrl}
              secondarySelected={btnState}
              ui={{
                callToActionLabel: getSummaryItemLabelSecond(),
                continueLabel: ui.guiWordContinue,
                singlePaymentFee: ui.summarySinglePaymentFee,
                paymentFee: isPrepaid ? ui.guiSumDay28 : ui.summaryPaymentFee,
                shippingHint: ui.guiWordPlusShippingFee,
              }}
              shippingFee={shippingFee}
            />
          </footer>
          {!standalone &&
          <span className={suitcss({ componentName, descendantName: 'selector' })} />
          }
        </div>
      </aside>
    );
  }
}

ProcessHeader.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      eid: PropTypes.string.isRequired,
      // only one hardwareEntity and one tariffEntity are allowed
      // tariffOptionEntity are is not compatible with hardwareEntity
      etype: PropTypes.oneOf([
        'hardwareEntity',
        'tariffEntity',
        'tariffOptionEntity',
      ]).isRequired,
      headline: PropTypes.string.isRequired,
      subline: PropTypes.string,
      paymentFee: PropTypes.object.isRequired,
      paymentFeeStrike: PropTypes.object,
      singlePaymentFee: PropTypes.object.isRequired,
      singlePaymentFeeStrike: PropTypes.object,
      urlSelect: PropTypes.string,
      urlChange: PropTypes.string,
    }),
  ),
  progressState: PropTypes.oneOf([
    'SINGLE_PENDING',
    'SINGLE_CONFIRMED',
    'BUNDLE',
  ]).isRequired,
  activeType: PropTypes.oneOf([
    'hardwareEntity',
    'tariffEntity',
  ]),
  validAsBundleOnly: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  urlSubmit: PropTypes.string,
  context: PropTypes.string,
  standalone: PropTypes.bool,
  ui: PropTypes.object.isRequired,
  isMediaSM: PropTypes.bool.isRequired,
  isMediaS: PropTypes.bool.isRequired,
  hardwareGroup: PropTypes.object,
  hardwareEntity: PropTypes.array,
  routing: PropTypes.object.isRequired,
  isContractRenewal: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  shippingFee: PropTypes.object,
};

export default compose(
  connectUI(),
  matchMediaConnector(['isMediaSM', 'isMediaS']),
)(ProcessHeader);

