/* global document, window */
import smoothScroll from 'smoothscroll';


export const NAVIGATION_MAIN_SIZE = 54;
export const NAVIGATION_MAIN_FACELIFT_SIZE = 70;
export const NAVIGATION_MAIN_FACELIFT_SCROLLED_SIZE = NAVIGATION_MAIN_SIZE;
export const NAVIGATION_MAIN_FACELIFT_TURNING_POINT = 95;
export const NAVIGATION_TOPBAR_SIZE = 40;
export const NAVIGATION_FULL_SIZE = NAVIGATION_MAIN_SIZE + NAVIGATION_TOPBAR_SIZE;

export const getStickyContentHeight = () => {
  let height = 0;
  if (process.browser) {
    [...document.querySelectorAll('.ServiceStickyContent-canvas')].forEach(el => {
      // Compensate for sticky content heights + transforms if fixed
      if (el.style.position === 'fixed') {
        const bounds = el.getBoundingClientRect();
        height += bounds.height + bounds.top;
      }
    });
  }

  return height;
};

export const getHeaderHeight = () => {
  let height = 0;
  if (process.browser) {
    const selectors = ['.ServiceStickyContent--header .ServiceStickyContent-canvas'];
    selectors.forEach(selector => {
      const el = [...document.querySelectorAll(selector)][0];
      if (el) {
        height += el.clientHeight;
      }
    });
  }

  return height;
};

export const scrollToPos = (pos, config) => {
  if (process.browser) {
    const scrollToPadding = 30;
    const settings = {
      offset: scrollToPadding + NAVIGATION_MAIN_SIZE,
      contentAware: true,
      delay: 100,
      ...(config || {}),
    };
    // use timeout to ensure dom has updated (accordions)
    setTimeout(() => {
      const stickyContentHeight = settings.contentAware ? getStickyContentHeight() : 0;
      const oy = stickyContentHeight + settings.offset;
      smoothScroll((pos) - oy);
    }, settings.delay);
  }
};

export const scrollToTop = () => {
  if (process.browser) {
    scrollToPos(0, { offset: 0, contentAware: false });
  }
};

export const scrollToElement = (el, config) => {
  if (el) {
    scrollToPos(el.getBoundingClientRect().top + window.pageYOffset, config);
  }
};

export const scrollToHash = (hash = '', config) => {
  if (process.browser && hash) {
    const el = document.querySelector(hash);
    scrollToElement(el, config);
  }
};

// helper function to ensure that the target is valid
// * bug - input field auto complete fires an
// unrelated event on mouseLeave
// * fix (mouseout) - filter related targets till either related
// is the current target (meaning the event is from within)
// or is not the current target (meaning we've left the target)
// TRUE MOUSE OOOOOOOUTTT!

export const hasMouseLeftTarget = (evt) => {
  const target = evt.target;
  let related = evt.relatedTarget;
  while (
    related &&
    related !== target &&
    related.nodeName !== 'BODY'
  ) {
    related = related.parentNode;
  }
  return related && related !== target;
};

/* Prevent / Allow document scrolling */
export const toggleDocumentScrolling = (blocked, resizeToViewport) => {
  if (process.browser) {
    const el = document.querySelector('[data-app="main"]');
    // scroll to top - if blocking set container height / ios body:hidden bug
    if (resizeToViewport) {
      scrollToTop();
      el.style.height = blocked ? `${window.innerHeight}px` : '';
      el.style.overflow = blocked ? 'hidden' : '';
    } else if (!blocked) {
      el.style.height = '';
      el.style.overflow = '';
    }
    // remove or set no-scroll class
    if (blocked) {
      document.documentElement.classList.add('no-scroll');
    } else {
      document.documentElement.classList.remove('no-scroll');
    }
  }
};
