import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { trackClick } from '../../actions/tracking/event';
import {
  UI_COLLECTION_CAPTCHA_SERVICE,
} from '../../helpers/constants';
import CaptchaComposition from '../../components/compositions/captcha/Captcha';
import {
  fetchCaptchaData,
  fetchUiElements,
} from '../../actions/request/registry';
import { unregisterCaptcha } from '../../actions/captcha/captcha';

/*
  =======================================================================
  | How to use
  =======================================================================

  1. Add this Component to the particular form you want a Captcha on

  2. Add this snippet to the form-config file of the form

     captcha: {
        name: 'captcha',
        label: 'captchaLabel',
        validation: {
          isRequired: true,
          range: [3, 3],
        },
      },

  3. Add snippet to Container-Component of the From to get captchaToken from the redux-state

      const mapStateToProps = (state) => {
          return {
            captchaToken: state.captcha.token,
        };
      };

  4. Add Redux-State value to getSubmitRoute

      getSubmitRoute(fieldMap, finalizedFormValues) {
      const { captchaToken } = this.props;
      debugger
      return new XXXXRequest({
        ...finalizedFormValues,
        captcha: {
          token: captchaToken,
          solution: finalizedFormValues.captcha ? finalizedFormValues.captcha : '',
        },
      });
    }
*/

class CaptchaService extends Component {
  loadCaptcha = async (event) => {
    const { dispatch } = this.props;
    if (event) {
      event.preventDefault();
    }
    await dispatch(fetchCaptchaData());
  };

  async componentDidMount() {
    const { dispatch } = this.props;
    await this.loadCaptcha();
    dispatch(fetchUiElements([UI_COLLECTION_CAPTCHA_SERVICE]));
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(unregisterCaptcha());
  }

  render() {
    const { ui, captcha, lineLength } = this.props;
    return (
      <CaptchaComposition
        trackDetail={() => {}}
        ui={ui}
        captcha={captcha}
        reloadCaptcha={this.loadCaptcha}
        lineLength={lineLength}
      />
    );
  }
}

CaptchaService.propTypes = {
  trackDetail: PropTypes.func.isRequired,
  dispatch: PropTypes.func,
  ui: PropTypes.object,
  captcha: PropTypes.object.isRequired,
  lineLength: PropTypes.array,
};

const mapStateToProps = (state) => ({
  ui: state.ui,
  captcha: state.captcha,
});

/* eslint-disable */
const mapDispatchToProps = (dispatch) => ({
  dispatch,
  trackDetail: answerId => {
    dispatch(trackClick({}));
  },
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(CaptchaService);
