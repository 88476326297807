import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../../helpers/suitcss';
import Headline from '../../../basics/text/TextHeadline';
import MyESimPushForm from '../../../../containers/account/eSim/MyESimPushForm';

const componentName = 'MyESimActivation';

class MyESimPushContent extends PureComponent {
  render() {
    const {
      ui,
      methodDescription,
    } = this.props;
    return (
      <Fragment>
        <div
          className={suitcss(
            {
              componentName,
              descendantName: 'sectionTop',
              utilities: ['paddingBottomS'],
            },
          )}
        >
          <div>
            <Headline embedded size="s" utilities={['weightBold']}>
              {ui.myEsimActivationPushHeadline}
            </Headline>
            {methodDescription()}
          </div>
        </div>
        <div className={suitcss({ componentName, descendantName: 'sectionBottom' }, this)}>
          <MyESimPushForm />
        </div>
      </Fragment>
    );
  }
}

MyESimPushContent.propTypes = {
  ui: PropTypes.object.isRequired,
  methodDescription: PropTypes.func.isRequired,
};

export default MyESimPushContent;
