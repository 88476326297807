import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { subscriptionItemShape } from '../../propTypes/account';
import {
  VO_TYPE_TARIFF,
  VO_TYPE_TARIFFOPTION,
  QUERY_INTERNAL_ID,
  QUERY_IS_BOOKED,
  MYTRACK_CONTRACT_CANCELLATION_DETAILS,
  MYTRACK_CONTRACT_CANCEL_CANCELLATION_DETAILS,
  CONTEXT_TARIFF_CHANGE,
  CONTEXT_CONTRACT_RENEWAL,
} from '../../helpers/constants';
import { isTariffVO } from '../../helpers/entity';
import * as statusTariffActions from '../../actions/dialog/statusTariffActions';
import * as registryActions from '../../actions/request/registry';
import * as contractRenewalActions from '../../actions/dialog/contractRenewalActions';
import * as trackingActions from '../../actions/tracking/event';
import Callout from '../callout/Callout';
import GlobalSection from '../../components/basics/global/GlobalSection';
import MyTariffDetailsComposition from '../../components/compositions/account/MyTariffDetails';
import Copy from '../../components/basics/text/TextCopy';
import ContentLoader from '../../components/compositions/content/ContentLoader';
import { createNormalizeTargetEntitiesSelector } from '../../selectors/entity';
import QueueableRequest from '../../model/requests/QueueableRequest';

class MyTariffDetails extends PureComponent {

  componentDidMount() {
    const {
      type,
      selectedItem,
      selectedItemId,
      fetchTariffs,
      fetchTariffOption,
      fetchMyPromotions,
      fetchContractData,
    } = this.props;

    if (type === VO_TYPE_TARIFF && !selectedItem) {
      fetchTariffs();
    }

    if (type === VO_TYPE_TARIFFOPTION && !selectedItem) {
      fetchTariffOption(selectedItemId);
    }
    fetchContractData({ priority: QueueableRequest.PRIO_80 });
    fetchMyPromotions({ priority: QueueableRequest.PRIO_20 });
  }

  render() {
    const {
      market,
      selectedItem,
      params,
      msisdn,
      onSelect,
      onCancel,
      onChange,
      isBookedByUser,
      isMultipleTariffsForUser,
      tariffMode,
    } = this.props;
    if (!msisdn) return null;
    return (
      <div>
        {selectedItem && selectedItem.type === VO_TYPE_TARIFF && !isBookedByUser && (
          <Callout
            theme="label"
            context={selectedItem.renewable || selectedItem.renewContract
              ? CONTEXT_CONTRACT_RENEWAL
              : CONTEXT_TARIFF_CHANGE
            }
            targets={[selectedItem.eid]}
            bordered
            expanded
          />
        )}
        <GlobalSection layout="contained">
          <ContentLoader isLoaded={!!selectedItem} height={380}>
            {selectedItem && (
              <MyTariffDetailsComposition
                market={market}
                item={selectedItem}
                footer={params.footer}
                onSelect={onSelect}
                onCancel={onCancel}
                onChange={onChange}
                isBookedByUser={isBookedByUser}
                isMultipleTariffsForUser={isMultipleTariffsForUser}
                tariffMode={tariffMode}
              />
            )}
          </ContentLoader>
        </GlobalSection>
        <GlobalSection>
          {selectedItem && (
            <Copy embedded raw>
              {selectedItem.copy || selectedItem.longCopy}
            </Copy>
          )}
        </GlobalSection>
      </div>
    );
  }
}

MyTariffDetails.propTypes = {
  type: PropTypes.string.isRequired,
  params: PropTypes.object,
  market: PropTypes.string,
  msisdn: PropTypes.string,
  tariffMode: PropTypes.string,
  isBookedByUser: PropTypes.bool,
  isMultipleTariffsForUser: PropTypes.bool,
  selectedItemId: PropTypes.string,
  selectedItem: subscriptionItemShape,
  onCancel: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  fetchTariffs: PropTypes.func.isRequired,
  fetchTariffOption: PropTypes.func.isRequired,
  fetchMyPromotions: PropTypes.func.isRequired,
  fetchContractData: PropTypes.func.isRequired,
};

const getType = (pathname, sitemap) => {
  switch (pathname) {
    case sitemap.MyTariffDetailsRoute.url:
      return VO_TYPE_TARIFF;
    case sitemap.MyTariffOptionDetailsRoute.url:
      return VO_TYPE_TARIFFOPTION;
    default:
      return null;
  }
};

const getItem = (id, type, { entities }) => {
  const { tariffVO, tariffOptionVO } = entities;
  switch (type) {
    case VO_TYPE_TARIFF:
      return tariffVO[id];
    case VO_TYPE_TARIFFOPTION:
      return tariffOptionVO[id];
    default:
      return null;
  }
};

const getNormalizedItem = (type, market, state, ownProps) => {
  const { location } = ownProps;
  const selectedItemId = location.query[QUERY_INTERNAL_ID];
  const isBookedByUser = Boolean(location.query[QUERY_IS_BOOKED]);
  const item = selectedItemId && getItem(selectedItemId, type, state);
  const eid = item && state.site.internalIdMap[item.id];
  return item
    ? {
      market,
      ...(isBookedByUser ? (item.original || item) : item),
      type,
      eid,
    }
    : null;
};

function mapStateToProps() {
  const myOptionSelector = createNormalizeTargetEntitiesSelector({ style: 'strike', context: 'bookTariffoption' });
  return (state, ownProps) => {
    const { user, site, orderProcess, entities } = state;
    const { params, location, ui } = ownProps;
    const { market } = user;
    const isBookedByUser = Boolean(location.query[QUERY_IS_BOOKED]);
    const isMultipleTariffsForUser = Object.keys(entities.tariffVO).length > 1;
    const selectedItemId = location.query[QUERY_INTERNAL_ID];
    const type = getType(location.pathname, site.sitemap);
    const normalizedItem = getNormalizedItem(type, market, state, ownProps);
    const selectedItem = isBookedByUser || type === VO_TYPE_TARIFF
      ? normalizedItem
      : myOptionSelector(state, [normalizedItem])[0];
    return {
      params,
      market,
      msisdn: user.credentials.msisdn,
      sitemap: site.sitemap,
      tariffMode: orderProcess.tariffMode,
      isBookedByUser,
      isMultipleTariffsForUser,
      normalizedItem,
      selectedItem,
      selectedItemId,
      type,
      ui,
    };
  };
}

const mapDispatchToProps = {
  push,
  fetchTariffs: registryActions.fetchTariffs,
  fetchTariffOption: registryActions.fetchTariffOption,
  fetchMyPromotions: registryActions.fetchMyPromotions,
  fetchContractData: registryActions.fetchContractData,
  startContractRenewalFromTariff: contractRenewalActions.startContractRenewalFromTariff,
  showTariffChangeDialog: contractRenewalActions.showTariffChangeDialog,
  cancelTariffOption: statusTariffActions.cancelTariffOption,
  withdrawTariffCancellation: statusTariffActions.withdrawTariffCancellation,
  bookOption: statusTariffActions.bookOption,
  trackMyState: trackingActions.trackMyState,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { selectedItem, type, sitemap } = stateProps;
  const {
    cancelTariffOption,
    bookOption,
    withdrawTariffCancellation,
    startContractRenewalFromTariff,
    showTariffChangeDialog,
    trackMyState,
  } = dispatchProps;
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onCancel: () => {
      const isTariff = isTariffVO(selectedItem);

      if (isTariff && !selectedItem.canceled) {
        trackMyState(MYTRACK_CONTRACT_CANCELLATION_DETAILS);
        dispatchProps.push(sitemap.MyCancellationFormRoute.url);
      } else if (isTariff && selectedItem.canceled) {
        trackMyState(MYTRACK_CONTRACT_CANCEL_CANCELLATION_DETAILS);
        withdrawTariffCancellation(selectedItem);
      } else if (!isTariff && !selectedItem.canceled) {
        cancelTariffOption(selectedItem);
      }
    },
    onSelect: (iid, isHardwareRequired) => {
      if (type === VO_TYPE_TARIFF) {
        startContractRenewalFromTariff(iid, isHardwareRequired);
      } else if (type === VO_TYPE_TARIFFOPTION) {
        bookOption(selectedItem);
      }
    },
    onChange: () => {
      showTariffChangeDialog(selectedItem);
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(MyTariffDetails);
