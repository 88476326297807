import PropTypes from 'prop-types';

export const propTypes = {
  eid: PropTypes.string.isRequired,
  etype: PropTypes.string.isRequired,
  etitle: PropTypes.string.isRequired,
  brand: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  priority: PropTypes.number.isRequired,
  topseller: PropTypes.bool.isRequired,
};

export const shape = PropTypes.shape(propTypes);
export const list = PropTypes.arrayOf(shape);
