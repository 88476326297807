import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import MediaImage from '../../basics/media/MediaImage';
import TextHeadline from '../../basics/text/TextHeadline';
import FormOptionBox from '../../basics/form/FormOptionBox';
import suitcss from '../../../helpers/suitcss';
import imageSizes from '../../../config/imageSizes';
import { ENTITY_TYPE_TARIFFOPTION } from '../../../helpers/constants';
import { stripHTML } from '../../../helpers/str';

class TariffOptionTeaser extends PureComponent {

  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    const { optionId, addItem, removeItem } = this.props;
    const item = {
      eid: optionId,
      etype: ENTITY_TYPE_TARIFFOPTION,
    };
    if (event.target.checked) {
      addItem(item, true);
    } else {
      removeItem(item);
    }
  }

  render() {
    const {
      image,
      headline,
      copy,
      active,
      uniqueId,
      info,
    } = this.props;

    return (
      <div className={suitcss({ utilities: ['flex'] }, this)} >
        <MediaImage {...image} sizes={imageSizes.tariffOptionDetailImage} isLazy />
        <div>
          <TextHeadline highlight size="xs" bold>{headline}</TextHeadline>
          <FormOptionBox
            checked={!!active}
            onChange={this.handleInputChange}
            label={stripHTML(copy, 'p')}
            id={uniqueId}
            info={info && {
              headline,
              copy: info,
            }}
            utilities={['colorInverted']}
          />
        </div>
      </div>
    );
  }
}

TariffOptionTeaser.propTypes = {
  image: PropTypes.object,
  headline: PropTypes.string.isRequired,
  addItem: PropTypes.func.isRequired,
  removeItem: PropTypes.func.isRequired,
  active: PropTypes.bool,
  optionId: PropTypes.string.isRequired,
  copy: PropTypes.string.isRequired,
  uniqueId: PropTypes.string.isRequired,
  info: PropTypes.string,
};

export default TariffOptionTeaser;
