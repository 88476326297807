import React from 'react';
import suitcss from '../../../helpers/suitcss';

import { copyPropTypes } from '../../../propTypes/typography';
import TextRaw from './TextRaw';

function TextCopy(props) {
  // styling only per utility classes -
  // props are only to get sure, that unrefactored elements will work
  const utilities = [
    props.uppercase && 'uppercase',
    props.inverted && 'colorInverted',
    props.condensed && 'fontCondensed',
    ...props.utilities,
  ];

  const className = suitcss({
    className: props.className,
    componentName: 'TextCopy',
    modifiers: [
      props.size,
      props.embedded && 'embedded',
      props.raw && 'raw',
    ],
    utilities,

  });

  if (props.raw && !props.postfix) {
    return (
      <TextRaw
        className={className}
        element={props.element === 'p' ? 'div' : props.element}
        onClick={props.onClick}
      >
        {props.children}
      </TextRaw>
    );
  }

  return (
    <props.element className={className} onClick={props.onClick}>
      {!props.postfix && props.children}
      {props.postfix && props.raw && (<TextRaw>{props.children}</TextRaw>)}
      {props.postfix && !props.raw && (<span>{props.children}</span>)}
      {props.postfix && (<span>{props.postfix}</span>)}
    </props.element>
  );
}

TextCopy.propTypes = copyPropTypes;

TextCopy.defaultProps = {
  size: 'default',
  element: 'p',
  raw: false,
  utilities: [],
};

export default TextCopy;

