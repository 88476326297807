import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import copyToClipboard from 'copy-to-clipboard';
import { showDialog } from '../../../../actions/page/dialog';
import { showESimStatusRefresh } from '../../../../actions/user/eSim';
import Headline from '../../../basics/text/TextHeadline';
import { MARKET_PREPAID } from '../../../../helpers/constants';
import { isWebView } from '../../../../helpers/site';
import suitcss from '../../../../helpers/suitcss';
import SvgLoader from '../../../basics/media/MediaSvgLoader';
import Tab from '../../../basics/tab/Tab';
import TabsWrapper from '../../../basics/tab/TabWrapper';
import Tag from '../../../basics/text/Tag';
import Copy from '../../../basics/text/TextCopy';
import Link from '../../../basics/text/TextLink';
import connectUI from '../../../basics/ui/UIConnector';
import ContentLoader from '../../content/ContentLoader';
import * as bridge from '../../../../services/bridge';
import MyESimQRContent from './MyESimQRContent';
import MyESimManuallyContent from './MyESimManuallyContent';
import MyESimPushContent from './MyESimPushContent';

const componentName = 'MyESimActivation';

class MyESimActivation extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isAccordionOpen: false,
      copiedText: null,
    };
  }

  onCopyText(text) {
    if (!isWebView()) {
      copyToClipboard(text);
    } else {
      bridge.setCopyText(text);
    }
    this.setState({ copiedText: text });
    if (this.copyTimeout) clearTimeout(this.copyTimeout);
    this.copyTimeout = setTimeout(() => this.setState({ copiedText: null }), 300);
  }

  showStatusRefresh() {
    const { dispatch } = this.props;
    if (!this.isEnabledToPush()) {
      return;
    }
    if (this.statusTimeout) {
      clearTimeout(this.timeout);
    }
    this.statusTimeout = setTimeout(() => {
      dispatch(showESimStatusRefresh(true));
    }, 60000);
  }

  isEnabledToPush() {
    const { user } = this.props;
    return user.market !== MARKET_PREPAID;
  }

  toggleTextVisibility(e, textType) {
    const { statusRefresh } = this.props;
    this.setState((prevState) => ({
      ...prevState,
      [textType]: !prevState[textType],
    }));
    if (!statusRefresh) this.showStatusRefresh();
  }

  renderESimDataText(text, textType, withCopyText = false, withToggleText = true) {
    const toggleVisibilityIcon = (isHidden) =>
      `/icons/icon-visibility-${isHidden ? 'shown' : 'hidden'}.svg`;
    const copyIcon = `/icons/content-copy${this.state.copiedText === text ? '-filled' : ''}.svg`;
    return (
      <p
        className={suitcss(
          {
            descendantName: 'dataText',
            utilities: ['flex', 'itemsCenter'],
          },
          this,
        )}
      >
        <span className="u-alignMiddle u-textBreakWord">
          {!this.state[textType] && text && withToggleText ? '*'.repeat(5) : text}
        </span>
        {withToggleText && (
          <span
            className={suitcss(
              {
                descendantName: 'visibilityIcon',
                utilities: ['marginLeftXS', 'u-alignMiddle'],
              },
              this,
            )}
            onClick={(e) => this.toggleTextVisibility(e, textType)}
          >
            <SvgLoader path={toggleVisibilityIcon(!this.state[textType])} />
          </span>
        )}
        {((withCopyText && withToggleText && this.state[textType])
          || (withCopyText && !withToggleText)) && (
          <span
            className={suitcss(
              {
                descendantName: 'copyIcon',
                utilities: ['marginLeftXS', 'u-alignMiddle'],
              },
              this,
            )}
            onClick={() => this.onCopyText(text)}
          >
            <SvgLoader path={copyIcon} />
          </span>
        )}
      </p>
    );
  }

  renderMethodDescription(text, type) {
    const { showESimInfoDialog } = this.props;
    return (
      <div className="u-marginTopXXS">
        <Copy element="span">{text}</Copy>
        <Link
          className={suitcss(
            { descendantName: 'infoIcon', utilities: ['marginLeftXXS'] },
            this,
          )}
          element="button"
          onClick={() => showESimInfoDialog(type)}
          withoutArrow
          withoutStyle
          icon="/icons/content-info.svg"
          size="small"
        />
      </div>
    );
  }

  renderQRContent() {
    const { ui, eSimData } = this.props;
    const { qr } = eSimData;
    return (
      <MyESimQRContent
        ui={ui}
        qr={qr}
        methodDescription={() => this.renderMethodDescription(ui.myEsimActivationQrSubline, 'qr', this)}
      >
        {qr.confirmationCode
          && this.renderESimDataText(
            qr.confirmationCode,
            `qr-${ui.myEsimActivationConfirmationCode}`,
            true,
          )}
      </MyESimQRContent>
    );
  }

  renderPushContent() {
    const { ui, dispatch } = this.props;
    const isEnabledToPush = this.isEnabledToPush();
    if (!isEnabledToPush) return null;
    return (
      <MyESimPushContent
        ui={ui}
        dispatch={dispatch}
        methodDescription={() => this.renderMethodDescription(ui.myEsimActivationPushSubline, 'push', this)}
      />
    );
  }

  renderManuallyData(text, type, withCopy, withToggle) {
    return (
      <div key={text} className={suitcss({ utilities: ['marginBottomS'] })}>
        <Copy utilities={['weightBold']}>{type}</Copy>
        {text
          && this.renderESimDataText(
            text,
            type,
            withCopy,
            withToggle,
          )}
      </div>
    );
  }

  renderManuallyContent() {
    const { ui, eSimData } = this.props;
    const { information } = eSimData;
    const eSimManuallyDataList = (isIOS = false) => (Object.keys(information).length ? [
      ...(isIOS
        ? [
          {
            text: information.smdpPlus,
            type: ui.myEsimActivationManuallySmdp,
            withCopy: true,
            withToggle: false,
          },
          {
            text: information.activationCode,
            type: ui.myEsimActivationCode,
            withCopy: true,
            withToggle: false,
          },
        ]
        : [
          {
            text: information.completeActivationCode,
            type: ui.myEsimActivationSmdpActivationCode,
            withCopy: true,
            withToggle: false,
          },
        ]
      ),
      {
        text: information.confirmationCode,
        type: ui.myEsimActivationConfirmationCode,
        withCopy: true,
        withToggle: true,
      },
    ] : []);
    return (
      <MyESimManuallyContent
        ui={ui}
        eSimData={eSimData}
        methodDescription={() =>
          this.renderMethodDescription(ui.myEsimActivationManuallySubline, 'manually', this)}
      >
        <TabsWrapper selected={0} withoutBorder centered={false}>
          <Tab label={ui.myEsimActivationAndroid}>
            {eSimManuallyDataList().map((data) =>
              this.renderManuallyData(data.text, data.type, data.withCopy, data.withToggle))}
          </Tab>
          <Tab label={ui.myEsimActivationIos}>
            {eSimManuallyDataList(true).map((data) =>
              this.renderManuallyData(data.text, data.type, data.withCopy, data.withToggle))}
          </Tab>
        </TabsWrapper>
      </MyESimManuallyContent>
    );
  }

  renderSection(type) {
    const { ui, dispatch, eSimData } = this.props;
    const { qr } = eSimData;
    const isEnabledToPush = this.isEnabledToPush();
    let content;
    if (type === 'qr') {
      content = this.renderQRContent('qr');
    } else if (type === 'push') {
      content = this.renderPushContent('push');
    } else {
      content = this.renderManuallyContent('manually');
    }
    const tag = `<span class="sticker u-elementInfoAfterInverted u-uppercase">${ui.myEsimActivationSticker}</span>`;
    return (
      <div
        className={suitcss(
          {
            descendantName: 'section',
            modifiers: [type],
            utilities: [...(type === 'qr' ? ['col12'] : ['sCol12', !isEnabledToPush ? 'col12' : 'col6'])],
          },
          this,
        )}
        key={type}
      >
        {type !== 'manually' && (
          <div onClick={() => dispatch(showDialog({
              headline: ui.myEsimActivationSticker,
              copy: ui.myEsimActivationStickerDialogCopy,
              actions: [{ label: ui.guiWordAllright }],
            }))}
          >
            <Tag
              utilities={[type === 'qr' ? 'sHidden' : 'mlHidden']}
              theme="tertiary"
              label={tag}
            />
          </div>
        )}
        <ContentLoader isLoaded={!!qr.quickResponseCode}>{content}</ContentLoader>
      </div>
    );
  }

  render() {
    const { ui } = this.props;
    const contentTypes = ['qr', 'push', 'manually'];
    const isEnabledToPush = this.isEnabledToPush();
    return (
      <div
        className={suitcss({
          componentName,
          states: [!isEnabledToPush && 'withoutPush'],
        })}
      >
        <div className={suitcss({ descendantName: 'inner' }, this)}>
          <Headline size="xl" embedded utilities={['weightBold']}>
            {ui.myEsimActivationHeadline}
          </Headline>
          <Copy embedded>{ui.myEsimActivationSubline}</Copy>
          <div className={suitcss({ utilities: ['grid', isEnabledToPush && 'itemsStart', 'marginTopXXS'] })}>
            {contentTypes.map((type) => this.renderSection(type))}
          </div>
        </div>
      </div>
    );
  }
}

MyESimActivation.propTypes = {
  ui: PropTypes.object.isRequired,
  eSimData: PropTypes.object.isRequired,
  showESimInfoDialog: PropTypes.func,
  dispatch: PropTypes.func,
  user: PropTypes.object,
  statusRefresh: PropTypes.bool,
};

export default connectUI()(MyESimActivation);
