import PropTypes from 'prop-types';

export const priceShape = PropTypes.shape({
  unit: PropTypes.number,
  currency: PropTypes.oneOf(['EUR']),
});

export const propTypes = {
  price: priceShape.isRequired,
  prefix: PropTypes.string,
  postfix: PropTypes.string,
  size: PropTypes.oneOf(['inherit', 's', 'm', 'l']),
  showCurrency: PropTypes.bool,
  inverted: PropTypes.bool,
  bold: PropTypes.bool,
};

export const shape = PropTypes.shape(propTypes);
export const list = PropTypes.arrayOf(shape);
