import { GENERIC_FRONTEND_ERROR } from '../../config/errorCodes';
import LogContext from '../logging/LogContext';

/**
 * Error super class from which all errors should extend from.
 *
 * @see https://confluence.db-n.com/x/Wtzc
 *
 * @param {string} fullCode - The full error code as it is returned from the exception
 * @param {LogContext} context
 */
function AbstractError(fullCode, context) {
  this.fullCode = fullCode || GENERIC_FRONTEND_ERROR;

  /**
   * the "code" is the last part of the full error code.
   * In case of middleware errors, the "code" part is the middleware error code.
   * @type {string}
   */
  this.code = (/.+-(\d+)$/.exec(fullCode) || [])[1] || '';

  /**
   * The context will be picked up by our logging system.
   * @type {LogContext}
   */
  this.context = context || new LogContext();
  if (Error.captureStackTrace) {
    Error.captureStackTrace(this, this.constructor);
  } else { // fallback
    this.stack = (new Error()).stack;
  }

  /**
   * The native Javascript error (or an
   * instance of AbstractError) that was previously catched.
   * @type {Error}
   */
  this.previous = undefined;
}

/**
 * WE CANNOT USE ES6 SYNTAX!
 * @see http://stackoverflow.com/questions/31089801/extending-error-in-javascript-with-es6-syntax
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error#Custom_Error_Types
 *
 * At one point we should move to ES6 inheritance though...
 */
AbstractError.prototype = Object.create(Error.prototype, {
  constructor: {
    value: Error,
    enumerable: false,
    writable: true,
    configurable: true,
  },
});

if (Object.setPrototypeOf) {
  Object.setPrototypeOf(AbstractError, Error);
} else {
  // @todo needed for legacy browsers; remove this in mid 2018
  AbstractError.__proto__ = Error; // eslint-disable-line no-proto
}

AbstractError.prototype.setFullCode = function (fullCode) {
  this.fullCode = fullCode;
};

AbstractError.prototype.setSilent = function (isSilent) {
  this._isSilent = isSilent;
};

AbstractError.prototype.isSilent = function () {
  return this._isSilent;
};

AbstractError.prototype.setContext = function (context) {
  this.context = context;
};

AbstractError.prototype.getContext = function () {
  return this.context;
};

AbstractError.prototype.getVerbalization = function (ui) {
  const text = (
    ui[`err${this.fullCode}`] ||
    ui[`err${this.code}`] || // fallback to (middleware) code
    ui.guiGenericErrorMessage ||
    'unknown error' // last resort
  );
  return `${text} (${this.fullCode})`;
};

/**
 * Anything that is worth logging.
 * Extend in base class!
 *
 * @return {object}
 */
AbstractError.prototype.getLoggingData = function () {
  return {
    previous: (
      this.previous && JSON.stringify(this.previous, Object.getOwnPropertyNames(this.previous))
    ),
  };
};

AbstractError.prototype.toString = function () {
  return this.fullCode;
};

AbstractError.prototype.getPrevious = function () {
  return this.previous;
};

AbstractError.prototype.setPrevious = function (previous) {
  this.previous = previous;
};

export default AbstractError;
