import React, { PureComponent } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import suitcss from '../../../../helpers/suitcss';
import { toIntegerDigits, toCurrencySymbol, toDecimal } from '../../../../helpers/money';
import {
  MARKET_POSTPAID,
  MYTRACK_DASHBOARD_OPEN_POBOX,
} from '../../../../helpers/constants';
import { capitalize } from '../../../../helpers/str';
import connectUI from '../../../basics/ui/UIConnector';
import ContentLoader from '../../content/ContentLoader';
import DashboardStatusMeter from './DashboardStatusMeter';
import Headline from '../../../basics/text/TextHeadline';
import Copy from '../../../basics/text/TextCopy';
import Unit from '../../../basics/text/TextUnit';
import Link from '../../../basics/text/TextLink';
import UILinearProgressBar from '../../../basics/ui/UILinearProgressBar';

class DashboardStatus extends PureComponent {

  getTopupLinkCopy() {
    const { topup, ui } = this.props;
    const moneyAmount = topup.autoTopupAmount > 0 && { currency: 'EUR', unit: topup.autoTopupAmount };
    const moneyValue = topup.autoTopupThreshold > 0 && { currency: 'EUR', unit: topup.autoTopupThreshold };
    return topup.autoTopupStatus && ui[`topupCopy${capitalize(topup.autoTopupStatus)}`]
      .replace('{AMOUNT}', toDecimal(moneyAmount))
      .replace('{THRESHOLD}', toDecimal(moneyValue));
  }

  renderConsumption(item, key) {
    const { onConsumptionDetails } = this.props;

    return (
      <button
        className={suitcss({ descendantName: 'consumption' }, this)}
        onClick={() => onConsumptionDetails(key)}
        key={key}
      >
        <DashboardStatusMeter
          type={item.type}
          value={item.left}
          valueMax={item.max}
          valueType={item.unit}
          delay={(key * 250) + 500}
        />
      </button>
    );
  }

  renderConsumptionIndividual(item, key) {
    const { ui, onConsumptionsIndividual } = this.props;
    return (
      <UILinearProgressBar
        key={key}
        value={item.left}
        valueMax={item.max}
        valueType={item.type}
        unit={item.unit}
        title={item.title}
        expiration={item.expirationDateTime}
        textInfo={item.textInfo}
        onConsumptionsIndividual={onConsumptionsIndividual}
        ui={ui}
      />
    );
  }

  renderFlatrate(item, key) {
    const { ui } = this.props;
    const headline = ui[`flatType${capitalize(item.type)}`] || '';
    const subline = ui[`flatTarget${capitalize(item.target)}`] || '';
    return (
      <div className={suitcss({ descendantName: 'flatrate' }, this)} key={key} >
        <Headline size="xs" embedded highlight bold utilities={['lowercase']}>{headline}</Headline>
        <Copy size="secondary" embedded>{subline}</Copy>
      </div>
    );
  }

  renderBalance() {
    const { market, balance, urlBilling, ui, onBalanceDetails } = this.props;
    const bonusMoney = balance && balance.bonus > 0 && { currency: 'EUR', unit: balance.bonus };
    const balanceMoney = balance && { currency: 'EUR', unit: balance.total };
    const isPostPaid = market === MARKET_POSTPAID;
    const Element = isPostPaid ? 'button' : 'div';
    return (
      <div className={suitcss({ descendantName: 'balance' }, this)} >
        <Element onClick={isPostPaid ? onBalanceDetails : null}>
          {balanceMoney && (
            <Unit price={balanceMoney} bold utilities={['colorPrimary']} />
          )}
          <Copy size="secondary" embedded>
            {isPostPaid ? ui.costsSubline : ui.balanceSubline}
          </Copy>
          {bonusMoney && (
            <Copy size="secondary" embedded>
              {ui.balanceBonus.replace('{AMOUNT}', toDecimal(bonusMoney))}
            </Copy>
          )}
        </Element>
        {isPostPaid && urlBilling && (
          <div className={suitcss({ descendantName: 'link' }, this)} >
            <Link to={urlBilling} asButton data={{ mytracking: MYTRACK_DASHBOARD_OPEN_POBOX }}>
              {ui.billing}
            </Link>
          </div>
        )}
      </div>
    );
  }

  renderTopups() {
    const {
      topup,
      autoTopupStatus,
      onAutoTopup,
      onVoucherTopup,
      ui,
      loyaltySettings,
      cumulativeConsumption,
    } = this.props;

    return (
      <ContentLoader
        className={suitcss({ descendantName: 'topup' }, this)}
        isLoaded={!!topup}
        height={150}
      >
        { topup && topup.topups &&
        <div className={suitcss({ descendantName: 'topups' }, this)} >
          {topup.topups.slice().reverse().map((item, index) => (
            this.renderTopUpButton(item, index)
          ))}
        </div>
        }
        {cumulativeConsumption && this.renderMonthyConsumption()}
        {loyaltySettings && loyaltySettings.level > 0 &&
        <div className={suitcss({ descendantName: 'loyaltyBenefits' }, this)} >
          <Copy size="small" embedded raw>{loyaltySettings.copy}</Copy>
        </div>
        }
        {topup &&
        <div className={suitcss({ descendantName: 'topupLink' }, this)} >
          <Link onClick={onAutoTopup} withoutArrow element="button"> { autoTopupStatus === '' ? ui.topupNew : ui.topupSettings }</Link>
          <Copy size="secondary" embedded>
            {this.getTopupLinkCopy()}
          </Copy>
        </div>
        }
        {topup &&
        <div className={suitcss({ descendantName: 'topupLink' }, this)} >
          <Link onClick={onVoucherTopup} withoutArrow element="button">{ui.topupWithCode}</Link>
        </div>
        }
      </ContentLoader>
    );
  }

  renderMonthyConsumption() {
    const { cumulativeConsumption, ui } = this.props;
    const {
      myDashboardCumulativeCostsHeadline,
      myDashboardCumulativeCostsSubline,
      myDashboardCumulativeCostsFootnote,
    } = ui;

    const computeMonthlyCost = (costs) => {
      const price = {
        currency: 'EUR',
        unit: costs,
      };
      return (
        <Unit price={price} />
      );
    };

    return (cumulativeConsumption &&
      <div className={suitcss({ descendantName: 'cumulativeConsumption' }, this)}>
        <Headline
          size="s"
        >
          {myDashboardCumulativeCostsHeadline}
        </Headline>
        <Copy
          utilities={['marginBottomS']}
        >
          {myDashboardCumulativeCostsSubline}
        </Copy>
        {cumulativeConsumption.cumulativeCosts.map((el, index) =>
          <Copy className={suitcss({ descendantName: 'cumulativeConsumptionMonth' }, this)} key={index}><span>{ui[`myDashboardCumulativeCostsMonth${el.month}`]}</span><span>{computeMonthlyCost(el.cost)}</span></Copy>)}
        <Copy
          utilities={['marginBottomS']}
        >
          {myDashboardCumulativeCostsFootnote}
        </Copy>
      </div>
    );
  }

  renderTopUpButton(item, key) {
    const { onTopup } = this.props;
    const money = { currency: 'EUR', unit: item };
    return (
      <div className={suitcss({ descendantName: 'topupButton' }, this)} key={key} >
        <Link
          onClick={() => onTopup(item)}
          asButton
          withoutArrow
          buttonFilled
          element="div"
        >
          {toIntegerDigits(money)} {toCurrencySymbol(money)}<br />
          <small>aufladen</small>
        </Link>
      </div>
    );
  }

  render() {
    const {
      ui,
      market,
      balance,
      consumptions,
      consumptionsIndividual,
      flatrates,
      withoutFlatrates,
      withoutConsumptions,
      withoutConsumptionsIndividual,
    } = this.props;
    const isPostPaid = market === MARKET_POSTPAID;
    return (
      <div className={suitcss({}, this)} data-consumptions={consumptions && consumptions.length}>
        <Headline size="m" embedded bold>{ui.status}</Headline>
        {!withoutConsumptions && (
          <ContentLoader
            className={suitcss({ descendantName: 'consumptions' }, this)}
            isLoaded={!!consumptions || withoutConsumptions}
            height={150}
          >
            {consumptions && consumptions.map(this.renderConsumption, this)}
          </ContentLoader>
        )}
        {!withoutFlatrates && (
          <ContentLoader
            className={suitcss({ descendantName: 'flatrates' }, this)}
            withoutProgressbar
            isLoaded={(!!flatrates || withoutFlatrates) && (!!consumptions || withoutConsumptions)}
          >
            {flatrates && flatrates.map((item, index) => this.renderFlatrate(item, index))}
          </ContentLoader>
        )}
        {!withoutConsumptionsIndividual && (
          <ContentLoader
            className={suitcss({ descendantName: 'consumptionsIndividual' }, this)}
            isLoaded={!!consumptionsIndividual || withoutConsumptionsIndividual}
          >
            {consumptionsIndividual &&
              consumptionsIndividual.map(this.renderConsumptionIndividual, this)
            }
          </ContentLoader>
        )}
        <ContentLoader
          className={suitcss({ descendantName: 'content' }, this)}
          isLoaded={!!balance}
          height={175}
        >
          {this.renderBalance()}
          {!isPostPaid && this.renderTopups()}
        </ContentLoader>
      </div>
    );
  }
}

DashboardStatus.propTypes = {
  market: PropTypes.string,
  autoTopupStatus: PropTypes.string,
  balance: PropTypes.shape({
    total: PropTypes.number,
    bonus: PropTypes.number,
  }),
  consumptions: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      unit: PropTypes.oneOf(['', 'mb', 'gb', 'quantity']),
      max: PropTypes.number,
      left: PropTypes.number,
    }),
  ),
  consumptionsIndividual: PropTypes.arrayOf(
    PropTypes.shape({
      expirationDateTime: PropTypes.string,
      max: PropTypes.number,
      left: PropTypes.number,
      title: PropTypes.string,
      textInfo: PropTypes.string,
      unit: PropTypes.oneOf(['quantity', 'mb', 'gb']),
      type: PropTypes.oneOf(['data', 'data_roaming_1', 'phone_sms_roaming_1']),
    }),
  ),
  flatrates: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      target: PropTypes.string,
    }),
  ),
  topup: PropTypes.shape({
    autoTopupStatus: PropTypes.string,
    autoTopupAmount: PropTypes.number,
    autoTopupThreshold: PropTypes.number,
    topups: PropTypes.array,
  }),
  withoutConsumptions: PropTypes.bool,
  withoutConsumptionsIndividual: PropTypes.bool,
  withoutFlatrates: PropTypes.bool,
  onBalanceDetails: PropTypes.func.isRequired,
  onConsumptionDetails: PropTypes.func.isRequired,
  onConsumptionsIndividual: PropTypes.func.isRequired,
  onTopup: PropTypes.func.isRequired,
  onAutoTopup: PropTypes.func.isRequired,
  onVoucherTopup: PropTypes.func.isRequired,
  urlBilling: PropTypes.string,
  ui: PropTypes.shape({
    guiSymbolEuro: PropTypes.string,
    balance: PropTypes.string,
    balanceBonus: PropTypes.string,
    balanceSubline: PropTypes.string,
    billing: PropTypes.string,
    costsSubline: PropTypes.string,
    evn: PropTypes.string,
    flatTargetAll: PropTypes.string,
    flatTargetEuRoaming: PropTypes.string,
    flatTargetOtelo: PropTypes.string,
    flatTargetLand: PropTypes.string,
    flatTypeEuRoaming: PropTypes.string,
    flatTypeLand: PropTypes.string,
    flatTypePhone: PropTypes.string,
    flatTypePhone_sms: PropTypes.string,
    status: PropTypes.string,
    singleTopupMmoCopy: PropTypes.string,
    topup: PropTypes.string,
    topupWithCode: PropTypes.string,
    topupSettings: PropTypes.string,
    topupNew: PropTypes.string,
    topupTimed: PropTypes.string,
    topupTimedSelect: PropTypes.string,
    topupSettingsCopy: PropTypes.string,
  }),
  loyaltySettings: PropTypes.object,
};

const mapStateToProps = ({ user }) => ({
  autoTopupStatus: user.topup && user.topup.autoTopupStatus,
});

export default compose(
  connect(mapStateToProps),
  connectUI({
    // careful changing these ui keys! they are renamed for some magic that happens above.
    balance: 'myDashboardBalance',
    balanceBonus: 'myDashboardBalanceBonus',
    balanceSubline: 'myDashboardBalanceSubline',
    billing: 'myDashboardBilling',
    costsSubline: 'myDashboardCostsSubline',
    evn: 'myDashboardEvn',
    flatTargetAll: 'myDashboardFlatTargetAll',
    flatTargetEuRoaming: 'myDashboardFlatTargetEuRoaming',
    flatTargetOtelo: 'myDashboardFlatTargetOtelo',
    flatTargetLand: 'myDashboardFlatTargetLand',
    flatTypeEuRoaming: 'myDashboardFlatTypeEuRoaming',
    flatTypeLand: 'myDashboardFlatTypeLand',
    flatTypePhone: 'myDashboardFlatTypePhone',
    flatTypePhone_sms: 'myDashboardFlatTypePhoneSms',
    flatTypeSms: 'myDashboardFlatTypeSms',
    status: 'myDashboardStatus',
    singleTopupMmoCopy: 'myDashboardSingleTopupMmoCopy',
    topup: 'myDashboardTopup',
    topupWithCode: 'myDashboardCodeTopup',
    topupSettings: 'myDashboardAutoTopupEdit',
    topupNew: 'myDashboardAutoTopupNew',
    topupCopyTimed: 'myDashboardAutoTopupTimed',
    topupCopyTimed_date: 'myDashboardAutoTopupTimedDate',
    topupCopyThreshold: 'myDashboardAutoTopupThreshold',
    topupCopyMmo: 'myDashboardAutoTopupMmo',
  }),
)(DashboardStatus);
