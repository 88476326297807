import { compose } from 'redux';
import { connect } from 'react-redux';
import initForm from '../form/FormInitializer';
import { trackMyState } from '../../actions/tracking/event';
import CancellationFormComposition from '../../components/compositions/cancellation/PresalesCancellationForm';
import { MYTRACK_CONTACT_SUBMIT_SUCCESS } from '../../helpers/constants';
import FormConfigProps from '../../model/form/FormConfigProps';

const createFieldMap = (state, { ui }) => ({
  firstName: {
    name: 'firstName',
    label: ui.guiFormNameFirst,
    validation: {
      range: [null, 50],
    },
  },
  lastName: {
    name: 'lastName',
    label: ui.guiFormNameLast,
    validation: {
      range: [null, 50],
    },
  },
  email: {
    name: 'email',
    label: ui.guiFormEmail,
    type: 'email',
    validation: {
      pattern: 'email',
    },
  },
  addressStreet: {
    name: 'street',
    label: ui.guiFormAddressStreet,
    validation: {
      range: [null, 50],
    },
  },
  addressNumber: {
    name: 'houseNumber',
    label: ui.guiFormAddressNumber,
    validation: {
      range: [null, 9],
    },
  },
  addressZip: {
    name: 'zip',
    label: ui.guiFormAddressZip,
    validation: {
      pattern: 'zip',
    },
  },
  addressCity: {
    name: 'city',
    label: ui.guiFormAddressCity,
    validation: {
      range: [null, 50],
    },
  },
  customerId: {
    name: 'customerId',
    label: ui.myProfileContractCustomerId,
  },
  contractIdent: {
    name: 'contractIdent',
    label: ui.myProfileContractContractId,
  },
  terminationDate: {
    name: 'terminationDate',
    label: ui.guiTerminationDate,
  },
  terminationDetails: {
    name: 'terminationDetails',
    label: ui.guiTerminationDetails,
  },
  captcha: {
    name: 'captcha',
    label: 'captchaLabel',
    type: 'captcha',
    validation: {
      range: [5, 5],
    },
  },
});

export const id = 'PresalesCancellationForm';

const makeSubmit = () => async (values, dispatch) => {
  dispatch(trackMyState(MYTRACK_CONTACT_SUBMIT_SUCCESS));
};

const mapStateToProps = (state, { fieldMap, ui }) => {
  const initialValues = {
    [fieldMap.terminationDate.name]: ui.guiWordAsap,
  };
  return { initialValues };
};

const component = compose(
  connect(mapStateToProps),
  initForm(),
)(CancellationFormComposition);

/**
 * @return {FormConfig}
 */
export const mapStateToFormConfig = (state, props) => ({
  id,
  component,
  fieldMap: createFieldMap(state, props),
  makeSubmit,
  formConfigProps: new FormConfigProps({
    labelSubmit: state.ui.guiTerminateNow,
  }),
});

export default mapStateToFormConfig;
