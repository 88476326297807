import PageModule from './PageModule';
import Headline from '../../containers/content/ContentHeadline';
import GiveMeYourPrice from '../../containers/modules/GiveMeYourPrice';
import HotlineDeals from '../../containers/modules/HotlineDeals';

class DynamicModule extends PageModule {

  constructor(rawModule) {
    let moduleData;
    let component;
    if (rawModule.isHydratable) {
      moduleData = rawModule;
      component = DynamicModule.getComponentByName(rawModule.params.type);
    } else {
      const { type, data } = rawModule;
      component = DynamicModule.getComponentByName(type);
      const parsedData = JSON.parse(data);
      // note: for the dynamic module we directly mix all data properties into the module
      // as if they had been placed by the MVC in the toplevel of the module's json.
      moduleData = { ...parsedData, ...rawModule, data: undefined };
    }

    super(component, moduleData);
  }

  /**
   * @override
   */
  prepare(pathname, page) {
    return async dispatch => {
      await dispatch(super.prepare(pathname, page));
      switch (this.params.module) {
        /* case 'give_me_your_price': {
          const subjects = await Promise.all(
            [
              this.params.subjects.tariff,
              this.params.subjects.hardware,
              this.params.subjects.tariffDetails,
              this.params.subjects.hardwareDetails,
            ]
              .filter(Boolean)
              .map((eid) => dispatch(fetchEntityById(eid))),
          );
          this.entities.push(...subjects);
          // if one of a hardware is included, we also fetch the hardware group as well
          const hardwareEntity = this.entities.find(isHardwareEntity);
          if (hardwareEntity) {
            this.entities.push(await dispatch(fetchEntityById(hardwareEntity.groupId)));
          }
          break;
        } */
        default:
          return null;
      }
    };
  }

  static getComponentByName(name) {
    switch (name) {
      case 'give_me_your_price':
        return GiveMeYourPrice;
      case 'hotline_deals':
        return HotlineDeals;
      case 'hello_world':
        return Headline;
      default:
        // returns a minimalistic component that does not render itself
        return () => null;
    }
  }
}

export default DynamicModule;
