import QueueableRequest from './QueueableRequest';
import { EECC_BMNP_PORTING_INITIATE } from '../../config/api';
import { REQUEST_METHOD_POST } from '../../helpers/constants';
import { bindParamsToRoute } from '../../helpers/url';

/**
 *
 */

export default class EECCBMnpPortingInitiateRequest extends QueueableRequest {
  constructor(msisdn, payload) {
    super(bindParamsToRoute(EECC_BMNP_PORTING_INITIATE, { msisdn }), {
      method: REQUEST_METHOD_POST,
      payload,
    });
  }
}
