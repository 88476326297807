/* global window */
export const createWhatsAppShareLink = (selectedTariff = null, selectedHardware = null, queryParams = '?') => {
  if (!process.browser) {
    return null;
  }

  const whatsAppPrefix = 'whatsapp://send?text=';
  const baseUrl = window.location.origin;
  const pathName = window.location.pathname;
  let tariffQuery = selectedTariff ? `selectedTariff=${selectedTariff.eid}` : '';
  let hardWareQuery = selectedHardware ? `selectedHardware=${selectedHardware.eid}` : '';
  let whatsAppSuffix = 'b_id=11210007&c_id=sms_cad_a01:fq0_O_gen_sta_eng_whatsapp';

  if (queryParams !== '?') {
    tariffQuery = selectedTariff ? `${'&'}${tariffQuery}` : '';
    hardWareQuery = selectedHardware ? `${'&'}${hardWareQuery}` : '';
    whatsAppSuffix = `${'&'}${whatsAppSuffix}`;

  } else if (selectedHardware && selectedTariff) {
    hardWareQuery = `${'&'}${hardWareQuery}`;
    whatsAppSuffix = `${'&'}${whatsAppSuffix}`;

  } else if (selectedHardware || selectedTariff) {
    whatsAppSuffix = `${'&'}${whatsAppSuffix}`;
  }

  const encodedString = encodeURIComponent(`${baseUrl}${pathName}${queryParams}${tariffQuery}${hardWareQuery}${whatsAppSuffix}`);
  return `${whatsAppPrefix}${encodedString}`;
};
