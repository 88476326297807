import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Copy from '../../../basics/text/TextCopy';

class PasswordResetCooldownCopy extends Component {

  componentDidMount() {
    this.intervalId = setInterval(() => this.forceUpdate(), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  render() {
    const { timestamp, copy } = this.props;
    const now = Date.now();
    const enabled = now > timestamp;
    const getFormattedCooldownTime = () => {
      const diff = timestamp - now;
      const hours = Math.floor(diff / 1000 / 60 / 60);
      const mins = Math.floor(diff / 1000 / 60) % 60;
      const secs = Math.floor(diff / 1000) % 60;

      const mins1 = mins < 10 ? `0${mins}` : mins;
      const secs1 = secs < 10 ? `0${secs}` : secs;
      return hours > 0 ? `${hours}:${mins1}:${secs1}` : `${mins1}:${secs1}`;
    };

    const getCooldownLabel = () => copy.replace('{TIME}', getFormattedCooldownTime());

    return (!enabled) ? (<Copy utilities={['colorGray100']} embedded>{getCooldownLabel()}</Copy>) : null;
  }
}

PasswordResetCooldownCopy.propTypes = {
  copy: PropTypes.string.isRequired,
  timestamp: PropTypes.number.isRequired,
};

export default PasswordResetCooldownCopy;
