import React from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';
import SvgLoader from '../media/MediaSvgLoader';

const componentName = 'ButtonCloseIcon';
const ButtonCloseIcon = (props) => {
  const { className, label, onClick, ariaLabel } = props;
  return (
    <button
      className={suitcss({ componentName, className })}
      onClick={onClick}
      aria-label={ariaLabel}
    >
      {label &&
        <span className={suitcss({ utilities: ['textSizeSmall', 'colorGray100'] })} >
          {label}
        </span>
      }
      {label && <span>&nbsp;</span>}
      <SvgLoader path="/icons/content-close.svg" />
    </button>
  );
};

ButtonCloseIcon.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  ariaLabel: PropTypes.string,
};

export default ButtonCloseIcon;
