import { compose } from 'redux';
import enrichEntities from './PreloaderEntity';

import {
  isHardwareEntity,
  isTariffEntity,
  isTariffOptionEntity,
} from '../../helpers/entity';
import { clearCart } from '../../actions/order/cart';

const mapStateToEntities = (state, ownProps) => {
  // try to use a cart from own props first
  // if it is not given, try to use the state cart
  const cart = ownProps.cart || state.cart;
  const cartHardware = cart.find(isHardwareEntity);
  const cartTariff = cart.find(isTariffEntity);
  const cartSelectedOptions = cart.filter(isTariffOptionEntity);
  return {
    cartHardware,
    cartTariff,
    cartSelectedOptions,
  };
};

const mapStateToSecondLoad = (state, { cartHardware, cartTariff }) => ({
  cartHardwareGroup: (cartHardware && cartHardware.groupId) ?
    { etype: 'hardwareGroup', eid: cartHardware.groupId } :
    null,
  cartTariffOptions: (cartTariff && cartTariff.options) ?
    cartTariff.options.map(eid => ({ etype: 'tariffOptionEntity', eid })) :
    [],
});

export default () => compose(
  enrichEntities(mapStateToEntities, {
    onFailure: clearCart,
  }),
  enrichEntities(mapStateToSecondLoad, {
    onFailure: clearCart,
  }),
);
