import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Copy from '../../basics/text/TextCopy';
import suitcss from '../../../helpers/suitcss';
import UIOverlayWrapper from '../../basics/ui/UIOverlayWrapper';
import TextLink from '../../basics/text/TextLink';
import TextHeadline from '../../basics/text/TextHeadline';
import MediaImage from '../../basics/media/MediaImage';

class OptionLayerDialog extends PureComponent {

  render() {

    const { headline, copy, image, continueText, cancelText, onClose, onContinue } = this.props;

    return (
      <UIOverlayWrapper
        className={suitcss({}, this)}
        visible
        theme="light"
        onClose={onClose}
      >
        <div className={suitcss({ utilities: ['row', 'sAlignCenter'], descendantName: 'layer' }, this)}>
          <div className={suitcss({ utilities: ['mlCol4 sCol12 mlPaddingRight'], descendantName: 'image' }, this)}>
            <MediaImage {...image} isLazy />
          </div>
          <div className={suitcss({ utilities: ['mlCol8 sCol12 flex dirColumn'], descendantName: 'content' }, this)}>
            <div className={suitcss({ utilities: ['sMarginTop'], descendantName: 'content' }, this)}>
              <TextHeadline size="l" utilities={['marginBottom']} embedded raw>{headline}</TextHeadline>
              <Copy
                className={suitcss({ descendantName: 'copy' }, this)}
                utilities={['marginBottom']}
                embedded
                raw
              >
                {copy}
              </Copy>
            </div>
            <div className={suitcss({ utilities: ['row', 'sJustifyCenter', 'marginTopAuto'], descendantName: 'buttons' }, this)}>
              <TextLink
                utilities={['sCol12 mlMarginRight sBlock']}
                withoutArrow
                asButton
                buttonFilled
                element="button"
                onClick={onContinue}
              >
                {continueText}
              </TextLink>
              <TextLink
                className={suitcss({ utilities: ['sMarginTopAuto'], descendantName: 'button' }, this)}
                withoutArrow
                element="button"
                onClick={onClose}
              >
                {cancelText}
              </TextLink>
            </div>
          </div>
        </div>
      </UIOverlayWrapper>
    );
  }
}


OptionLayerDialog.propTypes = {
  headline: PropTypes.string.isRequired,
  copy: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  continueText: PropTypes.string.isRequired,
  cancelText: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
};

export default OptionLayerDialog;
