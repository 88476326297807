import FormValidationError from '../errors/FormValidationError';

class FormValidationResult {
  /**
   * @param {object} ui - a map of all ui elements required to verbalize errors.
   * @param {Array<FieldErrorEntry>} fieldErrors - All errors discovered in
   *     the course of the validation.
   */
  constructor(ui, fieldErrors) {
    this.error = fieldErrors.length ? new FormValidationError(ui, fieldErrors) : null;
  }

  hasErrors() {
    return !!this.error;
  }

  getError() {
    return this.error;
  }

  /**
   * Output is interpretable by redux-form
   * @see https://redux-form.com/7.0.4/examples/syncvalidation/
   */
  getFieldErrors() {
    return this.error ? this.error.getFieldErrors() : {};
  }
}

export default FormValidationResult;
