import QueueableRequest from './QueueableRequest';
import { CONTACT_ENDPOINT } from '../../config/api';
import { REQUEST_METHOD_POST } from '../../helpers/constants';

export default class ContactRequest extends QueueableRequest {
  constructor(payload) {
    super(CONTACT_ENDPOINT, {
      method: REQUEST_METHOD_POST,
      payload,
    });
  }
}
