import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import oneLine from 'common-tags/lib/oneLine';

import suitcss from '../../../helpers/suitcss';
import connectUI from '../../basics/ui/UIConnector';
import {
  isTariffEntity,
  isHardwareEntity,
  isHardwareGroup,
  getHardwareTariffSelectedLink,
  getTariffHardwareSelectedLink, getTariffDuration,
} from '../../../helpers/entity';
import {
  ENTITY_TYPE_TARIFF,
  ENTITY_TYPE_HARDWARE,
  ENTITY_TYPE_HARDWAREGROUP,
  MARKET_PREPAID,
  IMAGE_SIM_CARD_PNG,
  E_SIM,
} from '../../../helpers/constants';
import {
  CATEGORY_POSTPAID,
  CATEGORY_PREPAID,
  CATEGORY_SINGLE_PRODUCT,
  shape as tariffShape,
} from '../../../propTypes/tariff';
import { shape as hardwareShape } from '../../../propTypes/hardware';
import { shape as hardwareGroupShape } from '../../../propTypes/hardwareGroup';
import FootnoteReference from '../../../containers/footnote/FootnoteReference';

import Headline from '../../basics/text/TextHeadline';
import Copy from '../../basics/text/TextCopy';
import Link from '../../basics/text/TextLink';
import MediaImage from '../../basics/media/MediaImage';
import Callout from '../../../containers/callout/Callout';
import imageSizes from '../../../config/imageSizes';

class ShoppingCartProduct extends PureComponent {

  static getContractLabel(entity, ui) {
    switch (entity.category) {
      case CATEGORY_SINGLE_PRODUCT:
      case CATEGORY_PREPAID:
        return ui.guiContractNone;
      case CATEGORY_POSTPAID:
        return getTariffDuration(entity, ui);
      default:
        return '';
    }
  }

  static getHardwareConfig(entities, ui, isCompact, theme) {
    const hardware = entities.find(isHardwareEntity);
    const hardwareGroup = entities.find(isHardwareGroup);
    const tariff = entities.find(isTariffEntity);

    const {
      eid, etype, capacity, color, colorCode, image, groupId,
    } = hardware;

    const deliveryState = ui[`guiDeliveryState${hardware.deliveryState}`];
    const urlChange = getTariffHardwareSelectedLink({ hardware, tariff }).url;

    const name = hardwareGroup && oneLine`
      ${hardwareGroup.brand}
      ${hardwareGroup.name}
    `;

    return {
      eid,
      etype,
      image,
      name,
      groupId,
      urlChange,
      specs: [
        !isCompact && [
          <span key="1" className="u-block">{ui.guiWordStorage}: {capacity} {ui.guiSymbolGigabyte}</span>,
          <span key="2" className="u-flex u-itemsCenter">{ui.guiWordColor}: <span className="ShoppingCartProduct-swatch u-marginLeftXXS" style={{ backgroundColor: colorCode }} />{color}</span>],
        !isCompact && deliveryState && <Copy key="3" element="span" size="secondary" utilities={[hardware.deliveryState === 1 && 'colorSuccess']}>{deliveryState.replace('{DELIVERY_TIME}', hardware.deliveryTime)}</Copy>,
        isCompact && [<span key="4" className="ShoppingCartProduct-swatch" style={{ backgroundColor: colorCode }} />, `${color}, ${capacity} ${ui.guiSymbolGigabyte}`],
        theme === 'compact' && deliveryState && [<Copy key="5" element="span" className="ShoppingCartProduct-delivery" size="secondary" utilities={['weightBold', hardware.deliveryState === 1 && 'colorSuccess']} condensed uppercase>{deliveryState.replace('{DELIVERY_TIME}', hardware.deliveryTime)}</Copy>, <span key="6" className="u-margin" />],
      ],
    };
  }

  static getTariffConfig(entities, isContractRenewal, ui, isCompact, theme) {
    const tariff = entities.find(isTariffEntity);
    const hardware = entities.find(isHardwareEntity);
    const {
      eid, etype, type, copyCart,
    } = tariff;

    const isPua = tariff.market === MARKET_PREPAID;

    const name = isPua
      ? `${type} ${tariff.name}`
      : type;

    const urlChange = hardware
      ? getHardwareTariffSelectedLink({ tariff, hardware }).url
      : tariff.urlOverview;

    const image = {
      alt: `${type} ${tariff.name}`,
      src: IMAGE_SIM_CARD_PNG,
      width: 50,
    };

    return {
      eid,
      etype,
      type,
      name,
      image,
      urlChange,
      info: copyCart,
      specs: [
        !isPua && [<span key="3" className="ShoppingCartProduct-data u-fontCondensed">{tariff.name}</span>,
          theme !== 'widget' && <Callout key="2" theme="content" targets={[tariff.eid]} />],
        isCompact && !isPua && ui.guiPhoneSmsFlat,
        isCompact && [ShoppingCartProduct.getContractLabel(tariff, ui), <FootnoteReference id={tariff.eid} key="1" />],
      ],
    };
  }

  isCompactTheme() {
    return this.props.theme === 'compact';
  }

  isWidgetTheme() {
    return this.props.theme === 'widget';
  }

  isFullTheme() {
    return this.props.theme === 'full';
  }

  render() {
    const {
      theme,
      type,
      entities,
      isConfirmed,
      isContractRenewal,
      onRemove,
      onRemoveAll,
      withCallout,
      ui,
      cartSimType,
      isPuc,
    } = this.props;

    const isCompact = this.isCompactTheme() || this.isWidgetTheme();
    const isWidget = this.isWidgetTheme();
    const isFull = this.isFullTheme();
    const isTariff = type === ENTITY_TYPE_TARIFF;
    const config = isTariff
      ? ShoppingCartProduct.getTariffConfig(entities, isContractRenewal, ui, isCompact, theme)
      : ShoppingCartProduct.getHardwareConfig(entities, ui, isCompact, theme);

    const {
      eid, groupId, image, info, name, specs, urlChange,
    } = config;

    const selectedSimType = cartSimType === E_SIM
      ? ui.scaChooseSimtypeOptionEsim
      : ui.scaChooseSimtypeOptionClassic;

    let simTypeCompact;
    let simTypeOrderSummary;

    if (isPuc && isConfirmed && isTariff && !isContractRenewal && !isWidget) {
      if (isCompact) {
        simTypeCompact = selectedSimType;
      }
      if (!isCompact && isFull) {
        simTypeOrderSummary = `${ui.scaSimType} ${selectedSimType}`;
      }
    }

    return (
      <div className={suitcss({ modifiers: [theme] }, this)}>
        {image && !isCompact &&
          <div className={suitcss({ descendantName: 'image' }, this)}>
            <MediaImage {...image} sizes={imageSizes.shoppingCartProduct} isLazy />
          </div>
        }
        <div className={suitcss({ descendantName: 'content' }, this)}>
          <div className={suitcss({ descendantName: 'headline' }, this)}>
            <Headline
              size={isCompact ? 'xxs' : 'xs'}
              bold={isCompact}
              utilities={[isCompact && 'lowercase', 'weightBold']}
              element="span"
              embedded
              raw
            >
              {name}
            </Headline>
            {withCallout && <Callout theme="sticker" targets={[eid, groupId]} />}
          </div>
          {specs && specs.map((desc, index) => desc && (
            <Copy
              className={suitcss({ descendantName: 'copy' }, this)}
              utilities={['colorGray100', isCompact && 'fontCondensed']}
              size="secondary"
              embedded
              key={index}
              element="div"
            >
              {desc}
            </Copy>
          ))}
          {info && isFull &&
            <Copy
              className={suitcss({ descendantName: 'infoList' }, this)}
              utilities={['marginTopS']}
              size="secondary"
              raw
            >
              {info}
            </Copy>
          }
          {simTypeCompact &&
            <Copy
              className={suitcss({ descendantName: 'infoList' }, this)}
              utilities={['marginTopS', 'fontCondensed']}
              size="secondary"
              raw
            >
              {selectedSimType}
            </Copy>
          }
          {simTypeOrderSummary &&
          <Copy
            className={suitcss({ descendantName: 'infoList' }, this)}
            utilities={['marginTopS', 'weightBold']}
            size="secondary"
            raw
          >
            {simTypeOrderSummary}
          </Copy>
          }
        </div>
        {withCallout && !isCompact && !isFull && <Callout theme="badge" targets={[eid, groupId]} />}
        {!isConfirmed && isFull &&
          <div className={suitcss({ descendantName: 'links' }, this)}>
            <Link to={urlChange} utilities={['textSizeSecondary']}>
              {ui.guiWordEdit}
            </Link>
            {onRemove &&
              <Link element="button" utilities={['textSizeSecondary']} onClick={() => onRemove(eid)}>
                {ui.guiWordRemove}
              </Link>
            }
            {onRemoveAll &&
              <Link element="button" utilities={['textSizeSecondary']} onClick={() => onRemoveAll(eid)}>
                {ui.guiWordRemove}
              </Link>
            }
          </div>
        }
      </div>
    );
  }
}

ShoppingCartProduct.propTypes = {
  theme: PropTypes.oneOf(['full', 'compact', 'widget']),
  type: PropTypes.oneOf([
    ENTITY_TYPE_TARIFF,
    ENTITY_TYPE_HARDWARE,
    ENTITY_TYPE_HARDWAREGROUP,
  ]),
  entities: PropTypes.arrayOf(PropTypes.oneOfType([
    tariffShape,
    hardwareShape,
    hardwareGroupShape,
  ])),
  onRemove: PropTypes.func,
  onRemoveAll: PropTypes.func,
  onChangeProduct: PropTypes.func,
  isConfirmed: PropTypes.bool,
  isContractRenewal: PropTypes.bool,
  withCallout: PropTypes.bool,
  cartSimType: PropTypes.string,
  ui: PropTypes.shape({
    guiWordEdit: PropTypes.string.isRequired,
    guiWordRemove: PropTypes.string.isRequired,
    scaChooseSimtypeOptionEsim: PropTypes.string,
    scaChooseSimtypeOptionClassic: PropTypes.string,
    scaSimType: PropTypes.string,
  }),
};

ShoppingCartProduct.defaultProps = {
  theme: 'full',
};

export default connectUI()(ShoppingCartProduct);
