import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import suitcss from '../../../../helpers/suitcss';
import Message from '../Message';
import Link from '../../../basics/text/TextLink';
import {
  MESSAGE_SENDER_USER,
  MESSAGE_TYPE_DATA_VOLUME,
} from '../constants';
import { pickRandom } from '../../../../helpers/random';


class BasarChatInputPanelDataVolume extends PureComponent {
  sendDataVolume(tariffData) {
    const { sendMessage, params } = this.props;
    sendMessage(
      new Message(MESSAGE_SENDER_USER, {
        type: MESSAGE_TYPE_DATA_VOLUME,
        data: tariffData,
        text: pickRandom(params.messages.choseTariffVolume).replace('{TARIFF_SHORTHAND}', tariffData.name),
      }),
    );
  }

  renderButtons() {
    const { params, isDisabled } = this.props;
    const { subjects } = params;
    return subjects.tariffs.map(data => (
      <Link
        isDisabled={isDisabled}
        withoutArrow
        asButton
        buttonFilled
        element="button"
        onClick={() => this.sendDataVolume(data)}
        to={null}
        key={data.name}
      >
        {data.name}
      </Link>
    ));
  }

  render() {
    const { componentName } = this.props;

    return (
      <div className={suitcss({ componentName, descendantName: 'footer' }, this)} >
        <div className={suitcss({ componentName, descendantName: 'form' }, this)} >
          <div className={suitcss({ componentName, descendantName: 'buttons' }, this)} >
            {this.renderButtons()}
          </div>
        </div>
      </div>
    );
  }
}

BasarChatInputPanelDataVolume.propTypes = {
  componentName: PropTypes.string.isRequired,
  sendMessage: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool,
};

BasarChatInputPanelDataVolume.defaultProps = {
};

export default BasarChatInputPanelDataVolume;
