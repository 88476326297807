import QueueableRequest from './QueueableRequest';
import { ACCOUNT_PROMOTION_BANNER_ROTATION } from '../../config/api';
import { bindParamsToRoute } from '../../helpers/url';
import { blockContentBasedOnUserScope } from '../../helpers/scope';
import { SCOPE_NOTIFICATIONS } from '../../helpers/constants';

export default class BannerRotationRequest extends QueueableRequest {
  constructor(msisdn, options) {
    super(bindParamsToRoute(ACCOUNT_PROMOTION_BANNER_ROTATION, { msisdn }), options);
  }

  block(state) {
    return !blockContentBasedOnUserScope(state.user.scope, SCOPE_NOTIFICATIONS);
  }
}
