import React from 'react';
import PropTypes from 'prop-types';

import { copyPropTypes } from '../../../propTypes/typography';
import Copy from './TextCopy';
import suitcss from '../../../helpers/suitcss';

// @todo turn this into a helper method
const flatten = (arr) => [].concat.apply([], arr); // eslint-disable-line

const renderValue = (value, raw, size) => {
  if (typeof value === 'string') {
    return <Copy size={size} embedded raw={raw}>{value}</Copy>;
  }
  return value;
};


const TextDataList = ({ items, size, withoutEllipsis, withoutBreakpoints, utilities }) => (
  <dl
    className={suitcss({
      componentName: 'TextDataList',
      modifiers: [
        !withoutEllipsis && 'ellipses',
        !withoutBreakpoints && 'breakpoints',
      ],
      utilities,
    })}
  >
    {flatten(items.map((p, index) => [
      <dt key={`${index}-key`}>
        <Copy raw size={size} embedded>{p.key}</Copy>
      </dt>,
      <dd key={`${index}-value`}>
        {renderValue(p.value, p.rawValue, size)}
      </dd>,
    ]))}
  </dl>
);


TextDataList.propTypes = {
  withoutEllipsis: PropTypes.bool,
  withoutBreakpoints: PropTypes.bool,
  size: copyPropTypes.size,
  items: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    rawValue: PropTypes.bool,
  })).isRequired,
  utilities: PropTypes.array,
};

export default TextDataList;
