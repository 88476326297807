import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { getDisplayName } from '../../../helpers/component';

const connectUniqueId = prefix => (WrappedComponent) => {
  class ConnectUniqueId extends PureComponent {
    constructor(props, context) {
      super(props, context);
      this.state = {
        uniqueId: context.uniqueId(prefix),
      };
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          uniqueId={this.state.uniqueId}
        />
      );
    }
  }

  ConnectUniqueId.displayName = `ConnectUniqueId(${getDisplayName(WrappedComponent)})`;
  ConnectUniqueId.WrappedComponent = WrappedComponent;
  ConnectUniqueId.contextTypes = { uniqueId: PropTypes.func };

  return ConnectUniqueId;
};

export default connectUniqueId;
