import PageModule from './PageModule';
import component from '../../containers/account/MyFriendReferralDashboard';
import {
  UI_MY_FRIEND_REFERRAL_DASHBOARD,
  UI_MY_FRIEND_REFERRAL_GLOBAL,
} from '../../helpers/constants';

/**
 * The My Friend Referral Dashboard module maps the friend referral dashboard on
 * which the logged in user accesses the KwK (Kunden werben Kunden) status.
 * @see https://confluence.db-n.com/x/HPTc
 */
class MyFriendReferralDashboardModule extends PageModule {
  constructor(moduleData) {
    super(component, moduleData);
  }

  getRequiredUi() {
    return [UI_MY_FRIEND_REFERRAL_DASHBOARD, UI_MY_FRIEND_REFERRAL_GLOBAL];
  }
}

export default MyFriendReferralDashboardModule;

