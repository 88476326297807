import initForm from '../../form/FormInitializer';
import LoginFormPassword from '../../../components/compositions/account/login/LoginFormPassword';
import FormConfigProps from '../../../model/form/FormConfigProps';

const createFieldMap = (ui) => ({
  username: {
    name: 'msisdn',
    label: ui.guiFormMsisdn,
    type: 'tel',
    validation: {
      isRequired: true,
    },
  },
  password: {
    name: 'password',
    label: ui.guiFormPassword,
    type: 'password',
    validation: {
      isRequired: true,
    },
  },
});

/*
   isPersist: {
    name: 'isPersist',
    label: ui.myLoginSaveInSession || 'myLoginSaveInSession',
    type: 'checkbox',
    shouldSubmit: false,
  },
    // This is placed in MapStateToProps!
    [fieldMap.isPersist.name]: false,
*/

/* const mapStateToProps = () => {
  const initialValues = {
    isPersist: false,
  };
  return {
    initialValues,
  };
}; */

// init component once, outside
const component = initForm()(LoginFormPassword);

export const id = 'loginPassword';

/**
 * @return {FormConfig}
 */
export const mapStateToFormConfig = (state) => ({
  component,
  fieldMap: createFieldMap(state.ui),
  formConfigProps: new FormConfigProps({
    labelSubmit: state.ui.guiWordLogin,
    withRequiredHint: false,
    withStackedFooter: true,
  }),
});

export default mapStateToFormConfig;
