import QueueableRequest from './QueueableRequest';
import { ACCOUNT_TARIFF_ENDPOINT } from '../../config/api';
import { bindParamsToRoute } from '../../helpers/url';

/**
 * Fetches a single bookable (or booked) tariff from the api.
 */
export default class TariffRequest extends QueueableRequest {
  constructor(msisdn, tariffId, options = {}) {
    const url = bindParamsToRoute(ACCOUNT_TARIFF_ENDPOINT, { msisdn, tariffId });
    super(url, options);
  }
}

