import React from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';

const componentName = 'FieldSelectbox';

const FieldSelectboxOption = props => (
  <option
    className={suitcss({
      componentName,
      descendantName: 'option',
    })}
    disabled={props.disabled}
    value={props.value}
  >
    {props.label}
  </option>
);

FieldSelectboxOption.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  disabled: PropTypes.bool,
};

FieldSelectboxOption.defaultProps = {
  disabled: false,
};

export default FieldSelectboxOption;
