import React from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';

const Accordion = ({ children, modifiers }) => (
  <div className={suitcss({ componentName: 'Accordion', modifiers })}>
    {children}
  </div>
);

Accordion.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array,
  ]),
  modifiers: PropTypes.array,
};

export default Accordion;
