import { ACTION_PREFIX } from '../../helpers/constants';

export const SET_PAYMENT_FEE = `${ACTION_PREFIX}/SET_PAYMENT_FEE`;

export function setPaymentFee(eids, amount) {
  return {
    type: SET_PAYMENT_FEE,
    payload: {
      eids,
      amount,
    },
  };
}
