import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { showDialog } from '../../../../actions/page/dialog';
import suitcss from '../../../../helpers/suitcss';
import Copy from '../../../basics/text/TextCopy';
import Link from '../../../basics/text/TextLink';
import connectUI from '../../../basics/ui/UIConnector';

class SimTypeToggleLabel extends PureComponent {

  dialogCopySim() {
    const { ui } = this.props;
    return (
      <div className={suitcss({ utilities: ['row'] }, this)}>
        <div className={suitcss({ utilities: ['col5', 'marginRight'] }, this)}>
          <img
            className={suitcss({ utilities: ['col5', 'marginRight'] })}
            src={ui.scaTripleSimImage}
            alt="triple-sim"
          />
        </div>
        <div className={suitcss({ descendantName: 'copy', utilities: ['col6'] }, this)}>
          <Copy utilities={['weightBold']} embedded raw>
            {ui.mySimrepSimtypeTrippleSim}
          </Copy>
          <Copy size="secondary" embedded raw>{ui.txtMySimrepSimtypeHint}</Copy>
        </div>
      </div>
    );
  }

  createDialogLabelInfo(event, isSimCard) {
    const { dispatch, ui } = this.props;
    if (event) {
      event.preventDefault();
    }
    dispatch(showDialog({
      headline: isSimCard
        ? ui.mnpSimTypeCard
        : ui.mnpSimTypeEsim,
      copy: isSimCard
        ? this.dialogCopySim()
        // eslint-disable-next-line
        : (ui.txtMySimrepEsimProsCopy + '<br/>' + ui.mySimrepEsimMoreInfo),
      shouldCloseOnTransition: true,
      actions: [{ label: ui.guiWordAllright }],
    }));
  }

  renderSimTypeOptionsLabel(isSimCardLabel, isContractSimTypeEsim) {
    const { ui } = this.props;
    return (
      <div className={suitcss({ descendantName: 'customLabel' }, this)}>
        <Copy utilities={['weightBold', 'inline', 'marginRightXS']}>
          {isSimCardLabel
            ? ui.mnpSimTypeCard
            : ui.mnpSimTypeEsim}
        </Copy>
        <Link
          element="button"
          withoutArrow
          withoutStyle
          onClick={(ev) => this.createDialogLabelInfo(ev, isSimCardLabel)}
          utilities={['alignMiddle']}
        >
          <img className={suitcss({ utilities: ['alignBaseline'] }, this)} src="/files/icons/info-i/info-icon.svg" alt="info-i" width="16px" height="16px" />
        </Link>
        <Copy
          className={suitcss({
            descendantName: 'customLabelHint',
            utilities: ['marginBottomAuto'],
          }, this)}
        >
          {isSimCardLabel
            ? !isContractSimTypeEsim && ui.mnpSimTypeCurrentHint
            : isContractSimTypeEsim && ui.mnpSimTypeCurrentHint}
        </Copy>
      </div>
    );
  }

  render() {
    const { isSimCardLabel, isContractSimTypeEsim } = this.props;
    return this.renderSimTypeOptionsLabel(isSimCardLabel, isContractSimTypeEsim);
  }
}

SimTypeToggleLabel.propTypes = {
  ui: PropTypes.shape({
    mnpSimTypeCurrentHint: PropTypes.string,
    mnpSimTypeEsim: PropTypes.string,
    mnpSimTypeCard: PropTypes.string,
    guiWordAllright: PropTypes.string,
  }),
  dispatch: PropTypes.func,
  isContractSimTypeEsim: PropTypes.bool,
  isSimCardLabel: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default compose(
  connectUI(),
  connect(mapDispatchToProps),
)(SimTypeToggleLabel);
