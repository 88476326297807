import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';
import MediaSvgLoader from '../media/MediaSvgLoader';
import Copy from '../text/TextCopy';
import { formatDate, formatTime } from '../../../helpers/date';
import { pascalCase } from '../../../helpers/str';

const componentName = 'UILinearProgressBar';
class UILinearProgressBar extends PureComponent {

  getValue(value) {
    const { unit } = this.props;
    const postfix = unit !== 'quantity' ? unit.toUpperCase() : '';
    const spacer = postfix !== '' ? ' ' : '';
    return `${value}${spacer}${postfix}`;
  }

  render() {
    const {
      value,
      valueMax,
      title,
      ui,
      expiration,
      valueType,
      textInfo,
      onConsumptionsIndividual,
    } = this.props;
    const to = Math.ceil((value / valueMax) * 100);
    // @TODO consider using component StringSubscriptionConsumption
    const valueCopy = ui[`consumptionsIndividual${pascalCase(valueType)}`].replace('{VALUE_LEFT}', this.getValue(value)).replace('{VALUE_MAX}', this.getValue(valueMax)) || '';
    const expirationCopy = ui.consumptionsIndividualExpiration.replace('{DATE}', formatDate(expiration)).replace('{TIME}', formatTime(expiration)) || '';

    return (
      <div
        className={suitcss({ componentName, modifiers: [textInfo && 'clickable'] })}
        data-from={0}
        data-to={to}
        onClick={() => textInfo && onConsumptionsIndividual(title, textInfo)}
      >
        {textInfo &&
          <div className={suitcss({ descendantName: 'info' }, this)} >
            <MediaSvgLoader path="/icons/content-info.svg" />
          </div>
        }
        <Copy className={suitcss({ utilities: ['marginBottomS'] })} size="tertiary" embedded element="p">{title}</Copy>
        <div className={suitcss({ descendantName: 'progress' }, this)} >
          <MediaSvgLoader path="/icons/linear-progressbar.svg" />
        </div>
        <div className={suitcss({ descendantName: 'content', utilities: ['marginTopS'] }, this)} >
          <Copy embedded raw>{valueCopy}</Copy>
          <Copy className={suitcss({ descendantName: 'date' }, this)} embedded raw>{expirationCopy}</Copy>
        </div>
      </div>
    );
  }
}

UILinearProgressBar.propTypes = {
  value: PropTypes.number.isRequired,
  valueMax: PropTypes.number.isRequired,
  valueType: PropTypes.oneOf(['data', 'data_roaming_1', 'phone_sms_roaming_1']).isRequired,
  unit: PropTypes.oneOf(['gb', 'mb', 'quantity']).isRequired,
  title: PropTypes.string.isRequired,
  expiration: PropTypes.string.isRequired,
  textInfo: PropTypes.string,
  onConsumptionsIndividual: PropTypes.func,
  ui: PropTypes.shape({
    consumptionsIndividualData: PropTypes.string.isRequired,
    consumptionsIndividualDataRoaming1: PropTypes.string.isRequired,
    consumptionsIndividualPhoneSmsRoaming1: PropTypes.string.isRequired,
    consumptionsIndividualExpiration: PropTypes.string.isRequired,
  }),
};

export default UILinearProgressBar;
