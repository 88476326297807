import PageModule from './PageModule';
import { send } from '../../actions/request/send';
import CountriesRequest from '../requests/CountriesRequest';
import { MARKET_PREPAID } from '../../helpers/constants';
import GdprConsentsRequest from '../requests/GdprConsentsRequest';
import PasswordValidationRulesRequest from '../requests/PasswordValidationRulesRequest';

/**
 * The ActivationForm module forms the new concept:
 * activation process for SIM card activation via a 6-step form.
 * @see https://confluence.db-n.com/x/tb7c
 */
class ActivationFormModule extends PageModule {
  constructor(moduleData) {
    super(import(/* webpackChunkName:"HeaderStage" */ '../../containers/activation/ActivationForm'), moduleData);
  }


  prepare() {
    return async dispatch => {
      await dispatch(send(new GdprConsentsRequest()));
    };
  }
  /**
   * @override
   */
  prepareBeforeMount() {
    return async (dispatch) => {
      dispatch(send(new PasswordValidationRulesRequest()));
      const countries = (await dispatch(send(new CountriesRequest(MARKET_PREPAID)))).body.data;
      return {
        countries,
      };
    };
  }
}

export default ActivationFormModule;

