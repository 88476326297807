import {
  startSubmit,
  stopSubmit,
  change as changeField,
} from 'redux-form';

import PasswordResetSmsRequest from '../../../model/requests/PasswordResetSmsRequest';
import PasswordResetEmailRequest from '../../../model/requests/PasswordResetEmailRequest';
import { send } from '../../../actions/request/send';

export const getTimestamps = (fieldMap, formValues) =>
  formValues[fieldMap.timestamps.name] || { email: {}, sms: {} };

export const getTimestamp = (fieldMap, formValues, option) => {
  const msisdn = formValues[fieldMap.msisdn.name];
  const timestamps = getTimestamps(fieldMap, formValues);
  return timestamps[option][msisdn] || 0;
};

export const getSmsTimestamp = (fieldMap, formValues) =>
  getTimestamp(fieldMap, formValues, 'sms');

export const getEmailTimestamp = (fieldMap, formValues) =>
  getTimestamp(fieldMap, formValues, 'email');

const REQUEST_NOT_ALLOWED_DURATION = 1000 * 120;

export const getUpdateTimestampAction =
  (form, fieldMap, formValues, selectedOption, cooldownSecs) => {
    const msisdn = formValues[fieldMap.msisdn.name];
    const timestamps = getTimestamps(fieldMap, formValues);
    const cooldown = cooldownSecs ? cooldownSecs * 1000 : REQUEST_NOT_ALLOWED_DURATION;

    const newTimestamps = {
      ...timestamps,
      ...{
        [selectedOption]: {
          ...timestamps[selectedOption],
          [msisdn]: Date.now() + cooldown,
        },
      },
    };

    return changeField(
      form,
      fieldMap.timestamps.name,
      newTimestamps,
    );
  };

export const getTokenRequestHandler = (selectedOption, dispatch, ownProps) => {
  const { form, showError, fieldMap, formValues } = ownProps;
  const msisdn = formValues[fieldMap.msisdn.name];

  const tokenRequest = selectedOption === 'sms'
    ? new PasswordResetSmsRequest(msisdn)
    : new PasswordResetEmailRequest(msisdn);

  return async () => {
    dispatch(startSubmit(form));
    let cooldown = null;
    try {
      const response = await dispatch(send(tokenRequest));
      cooldown = response.cooldown;
    } catch (err) {
      showError(err.response);
    }

    dispatch(getUpdateTimestampAction(form, fieldMap, formValues, selectedOption, cooldown));
    dispatch(stopSubmit(form));
  };
};
