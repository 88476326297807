export default class Environment {
  constructor(variables) {
    this.variables = variables;
  }

  /**
   * Filters the process variables by there beginning string
   * e. g. ns = 'DBN' retrieves all dbn related variables
   * @param name Name of the namespace
   * @return {{}} Filtered process variable object
   */
  getVariablesByNamespace(name) {
    return Object.entries(this.variables)
      .filter(envVariables => envVariables[0].startsWith(name))
      .reduce((acc, item) => { acc[item[0]] = item[1]; return acc; }, {});
  }

  getVariableAsBoolean(name) {
    const variable = this.variables[name];
    return variable === '1' || variable === 'true';
  }

  getVariableAsString(name) {
    return this.variables[name];
  }
}
