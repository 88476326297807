import PageModule from './PageModule';
import component from '../../containers/eSim/ESimDeviceCheck';

class ESimDeviceCheckModule extends PageModule {
  constructor(moduleData) {
    super(component, moduleData);
  }
}

export default ESimDeviceCheckModule;
