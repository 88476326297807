import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { showFriendReferralHistoryDialog } from '../../../actions/dialog/misc';
import * as requestActions from '../../../actions/request/registry';
import reducedReferralStatusItems from '../../../helpers/friendReferralStatus';
import suitcss from '../../../helpers/suitcss';
import Copy from '../../basics/text/TextCopy';
import TextHeadline from '../../basics/text/TextHeadline';
import Link from '../../basics/text/TextLink';
import ContentLoader from '../content/ContentLoader';
import FriendReferralStatusCard from '../friendReferralStatus/FriendReferralStatusCard';

class FriendReferralHistoryDialog extends PureComponent {
  componentDidMount() {
    const { fetchFriendReferralHistory } = this.props;
    fetchFriendReferralHistory();
  }

  render() {
    const {
      statusItems, actions, ui, showHistoryDialog,
    } = this.props;

    const items = statusItems && reducedReferralStatusItems(statusItems, ui);

    return (
      <div className={suitcss({}, this)}>
        <TextHeadline
          size="m"
          utilities={['weightBold', 'alignCenter', 'paddingBottomS']}
          embedded
        >
          {ui.myFrdHistoryHeadline}
        </TextHeadline>
        <ContentLoader
          isLoaded={!!items}
        >
          {items && (
          <div
            className={suitcss({
              descendantName: 'items',
              utilities: [!items.length && 'justifyCenter'],
            }, this)}
          >
            {items.length > 0 ? items.map((item) => (
              <div className={suitcss({ descendantName: 'item' }, this)} key={item.statusItemCopy}>
                <FriendReferralStatusCard
                  statusItem={item}
                  theme="light"
                  showHistoryDialog={showHistoryDialog}
                />
              </div>
            )) : (
              <Copy size="secondary" utilities={['alignCenter']}>
                {ui.myFrdHistoryEmptyCopy}
              </Copy>
            )}
          </div>
        )}
        </ContentLoader>
        <div
          className={suitcss({
                descendantName: 'actions',
                utilities: ['alignCenter', 'paddingTopS'],
              }, this)}
        >
          {actions.map((item) => (
            <Link
              utilities={['marginTopS']}
              onClick={item.action}
              asButton
              buttonFilled
              withoutArrow
              element="button"
              key={item.label}
            >
              {item.label}
            </Link>
          ))}
        </div>
      </div>
    );
  }
}

FriendReferralHistoryDialog.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    action: PropTypes.func,
  })).isRequired,
  statusItems: PropTypes.array,
  fetchFriendReferralHistory: PropTypes.func,
  showHistoryDialog: PropTypes.func,
  ui: PropTypes.object,
};

FriendReferralHistoryDialog.defaultProps = {
  actions: [],
};

const mapStateToProps = (state) => ({
  statusItems: state.friendReferral.referralHistory,
  ui: state.ui,
});

const mapDispatchToProps = (dispatch) => ({
  fetchFriendReferralHistory: () => dispatch(requestActions.fetchFriendReferralHistory()),
  showHistoryDialog: () => dispatch(showFriendReferralHistoryDialog()),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(FriendReferralHistoryDialog);
