import PageModule from './PageModule';
import component from '../../containers/account/passwordReset/PasswordResetForm';
import { send } from '../../actions/request/send';
import PasswordValidationRulesRequest from '../requests/PasswordValidationRulesRequest';

/**
 * Module for displaying password reset form
 * @see https://confluence.db-n.com/x/FPTc
 */
class PasswordResetFormModule extends PageModule {
  constructor(moduleData) {
    super(component, moduleData);
  }

  prepareBeforeMount() {
    return async (dispatch) => {
      dispatch(send(new PasswordValidationRulesRequest()));
    };
  }
}

export default PasswordResetFormModule;

