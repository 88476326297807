/* eslint-disable quotes */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import FormManager from '../form/FormManager';
import * as contactData from './FormConfigPresalesCancellation';
import GlobalSection from '../../components/basics/global/GlobalSection';
import { trackMyState } from '../../actions/tracking/event';
import { MYTRACK_CONTACT_SUBMIT_SUCCESS } from '../../helpers/constants';
import PresalesCancellationRequest from '../../model/requests/PresalesCancellationRequest';
import Headline from '../../components/basics/text/TextHeadline';
import CancellationConfirmation from '../../components/compositions/cancellation/PresalesCancellationConfirmation';
import matchMediaConnector from '../service/ServiceMatchMedia';
import Copy from '../../components/basics/text/TextCopy';
import { fetchCaptchaData } from '../../actions/request/registry';

class PresalesCancellationForm extends PureComponent {
  constructor(props, ...args) {
    super(props, ...args);
    this.state = {
      submitSuccessful: false,
      finalizedValues: undefined,
      apiResponse: undefined,
    };
    this.onAfterSubmitSuccess = this.onAfterSubmitSuccess.bind(this);
    this.getSubmitRoute = this.getSubmitRoute.bind(this);
  }

  onAfterSubmitSuccess(props) {
    const { response, finalizedValues, responseBody } = props;
    const { dispatch } = this.props;
    dispatch(trackMyState(MYTRACK_CONTACT_SUBMIT_SUCCESS));
    this.setState({
      submitSuccessful: response.status === 200,
      finalizedValues,
      apiResponse: responseBody,
    });
  }

  getSubmitRoute(fieldMap, finalizedFormValues) {
    const { captchaToken } = this.props;
    const values = {
      address: {
        firstName: finalizedFormValues.firstName,
        lastName: finalizedFormValues.lastName,
        street: finalizedFormValues.street,
        houseNumber: finalizedFormValues.houseNumber,
        zipCode: finalizedFormValues.zip,
        city: finalizedFormValues.city,
      },

      contractIdent: finalizedFormValues.contractIdent,
      customerId: finalizedFormValues.customerId,
      email: finalizedFormValues.email,
      terminationDate: finalizedFormValues.terminationDate,
      terminationDetails: finalizedFormValues.terminationDetails,
      captcha: {
        token: captchaToken,
        solution: finalizedFormValues.captcha ? finalizedFormValues.captcha : '',
      },
    };

    return new PresalesCancellationRequest(values);
  }

  renderForm() {
    const { params, dispatch } = this.props;
    return (
      <>
        <FormManager
          form={PresalesCancellationForm.formName}
          stepProps={this.props}
          submitRoute={this.getSubmitRoute}
          onAfterSubmitSuccess={this.onAfterSubmitSuccess}
          onSubmitWillFail={async () => { await dispatch(fetchCaptchaData()); }}
          steps={[contactData]}
          fieldsToResetOnSubmitFail={
            {
              captcha: '',
            }
          }
          withFooter
        />
        <Copy
          raw
          utilities={['MarginTopXXL', 'mlPaddingHXXL', 'mlMarginHXXL', 'PaddingTopS', 'alignCenter']}
        >
          {params.footnoteCopy}
        </Copy>
      </>
    );
  }

  renderCancellationConfirmation() {
    const { params, isMediaSM } = this.props;

    return (
      <CancellationConfirmation
        {...params}
        inputValues={this.state.finalizedValues}
        apiResponse={this.state.apiResponse}
        isMediaSM={isMediaSM}
      />
    );
  }

  render() {
    const { params, layoutSettings } = this.props;
    const { headline, confirmHeadline } = params;

    return (
      <GlobalSection layoutSettings={layoutSettings}>
        <Headline utilities={['alignCenter', 'marginBottomM']} size="xl">
          {this.state.submitSuccessful ? confirmHeadline : headline}
        </Headline>
        {this.state.submitSuccessful ? this.renderCancellationConfirmation() : this.renderForm()}
      </GlobalSection>
    );
  }
}

/**
 * required by tracking!
 */
PresalesCancellationForm.formName = 'cancellation';

PresalesCancellationForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  layoutSettings: PropTypes.object,
  captchaToken: PropTypes.string,
  params: PropTypes.shape({
    headline: PropTypes.string.isRequired,
  }).isRequired,
  isMediaSM: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => ({ dispatch });

const mapStateToProps = (state) => {
  return {
    captchaToken: state.captcha.token,
  };
};

export default compose(
  matchMediaConnector(['isMediaS', 'isMediaSM']),
  connect(mapStateToProps, mapDispatchToProps),
)(PresalesCancellationForm);
