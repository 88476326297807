import React from 'react';
import PropTypes from 'prop-types';

import suitcss from '../../../helpers/suitcss';
import connectUniqueId from '../service/UniqueIdConnector';

import { FieldPropTypes, inputPropTypes } from '../../../propTypes/field';

import FieldWrapper from './FieldWrapper';
import TextRaw from '../text/TextRaw';
import SvgLoader from '../media/MediaSvgLoader';

const componentName = 'FieldCheckbox';

function FieldCheckbox(props) {
  const { label, input, isControlled, isInactive, isPreChecked } = props;
  return (
    <FieldWrapper
      componentName={componentName}
      modifiers={[isControlled && 'controlled', isInactive && 'inactive']}
      {...props}
    >
      {wrapperProvider => (
        <div
          className={suitcss({
            componentName,
            descendantName: 'inner',
          })}
        >
          <input
            className={suitcss({
              componentName,
              descendantName: 'input',
            })}
            id={props.uniqueId}
            type="checkbox"
            {...input}
            checked={Boolean(input.value) || isPreChecked}
            disabled={isControlled}
          />
          <label
            className={suitcss({
              componentName,
              descendantName: 'label',
            })}
            htmlFor={props.uniqueId}
          >
            <span
              className={suitcss({
                componentName,
                descendantName: 'labelInner',
              })}
            >
              <TextRaw>{label}</TextRaw>
              {!wrapperProvider.isRequired && wrapperProvider.getIsOptionalElement()}
            </span>
          </label>
          <div
            className={suitcss({
              componentName,
              descendantName: 'checkmark',
            })}
          >
            <SvgLoader
              path="/icons/content-check-stroke.svg"
            />
          </div>
        </div>
      )}
    </FieldWrapper>
  );
}

FieldCheckbox.propTypes = Object.assign({}, FieldPropTypes, {
  uniqueId: PropTypes.string.isRequired,
  input: PropTypes.shape(Object.assign({}, inputPropTypes, {
    value: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
    ]).isRequired, // make this required
  })),
  info: PropTypes.shape({
    headline: PropTypes.string.isRequired,
    copy: PropTypes.string.isRequired,
  }),
  isControlled: PropTypes.bool,
  isPreChecked: PropTypes.bool,
});

FieldCheckbox.defaultProps = {
  meta: {},
  input: {
    value: false, // provide a default boolean value
  },
  isPreChecked: false, // provide a default boolean value
  raw: false,
  theme: 'default',
};

export default connectUniqueId('fcb')(FieldCheckbox);
