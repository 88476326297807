import React from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../../helpers/suitcss';
import Slider from '../../../basics/slider/Slider';
import SliderSlide from '../../../basics/slider/SliderSlide';
import DashboardNavigation from './DashboardNavigation';
import { breakpointSmallToMedium } from '../../../../containers/service/ServiceMatchMedia';


const sliderConfig = {
  breakpoints: {},
  slidesPerView: 2,
  centeredSlides: false,
  simulateTouch: false,
  autoHeight: false,
  spaceBetween: 48,
};

sliderConfig.breakpoints[0] = {
  slidesPerView: 1,
  spaceBetweenSlides: 0,
  centeredSlides: true,
  simulateTouch: true,
  spaceBetween: 30,
};

sliderConfig.breakpoints[breakpointSmallToMedium - 1] = {
  spaceBetween: 24,
};

function DashboardWrapper(props) {
  const { children } = props;
  return (
    <div className={suitcss({}, this)}>
      <Slider
        theme="light"
        arrows={DashboardNavigation}
        config={sliderConfig}
      >
        {children && children.map((child, index) => (
          <SliderSlide key={index}>
            {child}
          </SliderSlide>
        ))}
      </Slider>

    </div>
  );
}

DashboardWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array,
  ]),
};

export default DashboardWrapper;
