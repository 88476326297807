import AbstractError from './AbstractError';
import {
  FORM_UNKNOWN_SUBMIT_ERROR,
} from '../../config/errorCodes';

/**
 * Error to be thrown if the system has been shutdown for maintenance reasons.
 */
class UnknownSubmitError extends AbstractError {
  /**
   * @param {Error} previous
   */
  constructor(previous) {
    super(FORM_UNKNOWN_SUBMIT_ERROR);
    this.setPrevious(previous);
  }
}

export default UnknownSubmitError;
