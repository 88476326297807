import AbstractError from './AbstractError';
import { FORM_UNKNOWN_STEP_ERROR } from '../../config/errorCodes';

/**
 * Thrown if the user tried to navigate to an unknown form step.
 */
class UnknownFormStepError extends AbstractError {
  constructor() {
    super(FORM_UNKNOWN_STEP_ERROR);
  }
}

export default UnknownFormStepError;
