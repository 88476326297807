import QueueableRequest from './QueueableRequest';
import { ACCOUNT_NBA_OFFER_ENDPOINT } from '../../config/api';
import { bindParamsToRoute } from '../../helpers/url';

export default class NbaOfferRequest extends QueueableRequest {
  constructor(msisdn, options) {
    super(bindParamsToRoute(ACCOUNT_NBA_OFFER_ENDPOINT, { msisdn }), options);
  }

  isPreventDefaultErrorHandling(error) {
    return (error.fullResponse && error.fullResponse.status === 404)
      || super.isPreventDefaultErrorHandling(error);
  }
}
