import React from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';
import Headline from '../../basics/text/TextHeadline';
import Copy from '../../basics/text/TextCopy';
import Link from '../../basics/text/TextLink';
import TextRaw from '../../basics/text/TextRaw';

const componentName = 'ShareLinkContainer';
function ShareLinkContainer(props) {
  const {
    title,
    subline,
    bottomHint,
    onOpenHintDialog,
    links,
    showLinks,
    utilities,
  } = props;
  return (
    <div className={suitcss({ componentName, utilities: ['alignCenter', ...utilities] })}>
      {title && (
        <Headline size="s" embedded utilities={['weightBold']}>
          {title}
        </Headline>
      )}
      {subline && (
        <Copy size="secondary" embedded raw>{subline}</Copy>
      )}
      {showLinks && !!links.length && (
        <div className={suitcss({ componentName, descendantName: 'links' })}>
          {links.map((link, index) => {
            return (
              <div
                className={suitcss({ componentName, descendantName: 'linkWrapper' })}
                key={index}
                data-tracking="target"
                data-target-content={link.label}
              >
                <Link
                  className={suitcss({
                    componentName,
                    descendantName: 'link',
                  })}
                  icon={link.icon}
                  asShareButton
                  withoutArrow={!link.withArrow}
                  color="light"
                  element={link.to ? 'a' : 'button'}
                  href={link.to || ''}
                  target={link.target || '_self'}
                  onClick={link.onClick || null}
                >
                  {link.label}
                </Link>
              </div>
            );
          })}
        </div>
      )}
      {bottomHint && (
        <TextRaw className={suitcss({ componentName, descendantName: 'bottomHint' })} onClick={onOpenHintDialog || null}>
          {bottomHint}
        </TextRaw>
      )}
    </div>
  );
}

ShareLinkContainer.propTypes = {
  utilities: PropTypes.array,
  links: PropTypes.array,
  title: PropTypes.string,
  subline: PropTypes.string,
  bottomHint: PropTypes.string,
  onOpenHintDialog: PropTypes.func,
  showLinks: PropTypes.bool,
};

ShareLinkContainer.defaultProps = {};

export default ShareLinkContainer;
