import PropTypes from 'prop-types';

export const imagePropTypes = {
  alt: PropTypes.string,
  src: PropTypes.string,
  width: PropTypes.number,
  variations: PropTypes.object,
  isLazy: PropTypes.bool,
};

export const imageShape = PropTypes.shape(imagePropTypes);
export const imageList = PropTypes.arrayOf(imageShape);
