import QueueableRequest from './QueueableRequest';
import { ENTITY_ENDPOINT } from '../../config/api';
import { bindParamsToRoute } from '../../helpers/url';
import { camelCase } from '../../helpers/str';

export default class EntityRequest extends QueueableRequest {
  constructor(eid, options) {
    super(bindParamsToRoute(ENTITY_ENDPOINT, { eid }), options);
    this.eid = eid;
  }

  /**
   * We camelcase the etype of the entity. it has been decided to do so
   * for convenience reasons to simplify etype-related filter and matching operations.
   * {@inheritDoc}
   */
  handleResponse(response) {
    response.body.data.etype = camelCase(response.body.data.etype);
    return response;
  }
}
