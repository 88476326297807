/* global HTMLElement, document */
export const matches = (el, selector) => {
  const elProto = HTMLElement.prototype;
  const elMatches = (
    elProto.matches ||
    elProto.matchesSelector ||
    elProto.mozMatchesSelector ||
    elProto.msMatchesSelector ||
    elProto.oMatchesSelector ||
    elProto.webkitMatchesSelector
  );
  return elMatches.call(el, selector);
};

export const closest = (el, selector, checkSelf) => {
  let parent = checkSelf ? el : el.parentNode;

  while (parent && parent !== document) {
    if (matches(parent, selector)) {
      return parent;
    }
    parent = parent.parentNode;
  }
  return null;
};

export const parents = (el, selector, checkSelf) => {
  let parent = checkSelf ? el : el.parentNode;
  const list = [];

  while (parent && parent !== document) {
    if (matches(parent, selector)) {
      list.push(parent);
    }
    parent = parent.parentNode;
  }
  return list;
};
