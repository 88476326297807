import { formValueSelector } from 'redux-form';
import {
  isTariffEntity,
  isTariffOptionEntity,
  isHardwareEntity,
} from './entity';
import {
  fetchEntitiesByIds,
  fetchMyPromotions,
  fetchPromotions,
} from '../actions/request/registry';
import { resolveProducts } from '../actions/tracking/shared';
import {
  FORM_FIELD_CHECKOUT_MNP,
  FORM_NAME_CHECKOUT,
  MYTRACK_PROPOSITION_PRODUCT_POSTPAID,
  MYTRACK_PROPOSITION_PRODUCT_PREPAID,
  MYTRACK_PROPOSITION_LINE_DATA,
  MYTRACK_PROPOSITION_LINE_VOICE,
  MYTRACK_PROPOSITION_TYPE_DEBIT,
  MYTRACK_PROPOSITION_PRODUCT_AUDIENCE_YOUNG,
  MYTRACK_PROPOSITION_PRODUCT_AUDIENCE_CUSTOMER,
  MYTRACK_PROPOSITION_TYPE_CREDIT,
  MYTRACK_PROPOSITION_RETENTION_NEWCONTRACT,
  MYTRACK_PROPOSITION_CATEGORY_SELECTION,
  MYTRACK_PROPOSITION_PRODUCT_TYPE_DEVICE,
  MYTRACK_PROPOSITION_RETENTION_PROLONGATION,
  MYTRACK_PROPOSITION_CATEGORY_TARIFF,
  MYTRACK_PROPOSITION_CATEGORY_BUNDLE,
  E_SIM,
} from './constants';
import { getContext, getMatchingPromotions } from './promotions';

//--------------------------------------------------------------------------------------------------
//  Internal helper Functions
//--------------------------------------------------------------------------------------------------

const getProductName = (mnp) => (product) => {
  if (isTariffEntity(product)) {
    return mnp ? `${product.type} ${product.name} MNP` : `${product.type} ${product.name}`;
  }
  if (isTariffOptionEntity(product)) {
    return `${product.type} ${product.name}`;
  }
  if (isHardwareEntity(product)) {
    // @todo add brand name from hardwareGroup
    return `${product.name}`;
  }
  return '';
};

const getProductDiscountType = (incentive) => {
  if (incentive.type === 'other' || incentive.type === 'service' || incentive.type === 'volume') return {};
  if (incentive.feeType === 'one_time') {
    return {
      discount_once: (incentive.discount.unit / 100),
    };
  }
  return {
    discount_monthly: (incentive.discount.unit / 100),
  };
};


const getProductDiscounts = (promos) => {
  const allDiscounts = [];

  if (!promos || promos.length === 0) {
    return [];
  }

  promos.forEach((promotion) => {
    promotion.incentives.forEach(incentive => {
      allDiscounts.push({
        id: incentive.iid,
        name: incentive.headline,
        type: incentive.type,
        ...getProductDiscountType(incentive),
      });
    });
  });
  return allDiscounts;
};

const getProductType = type => {
  return type === 'postpaid' ? MYTRACK_PROPOSITION_PRODUCT_POSTPAID : MYTRACK_PROPOSITION_PRODUCT_PREPAID;
};

const getProductTariff = (product, mnp) => {

  return {
    name: getProductName(mnp)(product),
    type: getProductType(product.category),
  };

};

const getProductHardware = (product, getState, mnp) => {
  const { entities } = getState();

  const brand = entities.hardwareGroup[product.groupId] ?
    entities.hardwareGroup[product.groupId].brand :
    product.urlDetails.split('/')[2].charAt(0).toUpperCase() +
    product.urlDetails.split('/')[2].slice(1);
  const capacity = `${product.capacity + product.capacityUnit.toUpperCase()}`;
  return {
    name: `${brand} ${getProductName(mnp)(product)} ${capacity} ${product.color}`,
    type: MYTRACK_PROPOSITION_PRODUCT_TYPE_DEVICE,
  };
};

const getProductTariffOptions = (sortedEntities, product) => {
  return {
    name: getProductName(false)(product),
    type: `${sortedEntities.tariffs[0] && getProductType(sortedEntities.tariffs[0].category)} option`,
  };
};

// ++++++++++++++++++++++++++++++++++++++++++ Proposition ++++++++++++++++++++++++++++++++++++++++++

const getPropositionLine = (product) => {
  if (isTariffEntity(product)) {
    if (product.simOnly && product.market === 'PUC') {
      return MYTRACK_PROPOSITION_LINE_DATA;
    }
  }
  return MYTRACK_PROPOSITION_LINE_VOICE;
};

const getPropositionType = (product) => {
  if (isTariffEntity(product)) {
    if (!(product.market === 'PUC')) {
      return MYTRACK_PROPOSITION_TYPE_DEBIT;
    }
  }
  return MYTRACK_PROPOSITION_TYPE_CREDIT;
};

const getPropositionAudience = (product) => {
  if (isTariffEntity(product)) {
    if (product.iid === '75' || product.iid === '37' || product.iid === '77') {
      return MYTRACK_PROPOSITION_PRODUCT_AUDIENCE_YOUNG;
    }
  }
  return MYTRACK_PROPOSITION_PRODUCT_AUDIENCE_CUSTOMER;
};

const getPropositionRetention = (product, getState) => {
  const { site } = getState();
  if (site.contractRenewal.isInProgress ||
    site.routing.requestedLocation.pathname === '/mein-otelo/vertragsverlaengerung/post/vielen-dank' ||
    site.routing.requestedLocation.pathname === '/mein-otelo/vertragsverlaengerung/pre/vielen-dank'
  ) {
    return MYTRACK_PROPOSITION_RETENTION_PROLONGATION;
  }
  return MYTRACK_PROPOSITION_RETENTION_NEWCONTRACT;
};

const createPropositionHeaderFromTariff = (product, getState) => {

  return {
    audience: getPropositionAudience(product),
    retention: getPropositionRetention(product, getState),
    type: getPropositionType(product),
    line: getPropositionLine(product),
  };
};

const createPropositionHeaderFromHardware = () => {
  return {
    audience: MYTRACK_PROPOSITION_PRODUCT_AUDIENCE_CUSTOMER,
    retention: MYTRACK_PROPOSITION_RETENTION_NEWCONTRACT,
    type: MYTRACK_PROPOSITION_TYPE_CREDIT,
    line: MYTRACK_PROPOSITION_LINE_VOICE,
  };
};

const getPropositionHeader = (products, getState) => {

  const basePropositionHeader = {
    name: MYTRACK_PROPOSITION_CATEGORY_SELECTION,
    category: MYTRACK_PROPOSITION_CATEGORY_SELECTION,
  };

  if (products.tariffs.length > 0) {
    return {
      ...basePropositionHeader,
      ...createPropositionHeaderFromTariff(products.tariffs[0], getState),
    };

  } else if (products.tariffs.length === 0 && products.hardware.length > 0) {
    return {
      ...basePropositionHeader,
      ...createPropositionHeaderFromHardware(),
    };

  } else if (products.tariffs.length === 0 && products.tariffOptions.length > 0) {
    return {
      ...basePropositionHeader,
    };
  }
};

const mapEntityToProduct = (product, getState, sortedEntities) => {
  const selector = formValueSelector(FORM_NAME_CHECKOUT);
  const mnp = selector(getState(), FORM_FIELD_CHECKOUT_MNP);
  const { entities } = sortedEntities;
  let hardwarePrice = {};

  if (isHardwareEntity(product)) {
    const selectedTariff = entities.filter(entity => entity.etype === 'tariffEntity');

    if (selectedTariff.length > 0) {
      const prices = product.tariffMap[selectedTariff[0].eid.toLowerCase()];
      hardwarePrice = {
        price_once: prices.singlePaymentFee && prices.singlePaymentFee.unit,
        price_monthly: prices.paymentFee && prices.paymentFee.unit,
      };
    } else {
      const tariffValues = Object.values(product.tariffMap).filter(price => price);
      const smallestPrice = tariffValues.reduce(
        (smallest, current) =>
          smallest.singlePaymentFee.unit > current.singlePaymentFee.unit ? current : smallest,
        tariffValues[0]);

      hardwarePrice = {
        price_once: smallestPrice.singlePaymentFee.unit,
        price_monthly: smallestPrice.paymentFee.unit,
      };
    }
  }

  const priceOnes = (product.singlePaymentFee ?
    product.singlePaymentFee.unit / 100 :
    hardwarePrice.price_once / 100);

  const priceMonthly = (product.paymentFee ?
    product.paymentFee.unit / 100 :
    hardwarePrice.price_monthly / 100);

  let propositionProduct = {
    id: product.iid,
    price_once: isNaN(priceOnes) ? 0 : priceOnes,
    price_monthly: isNaN(priceMonthly) ? 0 : priceMonthly,
    quantity: '1',
  };

  if (isTariffEntity(product)) {
    propositionProduct = { ...propositionProduct, ...getProductTariff(product, mnp) };
  } else if (isHardwareEntity(product)) {
    propositionProduct = { ...propositionProduct, ...getProductHardware(product, getState) };
  } else if (isTariffOptionEntity(product)) {
    propositionProduct = {
      ...propositionProduct,
      ...getProductTariffOptions(sortedEntities, product),
    };
  }

  return propositionProduct;
};

const mapAllEntitiesToProducts = (products, getState, sortedEntities) => {
  return products.map(product => {
    return mapEntityToProduct(product, getState, sortedEntities);
  });
};

const fetchProductDetails = async (getState, dispatch, productsTypes) => {

  const { orderProcess, cart } = getState();
  const { entities } = orderProcess;
  const rawProducts = productsTypes === 'nav' ? entities : cart;

  await dispatch(
    fetchEntitiesByIds(rawProducts.map(stub => stub.eid), false, { isBlocking: false }),
  );

  const products = await dispatch(resolveProducts(rawProducts));

  return products;
};

const sortEntitieseByType = (products) => {
  const sortedProducts = {
    tariffs: [],
    hardware: [],
    tariffOptions: [],
    entities: products,
  };

  products.forEach(product => {
    if (isTariffEntity(product)) {
      sortedProducts.tariffs.push(product);
    } else if (isTariffOptionEntity()) {
      sortedProducts.tariffOptions.push(product);
    } else if (isHardwareEntity(product)) {
      sortedProducts.hardware.push(product);
    }
  });

  return sortedProducts;
};

const setBaseFormTracking = (
  formType,
  formName,
) => {
  return {
    form_type: formType,
    form_name: formName,
  };
};

const getTrackingRouteAndQuery = (getState) => {
  const { site } = getState();
  let location = site.routing.pendingLocation;

  let query = '';

  if (location) {
    query = location.query;
  } else {
    location = site.routing.currentLocation;
    query = location.query;
  }
  return { query, location };
};

const getCheckoutFormStepInfo = (getState, successRoute = false) => {

  const { query } = getTrackingRouteAndQuery(getState);

  if (successRoute) {
    return {
      form_step_name: 'confirmation',
      form_step_number: '4',
      form_status: 'success',
    };
  }

  switch (query.step) {
    case 'shipping': {
      return {
        form_step_name: 'personal data',
        form_step_number: '1',
      };
    }
    case 'payment': {
      return {
        form_step_name: 'payment',
        form_step_number: '2',
      };
    }
    case 'confirm': {
      return {
        form_step_name: 'summary',
        form_step_number: '3',
      };
    }
  }
};

const getActivationFormStepInfo = (getState) => {
  const { query, location } = getTrackingRouteAndQuery(getState);
  switch (query.step) {
    case 'simData': {
      return {
        form_step_name: 'phone number',
        form_step_number: '1',
      };
    }
    case 'tariff': {
      return {
        form_step_name: 'tariff',
        form_step_number: '2',
      };
    }
    case 'topup': {
      return {
        form_step_name: 'topup',
        form_step_number: '3',
      };
    }
    case 'payment': {
      return {
        form_step_name: 'payment',
        form_step_number: '4',
      };
    }
    case 'personalData': {
      return {
        form_step_name: 'personal detail',
        form_step_number: '5',
      };
    }
    case 'confirm': {
      return {
        form_step_name: 'summary',
        form_step_number: '6',
      };
    }
  }

  if (location.pathname === '/service/aktivierung/vielen-dank') {
    return {
      form_step_name: 'Confirmation',
      form_step_number: '7',
      form_status: 'success',
    };
  }
};

const getReplacementSimFormStepInfo = (getState) => {
  const { query, location } = getTrackingRouteAndQuery(getState);
  if (location.pathname === '/mein-otelo/meine-services/ersatzsimkarte/karte-aktiv/vielen-dank' ||
    location.pathname === '/mein-otelo/meine-services/ersatzsimkarte/aktivierung/vielen-dank') {
    return {
      form_step_name: 'Confirmation',
      form_step_number: '2',
      form_status: 'success',
    };
  }

  if (Object.keys(query).length === 0) {
    return {
      form_step_name: 'replacement sim card',
      form_step_number: '1',
    };
  }
};

const getBelatedSimFormStepInfo = (getState) => {
  const { query, location } = getTrackingRouteAndQuery(getState);
  const { form } = getState();
  const belatedFormSimType = form.belatedMnp.values.mnp_sim_type;
  const belatedSimType = belatedFormSimType
    ? belatedFormSimType === E_SIM ? 'esim' : 'physical'
    : null;
  switch (query.step) {
    case 'msisdn': {
      return {
        form_step_name: 'number entry',
        form_step_number: '1',
      };
    }
    case 'contract': {
      return {
        form_step_name: 'contract data',
        form_step_number: '2',
        ...(belatedSimType && {
          sim_type: belatedSimType,
        }),
      };
    }
    case 'confirm': {
      return {
        form_step_name: 'confirmation',
        form_step_number: '3',
        ...(belatedSimType && {
          sim_type: belatedSimType,
        }),
      };
    }
  }
  if (location.pathname === '/mein-otelo/meine-services/rufnummernmitnahme-puc/vielen-dank') {
    return {
      form_step_name: 'summary',
      form_step_number: '4',
      form_status: 'success',
      ...(belatedSimType && {
        sim_type: belatedSimType,
      }),
    };
  }
};

const getCancellactionFormStepInfo = (getState) => {
  const { query, location } = getTrackingRouteAndQuery(getState);

  switch (query.step) {
    case 'holdOn': {
      return {
        form_step_name: 'hold on',
        form_step_number: '1',
      };
    }
    case 'cancellation': {
      return {
        form_step_name: 'cancellation',
        form_step_number: '2',
      };
    }
    case 'futureContact': {
      return {
        form_step_name: 'future contact',
        form_step_number: '3',
      };
    }
  }
  if (location.pathname === '/mein-otelo/kuendigung/vielen-dank') {
    return {
      form_step_name: 'confirmation',
      form_step_number: '4',
      form_status: 'success',
    };
  }
};

const getContractExtensionFormStepInfo = (getState, isSuccessRoute) => {
  if (isSuccessRoute) {
    return {
      form_step_name: 'confirmation',
      form_step_number: '2',
      form_status: 'success',
    };
  }

  return {
    form_step_name: 'summary',
    form_step_number: '1',
  };
};

//--------------------------------------------------------------------------------------------------
//  Module Exports
//--------------------------------------------------------------------------------------------------

export const isHardwareComparator = (a, b) => {
  const aIsHardware = isHardwareEntity(a);
  const bIsHardware = isHardwareEntity(b);
  if (aIsHardware === bIsHardware) {
    return 0;
  }
  if (aIsHardware) {
    return -1;
  }
  return 1;
};

/**
 * Get tracking pixel for free sim form
 * We need to hack a bit as the free simcards are no real entities
 * in our store and there is no entity in our cart.
 * Technically it's just a Smartphone 100 tariff with a promotion in the middleware
 * identified by the hidden tariff_identifier form value
 * so we need to manually fill the product pixel
 *
 * @param tariffIdentifier
 * @returns {*}
 */
export const getFreeSimPixel = (tariffIdentifier) => {
  const name = 'Prepaid Freikarte';

  const productPixel = {
    product_id: [tariffIdentifier, tariffIdentifier],
    product_bundle: ['No bundle/Simonly', ''],
    product_duration: ['', ''],
    product_type: ['pre-paid tariff', ''],
    product_combination: [name, name],
    product_name: [name, name],
    product_tariff: [name, ''],
    order_segment: 'Pre-paid',
  };

  return {
    ...productPixel,
    // quantity and unit_price are not relevant for free sim
    product_quantity: [],
    product_unit_price: [],
  };
};

export const getProductBasePixel = async (products, getState, dispatch, pixelType) => {
  const state = getState();
  const allPromotions = state.site.contractRenewal.isInProgress
    ? await dispatch(fetchMyPromotions())
    : await dispatch(fetchPromotions());
  const matchingPromotions = getMatchingPromotions(
    allPromotions,
    getContext(state),
    products,
  );
  const discount = getProductDiscounts(matchingPromotions);
  const sortedEntities = sortEntitieseByType(products);
  const simTypePixel = {
    id: 'SIM-Type',
    price_one: 0,
    quantity: 1,
    name: state.user.cartSimType,
    type: 'service',
  };
  const product = mapAllEntitiesToProducts(products, getState, sortedEntities);

  if (state.user.cartSimType && pixelType === 'cart') product.push(simTypePixel);

  return { proposition: [{
    product,
    ...(discount.length > 0 ? { discount } : {}),
    ...getPropositionHeader(sortedEntities, getState),
  }],
  };
};

export const getProductPixel = (getState, dispatch, pixelType) => {

  return fetchProductDetails(getState, dispatch, pixelType)
    .then((entities) => {
      return getProductBasePixel(
        entities.sort(isHardwareComparator),
        getState,
        dispatch,
        pixelType,
      );
    });
};

export const setPropositionName = (propositionPixel) => {
  propositionPixel.proposition.forEach(proposition => {
    const productNames = [];
    proposition.product.forEach(product => {
      if (!product.type.includes('option') && !product.type.includes('service')) {
        productNames.push(product.name);
      }
    });
    return proposition.name = productNames.join(' | '); // eslint-disable-line
  });

  return propositionPixel;
};

export const setPropositionCategory = (propositionPixel) => {
  propositionPixel.proposition.forEach(proposition => {
    if (proposition.product.length > 1) {
      let propositionCategory = MYTRACK_PROPOSITION_CATEGORY_TARIFF;
      proposition.product.forEach(productItem => {
        if (productItem.type === MYTRACK_PROPOSITION_PRODUCT_TYPE_DEVICE) {
          propositionCategory = MYTRACK_PROPOSITION_CATEGORY_BUNDLE;
        }
      });
      proposition.category = propositionCategory; // eslint-disable-line
    } else if (proposition.product.length === 1) {
      proposition.category = MYTRACK_PROPOSITION_CATEGORY_TARIFF; // eslint-disable-line
    }
  });
  return propositionPixel;
};

export const setCheckoutFormTracking = (getState, isSuccessRoute) => {
  const { site } = getState();
  return {
    ...(setBaseFormTracking(
      'sales',
      (site.contractRenewal.isInProgress ?
        'contract extension' :
        'checkout'
      ),
    )),
    ...(site.contractRenewal.isInProgress ?
      getContractExtensionFormStepInfo(getState, isSuccessRoute) :
      getCheckoutFormStepInfo(getState, isSuccessRoute)
    ),
  };
};

// todo naming of the method include form
export const setSimActivationTracking = (getState) => {
  const { query } = getTrackingRouteAndQuery(getState);
  return {
    ...(Object.keys(query).length ? setBaseFormTracking(
      'service',
      'sim activation',
    ) : {}),
    ...getActivationFormStepInfo(getState),
  };
};

export const setSimReplacementFormTracking = (getState) => {
  return {
    ...setBaseFormTracking(
      'service',
      'replacement sim card',
    ),
    ...getReplacementSimFormStepInfo(getState),
  };
};

export const setBelatedMNPFormTracking = (getState) => {
  return {
    ...setBaseFormTracking(
      'service',
      'number portability',
    ),
    ...getBelatedSimFormStepInfo(getState),
  };
};

export const setCancellationFormTracking = (getState) => {
  return {
    ...setBaseFormTracking(
      'service',
      'contract cancellation',
    ),
    ...getCancellactionFormStepInfo(getState),
  };
};

export const setContractExtension = (getState, isSuccessRoute) => {
  return {
    ...setBaseFormTracking(
      'sales',
      'contract extension',
    ),
    ...getContractExtensionFormStepInfo(getState, isSuccessRoute),
  };
};

export const getCorrectFormTrackingForSuccess = (getState) => {
  const { location } = getTrackingRouteAndQuery(getState);
  if (location.pathname === '/service/aktivierung/vielen-dank') {
    return setSimActivationTracking(getState);
  } else if (location.pathname === '/mein-otelo/kuendigung/vielen-dank') {
    return setCancellationFormTracking(getState);
  } else if (location.pathname === '/mein-otelo/meine-services/ersatzsimkarte/karte-aktiv/vielen-dank' ||
    location.pathname === '/mein-otelo/meine-services/ersatzsimkarte/aktivierung/vielen-dank') {
    return setSimReplacementFormTracking(getState);
  } else if (location.pathname === '/mein-otelo/meine-services/rufnummernmitnahme-puc/vielen-dank') {
    return getBelatedSimFormStepInfo(getState);
  }
};
