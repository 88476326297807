/* global document */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { showSimArticleListDialog } from '../../../../actions/dialog/misc';

import suitcss from '../../../../helpers/suitcss';

import Copy from '../../../basics/text/TextCopy';
import Link from '../../../basics/text/TextLink';
import FormHeader from '../FormHeader';
import FieldRadioGroup from '../../../basics/form/FieldRadioGroup';

const componentName = 'SimTypeToggle';
class SimTypeToggle extends PureComponent {
  componentDidMount() {
    const { showSimArticleList } = this.props;
    const deviceCheckLink = document.querySelector('.deviceCheckLink');
    if (deviceCheckLink) {
      deviceCheckLink.addEventListener('click', showSimArticleList);
    }
  }

  componentWillUnmount() {
    const { showSimArticleList } = this.props;
    const deviceCheckLink = document.querySelector('.deviceCheckLink');
    if (deviceCheckLink) {
      deviceCheckLink.removeEventListener('click', showSimArticleList);
    }
  }

  render() {
    const { dispatch, ui, fieldMap } = this.props;
    return (
      <div className={suitcss({ componentName, utilities: ['grid', 'sCollapse', 'marginTop', 'marginBottomXL', 'sMarginVS'] })}>
        <FormHeader headline={ui.mnpSimTypeHeadline} copy={ui.mnpSimTypeCopy} />
        <Field {...fieldMap.mnpSimType} component={FieldRadioGroup} utilities={['col12']} asStack />
        <div className={suitcss({ utilities: ['marginTop'] }, this)}>
          <Copy embedded raw>
            {ui.mnpSimTypeDeviceCheckerHint}
          </Copy>
          <Link
            className={suitcss(
              {
                utilities: ['colorPrimary'],
              },
              this,
            )}
            element="button"
            onClick={(event) => {
              event.preventDefault();
              dispatch(showSimArticleListDialog());
            }}
          >
            {ui.mnpSimTypeDeviceCheckerCta}
          </Link>
        </div>
      </div>
    );
  }
}

SimTypeToggle.propTypes = {
  fieldMap: PropTypes.object.isRequired,
  ui: PropTypes.shape({
    mnpSimTypeHeadline: PropTypes.string,
    mnpSimTypeCopy: PropTypes.string,
    mnpSimTypeDeviceCheckerHint: PropTypes.string,
    mnpSimTypeDeviceCheckerCta: PropTypes.string,
  }),
  dispatch: PropTypes.func,
  showSimArticleList: PropTypes.func,
  isContractSimTypeEsim: PropTypes.bool,
};

export default SimTypeToggle;
