import { connect } from 'react-redux';
import { compose } from 'redux';

import initForm from '../form/FormInitializer';
import dates from '../../helpers/dates';
import FreeSimFormOrdering from '../../components/compositions/freeSim/FreeSimFormOrdering';
import FormConfigProps from '../../model/form/FormConfigProps';

const createFieldMap = (ui, params) => ({
  salutation: {
    name: 'salutation',
    label: ui.guiFormSalutation,
    validation: {
      isRequired: true,
    },
    options: [
      {
        label: ui.guiFormSalutationFemale,
        value: '02',
      },
      {
        label: ui.guiFormSalutationMale,
        value: '01',
      },
    ],
  },
  firstName: {
    name: 'first_name',
    label: ui.guiFormNameFirst,
    validation: {
      isRequired: true,
      range: [null, 50],
    },
  },
  lastName: {
    name: 'last_name',
    label: ui.guiFormNameLast,
    validation: {
      isRequired: true,
      range: [null, 50],
    },
  },
  birthday: {
    name: 'birthday',
    label: ui.guiFormDob,
    labelDay: ui.guiFormDobDay,
    labelMonth: ui.guiFormDobMonth,
    labelYear: ui.guiFormDobYear,
    optionsDays: dates.days,
    optionsMonths: dates.months,
    optionsYears: dates.years,
    validation: {
      isRequired: true,
      pattern: 'date',
    },
  },
  addressStreet: {
    name: 'street',
    label: ui.guiFormAddressStreet,
    validation: {
      isRequired: true,
      range: [null, 50],
    },
  },
  addressNumber: {
    name: 'house_number',
    label: ui.guiFormAddressNumber,
    validation: {
      isRequired: true,
      range: [null, 9],
    },
  },
  addressZip: {
    name: 'zip',
    label: ui.guiFormAddressZip,
    validation: {
      isRequired: true,
      pattern: 'zip',
    },
  },
  addressCity: {
    name: 'city',
    label: ui.guiFormAddressCity,
    validation: {
      isRequired: true,
      range: [null, 50],
    },
  },
  addressExtra: {
    name: 'address_supplement',
    label: ui.guiFormAddressExtra,
    hint: ui.guiFormAddressExtraHint,
  },
  eMail: {
    name: 'email',
    label: ui.guiFormEmail,
    type: 'email',
    validation: {
      isRequired: true,
      pattern: 'email',
    },
  },
  eMailRepeat: {
    name: 'email_confirm',
    label: ui.guiFormEmailRepeat,
    type: 'email',
    validation: {
      isRequired: true,
      equals: ['email'],
    },
  },
  agb: {
    name: 'agb',
    label: `${params.termsAgbLabel}`,
    hint: `${params.termsAgbHint}`,
    type: 'checkbox',
    validation: {
      isRequired: true,
      valueEquals: true,
    },
  },
  tariffIdentifier: {
    name: 'tariff_identifier',
    validation: {
      isRequired: true,
    },
  },
});

const mapStateToProps = (state, { fieldMap, params }) => ({
  initialValues: {
    [fieldMap.agb.name]: false,
    [fieldMap.tariffIdentifier.name]: params.tariffIdentifier,
  },
});

// init component once, outside
const component = compose(
  connect(mapStateToProps),
  initForm(),
)(FreeSimFormOrdering);

const normalize = (stepConfig, stepFormValues) => {
  const { fieldMap } = stepConfig;
  const normValues = { ...stepFormValues };

  normValues[fieldMap.agb.name] = stepFormValues[fieldMap.agb.name] ? 1 : 0;

  return normValues;
};

export const id = 'ordering';

/**
 * @return {FormConfig}
 */
export const mapStateToFormConfig = (state, props) => ({
  id,
  component,
  normalize,
  formConfigProps: new FormConfigProps({
    labelSubmit: state.ui.guiFormSubmit,
  }),
  fieldMap: createFieldMap(props.ui, props.params),
});

export default mapStateToFormConfig;
