import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';
import Link from '../../basics/text/TextLink';
import Copy from '../../basics/text/TextCopy';

const componentName = 'MyPromoConfirm';
class MyPromoConfirm extends PureComponent {

  render() {
    const { link, isAppView } = this.props;
    const {
      label,
      hint,
      url,
      onClick,
    } = link;
    return (
      <div className={suitcss({ componentName, descendantName: 'button', utilities: ['flex', 'dirColumn', 'itemsCenter', 'marginBottomXXL'] }, this)}>
        {!isAppView &&
          <Link
            buttonFilled
            asButton
            to={url}
            onClick={onClick}
          >
            {label}
          </Link>
        }
        {hint &&
          <span className={suitcss({ descendantName: 'hint', utilities: ['marginTopS'] }, this)}>
            <Copy
              className={suitcss({ utilities: ['colorGray100'] })}
              element="span"
              size="small"
              embedded
              raw
            >
              {hint}
            </Copy>
          </span>
        }
      </div>
    );
  }
}

MyPromoConfirm.propTypes = {
  link: PropTypes.shape({
    label: PropTypes.string,
    hint: PropTypes.string,
    url: PropTypes.string,
    onClick: PropTypes.func,
  }),
  isAppView: PropTypes.bool.isRequired,
};

export default MyPromoConfirm;
