import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { push } from 'react-router-redux';
import * as confirmOrder from './formConfigSimReplOrderConfirm';
import * as activationStyle from './formConfigSimReplActivationStyle';
import * as eSimActivationStyle from './formConfigESimReplActivationStyle';
import * as activation from './formConfigSimReplActivation';
import {
  FORM_MODE_SIM_REPL_ACTIVATION,
  FORM_MODE_SIM_REPL_ORDERING,
  FORM_MODE_SIM_REPL_DISABLED,
  MYTRACK_SERVICE_SIMREP_VALUE,
  TRACK_REPLACE_SIMREP_TYPE,
  MARKET_POSTPAID,
  MARKET_PREPAID,
} from '../../../helpers/constants';
import SimReplOrderRequest from '../../../model/requests/SimReplOrderRequest';
import SimReplActivationRequest from '../../../model/requests/SimReplActivationRequest';
import GlobalSection from '../../../components/basics/global/GlobalSection';
import FormManager from '../../form/FormManager';
import { getSimReplacementMode as getMode } from '../../../helpers/misc';
import * as requests from '../../../actions/request/registry';
import { trackMyState } from '../../../actions/tracking/event';
import {
  showServiceUnavailableDialog,
} from '../../../actions/dialog/mySimReplacementActions';
import FieldRadioGroup from '../../../components/basics/form/FieldRadioGroup';
import FormHeader from '../../../components/compositions/form/FormHeader';
import suitcss from '../../../helpers/suitcss';
import { toDecimal } from '../../../helpers/money';
import TextCopy from '../../../components/basics/text/TextCopy';
import Link from '../../../components/basics/text/TextLink';
import { showSimArticleListDialog } from '../../../actions/dialog/misc';
import Image from '../../../components/basics/media/MediaImage';
import { showDialog } from '../../../actions/page/dialog';
import Unit from '../../../components/basics/text/TextUnit';

/**
 * Guides the user through the process of ordering order activating a sim card replacement.
 *
 * @see https://confluence.db-n.com/x/2YPr
 */

class MySimReplacementForm extends PureComponent {

  constructor(props, ...args) {
    super(props, ...args);
    this.state = {
      isStepConfirm: false,
      isEsim: null,
      hasMultipleSimTypes: false,
      availableSimTypes: { eSim: {}, simCard: {} },
    };
    this.handleClickOptionBullet = this.handleClickOptionBullet.bind(this);
    this.getSubmitRoute = this.getSubmitRoute.bind(this);
    this.onAfterSubmitSuccess = this.onAfterSubmitSuccess.bind(this);
    this.onBeforeSubmit = this.onBeforeSubmit.bind(this);
    this.getSubmitRoute = this.getSubmitRoute.bind(this);
    this.getSuccessRoute = this.getSuccessRoute.bind(this);
    this.onStepEnter = this.onStepEnter.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  async componentDidMount() {
    const { dispatch } = this.props;

    // first we need to figure out the current state of the sim replacement process
    const simReplacementStatus
      = await dispatch(requests.fetchSimReplacementStatus({ isBlocking: true }));
    const mode = getMode(simReplacementStatus);

    if (mode === FORM_MODE_SIM_REPL_DISABLED) {

      dispatch(showServiceUnavailableDialog());

    } else if (mode === FORM_MODE_SIM_REPL_ORDERING) {

      // get all the necessary data we need for our form
      dispatch(requests.fetchCustomerData({ isBlocking: true }));
      dispatch(requests.fetchBalance({ isBlocking: true }));

    } else if (mode === FORM_MODE_SIM_REPL_ACTIVATION) {

      // no need to fetch anything!

    }
  }

  componentDidUpdate(prevProps) {
    const { simCards } = this.props;

    if (prevProps.simCards !== simCards) {
      if (simCards.length > 1) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          hasMultipleSimTypes: true,
          isEsim: false,
        });
      } else if (simCards.length === 1) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          hasMultipleSimTypes: false,
          isEsim: simCards[0].simType === 'E_SIM',
        });
      }
      if (simCards.length > 0) {
        const simTypes = {};
        // eslint-disable-next-line no-return-assign
        simCards.map(element => (element.simType === 'E_SIM' ? simTypes.eSim = element : simTypes.simCard = element));
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          availableSimTypes: simTypes,
        });
      }
    }
  }

  onAfterSubmitSuccess({ finalizedValues }) {
    const { dispatch } = this.props;
    const { activated } = finalizedValues;

    if (activated) {
      dispatch(trackMyState(MYTRACK_SERVICE_SIMREP_VALUE.replace(
        TRACK_REPLACE_SIMREP_TYPE,
        activationStyle.SHIP_ACTIVE,
      )));
    } else {
      dispatch(trackMyState(MYTRACK_SERVICE_SIMREP_VALUE.replace(
        TRACK_REPLACE_SIMREP_TYPE,
        activationStyle.SHIP_INACTIVE,
      )));
    }
  }

  async onStepEnter(step) {
    this.setState({
      isStepConfirm: step === 'simReplConfirm',
    });
  }

  onClick(ev) {
    const { dialog } = this.props;
    ev.preventDefault();

    showDialog(dialog);
  }

  onBeforeSubmit(fieldMap, normalizedValues) {
    const { simReplacementStatus } = this.props;
    const { availableSimTypes, isEsim } = this.state;


    if (getMode(simReplacementStatus) === FORM_MODE_SIM_REPL_ACTIVATION) {
      return normalizedValues;
    }

    const selectedSimCard = isEsim
      ? availableSimTypes.eSim.articleNumber
      : availableSimTypes.simCard.articleNumber;

    return {
      ...normalizedValues,
      articleNumber: selectedSimCard,
      activated: normalizedValues.activated === activationStyle.SHIP_ACTIVE ? true : undefined,
    };
  }

  getSuccessRoute({ finalizedValues }) {
    const { sitemap, simReplacementStatus } = this.props;

    if (!this.state.isEsim) {
      if (getMode(simReplacementStatus) === FORM_MODE_SIM_REPL_ACTIVATION) {
        return sitemap.MySimReplacementActivationSuccessRoute.url;
      }

      if (finalizedValues.activated) {
        return sitemap.MySimReplacementOrderActiveSuccessRoute.url;
      }

      return sitemap.MySimReplacementOrderInactiveSuccessRoute.url;
    }

    return sitemap.MyESimReplacementActivationSuccessRoute.url;
  }

  getSubmitRoute(fieldMap, finalizedValues) {
    const { msisdn, simReplacementStatus } = this.props;

    if (getMode(simReplacementStatus) === FORM_MODE_SIM_REPL_ACTIVATION) {
      return new SimReplActivationRequest(msisdn);
    }

    return new SimReplOrderRequest(msisdn, finalizedValues);
  }

  handleClickOptionBullet() {
    this.setState({
      isEsim: !this.state.isEsim,
    });
  }

  render() {
    const {
      simReplacementStatus,
      customerData,
      location,
      dispatch,
      sitemap,
      ui,
      params,
      market,
      balance,
    } = this.props;
    const { availableSimTypes, hasMultipleSimTypes } = this.state;

    if (!simReplacementStatus) {
      return null;
    }

    const mode = getMode(simReplacementStatus);

    if (
      mode === FORM_MODE_SIM_REPL_DISABLED ||
      (mode === FORM_MODE_SIM_REPL_ORDERING && !customerData)
    ) {
      return null;
    }

    const steps = (mode === FORM_MODE_SIM_REPL_ACTIVATION)
      ? [activation]
      : this.state.isEsim
        ? [{ ...eSimActivationStyle, next: confirmOrder.id }, confirmOrder]
        : [{ ...activationStyle, next: confirmOrder.id }, confirmOrder];

    const generateLabel = (basicFee) => {
      const fee = (basicFee === 0) || (basicFee === undefined)
        ? ui.mySimrepCostFree
        : ui.mySimrepChooseSimtypeOptionCosts
          .replace('{AMOUNT}', toDecimal({ unit: basicFee }));
      return fee;
    };

    const freeBasicFee = !(
      (availableSimTypes.eSim && availableSimTypes.eSim.basicFee) &&
      (availableSimTypes.simCard && availableSimTypes.simCard.basicFee)
    );

    const dialogCopySim = (
      <div className={suitcss({ utilities: ['row'] }, this)}>
        <div className={suitcss({ utilities: ['col5', 'marginRight'] }, this)}>
          <Image {...params.simTypeImage} variations={null} />
        </div>
        <div className={suitcss({ descendantName: 'copy', utilities: ['col6'] }, this)}>
          <TextCopy utilities={['weightBold']} embedded raw>
            {ui.mySimrepSimtypeTrippleSim}
          </TextCopy>
          <TextCopy size="secondary" embedded raw>{ui.txtMySimrepSimtypeHint}</TextCopy>
        </div>
      </div>
    );

    const createDialogLabelInfo = (isSimCard) => () => {
      dispatch(showDialog({
        headline: isSimCard
          ? ui.mySimrepChooseSimtypeOptionClassic
          : ui.mySimrepChooseSimtypeOptionEsim,
        copy: isSimCard
          ? dialogCopySim
        // eslint-disable-next-line
            : (ui.txtMySimrepEsimProsCopy + '<br/>' + ui.mySimrepEsimMoreInfo),
        shouldCloseOnTransition: true,
        actions: [{ label: ui.guiWordAllright }],
      }));
    };

    const createCustomLabel = (isSimCardLabel) => (
      <div>
        <TextCopy utilities={['weightBold', 'inline', 'marginRightXS']}>
          {isSimCardLabel
              ? ui.mySimrepChooseSimtypeOptionClassic
              : ui.mySimrepChooseSimtypeOptionEsim}
        </TextCopy>
        <Link
          element="button"
          withoutArrow
          withoutStyle
          onClick={isSimCardLabel ? createDialogLabelInfo(true) : createDialogLabelInfo(false)}
          utilities={['alignMiddle']}
        >
          <img className={suitcss({ utilities: ['alignBaseline'] }, this)} src="/files/icons/info-i/info-icon.svg" alt="info-i" width="16px" height="16px" />
        </Link>
        <TextCopy>
          {isSimCardLabel
              ? (availableSimTypes.simCard && generateLabel(availableSimTypes.simCard.basicFee))
              : (availableSimTypes.eSim && generateLabel(availableSimTypes.eSim.basicFee))}
        </TextCopy>
      </div>
    );

    const input = {
      onChange: this.handleClickOptionBullet,
      value: true,
    };

    const options = [
      {
        name: 'sim',
        label: '',
        customLabelEnhancement: hasMultipleSimTypes && createCustomLabel(true),
        value: !this.state.isEsim,
      },
      {
        name: 'esim',
        label: '',
        customLabelEnhancement: hasMultipleSimTypes && createCustomLabel(false),
        value: this.state.isEsim,
      },
    ];

    const isPrepaid = market === MARKET_PREPAID;
    const isPostpaid = market === MARKET_POSTPAID;

    const freeSimCostInfo = isPostpaid && hasMultipleSimTypes && freeBasicFee;
    const paymentHint = isPostpaid
      ? ui.mySimrepPaymentHintPost
      : ui.mySimrepPaymentHintPre;
    const balanceMoney = balance && { currency: 'EUR', unit: balance.total };

    return (
      <GlobalSection>
        {!this.state.isStepConfirm && mode !== FORM_MODE_SIM_REPL_ACTIVATION &&
        <div
          className={suitcss(
            {
              utilities: ['marginBottom'],
            },
            this,
          )}
        >
          {hasMultipleSimTypes && <FormHeader utilities={['marginBottom']} headline={ui.mySimrepChooseSimtypeHeadline} />}
          {hasMultipleSimTypes && <FieldRadioGroup utilities={['marginLeftXS']} options={options} input={input} meta={{}} asStack />}
          {freeSimCostInfo && <TextCopy utilities={['marginTop']} raw>{ui.mySimrepCostFreeInfo}</TextCopy>}
          {hasMultipleSimTypes && (
            <div className={suitcss({ utilities: ['marginTop'] }, this)}>
              <TextCopy embedded raw>
                {ui.mySimrepDeviceCheckerHeadline}
              </TextCopy>
              <Link
                className={suitcss(
                  {
                    utilities: ['colorPrimary'],
                  },
                  this,
                )}
                element="button"
                onClick={() => dispatch(showSimArticleListDialog())}
              >
                {ui.mySimrepDeviceCheckerLinkname}
              </Link>
            </div>
          )}
          {!freeBasicFee &&
            <TextCopy utilities={['marginTopM', 'marginBottom']} embedded raw>
              {paymentHint}
            </TextCopy>
            }
            {isPrepaid && !freeBasicFee &&
              <div className={suitcss({ utilities: ['weightBold', 'marginTop'] }, this)}>
                <TextCopy element="span" utilities={['colorError']} raw embedded>{ui.mySimrepBalanceLabel}</TextCopy>
              &nbsp;
                <Unit price={balanceMoney} />
              </div>
            }
        </div>
        }
        <FormManager
          form={MySimReplacementForm.formName}
          submitRoute={this.getSubmitRoute}
          successRoute={this.getSuccessRoute}
          onAfterSubmitSuccess={this.onAfterSubmitSuccess}
          onCancel={() => dispatch(push(sitemap.MyServicesRoute.url))}
          onBeforeSubmit={this.onBeforeSubmit}
          steps={steps}
          location={location}
          stepProps={{
            ...this.props,
            isPrepaid,
            isEsim: this.state.isEsim,
            selectedSimOption: this.state.isEsim
              ? availableSimTypes.eSim
              : availableSimTypes.simCard,
          }}
          withBlockError
          withFooter
          onStepEnter={this.onStepEnter}
        />
      </GlobalSection>
    );
  }
}

/**
 * required by tracking!
 */

MySimReplacementForm.formName = 'simReplacement';

MySimReplacementForm.propTypes = {
  location: PropTypes.object.isRequired,
  sitemap: PropTypes.object.isRequired,
  msisdn: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  customerData: PropTypes.object,
  simReplacementStatus: PropTypes.object,
  params: PropTypes.object,
  simCards: PropTypes.array,
  dialog: PropTypes.object,
  market: PropTypes.string,
  balance: PropTypes.object,
  ui: PropTypes.shape({
    mySimrepPaymentHintPre: PropTypes.string,
    mySimrepDeviceCheckerLinkname: PropTypes.string,
    mySimrepDeviceCheckerHeadline: PropTypes.string,
    mySimrepChooseSimtypeOptionEsim: PropTypes.string,
    mySimrepCostFree: PropTypes.string,
    mySimrepChooseSimtypeOptionCosts: PropTypes.string,
    mySimrepSimtypeTrippleSim: PropTypes.string,
    txtMySimrepSimtypeHint: PropTypes.string,
    mySimrepChooseSimtypeOptionClassic: PropTypes.string,
    txtMySimrepEsimProsCopy: PropTypes.string,
    mySimrepEsimMoreInfo: PropTypes.string,
    guiWordAllright: PropTypes.string,
    mySimrepChooseSimtypeHeadline: PropTypes.string,
    mySimrepCostFreeInfo: PropTypes.string,
    mySimrepPaymentHintPost: PropTypes.string,
    mySimrepBalanceLabel: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = ({ site, user }) => ({
  sitemap: site.sitemap,
  customerData: user.customerData,
  balance: user.balance,
  simReplacementStatus: user.simReplacementStatus,
  msisdn: user.credentials.msisdn,
  phoneNumber: user.credentials.phoneNumber,
  market: user.credentials.market,
  simCards: ((user.simReplacementStatus || {}).availableSimCards || []),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default compose(connect(
  mapStateToProps,
  mapDispatchToProps,
))(MySimReplacementForm);
