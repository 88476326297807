import { REQUEST_RECEIVED_RESPONSE } from '../actions/request/basic';
import {
  REGISTER_FOOTNOTE_REFERENCE,
  UNREGISTER_FOOTNOTE_REFERENCE,
} from '../actions/legal/footnote';
import LegalsRequest from '../model/requests/LegalsRequest';

/**
 * Returns an updated list of footnote references.
 *
 * Each footnote reference is given an id (e.g. an entity's eid)
 * that allows us to keeps track of it and the total number of references
 * that are currently displayed (mounted) on the site.
 *
 * Footnote references that are not referenced anymore are deleted.
 */
const getUpdatedFootnoteRefs = (references, { payload, type }) => {
  const { id } = payload;
  const updates = [...references];

  // get the current index
  const index = references.findIndex(footnote => footnote.id === id);

  if (index === -1) {
    if (type === REGISTER_FOOTNOTE_REFERENCE) {
      // no references with this id exist yet so we freshly add it to
      // the store with a counter set to 1
      updates[references.length] = { id, counter: 1 };

      return updates;
    }

    // cannot unregister a non existant footnote; simply ignore and return old state
    return references;
  }

  // update the reference counter
  const increment = (type === REGISTER_FOOTNOTE_REFERENCE ? 1 : -1);
  const counter = references[index].counter + increment;

  if (counter === 0 && type === UNREGISTER_FOOTNOTE_REFERENCE) {
    // completely remove footnote from list because no entity references it
    updates.splice(index, 1);

    return updates;
  }

  updates[index] = { id, counter };

  return updates;
};

const defaultState = {
  complete: false,
  legals: null,
  references: [],
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case REQUEST_RECEIVED_RESPONSE: {
      const { request } = action.meta;
      const responseBody = action.payload.response.body.data;

      if (request instanceof LegalsRequest) {
        return { ...state, legals: responseBody };
      }

      return state;
    }

    case REGISTER_FOOTNOTE_REFERENCE:
    case UNREGISTER_FOOTNOTE_REFERENCE:
      return { ...state, references: getUpdatedFootnoteRefs(state.references, action) };

    default:
      return state;
  }
};
