import QueueableRequest from './QueueableRequest';
import { ARTICLE_INFORMATION_GROUPS_ENDPOINT } from '../../config/api';
import { REQUEST_METHOD_GET } from '../../helpers/constants';

export default class ArticleInformationGroupsRequest extends QueueableRequest {
  constructor() {
    super(ARTICLE_INFORMATION_GROUPS_ENDPOINT, {
      method: REQUEST_METHOD_GET,
    });
  }
}
