import PageModule from './PageModule';
import component from '../../containers/navProcess/NavProcess';

/**
 * Process header displayed when user selects a tariff or hardware.
 * @see https://confluence.db-n.com/x/hpXc
 */
class NavProcessModule extends PageModule {
  constructor(moduleData) {
    super(component, moduleData);
  }

  /**
   * The nav process header is never considered to be a primary module.
   * @override
   */
  canBePrimary() {
    return false;
  }
}

export default NavProcessModule;
