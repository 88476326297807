import QueueableRequest from './QueueableRequest';
import TariffSummaryRequest from './TariffSummaryRequest';
import TariffsRequest from './TariffsRequest';
import { ACCOUNT_CANCELLATION_ENDPOINT } from '../../config/api';
import { bindParamsToRoute } from '../../helpers/url';

export default class CancellationRequest extends QueueableRequest {
  constructor(msisdn, contractId, options) {
    const url = bindParamsToRoute(ACCOUNT_CANCELLATION_ENDPOINT, { msisdn, contractId });
    super(url, options);
  }

  /**
   * @inheritDoc
   */
  getSubsequentRequests({ user }) {
    return [
      new TariffSummaryRequest(user.credentials.msisdn),
      new TariffsRequest(user.credentials.msisdn),
    ];
  }
}
