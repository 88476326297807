import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { trackClick } from '../../actions/tracking/event';
import {
  MYTRACK_FAQ_TOPIC,
  MYTRACK_FAQ_DETAIL,
  TRACK_REPLACE_ANSWER_ID,
  TRACK_REPLACE_TOPIC_ID,
} from '../../helpers/constants';

import GlobalSection from '../../components/basics/global/GlobalSection';
import FAQComposition from '../../components/compositions/faq/FAQ';

function FAQ({
  params,
  locationHash,
  onChange,
  trackTopic,
  trackDetail,
}) {
  const allGroups = params.groups;
  const expandedGroup = allGroups.filter(group => group.expanded === true);
  return (
    <GlobalSection adjacent>
      <FAQComposition
        headline={params.headline}
        groups={params.groups}
        hash={params.hash}
        locationHash={locationHash}
        open={params.expanded}
        groupVisible={params.groupsVisible}
        headlineVisible={params.headlineVisible}
        expandedGroup={expandedGroup}
        onChange={onChange}
        trackTopic={trackTopic}
        trackDetail={trackDetail}
      />
    </GlobalSection>
  );
}
FAQ.propTypes = {
  params: PropTypes.object.isRequired,
  locationHash: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  trackTopic: PropTypes.func.isRequired,
  trackDetail: PropTypes.func.isRequired,
};

const mapStateToProps = (state, { location }) => ({
  locationHash: location ? decodeURI(location.hash).replace('#', '') : '',
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: hash =>
    dispatch(push(`${ownProps.location.pathname}${hash ? `${ownProps.location.search}#${hash}` : `${ownProps.location.search}`}`)),
  trackTopic: topicId => {
    dispatch(trackClick({
      link_position: 'content module',
      link_title: MYTRACK_FAQ_TOPIC.replace(TRACK_REPLACE_TOPIC_ID, topicId),
      link_type: 'accordion',
    }));
  },
  trackDetail: answerId => {
    dispatch(trackClick({
      link_position: 'content module',
      link_title: MYTRACK_FAQ_DETAIL.replace(TRACK_REPLACE_ANSWER_ID, answerId),
      link_type: 'accordion',
    }));
  },
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(FAQ);
