import QueueableRequest from './QueueableRequest';
import { SITEMAP_ENDPOINT } from '../../config/api';

export default class SitemapRequest extends QueueableRequest {
  constructor() {
    super(SITEMAP_ENDPOINT, {
      isCritical: true,
    });
  }
}
