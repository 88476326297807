/* global document */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import suitcss from '../../../helpers/suitcss';
import { isWebView } from '../../../helpers/site';
import { BRAND_ALLMOBIL, BRAND_OTELO, ID_MY_OFFERS_TARIFFS } from '../../../helpers/constants';
import {
  getHeaderHeight,
  NAVIGATION_MAIN_FACELIFT_TURNING_POINT,
  scrollToHash,
  scrollToTop,
} from '../../../helpers/navigation';
import { navLinkList } from '../../../propTypes/navigation';
import Link from '../../basics/text/TextLink';
import CheckoutCartNavigation from '../checkout/CheckoutCartNavigation';
import ButtonToggleIcons from '../../basics/button/ButtonToggleIcons';
import AccountNavigation from '../account/AccountNavigation';
import RenderOnClient from '../../basics/ssr/RenderOnClient';
import connectViewport from '../../basics/service/ServiceViewportConnector';
import { breakpointMediumToLarge } from '../../../containers/service/ServiceMatchMedia';

// TODO Make the function more error prove
const isNaviSearchLink = (item) => (
  item.url === '/suche' && item.title === 'Suche'
);

class NavigationMain extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      hoverItem: null,
      isOpen: props.isOpen,
      isMoved: false,
    };

    this.createMouseEnter = this.createMouseEnter.bind(this);
    this.createMouseLeave = this.createMouseLeave.bind(this);
    this.createClick = this.createClick.bind(this);
    this.createScrollToTop = this.createScrollToTop.bind(this);
    this.toggleOpen = this.toggleOpen.bind(this);
    this.lineAnimationOnMouseEnter = this.lineAnimationOnMouseEnter.bind(this);
    this.lineAnimationOnMouseLeave = this.lineAnimationOnMouseLeave.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.isOpen !== prevState.isOpen) {
      return {
        isOpen: nextProps.isOpen,
        isMoved: !nextProps.isOpen ? prevState.isMoved : false,
      };
    }
    return null;
  }

  componentDidMount() {
    if (!isWebView() && !this.props.isMediaSM) {
      this.initializeFlyingLine();
    }
  }

  shouldComponentUpdate(nextProps) {
    // Check if isFacelift is false and only scroll has changed
    if (!nextProps.isFacelift && this.props.scroll !== nextProps.scroll
      && Object.keys(nextProps).every(key => key === 'scroll' || nextProps[key] === this.props[key])) {
      return false;
    }

    // Otherwise, update the component
    return true;
  }

  componentDidUpdate(prevProps) {
    const {
     items, client, isMediaSM, display,
    } = this.props;
    const { width } = client;
    const itemsLengthChanged = items.length !== prevProps.items.length;
    const widthChangedToLarge = width >= breakpointMediumToLarge
      && prevProps.client.width <= breakpointMediumToLarge
      && prevProps.client.width !== 0;
    const displayChanged = display !== prevProps.display;
    if (!isMediaSM && (itemsLengthChanged || widthChangedToLarge || displayChanged)) {
      this.initializeFlyingLine();
    }
  }

  componentWillUnmount() {
    if (this.targets) {
      this.targets.forEach(target => target.removeEventListener('mouseenter', this.lineAnimationOnMouseEnter));
      this.targets.forEach(target => target.removeEventListener('mouseleave', this.lineAnimationOnMouseLeave));
    }
  }

  getStyle() {
    const { isMediaSM, isFacelift, client } = this.props;
    if (!process.browser || !isMediaSM) {
      return {};
    }
    const height = client.height - getHeaderHeight();
    return { style: { height: isMediaSM && isFacelift ? height + 9 : height } };
  }

  initializeFlyingLine() {
    this.targets = document.querySelectorAll('.NavigationMain-item');
    this.line = document.querySelector('.NavigationMain-line');
    if (!this.line || !this.targets) return;
    this.targets.forEach((target) => target.addEventListener('mouseenter', this.lineAnimationOnMouseEnter));
    this.targets.forEach((target) => target.addEventListener('mouseleave', this.lineAnimationOnMouseLeave));
  }

  createClick(item) {
    return () => {
      if (item.children.length > 1 && this.props.isMediaSM) {
        this.setState({
          isOpen: true,
          isMoved: true,
        });
      }
    };
  }

  createScrollToTop(item) {
    const { activeCategory, currentLocation } = this.props;
    const isActiveCategory = item.url === activeCategory || item.url === currentLocation;
    if (isActiveCategory) {
      scrollToTop();
    }
    return null;
  }

  createMouseEnter(item) {
    const { onOpen, identifier } = this.props;
    return () => {
      if (item && item.children.length && !this.props.isMediaSM) {
        this.setState({
          hoverItem: item,
        }, () => {
          onOpen(identifier);
        });
      }
    };
  }

  createMouseLeave() {
    const { onClose, identifier } = this.props;
    return () => {
      if (!this.props.isMediaSM) {
        this.setState({ hoverItem: null }, () => {
          onClose(identifier);
        });
      }
    };
  }

  toggleOpen() {
    const {
     onOpen, onClose, isMediaSM, identifier,
    } = this.props;
    if (isMediaSM) {
      const open = !this.state.isOpen;
      this.setState({
        isMoved: this.state.isMoved,
      }, () => {
        if (open) {
          onOpen(identifier, isMediaSM);
        } else {
          onClose(identifier, isMediaSM);
        }
      });
    }
  }

  lineAnimationOnMouseEnter(event) {
    this.line.style.width = `${event.target.getBoundingClientRect().width}px`;
    this.line.style.left = `${event.target.offsetLeft}px`;
    this.line.style.transform = 'none';
  }

  lineAnimationOnMouseLeave() {
    this.line.style.transform = 'scaleX(-0.001)';
  }

  renderTitle() {
    const {
     items, isBranded, brandName, brandBasePath,
    } = this.props;

    const isSimBranded = brandName && brandName !== BRAND_OTELO;
    const simBrandName = isSimBranded && brandName;
    const element = isBranded && !isSimBranded ? 'p' : null;

    let item;
    let itemPoweredBy;
    if (isBranded && isSimBranded) {
      item = {
        url: brandBasePath || null,
        icon: `/images/brands/${simBrandName}/${simBrandName}.svg`,
        key: 'navBrandedTitle',
        disabled: true,
      };
    } else {
      item = items[0];
      item.key = 'navTitle';
      itemPoweredBy = items.find((entry) => (entry.class === 'nav-main-poweredby'));
      if (itemPoweredBy) {
        itemPoweredBy.key = 'navPoweredBy';
        itemPoweredBy.modifiers = ['poweredBy'];
      }
    }

    return (
      <div
        className={suitcss({
          descendantName: 'title',
          modifiers: [isBranded && simBrandName && 'branded'],
        }, this)}
      >
        <RenderOnClient>
          {this.renderItem({ item, index: item.key, element })}
        </RenderOnClient>
        {itemPoweredBy && (
          <RenderOnClient>
            {this.renderItem({
              item: itemPoweredBy,
              index: itemPoweredBy.key,
              element,
            })}
          </RenderOnClient>
        )}
      </div>
    );
  }

  renderFlyingLine() {
    return (
      <div className={suitcss({
        descendantName: 'line',
      }, this)}
      />
    );
  }

  renderMainNavi() {
    const { isMediaSM, isUserReferFriendsEnabled } = this.props;
    let items = this.props.items.filter((item, index) => (
      index > 0
      && (item.class !== 'nav-main-right' && item.class !== 'nav-main-login' && item.class !== 'nav-main-poweredby' && item.class !== 'nav-main-search')
    ));
    if (!isUserReferFriendsEnabled) {
      items = items.filter((item) => item.class !== 'nav-main-refer-friends');
    }
    return (
      <div
        key="main"
        className={suitcss({ descendantName: 'mainnavi' }, this)}
      >
        {isMediaSM
          && (
          <div className={suitcss({ descendantName: 'inner' }, this)}>
            <div className={suitcss({ descendantName: 'items' }, this)}>
              {items.map((item, index) => this.renderMainNaviItem({ item, index }))}
            </div>
          </div>
        )}
        {!isMediaSM
          && (
          <div className={suitcss({ descendantName: 'inner' }, this)}>
            {this.renderFlyingLine()}
            {items.map((item, index) => this.renderMainNaviItem({ item, index }))}
          </div>
        )}
      </div>
    );
  }

  renderSubNavi() {
    const {
      isMediaS,
      isMediaSM,
      display,
      brandName,
      currentLocation,
      ShoppingCartRoute,
    } = this.props;
    const isMinimal = display === 'minimal';
    const isAllmobil = brandName === BRAND_ALLMOBIL;
    const isShoppingCartLocation = currentLocation === ShoppingCartRoute;
    let allmobilItems = [];
    const items = this.props.items.filter(
      (item, index) => index > 0
        && (item.class === 'nav-main-right' || item.class === 'nav-main-login' || item.class === 'nav-main-search'),
    );
    const mobileItems = this.props.items.filter(item => (item.class === 'nav-main-right' || item.class === 'nav-main-login'))
      .filter(item => (item.url === ShoppingCartRoute ? !isShoppingCartLocation : item));
    if (isAllmobil) {
      allmobilItems = this.props.items.filter(item => item.title === 'Login');
    }

    return (
      <div key="sub" className={suitcss({ descendantName: 'subnavi' }, this)}>
        {items.map((item, index) =>
          this.renderItem({ item, index, isMinimal }))}
        {!isMinimal && isMediaSM && mobileItems.map((item, index) => (
          item.url === ShoppingCartRoute
            ? (
              <CheckoutCartNavigation
                key={index}
                link={item}
                isFacelift
                isMediaSM
                isMediaS={isMediaS}
              />
            )
            : (
              <AccountNavigation
                key={index}
                link={item}
                isMediaSM
                isMediaS={isMediaS}
              />
            )
        ))}
        {isMinimal && isAllmobil && allmobilItems.map((item, index) =>
          this.renderItem({ item, index }))}
        {!isMinimal && isMediaSM && (
          <div className={suitcss({ descendantName: 'toggle' }, this)}>
            <ButtonToggleIcons
              onClick={this.toggleOpen}
              icon={this.state.isOpen ? 'cross' : 'burger'}
            />
          </div>
        )}
      </div>
    );
  }

  renderMainNaviItem({ item, index }) {
    const { isMediaSM } = this.props;
    const { hoverItem } = this.state;
    const blockCTA = item.children.length > 0 && isMediaSM;
    return (
      <div
        className={suitcss({
          descendantName: 'item',
          className: 'naviItemFadeIn',
          states: [hoverItem === item && 'active'],
        }, this)}
        onMouseEnter={this.createMouseEnter(item)}
        onMouseLeave={this.createMouseLeave()}
        onClick={this.createClick(item)}
        key={index}
      >
        {this.renderItem({ item, element: blockCTA && 'span' })}
      </div>
    );
  }

  renderItem({
   item, index, element, isActive, isMinimal,
  }) {
    const {
      isMediaSM,
      activeCategory,
      MyDashboardRoute,
      ShoppingCartRoute,
      currentLocation,
    } = this.props;
    // @todo ensure sitemap is available on load or
    // add a special class identifier per cms like we have below for our login button.
    const isShoppingCart = item.url === ShoppingCartRoute;
    const isSearchLink = isNaviSearchLink(item);
    const isLoginItem = item.class === 'nav-main-login';
    const isActiveCategory = item.url === activeCategory;
    const isVvlItem = item.url === `${MyDashboardRoute}#${ID_MY_OFFERS_TARIFFS}`;
    const isShoppingCartLocation = currentLocation === ShoppingCartRoute;

    if (isMediaSM && (isLoginItem || isShoppingCart)) {
      return null;
    }
    return (
      <div
        className={suitcss({
          descendantName: 'link',
          states: [isActive && 'active', isMinimal && 'hidden'],
          modifiers: [
            ...(!item.modifiers ? [] : item.modifiers),
            isActiveCategory && 'activeCategory',
            item.class,
          ],
        }, this)}
        key={index}
      >
        {!isShoppingCart && (!isLoginItem || isMediaSM) && (
          <Link
            to={item.url}
            icon={item.icon}
            target={item.target}
            element={element || (isSearchLink ? 'button' : null)}
            withoutArrow
            ariaLabel={item.ariaLabel}
            onClick={isVvlItem ? () => scrollToHash(`#${ID_MY_OFFERS_TARIFFS}`) : () => this.createScrollToTop(item)}
          >
            {!item.icon && item.title}
          </Link>
        )}
        {(isShoppingCart && !isShoppingCartLocation) && (
          <CheckoutCartNavigation link={item} />
        )}
        {isLoginItem && (
          <AccountNavigation link={item} />
        )}
      </div>
    );
  }

  render() {
    const {
     isMediaSM, display, isFacelift, scroll,
    } = this.props;
    const { isOpen, isMoved } = this.state;
    const isScrolled = scroll.y > NAVIGATION_MAIN_FACELIFT_TURNING_POINT;
    const modifiers = [
      isFacelift && !isScrolled && 'facelift',
    ];
    const states = [
      isOpen && 'open',
      isMoved && 'moved',
    ];

    return (
      <nav className={suitcss({ modifiers, states }, this)}>
        <div className={suitcss({ descendantName: 'content' }, this)}>
          <div className={suitcss({ descendantName: 'inner' }, this)}>
            {this.renderTitle()}
            {display !== 'minimal' && !isMediaSM && this.renderMainNavi()}
            <RenderOnClient>
              {this.renderSubNavi()}
            </RenderOnClient>
          </div>
        </div>
        <div className={suitcss({ descendantName: 'subcontent' }, this)} {...this.getStyle()}>
          {isMediaSM && this.renderMainNavi()}
        </div>
      </nav>
    );
  }
}

NavigationMain.propTypes = {
  items: navLinkList.isRequired,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  isMediaS: PropTypes.bool,
  isMediaSM: PropTypes.bool,
  isBranded: PropTypes.bool,
  isFacelift: PropTypes.bool,
  display: PropTypes.oneOf(['minimal']),
  client: PropTypes.object.isRequired,
  brandName: PropTypes.string,
  brandBasePath: PropTypes.string,
  scroll: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
  activeCategory: PropTypes.string,
  identifier: PropTypes.string,
  currentLocation: PropTypes.string,
  MyDashboardRoute: PropTypes.string,
  ShoppingCartRoute: PropTypes.string,
  isUserReferFriendsEnabled: PropTypes.bool,
};

NavigationMain.defaultProps = {
  isMediaS: false,
  isMediaSM: false,
};

export default compose(
  connectViewport({ listenTo: ['client', 'scroll'] }),
)(NavigationMain);
