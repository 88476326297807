/* global window */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { switchModule } from '../../actions/page/page';
import { fetchEntityById } from '../../actions/request/registry';
import { getModuleInstance } from '../../helpers/moduleSwapper';
import { getEntityById } from '../../selectors/misc';
import GlobalModule from '../global/GlobalModule';

class ModuleSwapper extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fallbackContent: null,
    };
  }

  async componentDidMount() {
    const { params, location, moduleId, entities } = this.props;
    window.swapRuleListener.push(async swapRules => {
      const swapRule = swapRules.find(rule => rule.id === moduleId);
      const fetchedEntity = await this.props.fetchEntityById(
        swapRule ? swapRule.newContentId : params.fallbackContentId,
        false,
        { isBlocking: false },
      );
      const moduleInstance = await getModuleInstance(fetchedEntity.etype, fetchedEntity);
      this.props.switchModule(moduleInstance, moduleId, location.pathname);
    });
    const entity = getEntityById(entities, params.fallbackContentId);
    const module = await getModuleInstance(entity.etype, entity);

    this.setState({
      fallbackContent: module,
    });
  }

  render() {
    const { fallbackContent } = this.state;
    if (!fallbackContent) return null;
    return (
      <GlobalModule
        module={fallbackContent}
        location={this.props.location}
        primaryModule={false}
      />
    );
  }
}

ModuleSwapper.propTypes = {
  switchModule: PropTypes.func.isRequired,
  fetchEntityById: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  moduleId: PropTypes.string.isRequired,
  entities: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  entities: state.entities,
});

const mapDispatchToProps = {
  switchModule,
  fetchEntityById,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModuleSwapper);
