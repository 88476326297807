import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  updateCampaignToken,
} from '../../actions/global/storage';
import { showTemplateDialog } from '../../actions/page/dialog';
import * as registryActions from '../../actions/request/registry';
import {
  registerCampaignToken,
  registerCId,
  registerGrantedPromotions,
  removeCampaignToken,
  removePromoCodes,
} from '../../actions/user/marketing';
import GlobalSection from '../../components/basics/global/GlobalSection';
import Copy from '../../components/basics/text/TextCopy';
import { QUERY_CAMPAIGN_TOKEN, QUERY_CID } from '../../helpers/constants';
import suitcss from '../../helpers/suitcss';

const componentName = 'CampaignInfo';

class CampaignInfo extends PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isCampaignTokenValid: true,
    };
    this.checkCampaignToken = this.checkCampaignToken.bind(this);
  }

  componentDidMount() {
    const { dispatch, campaignTokenQuery } = this.props;

    dispatch(registerCampaignToken([campaignTokenQuery]));
    this.checkCampaignToken();
  }

  async checkCampaignToken() {
    const { params, dispatch, campaignTokenQuery, cidQuery } = this.props;
    const { failureDialog } = params;
    const promotions = await dispatch(
      registryActions.fetchGrantedCampaignPromotions({
        isBlocking: true,
      }),
    );
    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({ isCampaignTokenValid: !!promotions.length });

    if (promotions.length && !!campaignTokenQuery) {
    // if campaignToken is valid remove promoCode as there should never exist both
      dispatch(removePromoCodes());
      dispatch(registerGrantedPromotions({ promotions: [], promoCodes: [] }));
      dispatch(updateCampaignToken({ campaignToken: [campaignTokenQuery] }));
      dispatch(registerCId(cidQuery));
    } else {
      dispatch(removeCampaignToken());
      if (failureDialog && failureDialog.id) {
        dispatch(showTemplateDialog(failureDialog.id));
      }
    }
  }

  render() {
    const { params } = this.props;
    const { failureCopy } = params;
    return (
      <GlobalSection
        className={suitcss({
          componentName,
          utilities: [this.state.isCampaignTokenValid && 'hidden'],
        })}
        layout="contained"
        adjacent
      >
        <Copy
          className={suitcss({ utilities: ['elementErrorBox', 'colorError'] }, this)}
          raw
          embedded
          element="div"
        >
            {failureCopy}
        </Copy>
      </GlobalSection>
    );
  }
}

CampaignInfo.propTypes = {
  params: PropTypes.object,
  dispatch: PropTypes.func,
  campaignTokenQuery: PropTypes.string,
  cidQuery: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

const mapStateToProps = (state, ownProps) => {
  const { location } = ownProps;
  return {
    campaignTokenQuery: location.query[QUERY_CAMPAIGN_TOKEN],
    cidQuery: location.query[QUERY_CID],
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CampaignInfo);
