/* global window, document */
import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getItem, setItem, STORAGE_TYPE_COOKIE } from '../../helpers/storage';
import { MONTH, HOUR } from '../../helpers/date';
import { STORAGE_KEY_TEASER_LAYER, NOTIFICATION_TYPE_CUSTOM, DIALOG_TYPE_CUSTOM } from '../../helpers/constants';
import { addLocationChangePrompt } from '../../actions/page/location';
import TeaserLayerDialog from '../../components/compositions/dialog/TeaserLayerDialog';
import { hideDialog, showDialog, showNotification } from '../../actions/page/dialog';

const TEASERLAYER_TYPE_DIALOG = 'dialog';
const TEASERLAYER_TYPE_NOTIFICATION = 'notification';

class TeaserLayer extends PureComponent {

  constructor(props, context) {
    super(props, context);
    this.dispatchDialog = this.dispatchDialog.bind(this);
    this.dispatchNotification = this.dispatchNotification.bind(this);
    this.moveHandler = this.moveHandler.bind(this);
    this.leaveHandler = this.leaveHandler.bind(this);
    this.enterHandler = this.enterHandler.bind(this);
  }

  componentDidMount() {
    const { params, behaviorSettings } = this.props;
    const isDispatchable = this.isDispatchable();
    const shouldDispatchOnMount = isDispatchable && !behaviorSettings;

    if (shouldDispatchOnMount) {
      this.setDelayTimeout(params.delay || 0);
    }

    if (behaviorSettings && isDispatchable) {
      this.toggleBehaviorHandlers(true);
      if (behaviorSettings.onInactivity) {
        this.setDelayTimeout(this.getInactivityDelay());
      }
    }
  }

  componentWillUnmount() {
    const { behaviorSettings } = this.props;
    this.clearDelayTimeout();
    if (behaviorSettings) {
      this.toggleBehaviorHandlers(false);
    }
  }

  getInactivityDelay() {
    const { params, behaviorSettings } = this.props;
    return Number(behaviorSettings.onInactivity) > 0
      ? behaviorSettings.onInactivity
      : (params.delay || 0);
  }

  getDialogConfig() {
    const { params, ui, dispatch, moduleId, moduleTitle } = this.props;
    const { eyecandy } = params;
    const onClose = () => dispatch(hideDialog());
    let eyecandyParsed = {};
    if (eyecandy) {
      try {
        eyecandyParsed = JSON.parse(params.eyecandy);
      } catch (e) {
        console.log('Error while parsing eyecandy JSON', params.eyecandy);
      }
    }
    return {
      type: DIALOG_TYPE_CUSTOM,
      component: TeaserLayerDialog,
      props: {
        ...params,
        delay: 0,
        eyecandy: eyecandy ? eyecandyParsed : null,
        onClose,
        ui,
      },
      withCloseAction: true,
      shouldCloseOnTransition: true,
      onClose,
      id: moduleId,
      title: moduleTitle,
    };
  }

  getNotificationConfig() {
    const { params, moduleId, moduleTitle } = this.props;
    return {
      type: NOTIFICATION_TYPE_CUSTOM,
      copy: params.content,
      shouldCloseOnTransition: true,
      moduleId,
      moduleTitle,
    };
  }

  setStorageItem() {
    const { moduleId, params } = this.props;
    const { recurrenceDelay, recurrence } = params;
    const expires = recurrenceDelay
      ? new Date(Date.now() + (recurrenceDelay * HOUR))
      : new Date(Date.now() + MONTH);
    setItem(STORAGE_TYPE_COOKIE, `${STORAGE_KEY_TEASER_LAYER}_${moduleId}`, { recurrence }, {
      expires,
      path: '/',
    });
  }

  setDelayTimeout(delay) {
    if (process.browser) {
      const { params, behaviorSettings } = this.props;
      this.clearDelayTimeout();
      this.timeout = setTimeout(() => {
        if (behaviorSettings) {
          this.toggleBehaviorHandlers(false);
        }
        if (params.type === TEASERLAYER_TYPE_DIALOG) {
          this.dispatchDialog();
        } else if (params.type === TEASERLAYER_TYPE_NOTIFICATION) {
          this.dispatchNotification();
        }
      }, delay);
    }
  }

  clearDelayTimeout() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  toggleBehaviorHandlers(bool) {
    const { behaviorSettings, dispatch } = this.props;
    const action = bool ? 'addEventListener' : 'removeEventListener';

    if (behaviorSettings.onInactivity) {
      window[action]('mousemove', this.moveHandler, false);
      document[action]('scroll', this.moveHandler, { passive: true });
    }

    if (behaviorSettings.onLeave) {
      document.documentElement[action]('mouseleave', this.leaveHandler, false);
      document.documentElement[action]('mouseenter', this.enterHandler, false);
    }

    if (behaviorSettings.onNavigate && bool) {
      this.setStorageItem();
      dispatch(addLocationChangePrompt({
        dialog: this.getDialogConfig(),
        ...behaviorSettings,
        withRedirect: true,
      }));
    }
  }

  isDispatchable() {
    const { moduleId, params } = this.props;
    const { recurrence, recurrenceDelay, percent } = params;
    const hasBeenShown = !!getItem(STORAGE_TYPE_COOKIE, `${STORAGE_KEY_TEASER_LAYER}_${moduleId}`);
    const isRandom = Math.random() <= (percent / 100);
    return isRandom && (!hasBeenShown || (recurrence && !recurrenceDelay));
  }

  dispatchNotification() {
    const { dispatch } = this.props;
    this.setStorageItem();
    dispatch(showNotification(this.getNotificationConfig()));
  }

  dispatchDialog() {
    const { dispatch } = this.props;
    this.setStorageItem();
    dispatch(showDialog(this.getDialogConfig()));
  }

  moveHandler() {
    this.clearDelayTimeout();
    this.setDelayTimeout(this.getInactivityDelay());
  }

  leaveHandler() {
    const { params } = this.props;
    this.clearDelayTimeout();
    this.setDelayTimeout(params.delay || 0);
  }

  enterHandler() {
    this.clearDelayTimeout();
  }

  render() {
    return null;
  }
}

TeaserLayer.propTypes = {
  moduleId: PropTypes.string.isRequired,
  params: PropTypes.shape({
    type: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    delay: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired,
    recurrence: PropTypes.bool.isRequired,
    eyecandy: PropTypes.string,
  }),
  behaviorSettings: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  ui: PropTypes.object.isRequired,
  moduleTitle: PropTypes.string.isRequired,
};


const mapStateToProps = ({ ui }) => ({ ui });

export default connect(mapStateToProps)(TeaserLayer);
