import React from 'react';
import PropTypes from 'prop-types';

const ServiceRiskIdent = ({ token, location }) => (
  <iframe
    src={`/fds9erw0fcs6r.html#${token};${process.Environment.getVariableAsString('DBN_RISKINDENT_SNIPPEDID')};${location}`}
    frameBorder="0"
    height="0"
    width="0"
  />
);

ServiceRiskIdent.propTypes = {
  token: PropTypes.string.isRequired,
  location: PropTypes.string,
};

export default ServiceRiskIdent;
