/* global window */
import React from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';
import Link from '../text/TextLink';

const componentName = 'ButtonCustomerService';

// Use data={{ tracking: 'off' }} to deactivate Tracking

const ButtonCustomerService = (props) => (
  <div>
    {/** When being rendered inside an iFrame, window.frameElement returns the iFrame.
     Otherwise, it returns null. This ensures the customer service is not rendered if the site
     is displayed in an iframe */}

    {window.frameElement === null &&
      <Link
        element="button"
        className={suitcss({ componentName, modifiers: [props.modifiers] })}
        icon="/files/icons/icon-fab-kontakt.svg"
        onClick={props.onClick}
        withoutArrow
        asButton
        buttonFilled
      >
        {props.children}
      </Link>
    }
  </div>
);

ButtonCustomerService.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func.isRequired,
};

export default ButtonCustomerService;
