import React from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';
import Link from '../../basics/text/TextLink';
import SvgLoader from '../../basics/media/MediaSvgLoader';
import { capitalize } from '../../../helpers/str';

function HardwareTariffsTabs(props) {
  const { activeTab, label, identifier, colorScheme, onClick, showCheck } = props;
  const isActive = activeTab === label;
  return (
    <Link
      className={suitcss({
        componentName: 'HardwareTariffsTabs',
        modifiers: [
          isActive && 'active',
          colorScheme && `theme${capitalize(colorScheme)}`,
          showCheck && 'withCheck',
        ],
        descendantName: 'navigation',
      })}
      element="a"
      onClick={() => onClick(identifier)}
      key={identifier}
      withoutStyle
    >
      {showCheck && <SvgLoader path={`/icons/icon-${isActive ? 'active' : 'inactive'}.svg`} inverted={colorScheme} />}
      {label}
    </Link>);
}

HardwareTariffsTabs.propTypes = {
  activeTab: PropTypes.string,
  identifier: PropTypes.number,
  label: PropTypes.string,
  onClick: PropTypes.func,
  colorScheme: PropTypes.oneOf(['dark', 'none', 'light']),
  showCheck: PropTypes.object,
};

export default HardwareTariffsTabs;
