import React from 'react';
import PropTypes from 'prop-types';
import TeaserFreeformComposition from '../../components/compositions/teaser/TeaserFreeForm';

function TeaserFreeform(props) {
  return (
    <TeaserFreeformComposition {...props}>
      {props.children}
    </TeaserFreeformComposition>
  );
}

TeaserFreeform.propTypes = {
  columns: PropTypes.number,
  image: PropTypes.object,
  mobileImage: PropTypes.object,
  contentLeft: PropTypes.bool,
  mobileContentTop: PropTypes.bool,
  children: PropTypes.node,
  isMediaS: PropTypes.bool,
  theme: PropTypes.string,
  className: PropTypes.string,
};

export default TeaserFreeform;
