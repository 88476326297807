import React, { Component } from 'react';
import PropTypes from 'prop-types';

import suitcss from '../../../helpers/suitcss';
import PageContext from '../context/PageContext';
import IntersectionObserver from '../service/IntersectionObserver';

class LazyShowElement extends Component {

  static contextType = PageContext;

  constructor(props, context) {
    super(props, context);
    const classState = props.isParent ? 'triggerParent' : 'trigger';
    const isDisabled = !context.isLazyShowPage || props.isDisabled;
    this.state = { classStates: [!isDisabled && classState], isDisabled };
    this.onChange = this.onChange.bind(this);
  }

  onChange(ev, unobserve) {
    const { isParent } = this.props;
    const { classStates } = this.state;
    const { isIntersecting } = ev;
    if (isIntersecting) {
      const classState = isParent ? 'enterParent' : 'enter';
      classStates.push(classState);
      unobserve();
      this.setState({
        classStates,
      });
    }
  }

  renderElement(elementProps = {}) {
    const { className, children, element: Element } = this.props;
    const { classStates } = this.state;
    return (
      <Element
        className={suitcss({ utilities: classStates, className })}
        {...elementProps}
      >
        {children}
      </Element>
    );
  }

  render() {
    const {
      // LazyShow Props
      root,
      rootMargin,
      threshold,
      element: Element,
      className,
      children,
      isDisabled,
      isParent,
      // Element Props
      ...rest
    } = this.props;

    const { isDisabled: withoutObserver } = this.state;

    if (withoutObserver) {
      return this.renderElement(rest);
    }

    return (
      <IntersectionObserver
        root={root}
        rootMargin={rootMargin}
        threshold={threshold}
        onChange={this.onChange}
        disabled={isDisabled}
      >
        {this.renderElement(rest)}
      </IntersectionObserver>
    );
  }
}

LazyShowElement.propTypes = {
  className: PropTypes.string,
  element: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.array,
  ]),
  root: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]),
  rootMargin: PropTypes.string,
  threshold: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(
      PropTypes.number,
    ),
  ]),
  isDisabled: PropTypes.bool,
  isParent: PropTypes.bool,
};

LazyShowElement.defaultProps = {
  className: '',
  element: 'div',
  rootMargin: '0% 0% -10%',
};

export default LazyShowElement;
