import QueueableRequest from './QueueableRequest';
import { EECC_TARIFFOPTIONS_DELETE_INITIATE } from '../../config/api';
import { REQUEST_METHOD_PUT } from '../../helpers/constants';
import { bindParamsToRoute } from '../../helpers/url';

/**
 *
 *
 */

export default class EECCTariffOptionsDeleteInitiateRequest extends QueueableRequest {
  constructor(msisdn, payload) {
    super(bindParamsToRoute(EECC_TARIFFOPTIONS_DELETE_INITIATE, { msisdn }), {
      method: REQUEST_METHOD_PUT,
      payload,
    });
  }
}
