import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';
import UIContentSpinner from '../../basics/ui/UIContentSpinner';

const componentName = 'ContentLoader';
class ContentLoader extends PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      alreadyLoaded: props.isLoaded,
    };
  }

  componentWillUpdate(nextProps) {
    if (!nextProps.isLoaded && this.props.isLoaded) {
      this.setState({ alreadyLoaded: false });
    }
  }

  render() {
    const { className, isLoaded, withoutProgressbar, height, children } = this.props;
    const { alreadyLoaded } = this.state;
    const states = [
      !isLoaded && !alreadyLoaded && 'loading',
      isLoaded && !alreadyLoaded && 'loaded',
    ];

    return (
      <div
        className={suitcss({
          componentName,
          className,
          states,
        })}
        style={!isLoaded ? { minHeight: `${height || '34'}px` } : null}
      >
        {!isLoaded && !withoutProgressbar && (
          <div className={suitcss({ componentName, descendantName: 'spinner' })} >
            <UIContentSpinner />
          </div>
        )}
        {isLoaded && children}
      </div>
    );
  }
}

ContentLoader.propTypes = {
  className: PropTypes.string,
  isLoaded: PropTypes.bool,
  height: PropTypes.number,
  withoutProgressbar: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.func,
  ]),
};

export default ContentLoader;
