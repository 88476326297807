import React from 'react';
import PropTypes from 'prop-types';
import connectUI from '../../../basics/ui/UIConnector';
import suitcss from '../../../../helpers/suitcss';
import {
  MYTRACK_DASHBOARD_SWIPE_TARIFF,
  MYTRACK_DASHBOARD_SWIPE_STATUS,
} from '../../../../helpers/constants';
import FormOptionTab from '../../../basics/form/FormOptionTab';

const componentName = 'DashboardNavigation';

function DashboardNavigation(props) {
  const { activeIndex, slideTo, ui } = props;

  if (!slideTo) {
    return null;
  }

  return (
    <div className={suitcss({ componentName })}>
      <FormOptionTab
        onChange={() => slideTo(0)}
        checked={activeIndex === 0}
        attributes={{ 'data-mytracking': MYTRACK_DASHBOARD_SWIPE_STATUS }}
        shape="round"
        label={ui.myDashboardStatus}
        id={ui.myDashboardStatus}
      />
      <FormOptionTab
        onChange={() => slideTo(1)}
        checked={activeIndex === 1}
        attributes={{ 'data-mytracking': MYTRACK_DASHBOARD_SWIPE_TARIFF }}
        shape="round"
        label={ui.myDashboardTariff}
        id={ui.myDashboardTariff}
      />
    </div>
  );
}

DashboardNavigation.propTypes = {
  slideTo: PropTypes.func,
  activeIndex: PropTypes.number,
  ui: PropTypes.shape({
    myDashboardTariff: PropTypes.string.isRequired,
    myDashboardStatus: PropTypes.string.isRequired,
  }),
};

DashboardNavigation.defaultProps = {
  activeIndex: 0,
};

export default connectUI()(DashboardNavigation);
