import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import connectUI from '../../../basics/ui/UIConnector';
import suitcss from '../../../../helpers/suitcss';
import { toFullCurrency, toPriceObject } from '../../../../helpers/money';
import { isTariffVO, isTariffOptionVO, isPromotionVO, getPaymentFeeDuration, selectProductSheet } from '../../../../helpers/entity';
import { isTariffChangeable } from '../../../../helpers/user';
import {
  MYTRACK_MYOFFER_PROMOTION_OVERVIEW,
  TRACK_REPLACE_PROMOTION_ID,
  CONTEXT_CONTRACT_RENEWAL,
  CONTEXT_TARIFF_CHANGE,
  CONTEXT_TARIFF_OPTION,
  VO_TYPE_TARIFF,
  VO_STATE_BOOKEDSINGLE,
} from '../../../../helpers/constants';
import Callout from '../../../../containers/callout/Callout';
import Headline from '../../../basics/text/TextHeadline';
import Copy from '../../../basics/text/TextCopy';
import Link from '../../../basics/text/TextLink';
import Unit from '../../../basics/text/TextUnit';

class OffersPanel extends PureComponent {

  getContext() {
    const { item, context } = this.props;
    const isTypeTariffOption = isTariffOptionVO(item);
    if (context) {
      return context;
    } else if (isTypeTariffOption) {
      return CONTEXT_TARIFF_OPTION;
    } else if (item.renewable || item.renewContract) {
      return CONTEXT_CONTRACT_RENEWAL;
    }
    return CONTEXT_TARIFF_CHANGE;
  }

  computeMatchingCondition() {
    const { context, item, withHardware } = this.props;
    const { availableConditions } = item;
    const isContractRenewalContext = context === 'renewContract' || context === 'renewContractSimPlus';

    if (!availableConditions) {
      return null;
    }

    return availableConditions
      .find(condition =>
        condition.includesHardware === withHardware &&
        condition.requiresContractRenewal === isContractRenewalContext,
      );
  }

  renderBasicFee() {
    const { item, ui, inverted } = this.props;
    const { basicFee, basicFeeStrike } = item;
    const price = basicFee !== undefined && toPriceObject(basicFee);
    const strikePrice = basicFeeStrike && toPriceObject(basicFeeStrike);
    const postfix = getPaymentFeeDuration(item, ui, true);
    const availableCondition = this.computeMatchingCondition();
    const fee = { unit: availableCondition && availableCondition.transferFee, currency: 'EUR' };

    return (
      <div className={suitcss({ descendantName: 'basicFee' }, this)} >
        {price && (
          <Unit
            postfix={postfix}
            price={price}
            prefixPrice={strikePrice}
            size="s"
            inverted={inverted}
          />
        )}
        {availableCondition && (fee.unit > 0) && (
          <Copy className={suitcss({ descendantName: 'transferFee' }, this)} size="small" raw embedded condensed >
            {ui.tariffsEarlyFeeCondition.replace('{PRICE}', toFullCurrency(fee))}
          </Copy>
        )}
      </div>
    );
  }

  renderButton({ label, to, onClick, buttonFilled, trackingData }) {
    return (
      <div className={suitcss({ descendantName: 'button' }, this)} >
        <Link
          to={to}
          onClick={onClick}
          buttonFilled={buttonFilled}
          element={onClick ? 'button' : null}
          asButton={!!onClick || buttonFilled}
          data={trackingData}
        >
          {label}
        </Link>
      </div>
    );
  }

  renderHeader() {
    const { item, equalizerGroupM, equalizerGroupL, inverted } = this.props;
    const { headline, subline } = item;
    const itemContext = this.getContext();
    return (
      <div
        className={suitcss({ descendantName: 'header' }, this)}
        data-eq-group-m={`${equalizerGroupM}-offersPanelHeader`}
        data-eq-group-l={`${equalizerGroupL}-offersPanelHeader`}
      >
        <div className={suitcss({ descendantName: 'title' }, this)} >
          <Headline size="m" utilities={['colorInverted', 'weightBold']} embedded raw >
            {headline}
          </Headline>
          {subline && (
            <Headline size="m" utilities={['weightBold']} embedded raw >
              {subline}
            </Headline>
          )}
          <Callout
            utilities={['marginTopXS']}
            theme="content"
            context={itemContext}
            targets={[item.eid]}
            inverted={!inverted}
          />
        </div>
        {this.renderBasicFee()}
      </div>
    );
  }

  renderContent() {
    const { item, inverted } = this.props;
    const { shortCopy } = item;
    const itemContext = this.getContext();
    let productInformationSheetLink;

    if (item.type === VO_TYPE_TARIFF) {
      productInformationSheetLink = selectProductSheet(item, 'offerList');
    }

    return (
      <div className={suitcss({ descendantName: 'content' }, this)} >
        <div className={suitcss({ descendantName: 'stickers' }, this)} >
          <Callout theme="sticker" targets={[item.eid]} context={itemContext} allowMultiple />
        </div>
        {shortCopy &&
          <Copy embedded raw >{shortCopy}</Copy>
        }
        {item.type === VO_TYPE_TARIFF && (
          <Fragment>
          <Callout
            theme="badge"
            context={itemContext}
            targets={[item.eid]}
            inverted={inverted}
          />
          <Link
            className={suitcss({ descendantName: 'productInformation' }, this)}
            href={productInformationSheetLink.url}
            withoutArrow
            element="a"
            target={'_blank'}
          >
            {productInformationSheetLink.name}
          </Link>
          </Fragment>
        )}
      </div>
    );
  }

  renderSubContent() {
    const {
      item,
      onSelect,
      onChange,
      ui,
      isTariffChange,
      withHardware,
    } = this.props;
    const {
      id,
      state,
      bookable,
      contractRenewalWithSimOnly,
      contractRenewalWithHardwareOnly,
      urlDetails,
    } = item;
    const isTypeTariff = isTariffVO(item);
    const isTypeTariffOption = isTariffOptionVO(item);
    const isTypePromotion = isPromotionVO(item);
    const isBookedSingle = state === VO_STATE_BOOKEDSINGLE;
    const bookableOptionLabel = isBookedSingle ? ui.myTariffoptionBookAgain : ui.guiWordSelect;
    const isContractRenewal = contractRenewalWithHardwareOnly || contractRenewalWithSimOnly;
    const mytracking = MYTRACK_MYOFFER_PROMOTION_OVERVIEW.replace(TRACK_REPLACE_PROMOTION_ID, id);
    return (
      <div className={suitcss({ descendantName: 'subcontent' }, this)} >
        {isTypeTariffOption && bookable && (
          this.renderButton({
            label: ui.guiWordSelect || bookableOptionLabel,
            onClick: () => onSelect(item),
            buttonFilled: true,
          })
        )}
        {isTypeTariff && (!isContractRenewal || isTariffChange) && isTariffChangeable(item) && (
          this.renderButton({
            label: ui.myTariffChange,
            onClick: () => onChange(item),
            buttonFilled: true,
          })
        )}
        {isTypeTariff && isContractRenewal && !isTariffChange && (
          this.renderButton({
            label: withHardware ? ui.myTariffExtendInclusive : ui.myTariffExtendExclusive,
            onClick: () => onSelect(item, withHardware),
            buttonFilled: true,
          })
        )}
        {this.renderButton({
          label: ui.guiWordDetails,
          to: urlDetails,
          buttonFilled: isTypePromotion,
          trackingData: { mytracking },
        })}
      </div>
    );
  }

  render() {
    const { item, equalizerGroupM, equalizerGroupL } = this.props;
    return (
      <section className={suitcss({}, this)} data-tracking="offer" data-tariff-id={item.id} >
        {this.renderHeader()}
        <div
          className={suitcss({ descendantName: 'inner' }, this)}
          data-eq-group-m={`${equalizerGroupM}-offersPanelInner`}
          data-eq-group-l={`${equalizerGroupL}-offersPanelInner`}
        >
          {this.renderContent()}
          {this.renderSubContent()}
        </div>
      </section>
    );
  }
}

OffersPanel.propTypes = {
  item: PropTypes.object.isRequired,
  equalizerGroupM: PropTypes.string,
  equalizerGroupL: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  ui: PropTypes.shape({
    guiWordDetails: PropTypes.string.isRequired,
    singlePaymentFeePrefix: PropTypes.string,
    guiWordMonthlyAbbr: PropTypes.string.isRequired,
    tariffsEarlyFeeCondition: PropTypes.string.isRequired,
  }),
  inverted: PropTypes.bool,
  isTariffChange: PropTypes.bool,
  withHardware: PropTypes.bool,
  context: PropTypes.string,
};

OffersPanel.defaultProps = {
  inverted: false,
};

export default compose(connectUI())(OffersPanel);
