import QueueableRequest from './QueueableRequest';
import { PAGE_WEBSITE_ENDPOINT } from '../../config/api';
import { bindParamsToRoute, getUrlPath } from '../../helpers/url';
import {
  getEntitiesFromPage,
  getLocationModules,
  moduleConfigsToComponents,
} from '../../helpers/pageParser';
import { registerPromoCodes } from '../../actions/user/marketing';
import { receiveEntityList } from '../../actions/page/entity';

export default class AbstractPageRequest extends QueueableRequest {
  /**
   *
   * @param {string} pathname - the pathname under which the page resource should be stored.
   * @param {string} resource - the parametrized endpoint route that will yield the page resource
   */
  constructor(pathname, resource) {
    super(resource);
    this.pathname = pathname;
  }

  /**
   * Promo codes contained in the page setting are registered,
   * and page modules are instantiated.
   *
   * @inheritDoc
   */
  async handleResponse(response, dispatch, getState) {
    const { body } = response;
    const promoCodes = (body.data.settings || [])
      .filter(setting => setting.type === 'Promotion')
      .map(({ options }) => options.promoCode);
    if (promoCodes.length) {
      dispatch(registerPromoCodes(promoCodes));
    }

    dispatch(receiveEntityList(getEntitiesFromPage(body.data)));

    const { site } = getState();
    const location = { pathname: this.pathname };
    const configs = [
      ...getLocationModules(location, site.sitemap),
      ...body.data.modules,
    ];
    const modules = await moduleConfigsToComponents(configs, location, dispatch);
    // override response content
    response.body.data = {
      ...body.data,
      modules,
    };

    return response;
  }

  /**
   * Returns just the pathname part of an api route.
   *
   * @example
   * AbstractPageRequest.getPathname(api/v2/page/foo/bar`); // returns '/foo/bar'
   *
   * @param {string} url
   */
  static getPathname(url) {
    const routeWithoutPlaceholder = bindParamsToRoute(PAGE_WEBSITE_ENDPOINT, { fragment: '' });
    return getUrlPath(url.replace(routeWithoutPlaceholder), '/');
  }

  /**
   * We always suppress alerts for failed page requests. A dedicated error page is shown instead.
   * {@override}
   */
  isPreventDefaultErrorHandling() {
    return true;
  }
}
