import camelCase from 'camelcase';

import TriggerEventRequest from '../model/requests/TriggerEventRequest';
import { send } from '../actions/request/send';
import { getShortenedUuid } from '../model/requests/QueueableRequest';

export const CANCELLATION_START = 'cancellation-start';
export const CANCELLATION_COMPLETE = 'cancellation-complete';
export const RENEWAL_START = 'renewal-start';
export const RENEWAL_COMPLETE = 'renewal-complete';
export const PERMISSION_START = 'permissions-start';
export const PERMISSION_REMOVAL = 'permissions-removal';
export const CUSTOMER_DATA_CHANGE = 'customer-data-change';
export const TARIFF_CHANGE_COMPLETE = 'tariff-change-complete';

//------------------------------------------------------------------------------------
//  helper Functions
//------------------------------------------------------------------------------------

const getFeId = () => getShortenedUuid('fe_');

const isCancellationSuccessRoute = (location) =>
  (location.pathname.includes('kuendigung') &&
    (location.pathname.includes('post') ||
      location.pathname.includes('pre')));

const getDismissalDate = (state) => {
  const { user } = state;
  const { contractData } = user;
  return contractData && contractData.possibleCancellationDate;
};

const getPossibleDismissalDate = (state) => {
  const { user } = state;
  const { contractData } = user;
  return contractData.possibleCancellationDates[0];
};

const getChangedBewPermissions = (data) => {
  let oldBewTerm = {};
  let oldDataTerm = {};
  const oldActiveChannels = [];
  let removedBEW = [];

  data.oldData.forEach(term => {
    if (term.id === 'bew_checkout_terms') {
      oldBewTerm = term;
    } else if (term.id === 'data_handling_terms') {
      oldDataTerm = term;
    }
  });

  oldBewTerm.channels.forEach(channel => {
    if (channel.active) {
      oldActiveChannels.push(channel.adChannelType);
    }
  });

  const bewDifference = oldActiveChannels.filter((x) => {
    if (data.newData.bew_checkout_terms.indexOf(x) === -1) {
      return true;
    }
    return false;
  });

  removedBEW = [...new Set(bewDifference)];

  if (oldDataTerm.active && (oldDataTerm.active !== data.newData.data_handling_terms)) {
    removedBEW.push('DAT');
  }

  return removedBEW.toString();
};

const getCustomerDataChanges = (data) => {
  const changedFields = [];
  const newCustomerData = Object.entries(data.newData);

  newCustomerData.forEach(entry => {
    if (entry[1] !== data.oldData[camelCase(entry[0])]) {
      if (camelCase(entry[0]) === 'addressSupplement') {
        if (!((entry[1] === '') && (data.oldData[camelCase(entry[0])] === undefined))) {
          changedFields.push(entry[0]);
        }
      } else {
        changedFields.push(entry[0]);
      }
    }
  });

  return changedFields.toString();
};

const getBaseTriggerEvent = () => ({
  specversion: '1.0',
  source: 'www.otelo.de',
  subject: 'user-interaction',
  datacontenttype: 'application/json',
});

//------------------------------------------------------------------------------------
//  prepare trigger event
//------------------------------------------------------------------------------------

const prepareCancellationStart = (state) => ({
  ...getBaseTriggerEvent(),
  type: 'de.otelo.self-care.cancellation.start.v1',
  id: getFeId(),
  data: {
    next_possible_dismissal_date: getPossibleDismissalDate(state),
  },
});


const prepareCancellationComplete = (state) => ({
  ...getBaseTriggerEvent(),
  type: 'de.otelo.self-care.cancellation.complete.v1',
  id: getFeId(),
  data: {
    dismissal_date: getDismissalDate(state),
  },
});


const prepareRenewalStart = () => ({
  ...getBaseTriggerEvent(),
  type: 'de.otelo.self-care.renewal.start.v1',
  id: getFeId(),
});


const prepareRenewalComplete = () => ({
  ...getBaseTriggerEvent(),
  type: 'de.otelo.self-care.renewal.complete.v1',
  id: getFeId(),
});


const preparePermissionsStart = () => ({
  ...getBaseTriggerEvent(),
  type: 'de.otelo.self-care.permissions.start.v1',
  id: getFeId(),
});


const preparePermissionsRemoval = (state, data) => ({
  ...getBaseTriggerEvent(),
  type: 'de.otelo.self-care.permissions.removal.v1',
  id: getFeId(),
  data: {
    removed_permissions: getChangedBewPermissions(data),
  },
});


const prepareCustomerDataChange = (data) => ({
  ...getBaseTriggerEvent(),
  type: 'de.otelo.self-care.customer-data.change.v1',
  id: getFeId(),
  data: {
    changed_fields: getCustomerDataChanges(data),
  },
});


const prepareTariffChangeComplete = (data) => ({
  ...getBaseTriggerEvent(),
  type: 'de.otelo.self-care.tariff-change.complete.v1',
  id: getFeId(),
  data: {
    tariff_identifier: data,
  },
});


// ALLE EVENTS BRAUCHEN EIN source Feld


//------------------------------------------------------------------------------------
//  Business Logic
//------------------------------------------------------------------------------------

/*
 * fires a specific Trigger Events as discribed in the event parameter.
 */
const fireTriggerEvent = (event, store, dispatch, data = []) => {
  let siteState;

  if (!process.browser) {
    return null;
  }

  if ((typeof dispatch) === 'function') {
    siteState = store;
  } else {
    siteState = store.getState();
  }

  let triggerEvent = {};
  switch (event) {
    case CANCELLATION_START: {
      triggerEvent = prepareCancellationStart(siteState);
      break;
    }
    case CANCELLATION_COMPLETE: {
      triggerEvent = prepareCancellationComplete(siteState);
      break;
    }
    case RENEWAL_START: {
      triggerEvent = prepareRenewalStart();
      break;
    }
    case RENEWAL_COMPLETE: {
      triggerEvent = prepareRenewalComplete();
      break;
    }
    case PERMISSION_START: {
      triggerEvent = preparePermissionsStart();
      break;
    }
    case PERMISSION_REMOVAL: {
      triggerEvent = preparePermissionsRemoval(siteState, data);
      if (!triggerEvent.data.removed_permissions.length) {
        return null;
      }
      break;
    }
    case CUSTOMER_DATA_CHANGE: {
      triggerEvent = prepareCustomerDataChange(data);
      if (!triggerEvent.data.changed_fields.length) {
        return null;
      }
      break;
    }
    case TARIFF_CHANGE_COMPLETE: {
      triggerEvent = prepareTariffChangeComplete(data);
      break;
    }
    default: {
      return null;
    }
  }

  if ((typeof dispatch) === 'function') {
    dispatch(send(new TriggerEventRequest(triggerEvent)));
  } else {
    store.dispatch(send(new TriggerEventRequest(triggerEvent)));
  }
  return null;
};

/*
 * Fires trigger events on Pageload
 */
export const computeTriggerEvent = (currentLocation, store) => {
  const { site } = store.getState();
  const { sitemap } = site;

  if ((sitemap.MyCancellationFormRoute.url === currentLocation.pathname) &&
    (currentLocation.search === '?step=holdOn')) {
    fireTriggerEvent(CANCELLATION_START, store);
  } else if (
    sitemap.MyCancellationSuccessRoute.url === currentLocation.pathname ||
    isCancellationSuccessRoute(currentLocation)
  ) {
    fireTriggerEvent(CANCELLATION_COMPLETE, store);
  } else if ((currentLocation.pathname.includes('/handytarife/smartphone-tarife/')) &&
    (currentLocation.search === '' || (currentLocation.search && !currentLocation.search.includes('?selectedHardware'))) &&
    (site.contractRenewal && site.contractRenewal.isInProgress)) {
    fireTriggerEvent(RENEWAL_START, store);
  } else if ((sitemap.MyContractRenewalSuccessRoute.url === currentLocation.pathname ||
    sitemap.MyContractRenewalSuccessRoutePre.url === currentLocation.pathname) &&
    (currentLocation.action === 'PUSH')) {
    fireTriggerEvent(RENEWAL_COMPLETE, store);
  }
};

//------------------------------------------------------------------------------------
//  Fires trigger event by user interaction
//------------------------------------------------------------------------------------

export const firePermissionRemovalStartTriggerEvent = (store, dispatch) => {
  fireTriggerEvent(PERMISSION_START, store, dispatch);
};

export const firePermissionRemovalTriggerEvent = (store, dispatch, oldData, newData) => {
  fireTriggerEvent(PERMISSION_REMOVAL, store, dispatch, { oldData, newData });
};

export const fireCustomerDataChangeTriggerEvent = (store, dispatch, oldData, newData) => {
  fireTriggerEvent(CUSTOMER_DATA_CHANGE, store, dispatch, { oldData, newData });
};

export const fireTariffChangeCompleteTriggerEvent = (store, dispatch, data) => {
  fireTriggerEvent(TARIFF_CHANGE_COMPLETE, store, dispatch, data);
};

