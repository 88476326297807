import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import suitcss from '../../helpers/suitcss';
import UIProvider from '../../components/basics/ui/UIProvider';
import GlobalSection from '../../components/basics/global/GlobalSection';
import GiveMeYourPriceComposition from '../../components/compositions/modules/GiveMeYourPrice';
import TariffDetailsComposition from '../../components/compositions/tariff/TariffDetails';
import HardwareItemFull from '../../components/compositions/hardware/HardwareItemFull';
import MediaImageGallery from '../../components/basics/media/MediaImageGallery';
import {
  isTariffEntity,
  isHardwareEntity,
  isHardwareGroup,
  isTariffDetails,
  isHardwareDetails,
} from '../../helpers/entity';
import BasarChatBot from '../../components/compositions/chat/basar/BasarChatBot';
import AbstractChatUser from '../../components/compositions/chat/AbstractChatUser';
import { fetchEntityById } from '../../actions/request/registry';
import * as dialogActions from '../../actions/page/dialog';
import { DIALOG_TYPE_CUSTOM, ID_TARIFF_DETAILS, ID_HARDWARE_DETAILS } from '../../helpers/constants';


const componentName = 'GiveMeYourPriceContainer';

class GiveMeYourPrice extends PureComponent {

  constructor(props, context) {
    super(props, context);
    this.state = {
      tariff: null,
      image: null,
    };
    this.toggleGallery = this.toggleGallery.bind(this);
    this.setTariff = this.setTariff.bind(this);
    this.bot = new BasarChatBot(props.botSettings, props.dispatch, this.setTariff);
    this.user = new AbstractChatUser(null, props.userSettings.icon);
  }

  async setTariff(tariff) {
    if (tariff) {
      const { dispatch } = this.props;
      const subjects = await Promise.all(
        [
          tariff.entity,
          tariff.details,
          tariff.hardware ? tariff.hardware.entity : undefined,
          tariff.hardware ? tariff.hardware.details : undefined,
        ]
          .filter(Boolean)
          .map((eid) => dispatch(fetchEntityById(eid))),
      );
      const resolvedTariffs = GiveMeYourPrice.getSelectedProducts(subjects);
      this.bot.setSubject({
        tariff: resolvedTariffs.tariffEntity,
        hardware: resolvedTariffs.hardwareEntity,
      });
      this.setState({
        tariff: resolvedTariffs,
        image: tariff.image,
      });
    } else {
      this.setState({
        tariff: undefined,
      });
    }
  }

  toggleGallery() {
    const { showDialog } = this.props;
    const { tariff } = this.state;
    showDialog({
      type: DIALOG_TYPE_CUSTOM,
      component: MediaImageGallery,
      props: {
        images: tariff.hardwareDetails.gallery,
        withContainer: true,
      },
    });
  }

  render() {
    const {
      headline,
      subline,
      copy,
      accessoryDetails,
      ui,
      dispatch,
      animationDataUrl,
      dataVolumeChoose,
    } = this.props;
    const { tariff, image } = this.state;
    return (
      <UIProvider ui={ui}>
        <div
          className={suitcss({
            componentName,
            modifiers: [tariff && tariff.hardwareEntity && 'asBundle'],
          })}
        >
          <GlobalSection
            className={suitcss({
              componentName,
              descendantName: 'chat',
            })}
            theme="dark"
            layout="contained"
          >
            <GiveMeYourPriceComposition
              animationDataUrl={animationDataUrl}
              dataVolumeChoose={dataVolumeChoose}
              headline={headline}
              subline={subline}
              copy={copy}
              image={image}
              tariff={tariff && tariff.tariffEntity}
              hardware={tariff && tariff.hardwareEntity}
              accessory={accessoryDetails}
              bot={this.bot}
              user={this.user}
              dispatch={dispatch}
              ui={ui}
            />
          </GlobalSection>
          {tariff && tariff.tariffDetails && (
            <div className={suitcss({ componentName, descendantName: 'divider' })}>
              <hr className="u-borderDashedDarkTop" />
            </div>
          )}
          {tariff && tariff.tariffDetails && (
            <GlobalSection
              id={ID_TARIFF_DETAILS}
              className={suitcss({
                componentName,
                descendantName: 'tariffDetails',
              })}
              theme="dark"
              layout="contained"
            >
              <TariffDetailsComposition
                tariff={tariff.tariffEntity}
                headline={tariff.tariffDetails.headline}
                footer={tariff.tariffDetails.footer}
                features={tariff.tariffDetails.features}
                asTeaser
              />
            </GlobalSection>
          )}
          {tariff && tariff.hardwareEntity && tariff.hardwareDetails && (
            <GlobalSection
              id={ID_HARDWARE_DETAILS}
              className={suitcss({
                componentName,
                descendantName: 'hardwareDetails',
              })}
              layout="contained"
            >
              <HardwareItemFull
                name={tariff.hardwareDetails.name}
                description={tariff.hardwareDetails.description}
                hardwareList={[tariff.hardware]}
                selectedHardware={tariff.hardware}
                selectedTariffId={tariff && tariff.eid}
                onToggleGallery={this.toggleGallery}
                accessory={accessoryDetails}
                asTeaser
              />
            </GlobalSection>
          )}
        </div>
      </UIProvider>
    );
  }
}

GiveMeYourPrice.propTypes = {
  headline: PropTypes.string.isRequired,
  subline: PropTypes.string,
  copy: PropTypes.string.isRequired,

  // tariff: tariffShape.isRequired,
  // hardware: hardwareShape,
  // tariffDetails: PropTypes.object,
  // hardwareDetails: PropTypes.object,
  accessoryDetails: PropTypes.object,

  userSettings: PropTypes.shape({
    icon: PropTypes.string.isRequired,
  }).isRequired,
  botSettings: PropTypes.shape({
    name: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    isBasarOpen: PropTypes.bool.isRequired,
    subjects: PropTypes.object,
    dealVectors: PropTypes.object,
    rounds: PropTypes.array,
    messages: PropTypes.object.isRequired,
    routes: PropTypes.object,
    product: PropTypes.object,
    ui: PropTypes.object,
  }).isRequired,
  animationDataUrl: PropTypes.string.isRequired,
  dataVolumeChoose: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  showDialog: PropTypes.func.isRequired,
};

GiveMeYourPrice.getSelectedProducts = (entities) => ({
  tariffEntity: entities && entities.find(isTariffEntity),
  hardwareEntity: entities && entities.find(isHardwareEntity),
  hardwareGroup: entities && entities.find(isHardwareGroup),
  tariffDetails: entities && entities.find(isTariffDetails),
  hardwareDetails: entities && entities.find(isHardwareDetails),
});

const mapStateToProps = (state, ownProps) => {
  const { params } = ownProps;
  const {
    headline,
    subline,
    copy,
    image,
    accessoryDetails,
    botName,
    botIcon,
    userIcon,
    isBasarOpen,
    dealVectors,
    rounds,
    messages,
    routes,
    tracking,
    ui,
    subjects,
    code,
    animation,
    dataVolumeChoose,
  } = params;

  return {
    headline,
    subline,
    copy,
    image,
    accessoryDetails,
    userSettings: { icon: userIcon },
    botSettings: {
      name: botName,
      icon: botIcon,
      isBasarOpen,
      dealVectors,
      rounds,
      messages,
      routes,
      tracking,
      ui,
      subjects,
      code,
    },
    animationDataUrl: animation,
    dataVolumeChoose,
    ui: {
      ...ui,
      ...state.ui,
    },
  };
};

const mapDispatchToProps = {
  showDialog: dialogActions.showDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(GiveMeYourPrice);
