import { change } from 'redux-form';
import {
  getTimestamps,
  getUpdateTimestampAction,
} from './helpers';
import IllogicalResponseError from '../../../model/errors/IllogicalResponseError';
import PasswordResetFormMsisdn
  from '../../../components/compositions/account/passwordReset/PasswordResetFormMsisdn';
import initForm from '../../form/FormInitializer';
import {
  MYTRACK_LOGIN_GIVE_PASSWORD_SMS_SENT,
  MYTRACK_LOGIN_GIVE_PASSWORD,
} from '../../../helpers/constants';
import { trackMyState } from '../../../actions/tracking/event';
import * as smsConfig from './formConfigSms';
import * as optionsSelectConfig from './formConfigOptionsSelect';
import { send } from '../../../actions/request/send';
import PasswordResetOptionsRequest from '../../../model/requests/PasswordResetOptionsRequest';
import PasswordResetSmsRequest from '../../../model/requests/PasswordResetSmsRequest';
import PasswordResetEmailRequest from '../../../model/requests/PasswordResetEmailRequest';
import FormConfigProps from '../../../model/form/FormConfigProps';

const createFieldMap = ui => ({
  msisdn: {
    name: 'msisdn',
    shouldSubmit: false,
    label: ui.guiFormMsisdn,
    type: 'tel',
    validation: {
      isRequired: true,
      pattern: 'phone',
      range: [7, 25],
    },
  },
  msisdnShort: {
    shouldSubmit: false,
    name: 'msisdn_shortened',
  },
  emailShort: {
    shouldSubmit: false,
    name: 'email_shortened',
  },
  timestamps: {
    shouldSubmit: false,
    name: 'timestamps',
  },
  forcedOption: {
    shouldSubmit: false,
    name: 'forced_option',
  },
});

const isRequestAllowed = (timestamps, msisdn, option) => {
  const timestamp = timestamps[option][msisdn];
  return !timestamp || timestamp < Date.now();
};

/**
 * If the user has more than one reset options, we will display a next step in which
 * he she is able to choose one of the possible reset options.
 *
 * Otherwise, we immediately tell the server to create a reset token.
 */
const makeSubmit = (stepConfig, props) => async (values, dispatch) => {
  const { form, fieldMap, formValues } = props;

  const msisdn = values[fieldMap.msisdn.name];
  const forcedOption = values[fieldMap.forcedOption.name];
  const response = (await dispatch(send(new PasswordResetOptionsRequest(msisdn)))).body.data;
  const {
    msisdnShortened,
    emailShortened,
    email: hasEmailOption,
    sms: hasSmsOption,
  } = response;

  dispatch(change(form, fieldMap.msisdnShort.name, msisdnShortened));
  dispatch(change(form, fieldMap.emailShort.name, emailShortened));

  if (
    (!hasSmsOption && !hasEmailOption) ||
    (forcedOption === 'sms' && !hasSmsOption) ||
    (forcedOption === 'email' && !hasEmailOption)
  ) {
    throw new IllogicalResponseError();
  }

  if (!forcedOption && (hasSmsOption && hasEmailOption)) {
    return optionsSelectConfig.id;
  }

  const option = forcedOption || (hasSmsOption ? 'sms' : 'email');
  const tokenRequest = hasSmsOption
    ? new PasswordResetSmsRequest(msisdn)
    : new PasswordResetEmailRequest(msisdn);
  const timestamps = getTimestamps(fieldMap, formValues);

  if (isRequestAllowed(timestamps, msisdn, option)) {
    let cooldown = null;

    const res = await dispatch(send(tokenRequest));
    cooldown = res.cooldown;

    if (hasSmsOption) {
      dispatch(trackMyState(MYTRACK_LOGIN_GIVE_PASSWORD_SMS_SENT));
    }

    dispatch(getUpdateTimestampAction(form, fieldMap, formValues, option, cooldown));
  }

  return smsConfig.id;
};

// init component once, outside
const component = initForm()(PasswordResetFormMsisdn);

export const id = 'msisdn';

/**
 * @return {FormConfig}
 */
export const mapStateToFormConfig = ({ ui }, props) => ({
  component,
  fieldMap: createFieldMap(props.ui),
  makeSubmit,
  formConfigProps: new FormConfigProps({
    labelSubmit: ui.myPasswordLostSubmit,
    dataSubmit: { mytracking: MYTRACK_LOGIN_GIVE_PASSWORD },
  }),
});
