import React from 'react';
import PropTypes from 'prop-types';

import * as eSimPushConfig from './formConfigESimPush';
import FormManager from '../../form/FormManager';

const MyESimPushForm = (props) => {
  const { location } = props;

  return (
    <FormManager
      form={MyESimPushForm.formName}
      location={location}
      steps={[eSimPushConfig]}
      stepProps={props}
    />
  );
};

// note: required by tracking!
MyESimPushForm.formName = 'MyESimPushForm';

MyESimPushForm.propTypes = {
  location: PropTypes.object,
};

export default MyESimPushForm;
