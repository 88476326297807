/* global window */
import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  addTrackingGarbage,
  removeTrackingGarbage,
} from '../../actions/tracking/garbageCollection';
import TrackingFire from './TrackingFire';

const GARBAGE_NODE_TYPES = ['SCRIPT', 'IMG', 'IFRAME'];
const OBSERVE_OPTIONS = { attributes: false, childList: true, characterData: false };
const mutationCallback = dispatch => mutations => {
  mutations.forEach(mutation => {
    [...mutation.addedNodes]
      .filter(node => node && GARBAGE_NODE_TYPES.includes(node.nodeName))
      .forEach(node => dispatch(addTrackingGarbage(node)));
    [...mutation.removedNodes]
      .filter(node => node && GARBAGE_NODE_TYPES.includes(node.nodeName))
      .forEach(node => dispatch(removeTrackingGarbage(node)));
  });
};

/**
 * Fires tracking events on the utag function when it is available
 * The container requests tracking information from the store.
 * The component does not have any visual output.
 */
class Tracking extends PureComponent {
  constructor(...args) {
    super(...args);
    const { dispatch } = this.props;

    if (process.browser && window.MutationObserver) {
      this.headObserver = new window.MutationObserver(mutationCallback(dispatch));
      this.bodyObserver = new window.MutationObserver(mutationCallback(dispatch));
    }
  }

  componentDidMount() {
    if (process.browser && window.MutationObserver) {
      this.headObserver.observe(window.document.querySelector('head'), OBSERVE_OPTIONS);
      this.bodyObserver.observe(window.document.querySelector('body'), OBSERVE_OPTIONS);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.tick);
    if (process.browser) {
      this.headObserver.disconnect();
      this.bodyObserver.disconnect();
    }
  }

  render() {
    const { pplus, isAppView, dispatch, doNotTrackPage } = this.props;
    return (
      <Fragment>
        {this.props.queue.map(pixel => (
          <TrackingFire
            key={pixel.id}
            pixel={pixel}
            isAppView={isAppView}
            pplus={pplus}
            dispatch={dispatch}
            doNotTrackPage={doNotTrackPage}
          />
        ))}
      </Fragment>
    );
  }
}

Tracking.propTypes = {
  dispatch: PropTypes.func.isRequired,
  queue: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ),
  pplus: PropTypes.bool.isRequired,
  doNotTrackPage: PropTypes.bool,
  isAppView: PropTypes.bool.isRequired,
};

Tracking.defaultProps = {
  queue: [],
};

const mapStateToProps = ({ tracking, user, site }) => ({
  queue: tracking.queue,
  pplus: !!user.pplus,
  doNotTrackPage: !!site.doNotTrackPage,
  isAppView: !!site.appView,
});

export default connect(mapStateToProps)(Tracking);
