import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import TextCopy from '../../basics/text/TextCopy';
import Link from '../../basics/text/TextLink';
import suitcss from '../../../helpers/suitcss';
import FieldInput from '../../basics/form/FieldInput';
import SvgLoader from '../../basics/media/MediaSvgLoader';

const componentName = 'CaptchaService';
export default function Captcha(props) {
  const { ui, captcha, reloadCaptcha, lineLength } = props;
  const captchaSource = `data:${captcha.image.mime};base64, ${captcha.image.data}`;
  const lineStyling = lineLength && lineLength.length ? lineLength : [''];
  return (
    <section className={suitcss({
      componentName,
      utilities: ['marginTopXS', 'marginBottom'],
    }, this)}
    >
      <div className={suitcss({
        utilities: ['grid'],
      }, this)}
      >
        <TextCopy
          className={suitcss({
            utilities: ['col12'],
          }, this)}
          element="span"
          embedded
        >
          {ui.captchaServiceImageCopy ? ui.captchaServiceImageCopy : ''}
        </TextCopy>
        <Field
          className={suitcss({
            utilities: ['col10'],
          }, this)}
          name={'captcha'}
          utilities={[...lineStyling, 'marginTopXS']}
          label={ui.captchaServiceImageAnswerLabel}
          component={FieldInput}
          withToolTip
        />
        <img
          className={suitcss({
            componentName,
            descendantName: 'image',
            utilities: ['marginTopXS'],
        }, this)} src={captchaSource} alt="Captcha"
        />
        <Link
          className={suitcss({
            componentName,
            descendantName: 'reload',
            utilities: ['marginTopXS'],
          }, this)}
          element="button"
          withoutStyle
          ariaLabel={ui.captchaServiceRefreshButtonAlt}
          onClick={reloadCaptcha}
        >
          <SvgLoader path="/icons/content-autorenew.svg" />
        </Link>
      </div>
    </section>
  );
}
Captcha.propTypes = {
  captcha: PropTypes.object.isRequired,
  reloadCaptcha: PropTypes.func.isRequired,
  trackDetail: PropTypes.func.isRequired,
  lineLength: PropTypes.array,
  ui: PropTypes.shape({
    captchaServiceImageCopy: PropTypes.string,
    captchaServiceImageAnswerLabel: PropTypes.string,
    captchaServiceRefreshButtonAlt: PropTypes.string,
  }),
};
