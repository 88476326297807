import QueueableRequest from './QueueableRequest';
import { EMAIL_VALIDATION_CONFIRM } from '../../config/api';
import { REQUEST_METHOD_POST } from '../../helpers/constants';

export default class EmailValidationConfirmRequest extends QueueableRequest {
  constructor(payload) {
    super(EMAIL_VALIDATION_CONFIRM, {
      method: REQUEST_METHOD_POST,
      payload,
    });
  }
}
