import {
  ERROR_DISPLAY_STYLE_ALERT,
  DIALOG_TYPE_QUEUED,
  NOTIFICATION_TYPE_DEFAULT,
  NOTIFICATION_TYPE_ALERT,
  NOTIFICATION_TYPE_CUSTOM,
  ACTION_PREFIX,
  ERROR_DISPLAY_STYLE_HIDDEN,
  STORAGE_KEY_CONSENT_INQUIRY_DIALOG,
} from '../../helpers/constants';
import Dialog from '../../model/Dialog';
import ErrorLogEntry from '../../model/logging/ErrorLogEntry';
import { log, trackDialog } from '../tracking/event';
import { makeAbstractError } from '../../helpers/misc';
import { getItem, setItem, STORAGE_TYPE_COOKIE } from '../../helpers/storage';
import { DAY } from '../../helpers/date';
import { camelCase } from '../../helpers/str';
import { isAccountUrl } from '../../config/api';
import { getConsentInquiryDialog } from '../dialog/misc';

export const DISPLAY_DIALOG = `${ACTION_PREFIX}/DISPLAY_DIALOG`;
export const HIDE_DIALOG = `${ACTION_PREFIX}/HIDE_DIALOG`;
export const SHOW_DIALOG = `${ACTION_PREFIX}/SHOW_DIALOG`;
export const ADD_QUEUED_DIALOG = `${ACTION_PREFIX}/ADD_QUEUED_DIALOG`;
export const HIDE_QUEUED_DIALOG = `${ACTION_PREFIX}/HIDE_QUEUED_DIALOG`;
export const UPDATE_DIALOG_STATE = `${ACTION_PREFIX}/UPDATE_DIALOG_STATE`;
export const HIDE_ALERT = `${ACTION_PREFIX}/HIDE_ALERT`;
export const SHOW_ALERT = `${ACTION_PREFIX}/SHOW_ALERT`;
export const HIDE_NOTIFICATION = `${ACTION_PREFIX}/HIDE_NOTIFICATION`;
export const SHOW_NOTIFICATION = `${ACTION_PREFIX}/SHOW_NOTIFICATION`;
export const WINDOW_OPENED = `${ACTION_PREFIX}/WINDOW_OPENED`;

export const displayDialog = (dialog) => (dispatch) => {
  dispatch({
    type: DISPLAY_DIALOG,
    payload: { dialogShown: dialog },
  });
};

export const hideDialog = () => (dispatch) => {
  dispatch({
    type: HIDE_DIALOG,
    payload: { dialog: null },
  });
};

/**
 * showDialog description
 *
 * @param {string} theme                 [null, light, medium, dark]
 * @param {string} type                  [null]
 * @param {string} headline              [headline text]
 * @param {[element, string]} copy       [copy element or text]
 * @param {type} actions                 [list of links / buttons]
 * @param {[Function, Class]} component  [Component Class]
 * @param {[element, string]} props      [Component Props]
 * @param {object} actions               [{
 *                                         @param {string} label,
 *                                         @param {string, function} action,
 *                                         @param {boolean} asLink, renders action as TextLink
 *                                         @param {boolean} expanded, renders action with width 100%
 *                                       }]
 * @param {func} onClose                 [function called when dialog is closed manually]
 * @param {bool} withCloseAction         [determines if the dialog is manually closable]
 */
export const showDialog = ({
  theme,
  type,
  headline,
  copy,
  actions,
  component,
  props,
  onClose,
  withCloseAction,
  shouldCloseOnTransition,
  isExpanded,
  id,
  title,
  withoutLightboxFadeout,
  isAutomaticallyTracked,
}) => (dispatch) => {
  if (isAutomaticallyTracked || isAutomaticallyTracked === undefined) {
    dispatch(trackDialog({ headline: title || headline || JSON.stringify(props).slice(0, 50), module_id: id, type: 'lightbox' }));
  }

  dispatch({
    type: SHOW_DIALOG,
    payload: {
      dialog: {
        type,
        theme,
        withoutLightboxFadeout,
        headline,
        copy,
        actions,
        component,
        props,
        onClose,
        withCloseAction: withCloseAction !== undefined ? withCloseAction : true,
        shouldCloseOnTransition,
        isExpanded,
      },
    },
  });
};

export const showTemplateDialog = (id) => (dispatch, getState) => {
  const { dialogTemplates } = getState().site;
  const dialogTemplate = dialogTemplates[id];
  if (dialogTemplate) {
    const dialogEntity = dispatch(Dialog.createFromApiModel(dialogTemplate));
    dispatch(showDialog(dialogEntity));
  }
};

export const showConsentDialog = (id) => (dispatch, getState) => {
  const {
    site, user, routing, ui,
  } = getState();
  const { consentTexts } = site;
  const { gdprConsents } = user;
  const { locationBeforeTransitions } = routing;

  const isInsideConsentInquiryDialog =
    getItem(STORAGE_TYPE_COOKIE, STORAGE_KEY_CONSENT_INQUIRY_DIALOG);
  const consentDialogTexts = isAccountUrl(locationBeforeTransitions) &&
    !isInsideConsentInquiryDialog ? gdprConsents : consentTexts;
  const dialogTemplate = consentDialogTexts.find(text => id.includes(text.id)).notes[camelCase(id)];
  dialogTemplate.buttons = [{ title: ui.guiWordAlright }];
  if (dialogTemplate) {
    const dialogEntity = dispatch(Dialog.createFromApiModel(dialogTemplate));
    if (isInsideConsentInquiryDialog) {
      const actions = [
        {
          ...dialogEntity.actions[0],
          action: () => {
            dispatch(hideDialog());
            dispatch(getConsentInquiryDialog());
          },
        },
      ];
      const dialogConfig = {
        ...dialogEntity,
        onClose: () => {
          dispatch(getConsentInquiryDialog());
        },
        actions,
      };
      dispatch(showDialog(dialogConfig));
    } else {
      dispatch(showDialog(dialogEntity));
    }
  }
};

/**
 * Modifies the state to signalize that a window was opened or closed.
 * @param {bool} The state of the window.
 */
export const openWindow = (state) => ({
  type: WINDOW_OPENED,
  payload: { windowOpen: state },
});

/**
 * [addDialogToQueue description]
 */

export const addDialogToQueue = ({ headline, copy, actions, onClose, withCloseAction }) => ({
  type: ADD_QUEUED_DIALOG,
  payload: {
    type: DIALOG_TYPE_QUEUED,
    headline,
    copy,
    actions,
    onClose,
    withCloseAction: withCloseAction !== undefined ? withCloseAction : true,
  },
});

export const addExtensiveDialogToQueue = ({
  theme,
  headline,
  copy,
  actions,
  onClose,
  withCloseAction,
  component,
  props,
  shouldCloseOnTransition,
  isExpanded,
  id,
  title,
  withoutLightboxFadeout,
}) => ({
  type: ADD_QUEUED_DIALOG,
  payload: {
    type: DIALOG_TYPE_QUEUED,
    theme,
    headline,
    copy,
    actions,
    component,
    props,
    onClose,
    shouldCloseOnTransition,
    isExpanded,
    id,
    title,
    withoutLightboxFadeout,
    withCloseAction: withCloseAction !== undefined ? withCloseAction : true,
  },
});

export const hideQueuedDialog = (payload) => (dispatch) => {
  dispatch({
    type: HIDE_QUEUED_DIALOG,
    payload,
  });
};

export const updateDialogState = (payload) => ({
  type: UPDATE_DIALOG_STATE,
  payload,
});

export const hideAlert = () => ({
  type: HIDE_ALERT,
  payload: null,
});

/**
 * @param {AbstractError|string} error
 */
export const showAlert = (error) => (dispatch, getState) => {
  error = makeAbstractError(error); // eslint-disable-line no-param-reassign
  const { ui, site } = getState();
  const alreadyShown = site.errors.find((entry) => entry.error.fullCode === error.fullCode);
  dispatch(log(new ErrorLogEntry(
    error,
    error.getVerbalization(ui),
    alreadyShown ? ERROR_DISPLAY_STYLE_HIDDEN : ERROR_DISPLAY_STYLE_ALERT,
  )));
  dispatch({
    type: SHOW_ALERT,
    payload: { type: NOTIFICATION_TYPE_ALERT, error },
  });
};

export const hideNotification = () => ({
  type: HIDE_NOTIFICATION,
  payload: { notification: null },
});

export const showNotification = (notification) => (dispatch) => {
  const isText = typeof notification === 'string';
  const type = notification.type || NOTIFICATION_TYPE_DEFAULT;
  const props = isText ? { copy: notification } : notification;
  const autoClose =
    (props.autoClose === undefined && notification.type !== NOTIFICATION_TYPE_CUSTOM) ||
    !!props.autoClose;
  if (!isText) {
    const { copy, moduleId, moduleTitle } = notification;
    dispatch(trackDialog({ type: 'banner', module_id: moduleId, headline: moduleTitle || copy }));
  }
  dispatch({
    type: SHOW_NOTIFICATION,
    payload: {
      notification: {
        type,
        props,
        autoClose,
        shouldCloseOnTransition: props.shouldCloseOnTransition,
      },
    },
  });
};

export const showDialogOnce = (id, dialog) => (dispatch) => {

  const cookie = getItem(STORAGE_TYPE_COOKIE, id);

  if (!cookie) {
    setItem(STORAGE_TYPE_COOKIE, id, true, {
      expires: new Date(Date.now() + DAY),
      path: '/',
    });

    dispatch(showDialog(dialog));

  }
};

export const showQueuedDialogOnce = (id, dialog) => (dispatch) => {

  const cookie = getItem(STORAGE_TYPE_COOKIE, id);

  if (!cookie) {
    setItem(STORAGE_TYPE_COOKIE, id, true, {
      expires: new Date(Date.now() + DAY),
      path: '/',
    });

    dispatch(addDialogToQueue(dialog));

  }
};
