import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';
import connectUI from '../ui/UIConnector';
import Copy from './TextCopy';

const sizeExponents = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];

class TextFileSize extends PureComponent {

  getSizeExponent() {
    const { size } = this.props;
    const exp = Math.floor(Math.log(size) / Math.log(1024));
    // Ensure KB, MB, GB values only
    if (exp < 1) {
      return 1;
    }

    if (exp > 3) {
      return 3;
    }

    return exp;
  }

  // Convert number of bytes into human readable format
  getFormatedSize() {
    const { size, ui } = this.props;
    const exp = this.getSizeExponent();
    const postfix = ui[`symbol${sizeExponents[exp]}`];
    // Calculate size to the power of exp / covert to string
    const sizeStr = (size / (1024 ** exp)).toFixed(1).toString();
    return `${sizeStr.replace('.', ui.symbolComma)} ${postfix}`;
  }

  renderContent() {
    const { prefix, size } = this.props;

    if (!prefix) {
      return this.getFormatedSize();
    }

    if (!size) {
      return prefix;
    }

    return `${prefix} ${this.getFormatedSize()}`;
  }

  render() {
    return (
      <Copy className={suitcss({}, this)} size="secondary" raw embedded >
        {this.renderContent()}
      </Copy>
    );
  }
}

TextFileSize.propTypes = {
  prefix: PropTypes.string,
  size: PropTypes.number,
  ui: PropTypes.shape({
    symbolKB: PropTypes.string.isRequired,
    symbolMB: PropTypes.string.isRequired,
    symbolGB: PropTypes.string.isRequired,
  }),
};

export default connectUI({
  symbolKB: 'guiSymbolKilobyte',
  symbolMB: 'guiSymbolMegabyte',
  symbolGB: 'guiSymbolGigabyte',
  symbolComma: 'guiSymbolComma',
})(TextFileSize);
