/* global window */
import { push } from 'react-router-redux';
import { showDialog, hideDialog } from '../page/dialog';

/**
 * Shows the video ident confirmation dialog
 */
export const videoIdentValidateInputDialog = () => (dispatch, getState) =>
  new Promise(resolve => {
    const { ui } = getState();
    dispatch(showDialog({
      headline: ui.guiValidateInput,
      copy: ui.txtVididentValidateInputCopy,
      onClose: () => resolve(false),
      actions: [
        {
          label: ui.guiWordContinue,
          action: () => {
            resolve(true);
            dispatch(hideDialog());
          },
        },
        {
          label: ui.guiWordBack,
          action: () => {
            resolve(false);
            dispatch(hideDialog());
          },
          asLink: true,
        },
      ],
    }));
  });


/**
 * Shows a dialog in which the user is asked if the process really should be postponed
 */
export const videoIdentPostponeDialog = (headline, copy) => async (dispatch, getState) => {
  const { ui } = getState();
  dispatch(showDialog({
    headline,
    copy,
    actions: [
      {
        label: ui.guiWordContinue,
        action: async () => {
          dispatch(push('/'));
          dispatch(hideDialog());
        },
      },
      {
        label: ui.guiWordCancel,
        asLink: true,
      },
    ],
  }));
};

/**
 * Shows a dialog in which the user is informed to prepare for the video identification
 */
export const videoIdentPrepareDialog =
  (headline, copy, orderId, skip) => async (dispatch, getState) => {
    const { ui, order } = getState();
    if (!orderId) { return; }
    const openWindow = () => {
      window.open(order[orderId].response.deferredIdentification.url);
    };
    if (skip) {
      openWindow();
      return;
    }
    dispatch(showDialog({
      headline,
      copy,
      actions: [
        {
          label: ui.guiWordContinue,
          action: async () => {
            openWindow();
            dispatch(hideDialog());
          },
        },
        {
          label: ui.guiWordCancel,
          asLink: true,
        },
      ],
    }));
  };
