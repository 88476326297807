import { push } from 'react-router-redux';
import { bindQueryParams } from '../../helpers/url';
import { formatDate, DAY } from '../../helpers/date';
import { getAppEntityName, getPaymentFeeDuration } from '../../helpers/entity';
import { toDecimal } from '../../helpers/money';
import * as requests from '../request/registry';
import { trackMyState } from '../tracking/event';
import {
  TRACK_REPLACE_PROMOTION_ID,
  NOTIFICATION_TYPE_LOW_VOLUME,
  NOTIFICATION_TYPE_LOW_BALANCE,
  NOTIFICATION_TYPE_PAUSED,
  REQUEST_METHOD_DELETE,
  BILLING_MODE_PRE,
  MYTRACK_DASHBOARD_OVERLAY_DATA,
  MYTRACK_DASHBOARD_OVERLAY_MIN,
  MYTRACK_DASHBOARD_OVERLAY_UNIT,
  MYTRACK_DASHBOARD_OVERLAY_SMS,
  MYTRACK_DASHBOARD_CONSUMPTION_COSTS,
  MYTRACK_DASHBOARD_TARIFF_OPTIONS,
  MYTRACK_CONTRACT_CANCELLATION_DIALOG,
  MYTRACK_CONTRACT_CANCELLATION_DIALOG_CANCEL,
  MYTRACK_CONTRACT_CANCELLATION_DIALOG_SUBMIT,
  MYTRACK_TARIFF_OPTION_BUY,
  MYTRACK_MYOFFER_PROMOTION_BOOK_SUBMIT,
  MYTRACK_MYOFFER_PROMOTION_BOOK_SUCCESS,
  MYTRACK_NOTIFY_LOW_BALANCE,
  MYTRACK_NOTIFY_TARIFF_OPTION_PAUSED,
  MYTRACK_NOTIFY_TARIFF_OPTION_CANCELATION,
  MYTRACK_NOTIFY_TARIFF_OPTION_CONFIRM,
} from '../../helpers/constants';
import ConsumptionDetailsDialog
  from '../../components/compositions/dialog/ConsumptionDetailsDialog';
import EECCCheckSummaryConsentDialogCopy from '../../components/compositions/dialog/EECCCheckSummaryConsentDialogCopy';
import ConsumptionsRequest from '../../model/requests/ConsumptionsRequest';
import TariffNotificationRequest from '../../model/requests/TariffNotificationRequest';
import EECCTariffOptionsOrderInitiateRequest from '../../model/requests/EECCTariffOptionsOrderInitiateRequest';
import EECCTariffOptionsOrderDocumentRequest from '../../model/requests/EECCTariffOptionsOrderDocumentRequest';
import EECCTariffOptionsOrderConfirmRequest from '../../model/requests/EECCTariffOptionsOrderConfirmRequest';
import EECCTariffOptionsDeleteInitiateRequest from '../../model/requests/EECCTariffOptionsDeleteInitiateRequest';
import EECCTariffOptionsDeleteDocumentRequest from '../../model/requests/EECCTariffOptionsDeleteDocumentRequest';
import EECCTariffOptionsDeleteConfirmRequest from '../../model/requests/EECCTariffOptionsDeleteConfirmRequest';
import EECCPromotionsOrderConfirmRequest from '../../model/requests/EECCPromotionsConfirmRequest';
import ContractCancellationRequest from '../../model/requests/CancellationRequest';

import {
  showDialog, hideDialog, addDialogToQueue, hideQueuedDialog, updateDialogState, showNotification,
} from '../page/dialog';
import { send } from '../request/send';

// get notification dialog configuration
const getNotificationDialog = (id, notification, { ui, site }, dispatch) => {
  const dialog = {
    id,
    withCloseAction: false,
    actions: [{
      withoutArrow: true,
      label: ui.guiWordAllright,
    }],
  };
  switch (id) {
    case NOTIFICATION_TYPE_LOW_VOLUME: {
      const urlDetails = bindQueryParams(
        site.sitemap.MyTariffOptionDetailsRoute.url,
        { iid: notification.tariffOptionId },
      );
      return {
        ...dialog,
        headline: ui.myDashboardLowVolumeHeadline,
        copy: ui.myDashboardLowVolumeCopy,
        actions: [
          {
            label: ui.guiBookTariffoption,
            action: () => {
              dispatch(updateDialogState({
                id,
                cooldown: new Date(Date.now() + (DAY * notification.cooldown)),
              }));
              dispatch(hideQueuedDialog());
              dispatch(trackMyState(MYTRACK_DASHBOARD_TARIFF_OPTIONS));
              dispatch(push(urlDetails));
            },
          },
          { label: ui.guiWordCancel, asLink: true },
        ],
      };
    }
    case NOTIFICATION_TYPE_LOW_BALANCE:
      return {
        ...dialog,
        headline: ui.myDashboardLowBalanceHeadline,
        copy: ui.myDashboardLowBalanceCopy.replace('{DATE}', formatDate(notification.bookingDate)),
      };
    case NOTIFICATION_TYPE_PAUSED:
      return {
        ...dialog,
        headline: ui.myDashboardTariffoptionPausedHeadline,
        copy: ui.myDashboardTariffoptionPausedCopy,
      };
    default:
      console.warn('no notification dialog found for id:', id);
      return null;
  }
};

// helper function for determining if the notification's raw response (id flag) is true,
// normalize as active flag for easier usage
const isRawNotificationActive = (notification) => (
  (notification.lowVolume !== undefined && notification.lowVolume) ||
  (notification.lowBalance !== undefined && notification.lowBalance) ||
  (notification.paused !== undefined && notification.paused)
);

const isCooldownExpired = (expirationDate) => (new Date(expirationDate) - Date.now() <= 0);

const setTariffOptionCancelCopy = (uiElem, tariffOption) => {
  if (tariffOption.state !== 'paused') {
    return uiElem.myTariffoptionCancelCopy
      .replace('{NAME}', getAppEntityName(tariffOption))
      .replace('{DATE}', formatDate((tariffOption.stateDate || '')));
  }
  return uiElem.myTariffoptionCancelCopy2.replace('{NAME}', getAppEntityName(tariffOption));
};

/*
  Fetch Account Related Notification. Add to Dialog queue if active and cooldown has expired
  onClose update dialog state (cooldown)
 */
export const fetchAccountNotification = id => async (dispatch, getState) => {
  const { user, site } = getState();
  const msisdn = user.credentials.msisdn;
  const dialogState = site.dialogStates[id];

  if (!dialogState || isCooldownExpired(dialogState.cooldown)) {
    const response = await dispatch(send(new TariffNotificationRequest(msisdn, id)));
    const notification = response.body.data;
    if (isRawNotificationActive(notification)) {
      const onClose = () => {
        dispatch(updateDialogState({
          id,
          cooldown: new Date(Date.now() + (DAY * notification.cooldown)),
        }));
      };
      const dialog = { ...getNotificationDialog(id, notification, getState(), dispatch), onClose };
      dispatch(addDialogToQueue(dialog));

      if (id === NOTIFICATION_TYPE_LOW_BALANCE) {
        dispatch(trackMyState(MYTRACK_NOTIFY_LOW_BALANCE));
      } else if (id === NOTIFICATION_TYPE_PAUSED) {
        dispatch(trackMyState(MYTRACK_NOTIFY_TARIFF_OPTION_PAUSED));
      }
    }
  }
};

export const bookOption = (tariffOptionVO) => async (dispatch, getState) => {
  const { user, ui, site } = getState();
  const price = toDecimal({ unit: tariffOptionVO.basicFee, currency: 'EUR' });
  const copyText = tariffOptionVO.automaticExtension
    ? ui.myTariffoptionBookCopy
    : ui.myTariffoptionBookCopySingle;

  dispatch(trackMyState(MYTRACK_TARIFF_OPTION_BUY));
  const initResponse = await dispatch(send(new EECCTariffOptionsOrderInitiateRequest({
    product: {
      tariff_option_id: tariffOptionVO.id,
    },
  }, user.credentials.msisdn)));


  dispatch(showDialog({
    headline: ui.myTariffoptionBookHeadline,
    copy: copyText
      .replace('{NAME}', getAppEntityName(tariffOptionVO))
      .replace('{AMOUNT}', price)
      .replace('{DURATION}', getPaymentFeeDuration(tariffOptionVO, ui)),
    component: EECCCheckSummaryConsentDialogCopy,
    props: {
      DocumentRequest: EECCTariffOptionsOrderDocumentRequest,
      processId: initResponse.body.data.processId,
      showInfoI: false,
      showEECCCopy: !!(ui.tariffoptionsCsPlaceholder),
      eeccText: ui.tariffoptionsCsHint,
      eeccTextSuffix: ui.tariffoptionsCsPlaceholder,
      isAutomaticExtension: !!tariffOptionVO.automaticExtension,
    },
    actions: [
      {
        label: ui.myTariffoptionBook,
        action: async () => {
          dispatch(trackMyState(MYTRACK_DASHBOARD_TARIFF_OPTIONS));

          await dispatch(send(new EECCTariffOptionsOrderConfirmRequest(
            initResponse.body.data.processId,
            user.credentials.msisdn,
            { contract_summary_confirmed: true },
          )));

          dispatch(hideDialog());
          dispatch(trackMyState(MYTRACK_NOTIFY_TARIFF_OPTION_CONFIRM));
          dispatch(push(site.sitemap.MyDashboardRoute.url));
          dispatch(showNotification(ui.myTariffoptionBookResponse));
        },
      },
      { label: ui.guiWordCancel, asLink: true },
    ],
  }));
};

export const bookPromotion = (promotionVO) =>
  async (dispatch, getState) => {
    const { user } = getState();
    const { id } = promotionVO;

    dispatch(trackMyState(
      MYTRACK_MYOFFER_PROMOTION_BOOK_SUBMIT.replace(TRACK_REPLACE_PROMOTION_ID, id),
    ));
    await dispatch(send(new EECCPromotionsOrderConfirmRequest(
      user.EECC.promotion.processId,
      user.credentials.msisdn,
      {
        contract_summary_confirmed: true,
        promotions: [
          { promotion_id: id },
        ],
      },
    )));
    dispatch(trackMyState(
      MYTRACK_MYOFFER_PROMOTION_BOOK_SUCCESS.replace(TRACK_REPLACE_PROMOTION_ID, id),
    ));
  };

export const cancelTariffOption = (tariffOptionVO) => async (dispatch, getState) => {
  const { user, ui, site } = getState();
  const initResponse = await dispatch(send(new EECCTariffOptionsDeleteInitiateRequest(
    user.credentials.msisdn,
    { tariff_option_id: tariffOptionVO.id },
  )));

  dispatch(showDialog({
    headline: ui.myTariffoptionCancelHeadline,
    copy: setTariffOptionCancelCopy(ui, tariffOptionVO),
    component: EECCCheckSummaryConsentDialogCopy,
    props: {
      DocumentRequest: EECCTariffOptionsDeleteDocumentRequest,
      processId: initResponse.body.data.processId,
      showInfoI: false,
      showEECCCopy: !!(ui.tariffoptionsCsPlaceholder),
      eeccText: ui.tariffoptionsCsHint,
      eeccTextSuffix: ui.tariffoptionsCsPlaceholder,
      isAutomaticExtension: !!tariffOptionVO.automaticExtension,
    },
    actions: [
      {
        label: ui.myTariffoptionCancelOk,
        action: async () => {

          await dispatch(send(new EECCTariffOptionsDeleteConfirmRequest(
            user.credentials.msisdn,
            initResponse.body.data.processId,
            { contract_summary_confirmed: true },
          )));

          dispatch(showNotification(tariffOptionVO.state !== 'paused' ?
            ui.myTariffoptionCancelResponse.replace('{DATE}', formatDate(tariffOptionVO.stateDate || '')) :
            ui.myTariffoptionCancelResponse2,
          ));
          dispatch(hideDialog());
          dispatch(push(site.sitemap.MyDashboardRoute.url));
        },
      },
      { label: ui.guiWordCancel, asLink: true },
    ],
  }));
};

export const withdrawTariffCancellation = (tariffVO) => async (dispatch, getState) => {
  const { user, ui } = getState();
  dispatch(trackMyState(MYTRACK_CONTRACT_CANCELLATION_DIALOG));
  dispatch(showDialog({
    headline: ui.myTariffWithdrawCancellation,
    copy: ui.myCancellationWithdrawCopy.replace('{NAME}', getAppEntityName(tariffVO)),
    actions: [
      {
        label: ui.myCancellationWithdrawOk,
        action: async () => {
          const contractData = await dispatch(requests.fetchContractData());
          dispatch(trackMyState(MYTRACK_CONTRACT_CANCELLATION_DIALOG_SUBMIT));
          await dispatch(send(new ContractCancellationRequest(
            user.credentials.msisdn,
            contractData.contractId,
            {
              method: REQUEST_METHOD_DELETE,
              isBlocking: true,
            },
          )));
          dispatch(showNotification(ui.myCancellationWithdrawResponse));
          dispatch(hideDialog());
        },
      },
      { label: ui.guiWordCancel, asLink: true },
    ],
    onClose: () => dispatch(trackMyState(MYTRACK_CONTRACT_CANCELLATION_DIALOG_CANCEL)),
  }));
};

export const showConsumptionDetailsDialog = (id) => async (dispatch, getState) => {
  const { ui, user } = getState();
  const response = await dispatch(send(new ConsumptionsRequest(user.credentials.msisdn)));
  const consumption = response.body.data.consumptionSummary[id];
  switch (consumption.type) {
    case 'min':
      dispatch(trackMyState(MYTRACK_DASHBOARD_OVERLAY_MIN));
      break;
    case 'unit':
      dispatch(trackMyState(MYTRACK_DASHBOARD_OVERLAY_UNIT));
      break;
    case 'sms':
      dispatch(trackMyState(MYTRACK_DASHBOARD_OVERLAY_SMS));
      break;
    case 'data':
      dispatch(trackMyState(MYTRACK_DASHBOARD_OVERLAY_DATA));
      break;
    default:
      break;
  }

  dispatch(showDialog({
    headline: ui.consumptionsDetailsHeadline,
    component: ConsumptionDetailsDialog,
    props: {
      type: consumption.type,
      unit: consumption.unit,
      consumptions: consumption.consumptions,
      ui,
    },
    actions: [{ label: ui.guiWordAllright }],
  }));
};

export const showConsumptionsIndividualDialog = (headline, copy) => (dispatch, getState) => {
  const { ui } = getState();
  dispatch(showDialog({
    headline,
    copy,
    actions: [
      {
        label: ui.guiWordAllright,
        withoutArrow: true,
        asLink: true,
      }],
  }));
};

export const showBalanceDetailsDialog = () => async (dispatch, getState) => {
  const { ui } = getState();
  dispatch(trackMyState(MYTRACK_DASHBOARD_CONSUMPTION_COSTS));
  dispatch(showDialog({
    headline: ui.myDashboardCostsSubline,
    copy: ui.myDashboardCostsCopy,
    actions: [{ label: ui.guiWordAllright }],
  }));
};

export const showCancellationSuccessDialog = () => async (dispatch, getState) => {
  const state = getState();
  const { site, ui } = state;
  const contractData = await dispatch(requests.fetchContractData());
  dispatch(trackMyState(MYTRACK_NOTIFY_TARIFF_OPTION_CANCELATION));
  if (contractData.billingMode === BILLING_MODE_PRE) {
    dispatch(push(site.sitemap.MyCancellationSuccessRoute.url));
  } else {
    dispatch(showDialog({
      headline: ui.myCancellationFormSuccessHeadline,
      copy: ui.myCancellationFormSuccessCopy,
      actions: [{ label: ui.guiWordAllright }],
    }));
  }
};
