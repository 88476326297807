import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import GlobalSection from '../../components/basics/global/GlobalSection';
import Teaser from '../../components/compositions/teaser/Teaser';

import Slider from '../../components/basics/slider/Slider';
import SliderSlide from '../../components/basics/slider/SliderSlide';
import { bindQueryParams } from '../../helpers/url';
import { getContextAction } from '../../helpers/context';
import { camelCase } from '../../helpers/str';
import Link from '../../model/Link';
import { ENTITY_TYPE_TARIFFOPTION } from '../../helpers/constants';

const ITEM_TYPE_INFO = 'info';
const ITEM_TYPE_DETAILS = 'details';
const ITEM_TYPE_CONTEXT = 'context';
const ITEM_TYPE_PRODUCT = 'product';

const sliderConfig = {
  autoHeight: false,
  slidesPerView: 'auto',
  spaceBetween: 0,
  autoplay: { delay: 4000 },
  loop: true,
};

class MyPromoBannerRotation extends PureComponent {
  /**
   * Generates the link of the teaser from the given item type.
   * @param item The item.
   * @return {Link} The generated link.
   */
  getGeneratedLinkByItem(item) {
    const { sitemap, dispatch } = this.props;
    switch (item.type) {
      case ITEM_TYPE_INFO:
        return new Link({ url: item.url, target: '_blank' });
      case ITEM_TYPE_DETAILS:
        return new Link({ url: item.promo.details });
      case ITEM_TYPE_CONTEXT:
        return new Link({ onClick: () => { dispatch(getContextAction(item.promo.context)); } });
      case ITEM_TYPE_PRODUCT: {
        const { targets } = item.promo;
        const target = targets[Object.keys(targets).find((key) => targets[key].length)][0];
        const targetType = camelCase(target.etype);
        return new Link({
          url: bindQueryParams(
            targetType === ENTITY_TYPE_TARIFFOPTION ?
              sitemap.MyTariffOptionDetailsRoute.url
              : sitemap.MyTariffDetailsRoute.url,
            { iid: target.iid },
          ),
        });
      }
      default:
        return new Link({ url: item.url });
    }
  }

  render() {
    const { banners } = this.props;
    const filteredBanners = banners.filter((b) => b.sections && b.sections.includes('banner'));
    const orderedBanners = filteredBanners.sort((a, b) => b.priority - a.priority);
    const isSingleSlide = orderedBanners.length === 1;
    if (!banners.length || !filteredBanners.length) {
      return null;
    }
    return (
      <GlobalSection
        className="MyPromoBannerRotation"
        theme="light"
        layout="contained"
        layoutSettings={{ adjacentBottom: true }}
      >
        <Slider
          pagination={!isSingleSlide}
          config={{ ...sliderConfig,
            autoplay: isSingleSlide ? false : sliderConfig.autoplay,
            loop: !isSingleSlide,
          }}
        >
          {orderedBanners.map((item, index) => (
            <SliderSlide key={index}>
              <Teaser
                type="image_banner"
                image={item.image}
                link={this.getGeneratedLinkByItem(item)}
                layout="half"
              />
            </SliderSlide>
          ))}
        </Slider>
      </GlobalSection>
    );
  }
}

const mapStateToProps = ({ user, site }) => ({
  banners: user.banners,
  sitemap: site.sitemap,
});

const mapDispatchToProps = dispatch => ({ dispatch });

MyPromoBannerRotation.defaultProps = {
  banners: [],
};

MyPromoBannerRotation.propTypes = {
  banners: PropTypes.array,
  sitemap: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MyPromoBannerRotation);
