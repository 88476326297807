import { compose } from 'redux';

import HoldOnForm
  from '../../../components/compositions/account/cancellation/HoldOnForm';
import initForm from '../../form/FormInitializer';
import FormConfigProps from '../../../model/form/FormConfigProps';

const component = compose(
  initForm(),
)(HoldOnForm);

export const id = 'holdOn';

/**
 * @return {FormConfig}
 */
export const mapStateToFormConfig = (state) => ({
  id,
  component,
  fieldMap: {},
  label: id,
  formConfigProps: new FormConfigProps({
    labelSubmit: state.ui.myCancellationFormCancelAnyway,
    withRequiredHint: false,
    isSubmitOptional: true,
  }),
});

export default mapStateToFormConfig;
