import QueueableRequest from './QueueableRequest';
import { EECC_ACTIVATION_INITIATE } from '../../config/api';
import { REQUEST_METHOD_POST } from '../../helpers/constants';
import { bindParamsToRoute } from '../../helpers/url';

/**
 *
 * For the EECC Requirements it is necessary to present the user some
 * contract-infos in ordering-process.
 *
 * This is the first of three needed requests for this feature
 *
 * https://confluence.db-n.com/pages/viewpage.action?pageId=48344534
 *
 */

export default class EECCActivationInitiateRequest extends QueueableRequest {
  constructor({ msisdn, ...requestBody }) {
    super(bindParamsToRoute(EECC_ACTIVATION_INITIATE, { msisdn }), {
      method: REQUEST_METHOD_POST,
      payload: requestBody,
    });
  }
}
