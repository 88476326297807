import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';

import FormConfigProps from '../../../model/form/FormConfigProps';
import Form from '../../basics/form/Form';
import FormFooter from './FormFooter';
import { preventDefault } from '../../../helpers/functional';
import Headline from '../../basics/text/TextHeadline';
import TextLink from '../../basics/text/TextLink';

class FormWrapper extends PureComponent {
  renderFooter() {
    const {
      config, onCancel, customSubmitButton, customCancelButton, hasRequiredFields,
    } = this.props;
    return (
      <FormFooter
        labelSubmit={config.labelSubmit}
        labelCancel={config.labelCancel}
        dataSubmit={config.dataSubmit}
        dataCancel={config.dataCancel}
        onCancel={onCancel}
        additionalButton={config.additionalButton}
        customSubmitButton={config.customSubmitButton || customSubmitButton}
        customCancelButton={config.customCancelButton || customCancelButton}
        isSubmitDisabled={config.isSubmitDisabled}
        isSubmitOptional={config.isSubmitOptional}
        hintSubmit={config.hintSubmit}
        withRequiredHint={config.withRequiredHint !== false && hasRequiredFields}
        asStack={config.withStackedFooter}
      />
    );
  }

  renderHeadline() {
    const { headline, num, goToStep, open, isEditable, id } = this.props;
    const statusModifier = open ? 'active' : isEditable ? 'editable' : 'inactive';
    if (!headline) return null;
    return (
      <div className={suitcss({ descendantName: 'headline', modifiers: [statusModifier] }, this)} >
        <Headline id={id} size="xs" element="h2">
          {num ?
            <span className={suitcss({ descendantName: 'orderNumber', modifiers: [statusModifier] }, this)}>
              {num}
            </span> : null}
            {headline}
        </Headline>
        {!open && isEditable &&
        <TextLink
          onClick={(e) => { e.preventDefault(); goToStep(); }}
          className={suitcss({}, this)}
        >
          ändern
        </TextLink>
        }
      </div>
    );
  }

  render() {
    const {
      className,
      children,
      withFooter,
      customSubmitButton,
      open,
      Preview,
      fieldMap,
      formValues,
      componentBefore,
    } = this.props;
    const onSubmit = preventDefault(() => this.props.onSubmit());
    return (
      <section className={suitcss({ className }, this)} >
        <Form onSubmit={!customSubmitButton ? onSubmit : null} >
          {componentBefore && componentBefore()}
          {this.renderHeadline()}
          {!open && Preview && <Preview fieldMap={fieldMap} formValues={formValues} />}
          {open && children}
          {open && withFooter && this.renderFooter()}
        </Form>
      </section>
    );
  }
}

FormWrapper.propTypes = {
  className: PropTypes.string,
  config: PropTypes.shape(FormConfigProps.propTypes),
  componentBefore: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  customSubmitButton: PropTypes.element,
  customCancelButton: PropTypes.element,
  withFooter: PropTypes.bool,
  hasRequiredFields: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.array,
  ]),
  open: PropTypes.bool,
  headline: PropTypes.string,
  num: PropTypes.number,
  goToStep: PropTypes.func,
  Preview: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
  ]),
  fieldMap: PropTypes.object,
  formValues: PropTypes.object,
  isEditable: PropTypes.bool,
  id: PropTypes.string,
};

FormWrapper.defaultProps = {
  config: {},
  open: true,
};

export default FormWrapper;
