import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import suitcss from '../../../../helpers/suitcss';

import FieldInput from '../../../basics/form/FieldInput';
import FieldSelectbox from '../../../basics/form/FieldSelectbox';
import FormHeader from '../../form/FormHeader';
import FormSection from '../../form/FormSection';

class MyBelatedMnpFormMsisdn extends PureComponent {

  render() {
    const {
      mnpHeadline,
      fieldMap,
      mnpCopy,
    } = this.props;

    return (
      <FormSection className={suitcss({}, this)} asGrid>
        <FormHeader headline={mnpHeadline} copy={mnpCopy} />
        <Field
          {...fieldMap.mnpMsisdn}
          component={FieldInput}
          utilities={['col12', 'mlCol6']}
          withToolTip
        />
        <div className={suitcss({ utilities: ['col6', 'sHidden'] })} />
        <Field
          {...fieldMap.mnpProviderKey}
          component={FieldSelectbox}
          utilities={['col12', 'mlCol6']}
          withToolTip
        />
      </FormSection>
    );
  }
}

MyBelatedMnpFormMsisdn.propTypes = {
  mnpHeadline: PropTypes.string.isRequired,
  mnpCopy: PropTypes.string.isRequired,
  fieldMap: PropTypes.object.isRequired,
};

export default MyBelatedMnpFormMsisdn;
