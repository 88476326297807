import { CONFIRM_ORDER } from '../actions/order/completed';

export default (state = {}, action) => {
  switch (action.type) {
    case CONFIRM_ORDER:
      return Object.assign(state, {
        [action.meta.identifier]: action.payload,
      });

    default:
      return state;
  }
};
