/**
 * @file
 * Listens for click events of its children and fires tracking actions with
 * regarding information
 */

/* globals window */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { closest, parents } from '../../helpers/dom';
import { trackClick } from '../../actions/tracking/event';
import { MYTRACKING_ATTRIBUTE } from '../../helpers/constants';

class TrackingClickDelegation extends PureComponent {
  constructor(...args) {
    super(...args);
    this.onClick = this.onClick.bind(this);
  }

  onClick(ev) {
    const getLinkPosition = (target) => {
      let linkPosition = 'unknown';

      if (closest(target, '.GlobalContent')) {
        linkPosition = 'content module';

      } else if (closest(target, '.NavigationMain')) {
        linkPosition = 'header menu';

      } else if (
        closest(target, '.NavigationTopBar')
        || closest(target, '.GlobalNotificationBar')) {
        linkPosition = 'header notification';

      } else if (closest(target, '.FooterMain')) {
        linkPosition = 'footer menu';
      }
      return linkPosition;
    };

    const getLinkTitle = (target) => {
      if (target.attributes && target.attributes['data-tracking-title']) {
        return target.attributes['data-tracking-title'].value;
      } else if (target.tagName === 'A' || target.tagName === 'DIV') {
        return target.innerText;

      } else if (target.tagName === 'IMG') {
        return target.alt;

      } else if (target.tagName === 'INPUT') {
        return target.labels[0].innerText;

      } else if (target.tagName === 'BUTTON') {
        return target.innerText;

      } else if (target.tagName === 'path' || target.tagName === 'svg') {
        return null;

      } else if (target.tagName === 'SPAN') {
        const getNearestLink = closest(target, 'a')
          || closest(target, 'button')
          || closest(target, 'span');
        return getNearestLink.innerText;

      } else if (target.tagName === 'SELECT') {
        return target.innerText;
      } else {
        return `Missing selection ${target.tagName}`;
      }
    };

    const getLinkUrl = (target) => {
      let linkUrl = target;
      if (target.tagName !== 'A') {
        const hasParentLink = closest(target, 'a');
        if (hasParentLink) {
          linkUrl = hasParentLink;
        }
      }
      return linkUrl.hasAttribute('href')
        ? { link_href: window.location.host + linkUrl.getAttribute('href') }
        : null;
    };

    const getLinkType = (target) => {
      switch (target.tagName) {
        case 'A':
          if (target.className.indexOf('--asButton') > -1) {
            return 'button';
          }
          return 'link';
        case 'IMG':
        case 'path':
        case 'svg':
          return 'image';
        case 'accordion':
          return 'accordion';
        case 'INPUT':
          if (target.checked) {
            return 'checkbox-enable';
          }
          return 'checkbox-disable';
        case 'SPAN':
        default:
          return 'link';
      }
    };

    const advancedTrackingValues = (target) => ({
      link_position: getLinkPosition(target),
      link_title: getLinkTitle(target),
      ...getLinkUrl(target),
      Link_type: getLinkType(target),
    });

    const { target } = ev;
    const targetSelector = 'a, button, [data-tracking-target], [data-mytracking]';
    const delegateTarget = closest(target, targetSelector, true);
    const trackingStatus = target.dataset && target.dataset.tracking;

    if (trackingStatus === 'off' || !delegateTarget) {
      return;
    }

    const parentNodes = parents(delegateTarget, '[data-tracking], [data-mytracking]', true);
    // iterate over parentNodes that contains tracking pixels and merge relevant
    // information
    // Empty object as fallback for browsers that do not support dataset.
    // Those browsers (<= IE10) are not included in our browser matrix so its okay to
    // let them fail silently
    const scopePixel = parentNodes.reduce((pixel, el) => ({
      ...TrackingClickDelegation.reduceTrackingPixel(el.dataset || {}),
      ...pixel,
    }), {});
    delete scopePixel.optionboxLabel;
    delete scopePixel.optionboxValue;
    delete scopePixel.pageName;
    delete scopePixel.tariffId;

    this.props.trackClick(Object.assign(advancedTrackingValues(target), scopePixel));
  }

  render() {
    const cleanProps = { ...this.props };
    delete cleanProps.trackClick;
    return (
      <div
        onClick={this.onClick}
        {...cleanProps}
      />
    );
  }
}

/**
 * Extracts tracking information from an object. It returns all tupels of the object
 * that matches the tracking property.
 * @param  {object} dataset Object with an tracking property
 * @return {object}         Object with tupels which keys starts with the tracking value
 */
TrackingClickDelegation.reduceTrackingPixel = (dataset) => {
  if (dataset.mytracking) {
    const pixel = {};
    pixel[MYTRACKING_ATTRIBUTE] = dataset.mytracking;
    return pixel;
  }

  const type = dataset.tracking;
  return Object.entries(dataset)
    .filter(entry => entry[0].startsWith(type))
    .reduce((p, entry) => ({ ...p, [entry[0]]: entry[1] }), {});

};

TrackingClickDelegation.propTypes = {
  trackClick: PropTypes.func.isRequired,
};

export default connect(null, { trackClick })(TrackingClickDelegation);
