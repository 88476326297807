/* global window, newrelic */
import { push } from 'react-router-redux';

import { replaceCart } from '../../../../actions/order/cart';
import { registerPromoCodes } from '../../../../actions/user/marketing';
import { fetchEntityById } from '../../../../actions/request/registry';
import Message from '../Message';
import BasarChatInputPanelPrice from './BasarChatInputPanelPrice';
import BasarChatInputPanelChoice from './BasarChatInputPanelChoice';
import BasarChatInputPanelGameOver from './BasarChatInputPanelGameOver';
import BasarChatInputPanelDataVolume from './BasarChatInputPanelDataVolume';
import BasarChatInputPanelAcceptDataVolume from './BasarChatInputPanelAcceptDataVolume';
import {
  MESSAGE_TYPE_DECLINE_OFFER,
  MESSAGE_TYPE_ACCEPT_OFFER,
  MESSAGE_TYPE_OFFER,
  MESSAGE_TYPE_EXIT,
  MESSAGE_TYPE_WAKE_UP_LOOP,
  MESSAGE_TYPE_INIT,
  MESSAGE_SENDER_USER,
  MESSAGE_TYPE_DATA_VOLUME, MESSAGE_TYPE_ACCEPT_DATA_VOLUME, MESSAGE_TYPE_DECLINE_DATA_VOLUME,
} from '../constants';
import AbstractChatBot from '../AbstractChatBot';
import { pickRandom } from '../../../../helpers/random';
import { MONTH, SECOND } from '../../../../helpers/date';
import { toDecimal, toFullCurrency } from '../../../../helpers/money';
import { getBundlePaymentFee, getWebEntityName } from '../../../../helpers/entity';
import { setItem, getItem, STORAGE_TYPE_COOKIE } from '../../../../helpers/storage';
import { transformDealVectors } from './BasarModelTransformator';

const STORAGE_KEY = 'gmyp';

const TRACKING_TYPE_OFFER = 'user_offer_{id}';
const TRACKING_TYPE_ACCEPT = 'user_deal_accept';
const TRACKING_TYPE_DECLINE = 'user_deal_decline';
const TRACKING_TYPE_EXIT = 'user_leave_no_deal';
const TRACKING_TYPE_DEAL_YES = 'bot_deal_yes';
const TRACKING_TYPE_DEAL_NO = 'bot_deal_no';
const TRACKING_TYPE_CHOOSE_DATA_LIMIT = 'choose_data_limit';
const TRACKING_TYPE_CONFIRM_DATA_LIMIT = 'confirm_data_limit';
const TRACKING_TYPE_DECLINE_DATA_LIMIT = 'decline_data_limit';


/**
 * Registers the promo code, adds the items to the cart
 * and then redirects the user.
 *
 * @param promoCode
 * @param tariff
 * @param hardware
 */
const goToDeal = (promoCode, tariff, hardware = null) => async (dispatch, getState) => {
  const { site } = getState();
  dispatch(registerPromoCodes([promoCode]));
  const entities = await Promise.all([
    dispatch(fetchEntityById(tariff)),
    hardware && dispatch(fetchEntityById(hardware)),
  ].filter(Boolean));
  dispatch(replaceCart(entities));
  dispatch(push(site.sitemap.ShoppingCartRoute.url));
};

/**
 * generate tracking pixel
 *
 * @param type
 * @param tariffId
 * @param text
 * @param offerValue (optional)
 */
const getTrackingPixel = (type, tariff, hardware, text, offerValue, group, dataLimit) => ({
  chat_name: 'GMUP',
  chat_interaction: 1,
  chat_interaction_type: type,
  chat_offer_value: offerValue,
  chat_offer_tariff_id: `${tariff.iid}${hardware ? `+${hardware.iid}` : ''}`,
  chat_interaction_text: text.replace('{PRICE}', offerValue),
  chat_tariff_value: !hardware
    ? toDecimal(tariff.paymentFee)
    : toDecimal(getBundlePaymentFee({ tariff, hardware })),
  chat_test_group: group,
  chat_data_limit: dataLimit,
});

/**
 * fire tracking
 *
 * @param type
 * @param tariffId
 * @param text
 * @param offerValue (optional)
 */
const trackChatEvent = (type, tariff, hardware, text, offerValue, group, dataLimit) => {
  if (window && window.utag) {
    window.utag.link(getTrackingPixel(type, tariff, hardware, text, offerValue, group, dataLimit));
  }
  if (window && typeof window.newrelic === 'object') {
    newrelic.addPageAction(
      'gmupOffer',
      getTrackingPixel(type, tariff, hardware, text, offerValue, group, dataLimit),
    );
  }
};

/**
 * This is a chatbot written for the "give me your price" sales promotion.
 * The message flow and bot behaviour is defined here {@link https://confluence.db-n.com/x/34v3}.
 *
 * The bot responds to user messages and also changes the input panel depending
 * on the user actions. For example once the user made an offer that is accepted, the bot
 * will perform a deal up or down and then change the input panel style to give the user
 * a chance to accept or decline the deal.
 *
 * Wordings:
 *   an "offer" is a price offer made by the user
 *   a "deal" is the bot's response (counter offer) to a user's offer
 */
class BasarChatBot extends AbstractChatBot {
  /**
   * @param {Object} params - wordings and control-logic received from the CMS
   * @param {function} dispatch - give the bot the power to dispatch actions
   */
  constructor(params, dispatch, setTariff) {
    super(params.name, params.icon);
    // total number of price offers made by bot so far
    this.userOfferCount = 0;
    // collection of the user's previous offers
    this.offersByUser = [];
    // the initial input panel style is an empty component
    this.chatInputPanelClass = null;
    // last deal offered by the chatbot to the user
    this.lastMatchingDealVector = null;
    // when did the chatbot receive its final message
    this.lastMessageTimestamp = Date.now();
    // allows us to dispatch actions
    this.dispatch = dispatch;
    // signals if the bot is listening to offers
    this.isListening = true;
    // The function handle to change the tariff of the view
    this.setTariff = setTariff;

    // unwrap CMS params
    this.params = params;
    this.isBasarFullyClosed = !params.isBasarOpen;
    this.name = params.name || 'Bot';
    this.messages = params.messages;
    this.subjects = params.subjects;
    this.maxOffersAllowed = params.messages.rounds.length;
  }

  /**
   * Transforms the new model with the group and choosable tariff feature to
   * the old static one. In the future, we plan on only using the new data-model,
   * which would make the data transformation obsolete.
   */
  transformModel() {
    const storedData = getItem(STORAGE_TYPE_COOKIE, STORAGE_KEY) || {};
    this.dealVectors = transformDealVectors(
      this.params.dealVectors,
      storedData.group || this.group,
      storedData.price || this.subjects.tariff.paymentFee,
      this.params.code,
    );
    this.minAcceptableUserOffer = this.dealVectors[0].offerInterval[0];
    this.maxAcceptableUserOffer = this.dealVectors[this.dealVectors.length - 1].offerInterval[1];
  }

  isGameOver() {
    return this.userOfferCount >= this.maxOffersAllowed;
  }

  getSubject(type) {
    const subjects = this.subjects;
    return subjects && subjects[type];
  }

  trackEvent(type, offerValue) {
    const { tracking } = this.params;
    trackChatEvent(
      type,
      this.getSubject('tariff'),
      this.getSubject('hardware'),
      tracking[type] || '',
      offerValue ? toDecimal({ unit: offerValue * 100 }) : null,
      this.group,
      this.getSubject('tariff').name,
    );
  }

  setSubject(subject) {
    this.subjects = subject;
  }

  setChatInputPanelClass(componentClass) {
    this.isListening = componentClass === BasarChatInputPanelPrice;
    this.chatInputPanelClass = componentClass;
  }

  /**
   * @param {string} text
   * @return {Message}
   */
  makeMessage(text) {
    return new Message(this.name, {
      text: Array.isArray(text) ? pickRandom(text) : text,
    });
  }

  handleDealDecline() {
    if (this.isGameOver()) {
      return this.shutdown(true);
    }
    this.setChatInputPanelClass(BasarChatInputPanelPrice);
    return this.makeMessage(this.messages.rounds[this.userOfferCount].opening);
  }

  handleUserOffer(message) {
    const offerByUser = message.payload.offerValue;
    const previousOfferByUser = this.offersByUser[this.offersByUser.length - 1];

    if (!offerByUser) { // just an extra safeguard
      this.setChatInputPanelClass(BasarChatInputPanelPrice);
      return this.makeMessage(this.messages.unparsable);
    }

    if (offerByUser === previousOfferByUser) {
      return this.makeMessage(this.messages.unchangedOffer);
    }

    const reply = [];
    // increment counter at this point as the user has made a new valid offer
    this.userOfferCount++;
    this.trackEvent(TRACKING_TYPE_OFFER.replace('{id}', this.userOfferCount), offerByUser);
    const specialPriceText = this.messages.specialPrices[offerByUser];
    if (specialPriceText) {
      reply.push(this.makeMessage(specialPriceText));
    }

    const isOfferTooLow = offerByUser < this.minAcceptableUserOffer;
    const isOfferTooHigh = offerByUser > this.maxAcceptableUserOffer;
    const hasOneLastChance = isOfferTooHigh && this.isGameOver();

    if ((isOfferTooLow || isOfferTooHigh) && !hasOneLastChance) {
      if (isOfferTooLow) {
        if (!previousOfferByUser || previousOfferByUser > this.maxAcceptableUserOffer) {
          reply.push(this.makeMessage(this.messages.lowOffer));
        } else if (offerByUser < previousOfferByUser) {
          reply.push(this.makeMessage(this.messages.lowOfferBecameLower));
        } else {
          reply.push(this.makeMessage(this.messages.lowOfferBecameHigher));
        }
      } else if (isOfferTooHigh) {
        if (!previousOfferByUser || previousOfferByUser < this.minAcceptableUserOffer) {
          reply.push(this.makeMessage(this.messages.highOffer));
        } else if (offerByUser < previousOfferByUser) {
          reply.push(this.makeMessage(this.messages.highOfferBecameLower));
        } else {
          reply.push(this.makeMessage(this.messages.highOfferBecameHigher));
        }
      }

      if (this.isGameOver()) {
        const finalMessage = this.shutdown(true);
        reply.push(finalMessage);
        this.trackEvent(TRACKING_TYPE_DEAL_NO, offerByUser);
      } else {
        reply.push(
          this.makeMessage(this.messages.rounds[this.userOfferCount].opening),
        );
      }

    } else {
      this.setChatInputPanelClass(BasarChatInputPanelChoice);
      const matchingDealVector = hasOneLastChance
        ? this.dealVectors[this.dealVectors.length - 1]
        : this.dealVectors.find(
          v => v.offerInterval[0] <= offerByUser && offerByUser <= v.offerInterval[1],
        );
      const dealText = pickRandom(this.messages.suggestDeal)
        .replace(
          '{PRICE}',
          toFullCurrency({ unit: (matchingDealVector.deal * 100), currency: 'EUR' }),
        );

      const preDealText = matchingDealVector.deal === offerByUser
        ? this.messages.offerDealExact
        : matchingDealVector.deal < offerByUser
          ? this.messages.offerDealDown
          : this.messages.offerDealUp;

      this.lastMatchingDealVector = matchingDealVector;
      reply.push(
        this.makeMessage(preDealText),
        this.makeMessage(dealText),
      );
      this.trackEvent(TRACKING_TYPE_DEAL_YES, offerByUser);
    }

    // finally add the new offer to the list of previous offers
    this.offersByUser.push(offerByUser);

    return reply;
  }

  handleWakeUp() {
    const secondsSinceLastMessage = parseInt((Date.now() - this.lastMessageTimestamp) / SECOND, 10);
    const inactivityText = this.messages.inactivity[secondsSinceLastMessage];
    if (inactivityText && !this.isGameOver()) {
      return this.makeMessage(inactivityText);
    }
  }

  handleInit() {
    const storedData = getItem(STORAGE_TYPE_COOKIE, STORAGE_KEY) || {};
    if (storedData.group || storedData.subjects) {
      this.group = storedData.group;
      this.subjects = storedData.subjects;
    } else {
      this.group = pickRandom(Object.keys(this.params.dealVectors));
      setItem(
        STORAGE_TYPE_COOKIE,
        STORAGE_KEY,
        { ...storedData, group: this.group },
        {
          expires: new Date(Date.now() + MONTH),
          path: '/',
        });
    }
    const reply = [];
    const welcomeText = storedData && storedData.dayLastPlayed
      ? this.messages.welcomeBack
      : this.messages.welcomeFirstTime;
    reply.push(this.makeMessage(welcomeText));
    if (this.isBasarFullyClosed) {
      this.setChatInputPanelClass(BasarChatInputPanelGameOver);
      reply.push(
        this.makeMessage(this.messages.basarClosedCompletely),
        this.makeMessage(this.messages.basarClosedForToday),
      );
      return reply;
    }
    const isForceNewGame = storedData.dayLastPlayed !== (new Date()).getDate();
    if (isForceNewGame) {
      reply.push(this.makeMessage(this.messages.chooseTariff));
      this.setChatInputPanelClass(BasarChatInputPanelDataVolume);
    } else {
      this.transformModel();
      const matchingDealVector = this.dealVectors.find(v => v.code === storedData.promoCode);
      if (storedData.promoCode) {
        reply.push(this.makeMessage(
          pickRandom(this.messages.lastDeal).replace(
            '{PRICE}',
            toFullCurrency({ unit: (matchingDealVector.deal * 100), currency: 'EUR' }),
          ),
        ));
        this.lastMatchingDealVector = matchingDealVector;
        this.userOfferCount = this.maxOffersAllowed;
        this.trackEvent(TRACKING_TYPE_DEAL_YES, matchingDealVector.deal);
        this.setChatInputPanelClass(BasarChatInputPanelChoice);

      } else {
        this.lastMatchingDealVector = matchingDealVector;
        reply.push(this.makeMessage(this.messages.basarClosedForToday));
        this.setChatInputPanelClass(BasarChatInputPanelGameOver);
      }
    }
    return reply;
  }

  handleNegotiationStart() {
    const reply = [];
    reply.push(this.makeMessage(this.messages.rounds[0].opening));
    this.setChatInputPanelClass(BasarChatInputPanelPrice);
    return reply;
  }

  async handleAccept() {
    await this.dispatch(goToDeal(
      this.lastMatchingDealVector.code,
      this.subjects.tariff ? this.subjects.tariff.eid : undefined,
      this.subjects.hardware ? this.subjects.hardware.eid : undefined,
    ));
    this.handleExit();
    this.shutdown();
  }

  /*
    handle exiting chat eg. Some components may need a callback for handling a
    triggered event that cause the chat to close / exit
   */

  handleExit(url) {
    const { onExit } = this.params;
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(() => {
      if (url) {
        this.dispatch(push(url));
      }
      if (typeof onExit === 'function') {
        onExit();
      }
    }, 500);
  }

  async handleDataVolume(message) {
    this.setChatInputPanelClass(BasarChatInputPanelAcceptDataVolume);
    await this.setTariff(message.payload.data);
    const reply = [];
    reply.push(this.makeMessage(pickRandom(this.messages.tariffVolumeChosen)
      .replace('{TARIFF}', getWebEntityName(this.subjects.tariff))));
    this.trackEvent(TRACKING_TYPE_CHOOSE_DATA_LIMIT);
    return reply;
  }

  handleAcceptDataVolume() {
    const reply = [];
    this.transformModel();
    reply.push(this.makeMessage(pickRandom(this.messages.productInfo)
      .replace('{PRICE}', toFullCurrency(this.subjects.tariff.paymentFee))
      .replace('{TARIFF}', getWebEntityName(this.subjects.tariff)),
    ));
    this.trackEvent(TRACKING_TYPE_CONFIRM_DATA_LIMIT);
    reply.push(...this.handleNegotiationStart());
    return reply;
  }

  handleDeclineDataVolume() {
    const reply = [];
    this.setTariff(null);
    reply.push(this.makeMessage(pickRandom(this.messages.reinitAfterDecline)));
    this.setChatInputPanelClass(BasarChatInputPanelDataVolume);
    this.trackEvent(TRACKING_TYPE_DECLINE_DATA_LIMIT);
    return reply;
  }

  /**
   * @override
   */
  handle(message) {
    if (message.sender === MESSAGE_SENDER_USER) {
      this.lastMessageTimestamp = Date.now();
    }

    switch (message.payload.type) {
      case MESSAGE_TYPE_INIT:
        return this.handleInit();
      case MESSAGE_TYPE_ACCEPT_OFFER:
        this.trackEvent(TRACKING_TYPE_ACCEPT, this.lastMatchingDealVector.deal);
        return this.handleAccept();
      case MESSAGE_TYPE_DECLINE_OFFER:
        this.trackEvent(TRACKING_TYPE_DECLINE, this.lastMatchingDealVector.deal);
        return this.handleDealDecline();
      case MESSAGE_TYPE_WAKE_UP_LOOP:
        return this.handleWakeUp();
      case MESSAGE_TYPE_OFFER:
        return this.handleUserOffer(message);
      case MESSAGE_TYPE_DATA_VOLUME:
        return this.handleDataVolume(message);
      case MESSAGE_TYPE_ACCEPT_DATA_VOLUME:
        return this.handleAcceptDataVolume();
      case MESSAGE_TYPE_DECLINE_DATA_VOLUME:
        return this.handleDeclineDataVolume();
      case MESSAGE_TYPE_EXIT: {
        this.trackEvent(
          TRACKING_TYPE_EXIT,
          this.lastMatchingDealVector ? this.lastMatchingDealVector.deal : undefined,
        );
        const exitUrl = this.params.routes && this.params.routes.exitRoute;
        return this.handleExit(exitUrl);
      }
    }
  }

  /**
   * Will shutdown the chatbot.
   *
   * @param {boolean} withFinalMessage - whether or not to display a final message
   *     and render a different input panel
   */
  shutdown(withFinalMessage) {
    const promoCode = (this.lastMatchingDealVector || {}).code;
    setItem(STORAGE_TYPE_COOKIE, STORAGE_KEY, {
      dayLastPlayed: (new Date()).getDate(), // day of the month
      promoCode,
      group: this.group,
      subjects: this.subjects,
    }, {
      expires: new Date(Date.now() + MONTH),
      path: '/',
    });

    if (withFinalMessage) {
      this.setChatInputPanelClass(BasarChatInputPanelGameOver);
      return this.makeMessage(this.messages.basarClosedForToday);
    }
  }
}

export default BasarChatBot;
