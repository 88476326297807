import PageModule from './PageModule';
import component from '../../containers/cancellation/PresalesCancellationForm';

class CancellationPresalesFormModule extends PageModule {
  constructor(moduleData) {
    super(component, moduleData);
  }
}

export default CancellationPresalesFormModule;

