/* global document */
import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import matchMediaConnector from '../../../containers/service/ServiceMatchMedia';
import connectViewport from './ServiceViewportConnector';
import { CUSTOM_EVENT_EQUAL_RESIZE } from '../../../helpers/constants';

const getMatchMediaSuffix = ({ isMediaM, isMediaL }) => {
  if (isMediaL) { return 'l'; }
  if (isMediaM) { return 'm'; }
  return 's';
};

const getTargetGroupMaxHeight = (group) => (
  group.reduce((sum, target) => Math.max(sum, target.offsetHeight), 0)
);

class ServiceEqualizer extends PureComponent {

  constructor(props, context) {
    super(props, context);
    this.onResize = this.onResize.bind(this);
  }

  componentDidMount() {
    document.addEventListener(CUSTOM_EVENT_EQUAL_RESIZE, this.onResize, false);
    this.renderTargets();
  }

  componentDidUpdate(prevProps) {
    this.resize(prevProps);
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
    document.removeEventListener(CUSTOM_EVENT_EQUAL_RESIZE, this.onResize, false);
  }

  onResize() {
    this.resize(this.props);
  }

  resize(props) {
    clearTimeout(this.timeout);
    this.resetPreviousTargets(`data-eq-group-${getMatchMediaSuffix(props)}`);
    this.renderTargets();
  }

  resetPreviousTargets(selectorAttr) {
    const targets = [...document.querySelectorAll(`[${selectorAttr}]`)];
    if (targets && targets.length) {
      for (let i = 0; i < targets.length; i++) {
        targets[i].style.height = null;
      }
    }
  }

  renderTargets() {
    const selectorAttr = `data-eq-group-${getMatchMediaSuffix(this.props)}`;
    const targets = [...document.querySelectorAll(`[${selectorAttr}]`)];
    if (targets.length) {
      const sortedGroups = targets.reduce((obj, el) => {
        const key = el.getAttribute(selectorAttr);
        if (!obj[key]) {
          obj[key] = []; // eslint-disable-line
        }
        obj[key].push(el);
        return obj;
      }, {});

      Object.keys(sortedGroups).map(key => {
        const maxHeight = `${getTargetGroupMaxHeight(sortedGroups[key])}px`;
        for (let i = 0; i < sortedGroups[key].length; i++) {
          sortedGroups[key][i].style.height = maxHeight;
        }
        return key;
      });
    }
  }

  render() {
    return this.props.children;
  }
}

ServiceEqualizer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default compose(
  matchMediaConnector(['isMediaM', 'isMediaL']),
  connectViewport({ listenTo: ['client'] }),
)(ServiceEqualizer);
