import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { pascalCase } from '../../../../helpers/str';
import { decimalFormatter } from '../../../../helpers/money';

import connectUI from '../../../basics/ui/UIConnector';
import UICircularProgressBar from '../../../basics/ui/UICircularProgressBar';

class DashboardStatusMeter extends PureComponent {

  constructor(props, context) {
    super(props, context);
    const isGigaBytes = props.valueType === 'gb';
    this.state = {
      value: isGigaBytes ? props.value / 100 : props.value,
      valueMax: isGigaBytes ? props.valueMax / 100 : props.valueMax,
    };
  }

  getPostfix() {
    const { ui, valueType } = this.props;
    return ui[valueType] || '';
  }

  getCopy() {
    const { ui, type, valueType } = this.props;
    const { valueMax } = this.state;
    const copy = ui[`consumptionsSummary${pascalCase(type)}`] || '';
    const postfix = this.getPostfix();
    const spacer = postfix !== '' ? ' ' : '';
    const value = valueType === 'gb' ? `${decimalFormatter().format(valueMax)}${spacer}${postfix}` : `${valueMax}${spacer}${postfix}`;
    return copy.replace('{VALUE_MAX}', value);
  }

  render() {
    const { delay, inverted, valueType } = this.props;
    const { value, valueMax } = this.state;
    const isFloatingValue = valueType === 'gb';
    return (
      <UICircularProgressBar
        value={value}
        valueMax={valueMax}
        inverted={inverted}
        postfix={this.getPostfix()}
        copy={this.getCopy()}
        delay={delay}
        floatingValues={isFloatingValue}
        shouldAnimateFrom
      />
    );
  }
}

DashboardStatusMeter.propTypes = {
  type: PropTypes.oneOf(['data', 'sms', 'min', 'unit', 'volume', 'phone_sms']).isRequired,
  value: PropTypes.number.isRequired,
  valueMax: PropTypes.number.isRequired,
  valueType: PropTypes.oneOf(['', 'mb', 'gb', 'quantity']),
  inverted: PropTypes.bool,
  delay: PropTypes.number,
  ui: PropTypes.shape({
    mb: PropTypes.string,
    gb: PropTypes.string,
    consumptionsSummaryData: PropTypes.string.isRequired,
    consumptionsSummaryPhone: PropTypes.string.isRequired,
    consumptionsSummaryPhoneSms: PropTypes.string.isRequired,
    consumptionsSummarySms: PropTypes.string.isRequired,
  }).isRequired,
};

DashboardStatusMeter.defaultProps = {
  delay: 1000,
};

export default connectUI({
  // careful changing these keys! they are renamed for some magic that happens above.
  mb: 'guiSymbolMegabyte',
  gb: 'guiSymbolGigabyte',
})(DashboardStatusMeter);
