/**
 * This class
 *
 * @param {Object} mountPoint - the node where our application is mounted
 * @param {Object} reduxStore - the redux store
 */
class Application {
  constructor(mountPoint, reduxStore) {
    this._mountPoint = mountPoint;
    this._reduxStore = reduxStore;
  }

  getMountPoint() {
    return this._mountPoint;
  }

  getStore() {
    return this._reduxStore;
  }
}

export default Application;
