import React from 'react';
import PropTypes from 'prop-types';
import * as msisdnStep from './formConfigMsisdn';
import FormManager from '../../form/FormManager';
import GlobalSection from '../../../components/basics/global/GlobalSection';

const RegistrationForm = (props) => {
  const { location } = props;

  // this form has no success-route, see comments in formConfigMsisdn makeSubmit
  return (
    <GlobalSection>
      <FormManager
        form={RegistrationForm.formName}
        location={location}
        steps={[msisdnStep]}
        stepProps={props}
        withFooter
      />
    </GlobalSection>
  );
};

/**
 * required by tracking!
 */
RegistrationForm.formName = 'registration';

RegistrationForm.propTypes = {
  location: PropTypes.object.isRequired,
};

export default RegistrationForm;
