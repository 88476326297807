import PageModule from './PageModule';
import component from '../../containers/account/inbox/MyInbox';

/**
 * Displays the inbox with invoices / EVN and messages.
 * @see https://confluence.db-n.com/x/MYTr
 */
class MyInboxModule extends PageModule {
  constructor(moduleData) {
    super(component, moduleData);
  }
}

export default MyInboxModule;

