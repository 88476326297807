/* global window */

export default class LogEntry {
  /**
   * Logs a message to the graylog
   *
   * @see https://confluence.db-n.com/x/HqL3
   *
   * @param {('info'|'warning'|'error')} logLevel
   * @param {LogContext} context - data about the application state
   *     at the time the log entry was created.
   * @param {string} message - the message to be logged, e.g. a text that was displayed to the user
   * @param {object} additionalData
   */
  constructor(logLevel, context, message, additionalData) {
    this.logLevel = logLevel;
    this.message = message;
    this.additionalData = additionalData;
    this.uri = process.browser ? window.location.href : undefined;
    this.context = context;
  }

  /**
   * @return {LogContext}
   */
  getContext() {
    return this.context;
  }
}

LogEntry.LOG_LEVEL_ERROR = 'error';
LogEntry.LOG_LEVEL_WARNING = 'warning';
LogEntry.LOG_LEVEL_INFO = 'info';
