import PageModule from './PageModule';

/**
 * Fallback module that is used if a module is defined in the cms but
 * not in the frontend yet. This avoids frontend crashes resulting from
 * accessing or rendering a module in the module map that is undefined.
 */
class BlankModule extends PageModule {
  constructor(rawModule) {
    const component = () => null; // dummy component will simply render null.
    super(component, rawModule);
  }
}

export default BlankModule;
