import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as loginActions from '../../actions/user/login';
import suitcss from '../../helpers/suitcss';
import FormManager from './FormManager';
import Headline from '../../components/basics/text/TextHeadline';
import LoginRequest from '../../model/requests/LoginRequest';

import * as passwordConfig from '../account/login/formConfigPassword';

const componentName = 'LoginForm';
class SimpleLoginForm extends PureComponent { // eslint-disable-line
  render() {
    const { ui, receiveCredentials, onLoginSuccess } = this.props;

    const onAfterSubmitSuccess = (submitResult) => {
      const { response, formValues, fieldMap } = submitResult;
      const isPersist = !!formValues[fieldMap.isPersist.name];
      receiveCredentials(response, isPersist);
      onLoginSuccess(submitResult);
    };

    const getSubmitRoute = (fieldMap, finalizedFormValues) => {
      const { msisdn, password } = finalizedFormValues;
      return new LoginRequest(msisdn, password);
    };

    return (
      <div className={suitcss({ componentName, modifiers: ['widget'] })}>
        <Headline size="l" inverted embedded>{ui.myLoginLayerHeadline}</Headline>
        <FormManager
          form={SimpleLoginForm.formName}
          stepProps={{ withoutBorder: true }}
          onAfterSubmitSuccess={onAfterSubmitSuccess}
          submitRoute={getSubmitRoute}
          steps={[passwordConfig]}
          withFooter
        />
      </div>
    );
  }
}

/**
 * required by tracking!
 */
SimpleLoginForm.formName = 'simpleLoginForm';

SimpleLoginForm.propTypes = {
  onLoginSuccess: PropTypes.func.isRequired,
  receiveCredentials: PropTypes.func.isRequired,
  ui: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  ui: state.ui,
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    receiveCredentials: loginActions.receivedCredentials,
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SimpleLoginForm);
