/* global document */
import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { showMyESimInfoDialog, showMyESimStatusDialog } from '../../../actions/dialog/eSimActions';
import { showSimArticleListDialog } from '../../../actions/dialog/misc';
import * as requestActions from '../../../actions/request/registry';
import GlobalSection from '../../../components/basics/global/GlobalSection';
import {
  INSTALLED,
  MARKET_PREPAID,
  SCOPE_MYSIMCARD_ESIM,
  STATUS_PREACTIVE,
} from '../../../helpers/constants';
import { isScopeAllowed } from '../../../helpers/scope';
import Copy from '../../../components/basics/text/TextCopy';
import Headline from '../../../components/basics/text/TextHeadline';
import suitcss from '../../../helpers/suitcss';
import { eSimInformationShape, eSimQrShape } from '../../../propTypes/eSim';
import MyESimActivationComposition from '../../../components/compositions/account/eSim/MyESimActivation';

class MyESimActivation extends PureComponent {
  constructor(...args) {
    super(...args);
    this.onClickSimRepLink = this.onClickSimRepLink.bind(this);
  }

  componentDidMount() {
    const {
      fetchSimCardESimInformation,
      fetchSimCardESimQR,
      fetchESimStatus,
      isUserESimEnabled,
      showSimArticleList,
      isPrepaid,
      isTypeESim,
    } = this.props;
    if (
      isUserESimEnabled &&
      isTypeESim
    ) {
      fetchSimCardESimInformation();
      fetchSimCardESimQR({ size: 100 });
    }

    if (!isPrepaid && isTypeESim) {
      fetchESimStatus();
    }

    this.showESimStatusDialog();

    const deviceCheckLink = document.querySelector('.deviceCheckLink');
    if (deviceCheckLink) {
      deviceCheckLink.addEventListener('click', showSimArticleList);
    }
    const simRepLink = document.querySelector('.simReplLink');
    if (simRepLink) {
      simRepLink.addEventListener('click', this.onClickSimRepLink);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.eSimData.status.esimProvisionStatus &&
      this.props.eSimData.status.esimProvisionStatus
      !== prevProps.eSimData.status.esimProvisionStatus) {
      this.showESimStatusDialog();
    }
  }

  componentWillUnmount() {
    const { showSimArticleList } = this.props;
    const deviceCheckLink = document.querySelector('.deviceCheckLink');
    if (deviceCheckLink) {
      deviceCheckLink.removeEventListener('click', showSimArticleList);
    }
    const simRepLink = document.querySelector('.simReplLink');
    if (simRepLink) {
      simRepLink.removeEventListener('click', this.onClickSimRepLink);
    }
  }

  onClickSimRepLink() {
    const { showESimStatusDialog } = this.props;
    showESimStatusDialog(null, true);
  }

  eSimStatus() {
    const { user, eSimData, isTypeESim } = this.props;
    if ((isTypeESim)
      || (eSimData && !!Object.keys(eSimData.status).length)) {
      const status = user.contractData.simCard.status === STATUS_PREACTIVE
        ? user.contractData.simCard.status
        : (eSimData.status.esimProvisionStatus ||
          user.contractData.simCard.esimStatus.esimProvisionStatus);
      switch (status) {
        case STATUS_PREACTIVE:
          return 'IN_PROGRESS';
        case INSTALLED:
          return 'INSTALLED';
        default:
          return 'ELIGIBLE';
      }
    } else {
      return 'UNELIGIBLE';
    }
  }

  showESimStatusDialog() {
    const { showESimStatusDialog } = this.props;
    const eSimStatus = this.eSimStatus();
    if (eSimStatus !== 'ELIGIBLE') showESimStatusDialog(eSimStatus);
  }

  renderInfoCopy() {
    const { ui } = this.props;
    const deviceCheckLink = `<span class="deviceCheckLink u-colorPrimary u-weightBold">${
      ui.myEsimActivationInfoDeviceCheckLink
    }</span>`;
    const simReplLink = `<span class="simReplLink u-colorPrimary u-weightBold">${
      ui.myEsimActivationInfoSimRepl
    }</span>`;
    const infoCopy = ui.myEsimActivationInfoSubline1
      .replace('{DEVICE_CHECK}', deviceCheckLink)
      .replace('{SIM_REPLACEMENT}', simReplLink);
    return (
      <Copy raw className={suitcss({ utilities: ['marginBottomM'] })}>
        {infoCopy}
      </Copy>
    );
  }

  render() {
    const {
      eSimData,
      ui,
      dispatch,
      user,
      fetchESimStatus,
      showESimInfoDialog,
      statusRefresh,
    } = this.props;
    const eSimStatus = this.eSimStatus();
    const showESimActivationContent = eSimStatus === 'ELIGIBLE';
    return (
      <Fragment>
        {showESimActivationContent && (
          <MyESimActivationComposition
            fetchESimStatus={fetchESimStatus}
            user={user}
            eSimData={eSimData}
            dispatch={dispatch}
            showESimInfoDialog={showESimInfoDialog}
            statusRefresh={statusRefresh}
          />
        )}
        <GlobalSection>
          <Headline size="s" embedded utilities={['weightBold', 'marginBottomS']}>
            {ui.myEsimActivationInfoHeadline}
          </Headline>
          <Copy raw className={suitcss({ utilities: ['marginBottomM'] })}>
            {ui.txtMyEsimActivationSetupTime}
          </Copy>
          {this.renderInfoCopy()}
          <Copy raw>{ui.txtMyEsimActivationCustomerService}</Copy>
        </GlobalSection>
      </Fragment>
    );
  }
}

MyESimActivation.propTypes = {
  fetchSimCardESimInformation: PropTypes.func.isRequired,
  fetchSimCardESimQR: PropTypes.func.isRequired,
  fetchESimStatus: PropTypes.func.isRequired,
  showSimArticleList: PropTypes.func,
  showESimInfoDialog: PropTypes.func,
  showESimStatusDialog: PropTypes.func,
  eSimData: PropTypes.shape({
    information: eSimInformationShape.isRequired,
    qr: eSimQrShape.isRequired,
    status: PropTypes.object,
  }),
  isUserESimEnabled: PropTypes.bool,
  ui: PropTypes.object,
  dispatch: PropTypes.func,
  user: PropTypes.object,
  statusRefresh: PropTypes.bool,
  isPrepaid: PropTypes.bool,
  isTypeESim: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => ({
  fetchSimCardESimInformation: () => dispatch(requestActions.fetchSimCardESimInformation()),
  fetchSimCardESimQR: (size) => dispatch(requestActions.fetchSimCardESimQR({ size })),
  fetchESimStatus: () =>
    dispatch(requestActions.fetchSimCardESimStatus(null, true)),
  showSimArticleList: () => dispatch(showSimArticleListDialog()),
  showESimInfoDialog: (type) => dispatch(showMyESimInfoDialog(type)),
  showESimStatusDialog: (status, withClosAction) =>
    dispatch(showMyESimStatusDialog(status, withClosAction)),
  dispatch,
});

const mapStateToProps = (state) => ({
  ui: state.ui,
  eSimData: state.user.eSim,
  isUserESimEnabled: isScopeAllowed(state.user.scope, SCOPE_MYSIMCARD_ESIM),
  sitemap: state.site.sitemap,
  user: state.user,
  statusRefresh: state.user.eSim.statusRefresh,
  isPrepaid: state.user.market === MARKET_PREPAID,
  isTypeESim: state.user.contractData && state.user.contractData.simCard.simType === 'E_SIM',
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MyESimActivation);
