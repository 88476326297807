/* global window */
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import browserHistory from 'react-router/lib/browserHistory';
import thunkMiddleware from 'redux-thunk';

import reducers from '../reducers/index';
import loaderMiddleware from '../middlewares/loader';
import storageMiddleware from '../middlewares/storage';
import trackingMiddleware from '../middlewares/tracking';
import miscMiddleware from '../middlewares/misc';
import { fetchInitial } from '../actions/request/initial';

const makeLoaderHotMiddleware = () => {
  let nextMiddleware = loaderMiddleware;
  const hotMiddleware = (...args1) => (...args2) => (...args3) => (
    nextMiddleware(...args1)(...args2)(...args3)
  );

  // accept must be a static string to work, otherwise it will not trigger updates
  module.hot.accept('../middlewares/loader', () => {
    // eslint-disable-next-line global-require
    nextMiddleware = require('../middlewares/loader').default;
  });
  return hotMiddleware;
};

const defaultOptions = {
  reducers: {},
};

export default async (initialState = {}, options = defaultOptions) => {

  if (!initialState.routing && process.browser) {
    // eslint-disable-next-line no-param-reassign
    initialState = {
      ...initialState,
      routing: {
        locationBeforeTransitions: browserHistory.getCurrentLocation(),
      },
    };
  }

  const store = createStore(
    combineReducers({ ...reducers, ...options.reducers }),
    initialState,
    compose(
      applyMiddleware(
        thunkMiddleware,
        trackingMiddleware,
        miscMiddleware,
        routerMiddleware(browserHistory),
        module.hot ? makeLoaderHotMiddleware() : loaderMiddleware,
        storageMiddleware,
      ),
      // allow dev tools when in development and in the browser
      // @see https://github.com/zalmoxisus/redux-devtools-extension
      (process.env.NODE_ENV !== 'production' && process.browser && window.__REDUX_DEVTOOLS_EXTENSION__)
        ? window.__REDUX_DEVTOOLS_EXTENSION__({
          // @see https://github.com/zalmoxisus/redux-devtools-extension/issues/142
          serializeAction: (key, value) => ((typeof value === 'symbol') ? String(value) : value),
        })
        : f => f,
    ),
  );

  const { dispatch } = store;
  await dispatch(fetchInitial());

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers/index', () => {
      // eslint-disable-next-line global-require
      const nextReducers = require('../reducers/index').default;
      store.replaceReducer(combineReducers(nextReducers));
    });
  }

  return store;
};
