import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import MultiObjectProxy from '../../../model/MultiObjectProxy';

/**
 * If extra ui provided, a proxy object is returned that allows combined
 * access to the two ui lists. If no extra ui are provided
 * lists if necessary the two ui lists so we do not have to merge them.
 *
 * The benefit of the proxy is that we do not have to pass down a merged
 * ui object as childContext.
 *
 * @todo once module ui are fetched+mixed into global ui, this proxy may become obsolete
 *
 * @param {object} uiDefault
 * @param {object} ui - extra ui, not included in global ui.
 * @return {object}
 */
const getProxiedUi = (uiDefault = {}, ui = {}) => {

  if (!ui || !Object.keys(ui).length) { // proxy not necessary
    return uiDefault; // = state.ui
  }

  return new MultiObjectProxy([ui, uiDefault]);
};

/**
 * Allows to store ui information in the context to access them in the
 * UIConnector Component.
 *
 * Global UI-elements are always fetched from the store and included
 * in the context unless overridden by module-specific UI-elements.
 *
 * @param {Object} ui UI information to pass as context into nested components
 */
class UIProvider extends PureComponent {
  constructor(props, ...args) {
    super(props, ...args);
    const { ui, uiDefault } = props;
    // we proxy the two ui lists so we do not have to merge them.
    // merging them would unnecessarily replicate the default ui for every UIProvider.
    this.ui = getProxiedUi(uiDefault, ui);
  }
  /**
   * Will merge the additionally provided ui into the default ui.
   * If no additional ui elements are provided, the default ui are simply
   * used as context.
   *
   * Note: Since updating the context is considered bad practice (https://facebook.github.io/react/docs/context.html#updating-context)
   * and all ui elements that the component will need should be present at this time
   * already, we do not update the context!
   *
   * @see https://medium.com/@mweststrate/how-to-safely-use-react-context-b7e343eff076
   *
   * @return {object}
   */
  getChildContext() {
    return { ui: this.ui };
  }

  render() {
    return this.props.children;
  }
}

UIProvider.childContextTypes = {
  ui: PropTypes.object.isRequired,
};

UIProvider.propTypes = {
  children: PropTypes.node.isRequired,
  /**
   * Optional extra(!) ui-elements that do not exist within our ui store.
   * Typically those extra ui are those attached to a page-module.
   */
  ui: PropTypes.object,
  /**
   * ui-elements taken from the store.
   */
  uiDefault: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  uiDefault: state.ui,
});

export default connect(mapStateToProps)(UIProvider);
