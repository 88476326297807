import AbstractError from './AbstractError';
import { PAYPAL_CANCELED_ERROR } from '../../config/errorCodes';

/**
 * Thrown if the PayPal payment has been canceled
 * (e.g. due to a timeout or by the user closing the PayPal window).
 */
class PaypalCanceledError extends AbstractError {
  constructor() {
    super(PAYPAL_CANCELED_ERROR);
  }
}

export default PaypalCanceledError;
