import QueueableRequest from './QueueableRequest';
import {
  ACCOUNT_GDPR_CONSENTS_INQUIRY_ENDPOINT,
} from '../../config/api';
import { bindParamsToRoute } from '../../helpers/url';
import {
  REQUEST_METHOD_POST,
  SCOPE_MYDATA,
  SCOPE_MYDATA_WRITE,
  SCOPE_MYDATA_WRITE_CONSENTS,
  SCOPE_MYDATA_WRITE_GDPR,
} from '../../helpers/constants';
import { blockContentBasedOnUserScope } from '../../helpers/scope';

export default class AccountGdprConsentsInquiryRequest extends QueueableRequest {
  constructor(msisdn, payload) {
    super(bindParamsToRoute(ACCOUNT_GDPR_CONSENTS_INQUIRY_ENDPOINT, { msisdn }), {
      method: REQUEST_METHOD_POST,
      payload,
    });
  }

  block(state) {
    return !blockContentBasedOnUserScope(
      state.user.scope,
      SCOPE_MYDATA || SCOPE_MYDATA_WRITE ||
      SCOPE_MYDATA_WRITE_CONSENTS || SCOPE_MYDATA_WRITE_GDPR,
    );
  }
}
