import { connect } from 'react-redux';
import { fetchEntityById } from '../../actions/request/registry';
import {
  getNormalizedPaymentFees,
  getTargetCreditIncentives,
  selectProductSheet,
} from '../../helpers/entity';
import {
  CONTEXT_CONTRACT_RENEWAL,
  CONTEXT_CONTRACT_RENEWAL_SIM_PLUS,
} from '../../helpers/constants';
import {
  startContractRenewalFromTariff,
  startContractRenewalFromTariffWithSelectedHardware,
} from '../../actions/dialog/contractRenewalActions';
import { getBookablePromotions, getMatchingPromotions } from '../../helpers/promotions';
import TeaserNbaComposition from '../../components/compositions/teaser/TeaserNba';

const mapDispatchToProps = dispatch => ({
  dispatch,
  getHardwareEntity: (hardwareId) => dispatch(fetchEntityById(hardwareId)),
});

const getTotalPayments = (tariffPaymentFees, hardware) => {
  const totalMonthlyPaymentFeeStrike = tariffPaymentFees.paymentFeeStrike && {
    unit: tariffPaymentFees.paymentFeeStrike.unit +
      hardware.paymentFee.unit,
  };
  const totalMonthlyPaymentFee = {
    unit: tariffPaymentFees.paymentFee.unit +
      hardware.paymentFee.unit,
  };
  const totalSinglePaymentFeeStrike = tariffPaymentFees.singlePaymentFeeStrike && {
    unit: tariffPaymentFees.singlePaymentFeeStrike.unit +
      hardware.singlePaymentFee.unit,
  };
  const totalSinglePaymentFee = {
    unit: tariffPaymentFees.singlePaymentFee.unit +
      hardware.singlePaymentFee.unit,
  };
  return {
    totalMonthlyPaymentFeeStrike,
    totalMonthlyPaymentFee,
    totalSinglePaymentFeeStrike,
    totalSinglePaymentFee,
  };
};

const matchConditionToContext = (condition, context) => {
  if (
    context === CONTEXT_CONTRACT_RENEWAL_SIM_PLUS &&
    condition.includesHardware &&
    condition.requiresContractRenewal
  ) {
    return condition;
  } else if (
    context === CONTEXT_CONTRACT_RENEWAL &&
    !condition.includesHardware &&
    condition.requiresContractRenewal
  ) {
    return condition;
  }
  return false;
};

const mapStateToProps = () => (state) => {
  const {
    entities,
    user,
    site,
    ui,
  } = state;
  const { internalIdMap } = site;
  const { nbaOffer } = user;
  const { headline, copy } = nbaOffer;
  const tariffEid = internalIdMap[nbaOffer.bookableTariff.id];
  const isSimOnly = !nbaOffer.orderableArticle;
  const tariff = { ...nbaOffer.bookableTariff, eid: tariffEid };
  const hardware = nbaOffer.orderableArticle;
  const [tariffMap] = !isSimOnly ?
    Object.values(hardware.tariffMap).filter((el) => el.iid === tariff.id) :
    [];
  const normalizedTariff = {
    ...tariff,
    paymentFee: { unit: tariff.basicFee },
    singlePaymentFee: { unit: 0 },
  };
  const normalizedHardware = {
    ...hardware,
    paymentFee: { unit: tariffMap ? tariffMap.paymentFee.unit : 0 },
    singlePaymentFee: tariffMap ? tariffMap.singlePaymentFee : { unit: 0 },
  };
  const tariffMode = isSimOnly ? CONTEXT_CONTRACT_RENEWAL : CONTEXT_CONTRACT_RENEWAL_SIM_PLUS;
  const matchingPromos = getMatchingPromotions(
    getBookablePromotions(state),
    tariffMode,
    [
      { ...entities.tariffEntity[tariff.eid] },
      ...(hardware && hardware.eid ? [{ ...entities.hardwareEntity[hardware.eid] }] : []),
    ],
  );
  const incentives = getTargetCreditIncentives(entities.tariffEntity[tariff.eid], matchingPromos);
  const normalizedTariffPaymentFees =
    getNormalizedPaymentFees(normalizedTariff, incentives, null, true);
  const productInformationSheetLink = selectProductSheet(normalizedTariff, 'nba');
  const availableCondition = normalizedTariff.availableConditions &&
    normalizedTariff.availableConditions
      .find(condition => matchConditionToContext(condition, tariffMode));
  const availableConditionText = availableCondition && ui.nbaMinRuntimeCopy &&
    ui.nbaMinRuntimeCopy.replace('{MONTH}', availableCondition.newDurationMonth);
  const subline = `+ ${normalizedTariff.headline} ${normalizedTariff.subline}`;
  const image = isSimOnly ? ui.nbaSimImage : normalizedHardware.image.url;
  const totalPayments = getTotalPayments(normalizedTariffPaymentFees, normalizedHardware);
  return {
    hardware: normalizedHardware,
    tariff: normalizedTariff,
    headline,
    copy,
    subline,
    image,
    productInformationSheetLink,
    availableConditionText,
    totalPayments,
    isSimOnly,
    ui,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { isSimOnly, tariff, hardware } = stateProps;
  const { dispatch } = dispatchProps;
  const startContractRenewal = () => (
    isSimOnly ?
      dispatch(startContractRenewalFromTariff(tariff.id, false)) :
      dispatch(startContractRenewalFromTariffWithSelectedHardware(
        tariff.id, hardware.eid,
      ))
  );
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onClick: () => {
      startContractRenewal();
    },
  };
};


export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(TeaserNbaComposition);
