import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import connectSiteProvider from '../../../basics/service/ServiceSiteConnector';
import FieldInput from '../../../basics/form/FieldInput';
import FormSection from '../../form/FormSection';
import FormHeader from '../../form/FormHeader';

function RegistrationForm(props) {
  const { fieldMap, siteProvider: { ui } } = props;
  return (
    <FormSection asGrid>
      <FormHeader headline={ui.myPasswordLostMsisdnHeadline} copy={ui.guiRegistrationCopy} />
      <Field {...fieldMap.msisdn} component={FieldInput} utilities={['col12', 'mlCol6']} />
    </FormSection>
  );
}

RegistrationForm.propTypes = {
  siteProvider: PropTypes.object,
  // form logic
  fieldMap: PropTypes.shape({
  }),
};

export default connectSiteProvider()(RegistrationForm);
