import AbstractError from './AbstractError';
import { MAINTENANCE_MODE_ERROR } from '../../config/errorCodes';

/**
 * Error to be thrown if the system has been shutdown for maintenance reasons.
 */
class MaintenanceError extends AbstractError {
  constructor(error) {
    super(MAINTENANCE_MODE_ERROR, error.getContext());
    this.previous = error;
  }
}

export default MaintenanceError;
