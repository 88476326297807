import initForm from '../../form/FormInitializer';
import LoginFormSms from '../../../components/compositions/account/login/LoginFormSms';
import FormConfigProps from '../../../model/form/FormConfigProps';

const createFieldMap = (ui) => ({
  msisdn: {
    name: 'msisdn',
    label: ui.guiFormMsisdn,
    type: 'tel',
    validation: {
      pattern: 'phone',
      range: [7, 25],
      isRequired: true,
    },
  },
});

export const id = 'loginSms';

// init component once, outside
const component = initForm()(LoginFormSms);

/**
 * @return {FormConfig}
 */
export const mapStateToFormConfig = (state) => ({
  component,
  fieldMap: createFieldMap(state.ui),
  formConfigProps: new FormConfigProps({
    labelSubmit: state.ui.guiWordLogin,
    withRequiredHint: false,
    withStackedFooter: true,
  }),
});

export default mapStateToFormConfig;
