import PageModule from './PageModule';
import component from '../../containers/account/topup/MyAutoTopupForm';

/**
 * Displays the comfort charging form
 * @see https://confluence.db-n.com/x/jIvr
 */
class MyAutoTopupFormModule extends PageModule {
  constructor(moduleData) {
    super(component, moduleData);
  }
}

export default MyAutoTopupFormModule;

