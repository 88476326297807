import { PureComponent } from 'react';
import PropTypes from 'prop-types';

class UniqueIdProvider extends PureComponent {
  constructor(...args) {
    super(...args);
    this.pointer = 0;
    this.getUniqueId = this.getUniqueId.bind(this);
  }

  getChildContext() {
    return { uniqueId: this.getUniqueId };
  }

  componentWillUnmount() {
    // reset pointer on unmount
    this.pointer = 0;
  }

  getUniqueId(prefix) {
    this.pointer += 1;
    return `${prefix ? `${prefix}_` : ''}${this.pointer}`;
  }

  render() {
    return this.props.children;
  }
}

UniqueIdProvider.childContextTypes = {
  uniqueId: PropTypes.func.isRequired,
};

UniqueIdProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UniqueIdProvider;
