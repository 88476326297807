import React, { PureComponent } from 'react';
import oneLineTrim from 'common-tags/lib/oneLineTrim';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';
import connectUI from '../../basics/ui/UIConnector';

import { toPriceObject } from '../../../helpers/money';

import Headline from '../../basics/text/TextHeadline';
import Copy from '../../basics/text/TextCopy';
import Link from '../../basics/text/TextLink';
import Unit from '../../basics/text/TextUnit';
import Image from '../../basics/media/MediaImage';
import MediaImageBackground from '../../basics/media/MediaImageBackground';
import imageSizes from '../../../config/imageSizes';
import Info from '../../basics/text/TextInfo';

const componentName = 'MyPromoDetails';
class MyPromoDetails extends PureComponent {

  renderHeader() {
    const { headline, subline, imageBg } = this.props;

    if (!headline && !subline) {
      return null;
    }

    const Component = (
      <div className={suitcss({ componentName, descendantName: 'header' }, this)}>
        {headline &&
          <Headline
            className={suitcss({ componentName, descendantName: 'headline' }, this)}
            size="xxl"
            raw
          >
            {headline}
          </Headline>
        }
        {subline &&
          <Headline
            className={suitcss({ componentName, descendantName: 'subline' }, this)}
            utilities={['lowercase']}
            size="xs"
            embedded
            raw
          >
            {subline}
          </Headline>
        }
      </div>
    );

    if (!imageBg) {
      return Component;
    }

    return (
      <MediaImageBackground
        className={suitcss({ descendantName: 'background' }, this)}
        {...(imageBg || {})}
        sizes={imageSizes.globalSectionExpanded}
        isLazy
      >
        {Component}
      </MediaImageBackground>
    );
  }

  renderContent() {
    const {
      entity,
      copy,
      image,
      buttonPrimary,
      buttonSecondary,
      legalHeadline,
      legalText,
      ui,
      onDownloadDocument,
    } = this.props;

    const { requiresContractSummary } = entity;
    const basicFee = entity.basicFee != null && toPriceObject(entity.basicFee);
    const postfix = entity.automaticExtension ? ui.guiWordMonthlyAbbr : ui.guiWordSingularAbbr;
    const hintPrimary = buttonPrimary.hint;
    const hintSecondary = buttonSecondary && buttonSecondary.hint;

    const contractSummaryHint = ui.promotionCsHint.replace('{CONTRACT_SUMMARY_PDF}', oneLineTrim`
      <button class="TextLink">
        ${ui.promotionCsPlaceholder}
      </button>
    `);

    return (
      <div className={suitcss({ descendantName: 'content' }, this)}>
        {image && <Image {...image} sizes={imageSizes.teaser.full} /> }
        {copy &&
          <Copy
            className={suitcss({ descendantName: 'copy' }, this)}
            embedded
            raw
          >
            {copy}
          </Copy>
        }
        {basicFee &&
          <div className={suitcss({ descendantName: 'price' }, this)} >
            {<Unit postfix={postfix} price={basicFee} size="m" />}
          </div>
        }
        {requiresContractSummary &&
          <Copy
            className={suitcss({ descendantName: 'contractSummary' }, this)}
            embedded
            raw
            onClick={onDownloadDocument}
          >
            {contractSummaryHint}
          </Copy>
        }
        <div className={suitcss({ descendantName: 'buttons' }, this)} >
          {buttonPrimary &&
            <div className={suitcss({ descendantName: 'button' }, this)}>
              <Link
                onClick={buttonPrimary.onClick}
                buttonFilled
                asButton
                element={buttonPrimary.onClick ? 'button' : null}
                to={buttonPrimary.url}
                target={buttonPrimary.target}
                rel={buttonPrimary.target === '_blank' ? '' : null}
              >
                {buttonPrimary.label}
              </Link>
              {hintPrimary &&
                <span className={suitcss({ descendantName: 'hint', utilities: ['sHidden'] }, this)}>
                  <Copy
                    className={suitcss({ utilities: ['colorGray100'] })}
                    element="span"
                    size="small"
                    embedded
                    raw
                  >
                    {hintPrimary}
                  </Copy>
                </span>
              }
            </div>
          }
          {buttonSecondary &&
            <div className={suitcss({ descendantName: 'button' }, this)}>
              <Link asButton to={buttonSecondary.url} >
                {buttonSecondary.label}
              </Link>
              {hintSecondary &&
                <span className={suitcss({ descendantName: 'hint', utilities: ['sHidden'] }, this)}>
                  <Copy
                    className={suitcss({ utilities: ['colorGray100'] })}
                    element="span"
                    size="small"
                    embedded
                    raw
                  >
                    {hintSecondary}
                  </Copy>
                </span>
              }
            </div>
          }
        </div>
        {(hintPrimary || hintSecondary) &&
          <div className={suitcss({ descendantName: 'hints', utilities: ['mlHidden'] }, this)} >
            {buttonPrimary && hintPrimary &&
              <Copy
                className={suitcss({ utilities: ['colorGray100'] })}
                size="small"
                embedded
                raw
              >
                {hintPrimary}
              </Copy>
            }
            {buttonSecondary && hintSecondary &&
              <Copy
                className={suitcss({ utilities: ['colorGray100'] })}
                size="small"
                embedded
                raw
              >
                {hintSecondary}
              </Copy>
            }
          </div>
        }
        {legalHeadline && legalText &&
          <Info
            label={legalHeadline}
            copy={legalText}
            size="small"
            asAccordion
          />
        }
      </div>
    );
  }

  render() {
    return (
      <section className={suitcss({}, this)} >
        {this.renderHeader()}
        {this.renderContent()}
      </section>
    );
  }
}

MyPromoDetails.propTypes = {
  entity: PropTypes.object.isRequired,
  headline: PropTypes.string,
  subline: PropTypes.string,
  copy: PropTypes.string,
  image: PropTypes.object,
  imageBg: PropTypes.object,
  legalHeadline: PropTypes.string,
  legalText: PropTypes.string,
  buttonPrimary: PropTypes.shape({
    type: PropTypes.oneOf(['info', 'redeem', 'context', 'product']).isRequired,
    label: PropTypes.string.isRequired,
    hint: PropTypes.string,
    url: PropTypes.string,
    onClick: PropTypes.func,
  }).isRequired,
  buttonSecondary: PropTypes.shape({
    label: PropTypes.string.isRequired,
    hint: PropTypes.string,
    url: PropTypes.string,
  }),
  ui: PropTypes.object.isRequired,
  onDownloadDocument: PropTypes.func,
};

export default connectUI()(MyPromoDetails);
