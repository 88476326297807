import {
  PATCH_ORDER,
  ADD_ORDER_ITEM,
  REMOVE_ORDER_ITEM,
  REGISTER_TARIFF_MODE,
} from '../actions/order/process';
import { ENTITY_TYPE_HARDWARE, ENTITY_TYPE_TARIFF } from '../helpers/constants';

function updateEntities(state = [], action = {}) {
  switch (action.type) {
    case ADD_ORDER_ITEM:
      return [...state, {
        etype: action.payload.entity.etype,
        eid: action.payload.entity.eid,
        persistent: action.payload.persistent,
      }];

    case REMOVE_ORDER_ITEM:
      return state.filter(({ eid, etype }) => !(
        eid === action.payload.entity.eid &&
        etype === action.payload.entity.etype
      ));

    case PATCH_ORDER: {
      // reduce entities to etype and eid
      const nextEntities = action.payload.entities.map(
        ({ etype, eid }) => ({ etype, eid }),
      );
      // if the order should not stay the same we can just override it with the
      // new entities. EnsureOrder is more like ensure Reihenfolge not Bestellung
      if (!action.meta.ensureOrder) {
        return nextEntities;
      }
      const oldTariff = state.find(entity => entity.etype === ENTITY_TYPE_TARIFF);
      const newTariff = nextEntities.find(entity => entity.etype === ENTITY_TYPE_TARIFF);
      const tariffChanged = oldTariff && newTariff && (oldTariff.eid !== newTariff.eid);
      // persistent items will stay in the orderProcess if the tariff does not change
      const persistentItems = tariffChanged ? [] : state.filter(entity => entity.persistent);

      // filters out the old entities, but keeps them in the right place. E. g. tariff option in the
      // first place and hardware in the second
      const nextItems = nextEntities
        .reduce((nextState, entity) => {
          // find index of the first entity with the same type
          const index = nextState.findIndex(stateEntity => entity.etype === stateEntity.etype);
          if (index === -1) {
            // if no matching entity was found, just append it
            return [...nextState, entity];
          }
          // otherwise replace the current entity with the next one
          return [...nextState.slice(0, index), entity, ...nextState.slice(index + 1)];
        }, state)
        // filter those that remains from the old state and should not be included anymore
        .filter((entity) => nextEntities.find((listEntity) => listEntity.eid === entity.eid));

      return [...nextItems, ...persistentItems];
    }


    default:
      return state;
  }
}

const createDefaultState = () => ({
  entities: updateEntities(),
  // @todo put progress states into constants
  // can be: EMPTY, SINGLE_PENDING, SINGLE_CONFIRMED, BUNDLE
  progressState: 'EMPTY',
  // @todo the name "activeType" is misleading
  // what is meant is the entity type that has first been chosen in the selection process.
  activeType: undefined,
  // the entity that has been chosen before having moved further in the selection process
  confirmedEntityStub: undefined,
});

function orderProcess(state = createDefaultState(), action = {}) {
  switch (action.type) {
    case ADD_ORDER_ITEM:
    case REMOVE_ORDER_ITEM:
      return Object.assign({}, state, {
        entities: updateEntities(state.entities, action),
      });
    case PATCH_ORDER: {
      const firstEntity = action.payload.entities[0];
      const progressState = action.payload.progressState || state.progressState;
      const activeType = firstEntity ? firstEntity.etype : null;
      const entities = (
        (action.payload.entities && updateEntities(state.entities, action)) ||
        state.entities
      );

      let confirmedEntityStub;
      if (
        (activeType === ENTITY_TYPE_TARIFF && progressState === 'SINGLE_CONFIRMED') ||
        (activeType === ENTITY_TYPE_HARDWARE && progressState === 'BUNDLE')
      ) {
        confirmedEntityStub = entities.find(entity => entity.etype === ENTITY_TYPE_TARIFF);
      } else if (
        (activeType === ENTITY_TYPE_HARDWARE && progressState === 'SINGLE_CONFIRMED') ||
        (activeType === ENTITY_TYPE_TARIFF && progressState === 'BUNDLE')
      ) {
        confirmedEntityStub = entities.find(entity => entity.etype === ENTITY_TYPE_HARDWARE);
      }

      return { ...state, progressState, activeType, confirmedEntityStub, entities };
    }
    case REGISTER_TARIFF_MODE: {
      const { tariffMode } = action.payload;
      return { ...state, tariffMode };
    }

    default:
      return state;
  }
}

export default orderProcess;
