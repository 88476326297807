import React from 'react';
import PropTypes from 'prop-types';
import Observer from '@researchgate/react-intersection-observer';

const IntersectionObserver = (props) => {
  const {
    children,
    root,
    rootMargin,
    threshold,
    onChange,
    isDisabled,
  } = props;
  return (
    <Observer
      root={root}
      rootMargin={rootMargin}
      threshold={threshold}
      onChange={onChange}
      disabled={isDisabled}
    >
      {children}
    </Observer>
  );
};

IntersectionObserver.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.array,
  ]),
  root: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]),
  rootMargin: PropTypes.string,
  threshold: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(
      PropTypes.number,
    ),
  ]),
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
};

export default IntersectionObserver;
