import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import { capitalize } from '../../../../helpers/str';
import suitcss from '../../../../helpers/suitcss';
import FormSection from '../FormSection';
import FormHeader from '../FormHeader';
import * as constants from '../../../../helpers/constants';
import Copy from '../../../basics/text/TextCopy';
import FieldValueSlider from '../../../basics/form/FieldValueSlider';
import FieldRadioGroup from '../../../basics/form/FieldRadioGroup';
import FieldAccordion from '../../../basics/form/FieldAccordion';

class FormStepTopup extends PureComponent {

  getBrandingData() {
    const { brandName, ui } = this.props;
    return brandName && brandName !== constants.BRAND_OTELO
      ? {
        image: ui[`myAutotopup${capitalize(brandName)}Image`],
        copy: ui[`myAutotopup${capitalize(brandName)}Copy`],
        hint: ui[`myAutotopup${capitalize(brandName)}Hint`],
      }
      : null;
  }

  render() {
    const {
      params,
      form,
      fieldMap,
      ui,
      autoTopupAmountOptions,
      showSingleTopup,
      market,
      loyaltySettings,
    } = this.props;
    const { topupHeadline, topupCopy } = params;
    const brandedData = this.getBrandingData();
    const isMmo = market === constants.MARKET_MMO;
    return (
      <FormSection className={suitcss({}, this)} asGrid>
        <FormHeader headline={topupHeadline} copy={topupCopy} />
        {brandedData && (
          <div className="FormWrapper-brandedContent u-col12">
            {brandedData.image &&
              <img src={brandedData.image} alt="" />
            }
            {brandedData.copy &&
              <Copy embedded raw>{brandedData.copy}</Copy>
            }
          </div>
        )}
        {loyaltySettings && loyaltySettings.level > 0 &&
          <div className={suitcss({ descendantName: 'loyaltyBenefits', utilities: ['marginBottomS'] }, this)} >
            <Copy embedded raw>{loyaltySettings.copy}</Copy>
          </div>
        }
        {!isMmo &&
          <FieldAccordion
            formIdentifier={form}
            name={fieldMap.topupType.name}
            label={ui.guiTopupAuto}
            dependantFieldNames={[fieldMap.autoTopupType.name]}
            value={'auto'}
            withToolTip
            utilities={['col12', 'mlCol8']}
            validation={fieldMap.topupType.validation}
          >
            <FieldAccordion
              formIdentifier={form}
              name={fieldMap.autoTopupType.name}
              label={ui.guiTopupAutoBalance}
              value={'balance'}
              withToolTip
              validation={fieldMap.autoTopupType.validation}
            >
              <Field
                {...fieldMap.topupAmount}
                options={autoTopupAmountOptions}
                defaultValue={autoTopupAmountOptions[0].value}
                component={FieldValueSlider}
              />
              <Field
                {...fieldMap.autoTopupTrigger}
                defaultValue={fieldMap.autoTopupTrigger.options[0].value}
                component={FieldValueSlider}
              />
            </FieldAccordion>
            <FieldAccordion
              label={fieldMap.autoTopupMonthlyType.label}
              name={fieldMap.autoTopupType.name}
              value={'monthly'}
              formIdentifier={form}
              withToolTip
              validation={fieldMap.autoTopupMonthlyType.validation}
            >
              <Field
                {...fieldMap.topupAmount}
                options={autoTopupAmountOptions}
                defaultValue={autoTopupAmountOptions[0].value}
                component={FieldValueSlider}
              />
              <Field {...fieldMap.autoTopupMonthlyType} label="" component={FieldRadioGroup} />
            </FieldAccordion>
          </FieldAccordion>
        }
        {showSingleTopup &&
          <FieldAccordion
            name={fieldMap.topupType.name}
            label={ui.guiTopupSingle}
            value={'single'}
            formIdentifier={form}
            withToolTip
            utilities={['col12', 'mlCol8']}
            validation={fieldMap.topupType.validation}
          >
            <Field
              {...fieldMap.topupAmount}
              options={autoTopupAmountOptions}
              defaultValue={autoTopupAmountOptions[0].value}
              component={FieldValueSlider}
            />
          </FieldAccordion>
        }
        {isMmo &&
          <FieldAccordion
            name={fieldMap.topupType.name}
            label={ui.myDashboardAutoTopupMmo}
            value={'mmo'}
            formIdentifier={form}
            withToolTip
            utilities={['col12', 'mlCol8']}
            validation={fieldMap.topupType.validation}
          />
        }
        <FieldAccordion
          name={fieldMap.topupType.name}
          label={ui.guiTopupNone}
          value={'none'}
          formIdentifier={form}
          withToolTip
          utilities={['col12', 'mlCol8']}
          validation={fieldMap.topupType.validation}
        />
        {brandedData && brandedData.hint && (
          <Copy className="FormWrapper-brandedFooter u-col12" embedded raw>
            {brandedData.hint}
          </Copy>
        )}
      </FormSection>
    );
  }
}

FormStepTopup.propTypes = {
  showSingleTopup: PropTypes.bool,
  market: PropTypes.string,
  autoTopupAmountOptions: PropTypes.array.isRequired,
  fieldMap: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  form: PropTypes.string.isRequired,
  brandName: PropTypes.string,
  params: PropTypes.shape({
    topupHeadline: PropTypes.string,
    topupCopy: PropTypes.string.isRequired,
  }),
  context: PropTypes.symbol,
  loyaltySettings: PropTypes.object,
};

export default FormStepTopup;
