import React from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';

const FormSelectBoxOption = props => (
  <option
    className={suitcss({
      componentName: 'FormSelectBox',
      descendantName: 'option',
    })}
    disabled={props.disabled}
    value={props.value}
  >
    {props.label}
  </option>
);

FormSelectBoxOption.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

FormSelectBoxOption.defaultProps = {
  disabled: false,
};

export default FormSelectBoxOption;
