import React from 'react';
import PropTypes from 'prop-types';
import SearchForm from '../../../containers/search/SearchForm';

function NavigationSearch(props) {

  //------------------------------------------------------------------------------------------------
  //  Render
  //------------------------------------------------------------------------------------------------

  const { classForHtmlBind } = props;

  const setupData = {
    align: 'left',
    colorScheme: 'light',
    config: [],
    facet: 'none',
    inputAction: 'Suchen',
    inputPlaceholder: 'otelo.de durchsuchen',
    moreAction: 'Mehr anzeigen',
    resultsNone: 'Keine Treffer',
    resultsPageUrl: '/suche',
    resultsSorry: 'Deine Suche ergab leider kein Ergebnis.',
    resultsTotal: '{total} Treffer',
    classForHtmlBind,
  };

  return (
    <SearchForm params={setupData} />
  );
}

//------------------------------------------------------------------------------------------------
//  Proptypes
//------------------------------------------------------------------------------------------------

NavigationSearch.propTypes = {
  classForHtmlBind: PropTypes.string,
};

export default NavigationSearch;
