/* global window */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { createSelector } from 'reselect';
import * as requestActions from '../../actions/request/registry';
import GlobalSection from '../../components/basics/global/GlobalSection';
import { MEDIA_S } from '../service/ServiceMatchMedia';
import {
  UI_ESIM_DEVICE_CHECK,
} from '../../helpers/constants';
import suitcss from '../../helpers/suitcss';
import Accordion from '../../components/basics/accordion/Accordion';
import AccordionSection from '../../components/basics/accordion/AccordionSection';
import CustomDropdown from '../../components/basics/dropdown/CutomDropdown';
import SvgLoader from '../../components/basics/media/MediaSvgLoader';
import TextHeadline from '../../components/basics/text/TextHeadline';
import Copy from '../../components/basics/text/TextCopy';

const ICON_ESIM = '/icons/icon-circle-orange-filled.svg';
const ICON_SIM = '/icons/icon-triangle-green-filled.svg';

class ESimDeviceCheck extends PureComponent {
  constructor(props, context) {
    super(props, context);
    this.deviceListRef = React.createRef();
    this.manufacturerListRef = React.createRef();
    this.state = {
      selectedManufacturerOption: null,
      selectedDeviceOption: null,
      selectedDeviceList: null,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(requestActions.fetchUiElements([UI_ESIM_DEVICE_CHECK]));
    dispatch(requestActions.fetchArticleInformationGroups());
  }

  onManufacturerOptionChange(value) {
    const { deviceList } = this.props;
    const currentDeviceList = !!deviceList.length && deviceList.find((el) => el.title === value);
    this.setState({
      selectedDeviceList: currentDeviceList.articleGroups,
      selectedManufacturerOption: value,
      selectedDeviceOption: null,
    });
    if (this.deviceListRef && this.deviceListRef.current) this.deviceListRef.current.onReset();
  }

  onDeviceOptionChange(value) {
    const { selectedDeviceList } = this.state;
    const selectedDeviceOption = selectedDeviceList
      .find(option => Object.values(option).includes(value));
    this.setState({ selectedDeviceOption });
  }

  onManufacturerListFocus() {
    this.setState({ selectedManufacturerOption: null, selectedDeviceOption: null });
    if (this.deviceListRef && this.deviceListRef.current) this.deviceListRef.current.onReset();
    if (this.manufacturerListRef && this.manufacturerListRef.current) {
      this.manufacturerListRef.current.onReset();
    }
  }

  onDeviceListFocus() {
    this.setState({ selectedDeviceOption: null });
    if (this.deviceListRef && this.deviceListRef.current) this.deviceListRef.current.onReset();
  }

  renderLegend() {
    const { ui } = this.props;
    const legendItems = [
      { icon: ICON_ESIM, text: ui.esimDeviceCheckLabelEsim },
      { icon: ICON_SIM, text: ui.esimDeviceCheckLabelSim },
    ];
    return (
      <div
        className={suitcss(
          { descendantName: 'legend', utilities: ['flex', 'itemsCenter', 'marginBottom'] },
          this,
        )}
      >
        {legendItems.map((item, index) => (
          <Copy
            className={suitcss({ descendantName: 'legendItem' }, this)}
            utilities={['flex', 'itemsCenter', index !== 0 && 'marginLeftS']}
            size="secondary"
            embedded
            key={index}
          >
            <SvgLoader utilities={['textSizeXS', 'marginRightXS']} path={item.icon} />
            <span>{item.text}</span>
          </Copy>
        ))}
      </div>
    );
  }

  renderSelectManufacturerList() {
    const { ui, manufacturerList } = this.props;
    if (!manufacturerList || !manufacturerList.length) return null;
    return (
      <div className={suitcss({
        descendantName: 'manufacturerList',
      }, this)}
      >
        <CustomDropdown
          ref={this.manufacturerListRef}
          options={manufacturerList}
          placeholder={ui.esimDeviceCheckSelectPlaceholder}
          label={ui.esimDeviceCheckSelectManufacturer}
          onOptionChange={(val) => this.onManufacturerOptionChange(val, this)}
          onFocus={() => this.onManufacturerListFocus(this)}
          asDataList
        />
      </div>
    );
  }

  renderSelectDeviceList(isEsimSupported, isSimSupported) {
    const { ui } = this.props;
    const { selectedDeviceList } = this.state;
    if (!selectedDeviceList || !selectedDeviceList.length) return null;
    return (
      <div className={suitcss({
        descendantName: 'deviceList',
        utilities: ['marginTopS'],
      }, this)}
      >
        <CustomDropdown
          ref={this.deviceListRef}
          options={selectedDeviceList}
          placeholder={ui.esimDeviceCheckSelectPlaceholder}
          label={ui.esimDeviceCheckSelectDevice}
          onOptionChange={(val) => this.onDeviceOptionChange(val, this)}
          onInput={() => this.setState({ selectedDeviceOption: null })}
          onFocus={() => this.onDeviceListFocus(this)}
          asDataList
        />
        {isEsimSupported && (
          <div className={suitcss({ descendantName: 'deviceListIcon', modifiers: ['esim'] }, this)}>
            <SvgLoader path={ICON_ESIM} />
          </div>
        )}
        {isSimSupported && (
          <div className={suitcss({ descendantName: 'deviceListIcon', modifiers: ['sim'] }, this)}>
            <SvgLoader path={ICON_SIM} />
          </div>
        )}
      </div>
    );
  }

  renderMessage(isEsimSupported, isSimSupported) {
    const { ui } = this.props;
    let message;
    if (isEsimSupported && !isSimSupported) {
      message = ui.esimDeviceCheckMessageEsim;
    } else if (isSimSupported && !isEsimSupported) {
      message = ui.esimDeviceCheckMessageSim;
    } else {
      message = ui.esimDeviceCheckMessageEsimSim;
    }
    return (
      <Copy
        className={suitcss({ descendantName: 'message' }, this)}
        utilities={['marginBottom', 'colorPrimary']}
      >
        {message}
      </Copy>
    );
  }

  renderInfo(info) {
    if (!info) return null;
    return (
      <Copy className={suitcss({ descendantName: 'info' }, this)} utilities={['marginBottomM']}>
        {info}
      </Copy>
    );
  }

  renderHint(title, hint) {
    if (!title || !hint) return null;
    const label = `<span class="u-colorDefault u-weightBold u-marginRightXS">${title}</span>`;
    return (
      <div className={suitcss({ descendantName: 'hint' }, this)}>
        <Accordion>
          <AccordionSection
            label={label}
            embedded
            theme="primary"
          >
            <Copy utilities={['marginTopS']} size="small" raw>{hint}</Copy>
          </AccordionSection>
        </Accordion>
      </div>
    );
  }

  renderContent() {
    const { ui, headline } = this.props;
    const { selectedManufacturerOption, selectedDeviceOption } = this.state;
    const textHeadline = headline || null;
    const isEsimSupported = selectedDeviceOption && selectedDeviceOption.classNames.includes('is-esim');
    const isSimSupported = selectedDeviceOption && selectedDeviceOption.classNames.includes('is-sim');
    return (
      <div className={suitcss({}, this)}>
        <div className={suitcss({ descendantName: 'content' }, this)}>
          {textHeadline && (
            <TextHeadline size="m" utilities={['marginBottom', 'weightBold', 'alignCenter']}>
              {textHeadline}
            </TextHeadline>
          )}
          {this.renderLegend()}
          <div className={suitcss({
            descendantName: 'selectGroup',
            utilities: ['marginBottom'],
          }, this)}
          >
            {this.renderSelectManufacturerList()}
            {selectedManufacturerOption &&
              this.renderSelectDeviceList(isEsimSupported, isSimSupported)
            }
          </div>
          {(isEsimSupported || isSimSupported) &&
            this.renderMessage(isEsimSupported, isSimSupported)
          }
          {this.renderInfo(ui.esimDeviceCheckInfo)}
          {this.renderHint(ui.esimDeviceCheckHintTitle, ui.txtEsimDeviceCheckHint)}
        </div>
      </div>
    );
  }

  render() {
    const { withoutContainer } = this.props;
    if (withoutContainer) {
      return (this.renderContent());
    }
    return (
      <GlobalSection layoutSettings={{ adjacentTop: true }}>
        {this.renderContent()}
      </GlobalSection>
    );
  }
}

ESimDeviceCheck.propTypes = {
  ui: PropTypes.object,
  manufacturerList: PropTypes.array.isRequired,
  deviceList: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
  headline: PropTypes.string,
  withoutContainer: PropTypes.bool,
};

const mapStateToProps = () => {
  const slicedDeviceNameLength = typeof window !== 'undefined' && window.matchMedia(MEDIA_S).matches
    ? 35
    : 45;
  const articleListSelector = (state) => state.user.articleInformationGroups || [];
  const manufacturerListSelector = createSelector(
    articleListSelector,
    (articleList) => articleList.map((article) => ({ value: article.title })),
  );
  const deviceListSelector = createSelector(
    articleListSelector,
    (articleGroupList) => articleGroupList
      .map(({ title, articleGroups }) => ({
        title,
        articleGroups: articleGroups.map((group) => ({
          value: group.name.length >= slicedDeviceNameLength ? `${group.name.slice(0, slicedDeviceNameLength)}...` : group.name,
          classNames: ['deviceListOption', group.supportsEsim && 'is-esim', group.supportsSimCard && 'is-sim'],
        })),
      })),
  );
  return (state, ownProps) => ({
    ui: state.ui,
    manufacturerList: manufacturerListSelector(state),
    deviceList: deviceListSelector(state, ownProps),
  });
};


const mapDispatchToProps = dispatch => ({ dispatch });

export default compose(connect(mapStateToProps, mapDispatchToProps))(ESimDeviceCheck);
