/* global window */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import suitcss from '../../../../helpers/suitcss';
import { QUERY_TARIFF_MODE, CONTEXT_CONTRACT_RENEWAL_SIM_PLUS } from '../../../../helpers/constants';
import Header from './CancellationHeader';
import Link from '../../../basics/text/TextLink';
import FormSection from '../../form/FormSection';
import FormHeader from '../../form/FormHeader';

class FormStepHoldOn extends PureComponent {
  render() {
    const { ui, isContractRenewalPossible, sitemap, dispatch } = this.props;

    return (
      <div className={suitcss({}, this)}>
        <Header
          headline={ui.myCancellationFormHoldOnHeadline}
          imageLink={ui.myCancellationFormImageJson.replace('%gui.baseurl%', '')}
        />
        <FormSection asGrid>
          <FormHeader
            headline={ui.myCancellationFormCallUsHeadline}
            copy={ui.myCancellationFormCallUsCopy}
          />
          <Link
            element="button"
            utilities={['uppercase', 'fontCondensed']}
            asButton
            buttonFilled
            onClick={e => {
              e.preventDefault();
              window.location = `tel:${ui.myCancellationFormCallUsButtonLink}`;
            }}
          >
            {ui.myCancellationFormCallUsButton}
          </Link>
          {isContractRenewalPossible && (
            <FormHeader
              headline={ui.myCancellationFormNewPhoneHeadline}
              copy={ui.myCancellationFormNewPhoneCopy}
            />
          )}
          {isContractRenewalPossible && (
            <Link
              element="button"
              utilities={['uppercase', 'fontCondensed']}
              asButton
              buttonFilled
              onClick={e => {
                e.preventDefault();
                dispatch(push(`${sitemap.MyDashboardRoute.url}?${QUERY_TARIFF_MODE}=${CONTEXT_CONTRACT_RENEWAL_SIM_PLUS}#MyOffersTariffs`));
              }}
            >
              {ui.myCancellationFormNewPhoneButton}
            </Link>
          )}
        </FormSection>
      </div>
    );
  }
}

FormStepHoldOn.propTypes = {
  ui: PropTypes.object.isRequired,
  isContractRenewalPossible: PropTypes.bool.isRequired,
  sitemap: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = ({ user, site }) => ({
  isContractRenewalPossible: user.renewableTariffs.length !== 0,
  sitemap: site.sitemap,
});

const mapDispatchToProps = dispatch => ({ dispatch });

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FormStepHoldOn);
