import React from 'react';
import PropTypes from 'prop-types';

import TextHeadlineGroup from '../../components/basics/text/TextHeadlineGroup';
import GlobalSection from '../../components/basics/global/GlobalSection';

function ContentHeadline({ params, layoutSettings, primaryModule, moduleId }) {
  return (
    <GlobalSection
      layout="contained"
      theme={params.colorScheme}
      layoutSettings={layoutSettings}
    >
      <TextHeadlineGroup
        moduleId={moduleId}
        primaryModule={primaryModule}
        headline={params.headline}
        subline={params.subline}
        inverted={params.colorScheme === 'dark'}
        align={params.align}
      />
    </GlobalSection>
  );
}

ContentHeadline.propTypes = {
  params: PropTypes.object.isRequired,
  layoutSettings: PropTypes.object,
  primaryModule: PropTypes.bool.isRequired,
  moduleId: PropTypes.string.isRequired,
};

export default ContentHeadline;
