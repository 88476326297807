import React from 'react';
import PropTypes from 'prop-types';
import { getDisplayName } from '../../../helpers/component';

const connectSiteProvider = () => (WrappedComponent) => {
  const ServiceSiteConnector = (props, context) => (
    <WrappedComponent
      {...props}
      siteProvider={context.siteProvider}
    />
  );

  ServiceSiteConnector.displayName = `ServiceSiteConnector(${getDisplayName(WrappedComponent)})`;
  ServiceSiteConnector.WrappedComponent = WrappedComponent;
  ServiceSiteConnector.contextTypes = {
    siteProvider: PropTypes.shape({
      user: PropTypes.object.isRequired,
      sitemap: PropTypes.object.isRequired,
      internalIdMap: PropTypes.object.isRequired,
      ui: PropTypes.object.isRequired,
      getGlobalUi: PropTypes.func.isRequired,
      getInternalId: PropTypes.func.isRequired,
      getEntityId: PropTypes.func.isRequired,
      showDialog: PropTypes.func.isRequired,
      addDialogToQueue: PropTypes.func.isRequired,
      hideDialog: PropTypes.func.isRequired,
      hideQueuedDialog: PropTypes.func.isRequired,
      showAlert: PropTypes.func.isRequired,
      hideAlert: PropTypes.func.isRequired,
      showNotification: PropTypes.func.isRequired,
      hideNotification: PropTypes.func.isRequired,
    }),
  };

  return ServiceSiteConnector;
};

export default connectSiteProvider;
