import { ACTION_PREFIX } from '../../helpers/constants';

export const UPDATE_CONTRACT_DATA_ESIM_STATUS = `${ACTION_PREFIX}/UPDATE_CONTRACT_DATA_ESIM_STATUS`;

export const updateContractDataESimStatus = (esimStatus) => ({
  type: UPDATE_CONTRACT_DATA_ESIM_STATUS,
  payload: {
    esimStatus,
  },
});

export const SHOW_ESIM_STATUS_REFRESH = `${ACTION_PREFIX}/SHOW_ESIM_STATUS_REFRESH`;

export const showESimStatusRefresh = (statusRefresh = false) => ({
  type: SHOW_ESIM_STATUS_REFRESH,
  payload: { statusRefresh },
});
