import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindQueryParams } from '../../helpers/url';
import MediaIFrame from '../../components/basics/media/MediaIFrame';
import GlobalSection from '../../components/basics/global/GlobalSection';

/**
 * Iframe component
 */
const Iframe = props => {
  const { params, src } = props;

  return (
    <GlobalSection
      theme={params.colorScheme}
      layout="contained"
      adjacent={params.adjacent}
      centered
    >
      <MediaIFrame
        autoHeight={!params.height}
        frameBorder="0"
        width={params.width || '100%'}
        height={params.height || '100%'}
        src={src}
      />
    </GlobalSection>
  );
};


Iframe.propTypes = {
  params: PropTypes.object.isRequired,
  src: PropTypes.string,
};

/**
 * Create a copy from src with only the specified keys included
 *
 * @param {object} src
 * @param {Array} keys
 * @return {object}
 */
const extract = (src = {}, keys = []) =>
  Object.entries(src)
    .filter(([key]) => keys.includes(key))
    .reduce((result, [key, value]) => ({ ...result, [key]: value }), {});

const mapStateToProps = (state, ownProps) => {
  const { queryKeys, url } = ownProps.params;
  const { routing } = state;
  const { query } = routing.locationBeforeTransitions;

  return {
    src: bindQueryParams(url, extract(query, queryKeys)),
  };
};

export default connect(mapStateToProps)(Iframe);
