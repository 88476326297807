/**
 * @file
 *
 * We need to keep track of page transitions, various events and clicks on
 * buttons and links. This information will be send to otelo to perform
 * statistical calculations. All this data is referred to as "tracking" data
 * and stored via this reducer.
 */

import {
  TRACK_PAGE,
} from '../actions/tracking/page';
import {
  TRACK_CLICK,
  TRACK_FIRED,
  AGGREGATE_PAGE_TRACKING,
  TRACK_DIALOG,
  TRACK_REMOVE_ITEM,
  TRACK_ERROR,
  TRACK_FILTER_INTERACTION,
  TRACK_CONTACT,
  TRACK_FILTER_PRODUCTS,
} from '../actions/tracking/event';
import {
  ADD_TRACKING_GARBAGE,
  REMOVE_TRACKING_GARBAGE,
  CLEAR_TRACKING_GARBAGE,
} from '../actions/tracking/garbageCollection';

function tracking(state = { queue: [], pending: {}, gc: [] }, action = {}) {
  switch (action.type) {
    case TRACK_PAGE:
      // remove outdated tracking script tags
      state.gc.forEach(node => {
        // special treatment for iframes as an external script checks for a closed
        // attribute before it tries to delete the iframe. Because not all scripts remove the
        // garbage themselves, we remove all iframes and set the closed attribute for the ones
        // who care
        if (node.nodeName === 'IFRAME') {
          node.closed = true; // eslint-disable-line
        }
        node.remove();
      });

      // merge pending pixel data from previous page (e.g. form submission) with actual payload
      // and reset pending data
      return {
        ...state,
        queue: [
          ...state.queue,
          { ...state.pending, ...action.payload },
        ],
        pending: {},
        gc: [],
      };
    case TRACK_CLICK:
    case TRACK_DIALOG:
    case TRACK_CONTACT:
    case TRACK_REMOVE_ITEM:
    case TRACK_ERROR:
    case TRACK_FILTER_INTERACTION:
    case TRACK_FILTER_PRODUCTS:
      return { ...state, queue: [...state.queue, action.payload] };

    case TRACK_FIRED: {
      return {
        ...state,
        queue: state.queue.filter((pixel) => pixel.id !== action.meta.identifier),
      };
    }

    // Add dynamic added tracking script tag to garbage collector
    case ADD_TRACKING_GARBAGE:
      return { ...state, gc: [...state.gc, action.payload] };

    // remove an already removed tracking script tag from the garbage collector
    case REMOVE_TRACKING_GARBAGE:
      return { ...state, gc: state.gc.filter(node => node !== action.payload) };

    // remove all nodes inside the garbage collector
    case CLEAR_TRACKING_GARBAGE:
      state.gc.forEach(node => node.remove());
      return { ...state, gc: [] };

    // add to temporary page tracking store
    case AGGREGATE_PAGE_TRACKING: {
      return { ...state, pending: { ...state.pending, ...action.payload } };
    }

    default:
      return state;
  }
}

export default tracking;
