import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';
import Headline from '../../basics/text/TextHeadline';
import Copy from '../../basics/text/TextCopy';
import Link from '../../basics/text/TextLink';
import Slider from '../../basics/slider/Slider';
import SliderSlide from '../../basics/slider/SliderSlide';
import SvgLoader from '../../basics/media/MediaSvgLoader';

const getSliderConfig = () => {
  const config = {
    slidesPerView: 1,
    autoHeight: false,
    spaceBetween: 30,
    paginationClickable: false,
    simulateTouch: false,
    pagination: {
      type: 'custom',
      renderCustom: Slider.paginationNumberRenderer,
    },
  };

  return config;
};

class HotlineDeals extends PureComponent {

  constructor(props, context) {
    super(props, context);
    this.state = {
      activeStep: 0,
      totalSteps: props.items.length,
      answers: [],
    };
    this.onNextStep = this.onNextStep.bind(this);
    this.onPreviousStep = this.onPreviousStep.bind(this);
  }

  onNextStep(stepId, answer) {
    const { answers } = this.state;
    answers[stepId] = answer;
    this.setState({ answers, activeStep: stepId + 1 });
  }

  onPreviousStep() {
    const { activeStep, totalSteps } = this.state;
    if (activeStep > 0) {
      this.setState({ activeStep: activeStep >= totalSteps ? 0 : activeStep - 1 });
    }
  }

  getTelephoneCode() {
    return this.state.answers.reduce((str, val, index) => (
      `${str}${val}${(index + 2) % 2 === 0 ? ' ' : ''}`
    ), 'T');
  }

  renderOption(option, optionId, stepId) {
    const { trackingId } = this.props;
    const { answers } = this.state;
    const isSelected = answers[stepId] === option.value;
    const tracking = { [`data-${trackingId}-value`]: `Q${stepId + 1}A${optionId + 1}` };
    return (
      <button
        className={suitcss({
          descendantName: 'option',
          states: [isSelected && 'selected'],
        }, this)}
        onClick={() => this.onNextStep(stepId, option.value)}
        key={`${stepId}-${optionId}`}
        data-tracking={trackingId}
        {...tracking}
      >
        <span className={suitcss({ descendantName: 'optionInner' }, this)} >
          {option.icon && <SvgLoader path={option.icon} />}
          <Copy size="secondary" elements="span" embedded raw>{option.title}</Copy>
        </span>
      </button>
    );
  }

  renderSteps() {
    const { activeStep } = this.state;
    const { items } = this.props;
    const sliderConfig = getSliderConfig();
    return (
      <div className={suitcss({ descendantName: 'steps' }, this)} >
        <Slider
          theme="dark"
          paginationTheme="numbers"
          pagination
          activeIndex={activeStep}
          config={sliderConfig}
        >
          {items.map((step, stepIndex) => (
            <SliderSlide key={stepIndex}>
              <div className={suitcss({ descendantName: 'step' }, this)} >
                {step.answers.map((option, optionIndex) =>
                  this.renderOption(option, optionIndex, stepIndex),
                )}
              </div>
            </SliderSlide>
          ))}
        </Slider>
      </div>
    );
  }

  render() {
    const { headline, copy, items, link, ui, trackingId } = this.props;
    const { activeStep, totalSteps } = this.state;
    const activeStepData = activeStep < totalSteps && items[activeStep];
    return (
      <div className={suitcss({ modifiers: [!activeStepData && 'results'] }, this)} data-tracking={trackingId} >
        {activeStepData &&
          <Headline
            className={suitcss({ descendantName: 'headline' }, this)}
            utilities={['alignCenter']}
            size="xxl"
            inverted
            raw
          >
            {headline}
          </Headline>
        }
        {activeStepData &&
          <Copy
            className={suitcss({ descendantName: 'copy' }, this)}
            utilities={['alignCenter']}
            inverted
            embedded
            raw
          >
            {copy}
          </Copy>
        }
        {!activeStepData &&
          <Headline
            className={suitcss({ descendantName: 'headline', utilities: ['alignCenter'] }, this)}
            size="xxl"
            inverted
            raw
          >
            {`${ui.hldResultHeadline}</br><strong><em>${this.getTelephoneCode()}</em></strong>`}
          </Headline>
        }
        {!activeStepData &&
          <Copy
            className={suitcss({ descendantName: 'copy', utilities: ['alignCenter'] }, this)}
            inverted
            embedded
            raw
          >
            {ui.hldResultCopy}
          </Copy>
        }
        <div className={suitcss({ descendantName: 'content' }, this)} >
          {activeStepData && (
            <Headline
              className={suitcss({ descendantName: 'title' }, this)}
              utilities={['alignCenter', 'colorPrimary']}
              size="xs"
              inverted
              raw
            >
              {activeStepData.title}
            </Headline>
          )}
          {activeStepData && (
            <Headline
              className={suitcss({ descendantName: 'headline' }, this)}
              utilities={['alignCenter', 'colorDefault', 'lowercase']}
              size="m"
              inverted
              embedded
              raw
            >
              {activeStepData.question}
            </Headline>
          )}
          {activeStepData && this.renderSteps()}
          {!activeStepData && (
            <div className={suitcss({ descendantName: 'result', utilities: ['alignCenter'] }, this)} >
              <Headline
                className={suitcss({ descendantName: 'headline' }, this)}
                size="l"
                inverted
                embedded
                raw
              >
                {ui.hldPhoneNumber}
              </Headline>
              <div
                className={suitcss({ descendantName: 'link' }, this)}
              >
                <Link
                  to={link.url}
                  asButton
                  buttonFilled
                  data-tracking={trackingId}
                  {...{ [`data-${trackingId}-value`]: 'call' }}
                >
                  {link.title}
                </Link>
              </div>
              <Copy
                className={suitcss({ descendantName: 'hint' }, this)}
                utilities={['colorGray50']}
                size="secondary"
                inverted
                embedded
                raw
              >
                {ui.hldResultHint}
              </Copy>
            </div>
          )}
        </div>
        <div
          className={suitcss({
            descendantName: 'footer',
            utilities: ['alignCenter'],
            states: [activeStep > 0 && 'active'],
          }, this)}
        >
          <Link
            element="button"
            utilities={['arrowLeft']}
            onClick={this.onPreviousStep}
            data-tracking={trackingId}
            {...{ [`data-${trackingId}-value`]: 'back' }}
          >
            {ui.guiWordBack}
          </Link>
        </div>
      </div>
    );
  }
}

HotlineDeals.propTypes = {
  headline: PropTypes.string.isRequired,
  copy: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  link: PropTypes.object.isRequired,
  trackingId: PropTypes.string.isRequired,
  ui: PropTypes.shape({
    hldResultHeadline: PropTypes.string.isRequired,
    hldResultCopy: PropTypes.string.isRequired,
    hldResultHint: PropTypes.string.isRequired,
    hldPhoneNumber: PropTypes.string.isRequired,
    guiWordBack: PropTypes.string.isRequired,
  }),
};

HotlineDeals.defaultProps = {
  items: [],
};

export default HotlineDeals;
