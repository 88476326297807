import {
  REGISTER_CAPTCHA,
  UNREGISTER_CAPTCHA,
} from '../actions/captcha/captcha';

const defaultState = {
  challenge: null,
  image: {
    data: null,
    mime: null,
  },
  token: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case REGISTER_CAPTCHA:
      return { ...state, ...action.payload };

    case UNREGISTER_CAPTCHA:
      return { ...defaultState };

    default:
      return state;
  }
};
