/* global __karma__ */

export const isProduction = process.env.NODE_ENV === 'production';
export const isTesting =
  (process.browser && typeof __karma__ !== 'undefined') || process.env.NODE_ENV === 'test';

export const shouldBeVerbose = process.browser && !isTesting && !isProduction;

/**
 * If we are in production mode and in a browser, this function will not log anything at all.
 *
 * @param {*} args - will be passed to the console
 */
export const devWarning = shouldBeVerbose ? (...args) => console.warn(...args) : () => null;

/**
 * If we are in production mode and in a browser, this function will not log anything at all.
 *
 * @param {*} args - will be passed to the console
 */
export const devInfo = shouldBeVerbose ? (...args) => console.info(...args) : () => null;
