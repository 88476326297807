import { formatDate } from './date';

export const getDocumentUnreadCount = (user) => {
  const { documents } = user.inbox;
  if (!documents) return 0;
  return documents.reduce((acc, d) => acc + !d.alreadyAccessed, 0);
};

export const isTariffRenewable = tVO =>
  tVO.contractRenewalWithHardwareOnly ||
  tVO.contractRenewalWithSimOnly;
export const isTariffChangeable = tVO => tVO.contractChangeable;
export const isTariffBookable = tVO => isTariffRenewable(tVO) || isTariffChangeable(tVO);
export const isTariffBooked = tVO => !tVO.bookable;

export const getUserLoyaltySettings = (user, ui) => {
  const { customerData } = user;
  if (!customerData || !customerData.rewards || !customerData.rewards.loyalty || !ui) {
    return null;
  }
  const { activationDate, rewards } = customerData;
  const { loyalty } = rewards;
  const { changeDate } = loyalty;

  return {
    level: loyalty.level,
    headline: ui[`loyaltyLevel${loyalty.level}Headline`],
    status: ui[`loyaltyLevel${loyalty.level}Status`].replace('{DATE}', changeDate ? formatDate(changeDate) : formatDate(activationDate)),
    copy: ui[`txtLoyaltyLevel${loyalty.level}Copy`],
  };
};
