import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';

import connectUI from '../../basics/ui/UIConnector';
import Headline from '../../basics/text/TextHeadline';
import Copy from '../../basics/text/TextCopy';

import TariffItemFull from '../tariff/TariffItemFull';
import HardwareItemCompact from './HardwareItemCompact';

import Slider from '../../basics/slider/Slider';
import SliderSlide from '../../basics/slider/SliderSlide';

import {
  shape as hardwareShape,
  list as hardwareListPropType,
} from '../../../propTypes/hardware';

import {
  shape as tariffShape,
  list as tariffListPropType,
} from '../../../propTypes/tariff';
import { ICON_WHATSAPP_SHARE_BUTTON, QUERY_SELECTED_HARDWARE } from '../../../helpers/constants';
import { bindQueryParams } from '../../../helpers/url';
import Callout from '../../../containers/callout/Callout';
import Link from '../../basics/text/TextLink';
import { createWhatsAppShareLink } from '../../../helpers/socialMediaLinkShare';
import { selectProductSheet } from '../../../helpers/entity';
import HardwareTariffsTabs from './HardwareTariffsTabs';
import { toFullCurrency } from '../../../helpers/money';

class HardwareTariffs extends PureComponent {
  static getDerivedStateFromProps(props) {
    const { tabs, location } = props;

    if (tabs.length && location.hash) {
      const locationHash = location.hash;
      const hash = locationHash && locationHash.substring(1);
      const index = tabs.findIndex((tab) => tab.hash === hash);
      if (index >= 0) {
        return {
          activeTab: index,
        };
      }
    }
    return {};
  }

  constructor(props, context) {
    super(props, context);
    this.state = {
      activeTab: this.setInitialSelectedTab(),
    };
    this.changeSlideHandler = this.changeSlideHandler.bind(this);
    this.onClickTabItem = this.onClickTabItem.bind(this);
  }


  onClickTabItem = (tabIndex) => {
    const { location, tabs, onClickTabItem } = this.props;
    if (location.pathname && tabs[tabIndex].hash) {
      onClickTabItem(tabs[tabIndex].hash);
    }
    this.setState({ activeTab: tabIndex });
  };

  setInitialSelectedTab() {
    const { tabs, selectedTariff } = this.props;

    for (let i = 0; i < tabs.length; i++) {
      const temp = tabs[i].tariffs.filter(tariff =>
        tariff.eid === selectedTariff.eid);
      if (temp.length > 0) {
        return i;
      }
    }
    return 0;
  }

  changeSlideHandler(index) {
    const { tariffList, onSelect } = this.props;
    const selectedTariff = tariffList[index];
    onSelect({
      eid: selectedTariff.eid,
      etype: selectedTariff.etype,
    });
  }

  renderTariffItem(tariff, utilities) {
    const {
      moduleId,
      selectedHardware,
      selectedTariff,
      onSelect,
      onSubmit,
      isMediaS,
      isMediaML,
      ui,
    } = this.props;
    const isSelected = selectedTariff.eid === tariff.eid;
    const linkPrimary = {
      label: isSelected ? ui.guiAddCart : ui.guiWordSelect,
      onClick: isSelected
        ? () => onSubmit([
          selectedHardware,
          selectedTariff,
        ])
        : () => onSelect({
          eid: tariff.eid,
          etype: tariff.etype,
        }),
    };
    const linkSecondary = {
      label: ui.guiWordDetails,
      to: bindQueryParams(tariff.urlDetails, {
        [QUERY_SELECTED_HARDWARE]: selectedHardware && selectedHardware.eid,
      }),
    };

    return (
      <div
        key={tariff.eid}
        className={suitcss({
          componentName: 'HardwareTariffs',
          descendantName: 'item',
          states: [isSelected && 'selected'],
          utilities,
        })}
      >
        <Callout
          theme="sticker"
          targets={[tariff.eid]}
        />
        <TariffItemFull
          moduleId={moduleId}
          tariff={tariff}
          linkPrimary={linkPrimary}
          linkSecondary={linkSecondary}
          isSelected={isSelected}
          isMediaS={isMediaS}
          isMediaML={isMediaML}
          productInformationSheetLink={selectProductSheet(tariff, 'offerList')}
        />
      </div>
    );
  }

  renderHeader() {
    const { headline, primaryModule } = this.props;
    return (
      <div
        className={suitcss({
          descendantName: 'header',
        }, this)}
      >
        <div
          className={suitcss({
            descendantName: 'headline',
          }, this)}
        >
          {headline && <Headline
            size="l"
            utilities={['uppercase', 'alignCenter']}
            element={primaryModule ? 'h1' : 'h2'}
            embedded
            raw
          >
            {headline}
          </Headline>
          }
        </div>
      </div>
    );
  }

  renderFooter() {
    const { footer, ui, selectedTariff, selectedHardware } = this.props;
    const whatAppLink = createWhatsAppShareLink(selectedTariff, selectedHardware);
    return (
      <div
        className={suitcss({
          descendantName: 'footer',
        }, this)}
      >
        <div
          className={suitcss({
            descendantName: 'button',

          }, this)}
        >
          <Link
            icon={ICON_WHATSAPP_SHARE_BUTTON}
            asShareButton
            utilities={['flex', 'itemsCenter', 'justifyCenter', 'sMarginBottom', 'mlHidden']}
            href={whatAppLink}
          >
            {ui.guiButtonWhatsapp}
          </Link>
        </div>
        <div
          className={suitcss({
            descendantName: 'copy',
          }, this)}
        >
          <Copy
            size="secondary"
            raw
            utilities={['alignCenter']}
          >
            {footer}
          </Copy>
        </div>
      </div>
    );
  }

  renderContent() {
    const {
      hardwareName,
      hardwareList,
      selectedHardware,
      selectedTariff,
      onHardwareChange,
      isNew,
      dispatch,
      shippingFee,
    } = this.props;

    return (
      <div
        className={suitcss({
          descendantName: 'content',
        }, this)}
      >
        <div
          className={suitcss({
            componentName: 'Hardware',
            descendantName: 'item',
          })}
        >
          <HardwareItemCompact
            name={hardwareName}
            hardwareList={hardwareList}
            selectedHardware={selectedHardware}
            selectedTariffId={selectedTariff ? selectedTariff.eid : null}
            onChange={onHardwareChange}
            isNew={isNew}
            dispatch={dispatch}
            shippingFee={shippingFee}
          />
        </div>
      </div>
    );
  }

  renderTabs() {
    const { tabs, selectedTariff } = this.props;

    return (
      <div>
        <div
          className={suitcss({
            descendantName: 'contenttabs',
          }, this)}
        >
          {tabs.length && tabs.map((tab, index) => (
            <HardwareTariffsTabs
              activeTab={tabs[this.state.activeTab].label}
              identifier={index}
              label={tab.label}
              onClick={this.onClickTabItem}
              key={index}
              colorScheme={this.props.colorScheme}
              showCheck={selectedTariff}
            />))}
        </div>
        {this.renderSubcontent(tabs[this.state.activeTab].tariffs)}
      </div>
    );
  }

  renderSubcontent(tariffList) {
    const {
      selectedTariff,
      isMediaSM,
      isMediaS,
      ui,
    } = this.props;
    const selectedIndex = isMediaSM && selectedTariff
      ? tariffList.findIndex(item => item.eid === selectedTariff.eid)
      : null;

    const sliderPaginationElement = (index) =>
      `<div>
         <div class="slider-pagination-copy">${tariffList[index].name}</div>
         <span class="slider-pagination-price">${toFullCurrency(tariffList[index].paymentFee)}</span>
         <span class="slider-pagination-price"> ${ui.guiWordMonthlyAbbr}</span>
       </div>`;

    return (
      <div
        className={suitcss({
          descendantName: 'subcontent',
        }, this)}
      >
        {tariffList.length > 3 && (
          <div
            className={suitcss({
              descendantName: 'slider',
              utilities: ['hidden', 'lBlock'],
            }, this)}
          >
            <Slider theme="dark" pagination arrows arrowsAligned={false}>
              {tariffList.map((tariff, index) => (
                <SliderSlide key={index}>
                  {this.renderTariffItem(tariff, [
                    (tariffList.length > 1) && 'hidden',
                    (tariffList.length > 1) && 'lBlock',
                  ])}
                </SliderSlide>
              ))}
            </Slider>
          </div>
        )}
        {tariffList.length <= 3 && (
          tariffList.map(tariff => this.renderTariffItem(tariff, [
            (tariffList.length > 1) && 'hidden',
            (tariffList.length > 1) && 'lBlock',
          ]))
        )}
        {tariffList.length > 1 && (
          <div
            className={suitcss({
              descendantName: 'slider',
              utilities: ['lHidden'],
            }, this)}
          >
            <Slider
              changeEndHandler={isMediaSM ? this.changeSlideHandler : null}
              activeIndex={selectedIndex}
              key={this.state.activeTab}
              config={{
                autoHeight: true,
                centeredSlides: true,
                slidesPerView: isMediaS ? 1.2 : 'auto',
                spaceBetween: 8,
              }}
              pagination={{
                alignTop: true,
                renderBullet: (index, className) => (
                    `<span class="${className}">` +
                    `${sliderPaginationElement(index)}` +
                    '</span>'
                  ),
              }}
            >
              {tariffList.map((tariff, index) => (
                <SliderSlide key={index}>
                  {this.renderTariffItem(tariff)}
                </SliderSlide>
              ))}
            </Slider>
          </div>
        )}

      </div>
    );
  }

  render() {
    return (
      <section className={suitcss({}, this)}>
        {this.renderHeader()}
        <div
          className={suitcss({
            descendantName: 'inner',
          }, this)}
        >
          {this.renderContent()}
          <div
            className={suitcss({
              descendantName: 'wrapper',
            }, this)}
          >
            {this.renderTabs()}
          </div>
        </div>
        {this.renderFooter()}
      </section>
    );
  }
}

HardwareTariffs.propTypes = {
  moduleId: PropTypes.string.isRequired,
  headline: PropTypes.string,
  footer: PropTypes.string.isRequired,
  hardwareName: PropTypes.string.isRequired,
  hardwareList: hardwareListPropType.isRequired,
  tariffList: tariffListPropType.isRequired,
  selectedTariff: tariffShape,
  selectedHardware: hardwareShape.isRequired,
  primaryModule: PropTypes.bool.isRequired,
  onHardwareChange: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isMediaS: PropTypes.bool,
  isMediaSM: PropTypes.bool,
  isMediaML: PropTypes.bool,
  ui: PropTypes.shape({
    guiWordDetails: PropTypes.string.isRequired,
    guiWordSelect: PropTypes.string.isRequired,
    guiAddCart: PropTypes.string.isRequired,
    guiWordMonthlyAbbr: PropTypes.string.isRequired,
  }),
  isNew: PropTypes.bool,
  productInformationSheetLink: PropTypes.object,
  colorScheme: PropTypes.oneOf(['dark', 'medium', 'light']),
  tabs: PropTypes.arrayOf(PropTypes.object),
  location: PropTypes.object,
  onClickTabItem: PropTypes.func,
  dispatch: PropTypes.func,
  shippingFee: PropTypes.object,
};

export default connectUI()(HardwareTariffs);
