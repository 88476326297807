import PageModule from './PageModule';

/**
 * Module to create a headline group including headline and an optional subline.
 * @see https://confluence.db-n.com/x/nJDc
 */
class ContentHeadlineModule extends PageModule {
  constructor(moduleData) {
    super(import(/* webpackChunkName:"ContentHeadline" */ '../../containers/content/ContentHeadline'), moduleData);
  }

  /**
   * @override
   */
  static isCritical() {
    return false;
  }
}

export default ContentHeadlineModule;
