import { isAccountUrl } from '../../config/api';
import { camelCase } from '../../helpers/str';

/**
 * Stores data about the application state at the time the log entry is created.
 */
export default class LogContext {
  /**
   * The form that is active at the time the log entry is created.
   *
   * @param {string} formName
   * @param {string} formStepName
   */
  setForm(formName, formStepName) {
    this.formName = formName;
    this.formStepName = formStepName;
  }

  /**
   * A unique and specific term that identifies the process.
   *
   * @param processId
   */
  setProcessId(processId) {
    this.processId = processId;
  }

  /**
   * Enrich the context data with data from redux state
   * @param {Object} reduxState
   */
  setGlobalContext(reduxState) {
    const { site, routing } = reduxState;
    const { contractRenewal, sitemap } = site;

    this.isContractRenewal = contractRenewal.isInProgress;

    const location = routing.locationBeforeTransitions;
    this.realm = (isAccountUrl(location) || this.isContractRenewal) ? 'account' : 'presales';

    const [routeName] = Object
      .entries(sitemap)
      .find(([, route]) => route.url === location.pathname) || [];
    this.routeName = routeName;
  }

  /**
   * @see https://confluence.db-n.com/x/HqL3
   */
  toString() {
    const context = [];

    if (this.realm) {
      context.push(this.realm);

      if (this.isContractRenewal) {
        context.push('contractRenewal');
      }
    }

    if (this.formName) {
      context.push(this.formName);
      if (this.formStepName) {
        context.push(this.formStepName);
      }
    } else if (this.routeName) {
      // for the sake of conciseness, strip unecessary words from route name
      // (given that the route follows the standard naming convention)
      context.push(camelCase((/(.*)Route/i.exec(this.routeName) || [])[1] || this.routeName));
    }

    return context.join(LogContext.CONTEXT_DELIMETER);
  }
}

LogContext.CONTEXT_DELIMETER = '.';
