import { trackMyState } from '../../../actions/tracking/event';
import {
  MYTRACK_LOGIN_GIVE_PASSWORD_SMS,
  MYTRACK_LOGIN_GIVE_PASSWORD_EMAIL,
} from '../../../helpers/constants';
import * as smsConfig from './formConfigSms';
import {
  getUpdateTimestampAction,
} from './helpers';
import PasswordResetFormOptionsSelect
  from '../../../components/compositions/account/passwordReset/PasswordResetFormOptionsSelect';
import initForm from '../../form/FormInitializer';
import PasswordResetSmsRequest from '../../../model/requests/PasswordResetSmsRequest';
import PasswordResetEmailRequest from '../../../model/requests/PasswordResetEmailRequest';
import { send } from '../../../actions/request/send';
import FormConfigProps from '../../../model/form/FormConfigProps';

const createFieldMap = (ui) => ({
  selectedOption: {
    name: 'selected_option',
    shouldSubmit: false,
    options: [
      { label: ui.myPasswordLostChoiceSms, value: 'sms' },
      { label: ui.myPasswordLostChoiceEmail, value: 'email' },
    ],
    validation: {
      isRequired: true,
      oneOf: ['sms', 'email'],
    },
  },
});

// init component once, outside
const component = initForm()(PasswordResetFormOptionsSelect);

export const id = 'optionsSelect';

const makeSubmit = (stepConfig, props) => async (values, dispatch) => {
  const { form, fieldMap, formValues } = props;
  const msisdn = values[fieldMap.msisdn.name];
  const selectedOption = values[fieldMap.selectedOption.name];
  const isSmsOptionSelected = values[fieldMap.selectedOption.name] === 'sms';
  const tokenRequest = isSmsOptionSelected
    ? new PasswordResetSmsRequest(msisdn)
    : new PasswordResetEmailRequest(msisdn);
  let cooldown = null;

  if (selectedOption === 'sms') {
    dispatch(trackMyState(MYTRACK_LOGIN_GIVE_PASSWORD_SMS));
  } else {
    dispatch(trackMyState(MYTRACK_LOGIN_GIVE_PASSWORD_EMAIL));
  }

  const response = (await dispatch(send(tokenRequest))).body.data;
  cooldown = response.cooldown;

  dispatch(getUpdateTimestampAction(form, fieldMap, formValues, selectedOption, cooldown));

  return smsConfig.id;
};

/**
 * @return {FormConfig}
 */
export const mapStateToFormConfig = (state, props) => ({
  component,
  fieldMap: createFieldMap(props.ui),
  makeSubmit,
  formConfigProps: new FormConfigProps({
    labelSubmit: state.ui.myPasswordLostChoiceSubmit,
  }),
});

export default mapStateToFormConfig;
