import React from 'react';
import PropTypes from 'prop-types';
import { preventDefaultKeyEnter } from '../../../helpers/functional';

const Form = props => {
  const { onSubmit, children } = props; // eslint-disable-line react/prop-types
  return (
    <form
      noValidate
      method="post" // https://jira.db-n.com/browse/OT-4102
      onSubmit={onSubmit}
      onKeyDown={preventDefaultKeyEnter(onSubmit)}
    >
      {children}
    </form>
  );
};

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,
};

export default Form;
