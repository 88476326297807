/* global document */
import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Field } from 'redux-form';
import { showTemplateDialog } from '../../../../actions/page/dialog';
import { send } from '../../../../actions/request/send';
import { showESimStatusRefresh } from '../../../../actions/user/eSim';
import {
  DIALOG_ID_ESIM_SETTINGS,
  REQUEST_METHOD_DELETE,
  REQUEST_METHOD_POST,
} from '../../../../helpers/constants';
import SimCardESimPushRequest from '../../../../model/requests/SimCardESimPushRequest';
import Copy from '../../../basics/text/TextCopy';
import TextMessage from '../../../basics/text/TextMessage';
import TextRaw from '../../../basics/text/TextRaw';
import connectUI from '../../../basics/ui/UIConnector';
import suitcss from '../../../../helpers/suitcss';
import FieldInput from '../../../basics/form/FieldInput';
import Link from '../../../basics/text/TextLink';
import ContentLoader from '../../content/ContentLoader';

const componentName = 'MyESimPushForm';

class MyESimPushForm extends PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      hasPushFailed: false,
      isButtonClicked: false,
      isLoading: false,
      currentEid: null,
    };
    this.onKeyDown = this.onKeyDown.bind(this);
  }

  componentDidMount() {
    this.showProgressState();
    document.addEventListener('keydown', this.onKeyDown, false);
  }

  componentDidUpdate() {
    this.showProgressState();
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown, false);
  }

  onKeyDown(e) {
    if (e.code === 'Enter') {
      this.pushEid();
    }
  }

  onInput() {
    this.setState({ hasPushFailed: false });
  }

  getESimStatus() {
    const { fetchESimStatus } = this.props;
    fetchESimStatus()
      .then(data => {
        // repeat fetch status as long as there are no possible actions,
        // because after push action there are no actions available for an undefined time
        if (!data.possibleActions.length) {
          this.statusTimeout = setTimeout(() => {
            this.getESimStatus();
          }, 5000);
          this.setState({ isLoading: true });
        } else {
          this.setState({ isLoading: false });
          clearTimeout(this.statusTimeout);
        }
      });
  }

  showProgressState() {
    const { isESimUndefinedProgress } = this.props;
    // if there is no possible action after push, show loading spinner
    this.setState({ isLoading: isESimUndefinedProgress });
  }

  showStatusRefresh() {
    const { dispatch } = this.props;
    if (this.statusRefreshTimeout) {
      clearTimeout(this.timeout);
    }
    this.statusRefreshTimeout = setTimeout(() => {
      dispatch(showESimStatusRefresh(true));
    }, 60000);
  }

  async pushEid() {
    const {
      fieldMap,
      formValues,
      dispatch,
      msisdn,
      statusRefresh,
      onClearInput,
      fetchESimStatus,
      isESimReadyToPush,
    } = this.props;
    const fieldName = fieldMap.eSimEid.name;
    const eid = formValues[fieldName];
    this.setState({ isButtonClicked: true });
    await fetchESimStatus();
    if (!isESimReadyToPush) return;
    if (eid) {
      try {
        await dispatch(send(new SimCardESimPushRequest(msisdn, {
          method: REQUEST_METHOD_POST,
          payload: { eid },
          preventDefaultErrorHandling: true,
        })));
        this.setState({ hasPushFailed: false });
        this.getESimStatus();
        if (!statusRefresh) this.showStatusRefresh();
      } catch (e) {
        this.setState({
          hasPushFailed: true,
          isButtonClicked: false,
          currentEid: eid,
        });
      }
    }
    onClearInput();
  }

  async deAssignEid() {
    const {
      dispatch, msisdn, onClearInput, fetchESimStatus, isESimReadyToDeAssign,
    } = this.props;
    await fetchESimStatus();
    if (!isESimReadyToDeAssign) return;
    await dispatch(send(new SimCardESimPushRequest(msisdn, {
      method: REQUEST_METHOD_DELETE,
    })));
    this.getESimStatus();
    this.setState({ hasPushFailed: false, isButtonClicked: false });
    onClearInput();
  }

  render() {
    const {
      fieldMap,
      ui,
      dispatch,
      isInputValidated,
      isESimReadyToDeAssign,
      isESimReadyToPush,
      isESimUndefinedProgress,
    } = this.props;
    const { hasPushFailed, isButtonClicked, isLoading, currentEid } = this.state;
    const errorMessage = ui.myEsimActivationPushError.replace('{EID}', `<span class="u-textWrapWord">${currentEid}</span>`);

    return (
      <div className={suitcss({ componentName })}>
        <ContentLoader
          className={suitcss({ componentName, descendantName: 'content', utilities: ['flex', 'dirColumn'] })}
          isLoaded={!isLoading}
        >
          {isESimReadyToDeAssign && !hasPushFailed && (
            <TextMessage Component="div" level="success">
              {ui.myEsimActivationPushSuccess}
            </TextMessage>
          )}
          {isESimReadyToPush && hasPushFailed && (
            <TextMessage Component="div" level="error">
              <TextRaw>{errorMessage}</TextRaw>
            </TextMessage>
          )}
          {((isESimReadyToPush && hasPushFailed)
            || (isESimReadyToPush && !isButtonClicked)) && (
            <Fragment>
              <Field
                {...fieldMap.eSimEid}
                component={FieldInput}
                autocomplete="off"
                maxLength={32}
                onInput={() => this.onInput()}
              />
              <Link
                onClick={() => this.pushEid()}
                element="button"
                asButton
                buttonFilled
                withoutArrow
                utilities={['marginTopS']}
                isDisabled={isESimReadyToDeAssign || !isInputValidated || isButtonClicked}
              >
                {ui.myEsimActivationPushCta}
              </Link>
            </Fragment>
          )}
          {isESimReadyToDeAssign && (
            <Copy utilities={['marginBottomS']}>
              <TextRaw element="span">{ui.myEsimActivationPushInfo}</TextRaw>
              <Link
                className={suitcss(
                  { componentName, descendantName: 'infoIcon' },
                  this,
                )}
                element="button"
                onClick={() => dispatch(showTemplateDialog(DIALOG_ID_ESIM_SETTINGS))}
                withoutArrow
                withoutStyle
                icon="/icons/content-info.svg"
                size="small"
              />
            </Copy>
          )}
          {isESimReadyToDeAssign && (
            <Link
              utilities={['selfBaseline']}
              element="button"
              withoutArrow
              onClick={() => this.deAssignEid(this)}
            >
              {ui.myEsimActivationPushRevert}
            </Link>
          )}
        </ContentLoader>
        {isESimUndefinedProgress && (
          <Copy utilities={['colorPrimary', 'paddingTopS', 'textSizeSecondary', 'alignCenter']}>
            {ui.myEsimActivationOngoingActivationInfo}
          </Copy>
        )}
      </div>
    );
  }
}

MyESimPushForm.propTypes = {
  fieldMap: PropTypes.object.isRequired,
  ui: PropTypes.object,
  formValues: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  eSimData: PropTypes.object,
  isESimReadyToDeAssign: PropTypes.bool,
  isESimReadyToPush: PropTypes.bool,
  isESimUndefinedProgress: PropTypes.bool,
  msisdn: PropTypes.string,
  form: PropTypes.string,
  isInputValidated: PropTypes.bool,
  onClearInput: PropTypes.func,
  fetchESimStatus: PropTypes.func,
  statusRefresh: PropTypes.bool,
};

export default compose(connectUI())(MyESimPushForm);
