import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getDisplayName } from '../../helpers/component';
import { log } from '../../actions/tracking/event';
import ErrorLogEntry from '../../model/logging/ErrorLogEntry';
import { ERROR_DISPLAY_STYLE_HIDDEN } from '../../helpers/constants';
import { fetchEntityById } from '../../actions/request/registry';

/**
 * Entity props is an object with keys that may hold arrays or values.
 * This function will transform such kind of object into a flat array.
 * @param  {object} entityProps
 * @return {array}
 */
const entityPropsToArray = (entityProps) => {
  const keys = Object.keys(entityProps);
  return [].concat(...keys.map(
    name => entityProps[name],
  ));
};

const enrichEntities = (makeMapStateToEntities, options = {}) => (WrappedComponent) => {
  class PreloadEntity extends PureComponent {
    constructor(...args) {
      super(...args);
      this.mounted = false;
      this.state = { isLoading: true };
    }

    componentDidMount() {
      this.mounted = true;
      this.loadEntityProps(this.props.entityProps);
    }

    componentWillReceiveProps(nextProps) {
      const nextEntities = entityPropsToArray(nextProps.entityProps);
      const shallowEntities = this.getShallowEntities(nextEntities, nextProps.storeEntities);
      if (this.state.isLoading || !shallowEntities.length) {
        // do not fetch if already in progress or nothing needs to be fetched
        return;
      }
      this.loadEntityProps(nextProps.entityProps);
    }

    componentWillUnmount() {
      this.mounted = false;
    }

    /**
     * try to resolve entities from store and return those that could not be resolved
     * @param  {array} entities Array of entities like objects with etype and eid
     * @return {array}          Array of entities like objects that where not accessable from store
     */
    getShallowEntities(entities, storeEntities = this.props.storeEntities) {
      if (!entities) {
        return [];
      }
      const enrichedEntities = Array.isArray(entities) ?
        entities.map(entity => this.enrichEntityWithStore(entity, storeEntities)) :
        [this.enrichEntityWithStore(entities, storeEntities)];

      return enrichedEntities
        .filter(Boolean)
        .filter(entity => entity.shallow);
    }

    enrichEntityWithStore(entity, storeEntities = this.props.storeEntities) {
      if (!entity) {
        return null;
      }
      const entityTypeStore = storeEntities[entity.etype];
      const enrchedEntity = entityTypeStore && entityTypeStore[entity.eid];
      // try to access the entity and fire load action if not in store
      if (!enrchedEntity) {
        return Object.assign({ shallow: true }, entity);
      }
      return enrchedEntity;
    }


    loadEntities(entities) {
      this.setState({ isLoading: true }, async () => {
        const { dispatch } = this.props;
        const shallowEntities = this.getShallowEntities(entities);
        try {
          await Promise.all(shallowEntities.map(entity => dispatch(fetchEntityById(
            entity.eid,
            false,
            { isBlocking: false },
          ))));
        } catch (error) {
          // do not show error to user; instead log it and execute any registered failure handler
          dispatch(log(new ErrorLogEntry(error, error.message, ERROR_DISPLAY_STYLE_HIDDEN)));
          if (options.onFailure) {
            await dispatch(options.onFailure());
          }
        }
        if (this.mounted) {
          this.setState({
            isLoading: false,
          });
        }
      });
    }

    loadEntityProps(entityProps) {
      return this.loadEntities(entityPropsToArray(entityProps));
    }

    enrichEntityMapWithStore() {
      const { entityProps } = this.props;
      const keys = Object.keys(entityProps);
      return keys.reduce((props, name) => {
        const entities = Array.isArray(entityProps[name]) ?
          entityProps[name].map(entity => this.enrichEntityWithStore(entity)) :
          this.enrichEntityWithStore(entityProps[name]);
        return Object.assign({ [name]: entities }, props);
      }, {});
    }

    // @todo If not all entities are loaded jet, we do not render anything at the moment.
    //       It would be a great user experience if the module outline would
    //       already show up with dummy content or at least with some margin so
    //       that the page is not jumping after load and the user can already see
    //       something
    render() {
      if (this.state.isLoading) {
        return null;
      }
      const cleanProps = Object.assign({}, this.props);
      delete cleanProps.storeEntities;
      delete cleanProps.entityProps;
      delete cleanProps.dispatch;
      delete cleanProps.onFailure;
      return (
        <WrappedComponent
          {...cleanProps}
          {...this.enrichEntityMapWithStore()}
        />
      );
    }
  }

  PreloadEntity.displayName = `PreloadEntity(${getDisplayName(WrappedComponent)})`;
  PreloadEntity.WrappedComponent = WrappedComponent;
  PreloadEntity.propTypes = {
    storeEntities: PropTypes.object.isRequired,
    entityProps: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    onFailure: PropTypes.func,
  };

  const makeMapStateToProps = (makeState, makeOwnProps) => {
    // allow mapStateToEntities to create its own closure for a specific instance
    // to enable functionality like memoization
    let mapStateToEntities = makeMapStateToEntities(makeState, makeOwnProps);
    if (typeof mapStateToEntities !== 'function') {
      mapStateToEntities = makeMapStateToEntities;
    }

    return (state, ownProps) => ({
      storeEntities: state.entities,
      entityProps: mapStateToEntities(state, ownProps),
    });
  };

  const mapDispatchToProps = (dispatch) => ({ dispatch });

  return connect(makeMapStateToProps, mapDispatchToProps)(PreloadEntity);
};

export default enrichEntities;
