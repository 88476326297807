import QueueableRequest from './QueueableRequest';
import { MOBILE_PROVIDERS_ENDPOINT } from '../../config/api';
import { bindParamsToRoute } from '../../helpers/url';
import { MARKET_ALL } from '../../helpers/constants';

export default class MobileProvidersRequest extends QueueableRequest {
  constructor(market = MARKET_ALL) {
    super(bindParamsToRoute(MOBILE_PROVIDERS_ENDPOINT, { market }), {
      isBlocking: true,
    });
  }
}
