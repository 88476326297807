import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Link from '../../../basics/text/TextLink';


class PasswordResetCooldownButton extends Component {

  componentDidMount() {
    this.intervalId = setInterval(() => this.forceUpdate(), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  render() {
    const { timestamp, label, onClick, data } = this.props;
    const now = Date.now();
    const enabled = now > timestamp;

    return (
      <Link
        element="button"
        isDisabled={!enabled}
        onClick={e => { e.preventDefault(); if (enabled) onClick(); }}
        asButton
        buttonFilled={enabled}
        data={data}
      >
        {label}
      </Link>
    );
  }
}

PasswordResetCooldownButton.propTypes = {
  timestamp: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  data: PropTypes.object,
};

export default PasswordResetCooldownButton;
