import React, { Component } from 'react';
import lottie from 'lottie-web';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';
import { capitalize } from '../../../helpers/str';
import connectViewport from './ServiceViewportConnector';

/**
 *
 * New Lottie Service - only available in ContentStage for now
 *
 */

class ServiceLottieAnimation extends Component {
  constructor(props) {
    super(props);
    this.onLoadAnimation = this.onLoadAnimation.bind(this);
    this.ref = React.createRef();
    this.animation = null;
    this.state = { isLoaded: false };
  }

  componentDidMount() {
    fetch(this.props.path)
      .then(response => response.json())
      .then(animationData => {
        this.animation = lottie.loadAnimation({
          container: this.ref.current,
          loop: this.props.loop,
          autoplay: this.props.autoplay,
          animationData,
        });
        this.animation.addEventListener('DOMLoaded', this.onLoadAnimation);
      })
      .catch((error) => this.props.errorCallback(error));
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.client.width !== this.props.client.width) {
      this.resizeAnimation();
    }
  }

  componentWillUnmount() {
    if (this.animation) {
      this.animation.removeEventListener('DOMLoaded', this.onLoadAnimation);
      this.animation.stop();
      this.animation.destroy();
    }
  }

  onLoadAnimation() {
    this.setState({ isLoaded: true }, () => {
      this.resizeAnimation();
    });
  }

  resizeAnimation() {
    if (!this.animation) {
      return;
    }
    this.animation.wrapper.children[0].style.maxHeight = `${this.ref.current.clientHeight}px`;
  }

  render() {
    const { size, alignV } = this.props;
    const { isLoaded } = this.state;
    const states = [
      isLoaded && 'loaded',
    ];
    const modifiers = [
      size && `size${capitalize(size)}`,
      alignV && `align${capitalize(alignV)}`,
    ];

    return (
      <div
        className={suitcss({ modifiers, states }, this)}
        ref={this.ref}
      />
    );
  }
}

ServiceLottieAnimation.propTypes = {
  loop: PropTypes.bool,
  autoplay: PropTypes.bool,
  path: PropTypes.string,
  alignV: PropTypes.oneOf(['top', 'bottom', '']),
  size: PropTypes.oneOf(['cover', '']),
  client: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  errorCallback: PropTypes.func,
};


ServiceLottieAnimation.defaultProps = {
  loop: false,
  autoplay: true,
  size: '',
  alignV: 'bottom',
};

export default connectViewport({ listenTo: ['client'] })(ServiceLottieAnimation);

