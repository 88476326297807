/**
 * @file
 *
 * This file should tracks page transition.
 *
 * WARNING: This file should only(!) be imported by middleware,
 * otherwise we will create acyclic dependencies, which is
 * caused by the import of the module map!
 */

/* eslint-env browser */

import { isHardwareEntity } from '../../helpers/entity';
import { uniqueId, getDeepestProp } from '../../helpers/identifier';
import {
  ACTION_PREFIX,
  CANCELLATION_REASON_MAPPING,
  E_SIM,
  SIM_CARD,
} from '../../helpers/constants';
import {
  getProductPixel,
  getFreeSimPixel,
  setPropositionCategory,
  setPropositionName,
  setCheckoutFormTracking,
  setSimActivationTracking,
  setSimReplacementFormTracking,
  setBelatedMNPFormTracking,
  setCancellationFormTracking,
  getCorrectFormTrackingForSuccess,
} from '../../helpers/tracking';

//--------------------------------------------------------------------------------------------------
//  State Management
//--------------------------------------------------------------------------------------------------

export const TRACK_PAGE = `${ACTION_PREFIX}/TRACK_PAGE`;
export const TRACK_MNP_FLAG = `${ACTION_PREFIX}/TRACK_MNP_FLAG`;
export const TRACK_USERFLOW = `${ACTION_PREFIX}/TRACK_USERFLOW`;
export const DO_NOT_TRACK_PAGE = `${ACTION_PREFIX}/DO_NOT_TRACK_PAGE`;

export const trackUserFlow = url => ({
  type: TRACK_USERFLOW,
  payload: url,
});

export const trackMnp = value => ({
  payload: value,
  type: TRACK_MNP_FLAG,
});

export const doNotTrackPage = bool => ({
  type: DO_NOT_TRACK_PAGE,
  payload: {
    doNotTrackPage: bool,
  },
});


//--------------------------------------------------------------------------------------------------
//  Internal helper Functions
//--------------------------------------------------------------------------------------------------

const getCancellationPixel = (module, getState) => {
  const state = getState();
  try {
    const cancellationReason =
      CANCELLATION_REASON_MAPPING[state.form.cancellation.values.cancel_reason_key];
    return {
      contract_cancellation_reason: cancellationReason,
    };
  } catch (e) {
    return null;
  }
};

const getMyDataSimStatusPixel = (getState) => {
  const { user } = getState();
  const { contractData } = user;
  return {
    ...(contractData && contractData.simCard.status && { status_sim: contractData.simCard.status }),
    ...(contractData && contractData.simCard.esimStatus
      && contractData.simCard.esimStatus.esimProvisionStatus
      && { status_esim: contractData.simCard.esimStatus.esimProvisionStatus }),
  };
};

const getNavProcessPixel = async (module, getState, { dispatch }) => getProductPixel(getState, dispatch, 'nav');

const getAutoTopupFormPixel = async (module, getState, { dispatch }) => {
  return getProductPixel(getState, dispatch, 'abc');
};

const getRecommendedCartSimType = (getState) => {
  const { cart, site } = getState();
  const cartIncludesHardware = cart.find(item => isHardwareEntity(item));
  const cartHardwareSupportsEsim = cart.find(item => item.supportsEsim);
  if (!cartIncludesHardware || site.contractRenewal.isInProgress) {
    return null;
  }
  return {
    recommendedSimType: cartHardwareSupportsEsim ? E_SIM : SIM_CARD,
  };
};

export const getCartPixel = async (module, getState, { dispatch }) => {
  const productPixel = await getProductPixel(
    getState,
    dispatch,
    'cart',
  );

  return setPropositionName(setPropositionCategory(productPixel));
};

const getFormErrorPixel = (module, getState) => {
  const formName = typeof module === 'string'
    ? module
    : getDeepestProp(module.component, 'WrappedComponent').formName;
  const { form } = getState();
  // the empty object as a fallback will force the function to return an empty
  // object a few lines later. This is okay, because there are no errors yet.
  // This is required in cases where the form is not initialized yet but the
  // tracking likes to send information.
  const { error, submitErrors = [] } = form[formName] || {};

  if (!error) {
    // nothing to track
    return {};
  }

  return {
    error_type: 'validation',
    error_code: error.fullCode,
    error_message: Object.values(submitErrors)[0] && Object.values(submitErrors)[0].type,
    error_field: Object.keys(submitErrors)[0],
  };
};

const getModulePixel = (getState, options) => async (module) => {
  const { params } = module;
  switch (module.etype) {
    case 'ModuleSwapper':
      return {
        ...getFormErrorPixel('checkout', getState),
        ...(await getCartPixel(module, getState, options)),
      };
    case 'SuccessInfo': {
      return {
        ...getCancellationPixel(module, getState),
        ...getCorrectFormTrackingForSuccess(getState),
      };
    }
    case 'NavProcess':
      return getNavProcessPixel(module, getState, options);
      // @todo don't wire the module swapper to the checkout form tracking but trigger tracking
      // for the corrected module
    case 'CheckoutForm': {
      return {
        ...setCheckoutFormTracking(getState),
        ...getFormErrorPixel(module, getState),
        ...(await getCartPixel(module, getState, options)),
        ...getRecommendedCartSimType(getState),
      };
    }
    case 'ShoppingCart':
      return {
        ...await getCartPixel(module, getState, options),
      };
    case 'MyAutoTopupForm':
      return getAutoTopupFormPixel(module, getState, options);
    case 'ContactForm': {
      return getFormErrorPixel(module, getState);
    }
    case 'ActivationForm': {
      return {
        ...getFormErrorPixel(module, getState),
        ...setSimActivationTracking(getState),
      };
    }
    case 'ContentGroup': {
      return {
        ...getFormErrorPixel(module, getState),
        ...getCorrectFormTrackingForSuccess(getState),
      };
    }
    case 'MySimReplacementForm': {
      return {
        ...getFormErrorPixel(module, getState),
        ...setSimReplacementFormTracking(getState),
      };
    }
    case 'MyBelatedMnpForm':
      return {
        ...getFormErrorPixel(module, getState),
        ...setBelatedMNPFormTracking(getState),
      };
    case 'MyCancellationForm':
      return {
        ...getFormErrorPixel(module, getState),
        ...setCancellationFormTracking(getState),
      };
    case 'MyData':
      return {
        ...(getMyDataSimStatusPixel(getState)),
      };
    case 'FreeSimForm':
      return {
        ...getFormErrorPixel(module, getState),
        ...getFreeSimPixel(String(params.tariffIdentifier)),
      };
    default:
      return {};
  }
};

const getPagePixel = (pagePixel) => {

  const newPagePixel = Object.assign({}, pagePixel);

  const pageType = newPagePixel.pageType;

  delete newPagePixel.pageType;
  delete newPagePixel.pageCategory;

  return {
    page_type: pageType,
    page_hierarchy: [...Object.values(newPagePixel)],
    page_url: window.location.href,
  };
};

//--------------------------------------------------------------------------------------------------
//  Module Exports
//--------------------------------------------------------------------------------------------------

export const trackPage = (page, location, additionalParameter, appendToPageHierarchy) =>
  async (dispatch, getState) => {
    const { user, site } = getState();
    const { lastTrackedPage } = site;
    const { credentials, bId } = user;
    const id = uniqueId('tracking');
    const pagePixel = page.tracking;
    const authenticated = credentials ? Boolean(credentials.msisdn) : false;
    const userStatus = authenticated ? 'logged in' : 'not logged in';
    const modulePixelList = await Promise.all(
      page.modules.map(getModulePixel(getState, { dispatch, location })),
    );
    const modulePixel = Object.assign({}, ...modulePixelList);

    if (lastTrackedPage && lastTrackedPage.pathname === location.pathname &&
      lastTrackedPage.search === location.search &&
      !modulePixel.error_type) return null;
    await dispatch(trackUserFlow(location.pathname));

    const enhancedPagePixel = getPagePixel(pagePixel);

    if (appendToPageHierarchy && appendToPageHierarchy.length > 0) {
      if (enhancedPagePixel.page_hierarchy.every(
        pagePosition => pagePosition !== appendToPageHierarchy)
      ) {
        enhancedPagePixel.page_hierarchy.push(appendToPageHierarchy);
      }
    }

    return dispatch({
      type: TRACK_PAGE,
      meta: { identifier: id },
      payload: {
        id,
        method: 'view',
        bId,
        userStatus,
        ...enhancedPagePixel,
        ...modulePixel,
        ...additionalParameter,
      },
    });
  };

export const trackCurrentPage = (additionalParameter = {}, appendToPageHierarchy) =>
  (dispatch, getState) => {
    const { site, pages } = getState();
    const { routing } = site;
    const { requestedLocation } = routing;
    return dispatch(trackPage(
      pages[requestedLocation.pathname],
      requestedLocation,
      additionalParameter,
      appendToPageHierarchy,
    ));
  };
