import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import suitcss from '../../../helpers/suitcss';
import connectUI from '../../basics/ui/UIConnector';
import FieldInput from '../../basics/form/FieldInput';
import FormSection from '../form/FormSection';
import FormHeader from '../form/FormHeader';
import FieldDate from '../../basics/form/FieldDate';

const componentName = 'EmailRegistrationForm';
function EmailRegistrationForm(props) {
  const { fieldMap, params, ui } = props;
  const { headline, copy } = params;
  return (
    <FormSection className={suitcss({ componentName })} asGrid >
      <FormHeader headline={headline} copy={copy} ui={ui} utilities={['col12', 'mlCol12']} />
      <Field {...fieldMap.birthday} component={FieldDate} isDateGroup utilities={['col12', 'mlCol8']} />
      <Field {...fieldMap.email} component={FieldInput} utilities={['col12', 'mlCol8']} />
    </FormSection>
  );
}

EmailRegistrationForm.propTypes = {
  // form logic
  fieldMap: PropTypes.object.isRequired,
  // cms wordings
  params: PropTypes.shape({
    headline: PropTypes.string,
    copy: PropTypes.string,
  }),
  ui: PropTypes.object.isRequired,
};

export default connectUI()(EmailRegistrationForm);
