import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import GlobalSection from '../../components/basics/global/GlobalSection';
import Teaser from '../../components/compositions/teaser/Teaser';

class MyMarketingBanner extends PureComponent {
  render() {
    const { params } = this.props;
    return (
      <GlobalSection theme={params.colorScheme} layout="contained">
        <Teaser type="image_banner" image={params.image} link={params.link} layout="threeFourths" />
      </GlobalSection>
    );
  }
}

MyMarketingBanner.propTypes = {
  params: PropTypes.object,
};

export default MyMarketingBanner;
