import oneline from 'common-tags/lib/oneLine';
import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { toDecimal } from '../../../../helpers/money';
import suitcss from '../../../../helpers/suitcss';
import Headline from '../../../basics/text/TextHeadline';
import Link from '../../../basics/text/TextLink';
import ContentLoader from '../../content/ContentLoader';
import {
  MYTRACK_CONTRACT_CANCEL_CANCELLATION_OVERVIEW,
  ID_MY_OFFERS_TARIFFS,
  DIALOG_ID_CONTRACT_SUMMARY_PUC,
  DIALOG_ID_CONTRACT_SUMMARY_PUA,
} from '../../../../helpers/constants';
import { subscriptionItemShape } from '../../../../propTypes/account';
import { scrollToHash } from '../../../../helpers/navigation';
import { isTariffOptionVO, isTariffVO } from '../../../../helpers/entity';
import DashboardSummaryHeader from './DashboardSummaryHeader';
import DashboardSummarySection from './DashboardSummarySection';

const componentName = 'DashboardSummary';
const DashboardSummary = (props) => {
  const {
    ui,
    isPUC,
    bookingOverview,
    bookedTariff,
    bookingOverviewItems,
    withLinkToTariffs,
    isTariffRenewable,
    dispatch,
    sitemap,
    cancelTariffOption,
    withdrawTariffCancellation,
    msisdn,
  } = props;
  const headerHeadline = isPUC ? bookedTariff.headline : bookedTariff.subline;
  const headerLabel = isPUC ? ui.myBoHeadlinePuc : ui.myBoHeadlinePua;
  const headerDetailsButtonText = isPUC ? ui.myBoDetailsCtaPuc : ui.myBoDetailsCtaPua;
  const formattedVolume = bookingOverview.aggregatedVolumes
    && Number(bookingOverview.aggregatedVolumes.sum.toFixed(2));
  const parsedVolumeSum = formattedVolume
    && formattedVolume.toString().replace('.', ',');

  const aggregatedVolumesLabel = bookingOverview.aggregatedVolumes
    && oneline`<div class="u-flex u-justifyBetween u-marginRight u-paddingRightXXS">
      <span>${ui.myBoAggregatedVolumeHeadline}</span>
      <span>${parsedVolumeSum} ${(bookingOverview.aggregatedVolumes.sumUnit).toUpperCase()}</span>
    </div>`;

  const aggregatedCostSumTotal = toDecimal({ unit: bookingOverview.aggregatedCosts.sum });

  const aggregatedCostLabel = oneline`<div class="u-flex u-justifyBetween u-marginRight u-paddingRightXXS">
      <span>${ui.myBoAggregatedCostsHeadline}</span>
      <span>${aggregatedCostSumTotal}<span class="u-marginLeftXXS">${ui.guiSymbolEuro}</span></span>
    </div>`;

  const onCancel = (iid) => {
    const vo = bookingOverviewItems && bookingOverviewItems.find(item => item.id === iid);
    const isTariff = isTariffVO(vo);

    if (isTariff && !vo.canceled) {
      dispatch(push(sitemap.MyCancellationFormRoute.url));
    }

    if (isTariff && vo.canceled) {
      withdrawTariffCancellation(vo);
    }

    if (!isTariff && !vo.canceled) {
      cancelTariffOption(vo);
    }
  };

  const renderContractSummaryHint = () => {
    const pucPDFLink = `<a class="DashboardSummary-hintLink">${ui.myBoCsPuaPlaceholder}</a>`;
    const puaPDFLink = `<a class="DashboardSummary-hintLink">${ui.myBoCsPucPlaceholder}</a>`;
    const pucHint = ui.myBoCsPucHint && ui.myBoCsPucHint !== ''
      ? ui.myBoCsPucHint.includes('{CONTRACT_SUMMARY_PDF}')
        ? ui.myBoCsPucHint.replace('{CONTRACT_SUMMARY_PDF}', pucPDFLink)
        : ''
      : '';
    const puaHint = ui.myBoCsPuaHint && ui.myBoCsPuaHint !== ''
      ? ui.myBoCsPuaHint.includes('{CONTRACT_SUMMARY_PDF}')
        ? ui.myBoCsPuaHint.replace('{CONTRACT_SUMMARY_PDF}', puaPDFLink)
        : ''
      : '';
    const infoText = isPUC
      ? pucHint
      : puaHint;
    const dialogEntityId = isPUC
      ? DIALOG_ID_CONTRACT_SUMMARY_PUC
      : DIALOG_ID_CONTRACT_SUMMARY_PUA;
    return {
      infoText,
      dialogEntityId,
      pdfText: ui.guiContractSummary,
    };
  };

  const renderFooter = () => {
    const { id, cancelable, canceled } = bookingOverview.bookedTariff;
    const isTariff = isTariffVO(bookingOverview.bookedTariff);
    const isOption = isTariffOptionVO(bookingOverview.bookedTariff);
    const hasRevokeLink = isTariff && canceled && cancelable;
    const hasCancelLink = !canceled && cancelable && (isOption || (isTariff && isPUC));

    if (withLinkToTariffs === false && !hasCancelLink) {
      return null;
    }

    return (
      <div className={suitcss({ componentName, descendantName: 'footer' })} >
        <div className={suitcss({ componentName, descendantName: 'links' })} >
          {hasRevokeLink && (
            <Link
              element="button"
              asButton
              onClick={() => onCancel(id)}
              data={{ mytracking: MYTRACK_CONTRACT_CANCEL_CANCELLATION_OVERVIEW }}
              buttonFilled
            >
              {ui.myTariffWithdrawCancellation}
            </Link>
          )}
        </div>

        {withLinkToTariffs !== false && (
          <ContentLoader isLoaded={!!withLinkToTariffs}>
            <Link
              onClick={() => scrollToHash(`#${ID_MY_OFFERS_TARIFFS}`, { contentAware: false, offset: 0, delay: 0 })}
              asButton
              element="button"
            >
              {isTariffRenewable ? ui.guiContractRenewal : ui.guiTariffChange}
            </Link>
          </ContentLoader>
        )}
      </div>
    );
  };

  return (
    <div className={suitcss({ componentName })}>
      {bookedTariff && (
      <Headline utilities={['marginBottomM']} size="m" embedded bold>{ui.myDashboardTariff}</Headline>
        )}
      {bookedTariff &&
      <DashboardSummaryHeader
        msisdn={msisdn}
        label={headerLabel}
        headline={headerHeadline}
        subline={isPUC ? bookedTariff.subline : null}
        bookedTariff={bookedTariff}
        detailsButtonText={headerDetailsButtonText}
        contractSummaryHint={renderContractSummaryHint()}
        cancellationDateCopy={ui.myTariffCancellationDate}
      />
        }
      <div className={suitcss({ componentName, descendantName: 'sections' })}>
        {isPUC && bookingOverview.aggregatedVolumes &&
        <DashboardSummarySection
          label={aggregatedVolumesLabel}
          items={bookingOverview.aggregatedVolumes.items}
          bookedTariff={bookedTariff}
          type="volumes"
        />
          }
        {isPUC && bookingOverview.aggregatedCosts &&
        <DashboardSummarySection
          label={aggregatedCostLabel}
          items={bookingOverview.aggregatedCosts.items}
          bookedTariff={bookedTariff}
          type="costs"
        />
          }
      </div>
      <div className={suitcss({ componentName, descendantName: 'options', utilities: ['marginVS'] })}>
        <DashboardSummarySection
          label={isPUC ? ui.myBoAdditionalItemsHeadline : ui.myBoAdditionalItemsHeadlinePua}
          items={bookingOverview.additionalItems}
          emptyText={ui.myBoAdditionalItemsNoItemsHint}
          emptyCta={ui.myBoAdditionalItemsNoItemsCta}
          withLinkToOptions
          bookedTariff={bookedTariff}
          asAccordion={isPUC}
          isAccordionOpen
        />
      </div>
      {renderFooter()}
    </div>
  );
};

DashboardSummary.propTypes = {
  withLinkToTariffs: PropTypes.bool,
  ui: PropTypes.shape({
    myDashboardTariff: PropTypes.string,
    guiBookTariffoption: PropTypes.string,
    myBoAdditionalItemsHeadline: PropTypes.string,
    myBoAdditionalItemsHeadlinePua: PropTypes.string,
    myBoAdditionalItemsNoItemsHint: PropTypes.string,
    myBoAdditionalItemsNoItemsCta: PropTypes.string,
    myBoAggregatedVolumeHeadline: PropTypes.string,
    myBoAggregatedCostsHeadline: PropTypes.string,
    guiSymbolEuro: PropTypes.string,
    myBoCsPuaPlaceholder: PropTypes.string,
    myBoCsPucPlaceholder: PropTypes.string,
    myBoCsPucHint: PropTypes.string,
    myBoCsPuaHint: PropTypes.string,
    guiContractSummary: PropTypes.string,
    myTariffWithdrawCancellation: PropTypes.string,
    guiContractRenewal: PropTypes.string,
    guiTariffChange: PropTypes.string,
    myBoHeadlinePuc: PropTypes.string,
    myBoHeadlinePua: PropTypes.string,
    myBoDetailsCtaPuc: PropTypes.string,
    myBoDetailsCtaPua: PropTypes.string,
    myTariffCancellationDate: PropTypes.string,
  }).isRequired,
  isTariffRenewable: PropTypes.bool,
  bookingOverview: PropTypes.object,
  bookingOverviewItems: PropTypes.array,
  bookedTariff: subscriptionItemShape,
  isPUC: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  withdrawTariffCancellation: PropTypes.func,
  cancelTariffOption: PropTypes.func,
  sitemap: PropTypes.object,
  msisdn: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default compose(connect(null, mapDispatchToProps))(DashboardSummary);
