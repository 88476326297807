import CalloutRequest from '../model/requests/CalloutRequest';
import { REQUEST_RECEIVED_RESPONSE } from '../actions/request/basic';

export default (state = { callouts: [] }, action = {}) => {
  switch (action.type) {
    case REQUEST_RECEIVED_RESPONSE: {
      const { request } = action.meta;
      const responseBody = action.payload.response.body.data;
      if (request instanceof CalloutRequest) {
        return { ...state, callouts: responseBody };
      }
      return state;
    }
    default:
      return state;
  }
};
