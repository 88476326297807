import { formatDate } from './date';

const reducedReferralStatusItems = (statusItems, ui) => {
  return statusItems.reduce((acc, d) => {
    switch (d.status) {
      case 'ACTIVE':
        acc.push({
          statusItemStatus: d.status,
          statusItemHeadline: d.label,
          statusItemIcon: '/icons/icon-check-circle.svg',
          statusItemColorUtility: 'u-colorSuccess',
          statusItemSubline: ui.myFrdStatusCardConfirmed,
          statusItemCopy: d.effectiveDate ? formatDate(d.effectiveDate) : null,
          statusItemDialog: {
            copy: ui.myFrdStatusCardConfirmedCopy,
            actionLabel: ui.guiWordAllright,
          },
          statusItemActiveFrom: d.activeFrom ?? null,
        });
        break;
      case 'PENDING':
        acc.push({
          statusItemStatus: d.status,
          statusItemHeadline: d.label,
          statusItemIcon: '/icons/icon-pending-circle.svg',
          statusItemColorUtility: 'u-colorPrimary',
          statusItemSubline: ui.myFrdStatusCardPending,
          statusItemCopy: null,
          statusItemDialog: {
            copy: ui.myFrdStatusCardPendingCopy,
            actionLabel: ui.guiWordAllright,
          },
        });
        break;
      default: acc.push({
        statusItemStatus: d.status,
        statusItemHeadline: d.label,
        statusItemIcon: '/icons/icon-dotted-circle.svg',
        statusItemColorUtility: 'u-colorGray50',
        statusItemSubline: ui.myFrdStatusCardFree,
        statusItemCopy: null,
      });
    }
    return acc;
  }, []);
};

export default reducedReferralStatusItems;
