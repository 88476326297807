import React from 'react';
import PropTypes from 'prop-types';

import { list as downloadList } from '../../propTypes/download';
import GlobalSection from '../../components/basics/global/GlobalSection';
import DownloadListComposition from '../../components/compositions/download/DownloadList';

function DownloadList({ params, entities, primaryModule }) {
  // TODO Implement params.categories as soon as requiered
  return (
    <GlobalSection>
      <DownloadListComposition
        primaryModule={primaryModule}
        layout={params.style || 'compact'}
        headline={params.headline}
        items={entities}
        itemsPerView={params.limit || 0}
      />
    </GlobalSection>
  );
}

DownloadList.propTypes = {
  params: PropTypes.shape({
    headline: PropTypes.string.isRequired,
    categories: PropTypes.array.isRequired,
    style: PropTypes.oneOf(['compact', 'grid']),
  }),
  primaryModule: PropTypes.bool.isRequired,
  entities: downloadList.isRequired,
};

export default DownloadList;
