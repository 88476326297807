import PageModule from './PageModule';
import { UI_COLLECTION_GDPR, UI_MY_SIMCARD_DATA } from '../../helpers/constants';
import { send } from '../../actions/request/send';
import PasswordValidationRulesRequest from '../requests/PasswordValidationRulesRequest';

/**
 * This module allows the user to view and modify his / her personal data
 * @see https://confluence.db-n.com/x/GvTc
 */
class MyDataModule extends PageModule {
  constructor(moduleData) {
    super(import(/* webpackChunkName:"MyData" */ '../../containers/account/data/MyData'), moduleData);
  }

  /**
   * @override
   */
  getRequiredUi() {
    return [UI_COLLECTION_GDPR, UI_MY_SIMCARD_DATA];
  }

  prepareBeforeMount() {
    return async (dispatch) => {
      dispatch(send(new PasswordValidationRulesRequest()));
    };
  }
}

export default MyDataModule;
