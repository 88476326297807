import React from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';

import TextHeadlineCopyGroup from '../../basics/text/TextHeadlineCopyGroup';
import LazyShowElement from '../../basics/element/LazyShowElement';

function TextGroup(props) {
  return (
    <article
      className={suitcss({
        componentName: 'TextGroup',
      })}
    >
      <div
        className={suitcss({
          componentName: 'TextGroup',
          descendantName: 'item',
          modifiers: [
            'main',
            props.inverted && 'inverted',
          ],
        })}
      >
        <TextHeadlineCopyGroup
          align={props.align}
          item={{
            headline: props.headline,
            copy: props.copy,
          }}
          inverted={props.inverted}
          primaryModule={props.primaryModule}
          primary
        />
      </div>
      {props.secondaryGroups.map((group, index) => (
        <LazyShowElement
          className={suitcss({
            componentName: 'TextGroup',
            descendantName: 'item',
            modifiers: [
              props.inverted && 'inverted',
            ],
          })}
          key={index}
        >
          <TextHeadlineCopyGroup
            item={group}
            inverted={props.inverted}
          />
        </LazyShowElement>
      ))}
    </article>
  );
}

TextGroup.propTypes = {
  headline: PropTypes.string,
  copy: PropTypes.string,
  primaryModule: PropTypes.bool,
  secondaryGroups: PropTypes.arrayOf(
    PropTypes.shape({
      headline: PropTypes.string,
      copy: PropTypes.string,
    }),
  ),
  inverted: PropTypes.bool,
  align: PropTypes.oneOf(['left', 'right', 'center']),
};

TextGroup.defaultProps = {
  inverted: true,
  align: 'left',
  secondaryGroups: [],
};

export default TextGroup;
