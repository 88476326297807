import React from 'react';
import PropTypes from 'prop-types';

import suitcss from '../../../../helpers/suitcss';
import { pickRandom } from '../../../../helpers/random';
import Message from '../Message';
import Link from '../../../basics/text/TextLink';
import {
  MESSAGE_SENDER_USER, MESSAGE_TYPE_ACCEPT_DATA_VOLUME, MESSAGE_TYPE_DECLINE_DATA_VOLUME,
} from '../constants';
import connectUI from '../../../basics/ui/UIConnector';


const AcceptHardware = (props) => {
  const { componentName, sendMessage, params, ui, isDisabled } = props;
  return (
    <div className={suitcss({ componentName, descendantName: 'footer' }, this)} >
      <Link
        isDisabled={isDisabled}
        withoutArrow
        element="button"
        asButton
        onClick={() => {
          sendMessage(
            new Message(MESSAGE_SENDER_USER, {
              type: MESSAGE_TYPE_DECLINE_DATA_VOLUME,
              text: pickRandom(params.messages.declineTariffVolume),
            }),
          );
        }}
      >
        {ui.guiWordBack}
      </Link>
      <Link
        isDisabled={isDisabled}
        withoutArrow
        element="button"
        asButton
        buttonFilled
        onClick={() => {
          sendMessage(
            new Message(MESSAGE_SENDER_USER, {
              type: MESSAGE_TYPE_ACCEPT_DATA_VOLUME,
              text: pickRandom(params.messages.acceptTariffVolume),
            }),
          );
        }}
      >
        {params.ui.btnDealNow}
      </Link>
    </div>
  );
};

AcceptHardware.propTypes = {
  componentName: PropTypes.string.isRequired,
  sendMessage: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool,
};

export default connectUI()(AcceptHardware);
