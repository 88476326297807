import React from 'react';
import Route from 'react-router/lib/Route';

import { fetchPage } from '../actions/page/website';
import GlobalLayout from '../containers/global/GlobalLayout';
import GlobalContent from '../containers/global/GlobalContent';
import { cancelContractRenewal } from '../actions/dialog/contractRenewalActions';

/**
 * @see https://github.com/ReactTraining/react-router/blob/master/docs/API.md
 */
export default store => {
  const { dispatch, getState } = store;
  const { site, user } = getState();
  const { sitemap } = site;

  // note: this needs to be a function as we need to freshly need the state on each call
  const isLoggedIn = () => !!getState().user.credentials.msisdn;

  /*
   * Called for every regular page, but the logic is only executed in the first call
   * and only in the browser. It checks if we are a webview and in contract renewal
   * mode (query params are evaluated). In that case it establishes the
   * app <=> web bridge-connection and fetches the credentials
   * required to start a contract renewal. It also dispatches the initial action to
   * start that process.
   */
  const onEnterRegularPage = async (nextState, replace, callback) => {

    if (
      process.browser
      && isLoggedIn()
      && nextState.location.pathname === sitemap.MyLoginFormRoute.url
    ) {
      replace({ pathname: sitemap.MyDashboardRoute.url });
    }

    callback();
  };

  /**
   * WARNING: we always need to make a fresh check via getState(),
   * whether we are running in contract renewal mode!
   */
  const onChange = async (prevState, nextState, replace, callback) => {
    if (getState().site.contractRenewal.isInProgress) {
      const { pathname } = nextState.location;
      const contractRenewalProcessUrls = [
        sitemap.CheckoutFormRoute.url,
        sitemap.ShoppingCartRoute.url,
      ];
      const page = await dispatch(fetchPage(nextState.location.pathname));
      const hasProcessHeader = !!page.modules.find(module => module.etype === 'NavProcess');

      if (!hasProcessHeader && !contractRenewalProcessUrls.includes(pathname)) {
        const isCanceled = await dispatch(cancelContractRenewal());
        if (!isCanceled) {
          replace(prevState.location);
        }
      }
    }

    if (user.pplus) {
      const simCardActivationUrls = [
        sitemap.ActivationFormRoute.url,
        sitemap.ActivationPPlusRossmannRoute.url,
        sitemap.ActivationPPlusRoute.url,
        sitemap.SimActivationSuccessRoute.url,
        sitemap.RossmannSimActivationSuccessRoute.url,
      ];
      if (!simCardActivationUrls.includes(nextState.location.pathname)) {
        replace(prevState.location);
      }
    }

    callback();
  };

  return (
    <Route component={GlobalLayout} onChange={onChange}>
      <Route component={GlobalContent}>
        <Route path="*" onEnter={onEnterRegularPage} />
      </Route>
    </Route>
  );
};
