import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  MYTRACK_SERVICE_SIMREP_SUMMARY,
} from '../../../helpers/constants';
import { trackMyState } from '../../../actions/tracking/event';
import initForm from '../../form/FormInitializer';
import {
  showLeavingProcessDialog,
} from '../../../actions/dialog/mySimReplacementActions';
import SimReplacementFormConfirm
  from '../../../components/compositions/account/simReplacement/SimReplacementFormConfirm';
import FormConfigProps from '../../../model/form/FormConfigProps';

const mapDispatchToProps = {
  onUpdateMyData: showLeavingProcessDialog,
};

const component = compose(
  connect(null, mapDispatchToProps),
  initForm(),
)(SimReplacementFormConfirm);

export const id = 'simReplConfirm';

/**
 * @return {FormConfig}
 */
export const mapStateToFormConfig = (state, props) => {
  const isCostFree = props.selectedSimOption
    && !props.selectedSimOption.basicFee && !props.selectedSimOption.shippingFee;
  return {
    id,
    component,
    onStepEnter: () => (dispatch) => dispatch(trackMyState(MYTRACK_SERVICE_SIMREP_SUMMARY)),
    formConfigProps: new FormConfigProps({
      labelSubmit: isCostFree ? state.ui.guiOrderWithoutCost : state.ui.guiOrderWithCost,
      withRequiredHint: false,
    }),
  };
};
