/**
 * prevents a default event and executes a callback function if available with
 * the event supplied as argument.
 */
export const preventDefault = cb => (ev) => {
  ev.preventDefault();
  if (typeof cb === 'function') {
    cb(ev);
  }
};

/**
 * Pressing the enter in a selected input field will submit the form by
 * submitting a click event on the first button in the form. If you have extended functionality
 * and are using buttons for additional functionality, this might not be the intended behavior.
 * This function allows to prevent the enter key event by passing it into the onKeyDown event
 * listener on a form and define a custom callback that should be fired instead.
 */
const ENTER_KEY_CODE = 13;
export const preventDefaultKeyEnter = cb => (ev) => {
  const isEnterKey = ev.keyCode === ENTER_KEY_CODE;
  if (!isEnterKey) {
    return;
  }
  const isActiveFormField = ['input', 'select'].includes(
    document.activeElement.tagName.toLowerCase() // eslint-disable-line
  );
  if (!isActiveFormField) {
    return;
  }

  ev.preventDefault();
  if (typeof cb === 'function') {
    cb(ev);
  }
};

export const groupBy = (list, key) => list.reduce((group, item) => {
  (group[item[key]] = group[item[key]] || []).push(item); // eslint-disable-line
  return group;
}, {});
