import PageModule from './PageModule';
import component from '../../containers/account/MyPromoConfirm';
import { UI_COLLECTION_PROMOTION } from '../../helpers/constants';

/**
 * Displays promo confirmation within My otelo.
 * @see https://confluence.db-n.com/x/IphgAQ
 */
class MyPromoConfirmModule extends PageModule {
  constructor(moduleData) {
    super(component, moduleData);
  }

  prepare(pathname, page) {
    return async dispatch => {
      await dispatch(super.prepare(pathname, page));
    };
  }

  /**
   * @override
   */
  getRequiredUi() {
    return [UI_COLLECTION_PROMOTION];
  }
}

export default MyPromoConfirmModule;

