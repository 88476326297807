import React from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';
import SvgLoader from './MediaSvgLoader';
import TextPopup from '../text/TextPopup';

const componentName = 'MediaSocialSharing';
const MediaSocialSharing = ({
 states, modifiers, style, links,
}) => (
  <div className={suitcss({ componentName, modifiers, states })} style={style}>
    {links.map((link, index) => {
      const isMailTo = link.to.startsWith('mailto:');
      const Element = isMailTo ? 'a' : TextPopup;
      const aria = isMailTo ? { 'aria-label': link.ariaLabel } : { ariaLabel: link.ariaLabel };
      return (
        <div
          className={suitcss({ componentName, descendantName: 'link' })}
          key={index}
          data-tracking="target"
          data-target-content={link.content}
        >
          <Element
            className={suitcss({
              componentName,
              descendantName: 'link',
            })}
            href={link.to}
            {...aria}
          >
            <SvgLoader path={link.icon} />
          </Element>
        </div>
      );
    })}
  </div>
);

MediaSocialSharing.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
      ariaLabel: PropTypes.string,
    }),
  ).isRequired,
  modifiers: PropTypes.array,
  states: PropTypes.array,
  style: PropTypes.object,
};

MediaSocialSharing.defaultProps = {
  style: {},
};

export default MediaSocialSharing;
