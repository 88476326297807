import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';
import { capitalize } from '../../../helpers/str';

import Headline from '../../basics/text/TextHeadline';
import MediaImage from '../../basics/media/MediaImage';
import Copy from '../../basics/text/TextCopy';
import imageSizes from '../../../config/imageSizes';

import { imageShape } from '../../../propTypes/media';
import LazyShowElement from '../../basics/element/LazyShowElement';

class HardwareDetailBlock extends PureComponent {
  renderText() {
    const { headline, copy } = this.props;
    return (
      <div
        className={suitcss({
          descendantName: 'text',
        }, this)}
      >
        <div
          className={suitcss({
            descendantName: 'headline',
            utilities: [!headline && 'hidden'],
          }, this)}
        >
          {headline && (
            <Headline size="m" embedded element="h2">
              {headline}
            </Headline>
          )}
        </div>
        <div
          className={suitcss({
            descendantName: 'copy',
          }, this)}
        >
          <Copy raw embedded >{copy}</Copy>
        </div>
      </div>
    );
  }

  renderImage() {
    const hardwareImage = {
      ...this.props.image,
      sizes: imageSizes.hardwareDetailBlock,
    };

    return (
      <div className={suitcss({ descendantName: 'image' }, this)}>
        <MediaImage {...hardwareImage} isLazy />
      </div>
    );
  }

  render() {
    const { headline, image, alignment } = this.props;
    return (
      <LazyShowElement
        className={suitcss({
          modifiers: [
            image && `imageAlign${capitalize(alignment)}`,
            !headline && 'textColumns',
          ],
        }, this)}
      >
        <div
          className={suitcss({
            descendantName: 'inner',
          }, this)}
        >
          {this.renderText()}
          {image && this.renderImage()}
        </div>
      </LazyShowElement>
    );
  }
}

HardwareDetailBlock.propTypes = {
  headline: PropTypes.string,
  copy: PropTypes.string.isRequired,
  image: imageShape,
  alignment: PropTypes.oneOf([
    'top',
    'right',
    'bottom',
    'left',
  ]),
};

HardwareDetailBlock.defaultProps = {
  alignment: 'left',
};

export default HardwareDetailBlock;
