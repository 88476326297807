import AbstractError from './AbstractError';
import { FETCH_ERROR } from '../../config/errorCodes';

/**
 * Thrown if the fetch fails
 */
class FetchError extends AbstractError {

  constructor(request, error) {
    super(FETCH_ERROR);
    this.request = request;
    this.setPrevious(error);
  }

  getLoggingData() {
    return {
      ...super.getLoggingData(),
      ...this.request,
    };
  }
}

export default FetchError;
