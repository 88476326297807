import {
  startContractRenewalFromTariff,
  startContractRenewalFromTariffWithSelectedHardware,
} from '../dialog/contractRenewalActions';
import { isAppContextContractRenewal } from '../../helpers/site';
import {
  ACTION_PREFIX,
  QUERY_CONTR_RENEWAL_START,
  QUERY_CONTR_RENEWAL_TARIFF_ID,
  QUERY_CONTR_RENEWAL_HARDWARE_ID,
} from '../../helpers/constants';
import LoginRequest from '../../model/requests/LoginRequest';
import * as bridge from '../../services/bridge';
import { send } from '../request/send';

const CONTR_RENEWAL_START_TARIFF = 'tariff';
const CONTR_RENEWAL_START_TARIFF_WITH_HARDWARE = 'tariffWithHardware';

export const APP_VIEW_DETECTED = `${ACTION_PREFIX}/APP_VIEW_DETECTED`;

/**
 * Initializes the app <=> js bridge connection, fetches
 * the credentials from the app, transforms them to a fake response
 * to reuse the logic in receiveCredentials. When the returned
 * promise is resolved, the user should be logged in.
 */
const initBridgeAndGetCredentials = dispatch =>
  bridge.init().then(() => dispatch(send(new LoginRequest())));


export const appViewDetected = location => async (dispatch, getState) => {
  // signal the system that we are wrapped in an app
  dispatch({
    type: APP_VIEW_DETECTED,
    payload: { location },
  });

  // trigger actions by instructions found in url
  const { query } = location;
  if (query && process.browser) {

    if (!bridge.hasBeenStarted()) {
      bridge.start();
      await initBridgeAndGetCredentials(dispatch);
    }

    if (isAppContextContractRenewal(getState().site)) {
      const isTariffRenewal =
        query[QUERY_CONTR_RENEWAL_START] === CONTR_RENEWAL_START_TARIFF;
      const isTariffWithHardwareRenewal =
        query[QUERY_CONTR_RENEWAL_START] === CONTR_RENEWAL_START_TARIFF_WITH_HARDWARE;
      const internalHardwareId = query[QUERY_CONTR_RENEWAL_HARDWARE_ID];
      const internalTariffId = query[QUERY_CONTR_RENEWAL_TARIFF_ID];
      if ((isTariffRenewal || isTariffWithHardwareRenewal) && !internalHardwareId) {
        await dispatch(startContractRenewalFromTariff(
          internalTariffId,
          isTariffWithHardwareRenewal,
        ));
      }
      if (isTariffRenewal && internalHardwareId) {
        await dispatch(startContractRenewalFromTariffWithSelectedHardware(
          internalTariffId,
          internalHardwareId,
        ));
      }
    }
  }
};
