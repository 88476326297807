import React, { PureComponent } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import connectUI from '../../components/basics/ui/UIConnector';
import { formatDate } from '../../helpers/date';
import {
  CONTEXT_CONTRACT_RENEWAL,
  CONTEXT_CONTRACT_RENEWAL_SIM_PLUS,
  CONTEXT_TARIFF_CHANGE,
} from '../../helpers/constants';
import ContractRenewalTariffInfoComposition from '../../components/compositions/contractRenewal/ContractRenewalTariffInfo';

class ContractRenewalTariffInfo extends PureComponent {
  matchConditionToContext(condition, context) {
    if (
      context === CONTEXT_CONTRACT_RENEWAL_SIM_PLUS &&
      condition.includesHardware &&
      condition.requiresContractRenewal
    ) {
      return condition;
    } else if (
      context === CONTEXT_CONTRACT_RENEWAL &&
      !condition.includesHardware &&
      condition.requiresContractRenewal
    ) {
      return condition;
    } else if (context === CONTEXT_TARIFF_CHANGE && !condition.requiresContractRenewal) {
      return condition;
    }
  }

  render() {
    const {
      inverted,
      ui,
      item,
      tariffMode,
    } = this.props;

    const isTariffChange = tariffMode === CONTEXT_TARIFF_CHANGE;
    const availableCondition = item.availableConditions &&
      item.availableConditions
        .find(condition => this.matchConditionToContext(condition, tariffMode));
    const contractStart = formatDate(availableCondition.realizationDate);
    const contractEnd = formatDate(availableCondition.nextDismissalDate);
    const durationMonthsString = ui.tariffsContractDurationMonths.replace('{CONTRACT_DURATION_MONTHS}', availableCondition.newDurationMonth);
    const durationDaysString = ui.tariffsContractDurationDays.replace('{CONTRACT_DURATION_DAYS}', availableCondition.newDurationDays);
    const items = [
      {
        key: ui.tariffsContractDuration,
        value: isTariffChange
          ? ui.tariffsChangeDurationRemains
          : availableCondition.newDurationDays
            ? durationMonthsString.concat('', durationDaysString)
            : durationMonthsString,
      },
      {
        key: isTariffChange ? ui.tariffsChangeStart : ui.tariffsRenewalStart,
        value: contractStart,
      },
      { key: ui.tariffsContractEnd, value: contractEnd },
    ];

    return (
      <ContractRenewalTariffInfoComposition inverted={inverted} items={items} />
    );
  }
}

ContractRenewalTariffInfo.propTypes = {
  item: PropTypes.object.isRequired,
  inverted: PropTypes.bool,
  tariffMode: PropTypes.string,
  ui: PropTypes.shape({
    tariffsContractDuration: PropTypes.string.isRequired,
    tariffsContractDurationMonths: PropTypes.string.isRequired,
    tariffsContractStart: PropTypes.string.isRequired,
    tariffsContractEnd: PropTypes.string.isRequired,
  }),
};


function makeStateToProps() {
  return (state) => {
    const { user } = state;
    return {
      contractData: user.contractData,
    };
  };
}

export default compose(
  connectUI(),
  connect(makeStateToProps),
)(ContractRenewalTariffInfo);
