import PageModule from './PageModule';
import component from '../../containers/content/ContentTextGroup';

/**
 * Shows pure text contents with headings and interim headings.
 * @see https://confluence.db-n.com/x/co3c
 */
class ContentTextGroupModule extends PageModule {
  constructor(moduleData) {
    super(component, moduleData);
  }
}

export default ContentTextGroupModule;
