import { connect } from 'react-redux';
import oneLineTrim from 'common-tags/lib/oneLineTrim';
import { createSelector } from 'reselect';

import { bindQueryParams } from '../../helpers/url';
import MediaSocialSharing from '../../components/basics/media/MediaSocialSharing';
import { createCurrentPageSelector } from '../../selectors/page';

/**
 * @param {object} page
 * @param {Location} location
 * @return {array}
 */
const createLinks = (page, location, ui) => {
  if (!page) {
    return [];
  }
  const { metatags } = page;
  const canonical = metatags.link.find((link) => link.rel === 'canonical');
  const ogUrl = metatags.meta.find((meta) => meta.property === 'og:url');
  const url = ogUrl ? ogUrl.content : bindQueryParams(canonical.href, location.query);
  const metaDescription = metatags.meta.find((meta) => meta.name === 'description');
  const { title } = metatags;

  const links = [
    {
      icon: '/icons/socialmedia_facebook.svg',
      content: 'Facebook',
      to: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`,
      ariaLabel: ui.guiSocialFacebookAlt,
    },
    {
      icon: '/icons/socialmedia_twitter.svg',
      content: 'Twitter',
      to: `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}`,
      ariaLabel: ui.guiSocialTwitterAlt,
    },
    {
      icon: '/icons/socialmedia_mail.svg',
      content: 'E-Mail',
      to: oneLineTrim`
        mailto:
          ?subject=${encodeURIComponent(title)}
          &body=${metaDescription
    ? `${encodeURIComponent(metaDescription.content)}%0A${encodeURIComponent(url)}`
    : encodeURIComponent(url)
}
      `,
      ariaLabel: ui.guiSocialEmailAlt,
    },
  ];

  return { links };
};

const makeMapStateToProps = () => createSelector(
  createCurrentPageSelector(),
  (state, ownProps) => (ownProps && ownProps.location) || state.routing.locationBeforeTransitions,
  (state) => {
    const {
      guiSocialFacebookAlt,
      guiSocialTwitterAlt,
      guiSocialEmailAlt,
    } = state.ui;
    return { guiSocialFacebookAlt, guiSocialTwitterAlt, guiSocialEmailAlt };
  },
  (page, location, ui) => createLinks(page, location, ui),
);

export default connect(makeMapStateToProps)(MediaSocialSharing);
