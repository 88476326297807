/* global window */
import 'event-listener-with-options/js/polyfill';
import browserHistory from 'react-router/lib/browserHistory';
import { syncHistoryWithStore, push } from 'react-router-redux';
import deepFreeze from 'deep-freeze';

import SessionExpiredError from '../model/errors/SessionExpiredError';
import * as bridge from '../services/bridge';
import createStore from './store';
import { syncStorage } from '../actions/global/storage';
import { logout } from '../actions/user/logout';
import { clearNonce, setScope } from '../actions/user/login';
import { STORAGE_KEY_CREDENTIALS, STORAGE_KEY_SCOPE } from '../helpers/constants';
import { devWarning } from '../helpers/meta';
import Application from '../model/Application';
import Environment from '../helpers/Environment';
import { send } from '../actions/request/send';
import UmidInfoRequest from '../model/requests/UmidInfoRequest';
import * as storage from '../helpers/storage';
import { setPaymentFee } from '../actions/abtest/abtest';
import { registerPromoCodes } from '../actions/user/marketing';
import { showTemplateDialog, showConsentDialog } from '../actions/page/dialog';
import { hydratePages } from '../actions/page/page';
import { fetchPromotions } from '../actions/request/registry';
import { instantiatePage } from '../helpers/pageParser';

const pollTracking = () => {
  if (process.env.NODE_ENV === 'development') {
    return;
  }
  const clear = (timerId) => { clearInterval(timerId); };
  const timerId = setInterval(() => {
    if (window.adv_global && window.adv_global.registerEventListeners) {
      window.adv_global.registerEventListeners();
      clear(timerId);
    }
  }, 100);
};

/**
 *
 * @param {function} mountApp - callback `mountApp(store, history)` that will mount the app
 * @param {object} initialState - the initial state of the store.
 * @param {object} options - options passed to createStore
 * @return {Promise.<Application>}
 */
export const startApp = async (mountApp, initialState, options) => {
  pollTracking();
  window.swapRuleListener = [];
  Object.defineProperty(window, 'swapRules', {
    set(swapRules) {
      console.log('set swap rules', swapRules);
      window.swapRuleListener.forEach(listener => {
        listener(swapRules);
      });
    },
    configurable: true,
  });
  // namespace all the stuff we need to store in the window.
  // we transition to external pages via this helper in order to be able to
  // easily mock hard refreshes in our acceptance tests.
  window.dbn = {
    open(url) { window.location.href = url; },
    // process.env.GIT is defined by webpack
    git: process.env.GIT,
    Environment: deepFreeze(new Environment(window.ENVIRONMENT)),
  };

  await Promise.all([
    // load all intl polyfills within one chunk
    !window.Intl && (new Promise(resolve => require.ensure([], (require) => {
      require('intl');
      require('intl/locale-data/jsonp/de-DE');
      require('intl/locale-data/jsonp/en-US');
      resolve();
    }))),
    !window.HTMLPictureElement && import('picturefill'),
    !window.fetch && import('isomorphic-fetch'),
  ]);

  const store = await createStore(initialState, options);
  const { dispatch, getState } = store;

  if (process.env.NODE_ENV !== 'production') {
    window.__store__ = store; // eslint-disable-line
    window.__goto__ = (x) => dispatch(push(x)); // eslint-disable-line
    // some helpers to test the bridge with fake app responds
    window.__bridge__ = bridge; // eslint-disable-line
    bridge.setupMocks(store);
    // helper to test if reauthentication works, also useful when the reauthentication is
    // done via the bridge
    window.__clearNonce__ = () => dispatch(clearNonce); // eslint-disable-line
  }

  // some information is only available on the client and should be inserted before the
  // app is rendering
  const pages = await instantiatePage(getState().pages);
  dispatch(hydratePages(pages));
  dispatch(syncStorage());
  try {
    await dispatch(send(new UmidInfoRequest()));
  } catch (e) {
    // catch error
  }
  const scope = storage.getItem(storage.STORAGE_TYPE_SESSION_STORAGE, STORAGE_KEY_SCOPE);
  if (scope) {
    dispatch(setScope(scope));
  }
  window.dbn.abtest = {
    setPaymentFee: (eids, amount) => {
      dispatch(setPaymentFee(eids, amount));
    },
    setPromoCode: promoCode => {
      dispatch(registerPromoCodes([promoCode], true));
    },
  };

  // Opens dialog when an object's attribute has the value "javascript:dbn.showDialog('id')"
  // @see https://jira.db-n.com/browse/OP-1416
  window.dbn.showDialog = (id) => {
    dispatch(showTemplateDialog(id));
  };

  window.dialogConsent = (id) => {
    dispatch(showConsentDialog(id));
  };

  // Note: In our middleware, we prevent the LOCATION_CHANGE to reach the
  // reducers until we fully loaded the content. As a result react-router-redux's
  // syncHistoryWithStore() needs to have "adjustUrlOnReplay" set to true,
  // otherwise it will mess up the history as it thinks that the history needs to be
  // replayed.
  // @see https://github.com/reactjs/react-router-redux/issues/513
  // @see https://github.com/reactjs/react-router-redux/blob/b2c2259c189cafad3e6e68eac7729e74f2bd56a9/src/sync.js#L50
  const history = syncHistoryWithStore(browserHistory, store, { adjustUrlOnReplay: false });

  // expose the dispatch method, so that the app can access and call us via bridge
  window.dispatchToWeb = bridge.dispatchToWeb;

  if (!window.dbn.storageListener) {
    window.dbn.storageListener = ev => {
      if (ev.key === STORAGE_KEY_CREDENTIALS) {
        if (!ev.newValue || !Object.keys(ev.newValue).length) { // removed credentials <=> logout
          devWarning(`Logout happened in another window: ${ev.url}`);
          dispatch(logout({ error: new SessionExpiredError() }));
        }
      }
    };
    window.addEventListener('storage', window.dbn.storageListener);
  }

  const mountPoint = mountApp(store, history);

  await dispatch(fetchPromotions());

  return new Application(mountPoint, store);
};
