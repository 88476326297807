import { ACTION_PREFIX } from '../../helpers/constants';

export const RECEIVED_CREDENTIALS = `${ACTION_PREFIX}/RECEIVE_CREDENTIALS`;
export const SET_SCOPE = `${ACTION_PREFIX}/SET_SCOPE`;

export const receivedCredentials = (response, isPersist) => ({
  type: RECEIVED_CREDENTIALS,
  payload: { response, isPersist },
});

// CLEAR_NONCE is a helper to manually clear the authentication data
// which forces the reauthentication to kick in for
// pending or new requests that require authentication

export const CLEAR_NONCE = `${ACTION_PREFIX}/CLEAR_NONCE`;

export const clearNonce = ({
  type: CLEAR_NONCE,
});

export const setScope = scope => ({
  type: SET_SCOPE,
  payload: scope,
});
