import { bindParamsToRoute, bindQueryParams } from '../../helpers/url';
import QueueableRequest from './QueueableRequest';
import { ACCOUNT_SIM_CARD_ESIM_QR_ENDPOINT } from '../../config/api';

export default class SimCardESimQRRequest extends QueueableRequest {
  constructor(msisdn, { size }, options) {
    let url = bindParamsToRoute(ACCOUNT_SIM_CARD_ESIM_QR_ENDPOINT, { msisdn });
    url = bindQueryParams(url, { size });
    super(url, options);
  }
}
