import { ACTION_PREFIX } from '../../helpers/constants';

export const OPEN_MENU = `${ACTION_PREFIX}/OPEN_MENU`;
export const CLOSE_MENU = `${ACTION_PREFIX}/CLOSE_MENU`;

export default {
  openMenu: (identifier, blockViewport = false) => (dispatch, getState) => {
    const { menu } = getState();
    // close open menu's if current is set to standalone
    if (menu[identifier].standalone) {
      Object.keys(menu).map(key => (
        identifier !== key && menu[key].isOpen &&
          dispatch({
            type: CLOSE_MENU,
            meta: { identifier: key },
            payload: { isOpen: false, blockViewport: false },
          })
      ));
    }

    dispatch({
      type: OPEN_MENU,
      meta: { identifier },
      payload: { isOpen: true, blockViewport },
    });
  },

  closeMenu: (identifier) => (dispatch) => {
    dispatch({
      type: CLOSE_MENU,
      meta: { identifier },
      payload: { isOpen: false, blockViewport: false },
    });
  },

};
