import React from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';
import { capitalize } from '../../../helpers/str';

import Headline from './TextHeadline';
import Callout from '../../../containers/callout/Callout';

function TextHeadlineGroup(props) {
  return (
    <div
      className={suitcss({
        componentName: 'TextHeadlineGroup',
        modifiers: [
          props.align && `align${capitalize(props.align)}`,
        ],
        utilities: props.utitlity,
      })}
    >
      <Headline
        size="xl"
        utilities={[props.inverted && 'colorInverted']}
        element={props.primaryModule ? 'h1' : 'h2'}
        embedded={!props.subline}
        raw
      >
        {props.headline}
      </Headline>

      {props.subline &&
        <Headline
          size="subline"
          element="p"
          raw
          utilities={[props.inverted && 'colorInverted', 'colorPrimary', 'lowercase']}
          embedded
        >
          {props.subline}
        </Headline>
      }
      <Callout
        theme="badge"
        targets={[props.moduleId]}
        inverted={props.inverted}
      />
    </div>
  );
}

TextHeadlineGroup.propTypes = {
  headline: PropTypes.string.isRequired,
  subline: PropTypes.string,
  inverted: PropTypes.bool,
  align: PropTypes.oneOf(['left', 'right', 'center']),
  utitlity: PropTypes.array,
  primaryModule: PropTypes.bool,
  moduleId: PropTypes.string,
};

TextHeadlineGroup.defaultProps = {
  inverted: false,
  align: 'left',
  utitlity: [],
  moduleId: '',
};

export default TextHeadlineGroup;
