import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import suitcss from '../../../../helpers/suitcss';
import connectUI from '../../../basics/ui/UIConnector';
import FieldInput from '../../../basics/form/FieldInput';
import FormSection from '../../form/FormSection';
import FormHeader from '../../form/FormHeader';
import FieldDate from '../../../basics/form/FieldDate';

const componentName = 'PasswordResetFormPassword';

function PasswordResetFormPassword(props) {
  const { fieldMap, ui } = props;
  return (
    <div className={suitcss({ componentName })}>
      <FormSection asGrid>
        <FormHeader headline={ui.myPasswordIdentityHeadline} copy={ui.myPasswordIdentityHint} />
        <Field {...fieldMap.birthday} component={FieldDate} isDateGroup utilities={['col6']} withToolTip />
        <FormHeader headline={ui.myPasswordLostNewHeadline} />
        <Field {...fieldMap.password} type="password" component={FieldInput} utilities={['col6']} withToolTip />
        <div className={suitcss({ utilities: ['col6', 'sHidden'] })} />
        <Field {...fieldMap.passwordRepeat} type="password" component={FieldInput} utilities={['col6']} withToolTip />
      </FormSection>
    </div>
  );
}

PasswordResetFormPassword.propTypes = {
  // form logic
  fieldMap: PropTypes.shape({
  }),
  formValues: PropTypes.shape({
  }),
  ui: PropTypes.shape({
    myPasswordLostNewSubmit: PropTypes.string.isRequired,
    myPasswordLostNewHeadline: PropTypes.string.isRequired,
  }),
};

export default connectUI()(PasswordResetFormPassword);
