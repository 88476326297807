import PropTypes from 'prop-types';

export const navLinkPropTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  icon: PropTypes.string,
  class: PropTypes.string,
  children: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      icon: PropTypes.string,
      class: PropTypes.string,
      children: PropTypes.array.isRequired,
    }),
  ).isRequired,
};

export const navLinkShape = PropTypes.shape(navLinkPropTypes);
export const navLinkList = PropTypes.arrayOf(navLinkShape);
