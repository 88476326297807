import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import RouterLink from 'react-router/lib/Link';

import Headline from '../../basics/text/TextHeadline';
import Link from '../../basics/text/TextLink';

import suitcss from '../../../helpers/suitcss';

const componentName = 'ProcessHeaderItem';

class ProcessHeaderPlaceholder extends PureComponent {

  renderLink() {
    return (
      <RouterLink
        to={this.props.url}
        className={suitcss({ componentName, descendantName: 'cta' })}
      >
        <Link
          element="span"
          utilities={['colorInverted']}
        >
          {this.props.headline}
        </Link>
      </RouterLink>
    );
  }

  render() {
    const { headline, shouldDisplayLink, modifiers } = this.props;
    return (
      <div className={suitcss({ componentName, modifiers })}>
        <div className={suitcss({ componentName, descendantName: 'header' })}>
          <Headline size="s" lowercase raw embedded element="h2">
            {headline}
          </Headline>
        </div>
        {shouldDisplayLink && this.renderLink()}
      </div>
    );
  }
}

ProcessHeaderPlaceholder.propTypes = {
  headline: PropTypes.string.isRequired,
  shouldDisplayLink: PropTypes.bool,
  url: PropTypes.string.isRequired,
  modifiers: PropTypes.array,
};

ProcessHeaderPlaceholder.defaultProps = {
  selected: false,
  modifiers: [],
  shouldDisplayLink: false,
};

export default ProcessHeaderPlaceholder;
