/* global document */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { formatDate } from '../../../../helpers/date';
import suitcss from '../../../../helpers/suitcss';
import { savePdf } from '../../../../helpers/misc';
import Headline from '../../../basics/text/TextHeadline';
import Link from '../../../basics/text/TextLink';
import TextRaw from '../../../basics/text/TextRaw';
import { subscriptionItemShape } from '../../../../propTypes/account';
import { showTemplateDialog } from '../../../../actions/page/dialog';
import { send } from '../../../../actions/request/send';
import ContractSummaryDocumentRequest from '../../../../model/requests/ContractSummaryDocumentRequest';
import Copy from '../../../basics/text/TextCopy';
import StringSubscriptionState from '../../../basics/string/StringSubscriptionState';

const componentName = 'DashboardSummary';

class DashboardSummaryHeader extends PureComponent {
  componentDidMount() {
    const pdfLink = document.querySelector('.DashboardSummary-hintLink');
    if (pdfLink) {
      pdfLink.addEventListener('click', () => this.loadContractSummaryPdf());
    }
  }

  showInfoDialog(id) {
    const { dispatch } = this.props;
    dispatch(showTemplateDialog(id));
  }

  loadContractSummaryPdf() {
    const {
      dispatch, msisdn, contractSummaryHint: { pdfText },
    } = this.props;

    const loadDocument = () =>
      dispatch(send(new ContractSummaryDocumentRequest(msisdn)));

    loadDocument().then((d) => {
      const binaryData = d.body.data.content;
      savePdf(binaryData, `${pdfText}.pdf`, true);
    }, (err) => {
      console.log('pdf rejected', err);
    });
  }

  renderContractSummaryHint() {
    const { contractSummaryHint } = this.props;
    if (!contractSummaryHint.infoText) return null;
    return (
      <div
        className={suitcss({
          componentName,
          descendantName: 'hint',
          utilities: ['marginBottomXXS'],
        })}
      >
        <TextRaw element="span">{contractSummaryHint.infoText}</TextRaw>
        <Link
          className={suitcss({ componentName, descendantName: 'hintIcon', utilities: ['marginLeftXS'] }, this)}
          element="button"
          onClick={() => {
            this.showInfoDialog(contractSummaryHint.dialogEntityId);
          }}
          withoutArrow
          withoutStyle
          icon="/icons/content-info.svg"
          size="small"
        />
      </div>
    );
  }

  renderDetailsButton() {
    const { bookedTariff, detailsButtonText } = this.props;
    const { urlDetails } = bookedTariff;
    return (
      <Link size="secondary" to={urlDetails} utilities={['weightBold']}>
        {detailsButtonText}
      </Link>
    );
  }
  render() {
    const {
      label, headline, subline, bookedTariff, cancellationDateCopy,
    } = this.props;

    const { canceled, cancelable, cancellationDate } = bookedTariff;
    const hasRevokeLink = canceled && cancelable;

    return (
      <div className={suitcss({ componentName, descendantName: 'header' }, this)}>
        {label &&
          <Copy size="secondary" embedded utilities={['colorGray100', 'uppercase', 'marginBottomXXS']}>
            {label}
          </Copy>
        }
        <Headline size="s" embedded utilities={['weightBold', 'marginBottomXS']}>
          {headline}
          {subline &&
            <span
              className={suitcss({ utilities: ['colorPrimary', 'marginLeftXS'] })}
            >{subline}
            </span>
          }
        </Headline>
        {(bookedTariff.state || hasRevokeLink) &&
          <Copy size="secondary" embedded utilities={['colorGray100', 'marginBottomXXS']}>
            {hasRevokeLink
              ? cancellationDateCopy.replace('{DATE}', formatDate(cancellationDate))
              : <StringSubscriptionState type={bookedTariff.type} state={bookedTariff.state} date={bookedTariff.stateDate} element="span" />
            }
          </Copy>
        }
        {this.renderContractSummaryHint()}
        {this.renderDetailsButton()}
      </div>
    );
  }
}

DashboardSummaryHeader.propTypes = {
  dispatch: PropTypes.func.isRequired,
  msisdn: PropTypes.string,
  urlDetails: PropTypes.string,
  bookedTariff: subscriptionItemShape,
  headline: PropTypes.string,
  subline: PropTypes.string,
  label: PropTypes.string,
  contractSummaryHint: PropTypes.shape({
    pdfText: PropTypes.string,
    infoText: PropTypes.string,
    dialogEntityId: PropTypes.string,
  }),
  detailsButtonText: PropTypes.string,
  cancellationDateCopy: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default compose(connect(null, mapDispatchToProps))(DashboardSummaryHeader);
