import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, initialize } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';

/**
 * Default redux-form config
 *
 * @see http://redux-form.com/6.1.0/docs/api/ReduxForm.md
 */
const defaultConfig = {
  destroyOnUnmount: false, // needed as we use the same form in different "steps"
  enableReinitialize: true, // needed as we reinitialize on every form step mount
  keepDirtyOnReinitialize: true, // prevents reinitialization after form submission
};

/**
 * Returns a higher order component that initializes redux-form.
 *
 * @param {Component} WrappedComponent - the redux form component
 * @return The decorator component described above
 */
const initForm = WrappedComponent => {
  class FormInitializer extends PureComponent {

    /**
     * Sets up our forms with initial values defined in each form step
     * as well as values the user entered in any of the previous steps.
     *
     * @see https://jira.db-n.com/browse/OT-2228 - a critical bug solved by this decorator
     */
    componentDidMount() {
      const { dispatch, form, initialValues = {}, formValues = {} } = this.props;
      dispatch(initialize(form, { ...initialValues, ...formValues }, true));
    }

    /**
     * Note: we explicitly exclude "initialValues" from the props passed to the
     * wrapped component to prevent redux-form initialization via props. Instead,
     * initialization is done within componentDidMount()
     */
    render() {
      // eslint-disable-next-line no-unused-vars
      const { initialValues, dispatch, ...props } = this.props;
      return <WrappedComponent {...props} />;
    }
  }

  FormInitializer.propTypes = {
    dispatch: PropTypes.func.isRequired,
    form: PropTypes.string.isRequired, // the form name to identify the form in the store
    initialValues: PropTypes.object, // values to initialize the form with
    formValues: PropTypes.object, // current form values
  };

  const mapDispatchToProps = (dispatch) => ({ dispatch });

  return connect(null, mapDispatchToProps)(FormInitializer);
};

/**
 * Returns a function that accepts a redux-form config object and returns
 * a redux-form component wrapped by our initializer.
 *
 * @param {Object} config - configuration passed to redux-form
 * @return The decorator component described further above
 */
export default (config = defaultConfig) => compose(initForm, reduxForm(config));
