import AbstractError from './AbstractError';
import { RUNTIME_ERROR } from '../../config/errorCodes';

/**
 * Error to be thrown if javascript crashed for some unknown reason
 * and we cannot further specify the error reason.
 */
class RuntimeError extends AbstractError {
  /**
   * @param {Error} error - the catched error
   */
  constructor(error) {
    super(RUNTIME_ERROR);
    this.setPrevious(error);
  }
}

export default RuntimeError;
