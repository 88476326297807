import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import suitcss from '../../../helpers/suitcss';
import Headline from '../../basics/text/TextHeadline';
import Copy from '../../basics/text/TextCopy';
import FieldValueSlider from '../../basics/form/FieldValueSlider';
import FieldTextarea from '../../basics/form/FieldTextarea';
import FieldRadioGroup from '../../basics/form/FieldRadioGroup';

const componentName = 'FormWrapper';

class NpsFormField extends PureComponent {

  renderValueSlider({ type, label, rejectChoices, ...inputProps }) {
    return (
      <div className={suitcss({ componentName, descendantName: 'formHalf', utilities: ['mlCol6'] })}>
        <Field
          {...inputProps}
          withMarks
          component={FieldValueSlider}
        />
      </div>
    );
  }

  renderTextField({ type, label, rejectChoices, ...inputProps }) {
    return (
      <div className={suitcss({ componentName, descendantName: 'formHalf', utilities: ['mlCol6'] })}>
        <Field
          {...inputProps}
          component={FieldTextarea}
        />
      </div>
    );
  }

  renderRadioGroup({ type, label, rejectLabel, rejectChoices, ...inputProps }) {
    return (
      <div className={suitcss({ componentName, descendantName: 'formHalf', utilities: ['mlCol6'] })}>
        <Field {...inputProps} component={FieldRadioGroup} asToggleButtonGroup />
      </div>
    );
  }

  render() {
    const { item, position } = this.props;
    return (
      <div className={suitcss({ componentName, descendantName: 'formFull' })}>
        <div className={suitcss({ componentName, descendantName: 'formHalf', utilities: ['row', 'mlCol6'] })}>
          <Headline size="xxl" utilities={['colorPrimary', 'col1']} embedded>
            {position + 1}
          </Headline>
          <Copy utilities={['weightBold', 'mlCol10', 'sCol11']} embedded raw>{item.label}</Copy>
        </div>
        {item.type === 'bool' && this.renderRadioGroup(item)}
        {item.type === 'scale' && this.renderValueSlider(item)}
        {item.type === 'text' && this.renderTextField(item)}
      </div>
    );
  }
}

NpsFormField.propTypes = {
  position: PropTypes.any.isRequired,
  item: PropTypes.object.isRequired,
};

export default NpsFormField;
