import React from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';
import { stripHTML } from '../../../helpers/str';
import { hintShape } from '../../../propTypes/field';
import Copy from '../text/TextCopy';
import Info from '../text/TextInfo';

const componentName = 'FieldMessenger';

function FieldMessenger(props) {
  const { meta, hint, info } = props;
  const { error, touched } = meta;
  const showError = (error && touched);
  const hintAsElement = typeof hint !== 'string';
  return (
    <div className={suitcss({ componentName })} >
      {showError &&
        <div
          className={suitcss({
            componentName,
            descendantName: 'error',
          })}
        >
          <Copy size="secondary" embedded raw>
            {error.message}
          </Copy>
        </div>
      }
      {hint &&
        <div
          className={suitcss({
            componentName,
            descendantName: 'hint',
          })}
        >
          <Copy
            size="secondary"
            element="span"
            utilities={['colorGray100']}
            embedded
            raw={!hintAsElement}
          >
            {hintAsElement ? hint : stripHTML(hint, 'p')}
          </Copy>
          {info && '\u00A0'}
          {info && <Info {...info} />}
        </div>
      }
    </div>
  );
}

FieldMessenger.propTypes = {
  meta: PropTypes.object.isRequired,
  hint: hintShape,
  info: PropTypes.object,
};

FieldMessenger.defaultProps = {
  raw: false,
};

export default FieldMessenger;
