/* global window */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Motion, spring } from 'react-motion';
import suitcss from '../../../helpers/suitcss';
import { decimalFormatter } from '../../../helpers/money';
import MediaSvgLoader from '../media/MediaSvgLoader';
import Headline from '../text/TextHeadline';
import Copy from '../text/TextCopy';

const springConfig = { stiffness: 300, damping: 50 };
const componentName = 'UICircularProgressBar';
class UICircularProgressBar extends PureComponent {

  constructor(props, context) {
    super(props, context);
    this.timeout = null;
    this.state = {
      value: props.shouldAnimateFrom ? props.valueMax : 0,
      from: props.shouldAnimateFrom ? 100 : 0,
      to: null,
    };
  }

  componentDidMount() {
    this.initAnimation();
  }

  componentDidUpdate() {
    this.initAnimation();
  }

  componentWillUnmount() {
    this.clearTimeout();
  }

  clearTimeout() {
    if (this.timeout) {
      window.clearTimeout(this.timeout);
    }
  }

  initAnimation() {
    const { delay } = this.props;
    this.clearTimeout();
    if (delay > 0) {
      this.timeout = window.setTimeout(() => {
        this.runAnimation();
      }, delay);
      return;
    }
    this.runAnimation();
  }

  runAnimation() {
    const { value, valueMax } = this.props;
    this.setState({
      value,
      to: Math.ceil((value / valueMax) * 100),
    });
  }

  formatValue(value) {
    const { floatingValues } = this.props;
    return floatingValues ? decimalFormatter().format(value) : Math.round(value);
  }

  renderValue(value) {
    const { inverted, postfix } = this.props;
    const spacer = postfix !== '' ? ' ' : '';
    const label = `${this.formatValue(value)}${spacer}${postfix}`;
    return (
      <Headline size="l" embedded bold inverted={inverted} highlight={!inverted} element="p">{label}</Headline>
    );
  }

  render() {
    const { valueMax, copy, inverted } = this.props;
    const { value, from, to } = this.state;
    const defaultStyle = { value: valueMax };
    const style = { value: spring(value, springConfig) };
    return (
      <div
        className={suitcss({ componentName })}
        data-from={from}
        data-to={to}
      >
        <div className={suitcss({ descendantName: 'bar' }, this)} >
          <MediaSvgLoader path="/icons/circular-progressbar.svg" />
        </div>
        <div className={suitcss({ descendantName: 'content' }, this)} >
          <Motion defaultStyle={defaultStyle} style={style}>
            {data => (this.renderValue(data.value))}
          </Motion>
          {copy && (
            <Copy size="secondary" embedded inverted={inverted} raw>{copy}</Copy>
          )}
        </div>
      </div>
    );
  }
}

UICircularProgressBar.propTypes = {
  value: PropTypes.number.isRequired,
  valueMax: PropTypes.number.isRequired,
  postfix: PropTypes.string,
  copy: PropTypes.string,
  delay: PropTypes.number,
  inverted: PropTypes.bool,
  shouldAnimateFrom: PropTypes.bool,
  floatingValues: PropTypes.bool,
};

UICircularProgressBar.defaultProps = {
  delay: 0,
  shouldAnimateFrom: false,
  postfix: '',
};

UICircularProgressBar.defaultProps = {};

export default UICircularProgressBar;

