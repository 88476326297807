import { ACTION_PREFIX } from '../../helpers/constants';

export const UPDATE_CREDENTIALS = `${ACTION_PREFIX}/UPDATE_CREDENTIALS`;

export const updateCredentials = (credentials) => ({
  type: UPDATE_CREDENTIALS,
  payload: {
    credentials,
  },
});
