import { connect } from 'react-redux';
import { change, setSubmitSucceeded } from 'redux-form';

import FormBlockErrorComposition from '../../components/compositions/form/FormBlockError';
import FormValidationError from '../../model/errors/FormValidationError';

const mapStateToProps = (state, ownProps) => {
  const formState = state.form[ownProps.form] || {};
  return {
    // error is what has been passed to redux-form's stopSubmit() in "_error"
    error: formState.error,
    hasSubmitFailed: formState.submitFailed,
    hasSubmitSucceeded: formState.submitSucceeded,
    ui: state.ui,
  };
};

const mapDispatchToProps = (dispatch) => ({ dispatch });

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...ownProps,
  applyCorrections: () => {
    const { error } = stateProps;
    if (error instanceof FormValidationError) {
      const { dispatch } = dispatchProps;
      const { form, onDataCorrection, formValues } = ownProps;
      const finalizedValues = { ...formValues };
      const fieldErrors = Object.values(error.getFieldErrors());
      fieldErrors
        .map(fieldError =>
          dispatch(change(form, fieldError.field.name, fieldError.correction)));
      fieldErrors
        .map(fieldError =>
          Object.assign(finalizedValues, { [fieldError.field.name]: fieldError.correction }));

      dispatch(setSubmitSucceeded(form));
      dispatch(onDataCorrection(finalizedValues));
    }
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(FormBlockErrorComposition);
