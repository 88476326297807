import React from 'react';
import PropTypes from 'prop-types';

class RenderOnClient extends React.Component {
  constructor(...props) {
    super(...props);
    this.state = {
      isMounted: false,
    };
  }

  componentDidMount() {
    this.setState({
      isMounted: true,
    });
  }

  render() {
    if (!this.state.isMounted) {
      return null;
    }
    return (this.props.children);
  }
}

RenderOnClient.propTypes = {
  children: PropTypes.element.isRequired,
};

export default RenderOnClient;
