import { REQUEST_RECEIVED_RESPONSE } from '../actions/request/basic';
import AbstractPageRequest from '../model/requests/AbstractPageRequest';
import { makePageStoreId } from '../selectors/page';
import { HYDRATE_PAGES, SWITCH_MODULE } from '../actions/page/page';

function pages(state = {}, action = {}) {
  switch (action.type) {
    case HYDRATE_PAGES: {
      return action.payload.pages;
    }

    case SWITCH_MODULE: {
      const { payload } = action;
      const { page, newModuleInstance, oldModuleId } = payload;

      return {
        ...state,
        [page]: {
          ...state[page],
          modules: state[page].modules.map(module => {
            return module.eid !== oldModuleId ? module : newModuleInstance;
          }),
        },
      };
    }

    case REQUEST_RECEIVED_RESPONSE: {
      const { meta, payload } = action;
      const { request } = meta;
      if (request instanceof AbstractPageRequest) {
        return {
          ...state,
          [makePageStoreId(request.pathname)]: payload.response.body.data,
        };
      }
      return state;
    }

    default:
      return state;
  }
}

export default pages;
