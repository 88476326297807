import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';

import { shape as hardwareShape } from '../../../propTypes/hardware';
import connectUI from '../../basics/ui/UIConnector';

import TabsWrapper from '../../basics/tab/TabWrapper';
import Tab from '../../basics/tab/Tab';
import HardwareDetailBlock from './HardwareDetailBlock';

import Headline from '../../basics/text/TextHeadline';
import Copy from '../../basics/text/TextCopy';

import TeaserWrapper from '../teaser/TeaserWrapper';
import TeaserKeyFact from '../teaser/TeaserKeyFact';
import LazyShowElement from '../../basics/element/LazyShowElement';


class HardwareInformation extends PureComponent {

  renderFacts() {
    const { facts, factsHeadline } = this.props;
    return (
      <LazyShowElement
        className={suitcss({
          descendantName: 'facts',
        }, this)}
      >
        {factsHeadline && (
          <div
            className={suitcss({
              descendantName: 'factsHeadline',
            }, this)}
          >
            <Headline
              size="l"
              element="h2"
              utilities={['uppercase', 'alignCenter']}
              embedded
              raw
            >
              {factsHeadline}
            </Headline>
          </div>
        )}
        <TeaserWrapper type="facts" theme="light">
          {facts.map((props, index) => (
            <TeaserKeyFact {...props} key={index} />
          ))}
        </TeaserWrapper>
      </LazyShowElement>
    );
  }


  renderSpecs() {
    const { specs } = this.props;
    return (
      <LazyShowElement
        className={suitcss({
          descendantName: 'specs',
        }, this)}
      >
        <div
          className={suitcss({
            descendantName: 'inner',
          }, this)}
        >
          {specs.map((props, index) => (
            <div
              className={suitcss({
                descendantName: 'specsBlock',
              }, this)}
              key={index}
            >
              <div
                className={suitcss({
                  descendantName: 'specsHeadline',
                }, this)}
              >
                <Headline
                  size="l"
                  utilities={['uppercase', 'alignCenter']}
                  embedded
                  element="h2"
                  raw
                >
                  {props.headline}
                </Headline>
              </div>
              <div
                className={suitcss({
                  descendantName: 'specsCopy',
                }, this)}
              >
                <Copy raw>
                  {props.copy}
                </Copy>
              </div>
            </div>
          ))}
        </div>
      </LazyShowElement>
    );
  }

  renderDetails() {
    const { details } = this.props;
    return (
      <LazyShowElement
        className={suitcss({
          descendantName: 'details',
        }, this)}
      >
        {details.map((props, index) => (
          <HardwareDetailBlock {...props} key={index} />
        ))}
      </LazyShowElement>
    );
  }

  render() {
    const { details, specs, facts, ui } = this.props;
    const shouldRenderSpecs = !!specs && specs.length > 0;
    const shouldRenderFacts = !!facts && facts.length > 0;
    const shouldRenderDetails = !!details && details.length > 0;
    const shouldRenderTechnicalData = shouldRenderFacts || shouldRenderSpecs;
    const shouldRenderTabs = shouldRenderDetails && shouldRenderTechnicalData;
    const tabs = [
      <Tab label={ui.hdeTabDetails} key={0}>
        {this.renderDetails()}
      </Tab>,
      <Tab label={ui.hdeTabSpecs} key={1}>
        {shouldRenderFacts && this.renderFacts()}
        {shouldRenderSpecs && this.renderSpecs()}
      </Tab>,
    ];
    return (
      <LazyShowElement className={suitcss({}, this)}>
        {shouldRenderTabs && (
          <TabsWrapper selected={0}>
            {tabs}
          </TabsWrapper>
        )}
        {!shouldRenderTabs && shouldRenderDetails && this.renderDetails()}
        {!shouldRenderTabs && shouldRenderTechnicalData && (
          <div
            className={suitcss({
              descendantName: 'simpleContainer',
            }, this)}
          >
            {shouldRenderFacts && this.renderFacts()}
            {shouldRenderSpecs && this.renderSpecs()}
          </div>
        )}
      </LazyShowElement>
    );
  }
}

HardwareInformation.propTypes = {
  selectedHardware: hardwareShape.isRequired,
  factsHeadline: PropTypes.string,
  facts: PropTypes.array,
  details: PropTypes.array.isRequired,
  specs: PropTypes.array,
  ui: PropTypes.shape({
    hdeTabDetails: PropTypes.string.isRequired,
    hdeTabSpecs: PropTypes.string.isRequired,
    guiWordReviews: PropTypes.string.isRequired,
  }),
  reviewActivated: PropTypes.bool,
};

HardwareInformation.defaultProps = {
  reviewActivated: false,
};

export default connectUI()(HardwareInformation);
