import { AVAILABILITY_ACCOUNT, AVAILABILITY_PRESALES } from '../helpers/constants';

/**
 * Returns all tariffs that are available considering the state the user is currently
 * operating in (e.g. contractRenewal)
 *
 * @param {object} site
 * @param {object} user
 * @param {WebEntity[]} tariffEntities
 */
export const getAvailableTariffs = (site, user, tariffEntities) => {
  if (site.contractRenewal.isInProgress) {
    return tariffEntities.filter(tariff =>
      tariff.availability.includes(AVAILABILITY_ACCOUNT) &&
      (user.renewableTariffs || []).some(iid => iid === tariff.iid),
    );
  }

  return tariffEntities.filter(tariff => tariff.availability.includes(AVAILABILITY_PRESALES));
};

/**
 * Returns the entity from a hashmap of entities grouped(!) by their type.
 * This resembles the way we store our entities within our "entities" redux-store section.
 *
 * @param {Object<etype, Object<eid, WebEntity>>} entities grouped by their type
 * @param {string} entityId
 * @return {WebEntity}
 */
export const getEntityById = (entities, entityId) =>
  Object.assign({}, ...Object.values(entities))[entityId];
