import React, { PureComponent } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import connectUI from '../../components/basics/ui/UIConnector';
import FormOptionTab from '../../components/basics/form/FormOptionTab';
import {
  CONTEXT_CONTRACT_RENEWAL,
  CONTEXT_CONTRACT_RENEWAL_SIM_PLUS,
  CONTEXT_TARIFF_CHANGE,
  MARKET_POSTPAID,
  QUERY_TARIFF_MODE,
  VO_TYPE_TARIFF,
} from '../../helpers/constants';
import LazyShowElement from '../../components/basics/element/LazyShowElement';
import suitcss from '../../helpers/suitcss';
import Callout from '../callout/Callout';
import OffersPanel from '../../components/compositions/account/offers/OffersPanel';
import ContractRenewalTariffInfo from './ContractRenewalTariffInfo';
import { toFullCurrency, toPriceObject } from '../../helpers/money';
import { getPaymentFeeDuration } from '../../helpers/entity';
import ContractRenewalFeeInfo from './ContractRenewalFeeInfo';
import Unit from '../../components/basics/text/TextUnit';
import Copy from '../../components/basics/text/TextCopy';
import Link from '../../components/basics/text/TextLink';
import { isTariffChangeable } from '../../helpers/user';
import TextHeadline from '../../components/basics/text/TextHeadline';
import * as contractRenewalActions from '../../actions/dialog/contractRenewalActions';
import FieldRadioGroup from '../../components/basics/form/FieldRadioGroup';
import matchMediaConnector from '../service/ServiceMatchMedia';
import { createNormalizeTargetEntitiesSelector } from '../../selectors/entity';
import { registerTariffMode } from '../../actions/order/process';

class TariffFilter extends PureComponent {
  constructor(...args) {
    super(...args);
    this.handleClickOptionTab = this.handleClickOptionTab.bind(this);
    this.handleClickOptionBullet = this.handleClickOptionBullet.bind(this);
    const { tariffMode, items } = this.props;
    const mode = tariffMode || CONTEXT_CONTRACT_RENEWAL;
    const isSimPlus = mode === CONTEXT_CONTRACT_RENEWAL_SIM_PLUS
      || items.filter(item => item.contractRenewalWithHardwareOnly === true
        || item.contractRenewalWithSimOnly === true)
        .every(item => item.contractRenewalWithHardwareOnly === true
          && item.contractRenewalWithSimOnly === false);
    const canChangeTariff = items.some(item => item.contractChangeable === true);
    const canRenewContract = items.some(item => item.contractRenewalWithHardwareOnly === true
      || item.contractRenewalWithSimOnly === true);
    const setTariffMode = () => {
      switch (mode) {
        case CONTEXT_TARIFF_CHANGE: {
          if (canChangeTariff) {
            return CONTEXT_TARIFF_CHANGE;
          } else if (isSimPlus) {
            return CONTEXT_CONTRACT_RENEWAL_SIM_PLUS;
          } return CONTEXT_CONTRACT_RENEWAL;
        }
        case CONTEXT_CONTRACT_RENEWAL_SIM_PLUS: {
          if (canRenewContract && isSimPlus) {
            return CONTEXT_CONTRACT_RENEWAL_SIM_PLUS;
          } else if (canRenewContract) {
            return CONTEXT_CONTRACT_RENEWAL;
          } return CONTEXT_TARIFF_CHANGE;
        }
        case CONTEXT_CONTRACT_RENEWAL: {
          if (canRenewContract && !isSimPlus) {
            return CONTEXT_CONTRACT_RENEWAL;
          } else if (canRenewContract && isSimPlus) {
            return CONTEXT_CONTRACT_RENEWAL_SIM_PLUS;
          } return CONTEXT_TARIFF_CHANGE;
        }
        default:
          if (canRenewContract && isSimPlus) {
            return CONTEXT_CONTRACT_RENEWAL_SIM_PLUS;
          } else if (canRenewContract) {
            return CONTEXT_CONTRACT_RENEWAL;
          } return CONTEXT_TARIFF_CHANGE;
      }
    };
    this.state = {
      withHardware: isSimPlus || false,
      tariffMode: setTariffMode(),
    };
  }

  handleClickOptionTab() {
    const { tariffMode, withHardware } = this.state;
    const { onRegister } = this.props;
    const mode = tariffMode.includes(CONTEXT_CONTRACT_RENEWAL)
      ? CONTEXT_TARIFF_CHANGE
      : withHardware
        ? CONTEXT_CONTRACT_RENEWAL_SIM_PLUS
        : CONTEXT_CONTRACT_RENEWAL;
    this.setState({
      ...this.state,
      tariffMode: mode,
    });
    onRegister(mode);
  }

  handleClickOptionBullet() {
    const { withHardware } = this.state;
    const { onRegister } = this.props;
    const mode = withHardware ? CONTEXT_CONTRACT_RENEWAL : CONTEXT_CONTRACT_RENEWAL_SIM_PLUS;
    this.setState({
      ...this.state,
      tariffMode: mode,
      withHardware: !withHardware,
    });
    onRegister(mode);
  }

  isDarkTheme() {
    return this.props.theme === 'dark';
  }

  renderItem(item, index, tag) {
    const { tariffMode, withHardware } = this.state;
    const { theme, moduleId, onSelect, onChange } = this.props;
    const eqGroup = `${moduleId}-${tag ? `${tag.id}-` : ''}row${Math.floor(index / 2)}`;
    return (
      <LazyShowElement className={suitcss({ descendantName: 'item' }, this)} key={item.id} >
        <div className={suitcss({ descendantName: 'callouts' }, this)}>
          {item.type === VO_TYPE_TARIFF && (
            <div className={suitcss({ descendantName: 'labelCallout' }, this)} >
              <div data-eq-group-l={`${eqGroup}-callout`} data-eq-group-m={`${eqGroup}-callout`}>
                <Callout
                  theme="label"
                  context={tariffMode}
                  targets={[item.eid]}
                />
              </div>
            </div>
          )}
        </div>
        <OffersPanel
          theme={theme}
          item={item}
          equalizerGroupM={eqGroup}
          equalizerGroupL={eqGroup}
          onSelect={onSelect}
          onChange={onChange}
          isTariffChange={tariffMode === CONTEXT_TARIFF_CHANGE}
          withHardware={withHardware}
          context={tariffMode}
        />
      </LazyShowElement>
    );
  }

  renderPrices(normalizedTariffs) {
    const { item, ui } = this.props;
    const componentName = 'TariffDetails';
    const normalizedItem = normalizedTariffs.filter(entry => entry.id === item.id)[0] || item;
    const basicFee = normalizedItem.basicFee != null && toPriceObject(normalizedItem.basicFee);
    const basicFeeStrike = normalizedItem.basicFeeStrike
      && toPriceObject(normalizedItem.basicFeeStrike);
    const postfix = getPaymentFeeDuration(normalizedItem, ui, true);
    const availableCondition = normalizedItem.availableConditions &&
      normalizedItem.availableConditions
        .find(condition => condition.transferFee);
    const renewalFee = { unit: availableCondition && availableCondition.transferFee, currency: 'EUR' };
    return (
      <div className={suitcss({ componentName, descendantName: 'prices' })} >
        {availableCondition && (
          <ContractRenewalFeeInfo inverted renewalFee={availableCondition.transferFee} />
        )}
        {basicFee && (
          <div className={suitcss({ componentName, descendantName: 'priceMonthly' })} >
            <div className={suitcss({ componentName, descendantName: 'unit', utilities: ['hidden', 'smBlock'] })}>
              <Unit
                postfix={postfix}
                price={basicFee}
                size="m"
              />
            </div>
            <div className={suitcss({ componentName, descendantName: 'unit', utilities: ['hidden', 'lBlock'] })}>
              <Unit
                postfix={postfix}
                price={basicFee}
                prefixPrice={basicFeeStrike}
                size="l"
              />
            </div>
          </div>
        )}
        {availableCondition && (
          <Copy className={suitcss({ descendantName: 'transferFee', utilities: ['colorInverted'] }, this)} size="small" raw embedded condensed >
            {ui.tariffsEarlyFeeCondition.replace('{PRICE}', toFullCurrency(renewalFee))}
          </Copy>
        )}
      </div>
    );
  }

  renderButton({
    label, onClick, buttonFilled, disabled, element, href,
  }) {
    const componentName = 'TariffDetails';
    const htmlElement = element === 'a' ? null : element || 'div';
    return (
      <div className={suitcss({ componentName, descendantName: 'button' })} >
        <Link
          href={href}
          onClick={!disabled ? onClick : null}
          isDisabled={disabled}
          buttonFilled={buttonFilled}
          asButton
          element={htmlElement}
        >
          {label}
        </Link>
      </div>
    );
  }

  renderButtons() {
    const { tariffMode, withHardware } = this.state;
    const {
      item,
      onSelect,
      onChange,
      ui,
      sitemap,
    } = this.props;
    const {
      market,
      id,
      contractRenewalWithSimOnly,
      contractRenewalWithHardwareOnly,
    } = item;
    const componentName = 'TariffDetails';
    const isContextTariffChange = tariffMode === CONTEXT_TARIFF_CHANGE ||
      (!contractRenewalWithSimOnly && !contractRenewalWithHardwareOnly);
    return (
      <div className={suitcss({ componentName, descendantName: 'buttons' })} >
        {isContextTariffChange && isTariffChangeable(item) && (
          this.renderButton({
            label: ui.myTariffChange,
            onClick: () => onChange(id),
            buttonFilled: true,
          })
        )}
        {contractRenewalWithHardwareOnly
        && (!contractRenewalWithSimOnly || withHardware)
        && !isContextTariffChange && (
          this.renderButton({
            label: ui.myTariffExtendInclusive,
            onClick: () => onSelect(item, true),
            buttonFilled: true,
          })
        )}
        {contractRenewalWithSimOnly && !withHardware && (
          this.renderButton({
            label: ui.myTariffExtendExclusive,
            onClick: () => onSelect(item),
            buttonFilled: true,
          })
        )}
        {market === MARKET_POSTPAID && (
          this.renderButton({
            label: ui.myVvlChooseDifferentTariff,
            href: sitemap.MyDashboardRoute && `${sitemap.MyDashboardRoute.url}?${QUERY_TARIFF_MODE}=${tariffMode}#MyOffersTariffs`,
            element: 'a',
          })
        )}
      </div>
    );
  }

  renderContractRenewalFeeInfo() {
    const { renewalFee } = this.props;
    return <ContractRenewalFeeInfo global renewalFee={renewalFee} />;
  }

  renderContractRenewalTariffInfo() {
    const { tariffMode } = this.state;
    const { item } = this.props;
    return (
      <ContractRenewalTariffInfo
        item={item}
        tariffMode={tariffMode}
      />
    );
  }

  render() {
    const { tariffMode, withHardware } = this.state;
    const {
      ui,
      item,
      renewalFee,
      compact,
      isMediaS,
      tariffsContextTariffChange,
      tariffsContextContractRenewal,
      tariffsContextContractRenewalSimPlus,
    } = this.props;
    const tariffs = tariffMode.includes(CONTEXT_CONTRACT_RENEWAL) && withHardware
      ? tariffsContextContractRenewalSimPlus
      : tariffMode === CONTEXT_TARIFF_CHANGE
        ? tariffsContextTariffChange
        : tariffsContextContractRenewal;
    const tariffsCRwithHW = tariffs.filter(tariff =>
      tariff.availableConditions.some(entry =>
        entry.requiresContractRenewal === true && entry.includesHardware === true));
    const tariffsCRwithoutHW = tariffs.filter(tariff =>
      tariff.availableConditions.some(entry =>
        entry.requiresContractRenewal === true && entry.includesHardware === false));
    const tariffsContractRenewal = tariffs.filter(tariff =>
      tariff.availableConditions.some(entry =>
        entry.requiresContractRenewal === true));
    const tariffsTariffChange = tariffs.filter(tariff =>
      tariff.availableConditions.some(entry =>
        entry.requiresContractRenewal === false));

    const isCRAndTC = !!(tariffsContractRenewal.length && tariffsTariffChange.length);
    const isWithAndWithoutHW = !!(tariffsCRwithHW.length && tariffsCRwithoutHW.length);

    const isContextContractRenewal = tariffMode.includes(CONTEXT_CONTRACT_RENEWAL);
    const tariffsToRender = isContextContractRenewal
      ? withHardware
        ? tariffsCRwithHW
        : tariffsCRwithoutHW
      : tariffsTariffChange;

    const tabs = [
      {
        label: ui.myVvlTariffExtendHeadline,
        value: ui.myVvlTariffExtendHeadline,
        checked: tariffMode.includes(CONTEXT_CONTRACT_RENEWAL),
        context: CONTEXT_CONTRACT_RENEWAL,
      },
      {
        label: ui.myVvlHeadlineChangeTariff,
        value: ui.myVvlHeadlineChangeTariff,
        checked: tariffMode === CONTEXT_TARIFF_CHANGE,
        context: CONTEXT_TARIFF_CHANGE,
      },
    ];
    const input = {
      onChange: this.handleClickOptionBullet,
      value: true,
    };
    const options = [
      {
        name: ui.tariffsLabelVvlSimonly,
        label: ui.tariffsLabelVvlSimonly,
        value: !withHardware,
      },
      {
        name: ui.tariffsLabelVvlSimplus,
        label: ui.tariffsLabelVvlSimplus,
        value: withHardware,
      },
    ];

    return (
      <div className={suitcss({ modifiers: [compact && 'compact'] }, this)}>
        {isCRAndTC && tabs.map((tab, id) => (
          <FormOptionTab
            {...tab}
            data-tariff-context={tab.context}
            onChange={this.handleClickOptionTab}
            shape="round"
            key={id}
          />
        ))}
        {((isWithAndWithoutHW && isContextContractRenewal) || renewalFee) && (
          <div className={suitcss({ descendantName: 'subcontent' }, this)}>
            {isWithAndWithoutHW && isContextContractRenewal && (
              <FieldRadioGroup
                options={options}
                input={input}
                meta={{}}
                asStack={isMediaS}
              />
            )}
            {renewalFee && this.renderContractRenewalFeeInfo()}
          </div>
        )}
        {!compact && <TextHeadline size="m" embedded highlight element="p">{ui.tariffsListSubline}</TextHeadline>}
        {item && compact && this.renderPrices(tariffsToRender)}
        {item && compact && this.renderContractRenewalTariffInfo()}
        {item && compact && this.renderButtons()}
        {tariffsToRender && !compact &&
          <div className={suitcss({ descendantName: 'list' }, this)}>
            {tariffsToRender.map((tariff, id) => this.renderItem(tariff, id))}
          </div>
        }
        {item && compact &&
          <Callout
            theme="badge"
            targets={[item.eid]}
            inverted
            context={tariffMode}
          />
        }
      </div>
    );
  }
}

TariffFilter.propTypes = {
  tariffMode: PropTypes.string,
  items: PropTypes.array,
  item: PropTypes.object,
  renewalFee: PropTypes.number,
  compact: PropTypes.bool,
  isMediaS: PropTypes.bool,
  tariffsContextTariffChange: PropTypes.array,
  tariffsContextContractRenewal: PropTypes.array,
  tariffsContextContractRenewalSimPlus: PropTypes.array,
  onSelect: PropTypes.func,
  onChange: PropTypes.func,
  onRegister: PropTypes.func,
  inverted: PropTypes.bool,
  theme: PropTypes.string,
  moduleId: PropTypes.string,
  sitemap: PropTypes.object,
  ui: PropTypes.object,
};

function makeStateToProps() {
  const myTariffChangeSelector = createNormalizeTargetEntitiesSelector({ style: 'strike', context: CONTEXT_TARIFF_CHANGE });
  const myHardwareRenewalSelector = createNormalizeTargetEntitiesSelector({ style: 'strike', context: CONTEXT_CONTRACT_RENEWAL });
  const myHardwareRenewalSimPlusSelector = createNormalizeTargetEntitiesSelector({ style: 'strike', context: CONTEXT_CONTRACT_RENEWAL_SIM_PLUS });
  return (state, ownProps) => {
    const { site, orderProcess } = state;
    const { items } = ownProps;
    const tariffsContextTariffChange = myTariffChangeSelector(state, items);
    const tariffsContextContractRenewal = myHardwareRenewalSelector(state, items);
    const tariffsContextContractRenewalSimPlus = myHardwareRenewalSimPlusSelector(state, items);

    return {
      sitemap: site.sitemap,
      tariffMode: site.routing.currentLocation.query.tariffMode || orderProcess.tariffMode,
      tariffsContextTariffChange,
      tariffsContextContractRenewal,
      tariffsContextContractRenewalSimPlus,
    };
  };
}

const mapDispatchToProps = (dispatch) => ({
  onSelect: (tariff, isHardwareRequired) => {
    dispatch(contractRenewalActions.startContractRenewalFromTariff(tariff.id, isHardwareRequired));
  },
  onRegister: (tariffMode) => {
    dispatch(registerTariffMode(tariffMode));
  },
});

export default compose(
  connect(
    makeStateToProps,
    mapDispatchToProps,
  ),
  connectUI(),
  matchMediaConnector(['isMediaS']),
)(TariffFilter);
