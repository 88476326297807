import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../../helpers/suitcss';
import FormSection from '../../form/FormSection';
import FormHeader from '../../form/FormHeader';

class SimReplacementFormActivation extends PureComponent {
  render() {
    const { ui, phoneNumber } = this.props;
    return (
      <FormSection className={suitcss({}, this)} asGrid>
        <FormHeader
          headline={ui.mySimrepActivationTitle.replace('{MSISDN}', phoneNumber)}
          copy={ui.txtMySimrepActivateCopy}
        />
      </FormSection>
    );
  }
}

SimReplacementFormActivation.propTypes = {
  ui: PropTypes.object.isRequired,
  phoneNumber: PropTypes.string.isRequired,
};

export default SimReplacementFormActivation;
