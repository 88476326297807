import QueueableRequest from './QueueableRequest';
import { ACCOUNT_TARIFFS_ENDPOINT } from '../../config/api';
import { bindParamsToRoute } from '../../helpers/url';
import { blockContentBasedOnUserScope } from '../../helpers/scope';
import { SCOPE_MYDASHBOARD } from '../../helpers/constants';

/**
 * Fetches all tariffs available to the customer; both booked and bookable alike.
 */
export default class TariffsRequest extends QueueableRequest {
  constructor(msisdn, options = {}) {
    const url = bindParamsToRoute(ACCOUNT_TARIFFS_ENDPOINT, { msisdn });

    super(url, options);
  }

  block(state) {
    return !blockContentBasedOnUserScope(state.user.scope, SCOPE_MYDASHBOARD);
  }
}

