/* global window */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import prefixr from 'react-prefixr';
import { ResizeObserver } from '@juggle/resize-observer';

import suitcss from '../../../helpers/suitcss';
import { CUSTOM_EVENT_RESIZE } from '../../../helpers/constants';
import connectViewport from './ServiceViewportConnector';

class ServiceStickyContent extends PureComponent {
  constructor(...args) {
    super(...args);
    this.state = {
      initialized: false,
      wrapperStyles: {
        height: 'auto',
      },
    };
    this.registerCanvas = this.registerCanvas.bind(this);
    this.updateSize = this.updateSize.bind(this);
    this.onContentResize = this.onContentResize.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateSize, false);
    window.addEventListener('orientationchange', this.updateSize, false);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateSize, false);
    window.removeEventListener('orientationchange', this.updateSize, false);
    if (this.resizeObserver) {
      this.resizeObserver.unobserve(this.canvas);
    }
  }

  onContentResize(ev) {
    ev.preventDefault();
    ev.stopImmediatePropagation();
    this.updateSize();
  }

  updateSize() {
    if (!this.canvas) {
      return;
    }
    const { height } = this.canvas.getBoundingClientRect();
    const wrapperHeight = height;
    this.setState({
      initialized: true,
      wrapperStyles: {
        height: `${wrapperHeight}px`,
      },
    });
  }

  registerCanvas(target) {
    this.canvas = target;
    if (target) {
      this.updateSize();
      this.canvas.addEventListener(CUSTOM_EVENT_RESIZE, this.onContentResize);
      this.resizeObserver = new ResizeObserver(entries => {
        const entry = entries[0];
        const { wrapperHeight } = this.state;
        if (entry.contentRect.height !== wrapperHeight) {
          this.updateSize();
        }
      });
      this.resizeObserver.observe(this.canvas);
    }
  }

  render() {
    const { isFacelift, isMediaSM } = this.props;
    const isEnabled = this.state.initialized && !this.props.disabled;
    const translateValue = this.props.mapScrollToTop(this.props.scroll, isFacelift, isMediaSM);
    const wrapperStyles = isEnabled ? this.state.wrapperStyles : null;
    const canvasStyles = isEnabled ? prefixr({
      position: 'fixed',
      transform: `translateY(${translateValue}px) translateZ(0)`,
    }) : null;

    return (
      <div
        className={suitcss({ modifiers: [this.props.contextName] }, this)}
        style={wrapperStyles}
      >
        <div
          className={suitcss({ descendantName: 'canvas' }, this)}
          ref={this.registerCanvas}
          style={canvasStyles}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}

ServiceStickyContent.propTypes = {
  children: PropTypes.node.isRequired,
  contextName: PropTypes.string,
  scroll: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
  mapScrollToTop: PropTypes.func,
  disabled: PropTypes.bool,
  isMediaSM: PropTypes.bool,
  isFacelift: PropTypes.bool,
};

ServiceStickyContent.defaultProps = {
  mapScrollToTop: () => 0,
  disabled: false,
};

export default connectViewport({
  listenTo: ['scroll'],
})(ServiceStickyContent);
