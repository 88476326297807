import { createSelector } from 'reselect';
import { createMatchingPromosSelector } from './promotions';
import { normalizeTargetEntities } from '../helpers/entity';
import { isAccountUrl } from '../config/api';
import { AVAILABILITY_ACCOUNT, AVAILABILITY_PRESALES, MARKET_MMO, QUERY_SELECTED_TARIFF } from '../helpers/constants';

export const createNormalizeTargetEntitiesSelector = ({ style, context } = {}) => {
  const matchingPromosSelector = createMatchingPromosSelector({ style, context });
  return createSelector(
    (state, entities) => entities.filter(entity => entity),
    matchingPromosSelector,
    (state) => (
      isAccountUrl(state.routing.locationBeforeTransitions)
        ? AVAILABILITY_ACCOUNT
        : AVAILABILITY_PRESALES
    ),
    (state) => state.site.contractRenewal.isInProgress,
    (state) => (
      state.user.simStatusResponse &&
      state.user.simStatusResponse.market === MARKET_MMO &&
      state.user.simStatusResponse.freeSimExchangeAvailable
    ),
    (state) => state.user.contractData && state.user.contractData.earlyContractRenewalFee,
    (state) => (
      state.routing.locationBeforeTransitions &&
      state.routing.locationBeforeTransitions.query[QUERY_SELECTED_TARIFF]
    ),
    (state, entities, firstSelected) => firstSelected,
    normalizeTargetEntities,
  );
};
