import QueueableRequest from './QueueableRequest';
import { AUTHENTICATE_ENDPOINT } from '../../config/api';
import { REQUEST_METHOD_POST } from '../../helpers/constants';
import { isBridgeEnabled } from '../../helpers/site';
import { getCredentials } from '../../services/bridge';
import { createFakeResponse } from '../ApiResponse';
import { copyObj } from '../../helpers/misc';
import UmidInfoRequest from './UmidInfoRequest';

export default class LoginRequest extends QueueableRequest {

  constructor(username, password) {
    super(AUTHENTICATE_ENDPOINT, {
      method: REQUEST_METHOD_POST,
      payload: { username, password },
      priority: QueueableRequest.PRIO_100,
    });
  }

  /**
   * We always explicitly return true here since all errors
   * shall be handled by the login form. The flyout login menu doesn't
   * feature a block error which would cause the alert to be shown if
   * the default error handling were not prevented.
   *
   * {@inheritDoc}
   */
  isPreventDefaultErrorHandling() {
    return true;
  }

  async getFakeResponse(state) {
    const { site } = state;

    if (!isBridgeEnabled(site)) {
      return;
    }

    // we have to delegate the reauthentication in a webview the native app
    // and convert the result in a response-like object so that we don't
    // have to change the response handling
    const credentials = await getCredentials();
    const data = copyObj(credentials, ['token', 'refreshToken', 'userType', 'msisdn', 'name', 'phoneNumber', 'scope']);
    const headers = { 'X-Nonce': credentials.nonce };
    return createFakeResponse(data, headers);
  }

  getSubsequentRequests() {
    return [
      new UmidInfoRequest(),
    ];
  }
}
