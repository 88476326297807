import React from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';

import Headline from '../../basics/text/TextHeadline';
import { preventDefault } from '../../../helpers/functional';

function CheckoutProgressBar(props) {
  const { steps, currentStep, onStepClick } = props;
  const currentStepIndex = steps.findIndex(step => step.value === currentStep);
  const maxSteps = steps.length;
  return (
    <nav
      className={suitcss({
        componentName: 'CheckoutProgressBar',
      })}
    >
      {steps.map((step, index) => (
        <div
          className={suitcss({
            componentName: 'CheckoutProgressBar',
            descendantName: 'step',
            states: [
              !step.isEnabled && 'disabled',
              (currentStepIndex && index < currentStepIndex && step.isEnabled) && 'done',
              index === currentStepIndex && 'current',
            ],
          })}
          key={index}
        >
          <div
            className={suitcss({
              componentName: 'CheckoutProgressBar',
              descendantName: 'label',
            })}
          >
            <Headline
              size="xs"
              embedded
              element="button"
              utilities={['sHidden']}
              onClick={preventDefault(() => onStepClick(step.value))}
            >
              {step.label}
            </Headline>
            <Headline
              size="xs"
              embedded
              utilities={['sBlock', 'mlHidden']}
            >
              {step.label}
              <span
                className={suitcss({
                  componentName: 'CheckoutProgressBar',
                  descendantName: 'progressSteps',
                  utilities: ['sBlock'],
                })}
              >
                <span
                  className={suitcss({
                    componentName: 'CheckoutProgressBar',
                    descendantName: 'activeStep',
                    utilities: ['colorPrimary'],
                  })}
                >
                  {currentStepIndex + 1}
                </span> / {maxSteps}
              </span>
            </Headline>
          </div>
        </div>
      ))}
    </nav>
  );
}

CheckoutProgressBar.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  onStepClick: PropTypes.func.isRequired,
  currentStep: PropTypes.string,
};

CheckoutProgressBar.defaultProps = {};

export default CheckoutProgressBar;
