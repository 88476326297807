import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import suitcss from '../../helpers/suitcss';
import * as contractRenewalActions from '../../actions/dialog/contractRenewalActions';
import UIAlertBar from '../../components/basics/ui/UIAlertBar';

import Copy from '../../components/basics/text/TextCopy';
import Link from '../../components/basics/text/TextLink';

/*
  Todo - Refactor / merge with similiar Modules / Components
 */

class GlobalVVLBar extends PureComponent {
  getContent() {
    const { user, cancelContractRenewal, ui } = this.props;
    return [
      <Copy
        utilities={['fontCondensed']}
        embedded
        raw
        key="gvb-cr1-1"
      >
        {ui.myVvlHeaderExtendInfo.replace('{MSISDN}', `<span>${user.phoneNumber}</span>`)}
      </Copy>,
      <Link
        element="span"
        onClick={cancelContractRenewal}
        utilities={['fontCondensed', 'uppercase']}
        key="gvb-cr1-2"
      >
        {ui.guiWordCancel}
      </Link>,
    ];
  }

  render() {
    const { isVisible } = this.props;
    if (!isVisible) return null;
    const content = this.getContent();
    return (
      <div className={suitcss({}, this)}>
        <UIAlertBar content={content} />
      </div>
    );
  }
}

GlobalVVLBar.propTypes = {
  user: PropTypes.object,
  isVisible: PropTypes.bool,
  cancelContractRenewal: PropTypes.func,
  ui: PropTypes.shape({
    guiWordCancel: PropTypes.string,
    myVvlHeaderExtendInfo: PropTypes.string,
  }),
};

const mapStateToProps = (state) => {
  const { user, site, ui } = state;
  const isVisible = site.contractRenewal.isInProgress;
  return { user, isVisible, ui };
};

const mapDispatchToProps = {
  cancelContractRenewal: contractRenewalActions.cancelContractRenewal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GlobalVVLBar);
