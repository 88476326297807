import LogEntry from './LogEntry';
import { makeAbstractError } from '../../helpers/misc';

export default class ErrorLogEntry extends LogEntry {
  /**
   * Logs a frontend error to the graylog.
   *
   * @see https://confluence.db-n.com/x/HqL3
   *
   * @param {AbstractError} error
   * @param {string} message - the error text displayed to the user
   * @param {('dialog'|'alert'|'form-error-bar|'hidden')} displayStyle - how was the error
   *     displayed to the user?
   */
  constructor(error, message, displayStyle) {
    error = makeAbstractError(error); // eslint-disable-line no-param-reassign
    super(LogEntry.LOG_LEVEL_ERROR, error.getContext(), message, error.getLoggingData());
    this.fullErrorCode = error.fullCode;
    this.displayStyle = displayStyle;
    this.errorTrace = (error.stack || '').split('\n');
  }
}
