/**
 * @file
 *
 * Actions contained in this file are used for garbage collection.
 * @see https://jira.db-n.com/browse/OT-3950
 */

import { uniqueId } from '../../helpers/identifier';
import { ACTION_PREFIX } from '../../helpers/constants';

export const ADD_TRACKING_GARBAGE = `${ACTION_PREFIX}/ADD_TRACKING_GARBAGE`;
export const CLEAR_TRACKING_GARBAGE = `${ACTION_PREFIX}/CLEAR_TRACKING_GARBAGE`;
export const REMOVE_TRACKING_GARBAGE = `${ACTION_PREFIX}/REMOVE_TRACKING_GARBAGE`;

export const addTrackingGarbage = (nodes) => ({
  type: ADD_TRACKING_GARBAGE,
  meta: { identifier: uniqueId('tracking_gc') },
  payload: nodes,
});

export const removeTrackingGarbage = (nodes) => ({
  type: REMOVE_TRACKING_GARBAGE,
  meta: { identifier: uniqueId('tracking_gc') },
  payload: nodes,
});

export const clearTrackingGarbage = () => ({
  type: CLEAR_TRACKING_GARBAGE,
  meta: { identifier: uniqueId('tracking_gc') },
  payload: null,
});
