import {
  ADD_SEARCH_RESULTS,
  REMOVE_SEARCH_RESULTS,
  SET_LAST_USER_SEARCH_LOCATION,
  SET_ACTIVE_FACET,
} from '../actions/search/search';

const defaultState = {
  searchResults: [],
  searchMetaInfo: null,
  searchQuery: '',
  searchFacet: '',
  lastSearchLocation: '',
};

export default (state = defaultState, action = {}) => {
  const { payload } = action;

  switch (action.type) {
    case ADD_SEARCH_RESULTS:
      return {
        ...state,
        searchMetaInfo: payload.meta,
        searchResults: payload.data,
        searchQuery: payload.searchQuery,
        searchFacet: payload.searchFacet,
      };

    case REMOVE_SEARCH_RESULTS:
      return { ...state, ...defaultState };

    case SET_LAST_USER_SEARCH_LOCATION:
      return { ...state, ...payload };

    case SET_ACTIVE_FACET:
      return { ...state, ...payload };
    default:
      return state;
  }
};

