import AbstractError from './AbstractError';
import { ILLOGICAL_RESPONSE_ERROR } from '../../config/errorCodes';

/**
 * Thrown if the received response has inapplicable data
 */
class IllogicalResponseError extends AbstractError {
  constructor() {
    super(ILLOGICAL_RESPONSE_ERROR);
  }
}

export default IllogicalResponseError;
