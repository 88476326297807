import { push } from 'react-router-redux';
import { showSimpleDialogWithCancelButtonAndAction } from '../actions/dialog/misc';

const redirectToLandingPage = (dispatch, site) => {
  const path = site.contractRenewal.isInProgress ? '/mein-otelo/dashboard' : '/';
  dispatch(push(path));
};
const redirectToSuccessPage = (dispatch, orderId) => {
  const showInfo = '&showInfo=true';
  dispatch(push(`/bestellen/bestaetigung?orderId=${orderId}${showInfo}`));
};
const showNavDialog = (dispatch, site, ui) => {
  const action = site.contractRenewal.isInProgress ? ui.cfoNavBackDashboard : ui.cfoNavBackHome;
  dispatch(showSimpleDialogWithCancelButtonAndAction(
    ui.cfoOrderSuccessful,
    ui.cfoNavBack,
    action,
    '',
    () => redirectToLandingPage(dispatch, site),
  ));
};

/**
 * This function is used to modify the browser navigation
 * and insert a dialog to explain the user where he will be redirected.
 * @param dispatch
 * @param getState
 * @returns {boolean}
 */
export const checkoutNavCatcher = (dispatch, getState) => {
  const {
    cart,
    order,
    site,
    ui,
  } = getState();
  const isSuccessPage = site.routing.currentLocation.pathname === '/bestellen/bestaetigung';
  const orderId = Object.keys(order).length > 0 ? Object.keys(order)[0] : undefined;

  if (cart.length !== 0 && isSuccessPage) {
    redirectToLandingPage(dispatch, site);
    return true;
  }

  if (cart.length !== 0) {
    return false;
  }

  if (!orderId) {
    redirectToLandingPage(dispatch, site);
    return true;
  }

  if (!isSuccessPage) {
    redirectToSuccessPage(dispatch, orderId);
    return true;
  }

  if (site.routing.currentLocation.query.showInfo) {
    showNavDialog(dispatch, site, ui);
    return true;
  }
  return false;
};
