import React from 'react';
import PropTypes from 'prop-types';

import { isHardwareEntity, sortByAvailablePriority } from '../../helpers/entity';
import GlobalSection from '../../components/basics/global/GlobalSection';
import TeaserProductsComposition from '../../components/compositions/teaser/TeaserProducts';

function mapEntitiesToItems({ items, entities }) {
  return items.map((item) => {
    const hardwareGroup = entities.find(entity => entity.eid === item.hardwareGroupEid);
    const hardwareList = entities
      .filter(isHardwareEntity)
      .filter(entity => entity.groupId === hardwareGroup.eid)
      .sort(sortByAvailablePriority);
    return {
      hardwareGroup,
      name: `${hardwareGroup.brand} ${hardwareGroup.name}`,
      hardware: hardwareList[0],
      tariff: entities.find(entity => entity.eid === item.tariffEid),
    };
  }, []);
}

function TeaserProducts({ params, layoutSettings, entities, primaryModule }) {
  return (
    <GlobalSection
      layout="expanded"
      theme={params.colorScheme}
      layoutSettings={layoutSettings}
    >
      <TeaserProductsComposition
        primaryModule={primaryModule}
        type={params.type}
        headline={params.headline}
        footer={params.footer}
        items={mapEntitiesToItems({ items: params.group, entities })}
        inverted={params.colorScheme === 'dark'}
      />
    </GlobalSection>
  );
}

TeaserProducts.propTypes = {
  params: PropTypes.object.isRequired,
  layoutSettings: PropTypes.object,
  primaryModule: PropTypes.bool.isRequired,
  entities: PropTypes.array,
};

export default TeaserProducts;
