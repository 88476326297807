import { createSelector } from 'reselect';

import { uriFullDecode } from '../helpers/url';

/**
 * note: we remove the query part of the url as we do not store the query part
 * in our store but sometimes use url string that includes the query part
 * when calling getPage().
 *
 * @param {string} url
 * @return {string}
 */
export const makePageStoreId = url => uriFullDecode(url.split('?')[0]);

/**
 * @param {PageModule[]} pages
 * @param {string} url
 * @return {PageModule}
 */
export const getPage = (pages, url) => pages[makePageStoreId(url)];

// @todo this selector is unecessary as it returns the exact object that lies in the...
// ...store and the memoized selector is not a calculative expensive function.
export const createCurrentPageSelector = () => createSelector(
  state => state.pages,
  state => state.routing.locationBeforeTransitions.pathname,
  getPage,
);
