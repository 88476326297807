import PageModule from './PageModule';
import component from '../../containers/checkout/CheckoutCart';
import { UI_COLLECTION_PROMOTION } from '../../helpers/constants';

/**
 * This module displays the selected tariff/hardware/options before proceeding
 * to the actual checkout.
 * @see https://confluence.db-n.com/x/8qHc
 */
class CheckoutCartModule extends PageModule {
  constructor(moduleData) {
    super(component, moduleData);
  }

  /**
   * @override
   */
  getRequiredUi() {
    return [UI_COLLECTION_PROMOTION];
  }
}

export default CheckoutCartModule;
