import QueueableRequest from './QueueableRequest';
import { MENU_ENDPOINT } from '../../config/api';
import { bindParamsToRoute } from '../../helpers/url';

export default class MenuRequest extends QueueableRequest {
  constructor(id) {
    super(bindParamsToRoute(MENU_ENDPOINT, { id }), {
      isCritical: true,
    });
    this.identifier = id;
  }
}
