import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { fetchTariffs, fetchContractData, fetchGdprConsentsAggregatedData } from '../../../actions/request/registry';
import { trackMyState } from '../../../actions/tracking/event';
import * as cancellationReason from './formConfigCancellationReason';
import * as holdOn from './formConfigHoldOn';
import * as futureContact from './formConfigFutureContact';
import * as constants from '../../../helpers/constants';
import CancellationRequest from '../../../model/requests/CancellationRequest';
import GlobalSection from '../../../components/basics/global/GlobalSection';
import FormManager from '../../form/FormManager';
import AccountGdprConsentsAggregatedRequest from '../../../model/requests/AccountGdprConsentsAggregatedRequest';
import { send } from '../../../actions/request/send';
import { getConsentHashById } from '../../../helpers/misc';

/**
 * Form guides the user through the tariff cancellation process.
 */
class CancellationForm extends PureComponent {
  constructor(...args) {
    super(...args);
    // early bindings
    this.getSubmitRoute = this.getSubmitRoute.bind(this);
    this.onBeforeSubmit = this.onBeforeSubmit.bind(this);
    this.onAfterSubmitSuccess = this.onAfterSubmitSuccess.bind(this);
  }

  async componentDidMount() {
    const { dispatch } = this.props;
    await dispatch(fetchContractData());
    await dispatch(fetchGdprConsentsAggregatedData());
    await dispatch(fetchTariffs({ isBlocking: true }));
  }

  onAfterSubmitSuccess({ finalizedValues }) {
    const { dispatch, user, consentTexts } = this.props;
    const msg = constants.MYTRACK_CONTRACT_CANCELLATION_REASON.replace(
      constants.TRACK_REPLACE_REASON,
      `${finalizedValues.cancel_reason_key}`.toLowerCase(),
    );
    dispatch(trackMyState(msg));
    dispatch(send(new AccountGdprConsentsAggregatedRequest(user.credentials.msisdn, {
      method: constants.REQUEST_METHOD_POST,
      payload: {
        adConsent: !!finalizedValues.ad_consent,
        passData: !!finalizedValues.pass_data,
        ...!!finalizedValues.ad_consent && {
          adConsentTextHash:
            getConsentHashById(consentTexts, constants.FORM_FIELD_GDPR_CONSENT_BEW),
        },
        ...!!finalizedValues.ad_consent && {
          passDataTextHash:
            getConsentHashById(consentTexts, constants.FORM_FIELD_GDPR_CONSENT_DAT),
        },
      },
    })));
  }

  onBeforeSubmit(fieldMap, normalizedValues) {
    const { bookedTariff } = this.props;

    return {
      ...normalizedValues,
      cancel_date: bookedTariff.possibleCancellationDate,
    };
  }

  getSubmitRoute(fieldMap, finalizedValues) {
    const { user } = this.props;
    return new CancellationRequest(
      user.credentials.msisdn,
      user.contractData.contractId,
      {
        method: constants.REQUEST_METHOD_POST,
        payload: finalizedValues,
      },
    );
  }

  getSteps() {
    const { user } = this.props;
    const isBlackListed = user.adConsent && user.adConsent.blacklisted;
    const isSet = user.adConsent && user.adConsent.adConsent && user.adConsent.passData;
    return [
      {
        ...holdOn,
        next: cancellationReason.id,
      },
      {
        ...cancellationReason,
        next: !isBlackListed && !isSet
          ? futureContact.id
          : null,
      },
      !isBlackListed && !isSet && { ...futureContact },
    ].filter(step => !!step);
  }

  render() {
    const { location, sitemap, user, bookedTariff, dispatch } = this.props;

    if (!user.contractData || !bookedTariff) {
      return null;
    }

    const { contractData } = user;
    const stepProps = { ...this.props, cancellationReasons: contractData.cancellationReasons };
    const onCancel = () => {
      dispatch(trackMyState(constants.MYTRACK_CONTRACT_CANCELLATION_BACK));
      dispatch(push(sitemap.MyDashboardRoute.url));
    };

    return (
      <GlobalSection>
        <FormManager
          form={CancellationForm.formName}
          submitRoute={this.getSubmitRoute}
          successRoute={contractData.billingMode === 'post' ?
            sitemap.MyCancellationWithoutABFSuccessRoute.url :
            sitemap.MyCancellationSuccessRoute.url
          }
          onAfterSubmitSuccess={this.onAfterSubmitSuccess}
          onCancel={onCancel}
          onBeforeSubmit={this.onBeforeSubmit}
          steps={this.getSteps()}
          location={location}
          stepProps={stepProps}
          isLinearForm
          withFooter
        />
      </GlobalSection>
    );
  }
}

/**
 * required by tracking!
 */
CancellationForm.formName = 'cancellation';

CancellationForm.propTypes = {
  user: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  sitemap: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  bookedTariff: PropTypes.object,
  consentTexts: PropTypes.array,
};

const mapStateToProps = ({ site, user, entities }) => {
  const bookedTariff = user.bookedTariffs && entities.tariffVO[user.bookedTariffs[0]];
  return {
    sitemap: site.sitemap,
    user,
    adConsent: user.adConsent,
    consentTexts: site.consentTexts,
    bookedTariff: bookedTariff ? (bookedTariff.original || bookedTariff) : null,
  };
};

const mapDispatchToProps = dispatch => ({ dispatch });

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CancellationForm);
