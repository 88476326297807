import PageModule from './PageModule';
import component from '../../containers/account/registration/RegistrationForm';

/**
 * Module for displaying registration form
 * @see https://confluence.db-n.com/x/FPTc
 */
class RegistrationFormModule extends PageModule {
  constructor(moduleData) {
    super(component, moduleData);
  }
}

export default RegistrationFormModule;

