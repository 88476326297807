import QueueableRequest from './QueueableRequest';
import BalanceRequest from './BalanceRequest';
import TopupStatusRequest from './TopupStatusRequest';
import { ACCOUNT_TOPUP_VOUCHER_ENDPOINT } from '../../config/api';
import { bindParamsToRoute } from '../../helpers/url';
import { REQUEST_METHOD_POST } from '../../helpers/constants';

export default class VoucherTopupRequest extends QueueableRequest {
  constructor(msisdn, code) {
    const url = bindParamsToRoute(ACCOUNT_TOPUP_VOUCHER_ENDPOINT, { msisdn });
    super(url, {
      method: REQUEST_METHOD_POST,
      payload: { code },
    });
  }

  /**
   * @inheritDoc
   */
  getSubsequentRequests({ user }) {
    return [
      new TopupStatusRequest(user.credentials.msisdn),
      new BalanceRequest(user.credentials.msisdn),
    ];
  }
}
