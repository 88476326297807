import { MEDIA_S, MEDIA_SM, MEDIA_M } from '../containers/service/ServiceMatchMedia';

const FALLBACK = '';

// header item sizes are calculated based on the height, the width is based on
// the format we can just estimate the correct size and in the most of the cases
// its around 200px
const headerItemFeatured = {
  [MEDIA_S]: '200px',
  [FALLBACK]: '230px',
};
const headerItem = { [FALLBACK]: '200px' };

// The aspect ratio of the image is generally arount 3.5 (453x128) and
// the height of the is set to 13.375rem
const headerItemBackground = {
  [FALLBACK]: 'calc(3.5 * 13.375rem)',
};

const headerItemBackgroundFeatured = {
  [MEDIA_S]: '250vw',
  [FALLBACK]: '100vw',
};

const globalSection = {
  [MEDIA_SM]: '100vw',
  [FALLBACK]: '960px',
};

const globalSectionExpanded = {
  [FALLBACK]: '100vw',
};

const blogListItem = {
  [MEDIA_S]: 'calc(100vw - (0.625rem * 2))',
  [MEDIA_M]: 'calc(50vw - 2.25rem)',
  [FALLBACK]: 'calc(480px - 1.5rem)',
};

const blogListItemFeatured = {
  [MEDIA_S]: '250vw',
  [FALLBACK]: '100vw',
};

const blogArticle = {
  [MEDIA_S]: 'calc(100vw - 1.25rem)',
  [FALLBACK]: 'calc(960px - 1.5rem)',
};

const teaser = {
  full: {
    [MEDIA_SM]: '100vw',
    [FALLBACK]: '960px',
  },
  half: {
    [MEDIA_SM]: '100vw',
    [FALLBACK]: '480px',
  },
  third: {
    [MEDIA_SM]: '100vw',
    [FALLBACK]: '320px',
  },
  fourth: {
    [MEDIA_SM]: '100vw',
    [FALLBACK]: '240px',
  },
  threeFourths: {
    [MEDIA_S]: '100vw',
    [FALLBACK]: '720px',
  },
};

const dialog = {
  small: {
    [MEDIA_S]: '100vw',
    [FALLBACK]: '320px',
  },
};

const contentGroupItem = {
  'cols-1-25': {
    [MEDIA_S]: 'calc(100vw - 1.5rem)',
    [MEDIA_M]: 'calc((100vw - 3rem) * .25)',
    [FALLBACK]: '240px',
  },
  'cols-1-33': {
    [MEDIA_S]: 'calc(100vw - 1.5rem)',
    [MEDIA_M]: 'calc((100vw - 3rem) * .5)',
    [FALLBACK]: '480px',
  },
  'cols-1-50': {
    [MEDIA_S]: 'calc(100vw - 1.5rem)',
    [MEDIA_M]: 'calc((100vw - 3rem) * .5)',
    [FALLBACK]: '480px',
  },
  'cols-1-75': {
    [MEDIA_S]: 'calc(100vw - 1.5rem)',
    [MEDIA_M]: 'calc((100vw - 3rem) * .75)',
    [FALLBACK]: '720px',
  },
  'cols-1-100': {
    [MEDIA_S]: 'calc(100vw - 1.5rem)',
    [MEDIA_M]: 'calc(100vw - 3rem)',
    [FALLBACK]: '960px',
  },
  'cols-2-25': {
    [MEDIA_S]: 'calc(100vw - 1.5rem)',
    [MEDIA_M]: 'calc(50vw - 2.25rem)',
    [FALLBACK]: 'calc((480px - 1.5rem) * .5)',
  },
  'cols-2-50': {
    [MEDIA_S]: 'calc(100vw - 1.5rem)',
    [MEDIA_M]: 'calc(50vw - 2.25rem)',
    [FALLBACK]: 'calc((480px - 1.5rem) * .5)',
  },
  'cols-2-75': {
    [MEDIA_S]: 'calc(100vw - 1.5rem)',
    [MEDIA_M]: 'calc(50vw - 2.25rem)',
    [FALLBACK]: 'calc((480px - 1.5rem) * .75)',
  },
  'cols-2-100': {
    [MEDIA_S]: 'calc(100vw - 1.5rem)',
    [MEDIA_M]: 'calc(50vw - 2.25rem)',
    [FALLBACK]: 'calc(480px - 1.5rem)',
  },
  'cols-3-25': {
    [MEDIA_S]: 'calc(100vw - 1.5rem)',
    [MEDIA_M]: 'calc(50vw - 2.25rem)',
    [FALLBACK]: 'calc((320px - 2rem) * .25)',
  },
  'cols-3-50': {
    [MEDIA_S]: 'calc(100vw - 1.5rem)',
    [MEDIA_M]: 'calc(50vw - 2.25rem)',
    [FALLBACK]: 'calc((320px - 2rem) * .5)',
  },
  'cols-3-75': {
    [MEDIA_S]: 'calc(100vw - 1.5rem)',
    [MEDIA_M]: 'calc(50vw - 2.25rem)',
    [FALLBACK]: 'calc((320px - 2rem)  * .75)',
  },
  'cols-3-100': {
    [MEDIA_S]: 'calc(100vw - (0.625rem * 2))',
    [MEDIA_M]: 'calc(50vw - 2.25rem)',
    [FALLBACK]: 'calc(320px - 2rem)',
  },
};

const teaserProduct = {
  // limit bundle images to 150px (also fixes layout issues with landscape images)
  bundle: {
    [FALLBACK]: '9.375rem',
  },
  // hardware images are limited by css to 9.375rem
  hardware: {
    [FALLBACK]: '9.375rem',
  },
};

const downloadListItem = {
  [MEDIA_S]: 'calc(100vw - (0.625rem * 2) - (1.5rem * 2))',
  [MEDIA_M]: 'calc((100vw - (1.5rem * 2)) * .33333 - 1.5rem)',
  [FALLBACK]: 'calc((960px - (1.5rem * 2)) * .33333 - 1.5rem)',
};

const hardwareItem = {
  [MEDIA_S]: '7.0625rem',
  // available width for images limited to height 195px
  // maybe limit to 200px width?
  [MEDIA_M]: 'calc(50vw - 4.5rem)',
  [FALLBACK]: 'calc(960px * .33333 - 3rem)',
};

const hardwareItemCompact = {
  [MEDIA_S]: '1.5rem',
  [MEDIA_SM]: '5.25rem',
  // limited in css to 120px (960*25%-7.5rem)
  [FALLBACK]: '7.5rem',
};

const hardwareItemFull = {
  [MEDIA_S]: '7.875rem',
  [FALLBACK]: '13.875rem',
};

const hardwareDetailBlock = {
  [MEDIA_SM]: '100vw',
  [FALLBACK]: 'calc(480px - 1.5rem)',
};

const shoppingCartProduct = {
  [FALLBACK]: '5rem',
};

const tariffOptionDetailImage = {
  [FALLBACK]: '2.5rem',
};

const activationFormDescription = {
  [MEDIA_S]: 'calc(100vw - 1.25rem)',
  [MEDIA_M]: 'calc((100vw - 9rem) * 0.6)',
  [FALLBACK]: 'calc((960px - 9rem) * 0.6)',
};

const tariffDetailsBackground = {
  [MEDIA_S]: '100vw',
  [FALLBACK]: '450px',
};

const mediaGalleryImage = {
  [MEDIA_S]: 'calc(90vw - 3rem - 120px)',
  [FALLBACK]: 'calc(70vw - 3rem - 120px)',
};

export default {
  headerItemFeatured,
  headerItemBackgroundFeatured,
  headerItem,
  headerItemBackground,
  globalSection,
  globalSectionExpanded,
  blogArticle,
  blogListItem,
  blogListItemFeatured,
  teaser,
  teaserProduct,
  contentGroupItem,
  downloadListItem,
  hardwareItem,
  hardwareItemCompact,
  hardwareItemFull,
  hardwareDetailBlock,
  shoppingCartProduct,
  activationFormDescription,
  tariffDetailsBackground,
  mediaGalleryImage,
  tariffOptionDetailImage,
  dialog,
};
