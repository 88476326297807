import QueueableRequest from './QueueableRequest';
import { COUNTRIES_ENDPOINT } from '../../config/api';
import { bindParamsToRoute } from '../../helpers/url';
// import { createFakeResponse } from '../ApiResponse';
// import response from '../../../fixtures/responses/countries.json';

export default class CountriesRequest extends QueueableRequest {
  constructor(market) {
    super(bindParamsToRoute(COUNTRIES_ENDPOINT, { market }));
  }

  // getFakeResponse() {
  //  return createFakeResponse(response);
  // }
}
