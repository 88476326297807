import QueueableRequest from './QueueableRequest';
import { ID_MAP_ENDPOINT } from '../../config/api';

export default class IdMapRequest extends QueueableRequest {
  constructor() {
    super(ID_MAP_ENDPOINT, {
      isCritical: true,
    });
  }
}
