import React from 'react';
import PropTypes from 'prop-types';

import suitcss from '../../../helpers/suitcss';
import connectUniqueId from '../service/UniqueIdConnector';

import { hintShape, inputShape, metaShape } from '../../../propTypes/field';

import FieldWrapper from './FieldWrapper';

const componentName = 'FieldInput';
const allowedTypes = ['text', 'password', 'email', 'tel'];

function FieldInput(props) {
  const {
    input,
    label,
    uniqueId,
    autocomplete,
    isReadOnly,
    maxLength,
  } = props;
  const type = allowedTypes.includes(props.type) ? props.type : 'text';
  return (
    <FieldWrapper
      componentName={componentName}
      {...props}
    >
      {wrapperProvider => (
        <div className={suitcss({ componentName, descendantName: 'inner' })} >
          <input
            autoComplete={autocomplete}
            maxLength={maxLength}
            className={suitcss({
              componentName,
              descendantName: 'input',
            })}
            id={uniqueId}
            type={type}
            onInput={props.onInput}
            onBlur={props.onBlur}
            readOnly={isReadOnly}
            {...input}
          />
          <label
            className={suitcss({
              componentName,
              descendantName: 'label',
            })}
            htmlFor={uniqueId}
          >
            <span
              className={suitcss({
                componentName,
                descendantName: 'labelInner',
              })}
            >
              {label}
              {!wrapperProvider.isRequired && wrapperProvider.getIsOptionalElement()}
            </span>
          </label>
        </div>
      )}
    </FieldWrapper>
  );
}

FieldInput.propTypes = {
  uniqueId: PropTypes.string.isRequired,
  type: PropTypes.string,
  label: PropTypes.string,
  hint: hintShape,
  input: inputShape,
  meta: metaShape,
  raw: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  utitilies: PropTypes.array,
  theme: PropTypes.oneOf(['default', 'compact']),
  autocomplete: PropTypes.string,
  onInput: PropTypes.func,
  onBlur: PropTypes.func,
  maxLength: PropTypes.number,
};

FieldInput.defaultProps = {
  type: 'text',
  meta: {},
  input: {},
  theme: 'default',
};

export default connectUniqueId('fi')(FieldInput);
