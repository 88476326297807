import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';

import Unit from '../../basics/text/TextUnit';
import Copy from '../../basics/text/TextCopy';

class ShoppingCartRow extends PureComponent {

  render() {
    const {
      theme,
      className,
      children,
      paymentFee,
      paymentFeeStrike,
      singlePaymentFee,
      singlePaymentFeeStrike,
      isDisabled,
      isAdjacentTop,
      isAdjacentBottom,
      isAlignedBottom,
      isHighlighted,
      isMarked,
      isPua,
      withoutPrices,
      showGratis,
      wordGratis,
    } = this.props;

    const isCompact = theme === 'compact';
    const shouldRenderPrices = theme === 'full' || !withoutPrices;
    return (
      <div
        className={suitcss({
          className,
          modifiers: [
            theme,
            isAdjacentTop && 'adjacentTop',
            isAdjacentBottom && 'adjacentBottom',
            isHighlighted && 'highlight',
            isMarked && 'mark',
          ],
          states: [isDisabled && 'disabled'],
        }, this)}
      >
        <div
          className={suitcss({
            descendantName: 'inner',
            utilities: ['row', isAlignedBottom && 'itemsEnd'],
          }, this)}
        >
          <div
            className={suitcss({
              descendantName: 'col',
              utilities: isCompact
                ? [shouldRenderPrices ? 'col6' : 'col12']
                : [!withoutPrices && 'sCol6', withoutPrices && 'sCol12', 'mlCol8'],
            }, this)}
          >
            {children}
          </div>
          {shouldRenderPrices &&
            <div
              className={suitcss({
                descendantName: 'col',
                utilities: isCompact
                  ? ['col3', 'alignRight']
                  : ['sCol3', 'mlCol2', withoutPrices && 'sHidden', 'alignRight'],
              }, this)}
            >
              {singlePaymentFee && (singlePaymentFee.unit > 0 || !showGratis) &&
                <Unit
                  prefix={singlePaymentFee.prefix}
                  price={singlePaymentFee}
                  prefixPrice={singlePaymentFeeStrike}
                  bold={!isCompact}
                  wrap={isCompact}
                />
              }
              {singlePaymentFee && singlePaymentFee.unit === 0 && showGratis &&
                <Copy size="secondary" condensed embedded>{wordGratis}</Copy>
              }
            </div>
          }
          {shouldRenderPrices &&
            <div
              className={suitcss({
                descendantName: 'col',
                utilities: isCompact
                  ? ['col3', 'alignRight']
                  : ['sCol3', 'mlCol2', withoutPrices && 'sHidden', 'alignRight'],
              }, this)}
            >
              {paymentFee && (paymentFee.unit > 0 || !showGratis) &&
                <Unit
                  prefix={paymentFee.prefix}
                  price={paymentFee}
                  prefixPrice={paymentFeeStrike}
                  bold={!isCompact}
                  wrap={isCompact}
                />
              }
              {paymentFee && paymentFee.unit === 0 && showGratis && !isPua &&
              <Copy size="secondary" condensed embedded>{wordGratis}</Copy>
              }
            </div>
          }
        </div>
      </div>
    );
  }
}

ShoppingCartRow.propTypes = {
  theme: PropTypes.oneOf(['full', 'compact', 'widget']),
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  paymentFee: PropTypes.object,
  paymentFeeStrike: PropTypes.object,
  singlePaymentFee: PropTypes.object,
  singlePaymentFeeStrike: PropTypes.object,
  isDisabled: PropTypes.bool,
  isAdjacentTop: PropTypes.bool,
  isAdjacentBottom: PropTypes.bool,
  isAlignedBottom: PropTypes.bool,
  isHighlighted: PropTypes.bool,
  isMarked: PropTypes.bool,
  isPua: PropTypes.bool,
  showGratis: PropTypes.bool,
  withoutPrices: PropTypes.bool,
  wordGratis: PropTypes.string,
};

ShoppingCartRow.defaultProps = {
  theme: 'full',
};

export default ShoppingCartRow;
