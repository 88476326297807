import React from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';

import TextRaw from '../text/TextRaw';
import Info from '../text/TextInfo';
import FootnoteReference from '../../../containers/footnote/FootnoteReference';
import SvgLoader from '../media/MediaSvgLoader';
import { extractDataAttributes } from '../../../helpers/misc';

function FormOptionBox(props) {
  const {
    id,
    label,
    name,
    value,
    checked,
    info,
    modifiers,
    utilities,
    onChange,
    withFootnote,
    disabled,
    data,
  } = props;
  const inputProps = {
 id, name, value, checked, disabled,
};
  const dataset = extractDataAttributes(data);
  return (
    <div
      className={suitcss({
        componentName: 'FormOptionBox',
        modifiers,
        utilities,
      })}
    >
      <input
        className={suitcss({
          componentName: 'FormOptionBox',
          descendantName: 'input',
        })}
        type="checkbox"
        {...inputProps}
        onChange={onChange}
        data-tracking-target
        data-tracking="optionbox"
        data-optionbox-value={!checked}
        data-optionbox-label={label}
        {...dataset}
      />
      <label
        className={suitcss({
          componentName: 'FormOptionBox',
          descendantName: 'label',
        })}
        htmlFor={id}
      >
        <TextRaw
          className={suitcss({
            componentName: 'FormOptionBox',
            descendantName: 'labelInner',
          })}
          element="span"
        >
          {label}
        </TextRaw>
        {withFootnote && <FootnoteReference id={id} />}
        &nbsp;
        {info
          && <Info headline={info.headline} copy={info.copy} />}
      </label>
      <div
        className={suitcss({
          descendantName: 'checkmark',
        }, this)}
      >
        <SvgLoader
          path="/icons/content-check-stroke.svg"
        />
      </div>
    </div>
  );
}

FormOptionBox.propTypes = {
  id: PropTypes.string,
  info: PropTypes.object,
  name: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  modifiers: PropTypes.array,
  utilities: PropTypes.array,
  withFootnote: PropTypes.bool,
  disabled: PropTypes.bool,
  data: PropTypes.object,
};

FormOptionBox.defaultProps = {
  label: '',
  checked: false,
  modifiers: [],
  utilities: [],
  withFootnote: true,
};

export default FormOptionBox;
