import PageModule from './PageModule';
import component from '../../containers/birthdayValidation/EmailValidationForm';

/**
 * Validates the birthday to check if the customer is the right owner of the sent mail.
 */
class EmailValidationModule extends PageModule {
  constructor(moduleData) {
    super(component, moduleData);
  }
}

export default EmailValidationModule;
