/* global sessionStorage, localStorage */
import { oneLine } from 'common-tags/lib/oneLine';
import { set as setCookie, get as getCookie, remove as removeCookie } from '../services/cookie';

export const STORAGE_TYPE_SESSION_STORAGE = 'sessionStorage';
export const STORAGE_TYPE_LOCAL_STORAGE = 'localStorage';
export const STORAGE_TYPE_COOKIE = 'cookie';


/**
 * Stores values by key in different storages without the risk of throwing errors
 * in certain situations.
 * Some browsers, e.g. safari on iOS, will throw an error when storages are requested in
 * incognito mode.
 */
export const setItem = (type, key, value, options = {}) => {
  if (!process.browser) {
    return null;
  }

  // add expiration param to localy stored objects if expires option exists
  if (options.expires
    && typeof value === 'object'
    && type === STORAGE_TYPE_LOCAL_STORAGE) {
    // eslint-disable-next-line no-param-reassign
    value.expires = options.expires;
  }

  const cookieString = JSON.stringify(value);

  try {
    switch (type) {
      case STORAGE_TYPE_SESSION_STORAGE:
        return sessionStorage.setItem(key, cookieString);

      case STORAGE_TYPE_LOCAL_STORAGE:
        return localStorage.setItem(key, cookieString);

      case STORAGE_TYPE_COOKIE:
        return setCookie(key, cookieString, options);

      default:
        throw new TypeError(`Cannot store information. No strategy given for type ${type}`);
    }
  } catch (e) {
    console.warn(e);
  }
  return null;
};

/**
 * Removes values by key in different storages without the risk of throwing errors
 * in certain situations.
 * Some browsers, e.g. safari on iOS, will throw an error when storages are requested in
 * incognito mode.
 */
export const removeItem = (type, key) => {
  if (!process.browser) {
    return;
  }
  try {
    switch (type) {
      case STORAGE_TYPE_SESSION_STORAGE:
        sessionStorage.removeItem(key);
        return;

      case STORAGE_TYPE_LOCAL_STORAGE:
        localStorage.removeItem(key);
        return;

      case STORAGE_TYPE_COOKIE:
        removeCookie(key);
        return;

      default:
        throw new TypeError(`Cannot store information. No strategy given for type ${type}`);
    }
  } catch (e) {
    console.warn(e);
  }
};

/**
 * Returns values by key from different storages without the risk of throwing errors
 * in certain situations.
 * Some browsers, e.g. safari on iOS, will throw an error when storages are requested in
 * incognito mode.
 */
export const getItem = (type, key) => {
  if (!process.browser) {
    return null;
  }
  let value = null;
  try {
    switch (type) {
      case STORAGE_TYPE_SESSION_STORAGE:
        value = sessionStorage.getItem(key);
        break;
      case STORAGE_TYPE_LOCAL_STORAGE: {
        value = localStorage.getItem(key);
        const parsed = JSON.parse(decodeURIComponent(value));
        const isExpired = typeof parsed === 'object' && parsed.expires
          && new Date().getTime() > new Date(parsed.expires).getTime();
        if (isExpired) {
          localStorage.removeItem(key);
          value = null;
        }
        break;
      }
      case STORAGE_TYPE_COOKIE: {
        value = getCookie(key);
        break;
      }
      default:
        throw new TypeError(oneLine`
          Cannot retrieve stored information. No strategy given for type ${type}
        `);
    }
  } catch (e) {
    console.warn(e);
  }
  if (!value) {
    return null;
  }

  return JSON.parse(decodeURIComponent(value));
};
