import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import suitcss from '../../../../helpers/suitcss';
import connectUI from '../../../basics/ui/UIConnector';
import FieldRadioGroup from '../../../basics/form/FieldRadioGroup';
import FormHeader from '../../form/FormHeader';
import FormSection from '../../form/FormSection';

const componentName = 'PasswordResetFormOptionsSelect';
function PasswordResetFormOptionsSelect(props) {
  const { fieldMap, formValues, ui } = props;
  const copyText =
    ui.myPasswordLostChoiceCopy
      .replace('{MSISDN}', formValues[fieldMap.msisdn.name])
      .replace('{EMAIL}', formValues[fieldMap.emailShort.name]);

  return (
    <div className={suitcss({ componentName })}>
      <FormSection asRow>
        <FormHeader headline={ui.myPasswordLostChoiceHeadline} copy={copyText} />
        <Field {...fieldMap.selectedOption} component={FieldRadioGroup} asStack withToolTip />
      </FormSection>
    </div>
  );
}

PasswordResetFormOptionsSelect.propTypes = {
  // form logic
  formValues: PropTypes.object.isRequired,
  fieldMap: PropTypes.shape({
  }),
  ui: PropTypes.shape({
    myPasswordLostChoiceCopy: PropTypes.string.isRequired,
    myPasswordLostChoiceSubmit: PropTypes.string.isRequired,
    myPasswordLostChoiceSms: PropTypes.string.isRequired,
    myPasswordLostChoiceEmail: PropTypes.string.isRequired,
    myPasswordLostChoiceHeadline: PropTypes.string.isRequired,

  }),
};

export default connectUI()(PasswordResetFormOptionsSelect);
