import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import raf from 'raf';

class GlobalProgressbar extends PureComponent {

  constructor(...args) {
    super(...args);
    this.state = { progress: this.getProgressByProps() };
    // time in milliseconds for one frame of a generic monitor
    this.tickingInterval = (1 / 60) * 1000;
    this.registerProgressTick = this.registerProgressTick.bind(this);
  }

  componentDidMount() {
    this.registerProgressTick();
  }

  componentWillReceiveProps(nextProps) {
    const maxProgress = Math.max(this.getProgressByProps(nextProps), this.state.progress);
    this.setState({
      // to ensure the progress will not decrease we will always use the max value when
      // the page is in loading state
      progress: this.props.isLoading ? maxProgress : 0,
      isPrevLoading: this.props.isLoading,
    });
  }

  componentWillUnmount() {
    raf.cancel(this.tick);
  }

  getProgressByProps(props = this.props) {
    return props.progressMax === 0 ? 1 : props.progressValue / props.progressMax;
  }

  /**
   * animate width while loading and opacity afterwards
   */
  getTransition() {
    const { isLoading } = this.props;
    const { isPrevLoading } = this.state;
    if (!isLoading) {
      return 'opacity 1.5s 500ms';
    }

    // in certain conditions the the progress value 0 is skipped and thus an
    // animation from progress 1 to ~0 is performed. To fix this we check for the
    // current and the previous state
    if (isLoading && isPrevLoading) {
      return 'transform 500ms ease-out';
    }

    return 'none';
  }

  /**
   * calls the tickProgressUpdate function in an request animation frame and
   * executes itself again as the callback so it will never resolve
   */
  registerProgressTick() {
    this.tick = raf(() => this.tickProgressUpdate(this.registerProgressTick));
  }

  tickProgressUpdate(cb) {
    if (!this.props.isLoading) {
      cb();
      return;
    }
    // @todo decrease progress addition exponentially to never reach 100%
    // we assume a max duration of 30 seconds per request
    const autoProgressAddition = this.tickingInterval / (30000 * this.props.progressMax);
    this.setState({ progress: this.state.progress + autoProgressAddition });
    cb();
  }

  // @todo we may move some styles into the css
  render() {
    const { isLoading } = this.props;
    const { progress } = this.state;
    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          background: '#000000',
          zIndex: 9999,
          width: '100%',
          height: '3px',
          opacity: !isLoading ? 0 : 1,
          pointerEvents: 'none',
        }}
      >
        <div
          style={{
            background: '#fa5a14',
            width: '100%',
            height: 'inherit',
            transform: `scaleX(${progress}) translateZ(0)`,
            willChange: isLoading,
            transformOrigin: 'left top',
            transition: this.getTransition(),
          }}
        />
      </div>
    );
  }
}

GlobalProgressbar.propTypes = {
  progressMax: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  progressValue: state.lifecycle.progressValue,
  progressMax: state.lifecycle.progressMax,
  isLoading: state.lifecycle.isLoading,
});

export default connect(mapStateToProps)(GlobalProgressbar);
