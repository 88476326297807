import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import suitcss from '../../../helpers/suitcss';
import Headline from '../../basics/text/TextHeadline';
import Accordion from '../../basics/accordion/Accordion';
import AccordionSection from '../../basics/accordion/AccordionSection';
import Copy from '../../basics/text/TextCopy';
import connectUI from '../../basics/ui/UIConnector';
import matchMediaConnector from '../../../containers/service/ServiceMatchMedia';

class CheckoutCartFaqFooter extends PureComponent {

  constructor(props, context) {
    super(props, context);
    this.state = { faqsToggled: false };
    this.onToggleFaqs = this.onToggleFaqs.bind(this);
  }

  onToggleFaqs(ev) {
    ev.preventDefault();
    this.setState({ faqsToggled: !this.state.faqsToggled });
  }

  render() {
    const {
      isMediaS,
      ui,
      faqs,
      stepId,
    } = this.props;
    const { faqsToggled } = this.state;
    const stepFaqs = stepId ? faqs && faqs.filter(faq => faq.id.endsWith(stepId)) : faqs;
    return (
      <div className={suitcss({ descendantName: 'faqs' }, this)} >
        {isMediaS &&
          <button onClick={this.onToggleFaqs} >
            <Headline size="xxs" utilities={['colorPrimary']} embedded raw >
              {ui.cfoFaqHeadline}
            </Headline>
          </button>
          }
        {!isMediaS &&
          <Headline size="xxs" utilities={['colorPrimary']} embedded raw >
            {ui.cfoFaqHeadline}
          </Headline>
          }
        {(!isMediaS || faqsToggled) &&
          <Accordion>
            {stepFaqs.map(faq => faq.entries.map((entry, index) => (
              <AccordionSection
                label={entry.question}
                utilities={[isMediaS ? 'textSizeSecondary' : 'textSizeSmall']}
                toggleContent
                embedded
                key={index}
              >
                <Copy size="small" embedded raw>{entry.answer}</Copy>
              </AccordionSection>
            )))}
          </Accordion>
          }
      </div>
    );
  }
}

CheckoutCartFaqFooter.propTypes = {
  stepId: PropTypes.string,
  faqs: PropTypes.array,
  isMediaS: PropTypes.bool,
  ui: PropTypes.object.isRequired,
};

CheckoutCartFaqFooter.defaultProps = {
  faqs: [],
};

export default compose(
  connectUI(),
  matchMediaConnector(['isMediaS']),
)(CheckoutCartFaqFooter);
