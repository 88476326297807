import AbstractError from './AbstractError';
import { SKIP_ERROR } from '../../config/errorCodes';

/**
 * Thrown if an operation inside a loop doesn't want the currently
 * processed element to be included in the result set.
 */
export default class SkipException extends AbstractError {
  constructor() {
    super(SKIP_ERROR);
  }
}
