const getPriceAfterDiscount = (price, discount) => price - (price * discount);

const addMissingZeros = (value) => value.toString().padStart(3, '0');

const getDealVector = (price, discountLow, discountHigh, deal, promotion, dealLow) => ({
  offerInterval: [
    Number(getPriceAfterDiscount(price, discountLow).toFixed(2)),
    Number(getPriceAfterDiscount(price, discountHigh).toFixed(2)),
  ],
  deal: Number(getPriceAfterDiscount(price, dealLow ? discountLow : discountHigh).toFixed(2)),
  code: promotion.replace('{DISCOUNT}',
    addMissingZeros((dealLow ? discountLow : discountHigh) * 1000)),
});

/**
 * This transforms the new flexible deal vector chatbot model into the old static one.
 * It is mainly used so we don't need to rewrite most of the chatbot logic. The transformation
 * works with the knowledge of the chosen group, the deal vectors, the price and the code.
 * @param dealVectors
 * @param chosenGroup
 * @param unit The price
 * @param code
 * @return {[null,null,null,null]}
 */
export const transformDealVectors = (dealVectors, chosenGroup, { unit }, code) => {
  const price = unit / 100;
  const group = dealVectors[chosenGroup];
  group.deals.sort((a, b) => b - a);
  const transformedDealVector = [
    getDealVector(price, group.min, group.deals[0], group.deals[0], code),
    getDealVector(price, group.deals[0], group.deals[1], group.deals[1], code),
    getDealVector(price, group.deals[1], group.deals[2], group.deals[1], code),
    getDealVector(price, group.deals[2], 0, group.deals[2], code, true),
  ];
  return transformedDealVector;
};
