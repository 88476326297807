import React from 'react';
import SmartBanner from 'react-smartbanner';

const MySmartBanner = () => (
  <SmartBanner
    daysHidden={15}
    daysReminder={90}
    appStoreLanguage={'de'}
    title={'Mein otelo'}
    author={'Vodafone GmbH'}
    button={'Anzeigen'}
    store={{ ios: 'Im App Store', android: 'In Google Play' }}
    price={{ ios: 'Kostenlos', android: 'Kostenlos' }}
    position={'bottom'}
  />
);

export default MySmartBanner;
