/* global document */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import suitcss from '../../../helpers/suitcss';
import Copy from '../../basics/text/TextCopy';
import Headline from '../../basics/text/TextHeadline';
import TextRaw from '../../basics/text/TextRaw';
import ContentLoader from '../content/ContentLoader';
import FriendReferralStatusCard from './FriendReferralStatusCard';

const componentName = 'FriendReferralStatusContainer';
class FriendReferralStatusContainer extends PureComponent {
  componentDidMount() {
    const { onBottomLinkClick } = this.props;
    const bottomTextLink = document.querySelector('.FriendReferralStatusContainer-bottomTextLink');
    if (bottomTextLink && onBottomLinkClick) {
      bottomTextLink.addEventListener('click', () => onBottomLinkClick());
    }
  }

  render() {
    const {
      utilities,
      subline,
      title,
      bottomText,
      bottomTextPlaceholder,
      referralStatusItems,
      className,
    } = this.props;
    const bottomTextLink = `<a class="FriendReferralStatusContainer-bottomTextLink">${bottomTextPlaceholder}</a>`;
    const replacedBottomText = bottomText && bottomText.replace('{HISTORY_LINK}', bottomTextLink);
    return (
      <div
        className={suitcss({
          className,
          componentName,
          utilities: [utilities],
        })}
      >
        <div className={suitcss({ componentName, descendantName: 'inner' })}>
          {title && (
            <Headline size="m" utilities={['weightBold', 'alignCenter']} embedded>
              {title}
            </Headline>
          )}
          {subline && (
            <Copy size="secondary" utilities={['alignCenter']}>
              {subline}
            </Copy>
          )}
          <ContentLoader
            className={suitcss({ componentName, descendantName: 'cards' })}
            isLoaded={!!referralStatusItems}
          >
            {referralStatusItems && referralStatusItems.map((referralStatusItem, index) => (
              <div
                className={suitcss({ componentName, descendantName: 'card' })}
                key={index}
              >
                <FriendReferralStatusCard statusItem={referralStatusItem} />
              </div>
            ))}
          </ContentLoader>
          {replacedBottomText && (
            <TextRaw className={suitcss({
              componentName,
              descendantName: 'bottomText',
              utilities: [
                'alignCenter',
                'marginTopS',
                'textSizeSecondary',
                'block',
              ],
            })}
            >
              {replacedBottomText}
            </TextRaw>
          )}
        </div>
      </div>
    );
  }
}

FriendReferralStatusContainer.propTypes = {
  utilities: PropTypes.array,
  referralStatusItems: PropTypes.array,
  title: PropTypes.string,
  subline: PropTypes.string,
  bottomText: PropTypes.string,
  bottomTextPlaceholder: PropTypes.string,
  className: PropTypes.string,
  onBottomLinkClick: PropTypes.func,
  theme: PropTypes.string,
};

export default FriendReferralStatusContainer;
