import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import suitcss from '../../../helpers/suitcss';
import { savePdf } from '../../../helpers/misc';
import Copy from '../../../components/basics/text/TextCopy';
import Headline from '../../../components/basics/text/TextHeadline';
import Link from '../../../components/basics/text/TextLink';
import MediaIFrame from '../../../components/basics/media/MediaIFrame';
import SvgLoader from '../../../components/basics/media/MediaSvgLoader';
import { bindQueryParams, getUrlPath } from '../../../helpers/url';
import {
  QUERY_INBOXVIEW,
  MYTRACK_MYINBOX_VIEW_WEBVIEW,
  MYTRACK_MYINBOX_VIEW_BILL,
  MYTRACK_MYINBOX_VIEW_EVN,
  MARKET_POSTPAID,
} from '../../../helpers/constants';

import GlobalSection from '../../../components/basics/global/GlobalSection';

import ContentLoader from '../../../components/compositions/content/ContentLoader';

import { documentRead, openDocument } from '../../../actions/user/inbox';
import { send } from '../../../actions/request/send';
import { trackMyState } from '../../../actions/tracking/event';

import DocumentsRequest from '../../../model/requests/DocumentsRequest';
import DocumentContentRequest from '../../../model/requests/DocumentContentRequest';

const componentName = 'MyInbox';

class MyInbox extends Component {

  constructor(props, context) {
    super(props, context);
    this.setDocumentLoading = this.setDocumentLoading.bind(this);
    this.mounted = false;
    this.state = {
      isLoading: false,
    };
  }

  componentDidMount() {
    const { dispatch, msisdn } = this.props;
    this.mounted = true;

    if (msisdn) {
      dispatch(send(new DocumentsRequest(msisdn)));
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  setCurrentDocument(doc) {
    this.props.dispatch(openDocument(doc));
    this.setDocumentLoading(true);
  }

  setDocumentLoading(isLoading) {
    if (this.mounted) {
      this.setState({
        isLoading,
      });
    }
  }

  loadAndSavePdf(doc) {
    const { dispatch, msisdn } = this.props;

    const loadDocument = () =>
      dispatch(send(new DocumentContentRequest(msisdn, doc.id)));

    loadDocument().then((d) => {
      dispatch(documentRead(doc.id));
      const binaryData = d.body.data.content;
      // create a nice name for the pdf
      const fileName = decodeURI(doc.title).replace(/\s+/g, '-');
      savePdf(binaryData, `${fileName}.pdf`, true);
    }, (err) => {
      console.log('pdf rejected', err);
    });
  }

  renderItem(doc) {
    const { dispatch, ui } = this.props;
    const onClick = () => {
      switch (doc.type) {
        case 'webview':
          dispatch(documentRead(doc.id));
          dispatch(trackMyState(MYTRACK_MYINBOX_VIEW_WEBVIEW));
          this.setCurrentDocument(doc);
          break;
        case 'evn':
          dispatch(trackMyState(MYTRACK_MYINBOX_VIEW_EVN));
          this.loadAndSavePdf(doc);
          break;
        case 'bill':
          dispatch(trackMyState(MYTRACK_MYINBOX_VIEW_BILL));
          this.loadAndSavePdf(doc);
          break;
        default:
          this.loadAndSavePdf(doc);
          break;
      }
    };
    const isRead = doc.alreadyAccessed;
    const isPdf = doc.type !== 'webview';

    const icon = isPdf ? [
      <div key="1" className={suitcss({ componentName, descendantName: 'icon' })} >
        <SvgLoader path={'/icons/content-download.svg'} />
      </div>,
      <div key="2" className={suitcss({ componentName, descendantName: 'meta' })}>
        <Copy embedded utilities={['colorGray100', 'textSizeSmall']}>{ui.myInboxMessagePdfDownload}</Copy>
      </div>,
    ] : <Copy key="1" embedded utilities={['colorGray100', 'textSizeSmall']}>&gt;</Copy>;

    return (
      <button
        className={suitcss({ componentName, descendantName: 'item' })}
        onClick={() => onClick()}
        key={doc.id}
      >
        <div className={suitcss({ componentName, descendantName: 'content', modifiers: [isRead && 'read'] })}>
          <Copy embedded utilities={[...(!isRead ? ['weightBold'] : [])]}>{doc.title}</Copy>
        </div>
        <div className={suitcss({ componentName, descendantName: 'subcontent' })} >
          {icon}
        </div>
      </button>
    );
  }

  renderDocument(doc, isLoading) {
    // getUrlPath strips the domain/host from the url to allow testing
    // on localhost/dev/int without cross origin issues.
    // the param QUERY_INBOXVIEW leads to a plain content view without navigation bar and footer.
    const uri = bindQueryParams(getUrlPath(doc.uri), { [QUERY_INBOXVIEW]: 'true' });
    // we cannot load the content without displaying the iframe which is
    // required to have a loaded state.
    // In this case we have to use the content loader with fake content here.
    // The isLoading modifier is used to hide the iframe via css.
    return (
      <div className={suitcss({ componentName, descendantName: 'detail', modifiers: [isLoading && 'isLoading'] })}>
        {isLoading &&
          <ContentLoader isLoaded={!isLoading} height={100}>{null}</ContentLoader>
        }
        <div className={suitcss({ componentName, descendantName: 'iframeWrapper' })}>
          <MediaIFrame
            autoHeight
            scrolling="no"
            onLoad={() => this.setDocumentLoading(false)}
            frameBorder="0"
            width="100%"
            height="100%"
            src={uri}
          />
        </div>
      </div>
    );
  }

  renderList() {
    const { documents, ui } = this.props;

    return (
      <ContentLoader height={100} isLoaded={documents !== null}>
        { documents && documents.length > 0 && <div className={suitcss({ componentName, descendantName: 'itemList' })}>
          {documents.map((d) => this.renderItem(d))}
        </div>}
        { documents && documents.length === 0 && <div className={suitcss({ componentName, descendantName: 'itemListEmpty' })}>
          <Copy utilities={['colorGray50']}>{ui.myInboxEmpty || 'ui.myInboxEmpty'}</Copy>
        </div>}
      </ContentLoader>
    );
  }

  renderBackLink() {
    const { ui } = this.props;
    return (
      <Link
        element="button"
        asButton
        buttonFilled
        utilities={['arrowLeft']}
        onClick={() => this.setCurrentDocument(null)}
      >
        {ui.myInboxMessageBacktoinbox}
      </Link>
    );
  }

  render() {
    const { msisdn, ui, sitemap, currentDocument, isPuc } = this.props;
    const { isLoading } = this.state;
    if (!msisdn) return null;

    const link = currentDocument
      ? this.renderBackLink()
      : <Link to={sitemap.MyDashboardRoute.url} buttonFilled asButton utilities={['arrowLeft']}>{ui.myInboxBacktodashboard}</Link>;

    return (
      <div className={suitcss({ modifiers: [currentDocument && 'document'] }, this)}>
        <GlobalSection layout="contained">
          <div className={suitcss({ componentName, descendantName: 'contentWrapper' })}>
            <div className={suitcss({ componentName, descendantName: 'header' })}>
              <Headline
                size="xl"
                element={'h1'}
                utilities={['alignCenter']}
              >
                {ui.myInboxHeadline}
              </Headline>
              <Copy embedded>{ui.myInboxDocumentCopy}</Copy>
              {isPuc && <Copy embedded>{ui.myInboxCallRecordsCopy}</Copy>}
              {link}
            </div>
          </div>
        </GlobalSection>
        <GlobalSection>
          <div className={suitcss({ componentName, descendantName: 'contentWrapper' })}>
            {currentDocument
              ? this.renderDocument(currentDocument, isLoading)
              : this.renderList()
            }
          </div>
        </GlobalSection>
      </div>
    );
  }
}

MyInbox.propTypes = {
  documents: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    uri: PropTypes.string,
    alreadyAccessed: PropTypes.bool.isRequired,
  })),
  msisdn: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  ui: PropTypes.shape({
    myInboxHeadline: PropTypes.string.isRequired,
    myInboxBacktodashboard: PropTypes.string.isRequired,
    myInboxMessagePdfDownload: PropTypes.string.isRequired,
    myInboxMessageBacktoinbox: PropTypes.string.isRequired,
    myInboxEmpty: PropTypes.string.isRequired,
    myInboxDocumentCopy: PropTypes.string,
    myInboxCallRecordsCopy: PropTypes.string,
  }),
  sitemap: PropTypes.object.isRequired,
  currentDocument: PropTypes.object,
  isPuc: PropTypes.bool,
};

const mapStateToProps = ({ user, site, ui }) => ({
  msisdn: user.credentials.msisdn,
  documents: user.inbox.documents,
  currentDocument: user.inbox.openDocument,
  sitemap: site.sitemap,
  isPuc: user.market === MARKET_POSTPAID,
  ui,
});

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(MyInbox);
