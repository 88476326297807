import { UI_COLLECTION_ESIM_STATUS } from '../../helpers/constants';
import PageModule from './PageModule';
import component from '../../containers/account/eSim/MyESimStatus';

/**
 * The MyESimStatus module enables to update the esim activation status
 * @see https://confluence.db-n.com/x/HPTc
 */
class MyESimStatusModule extends PageModule {
  constructor(moduleData) {
    super(component, moduleData);
  }

  getRequiredUi() {
    return [UI_COLLECTION_ESIM_STATUS];
  }
}

export default MyESimStatusModule;
