import oneLine from 'common-tags/lib/oneLine';
import createMatchMediaConnect from './connectMatchMedia';

export const breakpointSmallToMedium = 768;
export const breakpointMediumToLarge = 1024;

export const MEDIA_S = `(max-width: ${breakpointSmallToMedium - 1}px)`;
export const MEDIA_SM = `(max-width: ${breakpointMediumToLarge - 1}px)`;
export const MEDIA_M = oneLine`
  (min-width: ${breakpointSmallToMedium}px) and
  (max-width: ${breakpointMediumToLarge - 1}px)
`;
// export const MEDIA_ML = `(min-width: ${breakpointSmallToMedium}px)`;
// export const MEDIA_L = `(min-width: ${breakpointMediumToLarge}px)`;

const connect = createMatchMediaConnect({
  breakPointSM: 768,
  breakPointML: 1024,
});

export const deviceMapping = {
  mobile: 480,
  tablet: 768,
  desktop: 1024,
};

export default connect;
