import { registerPromoCodes } from '../user/marketing';
import { fetchEntityById } from '../request/registry';
import { ACTION_PREFIX } from '../../helpers/constants';
import { trackRemoveItem } from '../tracking/event';

export const REPLACE_CART = `${ACTION_PREFIX}/REPLACE_CART`;
export const REMOVE_CART_ITEM = `${ACTION_PREFIX}/REMOVE_CART_ITEM`;
export const CHANGE_TARIFF_OPTIONS = `${ACTION_PREFIX}/CHANGE_TARIFF_OPTIONS`;
export const CLEAR_CART = `${ACTION_PREFIX}/CLEAR_CART`;
export const HIDE_MNP = `${ACTION_PREFIX}/HIDE_MNP`;
export const UPDATE_CART_SIM_TYPE = `${ACTION_PREFIX}/UPDATE_CART_SIM_TYPE`;
export const CLEAR_CART_SIM_TYPE = `${ACTION_PREFIX}/CLEAR_CART_SIM_TYPE`;

export function replaceCart(entities) {
  return {
    type: REPLACE_CART,
    payload: entities,
  };
}

export function changeOptions(options) {
  return {
    type: CHANGE_TARIFF_OPTIONS,
    payload: options,
  };
}

export function updateCartSimType(cartSimType) {
  return {
    type: UPDATE_CART_SIM_TYPE,
    payload: {
      cartSimType,
    },
  };
}

export const clearCartSimType = () => ({
  type: CLEAR_CART_SIM_TYPE,
  payload: null,
});

export const removeItem = (item) => (dispatch) => {
  dispatch(trackRemoveItem(item));
  dispatch({
    type: REMOVE_CART_ITEM,
    payload: item,
  });
};

export function clearCart() {
  return {
    type: CLEAR_CART,
  };
}

export const fillCart = (promoCode, tariff, hardware = null, tariffOption) => async (dispatch) => {
  if (promoCode) {
    dispatch(registerPromoCodes([promoCode], true));
  }
  const entities = await Promise.all([
    tariff && dispatch(fetchEntityById(tariff)),
    hardware && dispatch(fetchEntityById(hardware)),
    tariffOption && dispatch(fetchEntityById(tariffOption)),
  ].filter(Boolean));
  dispatch(replaceCart(entities));
};

export function hideMnp(bool) {
  return {
    type: HIDE_MNP,
    payload: { hideMnp: bool },
  };
}
