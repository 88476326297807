import { ACTION_PREFIX } from '../../helpers/constants';

export const SWITCH_MODULE = `${ACTION_PREFIX}/SWITCH_MODULE`;
export const HYDRATE_PAGES = `${ACTION_PREFIX}/HYDRATE_PAGES`;

export const switchModule = (newModuleInstance, oldModuleId, page) => ({
  type: SWITCH_MODULE,
  payload: {
    newModuleInstance,
    oldModuleId,
    page,
  },
});

export const hydratePages = (pages) => ({
  type: HYDRATE_PAGES,
  payload: {
    pages,
  },
});
