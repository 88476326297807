import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux';
import { destroy } from 'redux-form';

import { showFormError } from '../../../actions/formActions';
import menuActions from '../../../actions/page/menu';
import { receivedCredentials } from '../../../actions/user/login';
import LoginRequest from '../../../model/requests/LoginRequest';
import suitcss from '../../../helpers/suitcss';
import {
  MENU_IDENTIFIER_ACCOUNT,
} from '../../../helpers/constants';
import FormManager from '../../form/FormManager';
import TextCopy from '../../../components/basics/text/TextCopy';
import TextHeadline from '../../../components/basics/text/TextHeadline';
import TabWrapper from '../../../components/basics/tab/TabWrapper';
import Tab from '../../../components/basics/tab/Tab';
import GlobalSection from '../../../components/basics/global/GlobalSection';
import * as passwordConfig from './formConfigPassword';
import * as smsConfig from './formConfigSms';
import { gotoPasswordResetAndAutoSubmitWithMsisdn } from '../../../actions/user/passwordReset';
import * as registryActions from '../../../actions/request/registry';
import QueueableRequest from '../../../model/requests/QueueableRequest';

class LoginForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      passwordHidden: true,
    };
    this.togglePasswordVisibility = this.togglePasswordVisibility.bind(this);
  }

  getSubmitRoute(fieldMap, finalizedValues) {
    const { msisdn, password } = finalizedValues;
    return new LoginRequest(msisdn, password);
  }

  togglePasswordVisibility() {
    this.setState({ passwordHidden: !this.state.passwordHidden });
  }

  renderPassword() {
    const {
      ui,
      onReceiveCredentials,
      dispatch,
      asWidget,
      sitemap,
      nextLocation,
    } = this.props;

    const { passwordHidden } = this.state;

    const onAfterSubmitSuccess = ({ response, formValues, fieldMap }) => {
      const isPersist = !!formValues[fieldMap.isPersist];
      onReceiveCredentials(response, isPersist);
      dispatch(menuActions.closeMenu(MENU_IDENTIFIER_ACCOUNT));
      // destrrrroy the form manually as we prevent destroy on unmount
      dispatch(destroy(LoginForm.formNamePassword));
      dispatch(registryActions.fetchGdprConsentsInquiryData({
        context: {
          type: 'login',
        },
      }), { priority: QueueableRequest.PRIO_100 });
    };

    const stepProps = {
      urlPasswordReset: sitemap.MyPasswordResetFormRoute.url,
      passwordResetCopy: ui.myLoginLostPassword,
      withoutBorder: true,
      togglePasswordVisibility: this.togglePasswordVisibility,
      passwordHidden,
    };
    const onSubmitWillFail = async (error) => {
      await dispatch(push(sitemap.MyLoginFormRoute.url));
      setTimeout(
        () => { dispatch(showFormError(LoginForm.formNamePassword, null, error, asWidget)); },
        500, // short delay is necessary since push does not block until page is rendered!
      );
    };

    // the success route is the pathname the user entered before being redirected
    // to the login; otherwise the dashboard is used as default target page.
    const successRoute = nextLocation
      ? `${nextLocation.pathname}${nextLocation.search}${nextLocation.hash}`
      : sitemap.MyDashboardRoute.url;

    return (
      <FormManager
        form={LoginForm.formNamePassword}
        stepProps={stepProps}
        onAfterSubmitSuccess={onAfterSubmitSuccess}
        onSubmitWillFail={asWidget ? onSubmitWillFail : undefined}
        submitRoute={this.getSubmitRoute}
        successRoute={successRoute}
        destroyOnUnmount={!asWidget}
        withBlockError={!asWidget}
        withFooter
        steps={[passwordConfig]}
      />
    );
  }

  renderSms() {
    const { goToPasswordWithSms, asWidget } = this.props;
    const onAfterSubmitSuccess = (finalizedValues) => {
      goToPasswordWithSms(finalizedValues.msisdn);
    };
    const stepProps = { withoutBorder: true };
    return (
      <FormManager
        form={LoginForm.formNameSms}
        stepProps={stepProps}
        onAfterSubmitSuccess={onAfterSubmitSuccess}
        destroyOnUnmount={!asWidget}
        withBlockError={!asWidget}
        withFooter
        steps={[smsConfig]}
      />
    );
  }

  renderElement() {
    const {
      ui,
      asWidget,
    } = this.props;

    return (
      <div className={suitcss({ modifiers: [asWidget && 'widget'] }, this)}>
        {!asWidget && (
          <TextHeadline size="l" embedded>{ui.myOteloHeadline}</TextHeadline>
        )}
        <TextCopy embedded>{ui.myLoginCopy}</TextCopy>
        <TabWrapper selected={0}>
          <Tab label={ui.myLoginChoicePassword}>
            {this.renderPassword()}
          </Tab>
          <Tab label={ui.myLoginChoiceSms}>
            {this.renderSms()}
          </Tab>
        </TabWrapper>
        {/**
         * At present my_login_register has no CMS value https://jira.db-n.com/browse/OP-2869
        */}
        {/* <div className={suitcss({ descendantName: 'linkList' }, this)}>
          <div className={suitcss({ descendantName: 'link' }, this)}>
            <Link
              data={{ mytracking: MYTRACK_LOGIN_CREATE_ACCOUNT }}
              utilities={['textSizeSmall']}
              asButton={false}
              withoutArrow
              element="button"
              onClick={() => dispatch(push(sitemap.UserRegistrationFormRoute.url))}
            >
              {ui.myLoginRegister}
            </Link>
          </div>
        </div> */}
      </div>
    );
  }

  render() {
    const { asWidget } = this.props;
    return asWidget ?
      this.renderElement() :
      (<GlobalSection theme="light" layout="contained">{this.renderElement()}</GlobalSection>);
  }
}

/**
 * required by tracking!
 */
LoginForm.formName = 'userLogin';
LoginForm.formNameSms = 'userLoginSms';
LoginForm.formNamePassword = 'userLoginPassword';

LoginForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  onReceiveCredentials: PropTypes.func.isRequired,
  goToPasswordWithSms: PropTypes.func.isRequired,
  asWidget: PropTypes.bool,
  ui: PropTypes.object.isRequired,
  sitemap: PropTypes.object.isRequired,
  nextLocation: PropTypes.object,
};

const mapStateToProps = (state) => {
  const routingState = state.routing.locationBeforeTransitions.state || {};
  return {
    ui: state.ui,
    sitemap: state.site.sitemap,
    nextLocation: routingState.nextLocation,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators({
    goToPasswordWithSms: gotoPasswordResetAndAutoSubmitWithMsisdn,
    onReceiveCredentials: receivedCredentials,
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  {
    areStatesEqual: (next, prev) => {
      const nextRoutingState = next.routing.locationBeforeTransitions.state || {};
      const prevRoutingState = prev.routing.locationBeforeTransitions.state || {};
      const isRoutingStateEqual = (nextRoutingState.nextLocation === prevRoutingState.nextLocation);
      const isUiStateEqual = JSON.stringify(next.ui) === JSON.stringify(prev.ui);
      const isSitemapStateEqual = JSON.stringify(next.site.sitemap)
        === JSON.stringify(prev.site.sitemap);
      return isRoutingStateEqual && isUiStateEqual && isSitemapStateEqual;
    },
  },
)(LoginForm);
