/* global document */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import suitcss from '../../helpers/suitcss';
import { getRealmByContext } from '../../helpers/context';
import { getBookablePromotions, getContext } from '../../helpers/promotions';
import { BRAND_ROSSMANN, CUSTOM_EVENT_RESIZE } from '../../helpers/constants';

import UIAlertBar from '../../components/basics/ui/UIAlertBar';

class GlobalNotificationBar extends PureComponent {

  constructor(props, context) {
    super(props, context);
    this.container = null;
    this.registerContainer = this.registerContainer.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.container && this.props.items.length !== prevProps.items.length) {
      const event = document.createEvent('Event');
      event.initEvent(CUSTOM_EVENT_RESIZE, true, true);
      this.container.dispatchEvent(event);
    }
  }

  registerContainer(target) {
    this.container = target;
  }

  render() {
    const { items } = this.props;
    return (
      <div className={suitcss({}, this)} ref={this.registerContainer}>
        {items.map(({ theme, content }, key) => (
          <UIAlertBar key={key} theme={theme} content={content} />
        ))}
      </div>
    );
  }
}

GlobalNotificationBar.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      theme: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
      content: PropTypes.string,
      priority: PropTypes.number,
      areas: PropTypes.array,
      urls: PropTypes.array,
    }),
  ),
};


const maxItems = 3;
const makeMapStateToProps = () => {

  const currentAreaSelector = (state) => getRealmByContext(getContext(state));
  const currentLocationSelector = (state) => state.site.routing.requestedLocation
    || state.routing.locationBeforeTransitions;
  const teaserBarsSelector = (state) => state.entities.teaserBar;
  const contractRenewalSelector = (state) => state.site.contractRenewal.isInProgress;
  const bookablePromotionEntitiesSelector = state => getBookablePromotions(state);
  const itemsSelector = createSelector(
    teaserBarsSelector,
    currentAreaSelector,
    currentLocationSelector,
    contractRenewalSelector,
    bookablePromotionEntitiesSelector,
    (teaserBars, currentArea, currentLocation, isContractRenewal, bookablePromotions) => {
      // Filter valid items and sort by priority
      const items = Object.values(teaserBars).filter(item => {
        const isAreaValid = (!item.areas.length && !item.urls.length)
          || item.areas.some(area => area === currentArea || (area === 'vvl' && isContractRenewal));
        const isLocationValid = item.urls.some(url => url === currentLocation.pathname);
        const hasPromo = !!item.promoIds.length;
        const isPromoValid = bookablePromotions
          .some(promo => item.promoIds.includes(promo.id));
        if (hasPromo) {
          return isPromoValid && (isAreaValid || isLocationValid);
        }
        return isAreaValid || isLocationValid;
      }).sort((obj1, obj2) => (obj2.priority - obj1.priority));
      // return max items sorted by theme...primary first
      return items.splice(0, maxItems).sort((obj1, obj2) => {
        if (obj1.theme === 'primary' && obj2.theme !== obj1.theme) {
          return -1;
        } else if (obj1.theme !== 'primary' && obj2.theme !== obj1.theme) {
          return 1;
        }
        return 0;
      });
    },
  );

  return (state) => ({
    items: state.site.brandName !== BRAND_ROSSMANN ? itemsSelector(state) : [],
  });
};


export default connect(makeMapStateToProps)(GlobalNotificationBar);
