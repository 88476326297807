export const MESSAGE_TYPE_EXIT = Symbol('MESSAGE_TYPE_EXIT');
export const MESSAGE_TYPE_DECLINE_OFFER = Symbol('MESSAGE_TYPE_DECLINE_OFFER');
export const MESSAGE_TYPE_ACCEPT_OFFER = Symbol('MESSAGE_TYPE_ACCEPT_OFFER');
export const MESSAGE_TYPE_OFFER = Symbol('MESSAGE_TYPE_OFFER');
export const MESSAGE_TYPE_DATA_VOLUME = Symbol('MESSAGE_TYPE_DATA_VOLUME');
export const MESSAGE_TYPE_ACCEPT_DATA_VOLUME = Symbol('MESSAGE_TYPE_ACCEPT_DATA_VOLUME');
export const MESSAGE_TYPE_DECLINE_DATA_VOLUME = Symbol('MESSAGE_TYPE_DECLINE_DATA_VOLUME');
export const MESSAGE_TYPE_INIT = Symbol('MESSAGE_TYPE_INIT');
export const MESSAGE_TYPE_WAKE_UP_LOOP = Symbol('MESSAGE_TYPE_WAKE_UP_LOOP');
export const MESSAGE_SENDER_USER = Symbol('MESSAGE_SENDER_USER');
export const MESSAGE_SENDER_SYSTEM = Symbol('MESSAGE_SENDER_SYSTEM');
