import QueueableRequest from './QueueableRequest';
import { FLEXMODE_TOKEN_ENDPOINT_INFO } from '../../config/api';
import { bindParamsToRoute } from '../../helpers/url';
import { REQUEST_METHOD_GET } from '../../helpers/constants';

export default class FlexmodeTokenInfoRequest extends QueueableRequest {
  constructor(market, token, options) {
    super(bindParamsToRoute(FLEXMODE_TOKEN_ENDPOINT_INFO, { market, token }), {
      ...options,
      method: REQUEST_METHOD_GET,
    });
  }
}
