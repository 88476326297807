import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { showDialog } from '../../../../actions/page/dialog';
import { VO_STATE_BOOKEDSINGLE } from '../../../../helpers/constants';
import { toPriceObject } from '../../../../helpers/money';
import { subscriptionItemShape } from '../../../../propTypes/account';
import suitcss from '../../../../helpers/suitcss';
import Copy from '../../../basics/text/TextCopy';
import Unit from '../../../basics/text/TextUnit';
import Link from '../../../basics/text/TextLink';
import {
  isTariffOptionVO,
  isHardwareVO,
  isPromotionVO,
  getPaymentFeeDuration,
  getBookingOverviewItemsExpirationDate,
} from '../../../../helpers/entity';
import StringSubscriptionState from '../../../basics/string/StringSubscriptionState';
import connectUI from '../../../basics/ui/UIConnector';

const componentName = 'DashboardSummaryItem';
const DashboardSummaryItem = (props) => {
  const getCopy = () => {
    const { item, ui, isBaseTariff } = props;
    const { type, state, stateDate } = item;
    const isHardware = isHardwareVO(item);

    if (isHardware && item.imei) {
      return item.imei && `${ui.myTariffHardwareImei} ${item.imei}`;
    }

    if (state && (state !== VO_STATE_BOOKEDSINGLE) && !isBaseTariff) {
      return (<StringSubscriptionState type={type} state={state} date={stateDate} element="span" />);
    }

    return null;
  };

  const renderTitle = () => {
    const {
      item, ui, type, isBaseTariff, dispatch,
    } = props;
    const {
      headline, basicFee, inclusiveVolume, inclusiveVolumeUnit, billingType, subline,
    } = item;
    const isHardware = isHardwareVO(item);
    const price = basicFee !== undefined && basicFee !== null && toPriceObject(basicFee);
    const pricePostfix = `/ ${getPaymentFeeDuration(item, ui, true)}`;
    const formattedVolume = inclusiveVolume && Number(inclusiveVolume.toFixed(2));
    const parsedVolume = formattedVolume && formattedVolume.toString().replace('.', ',');
    const volume = parsedVolume && inclusiveVolumeUnit ? `${parsedVolume} ${inclusiveVolumeUnit.toUpperCase()}` : null;
    const volumePostfix = billingType === 'monthly' ? `/ ${ui.guiWordMonthlyAbbr}` : `/ ${ui.guiWordSingularAbbr}`;
    const title = isBaseTariff && type === 'volumes'
      ? ui.myBoBaseVolume
      : isBaseTariff && type === 'costs'
        ? ui.myBoBasePrice
        : null;

    return (
      <div className={suitcss({ componentName, descendantName: 'title' })}>
        <Copy
          className={suitcss({ componentName, descendantName: 'titleText' })}
          size="secondary"
          embedded
          utilities={['textEllipsis']}
        >
          <span>
             {title || headline}
             {!isHardware && !isBaseTariff && subline && (<span> {subline}</span>)}
          </span>
          {item.textInfo &&
            <Link
              className={suitcss({ componentName, descendantName: 'titleInfoIcon' })}
              element="button"
              onClick={() => dispatch(showDialog({
                headline: item.headline,
                copy: item.textInfo,
                actions: [{ label: ui.guiWordAllright }],
              }))}
              withoutArrow
              withoutStyle
              icon="/icons/content-info.svg"
              size="small"
            />
          }
        </Copy>
        {volume && type === 'volumes' && (
          <Copy size="secondary" embedded>
            <span className={suitcss({ utilities: ['paddingRightXXS'] })}>{volume}</span>
            <span className={suitcss({ componentName, descendantName: 'volumePostfix' })}>
              {volumePostfix}
            </span>
          </Copy>
        )}
        {price && type !== 'volumes' && (
          <Unit price={price} postfix={pricePostfix} inline bold={false} />
        )}
      </div>
    );
  };

  const renderCopy = () => {
    const { item, ui } = props;
    const expirationDateCopy = getBookingOverviewItemsExpirationDate(
      item,
      ui.myBoValidToDateHint,
      ui.myBoValidToDateTimeHint,
      isPromotionVO(item) && ui.myBoEol,
    );
    const copy = getCopy();
    return (
      <div className={suitcss({ componentName, descendantName: 'copy' })}>
        {copy &&
          <Copy size="secondary" utilities={['colorGray100']} embedded>
            {copy}
          </Copy>
        }
        {expirationDateCopy &&
          <Copy size="secondary" utilities={['colorGray100']} embedded>
            {expirationDateCopy}
          </Copy>
        }
      </div>
    );
  };
  const renderFooter = () => {
    const { item, ui } = props;
    const isOption = isTariffOptionVO(item);

    if (!item.urlDetails) {
      return null;
    }

    return (
      <div className={suitcss({ componentName, descendantName: 'footer' })} >
        <div className={suitcss({ componentName, descendantName: 'links' })} >
          {item.urlDetails && isOption &&
            <Link size="secondary" to={item.urlDetails} utilities={['weightBold']}>{ui.myBoAdditionalItemsDetailsCta}</Link>
          }
        </div>
      </div>
    );
  };

  return (
    <div className={suitcss({ componentName })} >
      {renderTitle()}
      {renderCopy()}
      {renderFooter()}
    </div>
  );
};

DashboardSummaryItem.propTypes = {
  item: subscriptionItemShape.isRequired,
  type: PropTypes.string,
  isBaseTariff: PropTypes.bool,
  ui: PropTypes.shape({
    myBoBaseVolume: PropTypes.string,
    myBoBasePrice: PropTypes.string,
    myBoAdditionalItemsDetailsCta: PropTypes.string,
    myBoValidToDateHint: PropTypes.string,
    myBoValidToDateTimeHint: PropTypes.string,
    myBoEol: PropTypes.string,
    myTariffHardwareImei: PropTypes.string,
    guiWordMonthlyAbbr: PropTypes.string,
    guiWordSingularAbbr: PropTypes.string,
  }).isRequired,
  dispatch: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default compose(connectUI(), connect(null, mapDispatchToProps))(DashboardSummaryItem);
