import { RECEIVED_CREDENTIALS } from '../actions/user/login';
import { LOGOUT } from '../actions/user/logout';
import {
  MYTRACK_LOGOUT,
  MYTRACK_LOGIN_PUC_MSISDN,
  MYTRACK_LOGIN_PUA_MSISDN,
  MYTRACK_LOGIN_MMO_MSISDN,
  MARKET_MMO,
  MARKET_POSTPAID,
  MARKET_PREPAID,
} from '../helpers/constants';

import { trackMyState, LOG_ENTRY, TRACK_CLICK } from '../actions/tracking/event';

import { send } from '../actions/request/send';
import LoggingRequest from '../model/requests/LoggingRequest';

const getUser = payload =>
  (payload && payload.response && payload.response.body && payload.response.body.data) || {};

const trackingMiddleware = ({ dispatch, getState }) => next => (action) => {
  const { userType } = getUser(action.payload);

  switch (action.type) {
    case RECEIVED_CREDENTIALS:
      if (userType === MARKET_POSTPAID) {
        dispatch(trackMyState(MYTRACK_LOGIN_PUC_MSISDN));
      } else if (userType === MARKET_PREPAID) {
        dispatch(trackMyState(MYTRACK_LOGIN_PUA_MSISDN));
      } else if (userType === MARKET_MMO) {
        dispatch(trackMyState(MYTRACK_LOGIN_MMO_MSISDN));
      }
      break;
    case LOGOUT:
      dispatch(trackMyState(MYTRACK_LOGOUT));
      break;
    case LOG_ENTRY:
      if (process.browser) {
        const { payload: logEntry } = action;
        logEntry.getContext().setGlobalContext(getState());
        dispatch(send(new LoggingRequest(logEntry)));
      }
      break;
    case TRACK_CLICK:
      if ((
        /(^Missing selection|\d+ GB)/.test(action.payload?.link_title || '')
        || action.payload.moduleType === 'HardwareDetails'
      ) && action.payload?.hardwareId) {
        const { entities } = getState();
        const hardware = (Object.values(entities?.hardwareEntity || {}) || [])
          .find((el) => el.iid === action.payload.hardwareId);
        if (hardware) {
          const capacity = `${hardware.capacity + hardware.capacityUnit.toUpperCase()}`;
          return next({
            ...action,
            payload: {
              ...action.payload,
              link_title: `${hardware.manufacturer} ${hardware.name} ${capacity} ${hardware.color}`,
            },
          });
        }
      }
      break;
  }

  return next(action);
};

export default trackingMiddleware;
