import PageModule from './PageModule';
import component from '../../containers/account/MyTariffDetails';

/**
 * Displays tariff details within My otelo.
 * @see https://confluence.db-n.com/x/V4Hr
 */
class MyTariffDetailsModule extends PageModule {
  constructor(moduleData) {
    super(component, moduleData);
  }
}

export default MyTariffDetailsModule;

