import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { replace } from 'react-router-redux';

import ContentTabsComposition from '../../components/compositions/tabs/ContentTabs';
import GlobalSection from '../../components/basics/global/GlobalSection';
import { getModuleInstance } from '../../helpers/pageParser';
import matchMediaConnector from '../service/ServiceMatchMedia';
import { receiveEntityList } from '../../actions/page/entity';
import { flatArray } from '../../helpers/misc';
import { trackCurrentPage } from '../../actions/tracking/page';

class ContentTabs extends PureComponent {

  constructor(props, context) {
    super(props, context);
    this.state = {
      tabs: [],
    };
  }

  async componentDidMount() {
    const { params, dispatch } = this.props;
    const { tabs } = params;
    const entities = [];

    const instancesPromises = tabs.map(tab =>
      Promise.all(tab.modules.map(module => {
        entities.push(module.entities);
        return getModuleInstance(module.etype, module, { preserveEntities: true });
      })));
    const instances = await Promise.all(instancesPromises);
    const tabsComposition = tabs.map((tab, index) => ({
      modules: instances[index],
      label: tab.label,
      hash: tab.hash,
    }));
    dispatch(receiveEntityList(flatArray(entities)));
    this.setState({
      tabs: tabsComposition,
    });
  }

  onClickTabItem = (hash) => {
    const { dispatch, location } = this.props;
    dispatch(replace(`${location.pathname}${location.search}#${hash}`));
    dispatch(trackCurrentPage());
  };

  render() {
    const { params, location, isMediaSM } = this.props;
    const { colorScheme, layoutSettings, align, theme } = params;
    return (
      <GlobalSection
        theme={colorScheme === 'light' ? 'light' : ''}
        layoutSettings={layoutSettings}
        layout="expanded"
      >
        <ContentTabsComposition
          colorScheme={colorScheme === 'light' ? 'dark' : colorScheme}
          align={align}
          tabs={this.state.tabs}
          location={location}
          onClickTabItem={this.onClickTabItem}
          theme={theme}
          isMediaSM={isMediaSM}
        />
      </GlobalSection>
    );
  }
}

ContentTabs.propTypes = {
  location: PropTypes.object,
  params: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
};

export default compose(matchMediaConnector(['isMediaSM']))(ContentTabs);
