/* global window */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';
import connectUI from '../ui/UIConnector';
import UIOverlayWrapper from '../ui/UIOverlayWrapper';
import Copy from '../text/TextCopy';
import Link from '../text/TextLink';

const componentName = 'GlobalNotification';
class GlobalNotification extends PureComponent {

  constructor(props, context) {
    super(props, context);
    this.timeout = null;
  }

  componentDidMount() {
    const { onClose, autoClose } = this.props;
    if (!this.timeout && onClose && autoClose) {
      this.setAutoCloseTimeout();
    }
  }

  componentWillUnmount() {
    if (this.timeout) {
      window.clearTimeout(this.timeout);
    }
  }

  setAutoCloseTimeout() {
    const { onClose } = this.props;
    this.timeout = window.setTimeout(() => {
      onClose();
    }, 4000);
  }

  render() {
    const {
      theme,
      copy,
      onClose,
      autoClose,
      alignH,
      alignV,
      ui,
    } = this.props;
    const isCustom = theme === 'custom';
    return (
      <UIOverlayWrapper
        className={componentName}
        theme={theme}
        onClose={(!autoClose || isCustom) ? onClose : null}
        alignH={alignH}
        alignV={alignV}
        withoutPadding={isCustom}
      >
        {copy && <Copy embedded raw >{copy}</Copy>}
        {!autoClose && !isCustom && onClose && (
          <div className={suitcss({ componentName, descendantName: 'link' })}>
            <Link onClick={onClose} element="button" withoutArrow>
              {ui.guiWordAllright}
            </Link>
          </div>
        )}
      </UIOverlayWrapper>
    );
  }
}

GlobalNotification.propTypes = {
  theme: PropTypes.oneOf(['light', 'medium', 'dark', 'custom']),
  alignH: PropTypes.oneOf(['left', 'right', 'center']),
  alignV: PropTypes.oneOf(['top', 'bottom', 'middle']),
  copy: PropTypes.string,
  onClose: PropTypes.func,
  autoClose: PropTypes.bool,
  ui: PropTypes.shape({
    guiWordAllright: PropTypes.string,
  }),
};

GlobalNotification.defaultProps = {
  theme: 'light',
};

export default connectUI()(GlobalNotification);
