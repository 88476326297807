import PageModule from './PageModule';
import component from '../../containers/nps/NpsForm';
import { UI_COLLECTION_GDPR } from '../../helpers/constants';

class NpsFormModule extends PageModule {
  constructor(moduleData) {
    super(component, moduleData);
  }

  /**
   * @override
   */
  getRequiredUi() {
    return [UI_COLLECTION_GDPR];
  }
}

export default NpsFormModule;

