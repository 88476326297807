import { updateQuery } from '../page/location';
import {
  QUERY_SELECTED_HARDWARE,
  QUERY_FILTERED_HW_BRAND,
  QUERY_FILTERED_HW_CATEGORIES,
} from '../../helpers/constants';

export const selectItem = (location, { eid } = {}) => (
  updateQuery(location, {
    [QUERY_SELECTED_HARDWARE]: eid,
  })
);

export const deselectItem = location => (
  updateQuery(location, {
    [QUERY_SELECTED_HARDWARE]: null,
  })
);

export const filterList = (location, { brand, categories }) => (
  updateQuery(location, {
    [QUERY_FILTERED_HW_BRAND]: brand,
    [QUERY_FILTERED_HW_CATEGORIES]: categories,
  })
);
