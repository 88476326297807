import React from 'react';
import PropTypes from 'prop-types';
import RouterLink from 'react-router/lib/Link';
import suitcss from '../../../helpers/suitcss';

function ButtonToggleIcons(props) {
  if (!props.onClick) {
    return null;
  }
  return (
    <RouterLink
      onClick={props.onClick}
      className={suitcss({
        componentName: 'ButtonToggleIcons',
        states: [props.icon],
      })}
    >
      <span
        className={suitcss({
          componentName: 'ButtonToggleIcons',
          descendantName: 'line',
        })}
      />
      <span
        className={suitcss({
          componentName: 'ButtonToggleIcons',
          descendantName: 'line',
        })}
      />
      <span
        className={suitcss({
          componentName: 'ButtonToggleIcons',
          descendantName: 'line',
        })}
      />
    </RouterLink>
  );
}

ButtonToggleIcons.propTypes = {
  onClick: PropTypes.func,
  icon: PropTypes.oneOf(['burger', 'cross']),
};
ButtonToggleIcons.defaultProps = {
  icon: 'burger',
};

export default ButtonToggleIcons;
