import { connect } from 'react-redux';
import { compose } from 'redux';

import initForm from '../form/FormInitializer';
import {
  FORM_FIELD_GDPR_CONSENT_BEW,
  FORM_FIELD_GDPR_CONSENT_DAT,
  FORM_FIELD_ADCONSENT_INFO,
  FORM_FIELD_ADCONSENT_CONTACT,
} from '../../helpers/constants';
import { getConsentTextById } from '../../helpers/misc';
import AdConsentUpdateForm
  from '../../components/compositions/account/AdConsentUpdateForm';

const createFieldMap = ({ consentTexts, entity }) => ({
  adConsent: {
    name: FORM_FIELD_ADCONSENT_INFO,
    label: getConsentTextById(consentTexts, FORM_FIELD_GDPR_CONSENT_BEW),
    type: 'checkbox',
    validation: {
      isRequired: entity.adConsentReqBew,
      valueEquals: entity.adConsentReqBew || undefined,
    },
  },
  passData: {
    name: FORM_FIELD_ADCONSENT_CONTACT,
    label: getConsentTextById(consentTexts, FORM_FIELD_GDPR_CONSENT_DAT),
    type: 'checkbox',
    validation: {
      isRequired: entity.adConsentReqDat,
      valueEquals: entity.adConsentReqDat || undefined,
    },
  },
});

const mapStateToProps = (state, { fieldMap, adConsent }) => {
  const initialValues = {
    [fieldMap.adConsent.name]: adConsent.adConsent,
    [fieldMap.passData.name]: adConsent.passData,
  };
  return {
    initialValues,
  };
};

const component = compose(
  connect(mapStateToProps),
  initForm(),
)(AdConsentUpdateForm);

export const id = 'adConsentUpdate';

/**
 * @return {FormConfig}
 */
export const mapStateToFormConfig = (state, props) => ({
  component,
  fieldMap: createFieldMap(props),
});

export default mapStateToFormConfig;
