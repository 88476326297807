import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { formValueSelector, initialize } from 'redux-form';
import { push } from 'react-router-redux';
import * as topup from '../../form/steps/formConfigTopup';
import * as constants from '../../../helpers/constants';
import { hideDialog, showDialog, showNotification } from '../../../actions/page/dialog';
import {
  showTopupSuccess,
  showPaymentDataDialog,
} from '../../../actions/dialog/topupActions';
import AutoTopupRequest from '../../../model/requests/AutoTopupRequest';
import * as requests from '../../../actions/request/registry';
import GlobalSection from '../../../components/basics/global/GlobalSection';
import FormManager from '../../form/FormManager';
import { trackMyState } from '../../../actions/tracking/event';
import { getUserLoyaltySettings } from '../../../helpers/user';

/**
 * Form guides the user through the process of auto topup.
 */
class MyAutoTopupForm extends PureComponent {
  constructor(...args) {
    super(...args);
    this.getSubmitRoute = this.getSubmitRoute.bind(this);
    this.onAfterSubmitSuccess = this.onAfterSubmitSuccess.bind(this);
  }

  async componentDidMount() {
    const { topupOptions, dispatch } = this.props;

    const paymentData = await dispatch(requests.fetchPaymentData({ isBlocking: true }));

    if (!paymentData.type || paymentData.type === 'none') {
      dispatch(showPaymentDataDialog(constants.TOPUP_TYPE_AUTO));
      return;
    }

    dispatch(requests.fetchCustomerData());

    const topupStatus = await dispatch(requests.fetchTopupStatus({}, true));
    if (!topupOptions) {
      dispatch(initialize(MyAutoTopupForm.formName, {
        [constants.FORM_FIELD_TOPUP_OPTIONS]: topupStatus.topups,
      }));
    }
  }

  onAfterSubmitSuccess({ response, formValues, fieldMap }) {
    const { dispatch, ui } = this.props;
    const isAutoTopupDisabled = formValues[fieldMap.topupType.name] === topup.AUTO_TOPUP_TYPE_NONE;

    if (isAutoTopupDisabled) {
      dispatch(trackMyState(constants.MYTRACK_DASHBOARD_AUTO_TOPUP_ENABLE));
      dispatch(showNotification(ui.myAutotopupResponseDeactivated));
    } else {
      dispatch(showTopupSuccess(response, constants.TOPUP_TYPE_AUTO));
      dispatch(trackMyState(constants.MYTRACK_DASHBOARD_AUTO_TOPUP_DISABLE));
    }
  }

  getSubmitRoute(fieldMap, finalizedValues) {
    const { msisdn } = this.props;
    return new AutoTopupRequest(msisdn, finalizedValues);
  }

  render() {
    const {
      location,
      sitemap,
      topupOptions,
      dispatch,
      user,
      ui,
    } = this.props;
    const loyaltySettings = getUserLoyaltySettings(user, ui);
    const stepProps = { ...this.props, context: topup.CONTEXT_AUTO_TOPUP, loyaltySettings };
    if (!topupOptions) {
      return null;
    }

    const onCancel = () => {
      dispatch(showDialog({
        headline: `${ui.guiWordForward}${ui.guiPunctuationQuestionmark}`,
        copy: ui.myProfileEditCancelCopy,
        actions: [
          {
            label: ui.guiWordYes,
            withoutArrow: true,
            action: () => {
              dispatch(push(sitemap.MyDashboardRoute.url));
              dispatch(hideDialog());
            },
          },
          {
            label: ui.guiWordNo,
            asLink: true,
            action: () => {
              dispatch(hideDialog());
            },
          },
        ],
      }));
    };

    return (
      <GlobalSection>
        <FormManager
          form={MyAutoTopupForm.formName}
          submitRoute={this.getSubmitRoute}
          successRoute={sitemap.MyDashboardRoute.url}
          onAfterSubmitSuccess={this.onAfterSubmitSuccess}
          onCancel={onCancel}
          steps={[topup]}
          location={location}
          stepProps={stepProps}
          withFooter
        />
      </GlobalSection>
    );
  }
}

/**
 * required by tracking!
 */
MyAutoTopupForm.formName = 'auto_topup';

MyAutoTopupForm.propTypes = {
  location: PropTypes.object.isRequired,
  sitemap: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  topupOptions: PropTypes.array,
  msisdn: PropTypes.string.isRequired,
  ui: PropTypes.object.isRequired,
  user: PropTypes.object,
};

const selector = formValueSelector(MyAutoTopupForm.formName);
const mapStateToProps = state => ({
  topupOptions: selector(state, constants.FORM_FIELD_TOPUP_OPTIONS),
  sitemap: state.site.sitemap,
  brandName: state.user.brandName,
  msisdn: state.user.credentials.msisdn,
  ui: state.ui,
  user: state.user,
  customerData: state.user.customerData,
});

const mapDispatchToProps = dispatch => ({ dispatch });

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MyAutoTopupForm);
