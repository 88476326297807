import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import suitcss from '../../../../helpers/suitcss';
import connectUI from '../../../basics/ui/UIConnector';
import FieldInput from '../../../basics/form/FieldInput';
import FormSection from '../../form/FormSection';
import FormHeader from '../../form/FormHeader';

const componentName = 'PasswordResetFormMsisdn';
function PasswordResetFormMsisdn(props) {
  const { fieldMap, ui } = props;

  return (
    <div className={suitcss({ componentName })}>
      <FormSection asRow>
        <FormHeader headline={ui.myPasswordLostMsisdnHeadline} copy={ui.myPasswordLostCopy} />
        <Field
          {...fieldMap.msisdn}
          component={FieldInput}
          utilities={['col12', 'mlCol6']}
          withToolTip
        />
      </FormSection>
    </div>
  );
}

PasswordResetFormMsisdn.propTypes = {
  fieldMap: PropTypes.object.isRequired,
  ui: PropTypes.shape({
    myPasswordLostCopy: PropTypes.string.isRequired,
    myPasswordLostMsisdnHeadline: PropTypes.string.isRequired,
  }),
};

export default connectUI()(PasswordResetFormMsisdn);
