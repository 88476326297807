import PropTypes from 'prop-types';

export const propType = {
  eid: PropTypes.string.isRequired,
  etitle: PropTypes.string.isRequired,
  etype: PropTypes.string.isRequired,
  copy: PropTypes.string.isRequired,
  secondaryCopy: PropTypes.string,
  promoIds: PropTypes.array,
  targets: PropTypes.arrayOf(PropTypes.string).isRequired,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  counterStart: PropTypes.number,
  type: PropTypes.oneOf(['counter', 'timer', 'text']).isRequired,
  theme: PropTypes.oneOf(['badge', 'label', 'content', 'sticker']).isRequired,
  colorScheme: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
};

export const shape = PropTypes.shape(propType);
