import React from 'react';
import PropTypes from 'prop-types';
import RouterLink from 'react-router/lib/Link';
import suitcss from '../../../helpers/suitcss';

import { linkShape } from '../../../propTypes/typography';
import { imageShape } from '../../../propTypes/media';
import imageSizes from '../../../config/imageSizes';

import Copy from '../../basics/text/TextCopy';
import MediaImageBackground from '../../basics/media/MediaImageBackground';
import MediaImage from '../../basics/media/MediaImage';
import Unit from '../../basics/text/TextUnit';

const sourcePlaceholder = '_SOURCE_';

function TeaserOffer(props) {
  const {
    item,
    layout,
    location,
    utilities,
    className,
    modifiers,
  } = props;
  const {
    headline, copy, image, link, volume, pricePrefix, pricePostfix, price,
  } = item;
  const { teaser: sizes } = imageSizes;
  const background = image ? { ...image, sizes: sizes[layout] } : {};
  return (
    <div className={suitcss({
      componentName: 'TeaserOffer', className, utilities, modifiers,
    })}
    >
      <MediaImageBackground
        className={suitcss({
          componentName: 'TeaserOffer', descendantName: 'background',
        })}
        {...background}
        isLazy
      >
        {image && link.onClick &&
          <div className={suitcss({ componentName: 'TeaserOffer', descendantName: 'image' })} onClick={link.onClick} >
            <MediaImage {...image} sizes={sizes[layout]} isLazy />
          </div>
        }
        {link &&
          <RouterLink className={suitcss({ componentName: 'TeaserOffer', descendantName: 'ctaOverlay' })} to={link.url.replace(sourcePlaceholder, location.pathname)} />
        }
        <div className={suitcss({ componentName: 'TeaserOffer', descendantName: 'inner' })} >
          {headline &&
            <Copy className={suitcss({ componentName: 'TeaserOffer', descendantName: 'headline', utilities: ['weightBold'] })} condensed raw >
              {headline}
            </Copy>
          }
          {copy &&
            <Copy className={suitcss({ componentName: 'TeaserOffer', descendantName: 'subline' })} raw >
              {copy}
            </Copy>
          }
          {volume &&
            <Copy className={suitcss({ componentName: 'TeaserOffer', descendantName: 'volume', utilities: ['weightBold'] })} condensed raw >
              {volume}
            </Copy>
          }
          {price &&
            <div className={suitcss({ componentName: 'TeaserOffer', descendantName: 'unit' })}>
              <Unit
                postfix={pricePostfix}
                prefix={pricePrefix}
                price={price}
                size="s"
                showCurrency={false}
              />
            </div>
          }
        </div>
      </MediaImageBackground>
    </div>
  );
}

TeaserOffer.propTypes = {
  item: PropTypes.shape({
    headline: PropTypes.string,
    copy: PropTypes.string,
    volume: PropTypes.string,
    price: PropTypes.object,
    pricePostfix: PropTypes.string,
    pricePrefix: PropTypes.string,
    image: imageShape,
    link: linkShape,
  }),
  layout: PropTypes.string,
  location: PropTypes.object,
  utilities: PropTypes.array,
  modifiers: PropTypes.array,
  className: PropTypes.string,
};

TeaserOffer.defaultProps = {
  location: {},
  utilities: [],
};

export default TeaserOffer;
