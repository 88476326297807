/* global window */
import { push } from 'react-router-redux';
import * as reduxForm from 'redux-form';

import { toDecimal } from '../../helpers/money';
import * as constants from '../../helpers/constants';
import
VoucherTopupForm,
{ FORM_NAME_VOUCHER_TOPUP }
  from '../../containers/account/topup/VoucherTopupForm';
import { showDialog, hideDialog, showNotification } from '../page/dialog';
import { send } from '../request/send';
import SingleTopupRequest from '../../model/requests/SingleTopupRequest';
import { fetchPaymentData, fetchCustomerData } from '../request/registry';
import { trackMyState } from '../tracking/event';
import { bindQueryParams } from '../../helpers/url';
import { getPaypalToken } from '../paypal/paypal';

/**
 * Performs a SingleTopup via Paypal
 */
export const performSingleTopupViaPaypal = (paymentOptions) => async (dispatch, getState) => {
  const { user, ui } = getState();
  const paymentToken = await dispatch(getPaypalToken(paymentOptions));
  await dispatch(send(new SingleTopupRequest(
    user.credentials.msisdn,
    paymentOptions.amount,
    {
      paymentType: 'paypal',
      paymentToken,
    },
  )));
  dispatch(showDialog({
    headline: ui.mySingletopupTitle,
    copy: ui.paymentPaypalMessageSuccess,
    actions: [{
      label: ui.guiWordAllright,
    }],
  }));
};

/**
 * Informs the user about that the process cannot be completed
 * because payment data is missing.
 */
export const showPaymentDataDialog = (topupType, amount = 0) => async (dispatch, getState) => {
  const state = getState();
  const { site, user, ui } = state;
  const headline = topupType === constants.TOPUP_TYPE_AUTO ?
    ui.myAutotopupResponseDelayTitle : ui.mySingletopupTitle;
  let copy;
  const actions = [];
  if (user.market === constants.MARKET_MMO) {
    if (topupType === constants.TOPUP_TYPE_SINGLE) {
      copy = ui.mySingletopupDebitMmoCopy;
    } else if (topupType === constants.TOPUP_TYPE_AUTO) {
      copy = ui.myDashboardAutoTopupMmoCopy;
    }
    actions.push({
      label: ui.myTopupDebitMmoButtonOk,
      action: () => {
        window.open(site.downloads.topupRegistrationPdf.url);
        dispatch(hideDialog());
      },
    });
  } else {
    copy = ui.myTopupPaymentdetailsCopy;
    actions.push({
      label: ui.guiSetupNow,
      action: () => {
        dispatch(hideDialog());
        dispatch(push(`${bindQueryParams(site.sitemap.MyDataRoute.url, { [constants.QUERY_EDIT]: true })}#${constants.QUERY_PAYMENT_DATA}`));
      },
    });
    // feature toggle
    const paypalEnabled = process.Environment.getVariableAsBoolean('DBN_FEATURE_PAYPAL');
    if (
      paypalEnabled &&
      topupType !== constants.TOPUP_TYPE_AUTO &&
      user.topup.singlePaymentTypes.includes(constants.SINGLE_PAYMENT_TYPE_PAYPAL) &&
      user.market === constants.MARKET_PREPAID &&
      (!user.paymentData.type ||
      user.paymentData.type === constants.PAYMENT_DATA_TYPE_NONE)
    ) {
      actions.push({
        label: ui.paymentPaypalPayWith,
        action: async () => {
          dispatch(hideDialog());
          const customerData = await dispatch(fetchCustomerData());
          await dispatch(performSingleTopupViaPaypal({
            ...customerData,
            amount,
            cartLabel: ui.paymentPaypalCartTitle,
          }));
        },
        asLink: true,
      });
    }
  }
  actions.push({
    label: ui.guiWordCancel,
    asLink: true,
  });
  dispatch(showDialog({ headline, copy, actions }));
};

/**
 * If the topup has been succesful but is delayed, a dialog
 * is displayed, otherwise, a simple notification is shown.
 */
export const showTopupSuccess = (response, topupType) => async (dispatch, getState) => {
  const { ui } = getState();
  const isSingleTopup = topupType === constants.TOPUP_TYPE_SINGLE;

  const copy = (isSingleTopup ? ui.mySingletopupDelayCopy : ui.myAutotopupResponseDelayCopy);
  const headline = (isSingleTopup ? ui.mySingletopupTitle : ui.myAutotopupResponseDelayTitle);

  if (response.status === 202) {
    dispatch(showDialog({
      headline,
      copy,
      actions: [
        {
          label: ui.guiWordAllright,
          action: () => {
            dispatch(hideDialog());
          },
        },
      ],
    }));
  } else {
    dispatch(showNotification(copy));
    dispatch(hideDialog());
  }
};

/**
 * Dialog that informs the user about the topup amount
 * and the payment method used to pay for the topup.
 */
const showSingleTopupDialog = (amount, paymentData) => async (dispatch, getState) => {
  const { user, ui } = getState();
  let copy;
  if (paymentData.type === constants.PAYMENT_DATA_TYPE_CREDIT_CARD) {
    copy = ui.mySingletopupCopyCc
      .replace('{CCTYPE}', paymentData.ccProvider)
      .replace('{CCNUMBER}', paymentData.ccNumber);
  } else if (paymentData.type === constants.PAYMENT_DATA_TYPE_DIRECT_DEBIT) {
    copy = ui.mySingletopupCopyDebit.replace('{IBAN}', paymentData.iban);
  } else if (paymentData.type === constants.PAYMENT_DATA_TYPE_MMO) {
    copy = ui.mySingletopupCopyMmo;
  }
  copy = copy.replace('{AMOUNT}', toDecimal({ unit: amount }));
  const headline = ui.mySingletopupTitle;
  const actions = [
    {
      label: ui.guiDebitNow,
      action: async () => {
        if (constants[`MYTRACK_DASHBOARD_TOPUP_${amount}_SUBMIT`]) {
          dispatch(trackMyState(constants[`MYTRACK_DASHBOARD_TOPUP_${amount}_SUBMIT`]));
        }
        const request = new SingleTopupRequest(user.credentials.msisdn, amount);
        const response = await dispatch(send(request));
        dispatch(showTopupSuccess(response, constants.TOPUP_TYPE_SINGLE));
        dispatch(trackMyState(constants.MYTRACK_NOTIFY_TOPUP_SUCCESS));
      },
    },
    {
      label: ui.guiWordCancel,
      asLink: true,
    },
  ];

  dispatch(showDialog({
    headline,
    copy,
    actions,
    onClose: () => {
      if (constants[`MYTRACK_DASHBOARD_TOPUP_${amount}_CANCEL`]) {
        dispatch(trackMyState(constants[`MYTRACK_DASHBOARD_TOPUP_${amount}_CANCEL`]));
      }
    },
  }));
};

/**
 * Starts the auto topup process
 *
 * @see https://confluence.db-n.com/x/jIvr
 */
export const performAutoTopup = () => async (dispatch, getState) => {
  const paymentData = await dispatch(fetchPaymentData());
  const { site } = getState();

  dispatch(trackMyState(constants.MYTRACK_DASHBOARD_AUTO_TOPUP));

  if (paymentData.type && paymentData.type !== constants.PAYMENT_DATA_TYPE_NONE) {
    dispatch(push(site.sitemap.MyAutoTopupRoute.url));
  } else {
    dispatch(showPaymentDataDialog(constants.TOPUP_TYPE_AUTO));
  }
};

/**
 * Starts the single topup process
 *
 * @see https://confluence.db-n.com/x/8Ivr
 */
export const performSingleTopup = amount => async dispatch => {
  const paymentData = await dispatch(fetchPaymentData());

  if (constants[`MYTRACK_DASHBOARD_TOPUP_${amount}`]) {
    dispatch(trackMyState(constants[`MYTRACK_DASHBOARD_TOPUP_${amount}`]));
  }

  if (paymentData.type && paymentData.type !== constants.PAYMENT_DATA_TYPE_NONE) {
    dispatch(showSingleTopupDialog(amount, paymentData));
  } else {
    dispatch(showPaymentDataDialog(constants.TOPUP_TYPE_SINGLE, amount));
  }
};

/**
 * Starts the voucher topup process
 *
 * @see https://confluence.db-n.com/x/OI3r
 */
export const performVoucherTopup = () => async (dispatch, getState) => {
  const { ui } = getState();
  dispatch(trackMyState(constants.MYTRACK_DASHBOARD_CODE_TOPUP));
  dispatch(showDialog({
    headline: ui.myVoucherTopupTitle,
    copy: ui.myVoucherTopupCopy,
    component: VoucherTopupForm,
    props: { ui },
    actions: [
      {
        label: ui.myTopupOk,
        action: async () => {
          dispatch(trackMyState(constants.MYTRACK_DASHBOARD_CODE_TOPUP_SUBMIT));
          // note: if submit succeeds, the dialog is closed by the form, since
          // the reduxForm.submit action doesn't tell us whether the submit was successful.
          dispatch(reduxForm.submit(FORM_NAME_VOUCHER_TOPUP));
        },
      },
      {
        label: ui.guiWordCancel,
        asLink: true,
      },
    ],
    onClose: () => dispatch(trackMyState(constants.MYTRACK_DASHBOARD_CODE_TOPUP_CANCEL)),
  }));
};

