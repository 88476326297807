import QueueableRequest from './QueueableRequest';
import { ACCOUNT_TARIFF_SUMMARY_ENDPOINT } from '../../config/api';
import { bindParamsToRoute, bindQueryParams } from '../../helpers/url';
import { blockContentBasedOnUserScope } from '../../helpers/scope';
import { SCOPE_MYDASHBOARD } from '../../helpers/constants';

/**
 * ATTENTION: This request is only(!) used to fetch the subscription items!
 */
export default class TariffSummaryRequest extends QueueableRequest {
  constructor(msisdn, options) {
    let url = bindParamsToRoute(ACCOUNT_TARIFF_SUMMARY_ENDPOINT, { msisdn });
    url = bindQueryParams(url, { 'filter[booked]': 1 });
    super(url, options);
  }

  block(state) {
    return !blockContentBasedOnUserScope(state.user.scope, SCOPE_MYDASHBOARD);
  }
}
