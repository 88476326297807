import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';

import { capitalize } from '../../../helpers/str';
import connectUI from '../../basics/ui/UIConnector';

import { list as downloadList } from '../../../propTypes/download';

import Link from '../../basics/text/TextLink';
import Headline from '../../basics/text/TextHeadline';
import DownloadListItem from './DownloadListItem';
import Accordion from '../../basics/accordion/Accordion';
import AccordionSection from '../../basics/accordion/AccordionSection';

// TODO Implement tab navigation when.categories are required
const componentName = 'DownloadList';
class DownloadList extends PureComponent {

  constructor(props, context) {
    super(props, context);

    const itemsPerView = props.itemsPerView === 0 ?
      props.items.length :
      props.itemsPerView;

    this.state = {
      itemsPerView,
      totalItems: itemsPerView,
    };

    this.onShowMore = this.onShowMore.bind(this);
  }

  onShowMore() {
    const { items } = this.props;
    const { itemsPerView, totalItems } = this.state;
    this.setState({
      totalItems: totalItems + itemsPerView >= items.length ?
        items.length : totalItems + itemsPerView,
    });
  }

  renderItem({ item, index }) {
    const { layout } = this.props;
    const isSubList = item.entities && item.entities.length;
    return (
      <div
        className={suitcss({
          componentName,
          descendantName: 'item',
        })}
        key={index}
      >
        {!isSubList && (
          <DownloadListItem
            type={item.type}
            layout={layout}
            date={item.date}
            copy={item.copy}
            image={item.thumbnail}
            file={item.file}
          />
        )}
        {isSubList && (
          <Accordion>
            <AccordionSection label={item.headline || ''} theme="light">
              {item.entities.map((subItem, subIndex) => (
                <DownloadListItem
                  type={subItem.type}
                  layout={layout}
                  date={subItem.date}
                  copy={subItem.copy}
                  image={subItem.thumbnail}
                  file={subItem.file}
                  key={subIndex}
                />
              ))}
            </AccordionSection>
          </Accordion>
        )}
      </div>
    );
  }

  renderContent() {
    const { layout, items } = this.props;
    const { totalItems } = this.state;
    const centerContent = layout !== 'compact' && totalItems < 3;
    return (
      <div className={suitcss({ componentName, descendantName: 'content' })} >
        <div
          className={suitcss({
            componentName,
            descendantName: 'list',
            modifiers: [centerContent && 'centered'],
          })}
        >
          {items.map((item, index) => index < totalItems &&
            this.renderItem({ item, index }))
          }
        </div>
      </div>
    );
  }

  render() {
    const { layout, headline, items, ui, primaryModule } = this.props;
    const { totalItems } = this.state;
    return (
      <div
        className={suitcss({
          componentName,
          modifiers: [
            layout && `layout${capitalize(layout)}`,
          ],
        })}
      >
        <div className={suitcss({ componentName, descendantName: 'inner' })} >
          {headline && (
            <div className={suitcss({ componentName, descendantName: 'headline' })} >
              <Headline size="l" embedded element={primaryModule ? 'h1' : 'h2'}>
                {headline}
              </Headline>
            </div>
          )}
          {this.renderContent()}
          {totalItems < items.length &&
            <div className={suitcss({ componentName, descendantName: 'link' })} >
              <Link element="button" onClick={this.onShowMore} >
                {ui.guiShowMoreContent}
              </Link>
            </div>
          }
        </div>
      </div>
    );
  }
}

DownloadList.propTypes = {
  layout: PropTypes.oneOf(['grid', 'compact']),
  headline: PropTypes.string,
  items: downloadList,
  itemsPerView: PropTypes.number,
  primaryModule: PropTypes.bool,
  ui: PropTypes.shape({
    guiShowMoreContent: PropTypes.string.isRequired,
  }),
};

DownloadList.defaultProps = {
  itemsPerView: 0,
};

export default connectUI()(DownloadList);
