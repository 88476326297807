import PageModule from './PageModule';
import component from '../../containers/freeSim/FreeSimForm';

/**
 * The FreeSimForm module allowes the user to order a free sim
 * @see https://confluence.db-n.com/x/1cLc
 */
class FreeSimFormModule extends PageModule {
  constructor(moduleData) {
    super(component, moduleData);
  }
}

export default FreeSimFormModule;

