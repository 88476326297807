import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as FileSaver from 'file-saver';
import suitcss from '../../../helpers/suitcss';
import Copy from '../../basics/text/TextCopy';
import Info from '../../basics/text/TextInfo';
import { send } from '../../../actions/request/send';


class EECCCheckSummaryConsentDialogCopy extends Component {

  manageDownloadEECCPdf = async (ev) => {
    const { processId, msisdn, DocumentRequest } = this.props;
    ev.preventDefault();
    const format = 'pdf';
    const pdfData = await this.props.dispatch(
      send(
        new DocumentRequest(
          msisdn, processId, { format }),
      ));

    if (pdfData.status === 200 && process.browser) {
      const blob = new window.Blob([pdfData.body.blob], { type: `application/${format};` }); //eslint-disable-line

      // todo use ui Element
      const fileName = 'Vertragszusammenfassung';
      FileSaver.saveAs(blob, `${fileName}.${format}`);
    }
  };

  renderEECCDocumentLink = () => {
    const { eeccText, eeccTextSuffix } = this.props;
    const cleanEeCcText = eeccText.replace('{CONTRACT_SUMMARY_PDF}', '');

    return (
      <Copy
        className={suitcss({
          descendantName: 'container' }, this)}
        element="p"
        embedded
      >
        <Copy
          className={suitcss({
             descendantName: 'copyText' }, this)}
          element="span"
          embedded
        >
            {cleanEeCcText}
        </Copy>
        <Copy
          className={suitcss({
          descendantName: 'copyLink' }, this)}
          element="span"
          embedded
          onClick={this.manageDownloadEECCPdf}
        >
          { eeccTextSuffix }
        </Copy>
      </Copy>
    );
  };

  renderInfoI = () => {
    const { label, copy, asAccordion, withContentBox } = this.props;
    return (
      <Info
        label={label}
        copy={copy}
        asAccordion={asAccordion}
        withContentBox={withContentBox}
      />
    );
  };

  render() {
    const { showInfoI, showEECCCopy, isAutomaticExtension } = this.props;
    /* eslint-disable quotes */
    return (
      <>
        {showInfoI && this.renderInfoI()}
        {isAutomaticExtension && showEECCCopy && this.renderEECCDocumentLink()}
      </>
    );
  }
}

EECCCheckSummaryConsentDialogCopy.propTypes = {
  DocumentRequest: PropTypes.func.isRequired,
  processId: PropTypes.string.isRequired,
  showEECCCopy: PropTypes.bool.isRequired,
  showInfoI: PropTypes.any.isRequired,
  eeccText: PropTypes.string.isRequired,
  eeccTextSuffix: PropTypes.string.isRequired,
  msisdn: PropTypes.string.isRequired,
  isAutomaticExtension: PropTypes.bool.isRequired,
  label: PropTypes.string,
  copy: PropTypes.string,
  asAccordion: PropTypes.bool,
  withContentBox: PropTypes.bool,
};

const mapStateToProps = ({ user }) => {
  return {
    msisdn: user.credentials.msisdn,
  };
};

export default connect(mapStateToProps)(EECCCheckSummaryConsentDialogCopy);

