import PageModule from './PageModule';
import component from '../../containers/header/LandingPage';

class LandingPageModule extends PageModule {
  constructor(moduleData) {
    super(component, moduleData);
  }
}

export default LandingPageModule;

