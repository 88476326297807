import { ACTION_PREFIX } from '../../helpers/constants';

export const CLEAR_USER_DATA = `${ACTION_PREFIX}/CLEAR_USER_DATA`;

export const ACCOUNT_USER_CONTRACT = 'contract';
export const ACCOUNT_USER_PASSWORD = 'password';
export const ACCOUNT_USER_CUSTOMER_DATA = 'customerData';
export const ACCOUNT_USER_PAYMENT_DATA = 'paymentData';
export const ACCOUNT_USER_EVN_SETTING = 'evnSetting';
export const ACCOUNT_USER_ACR_SETTING = 'acrSetting';
export const ACCOUNT_USER_BILLING = 'billing';
export const ACCOUNT_USER_ADCONSENT = 'adconsent';
export const ACCOUNT_USER_GDPR_CONSENTS = 'gdprConsents';
export const ACCOUNT_USER_GDPR_CONSENTS_INQUIRY = 'gdprConsentsInquiry';
export const ACCOUNT_USER_GDPR_DATA = 'gdprData';
export const ACCOUNT_USER_SIM = 'sim';
export const ACCOUNT_USER_MNP_OUT = 'mnpOut';

export const clearUserData = ids => ({
  type: CLEAR_USER_DATA,
  payload: { ids },
});
