import PageModule from './PageModule';
import component from '../../containers/email/EmailRegistrationForm';

class MyEmailRegistrationFormModule extends PageModule {
  constructor(moduleData) {
    super(component, moduleData);
  }
}

export default MyEmailRegistrationFormModule;
