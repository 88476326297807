import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import oneLineTrim from 'common-tags/lib/oneLineTrim';
import { createSelector } from 'reselect';
import {
  isTariffEntity,
  isHardwareEntity,
  sortByEntityType,
  getShippingFee,
  getCheapestHardwareWithTariff,
} from '../../helpers/entity';
import ProcessHeader from '../../components/compositions/processHeader/ProcessHeader';
import { CATEGORY_POSTPAID } from '../../propTypes/tariff';
import { bindQueryParams } from '../../helpers/url';
import {
  CONTEXT_CONTRACT_RENEWAL_SIM_PLUS,
  QUERY_SELECTED_HARDWARE,
  QUERY_SELECTED_TARIFF,
} from '../../helpers/constants';
import { createNormalizeTargetEntitiesSelector } from '../../selectors/entity';

class NavProcessSinglePending extends PureComponent {
  render() {
    const {
      ui,
      items,
      progressState,
      validAsBundleOnly,
      activeType,
      onSubmit,
      urlSubmit,
      params,
      hardwareGroup,
      context,
      isContractRenewal,
      routing,
      hardwareEntity,
      shippingFee,
    } = this.props;
    return (
      <ProcessHeader
        context={context}
        ui={ui}
        onSubmit={onSubmit}
        urlSubmit={urlSubmit}
        items={items}
        progressState={progressState}
        validAsBundleOnly={validAsBundleOnly}
        activeType={activeType}
        standalone={params.standalone}
        hardwareGroup={hardwareGroup}
        isContractRenewal={isContractRenewal}
        routing={routing}
        hardwareEntity={hardwareEntity}
        shippingFee={shippingFee}
      />
    );
  }
}

NavProcessSinglePending.propTypes = {
  items: PropTypes.array.isRequired,
  progressState: PropTypes.string.isRequired,
  validAsBundleOnly: PropTypes.bool,
  activeType: PropTypes.string,
  ui: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  urlSubmit: PropTypes.string,
  context: PropTypes.string,
  params: PropTypes.shape({
    standalone: PropTypes.bool,
  }).isRequired,
  hardwareGroup: PropTypes.object,
  isContractRenewal: PropTypes.bool,
  routing: PropTypes.object,
  hardwareEntity: PropTypes.array,
  shippingFee: PropTypes.object,
};

const entityToItem = ({ ui, hardwareGroup, entities, isContractRenewal, tariffVO }) => (entity) => {
  const tariffEntity = entities.find(isTariffEntity);
  const hardwareEntity = entities.find(isHardwareEntity);
  const isPendingRenewal = (!tariffEntity || !hardwareEntity) && isContractRenewal;
  const availableCondition = isContractRenewal && tariffVO.availableConditions &&
    tariffVO.availableConditions.find(condition => condition.transferFee);
  const renewalFee = { unit: availableCondition && availableCondition.transferFee, currency: 'EUR' };
  const cheapestHardwareWithTariff = hardwareEntity && !isContractRenewal && !tariffEntity
    && getCheapestHardwareWithTariff(hardwareEntity, null, ui);
  let urlChange;
  switch (entity.etype) {
    case 'tariffEntity':
      urlChange = !hardwareEntity ?
        tariffEntity.urlOverview :
        hardwareEntity && oneLineTrim`
        ${hardwareEntity.urlSelect}?
        selectedTariff=${entity.eid}&
        selectedHardware=${hardwareEntity.eid}
      `;
      return {
        eid: entity.eid,
        etype: entity.etype,
        headline: `${entity.type} ${entity.name ? `<strong><em>${entity.name}</em></strong>` : ''}`,
        subline: isContractRenewal ? ui.myVvlTariffHint : null,
        paymentFee: entity.paymentFee,
        paymentFeeStrike: entity.paymentFeeStrike,
        singlePaymentFee: isContractRenewal && availableCondition
          ? renewalFee
          : entity.singlePaymentFee,
        singlePaymentFeeStrike: entity.singlePaymentFeeStrike,
        urlSelect: entity.urlSelect,
        urlChange: !isPendingRenewal ? urlChange : null,
        simOnly: entity.simOnly,
        market: entity.market,
        availability: entity.availability,
      };

    case 'hardwareEntity':
      urlChange = !tariffEntity ?
        hardwareEntity.urlOverview :
        tariffEntity && oneLineTrim`
        ${tariffEntity.urlSelect}?
        selectedHardware=${entity.eid}
      `;
      return {
        eid: entity.eid,
        etype: entity.etype,
        headline: `${hardwareGroup.brand} ${entity.name}`,
        subline: `${entity.capacity} ${ui.guiSymbolGigabyte}${ui.guiSymbolComma} ${entity.color}`,
        paymentFee: entity.paymentFee,
        paymentFeeStrike: entity.paymentFeeStrike,
        singlePaymentFee: entity.singlePaymentFee,
        singlePaymentFeeStrike: entity.singlePaymentFeeStrike,
        urlSelect: bindQueryParams(entity.urlSelect, {
          [QUERY_SELECTED_HARDWARE]: hardwareEntity.eid,
          ...(cheapestHardwareWithTariff && {
            [QUERY_SELECTED_TARIFF]: cheapestHardwareWithTariff,
          }),
        }),
        urlChange: !isPendingRenewal ? urlChange : null,
      };

    case 'tariffOptionEntity':
      return {
        eid: entity.eid,
        etype: entity.etype,
        headline: `${entity.type} ${entity.name}`,
        paymentFee: entity.paymentFee,
        paymentFeeStrike: entity.paymentFeeStrike,
        singlePaymentFee: { unit: 0, currency: 'EUR' },
        marketingLocation: entity.marketingLocation,
      };

    default:
      return null;
  }
};

const normalizeUi = (
  ui,
  progressState,
  entities,
  isContractRenewal,
  tariff,
  tariffVO,
  validAsBundleOnly,
) => {
  const firstEntity = entities[0];
  const secondEntity = entities[1];
  const firstIsTariff = isTariffEntity(firstEntity);
  const firstIsHardware = isHardwareEntity(firstEntity);
  const isPending = progressState === 'SINGLE_PENDING';
  const isPendingTariff = isPending && firstIsTariff;
  const isPendingHardware = isPending && firstIsHardware;

  const isTariffPostpaid = tariff && tariff.category === CATEGORY_POSTPAID;
  const tariffOptionLabel = !secondEntity ? 'nprAddCartNoOption' : 'guiAddCart';
  const pendingTariffCartLabel = isTariffPostpaid ?
    'nprAddCartNoHardware' :
    tariffOptionLabel;
  const pendingTariffPlaceholderLabel = isTariffPostpaid ?
    'nprSelectHardware' :
    'nprSelectOptions';
  const tariffSinglePaymentFee = isContractRenewal ?
    'guiWordSingular' :
    isTariffPostpaid ?
      'guiFeeConnection' :
      'guiFeeSingularPayment';

  const tariffMonthlyPaymentFee = isTariffPostpaid ?
    'guiFeeMonthly' :
    'guiFeeMonthlyPayment';

  const checkoutCartLabel = progressState !== 'BUNDLE' ?
    pendingTariffCartLabel : 'guiAddCart';

  const isLabelContinue = validAsBundleOnly || isPendingTariff || isPendingHardware;

  const summaryCallToActionLabel = ui[isLabelContinue ? 'guiWordContinue' : checkoutCartLabel];
  return {
    option1CallToActionLabel: ui[firstIsTariff ? 'nprChangeTariff' : 'nprChangeHardware'],
    option1SinglePaymentFee: ui[firstIsTariff ? tariffSinglePaymentFee : 'guiFeeExtraSingular'],
    option1PaymentFee: ui[firstIsTariff ? tariffMonthlyPaymentFee : 'guiFeeExtraMonthly'],
    option1SinglePaymentPrefix: ui[(firstIsHardware && !secondEntity) ? 'guiWordStartingFrom' : null],

    options2ListHeadline: ui.nprSelectedOptions,

    option2CallToActionLabel: ui[!firstIsTariff ?
      'nprChangeTariff' :
      'nprChangeHardware'
    ],
    option2SinglePaymentFee: ui[!firstIsTariff ?
      tariffSinglePaymentFee :
      'guiFeeExtraSingular'
    ],
    option2PaymentFee: ui[!firstIsTariff ?
      'guiFeeMonthly' :
      'guiFeeExtraMonthly'
    ],

    summaryCallToActionLabel,
    summarySinglePaymentFee: ui.guiSumSingular,
    summaryPaymentFee: ui.guiSumMonthly,

    placeholderLabel: ui[firstIsTariff ?
      pendingTariffPlaceholderLabel :
      'nprSelectTariff'
    ],
  };
};

// @todo entities are now passed resolved into the component, so we may be able to
//       clean some lines of code and references to the store up
const mapStateToProps = () => {
  const normalizeTargetEntitiesSelector = createNormalizeTargetEntitiesSelector({ style: 'strike' });
  const normalizeTargetEntitiesSimPlusSelector = createNormalizeTargetEntitiesSelector({ style: 'strike', context: CONTEXT_CONTRACT_RENEWAL_SIM_PLUS });
  const entitiesRegular = (state, ownProps) =>
    normalizeTargetEntitiesSelector(state, ownProps.entities);
  const entitiesSimPlus = (state, ownProps) =>
    normalizeTargetEntitiesSimPlusSelector(state, ownProps.entities);
  const entitiesSelector = createSelector(
    entitiesSimPlus,
    entitiesRegular,
    (state) => state.site.contractRenewal.isInProgress,
    (simPlusEntities, regularEntities, isContractRenewal) => {
      return (isContractRenewal ? simPlusEntities : regularEntities)
        .sort(sortByEntityType);
    },
  );
  const tariffVOSelector = (tariff) => state => state.site.contractRenewal.isInProgress &&
    ((tariff && state.entities.tariffVO[tariff.iid]) || {});
  const itemsSelector = (ui, hardwareGroup, tariff) => createSelector(
    entitiesSelector,
    tariffVOSelector(tariff),
    (state) => state.site.contractRenewal.isInProgress,
    (entities, tariffVO, isContractRenewal) => {
      return entities.map(entityToItem({
        ui, hardwareGroup, entities, isContractRenewal, tariffVO,
      }));
    },
  );
  return (state, ownProps) => {
    const { params, hardwareGroup, ui, progressState, activeType } = ownProps;
    const { urlShoppingCart } = params;
    const { site, routing } = state;
    const isContractRenewal = site.contractRenewal.isInProgress;
    const context = isContractRenewal ? CONTEXT_CONTRACT_RENEWAL_SIM_PLUS : null;
    // always put tariff option entities in the back
    const entities = entitiesSelector(state, ownProps);

    const tariff = entities.find(isTariffEntity);
    const items = itemsSelector(ui, hardwareGroup, tariff)(state, ownProps);
    const tariffVO = tariffVOSelector(tariff)(state);
    const validAsBundleOnly = isContractRenewal &&
      !tariffVO.contractRenewalWithSimOnly &&
      tariffVO.contractRenewalWithHardwareOnly;

    const selectedTariffEntity = ownProps.entities.find(isTariffEntity);
    const selectedHardwareEntity = ownProps.entities.find(isHardwareEntity);
    const shippingFee = getShippingFee(
      selectedHardwareEntity,
      selectedTariffEntity,
      state.entities.tariffVO,
      isContractRenewal,
    );

    return ({
      entities,
      items,
      progressState,
      validAsBundleOnly,
      activeType,
      context,
      urlSubmit: isContractRenewal ? site.sitemap.CheckoutFormRoute.url : urlShoppingCart,
      hardwareEntity: ownProps.entities,
      routing,
      isContractRenewal,
      ui: normalizeUi(
        ui,
        progressState,
        entities,
        isContractRenewal,
        tariff,
        tariffVO,
        validAsBundleOnly,
      ),
      shippingFee,
    });
  };
};

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  {
    areStatesEqual: (next, prev) => {
      return prev.orderProcess === next.orderProcess &&
      prev.routing === next.routing &&
      prev.site.contractRenewal === next.site.contractRenewal &&
      prev.entities === next.entities;
    },
  },
)(NavProcessSinglePending);
