import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import copyToClipboard from 'copy-to-clipboard';
import RouterLink from 'react-router/lib/Link';

import suitcss from '../../../helpers/suitcss';
import { capitalize, stripHTML } from '../../../helpers/str';
import connectUI from '../../basics/ui/UIConnector';

import MediaImage from '../../basics/media/MediaImage';
import Copy from '../../basics/text/TextCopy';
import FileSize from '../../basics/text/TextFileSize';
import SvgLoader from '../../basics/media/MediaSvgLoader';
import imageSizes from '../../../config/imageSizes';

import { imageShape } from '../../../propTypes/media';

const componentName = 'DownloadListItem';
class DownloadListItem extends PureComponent {
  constructor(props, context) {
    super(props, context);
    this.copyTextToClipboard = this.copyTextToClipboard.bind(this);
  }

  copyTextToClipboard() {
    const { copy } = this.props;
    copyToClipboard(stripHTML(copy));
  }

  renderContent() {
    const { layout, date, copy } = this.props;
    const isCompact = layout === 'compact';
    const dateCopy = date && new Date(date).toLocaleDateString('de-DE', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
      timeZone: 'Europe/Berlin',
    });
    const copyText = layout === 'compact' ? stripHTML(copy) : copy;
    return (
      <div
        className={suitcss({
          componentName,
          descendantName: 'content',
          modifiers: [!dateCopy && 'centered'],
        })}
      >
        {isCompact && dateCopy &&
          <div className={suitcss({ componentName, descendantName: 'date' })} >
            <Copy size="secondary" raw embedded >{dateCopy}</Copy>
          </div>
        }
        {copy &&
          <div className={suitcss({ componentName, descendantName: 'copy' })} >
            <Copy raw embedded >
              {copyText}
            </Copy>
          </div>
        }
      </div>
    );
  }

  renderSubcontent() {
    const { type, file, ui } = this.props;
    const icon = type === 'text' ? 'content-clipboard' : 'content-download';
    const iconPath = `/icons/${icon}.svg`;
    return (
      <div className={suitcss({ componentName, descendantName: 'subcontent' })} >
        <div className={suitcss({ componentName, descendantName: 'infos' })} >
          <div className={suitcss({ componentName, descendantName: 'icon' })} >
            <SvgLoader path={iconPath} />
          </div>
          <div className={suitcss({ componentName, descendantName: 'meta' })} >
            {file &&
              <FileSize
                size={file.size}
                prefix={`${file.extension.toUpperCase()}${file.size ? ui.guiSymbolComma : ''}`}
              />
            }
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { layout, type, image, file } = this.props;
    const isCompact = layout === 'compact';
    const isTextItem = type === 'text';
    const modifiers = [
      type && `type${capitalize(type)}`,
      layout && `layout${capitalize(layout)}`,
    ];

    const dlImage = {
      ...image,
      sizes: imageSizes.downloadListItem,
    };

    return (
      <div className={suitcss({ componentName, modifiers })} >
        <div className={suitcss({ componentName, descendantName: 'inner' })} >
          {!isTextItem && file &&
            <RouterLink
              className={suitcss({
                componentName,
                descendantName: 'ctaOverlay',
              })}
              to={file.url}
              target="_blank"
              rel="noopener noreferrer"
            />
          }

          {isTextItem &&
            <button
              className={suitcss({
                componentName,
                descendantName: 'ctaOverlay',
              })}
              onClick={this.copyTextToClipboard}
            />
          }

          {!isCompact && image &&
            <div
              className={suitcss({
                componentName,
                descendantName: 'image',
              })}
            >
              <MediaImage {...dlImage} isLazy />
            </div>
          }
          {this.renderContent()}
          {this.renderSubcontent()}
        </div>
      </div>
    );
  }
}

DownloadListItem.propTypes = {
  type: PropTypes.oneOf(['text', 'image', 'pdf']),
  layout: PropTypes.oneOf(['compact', 'grid']),
  date: PropTypes.string,
  copy: PropTypes.string.isRequired,
  file: PropTypes.object,
  image: imageShape,
  ui: PropTypes.shape({
    guiSymbolComma: PropTypes.string.isRequired,
  }),
};

DownloadListItem.defaultProps = {};

export default connectUI()(DownloadListItem);
