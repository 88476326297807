import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import oneline from 'common-tags/lib/oneLine';
import suitcss from '../../../helpers/suitcss';
import connectUI from '../../basics/ui/UIConnector';
import { formatDate } from '../../../helpers/date';
import { toDecimal, toPriceObject } from '../../../helpers/money';
import { subscriptionItemShape } from '../../../propTypes/account';
import {
  MARKET_POSTPAID,
  VO_TYPE_TARIFFOPTION,
  VO_STATE_PROCESSING,
  VO_STATE_BOOKEDSINGLE,
  VO_STATE_PAUSED,
  VO_TYPE_TARIFF,
  CONTEXT_TARIFF_CHANGE,
  CONTEXT_CONTRACT_RENEWAL,
  CONTEXT_TARIFF_OPTION,
  MARKET_PREPAID,
} from '../../../helpers/constants';
import { isTariffVO, isTariffOptionVO, getPaymentFeeDuration } from '../../../helpers/entity';
import { isTariffChangeable } from '../../../helpers/user';
import StringSubscriptionState from '../../basics/string/StringSubscriptionState';
import Callout from '../../../containers/callout/Callout';
import Headline from '../../basics/text/TextHeadline';
import Copy from '../../basics/text/TextCopy';
import Link from '../../basics/text/TextLink';
import Unit from '../../basics/text/TextUnit';
import Feature from '../tariff/TariffDetailsFeature';
import TariffFilter from '../../../containers/contractRenewal/TariffFilter';

const componentName = 'TariffDetails';
class MyTariffDetails extends PureComponent {

  getContractCopy() {
    const { item, ui, isBookedByUser } = this.props;
    const {
      market,
      type,
      state,
      cancellationDate,
      possibleCancellationDate,
      automaticExtension,
      cancelable,
      canceled,
    } = item;

    const isTypeTariff = isTariffVO(item);
    const isPausedOption = market !== MARKET_POSTPAID && state === VO_STATE_PAUSED;

    const stateText = state && isBookedByUser && (
      (!isPausedOption && (<StringSubscriptionState type={type} state={item.state} date={item.stateDate} element="p" />)) ||
      (isPausedOption && (<p>{ui.myTariffoptionStatePaused}</p>))
    );
    const extensionText = !isTypeTariff && automaticExtension && isBookedByUser && (
      <p>{ui.myTariffoptionRuntime.replace('{DURATION}', getPaymentFeeDuration(item, ui))}</p>
    );

    const cancelableCopy = isTypeTariff ?
      ui.myTariffCancellationUntil :
      ui.myTariffoptionCancelCondition;
    const cancelableText = cancelable && !canceled && possibleCancellationDate && isBookedByUser
      && (
        <p>{cancelableCopy.replace('{DATE}', formatDate(possibleCancellationDate))}</p>
      );

    const canceledText = canceled && isTypeTariff && isBookedByUser && (
      <p>
        {ui.myTariffCancellationDate.replace('{DATE}', formatDate(cancellationDate))}
      </p>
    );
    const hasCopy = stateText || extensionText || cancelableText || canceledText;
    return hasCopy && (
      <Copy element="div" utilities={['colorGray100']} embedded>
        {stateText}
        {extensionText}
        {cancelableText}
        {canceledText}
      </Copy>
    );
  }

  getButtonsHint() {
    const { market, item, isBookedByUser, ui } = this.props;
    const {
      state,
      stateDate,
      basicFee,
      bookable,
      cancelable,
      canceled,
      changeable,
      renewable,
    } = item;
    const formatedFee = basicFee && toDecimal({ currency: 'EUR', unit: basicFee });
    const isTypeTariff = isTariffVO(item);
    const inProcess = state === VO_STATE_PROCESSING;

    if (!isTypeTariff && (bookable || (bookable && changeable))) {
      const copy = market === MARKET_POSTPAID ?
        ui.myTariffoptionBookSublinePuc :
        ui.myTariffoptionBookSubline;
      return copy && copy.replace('{AMOUNT}', formatedFee || '0,00');
    }

    if (!isTypeTariff && cancelable && !canceled && !inProcess && isBookedByUser) {
      return oneline`
        ${state !== 'paused' ? ui.myTariffCancellationUntil.replace('{DATE}', formatDate(stateDate)).concat('.') : ''}
        ${ui.myTariffoptionCancelConditionMonthly}
      `;
    }

    if (!isTypeTariff && cancelable && !canceled && inProcess && isBookedByUser) {
      return ui.myTariffoptionCancelNotPossible;
    }

    if (renewable && canceled && isBookedByUser) {
      return ui.myTariffWithdrawCancellationHint;
    }

    return null;
  }

  renderHeader() {
    const { item, tariffMode, isBookedByUser } = this.props;
    const { headline, subline } = item;
    const isTypeTariffOption = isTariffOptionVO(item);
    const getContext = () => {
      if (isTypeTariffOption) {
        return CONTEXT_TARIFF_OPTION;
      } else if (tariffMode) {
        return tariffMode;
      } else if (item.renewable || item.renewContract) {
        return CONTEXT_CONTRACT_RENEWAL;
      }
      return CONTEXT_TARIFF_CHANGE;
    };
    return (
      <div className={suitcss({ componentName, descendantName: 'header' })} >
        {headline &&
        <div className={suitcss({ componentName, descendantName: 'type' })} >
          <Headline size="xxl" embedded raw>
            {headline}
          </Headline>
        </div>
        }
        {subline &&
        <div className={suitcss({ componentName, descendantName: 'name' })} >
          <Headline
            size="xxl"
            embedded
            raw
            utilities={['colorPrimary', 'weightBold']}
          >
            {subline}
          </Headline>
        </div>
        }
        {!isBookedByUser && (
          <div className={suitcss({ componentName, descendantName: 'stickers' })} >
            <Callout
              theme="sticker"
              targets={[item.eid]}
              context={getContext()}
              allowMultiple
            />
          </div>
        )}
        <Callout
          theme="content"
          size="xl"
          context={getContext()}
          targets={[item.eid]}
        />
      </div>
    );
  }

  renderFeatures() {
    const { item } = this.props;
    return (
      <div className={suitcss({ componentName, descendantName: 'features' })} >
        {item.features && item.features.map((props, index) => (
          <div className={suitcss({ componentName, descendantName: 'feature' })} key={index} >
            <Feature {...props} />
          </div>
        ))}

        {!item.features && (
          <div className={suitcss({ componentName, descendantName: 'feature' })} >
            <Copy raw embedded>{item.shortCopy}</Copy>
          </div>
        )}
      </div>
    );
  }

  renderPrices() {
    const { item, ui } = this.props;
    const basicFee = item.basicFee != null && toPriceObject(item.basicFee);
    const basicFeeStrike = item.basicFeeStrike && toPriceObject(item.basicFeeStrike);
    const postfix = getPaymentFeeDuration(item, ui, true);
    const copy = this.getContractCopy();
    return (
      <div className={suitcss({ componentName, descendantName: 'prices' })} >
        {basicFee && (
          <div className={suitcss({ componentName, descendantName: 'priceMonthly' })} >
            <div className={suitcss({ componentName, descendantName: 'unit', utilities: ['hidden', 'smBlock'] })}>
              <Unit
                postfix={postfix}
                price={basicFee}
                size="m"
              />
            </div>
            <div className={suitcss({ componentName, descendantName: 'unit', utilities: ['hidden', 'lBlock'] })}>
              <Unit
                postfix={postfix}
                price={basicFee}
                prefixPrice={basicFeeStrike}
                size="l"
              />
            </div>
          </div>
        )}
        {copy && (
          <div className={suitcss({ componentName, descendantName: 'copy' })} >
            {copy}
          </div>
        )}
      </div>
    );
  }

  renderButton({
    label, onClick, buttonFilled, disabled, element, href,
  }) {
    const htmlElement = element === 'a' ? null : element || 'div';
    return (
      <div className={suitcss({ componentName, descendantName: 'button' })} >
        <Link
          href={href}
          onClick={!disabled ? onClick : null}
          isDisabled={disabled}
          buttonFilled={buttonFilled}
          asButton
          element={htmlElement}
        >
          {label}
        </Link>
      </div>
    );
  }

  renderButtons() {
    const {
      item, onCancel, onSelect, onChange, isBookedByUser, ui, sitemap, isMultipleTariffsForUser,
    } = this.props;
    const {
      market,
      type,
      id,
      state,
      bookable,
      cancelable,
      canceled,
      contractRenewalWithSimOnly,
      contractRenewalWithHardwareOnly,
      buttonSubmit,
      possibleCancellationDate,
    } = item;
    const hint = this.getButtonsHint();
    const isTypeTariff = isTariffVO(item);
    const inProcess = state === VO_STATE_PROCESSING;
    const isBookedSingle = state === VO_STATE_BOOKEDSINGLE;
    const bookableOptionLabel = isBookedSingle ? ui.myTariffoptionBookAgain : ui.guiWordSelect;
    return (
      <div className={suitcss({ componentName, descendantName: 'buttons' })} >
        {cancelable && !canceled && possibleCancellationDate && isBookedByUser && (
          this.renderButton({
            label: ui.buttonGotoCancellation,
            onClick: () => onCancel(id),
            element: 'button',
          })
        )}
        {canceled && isTypeTariff && isBookedByUser && cancelable && (
          this.renderButton({
            label: ui.myTariffWithdrawCancellation,
            onClick: () => onCancel(id),
            element: 'button',
          })
        )}
        {!isTypeTariff && bookable && (
          this.renderButton({
            label: buttonSubmit || bookableOptionLabel,
            onClick: () => onSelect(id),
            buttonFilled: true,
          })
        )}
        {isTypeTariff && isTariffChangeable(item) && !isBookedByUser && (
          this.renderButton({
            label: ui.myTariffChange,
            onClick: () => onChange(id),
            buttonFilled: !contractRenewalWithHardwareOnly && !contractRenewalWithSimOnly,
          })
        )}
        {!isTypeTariff && cancelable && !canceled && isBookedByUser && (
          this.renderButton({
            label: isTypeTariff ? ui.myTariffDetailCancel : ui.myTariffoptionCancelAction,
            onClick: () => onCancel(id),
            disabled: inProcess,
          })
        )}
        {contractRenewalWithHardwareOnly && !isBookedByUser && (
          this.renderButton({
            label: ui.myTariffExtendInclusive,
            onClick: () => onSelect(id, true),
            buttonFilled: true,
          })
        )}
        {contractRenewalWithSimOnly && !isBookedByUser && (
          this.renderButton({
            label: ui.myTariffExtendExclusive,
            onClick: () => onSelect(id),
            buttonFilled: !contractRenewalWithHardwareOnly,
          })
        )}
        {market === MARKET_POSTPAID && type === VO_TYPE_TARIFF && isMultipleTariffsForUser && (
          this.renderButton({
            label: ui.myVvlChooseDifferentTariff,
            href: sitemap.MyDashboardRoute && `${sitemap.MyDashboardRoute.url}#MyOffersTariffs`,
            element: 'a',
          })
        )}
        {type === VO_TYPE_TARIFFOPTION && (
          this.renderButton({
            label: ui.myVvlChooseDifferentTariffoption,
            href: sitemap.MyDashboardRoute && `${sitemap.MyDashboardRoute.url}#MyOffersOptions`,
            element: 'a',
          })
        )}
        {market === MARKET_PREPAID && type === VO_TYPE_TARIFF && (
          this.renderButton({
            label: ui.myVvlChooseDifferentTariffoption,
            href: sitemap.MyDashboardRoute && `${sitemap.MyDashboardRoute.url}#MyOffersOptions`,
            element: 'a',
          })
        )}
        {hint && (
          <div className={suitcss({ componentName, descendantName: 'hint' })} >
            <Copy size="secondary" utilities={['colorGray100']} embedded>
              {hint}
            </Copy>
          </div>
        )}
      </div>
    );
  }

  renderFooter() {
    const { item, isBookedByUser, footer } = this.props;
    const documentKey = isBookedByUser ? 'subscription' : 'offer';
    const documents = item.documents && item.documents.filter(
      doc => !doc.showAt || doc.showAt.includes(documentKey));

    return (
      <div className={suitcss({ componentName, descendantName: 'footer' })} >
        {footer && (<Copy size="secondary" embedded raw >{footer}</Copy>)}
        {documents && !!documents.length && (
          <div className={suitcss({ componentName, descendantName: 'links' })} >
            {documents.map((doc, index) => (
              <div className={suitcss({ componentName, descendantName: 'link' })} key={index} >
                <Link to={doc.url} utilities={['textSizeSmall']} withoutArrow >
                  {doc.name}
                </Link>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }

  renderTariffFilter() {
    const { item, onChange } = this.props;
    return (
      <TariffFilter
        onChange={onChange}
        compact
        items={[item]}
        item={item}
      />
    );
  }

  render() {
    const { item, footer, isBookedByUser } = this.props;
    const isTypeTariff = isTariffVO(item);
    return (
      <Fragment>
        <section className={suitcss({ componentName, modifiers: ['myotelo', isTypeTariff && !isBookedByUser && 'bookTariff'] })} >
          {this.renderHeader()}
          <div className={suitcss({ componentName, descendantName: 'content' })} >
            {this.renderFeatures()}
            <div className={suitcss({ componentName, descendantName: 'subcontent' })} >
              {(!isTypeTariff || isBookedByUser) && this.renderPrices()}
              {(!isTypeTariff || isBookedByUser) && this.renderButtons()}
            </div>
          </div>
          {(footer || (item.documents && !!item.documents.length)) &&
          !(isTypeTariff && !isBookedByUser) && this.renderFooter()}
        </section>
        {isTypeTariff && !isBookedByUser && this.renderTariffFilter()}
        {isTypeTariff && !isBookedByUser && this.renderFooter()}
      </Fragment>
    );
  }
}

MyTariffDetails.propTypes = {
  item: subscriptionItemShape,
  market: PropTypes.string,
  footer: PropTypes.string,
  tariffMode: PropTypes.string,
  onSelect: PropTypes.func,
  onCancel: PropTypes.func,
  onChange: PropTypes.func,
  isBookedByUser: PropTypes.bool,
  isMultipleTariffsForUser: PropTypes.bool,
  sitemap: PropTypes.object,
  ui: PropTypes.shape({
    buttonGotoCancellation: PropTypes.string.isRequired,

    guiSymbolEuro: PropTypes.string.isRequired,
    guiWordMonthlyAbbr: PropTypes.string.isRequired,
    guiWordSingularAbbr: PropTypes.string.isRequired,

    myCancellationCancelContract: PropTypes.string.isRequired,
    myVvlChooseDifferentTariffoption: PropTypes.string.isRequired,
    myVvlChooseDifferentTariff: PropTypes.string.isRequired,
    myTariffExtendInclusive: PropTypes.string.isRequired,
    myTariffExtendExclusive: PropTypes.string.isRequired,
    myTariffCancellationUntil: PropTypes.string.isRequired,
    myTariffDetailCancel: PropTypes.string.isRequired,
    myTariffCancellationDate: PropTypes.string.isRequired,
    myTariffWithdrawCancellation: PropTypes.string.isRequired,
    myTariffWithdrawCancellationHint: PropTypes.string.isRequired,
    myTariffConsumptionUnit: PropTypes.string.isRequired,
    myTariffConsumptionVolume: PropTypes.string.isRequired,
    myTariffChange: PropTypes.string.isRequired,

    myTariffoptionBook: PropTypes.string.isRequired,
    myTariffoptionBookAgain: PropTypes.string.isRequired,
    myTariffoptionCancelNotPossible: PropTypes.string.isRequired,
    myTariffoptionCancelCondition: PropTypes.string.isRequired,
    myTariffoptionCancelConditionMonthly: PropTypes.string.isRequired,
    myTariffoptionCancelAction: PropTypes.string.isRequired,
    myTariffoptionBookSublinePuc: PropTypes.string.isRequired,
    myTariffoptionBookSubline: PropTypes.string.isRequired,
    myTariffoptionRuntimeMonthly: PropTypes.string.isRequired,
  }),
};

const mapStateToProps = ({ site }) => ({
  sitemap: site.sitemap,
});

export default compose(
  connect(mapStateToProps),
  connectUI(),
)(MyTariffDetails);
