/* global document */
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import raf from 'raf';
import debounce from 'just-debounce';
import { send } from '../../../actions/request/send';
import SvgRequest from '../../../model/requests/SvgRequest';

/**
 *
 * Deprecated Lottie Service - new Lottie Service in: ServiceLottieAnimation.js
 *
 */

class ServiceLottie extends PureComponent {

  constructor(props, context) {
    super(props, context);
    this.state = { Lottie: null };
    this.targets = [];
    this.rafId = null;
    this.updateTargets = debounce(this.updateTargets.bind(this), 1000, true);
  }

  componentDidMount() {
    require.ensure([], require => {
      this.setState({ Lottie: require('lottie-web/build/player/lottie_light.min') });
    }, 'lottie');
  }

  componentDidUpdate() {
    if (this.state.Lottie && !this.rafId) {
      this.tick(this.updateTargets);
    }
  }

  componentWillUnmount() {
    this.destroyTargets();
    if (this.rafId) {
      raf.cancel(this.rafId);
    }
  }

  tick(updater) {
    this.rafId = raf(() => {
      updater();
      this.tick(updater);
    });
  }

  updateTargets() {
    const targets = [...document.getElementsByClassName(this.props.classSelector)];
    if (!targets.length) {
      return;
    }
    this.destroyTargets();
    this.initTargets(targets);
  }

  initTargets(targets) {
    const {
      dispatch,
      configAttrSrc,
      configAttrLoop,
      configAttrSpeed,
      classSelector,
      classIsRegistered,
      classIsLoaded,
    } = this.props;
    const { Lottie } = this.state;
    this.targets = targets.map(async animationElement => {
      // retrieve the attributes from the Lottie div
      const animationUrl = animationElement.getAttribute(configAttrSrc);
      const loop = animationElement.getAttribute(configAttrLoop) !== '0';
      const speed = parseFloat(animationElement.getAttribute(configAttrSpeed));

      if (!animationUrl) {
        return null;
      }
      // fetch the Lottie data
      const animationData = (await dispatch(send(new SvgRequest(animationUrl)))).body.data;
      // clear container before starting animation
      animationElement.innerHTML = ''; // eslint-disable-line no-param-reassign
      // put the Lottie parameters into Lottie
      const animation = Lottie.loadAnimation({
        loop,
        speed: speed || 1,
        wrapper: animationElement,
        animationData,
      });
      animationElement.classList.remove(classSelector);
      animationElement.classList.add(classIsRegistered);
      animationElement.classList.add(classIsLoaded);
      return animation;
    }).filter(item => !!item);
  }

  destroyTargets() {
    this.targets.forEach(async animation => {
      (await animation).destroy();
    });
  }

  render() {
    return null;
  }
}

ServiceLottie.propTypes = {
  classSelector: PropTypes.string,
  configAttrSrc: PropTypes.string,
  configAttrLoop: PropTypes.string,
  configAttrSpeed: PropTypes.string,
  classIsLoaded: PropTypes.string,
  classIsRegistered: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
};

ServiceLottie.defaultProps = {
  classSelector: 'LottieAnimation',
  configAttrSrc: 'animationDataUrl',
  configAttrLoop: 'loop',
  configAttrSpeed: 'speed',
  classIsRegistered: 'LottieAnimationRegistered',
  classIsLoaded: 'is-loaded',
};

export default connect()(ServiceLottie);
