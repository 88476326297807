import { sendIfNecessary } from '../../actions/request/send';
import { getMsisdn } from '../../helpers/accessors';
import { UI_COLLECTION_ESIM_ACTIVATION } from '../../helpers/constants';
import ContractDataRequest from '../requests/ContractDataRequest';
import PageModule from './PageModule';
import component from '../../containers/account/eSim/MyESimActivation';

/**
 * The MyESimActivation module displays the different possibilities to activate the esim.
 * @see https://confluence.db-n.com/x/HPTc
 */
class MyESimActivationModule extends PageModule {
  constructor(moduleData) {
    super(component, moduleData);
  }

  prepareBeforeMount() {
    return async (dispatch, getState) => {
      await dispatch(sendIfNecessary(new ContractDataRequest(getMsisdn(getState()))));
    };
  }

  getRequiredUi() {
    return [UI_COLLECTION_ESIM_ACTIVATION];
  }
}

export default MyESimActivationModule;
