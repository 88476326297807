/* global navigator */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import copyToClipboard from 'copy-to-clipboard';
import oneLineTrim from 'common-tags/lib/oneLineTrim';
import ShareLinkContainerComposition
  from '../../components/compositions/shareLinkContainer/ShareLinkContainer';
import {
  hideDialog,
  showDialog,
  showNotification,
  showTemplateDialog,
} from '../../actions/page/dialog';
import {
  ICON_WHATSAPP_SHARE_BUTTON,
} from '../../helpers/constants';
import connectUI from '../../components/basics/ui/UIConnector';

class ShareLinkContainer extends PureComponent {
  constructor(...args) {
    super(...args);
    this.onCopyLink = this.onCopyLink.bind(this);
    this.onShareLink = this.onShareLink.bind(this);
    this.onOpenHintDialog = this.onOpenHintDialog.bind(this);
  }

  onCopyLink(text) {
    const { dispatch, ui } = this.props;
    const copiedToClipboard = copyToClipboard(text);
    if (copiedToClipboard) {
      dispatch(showNotification(ui.myFrdLinkCopied || ui.guiLinkCopied));
    }
  }

  async onShareLink(shareData) {
    try {
      if (navigator) await navigator.share(shareData);
    } catch (err) {
      this.onCopyLink(shareData.text);
    }
  }

  onOpenHintDialog() {
    const { dispatch, bottomHintDialog, ui } = this.props;
    if (!bottomHintDialog) return;
    if (bottomHintDialog.text && bottomHintDialog.title) {
      dispatch(
        showDialog({
          headline: bottomHintDialog.title,
          copy: bottomHintDialog.text,
          actions: [
            {
              label: bottomHintDialog.label || ui.guiWordAllright,
              action: () => {
                dispatch(hideDialog());
              },
            },
          ],
        }),
      );
    } else if (bottomHintDialog.id) {
      dispatch(showTemplateDialog(bottomHintDialog.id));
    }
  }

  shareButtons() {
    const { mobileShareItems, shareItems } = this.props;
    const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    const shareLinks = isMobileDevice && mobileShareItems ? mobileShareItems : shareItems;

    return shareLinks.map((item) => {
      switch (item.type) {
        case 'whatsApp':
          return {
            icon: ICON_WHATSAPP_SHARE_BUTTON,
            label: item.label,
            to: `https://api.whatsapp.com/send?text=${item.shareText}`,
            target: '_blank',
          };
        case 'mail':
          return {
            icon: '/icons/icon-mail-small.svg',
            label: item.label,
            to: oneLineTrim`mailto:?body=${item.shareText}${item.shareTitle ? `&subject=${item.shareTitle}` : ''}`,
          };
        case 'copyLink':
          return {
            icon: '/icons/icon-copy.svg',
            label: item.label,
            onClick: () => this.onCopyLink(item.shareText),
          };
        case 'nativeShare':
          return {
            icon: '/icons/icon-copy.svg',
            label: item.label,
            onClick: () =>
              this.onShareLink({
                text: item.shareText,
                url: item.shareUrl,
              }),
          };
        default:
          return {};
      }
    });
  }

  render() {
    const {
      title,
      subline,
      bottomHint,
      showLinks,
      utilities,
    } = this.props;

    return (
      <ShareLinkContainerComposition
        title={title}
        subline={subline}
        bottomHint={bottomHint}
        onOpenHintDialog={() => this.onOpenHintDialog()}
        links={this.shareButtons()}
        showLinks={showLinks}
        utilities={utilities}
      />
    );
  }
}

ShareLinkContainer.defaultProps = {};

ShareLinkContainer.propTypes = {
  utilities: PropTypes.array,
  title: PropTypes.string,
  subline: PropTypes.string,
  bottomHint: PropTypes.string,
  bottomHintDialog: PropTypes.object,
  dispatch: PropTypes.func,
  showLinks: PropTypes.bool,
  mobileShareItems: PropTypes.array,
  shareItems: PropTypes.array,
  ui: PropTypes.object,
};

const mapDispatchToProps = dispatch => ({ dispatch });

export default compose(
  connect(mapDispatchToProps),
  connectUI(),
)(ShareLinkContainer);

