import React from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';

import Sitemap from '../navigation/Sitemap';
import MetaNavigation from '../navigation/MetaNavigation';
import FooterLegals from './FooterLegals';

function FooterMain(props) {
  return (
    <footer
      className={suitcss({
        componentName: 'FooterMain',
        modifiers: [props.isContractRenewal && 'extraPadding'],
      })}
    >
      {!props.withoutSitemap && (
        <div
          className={suitcss({
            componentName: 'FooterMain',
            descendantName: 'nav',
          })}
        >
          <Sitemap items={props.sitemapItems} />
        </div>
      )}
      <div
        className={suitcss({
          componentName: 'FooterMain',
          descendantName: 'meta',
        })}
      >
        <MetaNavigation items={props.metaItems} />
      </div>
      {!props.withoutLegals && (
        <div
          className={suitcss({
            componentName: 'FooterMain',
            descendantName: 'legals',
          })}
        >
          <FooterLegals />
        </div>
      )}
    </footer>
  );
}

FooterMain.propTypes = {
  sitemapItems: PropTypes.array.isRequired,
  metaItems: PropTypes.array.isRequired,
  withoutSitemap: PropTypes.bool,
  withoutLegals: PropTypes.bool,
  isContractRenewal: PropTypes.bool,
};

export default FooterMain;
