import PageModule from './PageModule';
import component from '../../containers/consentHandling/ConsentHandlingForm';
import { UI_COLLECTION_GDPR } from '../../helpers/constants';
import { sendIfNecessary } from '../../actions/request/send';
import UmidInfoRequest from '../requests/UmidInfoRequest';

/**
 * Form for consent handling.
 * @see https://confluence.db-n.com/x/_r-c
 */
class ConsentHandlingModule extends PageModule {
  constructor(moduleData) {
    super(component, moduleData);
  }

  /**
   * @override
   */
  prepareBeforeMount() {
    return async (dispatch) => {
      await dispatch(sendIfNecessary(new UmidInfoRequest()));
    };
  }
  /**
   * @override
   */
  getRequiredUi() {
    return [UI_COLLECTION_GDPR];
  }
}

export default ConsentHandlingModule;
