import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { bindQueryParams } from '../../helpers/url';
import { isTariffVO } from '../../helpers/entity';
import QueueableRequest from '../../model/requests/QueueableRequest';
import {
  MARKET_POSTPAID,
  VO_TYPE_TARIFF,
  VO_TYPE_TARIFFOPTION,
  VO_TYPE_PROMOTION,
  QUERY_INTERNAL_ID,
  SCOPE_MYTERMINATION,
  ID_MY_OFFERS_OPTIONS,
  ID_MY_OFFERS_TARIFFS,
  ID_MY_OFFERS_PROMOTIONS,
  TAG_MY_TARIFF,
} from '../../helpers/constants';
import * as statusTariffActions from '../../actions/dialog/statusTariffActions';
import * as contractRenewalActions from '../../actions/dialog/contractRenewalActions';
import * as registryActions from '../../actions/request/registry';
import GlobalSection from '../../components/basics/global/GlobalSection';
import OffersList from '../../components/compositions/account/offers/OffersList';
import ContentLoader from '../../components/compositions/content/ContentLoader';
import { blockContentBasedOnUserScope } from '../../helpers/scope';
import { createNormalizeTargetEntitiesSelector } from '../../selectors/entity';

class MyOffers extends PureComponent {

  componentDidMount() {
    const {
      market, fetchTariffs, fetchTariffOptions, fetchMyPromotions, fetchTags,
    } = this.props;
    if (market === MARKET_POSTPAID) {
      fetchTariffs({ priority: QueueableRequest.PRIO_10 });
    }
    fetchTags({ priority: QueueableRequest.PRIO_40 });
    fetchTariffOptions({ priority: QueueableRequest.PRIO_30 });
    fetchMyPromotions({ priority: QueueableRequest.PRIO_20 });
  }

  renderList({
    id,
    headline,
    subline,
    copy,
    items,
    theme,
    isLoaded,
    withoutProgressbar,
    isLoadingHeight,
    tags,
    renewalFee,
    isTariffRenewable,
    tariffList,
    additionalHeader,
  }) {
    const { moduleId, onSelect, onChange } = this.props;
    const usedTags = isLoaded && tags && tags.filter(
      tag => items.some(item => item.tags && item.tags.includes(tag.id)),
    );
    const withHighlights = usedTags && usedTags.some(
      tag => tag.id !== TAG_MY_TARIFF && tag.id === tags[1].id,
    );
    return (
      <GlobalSection id={id} theme={theme} layout="contained">
        <ContentLoader
          isLoaded={isLoaded}
          withoutProgressbar={withoutProgressbar}
          height={isLoadingHeight}
        >
          {isLoaded && (
            <OffersList
              headline={headline}
              subline={subline}
              copy={copy}
              theme={theme}
              items={items}
              tags={usedTags}
              withHighlights={withHighlights}
              moduleId={`${moduleId}-${id}`}
              onSelect={onSelect}
              onChange={onChange}
              renewalFee={renewalFee}
              isTariffRenewable={isTariffRenewable}
              tariffList={tariffList}
              additionalHeader={additionalHeader}
            />
          )}
        </ContentLoader>
      </GlobalSection>
    );
  }

  render() {
    const {
      market,
      ui,
      tags,
      tariffs,
      tariffOptions,
      promotions,
      params,
      scope,
      earlyContractRenewalFee,
      tariffsTariffChange,
    } = this.props;

    if (blockContentBasedOnUserScope(scope, SCOPE_MYTERMINATION)) {
      return null;
    }

    const filteredPromotions = promotions && promotions.filter(promo => promo.urlDetails);
    const isPostPaid = market === MARKET_POSTPAID;
    const withTariffs = isPostPaid && tariffs && tariffs.length > 0;
    const withTariffOptions = tariffOptions && tariffOptions.length > 0;
    const withPromotions = filteredPromotions && filteredPromotions.length > 0;
    const isTariffRenewable = tariffs && tariffs.some(
      vo => vo.contractRenewalWithHardwareOnly || vo.contractRenewalWithSimOnly,
    );
    const nothingBookable =
      withTariffs === false && withTariffOptions === false && withPromotions === false;
    if (nothingBookable) { return null; }
    const isLoaded = tags && (withTariffs || withTariffOptions || withPromotions);
    const isWithHardware = (
      tariffs
      && tariffs.some(tariff => tariff.contractRenewalWithHardwareOnly)
    );
    const tariffsCopyVVL = isWithHardware
      ? params.tariffsCopyRenew
      : params.tariffsCopyRenewSim;
    return (
      <div>
        <ContentLoader isLoaded={isLoaded} height={380}>
          {(!tariffOptions || withTariffOptions) && (
            this.renderList({
              id: ID_MY_OFFERS_OPTIONS,
              headline: params.optionsHeadline,
              copy: params.optionsCopy,
              items: tariffOptions,
              theme: (withTariffs || withPromotions) ? 'light' : null,
              isLoaded: (isPostPaid || tags) && withTariffOptions,
              isLoadingHeight: 380,
              tags,
            })
          )}
          {((!tariffs && isPostPaid) || withTariffs) && (
            this.renderList({
              id: ID_MY_OFFERS_TARIFFS,
              headline: isTariffRenewable ?
                params.tariffsHeadlineRenew : params.tariffsHeadline,
              subline: ui.tariffsListSubline,
              copy: isTariffRenewable ? tariffsCopyVVL : params.tariffsCopyChange,
              additionalHeader: !isTariffRenewable ? {
                headline: params.tariffsHeadlineRenew,
                copy: ui.renewContractHintUnableSl,
              } : null,
              items: isTariffRenewable ? tariffs : tariffsTariffChange,
              theme: 'light',
              isLoaded: tags && withTariffs,
              isLoadingHeight: 380,
              withoutProgressbar: !tariffOptions,
              tags,
              renewalFee: isTariffRenewable && earlyContractRenewalFee,
              isTariffRenewable,
              tariffList: true,
            })
          )}
          {(!filteredPromotions || withPromotions) && (
            this.renderList({
              id: ID_MY_OFFERS_PROMOTIONS,
              headline: params.promotionsHeadline,
              copy: params.promotionsCopy,
              items: filteredPromotions,
              theme: withTariffs ? 'light' : null,
              isLoaded: withPromotions,
              isLoadingHeight: 240,
              withoutProgressbar: !tariffOptions || (!tariffs && isPostPaid),
            })
          )}
        </ContentLoader>
      </div>
    );
  }
}

MyOffers.propTypes = {
  market: PropTypes.string,
  tags: PropTypes.array,
  tariffs: PropTypes.array,
  tariffsTariffChange: PropTypes.array,
  tariffOptions: PropTypes.array,
  promotions: PropTypes.array,
  params: PropTypes.object,
  ui: PropTypes.object.isRequired,
  fetchTariffs: PropTypes.func.isRequired,
  fetchTariffOptions: PropTypes.func.isRequired,
  fetchMyPromotions: PropTypes.func.isRequired,
  fetchTags: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  moduleId: PropTypes.string.isRequired,
  earlyContractRenewalFee: PropTypes.number,
};

/*
  Inject props to conform with our codebase
 */
const normalizeItems = (type, items, urlDetails, internalIdMap) => (
  items && items.map(item => {
    const normalizedItem = {
      type,
      ...item,
      urlDetails: urlDetails ? bindQueryParams(urlDetails, {
        [QUERY_INTERNAL_ID]: item.id,
      }) : item.details,
    };

    if (!normalizedItem.eid && internalIdMap) {
      normalizedItem.eid = internalIdMap[item.id];
    }

    return normalizedItem;
  }));

const makeMapStateToProps = () => {
  const myTariffsSelector = createNormalizeTargetEntitiesSelector({ style: 'strike', context: 'changeTariff' });
  const myOptionsSelector = createNormalizeTargetEntitiesSelector({ style: 'strike', context: 'bookTariffoption' });
  const tariffEntitiesSelector = state => state.entities.tariffVO;
  const tariffOptionEntitiesSelector = state => state.entities.tariffOptionVO;
  const promotionEntitiesSelector = state => state.entities.promotionVO;
  const bookableTariffsSelector = state => state.user.bookableTariffs;
  const bookableTariffOptionsSelector = state => state.user.bookableTariffOptions;
  const bookablePromotionsSelector = state => state.user.bookablePromotions;
  const sitemapSelector = state => state.site.sitemap;
  const internalIdMapSelector = state => state.site.internalIdMap;
  const tagsSelector = state => (
    state.site.tags ? [
      {
        id: TAG_MY_TARIFF,
        headline: state.ui.myVvlRenewOwnContract,
        iconWeb: state.ui.myVvlRenewOwnContractIcon,
      },
      ...state.site.tags,
    ] : null
  );


  const myTariffSelector = state => state.user.tariffSummary &&
    state.user.tariffSummary.subscriptionItems.find(isTariffVO);

  const tariffsSelector = createSelector(
    myTariffSelector,
    bookableTariffsSelector,
    tariffEntitiesSelector,
    sitemapSelector,
    internalIdMapSelector,
    (myTariff, bookables, entities, sitemap, internalIdMap) => {
      const items = bookables && normalizeItems(
        VO_TYPE_TARIFF,
        bookables.map(id => entities[id]),
        sitemap.MyTariffDetailsRoute.url,
        internalIdMap,
      );
      const myTariffIndex = items && items.findIndex(vo => vo.id === (myTariff || {}).id);
      if (items && myTariffIndex >= 0) {
        items[myTariffIndex].tags = [TAG_MY_TARIFF];
      }
      return items || null;
    },
  );

  const tariffOptionsSelector = createSelector(
    bookableTariffOptionsSelector,
    tariffOptionEntitiesSelector,
    sitemapSelector,
    internalIdMapSelector,
    (bookables, entities, sitemap, internalIdMap) => (
      bookables ? normalizeItems(
        VO_TYPE_TARIFFOPTION,
        bookables.map(id => entities[id]),
        sitemap.MyTariffOptionDetailsRoute.url,
        internalIdMap,
      ) : null
    ),
  );

  const promotionsSelector = createSelector(
    bookablePromotionsSelector,
    promotionEntitiesSelector,
    (bookables, entities) => (
      bookables ? normalizeItems(
        VO_TYPE_PROMOTION,
        bookables.map(id => entities[id]),
      ) : null
    ),
  );

  return (state) => ({
    market: state.user.market,
    earlyContractRenewalFee: state.user.contractData &&
      state.user.contractData.earlyContractRenewalFee,
    tags: tagsSelector(state),
    tariffs: tariffsSelector(state),
    tariffsTariffChange: myTariffsSelector(state, tariffsSelector(state)),
    tariffOptions: myOptionsSelector(state, tariffOptionsSelector(state)),
    promotions: promotionsSelector(state),
    ui: state.ui,
    scope: state.user.scope,
  });
};

const mapDispatchToProps = {
  fetchTariffs: registryActions.fetchTariffs,
  fetchTariffOptions: registryActions.fetchTariffOptions,
  fetchMyPromotions: registryActions.fetchMyPromotions,
  fetchTags: registryActions.fetchTags,
  bookOption: statusTariffActions.bookOption,
  startContractRenewalFromTariff: contractRenewalActions.startContractRenewalFromTariff,
  showTariffChangeDialog: contractRenewalActions.showTariffChangeDialog,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const {
    bookOption,
    startContractRenewalFromTariff,
    showTariffChangeDialog,
  } = dispatchProps;
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onSelect: (vo, isHardwareRequired) => {
      if (vo.type === VO_TYPE_TARIFF) {
        startContractRenewalFromTariff(vo.id, isHardwareRequired);
      } else if (vo.type === VO_TYPE_TARIFFOPTION) {
        bookOption(vo);
      }
    },
    onChange: (vo) => showTariffChangeDialog(vo),
  };
};


export default compose(
  connect(makeMapStateToProps, mapDispatchToProps, mergeProps),
)(MyOffers);
