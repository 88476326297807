import React from 'react';
import suitcss from '../../../helpers/suitcss';

import SvgLoader from '../media/MediaSvgLoader';

const componentName = 'UIContentSpinner';
const UIContentSpinner = () => (
  <div className={suitcss({ componentName })} >
    <SvgLoader path="/icons/content-spinner.svg" />
  </div>
);

UIContentSpinner.propTypes = {};

export default UIContentSpinner;
