import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';
import TextHeadline from '../../basics/text/TextHeadline';
import Copy from '../../basics/text/TextCopy';
import { getItem, setItem, removeItem, STORAGE_TYPE_COOKIE } from '../../../helpers/storage';
import { STORAGE_KEY_CONSENT_INQUIRY_DIALOG } from '../../../helpers/constants';

class ConsentsInquiryDialog extends PureComponent {
  componentDidMount() {
    const cookie = getItem(STORAGE_TYPE_COOKIE, STORAGE_KEY_CONSENT_INQUIRY_DIALOG);

    if (!cookie) {
      setItem(STORAGE_TYPE_COOKIE, STORAGE_KEY_CONSENT_INQUIRY_DIALOG, true);
    }
  }

  componentWillUnmount() {
    removeItem(STORAGE_TYPE_COOKIE, STORAGE_KEY_CONSENT_INQUIRY_DIALOG);
  }

  render() {
    const { headline, subline, consents } = this.props;
    return (
      <div className={suitcss({}, this)}>
        <div className={suitcss({ descendantName: 'layer' }, this)}>
          <div className={suitcss({ descendantName: 'content' }, this)}>
            <TextHeadline size="m" utilities={['marginBottom']} embedded>{headline}</TextHeadline>
            {subline &&
              <Copy
                className={suitcss({ descendantName: 'copy' }, this)}
                utilities={['marginBottom']}
                embedded
                raw
              >
                {subline}
              </Copy>
            }
            {consents.map((consent) => (
              <Copy key={consent.hash} raw>
                {consent.text}
              </Copy>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

ConsentsInquiryDialog.propTypes = {
  headline: PropTypes.string,
  subline: PropTypes.string,
  consents: PropTypes.array.isRequired,
};

export default connect()(ConsentsInquiryDialog);
