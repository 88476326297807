import React from 'react';
import PropTypes from 'prop-types';

import suitcss from '../../../helpers/suitcss';
import connectUniqueId from '../service/UniqueIdConnector';

import { hintShape, metaShape } from '../../../propTypes/field';

import FieldWrapper from './FieldWrapper';
import TextRaw from '../text/TextRaw';

const componentName = 'FieldRadio';
function FieldRadio(props) {
  const {
    input,
    label,
    checked,
    uniqueId,
    inputValue,
    asToggleButton,
    customLabelEnhancement,
  } = props;
  const inputProps = Object.assign({}, input, {
    value: inputValue != null ? inputValue : input.value,
  });

  // @todo raw modifier is missing
  return (
    <FieldWrapper componentName={componentName} {...props} >
      <div className={suitcss({ componentName, descendantName: 'inner' })} >
        <input
          className={suitcss({
            componentName,
            descendantName: 'input',
          })}
          id={uniqueId}
          type="radio"
          checked={checked}
          {...inputProps}
        />
        <label
          className={suitcss({
            componentName,
            descendantName: 'label',
            modifiers: [asToggleButton && 'asToggleButton'],
          })}
          htmlFor={uniqueId}
        >
          {label &&
          <div className={suitcss({ componentName, descendantName: 'labelInner' })}>
            <TextRaw>{label}</TextRaw>
          </div>}
          {customLabelEnhancement}
        </label>
      </div>
    </FieldWrapper>
  );
}

FieldRadio.propTypes = {
  checked: PropTypes.bool,
  input: PropTypes.object,
  inputValue: PropTypes.string,
  uniqueId: PropTypes.string.isRequired,
  asToggleButton: PropTypes.bool,
  type: PropTypes.string,
  label: PropTypes.string,
  hint: hintShape,
  meta: metaShape,
  raw: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  utilities: PropTypes.array,
  theme: PropTypes.oneOf(['default', 'compact']),
  customLabelEnhancement: PropTypes.object,
};

FieldRadio.defaultProps = {
  meta: {},
  input: {},
  raw: false,
  checked: false,
  theme: 'default',
};

export default connectUniqueId('frb')(FieldRadio);
