import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as requestActions from '../../../actions/request/registry';
import { showESimStatusRefresh } from '../../../actions/user/eSim';
import { MARKET_PREPAID } from '../../../helpers/constants';
import suitcss from '../../../helpers/suitcss';
import Copy from '../../../components/basics/text/TextCopy';

const MILLISECONDS_TIME_SECOND = 1000;
const SECONDS_TIME_MINUTE = 60;

class MyESimStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      remainingTime: SECONDS_TIME_MINUTE,
      isLoading: false,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(showESimStatusRefresh(false));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.statusRefresh !== this.props.statusRefresh
      && this.props.statusRefresh && this.isEnabledToPush()) {
      this.startCountDownInterval();
      this.startRefreshInterval();
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(showESimStatusRefresh(false));
    if (this.countDownInterval) clearInterval(this.countDownInterval);
    if (this.statusRefreshInterval) clearInterval(this.statusRefreshInterval);
  }

  /* onStatusRefresh() {
    this.getESImStatus();
    if (this.statusRefreshInterval) clearInterval(this.statusRefreshInterval);
    this.setState({ remainingTime: SECONDS_TIME_MINUTE });
    this.startRefreshInterval();
  } */

  async getESImStatus() {
    const { fetchESimStatus } = this.props;
    this.setState({ isLoading: true });
    try {
      await fetchESimStatus();
    } finally {
      this.setState({ isLoading: false });
    }
  }

  isEnabledToPush() {
    const { user } = this.props;
    return user.market !== MARKET_PREPAID;
  }

  startCountDownInterval() {
    this.countDownInterval = setInterval(() => {
      this.setState((prevState) => ({ remainingTime: prevState.remainingTime - 1 }));
    }, MILLISECONDS_TIME_SECOND);
  }

  startRefreshInterval() {
    this.statusRefreshInterval = setInterval(() => {
      this.setState({ remainingTime: SECONDS_TIME_MINUTE });
      this.getESImStatus();
    }, MILLISECONDS_TIME_SECOND * SECONDS_TIME_MINUTE);
  }

  render() {
    const { statusRefresh, ui } = this.props;
    const { remainingTime } = this.state;
    const replacedBannerText = ui.myEsimStatusBannerText.replace('{TIME}', `${remainingTime}`);
    return (
      <div
        className={suitcss(
          {
            states: [statusRefresh && 'shown'],
            utilities: ['paddingTop', 'paddingBottomS', 'paddingHS', 'alignCenter'],
          },
          this,
        )}
      >
        <Copy size="secondary">{replacedBannerText}</Copy>
        {/* Refresh Button */}
        {/* <Link
          className={suitcss(
            {
              descendantName: 'refreshButton',
              utilities: ['bgColorInverted'],
            },
            this,
          )}
          icon="/icons/icon-refresh-black.svg"
          color="dark"
          element="button"
          onClick={() => this.onStatusRefresh()}
          asButton
          buttonFilled
          withoutArrow
          highlight
          isLoading={isLoading}
        >
          {ui.myEsimStatusBannerCta}
        </Link> */}
      </div>
    );
  }
}

MyESimStatus.propTypes = {
  statusRefresh: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  ui: PropTypes.shape({
    myEsimStatusBannerText: PropTypes.string,
    myEsimStatusBannerCta: PropTypes.string,
  }),
  fetchESimStatus: PropTypes.func,
  user: PropTypes.object,
};

MyESimStatus.defaultProps = {
  statusRefresh: false,
};

const mapDispatchToProps = (dispatch) => ({
  fetchESimStatus: () => dispatch(requestActions.fetchSimCardESimStatus(null, true)),
  dispatch,
});

const mapStateToProps = (state) => {
  return {
    statusRefresh: state.user.eSim.statusRefresh,
    ui: state.ui,
    user: state.user,
    lifecycle: state.lifecycle,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MyESimStatus);
