import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import suitcss from '../../../helpers/suitcss';
import Chat from '../chat/Chat';
import Headline from '../../basics/text/TextHeadline';
import Copy from '../../basics/text/TextCopy';
import Link from '../../basics/text/TextLink';
import Image from '../../basics/media/MediaImage';
import { getBundlePaymentFee, getBundleSinglePaymentFee } from '../../../helpers/entity';
import { toFullCurrency } from '../../../helpers/money';
import { shape as tariffShape, CATEGORY_POSTPAID } from '../../../propTypes/tariff';
import matchMediaConnector from '../../../containers/service/ServiceMatchMedia';
import { showGiveMeYourPriceChatDialog } from '../../../actions/dialog/misc';
import MediaSvgLoader from '../../basics/media/MediaSvgLoader';
import { scrollToHash } from '../../../helpers/navigation';
import { ID_TARIFF_DETAILS, ID_HARDWARE_DETAILS } from '../../../helpers/constants';

const componentName = 'GiveMeYourPrice';

class GiveMeYourPrice extends PureComponent {
  renderBulletPoints() {
    const { dataVolumeChoose } = this.props;
    return dataVolumeChoose.bulletPoints.map((point) =>
      <li className="u-elementCheckmarkBefore" key={point}>{point}</li>);
  }
  renderTeaser() {
    const { animationDataUrl, dataVolumeChoose } = this.props;
    return (
      <div className={suitcss({ componentName, descendantName: 'content' })} >
        <div className={suitcss({ componentName, descendantName: 'image' })} >
          <MediaSvgLoader path={animationDataUrl} />
        </div>
        <div className={suitcss({ componentName, descendantName: 'text' })} >
          <Headline size="m" inverted raw>{dataVolumeChoose.headline}</Headline>
          <ul className={suitcss({ componentName, descendantName: 'list' })}>
            {this.renderBulletPoints()}
          </ul>
        </div>
      </div>
    );
  }

  renderContent() {
    const { tariff, hardware, accessory, image, ui } = this.props;
    const paymentFee = hardware
      ? getBundlePaymentFee({ tariff, hardware })
      : tariff.paymentFee;
    const singlePaymentFee = hardware
      ? getBundleSinglePaymentFee({ tariff, hardware })
      : tariff.singlePaymentFee;
    const paymentCopy = `${ui.guiRegularPrice} ${toFullCurrency(paymentFee)} ${ui.guiWordMonthlyAbbr}`;
    const singlePaymentCopy = `${ui.guiFeeSingularPayment} ${toFullCurrency(singlePaymentFee)}`;
    const contractTermText = tariff.category === CATEGORY_POSTPAID && ui.guiContract24Months;
    return (
      <div className={suitcss({ componentName, descendantName: 'content' })} >
        <div className={suitcss({ componentName, descendantName: 'image' })} >
          <Image {...image} />
        </div>
        <div className={suitcss({ componentName, descendantName: 'text' })}>
          <Headline size="m" inverted embedded raw>
            {`${tariff.type} <strong><em>${tariff.name}</em></strong>`}
          </Headline>
          {hardware && (<Headline size="m" inverted embedded>+ {hardware.name}</Headline>)}
          {accessory && (<Headline size="m" inverted embedded>+ {accessory.name}</Headline>)}
          {!hardware && (<Copy inverted embedded raw>{tariff.copy}</Copy>)}
          <div className={suitcss({ componentName, descendantName: 'details' })} >
            <Copy inverted embedded>{paymentCopy}</Copy>
            {contractTermText && (
              <Copy utilities={['colorGray100']} inverted embedded>{contractTermText}</Copy>
            )}
            <Copy utilities={['colorGray100']} inverted embedded>{singlePaymentCopy}</Copy>
          </div>
          <div className={suitcss({ componentName, descendantName: 'links' })} >
            <div className={suitcss({ componentName, descendantName: 'link' })} >
              <Link to={null} onClick={() => scrollToHash(`#${ID_TARIFF_DETAILS}`)}>{ui.btnTariffDetails}</Link>
            </div>
            {hardware && (
              <div className={suitcss({ componentName, descendantName: 'link' })} >
                <Link to={null} onClick={() => scrollToHash(`#${ID_HARDWARE_DETAILS}`)}>{ui.btnHardwareDetails}</Link>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {
      headline,
      subline,
      copy,
      user,
      bot,
      ui,
      isMediaS,
      dispatch,
      tariff,
    } = this.props;
    return (
      <div className={suitcss({ componentName })} >
        <header className={suitcss({ componentName, descendantName: 'header' })}>
          <Headline size="xxl" inverted embedded raw>{headline}</Headline>
          {subline && (
            <Headline size="xs" utilities={['lowercase']} inverted embedded raw>{subline}</Headline>
          )}
          <Copy inverted embedded raw>{copy}</Copy>
        </header>
        {tariff && this.renderContent()}
        {!tariff && this.renderTeaser()}
        {isMediaS && (
          <div className={suitcss({ componentName, descendantName: 'startButton' })}>
            <Link
              element="button"
              asButton
              buttonFilled
              onClick={() => dispatch(showGiveMeYourPriceChatDialog(user, bot))}
            >
              {ui.btnStart}
            </Link>
          </div>
        )}
        {!isMediaS && (
          <Chat
            className={suitcss({ componentName, descendantName: 'chat' })}
            chatBot={bot}
            user={user}
          />
        )}
        {!tariff && <div className={suitcss({ componentName, descendantName: 'footer' })} />}
      </div>
    );
  }
}

GiveMeYourPrice.propTypes = {
  headline: PropTypes.string.isRequired,
  subline: PropTypes.string,
  copy: PropTypes.string.isRequired,
  image: PropTypes.object,
  tariff: tariffShape,
  hardware: PropTypes.object,
  accessory: PropTypes.object,
  user: PropTypes.object,
  bot: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  animationDataUrl: PropTypes.string.isRequired,
  ui: PropTypes.shape({
    btnLabelDecline: PropTypes.string.isRequired,
    btnLabelExit: PropTypes.string.isRequired,
  }).isRequired,
  isMediaS: PropTypes.bool,
  dataVolumeChoose: PropTypes.object.isRequired,
};

export default matchMediaConnector(['isMediaS'])(GiveMeYourPrice);
