import {
  registerField,
  change,
  startSubmit,
  stopSubmit,
  touch,
} from 'redux-form';

import { ERROR_DISPLAY_STYLE_FORM } from '../helpers/constants';
import { log } from './tracking/event';

import { ERROR_TYPE_FORM_ERROR } from '../config/errorCodes';

import UnknownSubmitError from '../model/errors/UnknownSubmitError';
import AbstractError from '../model/errors/AbstractError';
import FormValidationError from '../model/errors/FormValidationError';
import ApiError from '../model/errors/ApiError';
import ErrorLogEntry from '../model/logging/ErrorLogEntry';

/**
 * Registers and changes a field in one go. Just changing a field without
 * registering it first will cause issues later when reinitializing a form.
 * @param {string} formName - the name of the form
 * @param {string} fieldName - the name of the field to set
 * @param {*} fieldValue - the value to set the field to.
 */
export const setField = (formName, fieldName, fieldValue) => (dispatch) => {
  dispatch(registerField(formName, fieldName, 'Field'));
  dispatch(change(formName, fieldName, fieldValue));
};

/**
 * Will dispatch a redux-form error that will be displayed above the form.
 *
 * @param {string} formName
 * @param {FormValidationError} error
 */
export const showFormError = (formName, formStepName, error) => (dispatch, getState) => {
  const state = getState();
  const { ui } = state;
  const noError = error === false;
  // we do not work directly with the error passed as argument;
  // it might not have the right type or needs to be converted.
  let _error;
  if (error instanceof AbstractError) {

    if (error instanceof ApiError && error.getDetails().type === ERROR_TYPE_FORM_ERROR) {
      _error = FormValidationError.createFromApiError(error, ui);
    } else {
      _error = error;
    }

  } else if (typeof error === 'string') {
    _error = new AbstractError(error); // we assume the string is a full-error-code

  } else if (typeof error === 'object' && error.fullCode) {
    _error = new AbstractError(error.fullCode);
  }

  if (!_error) {
    _error = new UnknownSubmitError(error);
  }

  // error data passed to redux-form
  let errors = { _error };

  if (noError) {
    errors = {};
  }

  if (_error instanceof FormValidationError) {
    errors = { ...errors, ...(_error.getFieldErrors()) };
  }

  // we need to issue a startsubmit event, otherwise redux-form won't show any errors
  dispatch(startSubmit(formName));

  dispatch(touch(formName, Object.keys(errors)));

  dispatch(stopSubmit(formName, errors));

  if (noError) {
    return;
  }

  // enrich error context
  _error.getContext().setForm(formName, formStepName);

  // @todo this is actually not what is displayed; move this into the FormBlockError!
  const message = _error.getVerbalization(ui);
  dispatch(log(new ErrorLogEntry(_error, message, ERROR_DISPLAY_STYLE_FORM)));
};
