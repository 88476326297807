import QueueableRequest from './QueueableRequest';
import { EMAIL_REGISTRATION_ENDPOINT } from '../../config/api';
import { REQUEST_METHOD_POST } from '../../helpers/constants';

export default class EmailRegistrationRequest extends QueueableRequest {
  constructor(payload) {
    super(EMAIL_REGISTRATION_ENDPOINT, {
      method: REQUEST_METHOD_POST,
      payload,
    });
  }
}
