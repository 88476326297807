import QueueableRequest from './QueueableRequest';
import { PRESALES_CANCELLATION_ENDPOINT } from '../../config/api';
import { REQUEST_METHOD_POST } from '../../helpers/constants';

export default class PresalesCancellationRequest extends QueueableRequest {
  constructor(payload) {
    super(PRESALES_CANCELLATION_ENDPOINT, {
      method: REQUEST_METHOD_POST,
      payload,
    });
  }
}
