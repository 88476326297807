import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../../helpers/suitcss';
import Accordion from '../../../basics/accordion/Accordion';
import AccordionSection from '../../../basics/accordion/AccordionSection';
import Headline from '../../../basics/text/TextHeadline';

const componentName = 'MyESimActivation';

class MyESimManuallyContent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isAccordionOpen: false,
    };
    this.onAccordionClick = this.onAccordionClick.bind(this);
  }

  onAccordionClick(open) {
    this.setState({ isAccordionOpen: open });
  }


  render() {
    const {
      ui,
      eSimData,
      children,
      methodDescription,
    } = this.props;
    const { information } = eSimData;
    return (
      <Fragment>
        <div
          className={suitcss(
            {
              componentName,
              descendantName: 'sectionTop',
              utilities: ['paddingBottomS'],
            },
          )}
        >
          <div>
            <Headline embedded size="s" utilities={['weightBold']}>
              {ui.myEsimActivationManuallyHeadline}
            </Headline>
            {methodDescription()}
          </div>
        </div>
        <div className={suitcss({ componentName, descendantName: 'sectionBottom' }, this)}>
          {!!Object.keys(information).length && (
            <Accordion>
              <AccordionSection
                label=""
                embedded
                centered
                theme="primary"
                onClick={this.onAccordionClick}
                open={this.state.isAccordionOpen}
              >
                {children}
              </AccordionSection>
            </Accordion>
          )}
        </div>
      </Fragment>
    );
  }
}

MyESimManuallyContent.propTypes = {
  ui: PropTypes.object.isRequired,
  eSimData: PropTypes.object.isRequired,
  methodDescription: PropTypes.func.isRequired,
  children: PropTypes.node,
};

export default MyESimManuallyContent;
