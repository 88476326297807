import { UI_COLLECTION_VVL } from '../../helpers/constants';
import PageModule from './PageModule';
import component from '../../containers/account/MyOffers';

/**
 * Creates a list of tariffs, tariff options and any offers (bundles) from which the user can book
 * @see https://confluence.db-n.com/x/zJTr
 */
class MyOffersModule extends PageModule {
  constructor(moduleData) {
    super(component, moduleData);
  }

  /**
   * @override
   */
  getRequiredUi() {
    return [UI_COLLECTION_VVL];
  }
}

export default MyOffersModule;

