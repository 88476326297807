import QueueableRequest from './QueueableRequest';
import { ACTIVATION_CHECK_ENDPOINT } from '../../config/api';
import { REQUEST_METHOD_POST } from '../../helpers/constants';
// import { createFakeResponse } from '../ApiResponse';

export default class SimStatusRequest extends QueueableRequest {
  constructor(payload) {
    super(ACTIVATION_CHECK_ENDPOINT, {
      method: REQUEST_METHOD_POST,
      payload,
    });
  }

  /* getFakeResponse() {
    return createFakeResponse(response);
  } */
}
