import PropTypes from 'prop-types';
import {
  VO_TYPE_TARIFF,
  VO_TYPE_TARIFFOPTION,
  VO_TYPE_HARDWARE,
  VO_TYPE_PROMOTION,
  VO_STATE_TERMINATED,
  VO_STATE_CANCELED,
  VO_STATE_ACTIVE,
  VO_STATE_BOOKED,
  VO_STATE_BOOKEDSINGLE,
  VO_STATE_CHANGING,
  VO_STATE_PROCESSING,
  VO_STATE_PAUSED,
  CONSUMPTION_TYPE_DATA,
  CONSUMPTION_TYPE_SMS,
  CONSUMPTION_TYPE_MIN,
  CONSUMPTION_TYPE_UNIT,
  CONSUMPTION_TYPE_VOLUME,
  VO_TYPE_ACTIVE_PROMOTION,
} from '../helpers/constants';


export const subscriptionItemConsumptionProps = {
  type: PropTypes.oneOf([
    CONSUMPTION_TYPE_DATA,
    CONSUMPTION_TYPE_SMS,
    CONSUMPTION_TYPE_MIN,
    CONSUMPTION_TYPE_UNIT,
    CONSUMPTION_TYPE_VOLUME,
  ]),
  unit: PropTypes.oneOf(['', 'mb', 'gb']),
  max: PropTypes.number,
  left: PropTypes.number,
};

export const subscriptionItemConsumptionShape = PropTypes.shape(subscriptionItemConsumptionProps);
export const subscriptionItemConsumptionList = PropTypes.arrayOf(subscriptionItemConsumptionShape);

export const subscriptionItemFeatureProps = {
  headline: PropTypes.string,
  subline: PropTypes.string,
  copy: PropTypes.string,
};

export const subscriptionItemFeatureShape = PropTypes.shape(subscriptionItemFeatureProps);
export const subscriptionItemFeatureList = PropTypes.arrayOf(subscriptionItemFeatureShape);

export const subscriptionItemProps = {
  id: PropTypes.string,
  type: PropTypes.oneOf([
    '',
    VO_TYPE_TARIFF,
    VO_TYPE_TARIFFOPTION,
    VO_TYPE_HARDWARE,
    VO_TYPE_PROMOTION,
    VO_TYPE_ACTIVE_PROMOTION,
  ]),
  state: PropTypes.oneOf([
    '',
    VO_STATE_TERMINATED,
    VO_STATE_CANCELED,
    VO_STATE_ACTIVE,
    VO_STATE_BOOKED,
    VO_STATE_BOOKEDSINGLE,
    VO_STATE_CHANGING,
    VO_STATE_PROCESSING,
    VO_STATE_PAUSED,
  ]),
  stateDate: PropTypes.string,
  cancellationDate: PropTypes.string,
  renewContractDate: PropTypes.string,
  possibleCancellationDate: PropTypes.string,
  basicFee: PropTypes.number,
  transferFee: PropTypes.number,
  headline: PropTypes.string,
  subline: PropTypes.string,
  copy: PropTypes.string,
  features: subscriptionItemFeatureList,
  consumptions: subscriptionItemConsumptionList,
  automaticExtension: PropTypes.bool,
  bookable: PropTypes.bool,
  changeable: PropTypes.bool,
  cancelable: PropTypes.bool,
  canceled: PropTypes.bool,
  renewable: PropTypes.bool,
  contractPeriod: PropTypes.number,
  detailsUrl: PropTypes.string,
};

export const subscriptionItemShape = PropTypes.shape(subscriptionItemProps);
export const subscriptionItemList = PropTypes.arrayOf(subscriptionItemShape);
