import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import suitcss from '../../../helpers/suitcss';
import connectUI from '../../basics/ui/UIConnector';
import FieldInput from '../../basics/form/FieldInput';
import FieldTextarea from '../../basics/form/FieldTextarea';
import FieldSelectbox from '../../basics/form/FieldSelectbox';
import FormSection from '../form/FormSection';
import FormHeader from '../form/FormHeader';
import FieldRadioGroup from '../../basics/form/FieldRadioGroup';
import { CONTACT_FORM_TYPE_GDPR } from '../../../helpers/constants';
import Captcha from '../../../containers/captcha/CaptchaService';

const componentName = 'ContactForm';
function ContactForm(props) {
  const { fieldMap, params, ui } = props;
  const { headline, copy, type } = params;
  return (
    <FormSection className={suitcss({ componentName })} asGrid >
      <FormHeader headline={headline} copy={copy} ui={ui} utilities={['col12', 'lCol8']} />
      {type === CONTACT_FORM_TYPE_GDPR && <Field {...fieldMap.customer} component={FieldRadioGroup} utilities={['col12']} withToolTip />}
      <Field {...fieldMap.subject} component={FieldSelectbox} utilities={['col12']} withToolTip />
      <Field {...fieldMap.message} component={FieldTextarea} utilities={['col12']} withToolTip />
      <Field {...fieldMap.salutation} component={FieldRadioGroup} utilities={['col12']} withToolTip />
      <Field {...fieldMap.firstName} component={FieldInput} utilities={['col12', 'mlCol6']} withToolTip />
      <Field {...fieldMap.lastName} component={FieldInput} utilities={['col12', 'mlCol6']} withToolTip />
      <Field {...fieldMap.email} component={FieldInput} utilities={['col12', 'mlCol6']} withToolTip />
      <Field {...fieldMap.phone} component={FieldInput} utilities={['col12', 'mlCol6']} withToolTip />
      <Field {...fieldMap.customerId} component={FieldInput} utilities={['col12', 'mlCol6', 'marginBottomS']} withToolTip />
      <Captcha lineLength={['col12', 'mlCol5']} />
    </FormSection>
  );
}

ContactForm.propTypes = {
  // form logic
  fieldMap: PropTypes.object.isRequired,
  // cms wordings
  params: PropTypes.shape({
    headline: PropTypes.string,
    copy: PropTypes.string,
    subjects: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        options: PropTypes.array.isRequired,
      }),
    ).isRequired,
    type: PropTypes.string,
  }),
  ui: PropTypes.shape({
    guiFormSubmit: PropTypes.string.isRequired,
  }),
};

export default connectUI()(ContactForm);
