/* global window, screen */
import FlexmodeTokenInfoRequest from '../../model/requests/FlexmodeTokenInfoRequest';
import FlexmodeTokenRequest from '../../model/requests/FlexmodeTokenRequest';
import { MARKET_PREPAID } from '../../helpers/constants';
import { send } from '../request/send';
import {
  poll,
  MILLISECONDS_TIME_FIFTEEN_MINUTES,
  MILLISECONDS_TIME_ONE_HALF_SECOND,
} from '../../helpers/polling';
import PaypalCanceledError from '../../model/errors/PaypalCanceledError';
import PopupBlockedError from '../../model/errors/PopupBlockedError';
import { devWarning } from '../../helpers/meta';
import { openWindow } from '../page/dialog';

const PAYPAL_TIMEOUT = MILLISECONDS_TIME_FIFTEEN_MINUTES;

const getParameterByName = (name, url) => {
  // eslint-disable-next-line no-useless-escape
  const cleanName = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${cleanName}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

/**
 * Loads a script into a given window
 * @param {string} src The source of the script
 * @param {Window} win The window to load the source to
 */
const loadScript = (src, win) => {
  const head = win.document.getElementsByTagName('head')[0];
  const script = win.document.createElement('script');
  script.src = src;
  head.appendChild(script);
};

/**
 * Initializes a window for the paypal button
 * @param {string} token The flexmode token
 * @param {string} host The host from which to fetch the script
 * @param {Window} win The window to initialize
 */
const initPaypalWindow = async (token, host, win) => {
  const form = win.document.createElement('form');
  form.action = win.location.href;
  form.id = token;
  form.classList.add('paymentWidgets');
  form.setAttribute('data-brands', 'PAYPAL');
  win.document.body.appendChild(form);

  const scriptUrl = `https://${host}/v1/paymentWidgets.js`;
  loadScript(`${scriptUrl}?checkoutId=${token}`, win);
  const paypalButtonClassName = 'wpwl-button-brand';
  try {
    await poll(
      () => win.document.getElementsByClassName(paypalButtonClassName)[0],
      PAYPAL_TIMEOUT,
      MILLISECONDS_TIME_ONE_HALF_SECOND,
    );
    win.document.getElementsByClassName(paypalButtonClassName)[0].click();
  } catch (e) {
    devWarning(`The automated click timed out in ${PAYPAL_TIMEOUT / 1000} seconds.
    Maybe the class of the paypal button is no longer ${paypalButtonClassName}`);
  }
};

/**
 * Gets the value of the query parameter token of a url
 * @param {Window} win The window to get the token from
 * @returns {Array|{index: number, input: string}|*}
 */
const getTokenFromWindow = (win) => {
  // is catched because the window is taken over by the loaded script and a DOMException will occur
  // due to cross origin reasons
  try {
    return getParameterByName('id', win.location.href);
  } catch (DOMException) {
    return null;
  }
};

/**
 * Executes the request to fetch the flexmode token.
 * @param {object} paymentOptions The options of the payment
 * @returns {Promise.<*>} The flexmode token
 */
const fetchFlexmodeToken = paymentOptions => async dispatch => {
  const response = await dispatch(send(new FlexmodeTokenRequest(MARKET_PREPAID, 'paypal', {
    ...paymentOptions,
    cart: [{ name: 'Paypal Checkout' }],
    paypal: true,
    paymentType: 'paypal',
  })));
  return response.body.data.flexmodeToken;
};

/**
 * Gets the info token of the paypal payment.
 * @param {string} token The token which to send
 * @returns {Promise.<*>}
 */
const getTokenInfo = token => async dispatch => {
  const response = await dispatch(send(new FlexmodeTokenInfoRequest(MARKET_PREPAID, token)));
  return response.body.data.mediaId;
};

function openPaypalWindow() {
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : screen.left;
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : screen.top;
  const width = 450;
  const height = 805;
  const left = ((screen.width / 2) - (width / 2)) + dualScreenLeft;
  const top = ((screen.height / 2) - (height / 2)) + dualScreenTop;
  return window.open(
    `/paypal.html?rand=${Date.now()}`,
    'Paypal',
    `width=${width}, height=${height}, top=${top}, left=${left}`,
  );
}

/**
 * Shows the Paypal dialog and returns a promise that resolves
 * if the transaction was successful. The resolving value is the tokenInfo, which
 * can then be passed to dispatch the payment.
 * @param {object} paymentOptions The options of the payment
 */
export const getPaypalToken = (paymentOptions) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    const paypalWindow = openPaypalWindow();
    if (!paypalWindow) { // window is null if it could not be opened
      reject(new PopupBlockedError());
      return;
    }
    dispatch(openWindow(true));
    // @todo what if window has been loaded before this listener is set?
    const onComplete = async () => {
      try {
        const sessionToken = await dispatch(fetchFlexmodeToken(paymentOptions));
        initPaypalWindow(
          sessionToken,
          process.Environment.getVariableAsString('DBN_PAYMENT_PAYPALHOST'),
          paypalWindow,
        );
      } catch (e) {
        paypalWindow.close();
        dispatch(openWindow(false));
        reject(new PaypalCanceledError(e));
      }
    };

    const checkWindowStatus = (timeoutId) => {
      if (paypalWindow.document.readyState === 'complete') {
        onComplete();
        clearTimeout(timeoutId);
      } else {
        const checkStatusTimeout = setTimeout(() => {
          checkWindowStatus(checkStatusTimeout);
        }, 300);
      }
    };

    checkWindowStatus();
    // paypalWindow.onload = func

    // we need this variable to signal the "close poller" that we are done with our paypal operation
    let isPending = true;

    // listens for the closing of the paypal window
    poll(
      () => paypalWindow.closed,
      PAYPAL_TIMEOUT,
      MILLISECONDS_TIME_ONE_HALF_SECOND,
    ).then(() => {
      if (isPending) {
        // if paypal window was closed and our promise did not resolve yet we throw an error
        dispatch(openWindow(false));
        reject(new PaypalCanceledError());
      }
    });

    // polls the url of the opened window
    try {
      const urlToken = await poll(
        () => getTokenFromWindow(paypalWindow),
        PAYPAL_TIMEOUT,
        MILLISECONDS_TIME_ONE_HALF_SECOND,
      );
      const tokenInfo = await dispatch(getTokenInfo(urlToken));
      resolve(tokenInfo);
    } catch (e) {
      reject(new PaypalCanceledError(e));
    } finally {
      dispatch(openWindow(false));
      isPending = false;
      paypalWindow.close();
    }
  });

