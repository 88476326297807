/* global window, navigator, document */
import FileSaver from 'file-saver';
import { isWebEntity } from './entity';
import AbstractError from '../model/errors/AbstractError';
import RuntimeError from '../model/errors/RuntimeError';
import { devWarning } from './meta';
import {
  FORM_MODE_SIM_REPL_DISABLED,
  FORM_MODE_SIM_REPL_ACTIVATION,
  FORM_MODE_SIM_REPL_ORDERING,
} from './constants';

/**
 * Traverses a given object and calls a function on every entry
 * @param obj The object to traverse
 * @param func The function to call on every entry
 */
export const traverseObject = (obj, func) => {
  Object.keys(obj).forEach((key) => {
    func(obj, key);
    if (obj[key] !== null && typeof obj[key] === 'object') {
      traverseObject(obj[key], func);
    }
  });
};

/**
 * Filters properties from the specified object and returns a
 * new object. The original object is left untouched.
 *
 * @param {Object} obj
 * @param {Function|Array} filter - will be called for each property filter(key, value)
 *     and returns true if property should be included. If an array is supplied, it
 *     will be interpreted as a simple object key filter.
 * @param {Function} [normalizer] - will be called for each property normalize(key, value)
 *     and returns an array in the form [newKey, newValue] where newKey will replace the original
 *     key and newValue the original value.
 * @return {Object}
 */
export const copyObj = (obj, filter, normalizer) => {
  const result = {};
  const _filter = Array.isArray(filter) // eslint-disable-line no-param-reassign
    ? key => filter.includes(key)
    : filter;
  Object.entries(obj).forEach(([key, value]) => {
    if (_filter(key, value)) {
      if (normalizer) {
        [key, value] = normalizer(key, value); // eslint-disable-line no-param-reassign
      }
      result[key] = value;
    }
  });

  return result;
};

/**
 * Returns a replacement sim can be ordered, activated or the service is
 * not available.
 */
export const getSimReplacementMode = simReplacementStatus => {
  const { activatable, orderable } = simReplacementStatus;

  if (!activatable && !orderable) {
    return FORM_MODE_SIM_REPL_DISABLED;
  }

  return (activatable
    ? FORM_MODE_SIM_REPL_ACTIVATION
    : FORM_MODE_SIM_REPL_ORDERING);
};

/**
 * Adds the product information sheet to the terms and conditions markup.
 * We check if tariff is from app or web object.
 * In app object we use first object under documents param.
 * In web object we use the one that match productInformationSheet.
 * @TODO document[0] is fragile, this should be refactored
 * https://jira.db-n.com/browse/OT-1802A
 */
export const addProductSheetToTerms = (hint, ui, tariff) => {
  try {
    const url = isWebEntity(tariff)
      ? tariff.documents.find(d => d.eid === tariff.productInformationSheet).file.url
      : tariff.documents[0].url;
    const link = `<a href="${url}" target="_blank">${ui.guiWordPib}</a>`;
    return hint.replace('{DOCUMENT}', link);
  } catch (e) {
    return hint;
  }
};

/**
 * Ensures that an error or error representation is turned
 * into an instamce of AbstractError (unless it is already the case)
 *
 * @param {*} error
 * @return {AbstractError}
 */
export const makeAbstractError = (error) => {

  if (error instanceof AbstractError) {
    return error;
  }

  if (typeof error === 'string') { // assume full error code
    devWarning('It is bad practice to create an error on the fly. Create a subclass of AbstractError instead.');
    return new AbstractError(error);
  }

  return new RuntimeError(error);
};

export const findKeyByValue = (obj, val) => Object.keys(obj).find(key => obj[key] === val);

export const getConsentTextById = (consentTexts, id) => {
  if (!consentTexts) {
    return '';
  }
  const text = consentTexts.find(consentText => consentText.id === id);
  return text ? text.text : '';
};

export const getConsentHashById = (consentTexts, id) => {
  if (!consentTexts) {
    return '';
  }
  const text = consentTexts.find(consentText => consentText.id === id);
  return text ? text.hash : '';
};
/**
 * Checks if Browser is IE11
 */
export const isIE11 = () => process.browser && !!window.navigator.userAgent.match(/Trident\/7\./);

export const flatArray = (input, depth = 1) => (depth ? input.reduce((acc, cur) => {
  if (Array.isArray(cur)) {
    return [...acc, ...flatArray(cur, depth - 1)];
  }
  return [...acc, cur];
}, []) : input.slice());

export const isEmptyObject = (obj) => Object.keys(obj).length === 0;

/**
 *  Saves pdf file by given data, filename and boolean isBase64, if data is base64 encoded.
 *  Ensures pdf is loaded on mobile safari and firefox.
 *
 *  @param {string} pdfData
 *  @param {string} fileName
 *  @param {boolean} isBase64
 */
export const savePdf = (pdfData, fileName, isBase64 = false) => {
  // convert base64 binary pdf data to arraybuffer
  const base64ToArrayBuffer = (base64) => {
    // next line is an ie11 fix, remove whitespace from base64, not sure why there is white space
    // see: http://stackoverflow.com/a/16114206
    const whitespaceFreeBase64 = base64.replace(/\s/g, '');
    const binaryString = window.atob(whitespaceFreeBase64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i += 1) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  };
  const blobData = isBase64 ? base64ToArrayBuffer(pdfData) : pdfData.body.blob;
  // create blob from arraybuffer and trigger saveAs browser dialog
  const isMobileSafari = /((iPhone|iPad).*Safari)/g.test(navigator.userAgent);
  const isFirefox = /(Firefox)/g.test(navigator.userAgent);
  const blob = new window.Blob([blobData], { type: `application/pdf;${isBase64 ? 'base64' : ''}` });

  if (isMobileSafari) {
    const reader = new FileReader(); // eslint-disable-line
    reader.onload = function () {
      window.location.href = reader.result;
    };
    reader.readAsDataURL(blob);

  } else if (isFirefox) {
    // firefox workaround for saving blob with custom filename
    // https://www.aspsnippets.com/Articles/JavaScript-Save-BLOB-as-PDF-File.aspx
    const ffBlob = new window.Blob([blobData], { type: 'application/octet-stream' });
    const url = window.URL || window.webkitURL;
    const link = url.createObjectURL(ffBlob);
    const a = document.createElement('a');
    a.setAttribute('download', fileName);
    a.setAttribute('href', link);
    a.setAttribute('target', '_blank');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

  } else {
    FileSaver.saveAs(blob, fileName);
  }
};

export const extractDataAttributes = (obj) => Object.keys(obj || {}).reduce((result, key) => {
    result[`data-${key}`] = obj[key]; // eslint-disable-line
    return result;
  }, {});
