import { formatDate, formatTime } from './date';
import { camelCase, capitalize } from './str';
import { bindQueryParams } from './url';
import { sum, toNegative, toPriceObject } from './money';
import { ORDER_STATE_UNAVAILABLE } from '../propTypes/common';
import {
  ENTITY_TYPE_TARIFF,
  ENTITY_TYPE_TARIFFOPTION,
  ENTITY_TYPE_HARDWARE,
  ENTITY_TYPE_HARDWAREGROUP,
  ENTITY_TYPE_TARIFF_VO,
  VO_TYPE_TARIFF,
  VO_TYPE_TARIFFOPTION,
  VO_TYPE_HARDWARE,
  VO_TYPE_PROMOTION,
  ENTITY_TYPE_TARIFF_DETAILS,
  ENTITY_TYPE_HARDWARE_DETAILS,
  QUERY_SELECTED_TARIFF,
  QUERY_SELECTED_HARDWARE,
  MARKET_PREPAID,
  MARKET_MMO,
  PAYMENT_FEE_MONTHLY,
  PAYMENT_FEE_SINGLE,
  PAYMENT_FEE_MONTHLY_STRIKE,
  PAYMENT_FEE_SINGLE_STRIKE,
  BASIC_FEE,
  BASIC_FEE_STRIKE,
  AVAILABILITY_ACCOUNT,
  VO_TYPE_ACTIVE_PROMOTION,
  AVAILABILITY_PRESALES,
} from './constants';
import { getEntityById } from '../selectors/misc';

export const isHardwareGroup = entity =>
  entity && entity.etype === ENTITY_TYPE_HARDWAREGROUP;

export const isHardwareEntity = entity =>
  entity && entity.etype === ENTITY_TYPE_HARDWARE;

export const isTariffEntity = entity =>
  entity && (entity.etype === ENTITY_TYPE_TARIFF || entity.etype === ENTITY_TYPE_TARIFF_VO);

export const isTariffOptionEntity = entity =>
  entity && entity.etype === ENTITY_TYPE_TARIFFOPTION;

export const isTariffDetails = entity =>
  entity && entity.etype === ENTITY_TYPE_TARIFF_DETAILS;

export const isHardwareDetails = entity =>
  entity && entity.etype === ENTITY_TYPE_HARDWARE_DETAILS;

export const isHardwareVO = vo =>
  vo && vo.type === VO_TYPE_HARDWARE;

export const isTariffVO = vo =>
  vo && vo.type === VO_TYPE_TARIFF;

export const isTariffOptionVO = vo =>
  vo && vo.type === VO_TYPE_TARIFFOPTION;

export const isPromotionVO = vo =>
  vo && (vo.type === VO_TYPE_PROMOTION || vo.type === VO_TYPE_ACTIVE_PROMOTION);

export const isWebEntity = object => object.iid && object.eid;

/**
 * Returns the name of a tariff, option or hardware.
 *
 * @param {AppEntity} entity
 */
export const getAppEntityName = entity => `${entity.headline} ${entity.subline}`;

/**
 * Returns the name of a tariff, option or hardware.
 *
 * @param {WebEntity} entity
 */
export const getWebEntityName = entity => `${entity.type} ${entity.name}`;

export const getTariffHardwareRelation = (hardwareEntity, tariffId) => {
  const id = tariffId || hardwareEntity.cheapestHardwareWithTariff;
  // return pre-existing prices if hardware is already normalized
  return (hardwareEntity.paymentFee || hardwareEntity.singlePaymentFee) ?
    {
      [PAYMENT_FEE_MONTHLY]: hardwareEntity.paymentFee,
      [PAYMENT_FEE_MONTHLY_STRIKE]: hardwareEntity.paymentFeeStrike,
      [PAYMENT_FEE_SINGLE]: hardwareEntity.singlePaymentFee,
      [PAYMENT_FEE_SINGLE_STRIKE]: hardwareEntity.singlePaymentFeeStrike,
    }
    // convert id to camelCase to ensure to have the same format like in the store
    : hardwareEntity.tariffMap[camelCase(id)];
};

export const getTariffHardwareSelectedLink = ({ title, hardware, tariff }) => ({
  title,
  url: bindQueryParams(tariff.urlSelect, { [QUERY_SELECTED_HARDWARE]: hardware.eid }),
});

/**
 * Select a Tariff document depending on the given showAt
 * @param tariff tariffentity
 * @param showAt string
 * @returns {{file: {url: string}, title: string}|*}
 */
export const selectProductSheet = (tariff, showAt) => {
  for (let i = 0; i < tariff.documents.length; i++) {
    for (let j = 0; j < tariff.documents[i].showAt.length; j++) {
      if (tariff.documents[i].showAt[j] === showAt) {
        return tariff.documents[i];
      }
    }
  }

  return {
    file: { url: '/' },
    url: '/',
    copy: 'MISSING',
    name: 'MISSING',
  };
};

export const getHardwareTariffSelectedLink = ({ title, hardware, tariff }) => ({
  title,
  url: tariff
    ? bindQueryParams(hardware.urlSelect, {
      [QUERY_SELECTED_HARDWARE]: hardware.eid,
      [QUERY_SELECTED_TARIFF]: tariff.eid,
    })
    : bindQueryParams(hardware.urlSelect, {
      [QUERY_SELECTED_HARDWARE]: hardware.eid,
    }),
});

/**
 * @param {WebEntity[]} entityList
 * @param {string} eid
 * @return {boolean} true if entity list contains entity with eid.
 */
export const hasEntity = (entityList, eid) => entityList.some(entity => entity.eid === eid);

/**
 * Filters undefined entities and returns the eids of the defined entities.
 * @param entities The entities to get the eids from.
 */
export const getEntityIds = entities => (entities ? entities.filter(e => e).map(e => e.eid) : []);

export const idToTariffOptionEntity = eid =>
  ({ eid, etype: 'tariffOptionEntity' });

export const getSumFromProperty = (list, method) => sum(
  ...list
    .filter(item => !!item)
    .map(item => item[method]),
);

export const getBundlePaymentFee = ({ hardware, tariff }, ...additional) =>
  getSumFromProperty(
    !hardware
      ? [tariff, ...additional]
      : [getTariffHardwareRelation(hardware, tariff.eid), tariff, ...additional],
    PAYMENT_FEE_MONTHLY,
  );

export const getBundleSinglePaymentFee = ({ hardware, tariff }, ...additional) =>
  getSumFromProperty(
    !hardware
      ? [tariff, ...additional]
      : [getTariffHardwareRelation(hardware, tariff.eid), tariff, ...additional],
    PAYMENT_FEE_SINGLE,
  );

const ensureSelectedTariff = (selectedHardware, selectedTariff) => {
  if (!selectedTariff) {
    const availableTariffs = Object.keys(selectedHardware.tariffMap);
    return availableTariffs.map(key => ({
      key,
      shippingFee: selectedHardware.tariffMap[key].shippingFee,
    }))
      .reduce((prev, current) => {
        if (current.shippingFee?.unit > prev.shippingFee?.unit) return current;
        return prev;
      }).key;
  } else {
    return selectedTariff?.eid;
  }
};

/**
 * Calculate the shipping fee based on the selected hardware,
 * tariff, tariffVO, and contract renewal status.
 *
 * @param {Object} selectedHardware - the selected hardware
 * @param {Object} selectedTariff - the selected tariff
 * @param {Object[]} tariffVO - the bookable tariff entities
 * @param {boolean} isContractRenewal - the contract renewal status
 * @return {Object} an object with the unit and currency of the shipping fee
 */
export const getShippingFee = (selectedHardware, selectedTariff, tariffVO, isContractRenewal) => {
  if (!selectedHardware) return { unit: 0, currency: 'Euro' };
  // if no tariff is selected in current context, we assume the maximal costs in tariffMap
  const selectedTariffEid = ensureSelectedTariff(selectedHardware, selectedTariff);

  if (isContractRenewal) {
    const selectedTariffVO = tariffVO[selectedTariff.iid];
    const availableConditions = selectedTariffVO && selectedTariffVO.availableConditions
      .find(condition =>
        condition.includesHardware &&
        condition.requiresContractRenewal,
      );
    return {
      unit: (availableConditions && availableConditions.hardwareShippingCosts) || 0,
      currency: 'Euro',
    };
  }

  if (selectedHardware.tariffMap) {
    return selectedHardware.tariffMap[(selectedTariffEid).toLowerCase()].shippingFee;
  }
};

// eslint-disable-next-line max-len
export const getBundleSinglePaymentFeeIncludingShipping = ({ hardware, tariff, shippingFee }, additional) => {
  const singlePaymentFee = getBundleSinglePaymentFee({ hardware, tariff }, additional);
  return { ...singlePaymentFee, unit: singlePaymentFee.unit + shippingFee.unit };
};

/**
 * Convenience function that assumes entity to be an eid or a property with an eid.
 * @param {WebEntity|string} entity object or eid
 * @return {string}
 */
export const getEid = entity => ((typeof entity === 'string') ? entity : entity.eid);

/**
 * Turn the provided entities into entity objects.
 * If entities cannot be resolved, the returned object values for their eids is undefined.
 *
 * @param {array<string|object>} entityList A flat list containing objects that have an eid property
 *     or containing eid strings as elements or a mixture of both.
 * @param {object} entitiesInStore A hashmap of entities grouped by
 *     their etype (how it also exists in our store)
 * @param {object} internalIdMap Store object with our entities' ids as key and eids as value
 *     to retrieve a missing eid
 * @return {object<eid, WebEntity|undefined>}
 */
export const mapEntitiesToStoreObjects = (entityList, entitiesInStore, internalIdMap) => {
  const resolvedEntities = {};
  entityList
    .filter(Boolean)
    .forEach(entity => {
      const eid = getEid(entity);
      const id = Object.keys(internalIdMap).find(key => internalIdMap[key] === eid);
      resolvedEntities[eid] = getEntityById(entitiesInStore, eid)
        || { ...getEntityById(entitiesInStore, id), eid };
    });
  return resolvedEntities;
};

export const getPaymentFeeDuration = (entity, ui, abbreviated) => {
  const { automaticExtension, duration } = entity;
  const isWeb = isWebEntity(entity);
  const isPrepaid = entity.market === MARKET_PREPAID || entity.market === MARKET_MMO;
  const abbrKey = abbreviated ? 'Abbr' : '';

  if (isPromotionVO(entity)) {
    if (entity.billingType && entity.billingType === 'one_time') {
      return ui[`guiWordSingular${abbrKey}`];
    }
    return ui[`guiWordMonthly${abbrKey}`];
  }

  if (duration && (isWeb || !!automaticExtension)) {
    const str = ui[`guiDuration${capitalize(duration.unit)}${duration.amount}${abbrKey}`];
    if (str) { return str; }
  }

  if (isPrepaid) {
    if (duration && duration.unit === 'hour') {
      return ui[`guiWordSingular${abbrKey}`];
    }
    if (duration && duration.amount === 14) {
      return ui[`guiDurationDay14${abbrKey}`];
    }
    if (duration && duration.unit === 'month') {
      return ui[`guiDurationDay28${abbrKey}`];
    }
    return ui[`guiWordSingular${abbrKey}`];
  }
  return !isWeb && !automaticExtension && !isHardwareVO(entity)
    ? ui[`guiWordSingular${abbrKey}`]
    : ui[`guiWordMonthly${abbrKey}`];
};

/**
 * Compares two elements by priority under consideration of the orderState
 * @return {Number}
 * @todo  Used in a sort function it will not sort all elements in the expected order
 *        when available and unavailable items are provided. The unavailable elements
 *        will be last but in the reversed order:
 *        is: [10 available, 1 available, 1 unavailable, 10 unavailable]
 *        should: [10 available, 1 available, 10 unavailable, 1 unavailable]
 */
export const sortByAvailablePriority = (a, b) => {
  const aHasPriority = b.priority < a.priority;
  const bHasPriority = b.priority > a.priority;
  const isAAvailable = a.orderState !== ORDER_STATE_UNAVAILABLE;
  const isBAvailable = b.orderState !== ORDER_STATE_UNAVAILABLE;
  const conditionEquals = (
    aHasPriority === bHasPriority &&
    isAAvailable === isBAvailable
  );

  if (conditionEquals) {
    return 0;
  }

  if ((bHasPriority && isBAvailable) || !isAAvailable) {
    return 1;
  }

  return -1;
};

export const sortByMarketingLocation = (a, b) => {
  if (a.marketingLocation.length && !b.marketingLocation.length) {
    return 1;
  }
  if (!a.marketingLocation.length && b.marketingLocation.length) {
    return -1;
  }
  return 0;
};

export const sortByEntityType = (entity1, entity2) => {
  if (entity1.etype === entity2.etype) {
    return 0;
  }
  if (entity1.etype === 'tariffOptionEntity') {
    return 1;
  }
  return 0;
};

export const getTargetCreditIncentives = (entity, promotions = []) => {
  const targetEntity = isTariffEntity(entity)
    ? 'tariffs'
    : (isHardwareEntity(entity) ? 'hardware' : 'tariffOptions');

  const matchingPromotions = promotions.filter(
    promo => promo.targets && promo.targets[targetEntity].some(entry => entry.eid === entity.eid),
  );

  return matchingPromotions.reduce((arr, promo) => {
    const incentives = promo.incentives.filter(entry => entry.type === 'credit' && camelCase(entry.targetEntity) === targetEntity);
    return arr.concat(incentives);
  }, []);
};

export const getNormalizedPaymentFees = (
  entity, creditIncentives = [], selectedTariff, isContractRenewal, isSimExchange, renewalFee,
) => {
  // filter credit incentives for monthly and one time payment
  const paymentFeeIncentives = creditIncentives.filter(
    incentive => (camelCase(incentive.targetProperty) === PAYMENT_FEE_MONTHLY),
  );

  const singlePaymentFeeIncentives = creditIncentives.filter(
    incentive => (camelCase(incentive.targetProperty) === PAYMENT_FEE_SINGLE),
  );

  // set monthly and one time savings
  const paymentFeeDiscount = paymentFeeIncentives.length
    ? toNegative(sum(...paymentFeeIncentives.map(incentive => incentive.discount)))
    : null;

  const singlePaymentFeeDiscount = singlePaymentFeeIncentives.length
    ? toNegative(sum(...singlePaymentFeeIncentives.map(incentive => incentive.discount)))
    : null;

  // set hardware + tariff monthly and on time relationship fees
  const tariffRelationshipFees = isHardwareEntity(entity)
    ? getTariffHardwareRelation(entity, selectedTariff && selectedTariff.eid)
    : {};

  // check and set which monthly and one time fee should be used
  const temporaryPaymentFee = tariffRelationshipFees[PAYMENT_FEE_MONTHLY]
    || entity[PAYMENT_FEE_MONTHLY];

  const temporarySinglePaymentFee = tariffRelationshipFees[PAYMENT_FEE_SINGLE]
    || entity[PAYMENT_FEE_SINGLE];

  // set monthly and one time strike and discounted fees
  const paymentFee = paymentFeeDiscount
    ? toPriceObject(temporaryPaymentFee.unit + paymentFeeDiscount.unit)
    : temporaryPaymentFee;

  const paymentFeeStrike = paymentFeeDiscount
    ? temporaryPaymentFee
    : null;

  const contractRenewalFee = { unit: renewalFee, currency: 'EUR' };

  const singlePaymentFee = isContractRenewal && isTariffEntity(entity) && renewalFee
    ? contractRenewalFee
    : (isContractRenewal || isSimExchange) && isTariffEntity(entity)
      ? { unit: 0, currency: 'EUR' }
      : singlePaymentFeeDiscount
        ? toPriceObject(temporarySinglePaymentFee.unit + singlePaymentFeeDiscount.unit)
        : temporarySinglePaymentFee;

  const singlePaymentFeeStrike = isContractRenewal || isSimExchange || !singlePaymentFeeDiscount
    ? null
    : temporarySinglePaymentFee;

  // return normalized paymentFees
  return {
    [PAYMENT_FEE_MONTHLY]: paymentFee,
    [PAYMENT_FEE_SINGLE]: singlePaymentFee,
    [PAYMENT_FEE_MONTHLY_STRIKE]: paymentFeeStrike,
    [PAYMENT_FEE_SINGLE_STRIKE]: singlePaymentFeeStrike,
  };
};

export const getNormalizedBasicFees = (entity, creditIncentives = []) => {
  // filter credit incentives for monthly payments
  const basicFeeIncentives = creditIncentives.filter(
    incentive => (camelCase(incentive.targetProperty) === PAYMENT_FEE_MONTHLY),
  );
  // set monthly savings
  const basicFeeDiscount = basicFeeIncentives.length
    ? toNegative(sum(...basicFeeIncentives.map(incentive => incentive.discount)))
    : null;

  // set monthly and strike and discounted fees
  const basicFee = basicFeeDiscount
    ? entity[BASIC_FEE] + basicFeeDiscount.unit
    : entity[BASIC_FEE];

  const basicFeeStrike = basicFeeDiscount
    ? entity[BASIC_FEE]
    : null;

  // return normalised basicFees
  return {
    [BASIC_FEE]: basicFee,
    [BASIC_FEE_STRIKE]: basicFeeStrike,
  };
};

export const normalizeTargetEntities = (
  entities,
  promotions,
  realm,
  isContractRenewal,
  isSimExchange,
  renewalFee,
  querySelectedTariff,
  firstSelected,
) => {
  const tariffs = entities.filter(entity => isTariffEntity(entity));
  const tariffFromQuery = tariffs.find(({ eid }) => eid === querySelectedTariff);
  const selectedTariff = tariffs.length === 1 ? tariffs[0]
    : firstSelected && !tariffFromQuery
      ? tariffs[0]
      : tariffFromQuery;

  return entities.map((entity) => {
    const creditIncentives = getTargetCreditIncentives(entity, promotions);
    const normalizedPaymentFees = realm === AVAILABILITY_ACCOUNT
      ? getNormalizedBasicFees(entity, creditIncentives)
      : getNormalizedPaymentFees(
        entity, creditIncentives, selectedTariff, isContractRenewal, isSimExchange, renewalFee,
      );
    return {
      ...entity,
      ...normalizedPaymentFees,
    };
  });
};

export const getTariffDuration = (tariffEntity, ui) => {
  if (tariffEntity.duration.unit === 'month' && tariffEntity.duration.amount > 1) {
    return ui.guiContractPeriodN.replace('{CONTRACT_PERIOD}', tariffEntity.duration.amount);
  } else if (tariffEntity.duration.amount === 1) {
    return ui.guiContractPeriod1;
  }
  return null;
};

/**
 * Returns the expiration date for booking overview items based on the entity state.
 *
 * @param {object} entity - The entity for which the expiration date is being calculated
 * @param {string} dateCopy - The date copy to be used for the calculation
 * @param dateTimeCopy
 * @param eolCopy
 * @return {string | null} The calculated expiration date or null
 * if no valid expiration date is found
 */
export const getBookingOverviewItemsExpirationDate = (entity, dateCopy, dateTimeCopy, eolCopy) => {
  if (entity.expirationDate) {
    // check if expiration date is more than 20 years in the future
    const currentDate = new Date();
    const futureDate = new Date();
    futureDate.setFullYear(currentDate.getFullYear() + 20);
    // set eol copy
    if (eolCopy && new Date(entity.expirationDate) > futureDate) {
      return eolCopy;
    }
    // set date and time
    if (entity.expirationDateTime) {
      return dateTimeCopy.replace('{DATE}', formatDate(entity.expirationDate)).replace('{TIME}', formatTime(entity.expirationDateTime));
    }
    return dateCopy.replace('{DATE}', formatDate(entity.expirationDate));
  }
  return null;
};

/**
 * Get the sorted single payment fees from the tariff map.
 *
 * @param {Object} tariffMap - The map containing tariff information.
 * @return {Array} The sorted array of single payment fees.
 */
const getSortedSinglePaymentFees = (tariffMap) => Object.values(tariffMap).sort(
  (p1, p2) => p1.singlePaymentFee.unit - p2.singlePaymentFee.unit,
);
/**
 * Generate the prefix for a given price based on certain conditions.
 *
 * @param {number} price - The initial price value.
 * @param {Object} prefixPrice - The prefix price object.
 * @param {Object} options - An object containing hardware, tariffId, paymentType, ui
 *  and currentRealm.
 * @return {string|null} The prefix for the given price or null if conditions are met.
 */
export const getHardwarePricePrefix = (price, prefixPrice, {
  hardware, tariffId, paymentType, ui, currentRealm,
}) => {
  if (tariffId) {
    return null;
  }

  /* QUICK & DIRTY FIX FOR:
   * https://jira.db-n.com/browse/OP-1370
   * Filter out Basic Tariff (n592) in Presales
  */
  // eslint-disable-next-line prefer-destructuring
  let tariffMap = hardware.tariffMap;
  if (currentRealm === AVAILABILITY_PRESALES) {
    tariffMap = Object.keys(tariffMap).reduce((obj, key) => {
      if (key !== 'n592') {
        // eslint-disable-next-line no-param-reassign
        obj[key] = tariffMap[key];
      }
      return obj;
    }, {});
  }
  /* END OF QUICK AND DIRTY FIX :) */

  const prices = getSortedSinglePaymentFees(tariffMap);
  const maxPrice = prices[prices.length - 1][paymentType];
  if (price.unit === maxPrice.unit || (prefixPrice && prefixPrice.unit === maxPrice.unit)) {
    return null;
  }

  return ui.guiWordStartingFrom;
};

/**
 * Function to get the cheapest hardware with a specific tariff.
 * The cheapest hardware with tariff should only be displayed,
 * if the corresponding tariffs have different prices.
 * So the tariff with the cheapest hardware price will be returned.
 * If the hardware price is the same for all tariffs,
 * the cheapest hardware with tariff will be null.
 *
 * @param {Array} hardware - The list of hardware items.
 * @param {null} tariffId - The ID of the tariff to consider.
 * @param {Object} ui - The user interface object.
 * @return {Object|null} The cheapest hardware item with the specified tariff, or null if not found.
 */
export const getCheapestHardwareWithTariff = (hardware, tariffId, ui) => {
  const paymentType = PAYMENT_FEE_SINGLE;
  const tariffRelation = getTariffHardwareRelation(hardware, tariffId);
  const hardwarePrice = hardware[paymentType] || tariffRelation[paymentType];
  const prefixPrice = hardware[`${paymentType}Strike`];
  const pricePrefix = getHardwarePricePrefix(hardwarePrice, prefixPrice, {
    hardware, tariffId, paymentType, ui, AVAILABILITY_PRESALES,
  });
  if (pricePrefix) {
    return hardware.cheapestHardwareWithTariff;
  }
  return null;
};
