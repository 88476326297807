import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import FormSection from '../form/FormSection';
import FormHeader from '../form/FormHeader';
import suitcss from '../../../helpers/suitcss';
import FieldDate from '../../basics/form/FieldDate';

class EmailValidation extends PureComponent {
  render() {
    const { ui, fieldMap } = this.props;

    return (
      <FormSection className={suitcss({}, this)} asGrid>
        <FormHeader headline={ui.myEmailConfirmHeadline} copy={ui.myEmailConfirmCopy} />
        <Field {...fieldMap.birthday} component={FieldDate} isDateGroup utilities={['col8']} withToolTip />
      </FormSection>
    );
  }
}

EmailValidation.propTypes = {
  fieldMap: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
};

export default EmailValidation;
