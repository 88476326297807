import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import suitcss from '../../../../helpers/suitcss';
import FieldInput from '../../../basics/form/FieldInput';
import FormSection from '../../form/FormSection';
import Link from '../../../basics/text/TextLink';
import { MYTRACK_LOGIN_FORGOT_PASSWORD } from '../../../../helpers/constants';
import SvgLoader from '../../../basics/media/MediaSvgLoader';

const componentName = 'LoginFormStep';

const LoginFormPassword = memo(({
  fieldMap,
  urlPasswordReset,
  passwordResetCopy,
  passwordHidden,
  togglePasswordVisibility,
}) => {

  const passwordVisibility = passwordHidden ? 'shown' : 'hidden';
  const passwordIcon = `/files/icons/login/icon-login-${passwordVisibility}-black.svg`;

  return (
    <FormSection
      className={suitcss({ componentName, modifiers: ['stepPwd'] })}
      withoutBorder
      asGrid
    >
      <Field
        {...fieldMap.username}
        component={FieldInput}
        utilities={['col12']}
        theme="compact"
        withToolTip
      />
      <Field
        {...fieldMap.password}
        component={FieldInput}
        utilities={['col12']}
        theme="compact"
        withToolTip
        type={passwordHidden ? 'password' : 'text'}
      />
      <span
        className={suitcss({
          componentName,
          descendantName: 'stepPwdIcons',
        })}
        onClick={togglePasswordVisibility}
      >
        <SvgLoader path={passwordIcon} />
      </span>
      { urlPasswordReset &&
        <div className={suitcss({ utilities: ['alignRight', 'col12', 'marginTopAuto'] })} >
          <Link
            className="passwordReminderLink"
            withoutArrow
            size="small"
            data={{ mytracking: MYTRACK_LOGIN_FORGOT_PASSWORD }}
            to={urlPasswordReset}
          >
            {passwordResetCopy}
          </Link>
        </div>
      }
    </FormSection>
  );
}, (prevProps, nextProps) => {
  // Only re-render if the fieldMap or passwordHidden props have changed
  return JSON.stringify(prevProps.fieldMap) === JSON.stringify(nextProps.fieldMap)
    && prevProps.passwordHidden === nextProps.passwordHidden;
});

LoginFormPassword.propTypes = {
  // form logic
  urlPasswordReset: PropTypes.string,
  passwordResetCopy: PropTypes.string,
  fieldMap: PropTypes.shape({
    username: PropTypes.object.isRequired,
    password: PropTypes.object.isRequired,
  }),
  togglePasswordVisibility: PropTypes.func,
  passwordHidden: PropTypes.bool,
};

export default LoginFormPassword;
