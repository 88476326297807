import { DIALOG_TYPE_QUEUED, ACTION_PREFIX } from '../../helpers/constants';
import { hideDialog, hideQueuedDialog, hideNotification } from '../page/dialog';
import menuActions from '../page/menu';

export const BLOCK_VIEWPORT = `${ACTION_PREFIX}/BLOCK_VIEWPORT`;
export const TOGGLE_TOUCH_SCREEN_INPUT = `${ACTION_PREFIX}/TOGGLE_TOUCH_SCREEN_INPUT`;
export const CHANGE_SCREEN_SIZE = `${ACTION_PREFIX}/CHANGE_SCREEN_SIZE`;

export const changeScreenSize = sizes => ({
  type: CHANGE_SCREEN_SIZE,
  payload: sizes,
});

export const toggleTouchScreenInput = (isUsingTouchScreen) => ({
  type: TOGGLE_TOUCH_SCREEN_INPUT,
  payload: { isUsingTouchScreen },
});

export const blockViewport = (blocking, scrollToTop) => ({
  type: BLOCK_VIEWPORT,
  payload: { blocking, scrollToTop },
});

const toggleViewportBlocking = () => (dispatch, getState) => {
  const { menu, site } = getState();
  const { isBlockingViewport } = site;
  const isMenuBlocking = Object.keys(menu).some(key => (
    menu[key].blockViewport
  ));
  const remainBlocked = site.dialogShown || isMenuBlocking;

  if (isBlockingViewport && !remainBlocked) {
    return dispatch(blockViewport(false));
  }

  if (!isBlockingViewport && remainBlocked) {
    return dispatch(blockViewport(true, isMenuBlocking));
  }

  return null;
};

const onLocationChange = () => (dispatch, getState) => {
  const { menu, site } = getState();
  const currentDialog = site.dialogShown;
  const currentNotification = site.notification;
  let shouldToggleViewportBlocking = false;

  Object.keys(menu).forEach(key => {
    if (menu[key].isOpen) {
      dispatch(menuActions.closeMenu(key));
      shouldToggleViewportBlocking = true;
    }
  });

  if (currentDialog && currentDialog.shouldCloseOnTransition) {
    dispatch(currentDialog.type === DIALOG_TYPE_QUEUED ? hideQueuedDialog() : hideDialog());
    shouldToggleViewportBlocking = false;
  }

  if (currentNotification && currentNotification.shouldCloseOnTransition) {
    dispatch(hideNotification());
  }

  if (shouldToggleViewportBlocking) {
    dispatch(toggleViewportBlocking());
  }
};

export default {
  toggleViewportBlocking,
  onLocationChange,
};
