/* global window */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FormManager from '../form/FormManager';
import GlobalSection from '../../components/basics/global/GlobalSection';
import { MYTRACK_CONSENT_HANDLING_SUBMIT_SUCCESS } from '../../helpers/constants';
import * as consentHandling from './formConfigConsentHandling';
import { trackMyState } from '../../actions/tracking/event';
import UmidInfoRequest from '../../model/requests/UmidInfoRequest';
import { hideDialog, showNotification } from '../../actions/page/dialog';
import { setCookieConsent } from '../../actions/dialog/cookieConsentActions';

class ConsentHandlingForm extends PureComponent {

  constructor(props, ...args) {
    super(props, ...args);
    this.onAfterSubmitSuccess = this.onAfterSubmitSuccess.bind(this);
  }

  onAfterSubmitSuccess(submitResult) {
    // updates the fcData object which is the interface for trakken
    setCookieConsent(submitResult.finalizedValues);
    const { ui, dispatch } = this.props;
    dispatch(hideDialog());
    this.props.trackMyState(MYTRACK_CONSENT_HANDLING_SUBMIT_SUCCESS);
    this.props.showNotification(ui.guiSettingsSaved);
    window.adv_global && window.adv_global.registerEventListeners(); /*eslint-disable-line*/
  }

  onBeforeSubmit(fieldMap, finalizedValues) {
    return {
      'LI-OPT': finalizedValues.analytics || false,
      'LI-OM': finalizedValues.contact || false,
    };
  }

  getSubmitRoute(fieldMap, finalizedFormValues) {
    return new UmidInfoRequest(finalizedFormValues, { 'LI-OM': true, 'LI-OPT': true });
  }

  render() {
    const { location } = this.props;

    return (
      <GlobalSection adjacent>
        <FormManager
          form={'gdpr'}
          location={location}
          stepProps={this.props}
          submitRoute={this.getSubmitRoute}
          onAfterSubmitSuccess={this.onAfterSubmitSuccess}
          onBeforeSubmit={this.onBeforeSubmit}
          steps={[consentHandling]}
          withFooter
        />
      </GlobalSection>
    );
  }
}


ConsentHandlingForm.propTypes = {
  trackMyState: PropTypes.func.isRequired,
  showNotification: PropTypes.func.isRequired,
  ui: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  location: PropTypes.object,
};

const mapStateToProps = state => ({
  ui: state.ui,
});

const mapDispatchToProps = (dispatch) => ({
  trackMyState,
  showNotification,
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(ConsentHandlingForm);
