/* eslint-disable quotes */
/* global window */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import suitcss from '../../../helpers/suitcss';
import connectUI from '../../basics/ui/UIConnector';
import FormSection from '../form/FormSection';
import FormHeader from '../form/FormHeader';
import Copy from "../../basics/text/TextCopy";
import Headline from "../../basics/text/TextHeadline";
import { formatDate, formatTime } from '../../../helpers/date';
import { savePdf } from '../../../helpers/misc';
import Link from "../../basics/text/TextLink";

const componentName = 'CancellationConfirmation';

class PresalesCancellationConfirmation extends Component {
  constructor(props, context) {
    super(props, context);
    this.showPdf = this.showPdf.bind(this);
  }

  componentDidMount() {
    if (window) {
      window.scroll(0, 0);
    }
  }

  showPdf() {
    const { apiResponse, ui } = this.props;
    savePdf(apiResponse.document.document, `${ui.guiNoticeOfTermination}.pdf`, true);
  }

  render() {
    const {
      ui,
      confirmCopy,
      dataHeadline,
      inputValues,
      apiResponse,
      isMediaSM,
    } = this.props;

    const cancellationDate = formatDate(apiResponse.createdAt);
    const cancellationTime = formatTime(apiResponse.createdAt);
    const tempCopy = confirmCopy
      .replace('{#date}', cancellationDate)
      .replace('{#time}', cancellationTime)
      .replace('{#number}', apiResponse.caseNumber);

    const mobileColor = isMediaSM ? 'colorGray100' : '';

    return (
      <>
        <FormSection
          className={suitcss({
            componentName,
            utilities: ['paddingXL', 'smPaddingXS', 'marginBottomL'],
          })}
        >
          <FormHeader copy={tempCopy} ui={ui} utilities={['col12']} isExpanded />
          <Headline utilities={['colorPrimary', 'marginBottomL']}>{dataHeadline}</Headline>
          <div
            className={suitcss({
              utilities: ['grid', 'lCol12', 'marginBottomM', 'smMarginBottomS'],
            })}
          >
            <Copy
              utilities={[
                'col4',
                'smCol12',
                'marginTopAuto',
                'smMarginBottomXXS',
                'weightBold',
                mobileColor,
              ]}
            >
              {ui.guiFormNameFirst}
            </Copy>
            <Copy utilities={['col8', 'smCol12', 'marginTopAuto', 'smMarginBottomS']}>
              {inputValues.firstName}
            </Copy>

            <Copy
              utilities={[
                'col4',
                'smCol12',
                'marginTopAuto',
                'smMarginBottomXXS',
                'weightBold',
                mobileColor,
              ]}
            >
              {ui.guiFormNameLast}
            </Copy>
            <Copy utilities={['col8', 'smCol12', 'marginTopAuto', 'smMarginBottomS']}>
              {inputValues.lastName}
            </Copy>

            <Copy
              utilities={[
                'col4',
                'smCol12',
                'marginTopAuto',
                'smMarginBottomXXS',
                'weightBold',
                mobileColor,
              ]}
            >
              {ui.guiFormEmail}
            </Copy>
            <Copy utilities={['col8', 'smCol12', 'marginTopAuto', 'smMarginBottomS']}>
              {inputValues.email}
            </Copy>

            <Copy
              utilities={[
                'col4',
                'smCol12',
                'marginTopAuto',
                'smMarginBottomXXS',
                'weightBold',
                mobileColor,
              ]}
            >
              {ui.guiFormAddressStreet}
            </Copy>
            <Copy utilities={['col8', 'smCol12', 'marginTopAuto', 'smMarginBottomS']}>
              {inputValues.street}
            </Copy>

            <Copy
              utilities={[
                'col4',
                'smCol12',
                'marginTopAuto',
                'smMarginBottomXXS',
                'weightBold',
                mobileColor,
              ]}
            >
              {ui.guiFormAddressNumber}
            </Copy>
            <Copy utilities={['col8', 'smCol12', 'marginTopAuto', 'smMarginBottomS']}>
              {inputValues.houseNumber}
            </Copy>

            <Copy
              utilities={[
                'col4',
                'smCol12',
                'marginTopAuto',
                'smMarginBottomXXS',
                'weightBold',
                mobileColor,
              ]}
            >
              {ui.guiFormAddressZip}
            </Copy>
            <Copy utilities={['col8', 'smCol12', 'marginTopAuto', 'smMarginBottomS']}>
              {inputValues.zip}
            </Copy>

            <Copy
              utilities={[
                'col4',
                'smCol12',
                'marginTopAuto',
                'smMarginBottomXXS',
                'weightBold',
                mobileColor,
              ]}
            >
              {ui.guiFormAddressCity}
            </Copy>
            <Copy utilities={['col8', 'smCol12', 'marginTopAuto', 'smMarginBottomS']}>
              {inputValues.city}
            </Copy>

            <Copy
              utilities={[
                'col4',
                'smCol12',
                'marginTopAuto',
                'smMarginBottomXXS',
                'weightBold',
                mobileColor,
              ]}
            >
              {ui.myProfileContractCustomerId}
            </Copy>
            <Copy utilities={['col8', 'smCol12', 'marginTopAuto', 'smMarginBottomS']}>
              {inputValues.customerId}
            </Copy>

            <Copy
              utilities={[
                'col4',
                'smCol12',
                'marginTopAuto',
                'smMarginBottomXXS',
                'weightBold',
                mobileColor,
              ]}
            >
              {ui.myProfileContractContractId}
            </Copy>
            <Copy utilities={['col8', 'smCol12', 'marginTopAuto', 'smMarginBottomS']}>
              {inputValues.contractIdent}
            </Copy>

            <Copy
              utilities={[
                'col4',
                'smCol12',
                'marginTopAuto',
                'smMarginBottomXXS',
                'weightBold',
                mobileColor,
              ]}
            >
              {ui.guiTerminationDate}
            </Copy>
            <Copy utilities={['col8', 'smCol12', 'marginTopAuto', 'smMarginBottomS']}>
              {inputValues.terminationDate}
            </Copy>

            <Copy
              utilities={[
                'col4',
                'smCol12',
                'marginTopXXS',
                'smMarginBottomXXS',
                'weightBold',
                mobileColor,
              ]}
            >
              {ui.guiTerminationDetails}
            </Copy>
            <Copy utilities={['col8', 'smCol12', 'marginTopXXS']}>
              {inputValues.terminationDetails}
            </Copy>
          </div>
        </FormSection>
        <div
          className={suitcss({
            utilities: ['flex', 'justifyCenter'],
          })}
        >
          <Link
            element="button"
            utilities={['uppercase', 'fontCondensed']}
            onClick={this.showPdf}
            asButton
            buttonFilled
          >
            {ui.guiDownloadConfirmation}
          </Link>
        </div>
      </>
    );
  }
}

PresalesCancellationConfirmation.propTypes = {
  params: PropTypes.shape({
    headline: PropTypes.string,
    copy: PropTypes.string,
  }),
  confirmCopy: PropTypes.string,
  dataHeadline: PropTypes.string,
  inputValues: PropTypes.object,
  apiResponse: PropTypes.object,
  isMediaSM: PropTypes.bool,
  ui: PropTypes.shape({
    guiFormSubmit: PropTypes.string.isRequired,
  }),
};

export default connectUI()(PresalesCancellationConfirmation);
