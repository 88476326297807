import React from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';
import Copy from './TextCopy';

const componentName = 'Tag';

function Tag(props) {
  const { theme, label, utilities } = props;
  return (
    <div className={suitcss({ modifiers: [theme], utilities }, this)}>
      <Copy className={suitcss({ componentName, descendantName: 'copy' })} element="span" embedded raw >
        {label}
      </Copy>
    </div>
  );
}

Tag.propTypes = {
  theme: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
  label: PropTypes.string.isRequired,
  utilities: PropTypes.array,
};

export default Tag;
