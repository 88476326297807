import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import suitcss from '../../../../helpers/suitcss';
import connectUI from '../../../basics/ui/UIConnector';
import FieldRadioGroup from '../../../basics/form/FieldRadioGroup';
import Copy from '../../../basics/text/TextCopy';
import FormHeader from '../../form/FormHeader';
import Link from '../../../basics/text/TextLink';

class SimReplacementFormActivationStyle extends PureComponent {

  render() {
    const {
      fieldMap,
      ui,
      isPrepaid,
      sitemap,
      selectedSimOption,
    } = this.props;
    const insufficientBalance = selectedSimOption.sufficientBalance === false;

    return (
      <Fragment>
        {isPrepaid && insufficientBalance &&
        <div className={suitcss({ descendantName: 'warning', utilities: ['col12'] }, this)}>
          <Copy utilities={['colorError']} raw>{ui.txtMySimrepSimtypeBalanceTopup}</Copy>
          <Link to={sitemap.MyDashboardRoute.url} asButton>
            {ui.mySimrepBalanceTopupButton}
          </Link>
        </div>
        }
        {!insufficientBalance &&
          <div className={suitcss({}, this)}>
            <FormHeader headline={ui.mySimrepActviationformTitle} />
            <div className={suitcss({ utilities: ['grid', 'sCollapse', 'col12'] })}>
              <Field {...fieldMap.shipActivatedCard} component={FieldRadioGroup} asStack />
              <Copy raw embedded>{ui.txtMySimrepFormfooterCopy}</Copy>
              <Copy raw size="secondary" utilities={['colorGray100']} embedded>{ui.txtMySimrepFormfooterHint}</Copy>
            </div>
          </div>
        }
      </Fragment>
    );
  }
}

SimReplacementFormActivationStyle.propTypes = {
  fieldMap: PropTypes.shape({
    shipActivatedCard: PropTypes.object,
  }).isRequired,
  msisdn: PropTypes.string.isRequired,
  market: PropTypes.string.isRequired,
  sitemap: PropTypes.object.isRequired,
  balance: PropTypes.object,
  simReplacementStatus: PropTypes.object,
  params: PropTypes.object,
  isPrepaid: PropTypes.bool,
  selectedSimOption: PropTypes.object,
  ui: PropTypes.shape({
    mySimrepSimcardTitle: PropTypes.string,
    mySimrepPaymentHintPost: PropTypes.string,
    mySimrepPaymentHintPre: PropTypes.string,
    mySimrepActviationformTitle: PropTypes.string,
    mySimrepBalanceLabel: PropTypes.string,
    mySimrepBalanceTopupButton: PropTypes.string,
    txtMySimrepSimtypeHint: PropTypes.string,
    txtMySimrepSimtypeDescr: PropTypes.string,
    txtMySimrep_simtype_balance_topup: PropTypes.string,
    txtMySimrepActiveYesLabel: PropTypes.string,
    txtMysimrepActiveYesHint: PropTypes.string,
    txtMySimrepActiveNoLabel: PropTypes.string,
    txtMySimrepActiveNoHint: PropTypes.string,
    txtMySimrepFormfooterCopy: PropTypes.string,
    txtMySimrepFormfooterHint: PropTypes.string,
    txtMySimrepSimtypeBalanceTopup: PropTypes.string,
  }).isRequired,
};

export default connectUI()(SimReplacementFormActivationStyle);
