import { connect } from 'react-redux';
import { compose } from 'redux';

import FormStepTopup
  from '../../../components/compositions/form/steps/FormStepTopup';
import initForm from '../FormInitializer';
import * as constants from '../../../helpers/constants';
import * as money from '../../../helpers/money';
import FormConfigProps from '../../../model/form/FormConfigProps';

const AUTO_TOPUP_TYPE_AUTO = 'auto';
const AUTO_TOPUP_TYPE_SINGLE = 'single';
const AUTO_TOPUP_TYPE_MMO = 'mmo';
export const AUTO_TOPUP_TYPE_NONE = 'none';
const AUTO_TOPUP_MONTHLY_VARIANT_B = 'B';
const AUTO_TOPUP_MONTHLY_VARIANT_D = 'D';

export const CONTEXT_AUTO_TOPUP = Symbol('CONTEXT_AUTO_TOPUP');
export const CONTEXT_SIM_ACTIVATION = Symbol('CONTEXT_SIM_ACTIVATION');

const createFieldMap = ({ ui }) => ({
  topupType: {
    name: '_topup_type',
    label: ui.guiWordTopup,
    shouldSubmit: false,
    validation: {
      isRequired: true,
      oneOf: [
        AUTO_TOPUP_TYPE_AUTO,
        AUTO_TOPUP_TYPE_SINGLE,
        AUTO_TOPUP_TYPE_NONE,
        AUTO_TOPUP_TYPE_MMO,
      ],
    },
  },
  topupAmount: {
    name: '_topup_amount',
    label: ui.guiTopupAmount,
    shouldSubmit: false,
    validation: {
      dependsOn: {
        topupType: [
          AUTO_TOPUP_TYPE_AUTO,
          AUTO_TOPUP_TYPE_SINGLE,
        ],
      },
      isRequired: true,
    },
  },
  autoTopupType: {
    name: '_topup_auto_type', // one of the inner radio groups!
    label: ui.guiTopupAuto,
    shouldSubmit: false,
    validation: {
      dependsOn: {
        topupType: AUTO_TOPUP_TYPE_AUTO,
      },
      isRequired: true,
    },
  },
  autoTopupTrigger: {
    name: '_topup_auto_trigger',
    label: ui.guiTopupAutoBalanceThreshold,
    shouldSubmit: false,
    options: [
      { value: 500, label: '5,00 €' },
      { value: 1000, label: '10,00 €' },
      { value: 1500, label: '15,00 €' },
      { value: 2000, label: '20,00 €' },
      { value: 2500, label: '25,00 €' },
      { value: 3000, label: '30,00 €' },
      { value: 3500, label: '35,00 €' },
      { value: 4000, label: '40,00 €' },
      { value: 4500, label: '45,00 €' },
      { value: 5000, label: '50,00 €' },
    ],
    validation: {
      dependsOn: {
        autoTopupType: 'balance',
      },
      isRequired: true,
    },
  },
  autoTopupMonthlyType: {
    name: '_topup_auto_monthly_type', // one of the inner radio groups!
    label: ui.guiTopupAutoMonthly,
    shouldSubmit: false,
    validation: {
      dependsOn: {
        autoTopupType: 'monthly',
      },
      isRequired: true,
    },
    options: [
      {
        label: ui.guiTopupAutoMonthly2Nd,
        value: AUTO_TOPUP_MONTHLY_VARIANT_B,
      },
      {
        label: ui.guiTopupAutoMonthly19Th,
        value: AUTO_TOPUP_MONTHLY_VARIANT_D,
      },
    ],
  },
});

// translates MVC value names to our values
const monthlyType = {
  timed: AUTO_TOPUP_MONTHLY_VARIANT_B,
  timed_date: AUTO_TOPUP_MONTHLY_VARIANT_D,
};

const mapStateToProps = (state, ownProps) => {
  const { fieldMap, formValues, context, params } = ownProps;
  const autoTopupAmountOptions = formValues[constants.FORM_FIELD_TOPUP_OPTIONS]
    .map(el => ({ label: money.toFullCurrency({ unit: el, currency: 'EUR' }), value: el }));

  const { user } = state;
  const { market } = user;
  const topup = user.topup || {};
  const { autoTopupStatus, autoTopupThreshold, autoTopupAmount } = topup;

  const props = { autoTopupAmountOptions };

  if (context === CONTEXT_SIM_ACTIVATION) {
    props.showSingleTopup = true;
    props.initialValues = {
      [fieldMap.topupType.name]: AUTO_TOPUP_TYPE_AUTO,
      [fieldMap.autoTopupType.name]: 'balance',
      [fieldMap.topupAmount.name]: autoTopupAmountOptions[1].value,
      [fieldMap.autoTopupTrigger.name]: fieldMap.autoTopupTrigger.options[2].value,
      [fieldMap.autoTopupMonthlyType.name]: AUTO_TOPUP_MONTHLY_VARIANT_B,
    };
  } else if (context === CONTEXT_AUTO_TOPUP) {
    props.market = market;
    props.params = {
      ...params,
      topupCopy: market === constants.MARKET_MMO ? params.mmoCopy : params.copy,
    };
    const topupType = !autoTopupStatus
      ? AUTO_TOPUP_TYPE_NONE
      : (market === constants.MARKET_MMO ? AUTO_TOPUP_TYPE_MMO : AUTO_TOPUP_TYPE_AUTO);

    props.initialValues = {
      [fieldMap.topupType.name]: topupType,
      [fieldMap.autoTopupType.name]:
        autoTopupStatus === 'threshold' ? 'balance' : 'monthly',
      [fieldMap.topupAmount.name]:
        autoTopupAmount
        || (autoTopupAmountOptions[1] && autoTopupAmountOptions[1].value)
        || autoTopupAmountOptions[0].value,
      [fieldMap.autoTopupTrigger.name]:
        autoTopupThreshold || fieldMap.autoTopupTrigger.options[2].value,
      [fieldMap.autoTopupMonthlyType.name]:
        monthlyType[autoTopupStatus] || AUTO_TOPUP_MONTHLY_VARIANT_B,
    };
  }

  return props;
};

// init component once, outside
const component = compose(
  connect(
    mapStateToProps,
  ),
  initForm(),
)(FormStepTopup);

const normalize = (formConfig, stepFormValues, stepProps) => {
  const { fieldMap } = formConfig;
  const normValues = { ...stepFormValues };
  const { context } = stepProps;

  const topupType = stepFormValues[fieldMap.topupType.name];
  const autoTopupType = stepFormValues[fieldMap.autoTopupType.name];
  const autoTopupMonthlyType = stepFormValues[fieldMap.autoTopupMonthlyType.name];
  const autoTopupTrigger = stepFormValues[fieldMap.autoTopupTrigger.name];
  const topupAmount = stepFormValues[fieldMap.topupAmount.name];

  if (context === CONTEXT_SIM_ACTIVATION) {
    if (topupType === AUTO_TOPUP_TYPE_AUTO) {
      normValues.autotopup_amount = topupAmount / 100;
      if (autoTopupType === 'balance') {
        normValues.autotopup_type = 'ACT'; // threshold as trigger
        normValues.autotopup_trigger = autoTopupTrigger / 100;
      } else if (autoTopupType === 'monthly') {
        normValues.autotopup_type = 'TCT';
        normValues.autotopup_trigger = autoTopupMonthlyType;
      }
    } else if (topupType === AUTO_TOPUP_TYPE_SINGLE) {
      normValues.singletopup_amount = topupAmount / 100;
    }
  } else if (context === CONTEXT_AUTO_TOPUP) {
    if (topupType === AUTO_TOPUP_TYPE_NONE) {
      normValues.auto_topup_status = '';
    } else {
      normValues.auto_topup_amount = topupAmount;
      if (topupType === AUTO_TOPUP_TYPE_MMO) {
        normValues.auto_topup_status = 'threshold_mmo';
      } else if (autoTopupType === 'balance') {
        normValues.auto_topup_status = 'threshold';
        normValues.auto_topup_threshold = autoTopupTrigger;
      } else if (autoTopupType === 'monthly') {
        normValues.auto_topup_status = autoTopupMonthlyType === AUTO_TOPUP_MONTHLY_VARIANT_B
          ? 'timed_monthly'
          : 'timed_date';
      }
    }
  }

  return normValues;
};

export const id = 'topup';

/**
 * @return {FormConfig}
 */
export const mapStateToFormConfig = (state, props) => ({
  component,
  fieldMap: createFieldMap(state),
  normalize,
  label: state.ui.guiWordTopup,
  formConfigProps: new FormConfigProps({
    labelSubmit: (props.context === CONTEXT_SIM_ACTIVATION)
      ? state.ui.guiWordContinue
      : (props.context === CONTEXT_AUTO_TOPUP)
        ? state.ui.guiWordSave
        : state.ui.btnContinue,
    labelCancel: (props.context === CONTEXT_AUTO_TOPUP)
      ? state.ui.guiWordCancel : state.ui.guiWordBack,
  }),
});

export default mapStateToFormConfig;
