import React from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';

const MediaSvgData = props => (
  <span
    className={suitcss({
      componentName: 'Icon',
      modifiers: [
        props.inverted && 'inverted',
      ],
      utilities: props.utilities,
    })}
    dangerouslySetInnerHTML={{ __html: props.data }}
  />
);

MediaSvgData.propTypes = {
  inverted: PropTypes.bool,
  utilities: PropTypes.array,
  data: PropTypes.string,
};

export default MediaSvgData;
