import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';
import TextLink from '../../basics/text/TextLink';
import TextCopy from '../../basics/text/TextCopy';

class CustomerServiceDialogItem extends PureComponent {
  render() {
    const {
      parent,
      headline,
      buttonText,
      buttonAction,
      isChatLauncher,
    } = this.props;

    const componentName = 'QuiqChatLaunch';
    return (
      <li
        className={suitcss({ componentName, descendantName: 'option', modifiers: [isChatLauncher && 'quiq-launcher'] }, parent)}
        onClick={buttonAction}
      >
        <TextCopy
          className={suitcss({ descendantName: 'optionLabel' }, parent)}
          utilities={['weightBold']}
          embedded
        >
          {headline}
        </TextCopy>
        <TextLink
          className={suitcss({ descendantName: '' }, parent)}
          asButton
          buttonFilled
          withoutArrow
          element="button"
        >
          {buttonText}
        </TextLink>
      </li>
    );
  }
}

CustomerServiceDialogItem.propTypes = {
  parent: PropTypes.object.isRequired,
  headline: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonAction: PropTypes.func.isRequired,
};

export default CustomerServiceDialogItem;
