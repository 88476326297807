import { ACTION_PREFIX } from '../../helpers/constants';

export const LOGOUT = `${ACTION_PREFIX}/LOGOUT`;

/**
 * Will logout the user.
 *
 * If an error is supplied, an alert will be displayed with the
 * verbalized error message.
 *
 * @param {AbstractError} error
 */
export const logout = ({ error } = {}) => ({
  type: LOGOUT,
  payload: { error },
});
