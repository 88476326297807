import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';
import connectUI from '../../basics/ui/UIConnector';
import Headline from '../../basics/text/TextHeadline';
import SvgLoader from '../../basics/media/MediaSvgLoader';


const componentName = 'MyAvatarSelector';
class MyAvatarSelector extends PureComponent {

  constructor(props, context) {
    super(props, context);
    this.state = { selectedItemId: props.selectedItem ? props.selectedItem.id : null };
    this.onSelectItem = this.onSelectItem.bind(this);
  }

  onSelectItem(id) {
    this.setState({ selectedItemId: id });
    this.props.onSelect(id);
  }

  renderItem(item, key) {
    const { selectedItemId } = this.state;
    return (
      <div
        className={suitcss({
          componentName,
          descendantName: 'item',
          states: [selectedItemId === item.id && 'active'],
        })}
        key={key}
      >
        <button onClick={() => this.onSelectItem(item.id)} >
          <SvgLoader path={item.url} />
        </button>
      </div>
    );
  }

  render() {
    const { items, ui } = this.props;
    return (
      <div className={suitcss({ componentName })}>
        <Headline>{ui.myProfileChangeAvatar}</Headline>
        <div className={suitcss({ componentName, descendantName: 'items' })} >
          {items.map(this.renderItem, this)}
        </div>
      </div>
    );
  }
}

MyAvatarSelector.propTypes = {
  selectedItem: PropTypes.object,
  items: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  ui: PropTypes.shape({
    guiWordSave: PropTypes.string,
    myProfileChangeAvatar: PropTypes.string,
  }),

};

export default connectUI()(MyAvatarSelector);
