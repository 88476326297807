/* global document */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import suitcss from '../../../../helpers/suitcss';

import {
  CONTEXT_CONTRACT_RENEWAL,
  CONTEXT_TARIFF_CHANGE,
  CONTEXT_TARIFF_OPTION,
  CUSTOM_EVENT_EQUAL_RESIZE,
} from '../../../../helpers/constants';
import Headline from '../../../basics/text/TextHeadline';
import Copy from '../../../basics/text/TextCopy';
import Link from '../../../basics/text/TextLink';
import SvgLoader from '../../../basics/media/MediaSvgLoader';
import Callout from '../../../../containers/callout/Callout';
import OffersPanel from './OffersPanel';
import { fetchEntitiesByIds } from '../../../../actions/request/registry';
import { isTariffOptionVO, isTariffVO } from '../../../../helpers/entity';
import LazyShowElement from '../../../basics/element/LazyShowElement';
import TariffFilter from '../../../../containers/contractRenewal/TariffFilter';

class OffersList extends PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = { isShowingAll: !props.withHighlights };
    this.toggleShowingAll = this.toggleShowingAll.bind(this);
  }

  componentDidMount() {
    const { dispatch, items } = this.props;
    // fetches the entities that are needed to display the callout badges
    dispatch(fetchEntitiesByIds(
      items.filter(item => item.etype === 'tariffVO').map(item => item.eid).filter(eid => eid)),
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const { isShowingAll } = this.state;
    if (process.browser && isShowingAll && prevState.isShowingAll !== isShowingAll) {
      const event = document.createEvent('Event');
      event.initEvent(CUSTOM_EVENT_EQUAL_RESIZE, true, true);
      this.container.dispatchEvent(event);
    }
  }

  getTaglessItems() {
    const { items, tags } = this.props;
    return tags
      ? items.filter(item => !item.tags || !tags.some(tag => item.tags.includes(tag.id)))
      : [];
  }

  isDarkTheme() {
    return this.props.theme === 'dark';
  }

  toggleShowingAll() {
    this.setState({ isShowingAll: !this.state.isShowingAll });
  }

  renderHeadline(headline) {
    return (
      <div className={suitcss({ descendantName: 'headline' }, this)} >
        <Headline
          size="l"
          element="h2"
          embedded
          utilities={[
            this.isDarkTheme() && 'colorInverted',
            'weightNormal',
          ]}
        >
          {headline}
        </Headline>
      </div>
    );
  }

  renderCopy(copy) {
    return (
      <div className={suitcss({ descendantName: 'copy' }, this)} >
        <Copy utilities={[this.isDarkTheme() && 'colorInverted']} embedded raw >
          {copy}
        </Copy>
      </div>
    );
  }

  renderList(items, tag, key) {
    const { subline } = this.props;
    const listItems = !tag ? items : items.filter(item => item.tags && item.tags.includes(tag.id));
    return (
      <div className={suitcss({ descendantName: 'list' }, this)} key={key}>
        {tag && (
          <LazyShowElement element="header" className={suitcss({ descendantName: 'header' }, this)} >
            {tag.iconWeb && (
              <SvgLoader path={tag.iconWeb} />
            )}
            {tag.headline && (
              <div className={suitcss({ descendantName: 'headline' }, this)} >
                <Headline
                  size="m"
                  element="h3"
                  embedded
                  utilities={[
                    'weightNormal',
                    'lowercase',
                    'colorPrimary',
                  ]}
                >
                  {tag.headline}
                </Headline>
                {subline && (
                  <Headline
                    size="m"
                    element="h4"
                    embedded
                    utilities={[
                      'weightNormal',
                      'lowercase',
                    ]}
                  >
                    {subline}
                  </Headline>
                )}
              </div>
            )}
          </LazyShowElement>
        )}
        <div className={suitcss({ descendantName: 'items' }, this)} >
          {listItems.map((item, index) => this.renderItem(item, index, tag))}
        </div>
      </div>
    );
  }

  renderItem(item, index, tag) {
    const { theme, moduleId, onSelect, onChange } = this.props;
    const eqGroup = `${moduleId}-${tag ? `${tag.id}-` : ''}row${Math.floor(index / 2)}`;
    const isTypeTariff = isTariffVO(item);
    const isTypeTariffOption = isTariffOptionVO(item);
    const getContext = () => {
      if (isTypeTariffOption) {
        return CONTEXT_TARIFF_OPTION;
      } else if (item.renewable || item.renewContract) {
        return CONTEXT_CONTRACT_RENEWAL;
      }
      return CONTEXT_TARIFF_CHANGE;
    };
    return (
      <LazyShowElement className={suitcss({ descendantName: 'item' }, this)} key={index} >
        <div className={suitcss({ descendantName: 'callouts' }, this)}>
          {isTypeTariff && (
            <div className={suitcss({ descendantName: 'labelCallout' }, this)} >
              <div data-eq-group-l={`${eqGroup}-callout`} data-eq-group-m={`${eqGroup}-callout`}>
                <Callout
                  theme="label"
                  context={getContext()}
                  targets={[item.eid]}
                />
              </div>
            </div>
          )}
        </div>
        <OffersPanel
          theme={theme}
          item={item}
          inverted={this.isDarkTheme()}
          equalizerGroupM={eqGroup}
          equalizerGroupL={eqGroup}
          onSelect={onSelect}
          onChange={onChange}
        />
      </LazyShowElement>
    );
  }

  renderTariffFilter() {
    const {
      items,
      theme,
      moduleId,
      renewalFee,
      onChange,
    } = this.props;
    return (
      <TariffFilter
        items={items}
        theme={theme}
        moduleId={moduleId}
        onChange={onChange}
        renewalFee={renewalFee}
      />
    );
  }

  render() {
    const {
      headline,
      copy,
      items,
      tags,
      withHighlights,
      ui,
      tariffList,
      isTariffRenewable,
      additionalHeader,
    } = this.props;
    const { isShowingAll } = this.state;
    const taglessItems = this.getTaglessItems();
    return (
      <section
        className={suitcss({}, this)}
        ref={(ref) => { this.container = ref; }}
      >
        {additionalHeader && (
          <LazyShowElement className={suitcss({ descendantName: 'additionalHeader' }, this)} >
            {additionalHeader.headline && this.renderHeadline(additionalHeader.headline)}
            {additionalHeader.copy && this.renderCopy(additionalHeader.copy)}
          </LazyShowElement>
        )}
        {(headline || copy) && (
          <LazyShowElement element="header" className={suitcss({ descendantName: 'header' }, this)} >
            {headline && this.renderHeadline(headline)}
            {copy && this.renderCopy(copy)}
          </LazyShowElement>
        )}
        {!tags && (!tariffList || !isTariffRenewable) && this.renderList(items)}
        {tags && (!tariffList || !isTariffRenewable) && !isShowingAll &&
          this.renderList(items, tags[0])}
        {tags && (!tariffList || !isTariffRenewable) && isShowingAll &&
          tags.map((tag, index) => this.renderList(items, tag, index))}
        {tags && (!tariffList || !isTariffRenewable) && isShowingAll && !!taglessItems.length &&
          this.renderList(taglessItems)}
        {withHighlights && (
          <div className={suitcss({ descendantName: 'link' }, this)} >
            <Link onClick={this.toggleShowingAll} element="button" asButton>
              {isShowingAll ? ui.guiActionShowLess : ui.guiActionShowAll}
            </Link>
          </div>
        )}
        {(tariffList && isTariffRenewable) && this.renderTariffFilter()}
      </section>
    );
  }
}

OffersList.propTypes = {
  headline: PropTypes.string,
  tariffList: PropTypes.bool,
  isTariffRenewable: PropTypes.bool,
  subline: PropTypes.string,
  copy: PropTypes.string,
  theme: PropTypes.string,
  items: PropTypes.array.isRequired,
  tags: PropTypes.array,
  renewalFee: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  withHighlights: PropTypes.bool,
  moduleId: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  ui: PropTypes.shape({
    guiActionShowLess: PropTypes.string,
    guiActionShowAll: PropTypes.string,
  }),
  dispatch: PropTypes.func.isRequired,
  additionalHeader: PropTypes.shape({
    headline: PropTypes.string,
    copy: PropTypes.string,
  }),
};

const mapStateToProps = ({ ui }) => ({ ui });

export default connect(mapStateToProps)(OffersList);
