import React from 'react';
import PropTypes from 'prop-types';

import suitcss from '../../../helpers/suitcss';
import { shape as optionShape } from '../../../propTypes/tariffOption';

import Copy from '../../basics/text/TextCopy';
import FormOptionBox from '../../basics/form/FormOptionBox';
import FootnoteReference from '../../../containers/footnote/FootnoteReference';

const componentName = 'ShoppingCartOption';
function ShoppingCartOption(props) {
  const { theme, item, selected, onChange, isConfirmed, disabled } = props;
  const { eid, type, name, copy } = item;
  const label = `${type} ${name}`;
  const isCompact = theme === 'compact' || theme === 'widget';
  return (
    <div
      className={suitcss({
        componentName,
        modifiers: [theme],
        utilities: [isCompact && 'fontCondensed', isCompact && 'textSizeSecondary'],
      })}
    >
      {!isConfirmed &&
        <FormOptionBox
          id={item.eid}
          label={label}
          value={eid}
          checked={selected}
          onChange={onChange}
          disabled={disabled}
          info={theme === 'full'
            ? { headline: label, copy }
            : null
          }
        />
      }
      {isConfirmed &&
        <Copy
          element="span"
          utilities={[isCompact && 'fontCondensed', isCompact && 'textSizeInherit']}
          embedded
        >
          {`+ ${label}`}
          {theme === 'compact' &&
            <FootnoteReference id={item.eid} />
          }
        </Copy>
      }
    </div>
  );
}

ShoppingCartOption.propTypes = {
  theme: PropTypes.oneOf(['full', 'compact', 'widget']),
  item: optionShape.isRequired,
  onChange: PropTypes.func,
  selected: PropTypes.bool,
  isConfirmed: PropTypes.bool,
  disabled: PropTypes.bool,
};

ShoppingCartOption.defaultProps = {
  theme: 'full',
  selected: false,
};

export default ShoppingCartOption;
