import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';
import Accordion from '../../basics/accordion/Accordion';
import AccordionSection from '../../basics/accordion/AccordionSection';
import Copy from '../../basics/text/TextCopy';

function MyESimInfoDialog(props) {
  const { subHl1, subHl2, copy1, copy2 } = props;
  const label1 = `<span class="u-weightBold u-marginRightXS">${subHl1}</span>`;
  const label2 = `<span class="u-weightBold u-marginRightXS">${subHl2}</span>`;
  return (
    <div className={suitcss({}, this)}>
      <div className={suitcss({ descendantName: 'layer' }, this)}>
        <div className={suitcss({ descendantName: 'content' }, this)}>
          <div className="u-marginV">
            <Accordion>
              <AccordionSection
                label={label1}
                embedded
                theme="primary"
                arrowLeft
              >
                <Copy utilities={['marginTopS']} raw>{copy1}</Copy>
              </AccordionSection>
            </Accordion>
          </div>
          <div className="u-marginV">
            <Accordion>
              <AccordionSection
                label={label2}
                embedded
                theme="primary"
                arrowLeft
              >
                <Copy utilities={['marginTopS']} raw>{copy2}</Copy>
              </AccordionSection>
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
}

MyESimInfoDialog.propTypes = {
  ui: PropTypes.object,
  subHl1: PropTypes.string,
  subHl2: PropTypes.string,
  copy1: PropTypes.string,
  copy2: PropTypes.string,
};

export default connect()(MyESimInfoDialog);
