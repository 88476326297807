/* global document, window */
import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { createSelector } from 'reselect';
import shallowEqual from 'react-pure-render/shallowEqual';
import { send } from '../../actions/request/send';
import { bindQueryParams } from '../../helpers/url';
import { isTariffVO, isTariffOptionVO } from '../../helpers/entity';
import { scrollToHash } from '../../helpers/navigation';
import {
  MARKET_POSTPAID,
  NOTIFICATION_TYPE_LOW_VOLUME,
  NOTIFICATION_TYPE_LOW_BALANCE,
  NOTIFICATION_TYPE_PAUSED,
  QUERY_INTERNAL_ID,
  QUERY_IS_BOOKED,
  STORAGE_KEY_BELATED_MNP_DIALOG,
  MARKET_MMO,
  SCOPE_MYTERMINATION,
  MARKET_PREPAID,
  SCOPE_MYFRIEND_READ,
  SCOPE_MYFRIEND,
  SCOPE_NOTIFICATIONS,
  SCOPE_MYSIMCARD_ESIM,
} from '../../helpers/constants';
import * as registryActions from '../../actions/request/registry';
import QueueableRequest from '../../model/requests/QueueableRequest';
import * as topupActions from '../../actions/dialog/topupActions';
import * as statusTariffActions from '../../actions/dialog/statusTariffActions';
import * as eSimActions from '../../actions/dialog/eSimActions';
import GlobalSection from '../../components/basics/global/GlobalSection';
import DashboardStatus from '../../components/compositions/account/dashboard/DashboardStatus';
import DashboardSummary from '../../components/compositions/account/dashboard/DashboardSummary';
import DashboardWrapper from '../../components/compositions/account/dashboard/DashboardWrapper';
import ContentLoader from '../../components/compositions/content/ContentLoader';
import {
  MNP_STATUS_CAN,
  MNP_STATUS_CPL,
  showBelatedMnpInProgressDialog as showBelatedMnpDialog,
} from '../../actions/dialog/myBelatedMnpActions';
import { setItem, getItem, STORAGE_TYPE_COOKIE } from '../../helpers/storage';
import { MONTH } from '../../helpers/date';
import {
  getConsentInquiryDialog,
  showEmailValidationDialog,
} from '../../actions/dialog/misc';
import Headline from '../../components/basics/text/TextHeadline';
import Copy from '../../components/basics/text/TextCopy';
import Link from '../../components/basics/text/TextLink';
import suitcss from '../../helpers/suitcss';
import {
  blockContentBasedOnUserScope,
  isScopeAllowed,
} from '../../helpers/scope';
import { getUserLoyaltySettings } from '../../helpers/user';
import MyOfferTiles from './MyOfferTiles';
import SvgLoader from '../../components/basics/media/MediaSvgLoader';

class MyDashboard extends PureComponent {
  constructor(props) {
    super(props);
    this.checkOfferTilesVisibility = this.checkOfferTilesVisibility.bind(this);
    this.state = {
      isOfferTilesVisible: false,
      isScrollIndicatorVisible: false,
    };
  }

  async componentDidMount() {
    const {
      market,
      fetchConsumptions,
      fetchFlatrates,
      fetchTariffSummary,
      fetchBookingOverview,
      fetchBalance,
      fetchCumulativeConsumption,
      fetchTopupStatus,
      fetchAccountNotification,
      fetchEmailValidationStatus,
      fetchContractData,
      fetchCustomerData,
      showBelatedMnpInProgressDialog,
      credentials,
      showConsentsInquiryDialog,
      fetchESimReadyToPair,
      showESimReadyToPair,
      isUserESimReadyToPairEnabled,
    } = this.props;

    const { msisdn } = credentials;
    const isPostPaid = market === MARKET_POSTPAID;

    // @todo move to MyDashboardModule prepareBeforeMount()
    fetchCustomerData({ priority: QueueableRequest.PRIO_70 });
    fetchConsumptions({ priority: QueueableRequest.PRIO_90 });
    fetchFlatrates({ priority: QueueableRequest.PRIO_80 });
    fetchBalance({ priority: QueueableRequest.PRIO_70 });
    fetchTariffSummary({ priority: QueueableRequest.PRIO_50 });
    fetchBookingOverview({ priority: QueueableRequest.PRIO_100 });

    showConsentsInquiryDialog();

    if (market !== MARKET_MMO) {
      fetchEmailValidationStatus({ priority: QueueableRequest.PRIO_50 });
    }

    if (market === MARKET_PREPAID) {
      fetchCumulativeConsumption({ priority: QueueableRequest.PRIO_70 });
    }

    const data = await fetchContractData({ priority: QueueableRequest.PRIO_40 });
    const belatedMnpDialog = getItem(
      STORAGE_TYPE_COOKIE,
      STORAGE_KEY_BELATED_MNP_DIALOG,
    );
    if (
      data.belatedMnp &&
      (!belatedMnpDialog || !belatedMnpDialog[msisdn])
    ) {
      const { state } = data.belatedMnp;
      if (!(state === MNP_STATUS_CPL || state === MNP_STATUS_CAN)) {
        showBelatedMnpInProgressDialog();
        setItem(STORAGE_TYPE_COOKIE, STORAGE_KEY_BELATED_MNP_DIALOG, {
          [msisdn]: true,
          ...belatedMnpDialog,
        }, {
          expires: new Date(Date.now() + MONTH),
          path: '/',
        });
      }
    }

    if (isPostPaid) {
      this.timeout = setTimeout(() => {
        fetchAccountNotification(NOTIFICATION_TYPE_LOW_VOLUME);
      }, 3000);
    } else {
      fetchTopupStatus({ priority: QueueableRequest.PRIO_60 });
      this.timeout = setTimeout(() => {
        fetchAccountNotification(NOTIFICATION_TYPE_LOW_BALANCE);
        fetchAccountNotification(NOTIFICATION_TYPE_PAUSED);
      }, 3000);
    }

    if (market !== MARKET_MMO && this.props.customerData && this.props.customerData.status) {
      this.whenEmailIsNotValid(this.props.customerData);
    }

    if (isUserESimReadyToPairEnabled) {
      const isReadyToPair = await fetchESimReadyToPair();

      if (isReadyToPair) {
        showESimReadyToPair();
      }
    }

    this.timeout = setTimeout(() => {
      this.checkOfferTilesVisibility();
    }, 3000);
    document.addEventListener('scroll', this.checkOfferTilesVisibility);
  }

  componentWillUpdate(nextProps) {
    const { market } = nextProps;
    if (market && market !== MARKET_MMO && this.props.customerData) {
      this.whenEmailIsNotValid(nextProps);
    }
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    document.removeEventListener('scroll', this.checkOfferTilesVisibility);
  }

  whenEmailIsNotValid(nextProps) {

    if (!shallowEqual(this.props.customerData, nextProps.customerData)
      && nextProps.customerData && (nextProps.customerData.status === 'pending' ||
        nextProps.customerData.status === 'required')) {
      this.props.showEmailValidation();
    }
  }

  checkOfferTilesVisibility() {
    const { isOfferTilesVisible } = this.state;
    const offerTilesSection = document.querySelector('#MyOfferTiles');
    const offerTilesHeight = offerTilesSection && offerTilesSection.offsetHeight;
    const bounds = offerTilesSection && offerTilesSection.getBoundingClientRect();
    const offset = 20;

    if (isOfferTilesVisible || !offerTilesSection) {
      document.removeEventListener('scroll', this.checkOfferTilesVisibility);
      return;
    }

    if (bounds && offerTilesHeight) {
      this.setState({
        isOfferTilesVisible: bounds.top + offset < window.innerHeight,
      });
    }

    this.setState({
      isScrollIndicatorVisible: offerTilesSection && !isOfferTilesVisible,
    });
  }

  renderStatus() {
    const {
      consumptions,
      consumptionsIndividual,
      balance,
      flatrates,
      topup,
      market,
      performSingleTopup,
      performAutoTopup,
      performVoucherTopup,
      sitemap,
      showBalanceDialog,
      showConsumptionsDialog,
      showConsumptionsIndividualDialog,
      cumulativeConsumption,
      user,
      ui,
    } = this.props;
    const loyaltySettings = getUserLoyaltySettings(user, ui);
    return (
      <ContentLoader
        height={380}
        isLoaded={!!(consumptions || flatrates || balance)}
      >
        <DashboardStatus
          loyaltySettings={loyaltySettings}
          market={market}
          consumptions={consumptions}
          consumptionsIndividual={consumptionsIndividual}
          flatrates={flatrates}
          balance={balance}
          topup={topup}
          cumulativeConsumption={cumulativeConsumption}
          onTopup={performSingleTopup}
          onAutoTopup={performAutoTopup}
          onBalanceDetails={showBalanceDialog}
          onConsumptionDetails={showConsumptionsDialog}
          onConsumptionsIndividual={showConsumptionsIndividualDialog}
          onVoucherTopup={performVoucherTopup}
          urlBilling={sitemap.MyInboxRoute.url}
          withoutFlatrates={flatrates && flatrates.length === 0}
          withoutConsumptions={consumptions && consumptions.length === 0}
          withoutConsumptionsIndividual={
            consumptionsIndividual && consumptionsIndividual.length === 0
          }
        />
      </ContentLoader>
    );
  }

  renderSummary() {
    const {
      market,
      isTariffRenewable,
      withLinkToTariffs,
      withLinkToOptions,
      bookingOverview,
      bookingOverviewItems,
      withdrawTariffCancellation,
      cancelTariffOption,
      credentials: { msisdn },
      ui,
      sitemap,
    } = this.props;
    return (
      <ContentLoader isLoaded={!!(bookingOverview && bookingOverview.bookedTariff)} height={380}>
        <DashboardSummary
          market={market}
          isTariffRenewable={isTariffRenewable}
          withLinkToTariffs={withLinkToTariffs}
          withLinkToOptions={withLinkToOptions}
          bookingOverview={bookingOverview}
          bookingOverviewItems={bookingOverviewItems}
          bookedTariff={bookingOverview && bookingOverview.bookedTariff}
          isPUC={market === MARKET_POSTPAID}
          msisdn={msisdn}
          ui={ui}
          withdrawTariffCancellation={withdrawTariffCancellation}
          cancelTariffOption={cancelTariffOption}
          sitemap={sitemap}
        />
      </ContentLoader>
    );
  }

  renderDashboard() {
    const {
      consumptions,
      balance,
      flatrates,
      bookingOverview,
    } = this.props;
    const isLoaded = !!(consumptions || balance || flatrates
      || (bookingOverview && bookingOverview.bookedTariff));
    return (
      <ContentLoader isLoaded={isLoaded} height={380}>
        <DashboardWrapper>
          {this.renderStatus()}
          {this.renderSummary()}
        </DashboardWrapper>
      </ContentLoader>
    );
  }

  renderMessage() {
    const { ui } = this.props;
    return (
      <div className={suitcss({ descendantName: 'message', utilities: ['alignCenter', 'sPaddingBottomXL', 'mlPaddingVXL'] }, this)}>
        {ui.myDashboardContractStatusTerminatedAvatar &&
          <SvgLoader
            path={ui.myDashboardContractStatusTerminatedAvatar.replace('%gui.baseurl%', '')}
            utilities={['sCol6', 'mCol3', 'lCol2', 'inlineBlock', 'marginBottom']}
          />
        }
        <Headline size="l" utilities={['marginBottom']}>{ui.myDashboardContractStatusTerminatedHeadline}</Headline>
        <Copy raw utilities={['col12']} embedded>{ui.txtMyDashboardContractStatusTerminatedCopy}</Copy>
      </div>
    );
  }

  renderFriendReferralBanner() {
    const { ui, sitemap, onFriendReferralBannerClick } = this.props;
    return (
      <div
        className={suitcss({ descendantName: 'referralBanner' }, this)}
        onClick={onFriendReferralBannerClick}
      >
        <SvgLoader path="/icons/icon-friends.svg" />
        <Copy
          utilities={['weightBold', 'marginLeftS']}
          size="tertiary"
          embedded
          tertiary
          condensed
          uppercase
        >
          {ui.myFrdGlobalBannerLabel}
        </Copy>
        <Link
          className={suitcss({ descendantName: 'referralBannerButton' }, this)}
          to={sitemap.MyFriendReferralDashboardRoute.url}
          buttonFilled
          asButton
          buttonWithoutLabel
        />
      </div>
    );
  }

  render() {
    const {
      credentials: { msisdn },
      isTerminated,
      ui,
      isTariffRenewable,
      isUserReferFriendsEnabled,
    } = this.props;

    const { isOfferTilesVisible, isScrollIndicatorVisible } = this.state;

    if (!msisdn) return null;
    const utilities = isTerminated ? ['row', 'itemsCenter'] : [];
    const theme = isTerminated ? '' : 'light';
    return (
      <Fragment>
        {isUserReferFriendsEnabled && !isTerminated && (
          <GlobalSection
            className={suitcss({ utilities: ['smPaddingTopS', 'PaddingTop'] }, this)}
            theme="light"
            layout="contained"
            adjacent
          >
            {this.renderFriendReferralBanner()}
          </GlobalSection>
        )}
        <GlobalSection className={suitcss({ modifiers: [isTerminated && 'placeholder'], utilities }, this)} theme={theme} layout="contained">
          {isTerminated && this.renderMessage()}
          {!isTerminated && this.renderDashboard()}
        </GlobalSection>
        <MyOfferTiles id="MyOfferTiles" isTariffRenewable={isTariffRenewable} />
        <div className={suitcss({
            descendantName: 'scrollIndicator',
            states: [!isOfferTilesVisible && isScrollIndicatorVisible && 'visible'],
          }, this)}
        >
          <Copy
            onClick={() => scrollToHash('#MyOfferTiles', { contentAware: false, offset: 70, delay: 100 })}
            uppercase
            inverted
            condensed
            raw
            size="tertiary"
            utitilies={['weightBold']}
          >
            {ui.guiWordOffers}
          </Copy>
        </div>
      </Fragment>
    );
  }
}

MyDashboard.propTypes = {
  market: PropTypes.string,
  credentials: PropTypes.object,
  balance: PropTypes.object,
  cumulativeConsumption: PropTypes.object,
  consumptions: PropTypes.array,
  consumptionsIndividual: PropTypes.array,
  flatrates: PropTypes.array,
  bookingOverview: PropTypes.object,
  topup: PropTypes.object,
  ui: PropTypes.object,
  user: PropTypes.object,
  customerData: PropTypes.object,
  isTariffRenewable: PropTypes.bool,
  withLinkToTariffs: PropTypes.bool,
  withLinkToOptions: PropTypes.bool,
  showConsumptionsDialog: PropTypes.func.isRequired,
  showConsumptionsIndividualDialog: PropTypes.func.isRequired,
  showBalanceDialog: PropTypes.func.isRequired,
  fetchAccountNotification: PropTypes.func.isRequired,
  fetchTopupStatus: PropTypes.func.isRequired,
  fetchConsumptions: PropTypes.func.isRequired,
  fetchFlatrates: PropTypes.func.isRequired,
  fetchTariffSummary: PropTypes.func.isRequired,
  fetchBookingOverview: PropTypes.func.isRequired,
  fetchBalance: PropTypes.func.isRequired,
  fetchContractData: PropTypes.func.isRequired,
  performSingleTopup: PropTypes.func.isRequired,
  performAutoTopup: PropTypes.func.isRequired,
  performVoucherTopup: PropTypes.func.isRequired,
  fetchEmailValidationStatus: PropTypes.func.isRequired,
  sitemap: PropTypes.object.isRequired,
  fetchCustomerData: PropTypes.func.isRequired,
  showBelatedMnpInProgressDialog: PropTypes.func.isRequired,
  showEmailValidation: PropTypes.func.isRequired,
  isTerminated: PropTypes.bool.isRequired,
  showConsentsInquiryDialog: PropTypes.func.isRequired,
  isUserReferFriendsEnabled: PropTypes.bool.isRequired,
  showESimReadyToPair: PropTypes.func,
  fetchESimReadyToPair: PropTypes.func,
  isUserESimReadyToPairEnabled: PropTypes.bool,
  onFriendReferralBannerClick: PropTypes.func,
  cancelTariffOption: PropTypes.func,
  withdrawTariffCancellation: PropTypes.func,
  bookingOverviewItems: PropTypes.array,
};

/*
  Inject props to conform with our codebase
 */
const normalizeItems = (items, sitemap, market) => (
  items && items.map(item => {
    const isTariff = isTariffVO(item);
    const isOption = isTariffOptionVO(item);
    const urlDetails = (
      (isTariff && sitemap.MyTariffDetailsRoute.url) ||
      (isOption && sitemap.MyTariffOptionDetailsRoute.url)
    );
    return {
      market,
      ...(item.original || item),
      urlDetails: urlDetails ? bindQueryParams(urlDetails, {
        [QUERY_INTERNAL_ID]: item.id,
        [QUERY_IS_BOOKED]: 1,
      }) : '',
    };
  })
);

const makeStateToProps = () => {
  const marketSelector = state => state.user.market;
  const tariffEntitiesSelector = state => state.entities.tariffVO;
  const bookableTariffsSelector = state => state.user.bookableTariffs;
  const bookableTariffOptionsSelector = state => state.user.bookableTariffOptions;
  const sitemapSelector = state => state.site.sitemap;
  const bookingOverviewSelector = state => state.user.bookingOverview;

  const normalizeBookingOverviewSelector = createSelector(
    bookingOverviewSelector,
    sitemapSelector,
    marketSelector,
    (bookingOverview, sitemap, market) => {
      // make new object bookingOverview
      if (bookingOverview && bookingOverview.bookedTariff) {
        return {
          ...bookingOverview,
          bookedTariff: normalizeItems([bookingOverview.bookedTariff], sitemap, market)[0],
          ...(bookingOverview.aggregatedVolumes && {
            aggregatedVolumes: {
              ...bookingOverview.aggregatedVolumes,
              items: normalizeItems(bookingOverview.aggregatedVolumes.items, sitemap, market),
            },
          }),
          ...(bookingOverview.aggregatedCosts && {
            aggregatedCosts: {
              ...bookingOverview.aggregatedCosts,
              items: normalizeItems(bookingOverview.aggregatedCosts.items, sitemap, market),
            },
          }),
          ...(bookingOverview.additionalItems && {
            additionalItems: normalizeItems(bookingOverview.additionalItems, sitemap, market),
          }),
        };
      }
    },
  );

  const bookingOverviewItemsSelector = createSelector(
    bookingOverviewSelector,
    (bookingOverview) => {
      if (bookingOverview && bookingOverview.bookedTariff) {
        return [
          bookingOverview.bookedTariff,
          bookingOverview.aggregatedVolumes.items,
          bookingOverview.aggregatedCosts.items,
          bookingOverview.additionalItems,
        ];
      }
    },
  );

  const withLinkToTariffsSelector = createSelector(
    marketSelector,
    bookableTariffsSelector,
    (market, bookables) => {
      const isPostPaid = market === MARKET_POSTPAID;
      return bookables !== undefined || !isPostPaid ?
        isPostPaid && bookables && bookables.length > 0 : undefined;
    },
  );

  const withLinkToOptionsSelector = createSelector(
    bookableTariffOptionsSelector,
    (bookables) => (bookables && bookables.length > 0),
  );

  const isTariffRenewableSelector = createSelector(
    marketSelector,
    bookableTariffsSelector,
    tariffEntitiesSelector,
    (market, bookables, entities) => {
      const isPostPaid = market === MARKET_POSTPAID;
      return isPostPaid && bookables && bookables.some(iid => entities[iid] && (
        entities[iid].contractRenewalWithHardwareOnly ||
        entities[iid].contractRenewalWithSimOnly
      ));
    },
  );

  return (state, ownProps) => ({
    user: state.user,
    market: state.user.market,
    consumptions: state.user.consumptions,
    consumptionsIndividual: state.user.consumptionsIndividual,
    flatrates: state.user.flatrates,
    balance: state.user.balance,
    topup: state.user.topup,
    credentials: state.user.credentials,
    cumulativeConsumption: state.user.cumulativeConsumption,
    sitemap: state.site.sitemap,
    bookingOverview: normalizeBookingOverviewSelector(state),
    bookingOverviewItems: bookingOverviewItemsSelector(state),
    isTariffRenewable: isTariffRenewableSelector(state),
    withLinkToTariffs: withLinkToTariffsSelector(state),
    withLinkToOptions: withLinkToOptionsSelector(state),
    ui: ownProps.ui,
    customerData: state.user.customerData,
    isTerminated: blockContentBasedOnUserScope(state.user.scope, SCOPE_MYTERMINATION),
    isUserReferFriendsEnabled: isScopeAllowed(state.user.scope, SCOPE_MYFRIEND) ||
      isScopeAllowed(state.user.scope, SCOPE_MYFRIEND_READ),
    isUserESimReadyToPairEnabled: isScopeAllowed(state.user.scope, SCOPE_NOTIFICATIONS)
      && isScopeAllowed(state.user.scope, SCOPE_MYSIMCARD_ESIM)
      && state.user.market === MARKET_POSTPAID,
  });
};

const mapDispatchToProps = {
  push,
  fetchEmailValidationStatus: registryActions.fetchEmailValidationStatus,
  fetchCustomerData: registryActions.fetchCustomerData,
  fetchContractData: registryActions.fetchContractData,
  fetchTopupStatus: registryActions.fetchTopupStatus,
  fetchConsumptions: registryActions.fetchConsumptions,
  fetchFlatrates: registryActions.fetchFlatrates,
  fetchTariffSummary: registryActions.fetchTariffSummary,
  fetchBookingOverview: registryActions.fetchBookingOverview,
  fetchBalance: registryActions.fetchBalance,
  fetchCumulativeConsumption: registryActions.fetchMonthlyConsumption,
  fetchAccountNotification: statusTariffActions.fetchAccountNotification,
  performSingleTopup: topupActions.performSingleTopup,
  performAutoTopup: topupActions.performAutoTopup,
  performVoucherTopup: topupActions.performVoucherTopup,
  cancelTariffOption: statusTariffActions.cancelTariffOption,
  withdrawTariffCancellation: statusTariffActions.withdrawTariffCancellation,
  showConsumptionsDialog: statusTariffActions.showConsumptionDetailsDialog,
  showConsumptionsIndividualDialog: statusTariffActions.showConsumptionsIndividualDialog,
  showBalanceDialog: statusTariffActions.showBalanceDetailsDialog,
  showEmailValidation: showEmailValidationDialog,
  showConsentsInquiryDialog: getConsentInquiryDialog,
  showESimReadyToPair: eSimActions.showESimReadyToPairDialog,
  fetchESimReadyToPair: registryActions.fetchESimReadyToPair,
  fetchStuff: registryActions.fetchEECCTariffChangeInitialData,
  send,
  showBelatedMnpInProgressDialog: showBelatedMnpDialog,
};

export default compose(connect(makeStateToProps, mapDispatchToProps))(MyDashboard);
