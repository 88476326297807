import { push } from 'react-router-redux';
import {
  AVAILABILITY_ACCOUNT,
  AVAILABILITY_PRESALES,
  CONTEXT_ACTIVATION,
  CONTEXT_REGISTRATION,
  QUERY_EDIT,
  QUERY_IS_BOOKED,
} from './constants';
import { bindQueryParams } from './url';
import { ACCOUNT_USER_CUSTOMER_DATA, ACCOUNT_USER_PASSWORD } from '../actions/user/clear';
import { withdrawTariffCancellation } from '../actions/dialog/statusTariffActions';
import { fetchTariffs } from '../actions/request/registry';

/**
 * Goes to the user's tariff details page and returns the current tariff
 *
 * @todo Move this to actions folder
 *
 * @param {boolean} forceFetchTariffs
 * @return {AppEntity} the user's tariff
 */
export const goToBookedTariffDetailsPage = (forceFetchTariffs) => async (dispatch, getState) => {
  const { sitemap } = getState().site;
  const { bookedTariffs } = await dispatch(fetchTariffs({ isBlocking: true }), forceFetchTariffs);
  const bookedTariffVO = bookedTariffs[0];
  const url = bindQueryParams(sitemap.MyTariffDetailsRoute.url, {
    iid: bookedTariffVO.id,
    [QUERY_IS_BOOKED]: 1,
  });
  await dispatch(push(url));

  return bookedTariffVO;
};

/**
 * @todo Move this to actions folder
 */
export const contextActions = {
  belatedMnp: (dispatch, getState) => {
    const { sitemap } = getState().site;
    dispatch(push(sitemap.MyBelatedMNPFormRoute.url));
  },
  changePassword: (dispatch, getState) => {
    const { sitemap } = getState().site;
    dispatch(push(`${bindQueryParams(sitemap.MyDataRoute.url, { [QUERY_EDIT]: true })}#${ACCOUNT_USER_PASSWORD}`));
  },
  changeContactdata: (dispatch, getState) => {
    const { sitemap } = getState().site;
    dispatch(push(`${bindQueryParams(sitemap.MyDataRoute.url, { [QUERY_EDIT]: true })}#${ACCOUNT_USER_CUSTOMER_DATA}`));
  },
  withdrawCancellation: async (dispatch) => {
    const bookedTariffVO = await dispatch(goToBookedTariffDetailsPage(true));
    if (bookedTariffVO.canceled) {
      dispatch(withdrawTariffCancellation(bookedTariffVO.id));
    }
  },
  renewContract: (dispatch, getState) => {
    const { sitemap } = getState().site;
    const renewContractUrl = `${sitemap.MyDashboardRoute.url}?tariffMode=renewContract#MyOffersTariffs`;
    dispatch(push(renewContractUrl));
  },
  renewContractSimPlus: (dispatch, getState) => {
    const { sitemap } = getState().site;
    const renewContractUrl = `${sitemap.MyDashboardRoute.url}?tariffMode=renewContractSimPlus#MyOffersTariffs`;
    dispatch(push(renewContractUrl));
  },
  error: () => console.log('context action not defined'),
};

export const getContextAction = name => {
  if (contextActions[name]) {
    return contextActions[name];
  }
  return contextActions.error;
};

/**
 * Returns the realm (i.e. "presales" or "account") on the basis
 * of the provided context (see context constants).
 *
 * @param {string} context
 * @return {string}
 */
export const getRealmByContext = (context) => (
  [CONTEXT_REGISTRATION, CONTEXT_ACTIVATION].includes(context)
    ? AVAILABILITY_PRESALES
    : AVAILABILITY_ACCOUNT
);
