import React from 'react';
import PropTypes from 'prop-types';
import Link from '../../basics/text/TextLink';
import suitcss from '../../../helpers/suitcss';

const AccountLink = ({
  to,
  icon,
  element,
  onClick,
  notificationCount,
  isActive,
  children,
  loyaltyLevel,
  ariaLabel,
}) => (
  <span
    className={suitcss({
      componentName: 'AccountLink',
      utilities: [notificationCount > 0 && 'elementCountAfter'],
      modifiers: [loyaltyLevel > 0 && `loyaltyLevel${loyaltyLevel}`],
    })}
    data-count={notificationCount}
  >
    <Link
      to={to}
      onClick={onClick}
      element={element}
      icon={icon}
      isActive={isActive}
      withoutArrow
      ariaLabel={ariaLabel}
    >
      {children}
    </Link>
  </span>
);

AccountLink.propTypes = {
  to: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.string,
  element: PropTypes.string,
  notificationCount: PropTypes.number.isRequired,
  isActive: PropTypes.bool,
  children: PropTypes.node,
  loyaltyLevel: PropTypes.number,
  ariaLabel: PropTypes.string.isRequired,
};

export default AccountLink;
