import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import suitcss from '../../../helpers/suitcss';
import Copy from '../../basics/text/TextCopy';
import FieldTextarea from '../../basics/form/FieldTextarea';
import FieldRadioGroup from '../../basics/form/FieldRadioGroup';
import NpsFormField from './NpsFormField';
import FormSection from '../form/FormSection';

const componentName = 'FormWrapper';

class NpsForm extends PureComponent {

  renderField(item, index) {
    const { formValues } = this.props;
    const { name, rejectLabel, rejectChoices } = item;
    const value = formValues && formValues[name];
    return (
      <div className={suitcss({ descendantName: 'field' }, this)} key={index}>
        <NpsFormField item={item} position={index} />
        {!!value && value === 'Nein' && rejectChoices &&
          <div className={suitcss({ descendantName: 'reasons' }, this)}>
            <Copy utilities={['weightBold']} embedded raw>{rejectLabel}</Copy>
            <Field
              name={`${name}_reason`}
              options={rejectChoices}
              component={FieldRadioGroup}
              asToggleButtonGroup
              asStack
            />
            {formValues && formValues[`${name}_reason`] === 'Sonstiges' &&
              <div className={suitcss({ componentName, descendantName: 'formHalf' })}>
                <Field
                  name={`${name}_reason_text`}
                  component={FieldTextarea}
                />
              </div>
            }
          </div>
        }
      </div>
    );
  }

  render() {
    const { fieldMap, ui, params } = this.props;
    const { optionType } = params;
    return (
      <div className={suitcss({}, this)}>
        <FormSection>
          {Object.values(fieldMap).map(this.renderField, this)}
          {optionType === 'tnps' &&
            <div className={suitcss({ descendantName: 'field' }, this)}>
              <Copy size="secondary" utilities={['colorGray100']} embedded raw>{ui.txtGdprTnpsFormHint}</Copy>
            </div>
          }
        </FormSection>
      </div>
    );
  }
}

NpsForm.propTypes = {
  // form logic
  fieldMap: PropTypes.object.isRequired,
  formValues: PropTypes.object,
  ui: PropTypes.object,
  params: PropTypes.object,
};

export default NpsForm;
