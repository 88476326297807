/* global window */
import { parse, stringify } from 'querystring-es3';
import { sanitizeString } from './str';

/**
 * Iterates over a string and replaces all identifiers in format :identifier
 * with the value of its key in the params object
 * @param  {string} path   String with replaceable fragments
 * @param  {object} params Key values pares to replace the fragments
 * @return {string}        Generated string
 */
export function bindParamsToRoute(path, params) {
  const identifier = Object.keys(params);
  const assembleURL = identifier.map(key => {
    if (params[key] || typeof params[key] === 'string') {
      return new RegExp(`:${key}`, 'g');
    }
    // if the value is not a string but undefined we like to also remove
    // a trailing slash
    return new RegExp(`/?:${key}`, 'g');
  }).reduce((p, expression, index) => {
    // use an empty string as default if no value is given
    const value = params[identifier[index]] || '';
    return p.replace(expression, encodeURI(value));
  }, path);

  // remove trailing slash and return
  return assembleURL;
}

/**
 * removes empty keys from params and returns a clean path with query params if
 * existing
 * @param  {string} path
 * @param  {object} params
 * @return {string}
 */
export function bindQueryParams(path, params) {
  const cleanParams = {};
  Object.keys(params).forEach((key) => {
    if (params[key]) {
      cleanParams[key] = params[key];
    }
  });

  if (Object.keys(cleanParams).length) {
    const connector = path.includes('?') ? '&' : '?';
    return path + connector + stringify(cleanParams);
  }

  return path;
}

/**
 * Special Implementation for bindQueryParams which works with complex data like
 * Arrays in the param Object removes empty keys from params and returns a clean
 * path with query params if
 * existing
 * @param  {string} path
 * @param  {object} params
 * @return {string}
 */
export function bindQueryParamsWithComplexData(path, params) {
  const cleanParams = {};
  let arrayParams = '';

  Object.keys(params).forEach((key) => {
    if (params[key] && !Array.isArray(params[key])) {
      cleanParams[key] = params[key];
    } else if (params[key] && Array.isArray(params[key])) {
      arrayParams += '&';
      params[key].map((item, index) => { // eslint-disable-line
        arrayParams += `${key}[]=${item}${(params[key].length > 1 && index <= params[key].length - 2) ? '&' : ''}`;
      });
    }
  });

  if (Object.keys(cleanParams).length) {
    const connector = path.includes('?') ? '&' : '?';
    return path + connector + stringify(cleanParams) + arrayParams;
  }

  return path;
}

/**
 * decodes an uri until it cannot be decoded any further.
 * Example: %2528 becomes %28 in the first step and ( in the second step.
 * @param {string} url
 * @return {string} the fully decoded uri
 */
export function uriFullDecode(url) {
  const dec = decodeURI(sanitizeString(url));
  return dec === url ? dec : uriFullDecode(dec);
}

export function sanitizeUrlQueryParams(queryParams) {

  const result = {};

  Object.entries(queryParams).forEach((element) => {
    result[sanitizeString(element[0])] = sanitizeString(element[1], {});
  });

  return result;
}

export function sanitizeLocationParams(location, queryKey) {
  if (location && location.query && location.query[queryKey]) {
    return sanitizeString(location.query[queryKey]);
  }
  return null;
}

/**
 * Removes the domain from a url.
 * @param {string} url
 */
export const getUrlPath = (url) => (
  url.replace(/^.*\/\/[^\/]+/, '') // eslint-disable-line no-useless-escape
);

export const getLocationHost = () => window.location.host;
export const getLocationProtocol = () => window.location.protocol;
export const getAbsoluteUrl = (url) => (
  `${getLocationProtocol()}//${getLocationHost()}${url}`
);

export const locationToUrl = (location) => (`${location.pathname}${location.search}${location.hash}`);

/**
 * This functionality is usually done by react-router-redux. But it is needed in the reducer where
 * * the router is not initialized yet.
 */
export const getQueryParam = (name) => {
  const location = process.browser ? window.location.search.replace('?', '') : null;
  return parse(location)[name];
};

/**
 * Copy the relevant parts of a URL object into a new object and sanitize the values.
 * @param {URL} url - The URL object to copy from.
 * @returns {Object} - The copied and sanitized URL object.
 */
export const copyRequestURLObj = (url) => {
  // Copy the relevant parts of the URL object
  const location = {
    href: sanitizeString(url.href),
    origin: url.origin,
    protocol: url.protocol,
    username: url.username,
    password: url.password,
    host: url.host,
    hostname: url.hostname,
    port: url.port,
    pathname: url.pathname,
    search: url.search && sanitizeString(url.search),
    query: [...url.searchParams.entries()]
      .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {}),
    hash: url.hash,
  };

  // Sanitize the query parameters
  location.query = sanitizeUrlQueryParams(location.query || {});

  return location;
};
