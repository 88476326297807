import PropTypes from 'prop-types';

export const headlinePropTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
    PropTypes.array,
  ]).isRequired,
  element: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.element,
  ]),
  size: PropTypes.oneOf([
    'xxl',
    'xl',
    'l',
    'm',
    's',
    'xs',
    'xxs',
    'subline',
    'subheading',
  ]),
  embedded: PropTypes.bool,
  inverted: PropTypes.bool,
  highlight: PropTypes.bool,
  lowercase: PropTypes.bool,
  bold: PropTypes.bool,
  raw: PropTypes.bool,
  utilities: PropTypes.array,
};

export const headlineShape = PropTypes.shape(headlinePropTypes);

export const linkPropTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  icon: PropTypes.string,
  to: PropTypes.string,
  onClick: PropTypes.func,
  data: PropTypes.object, // @todo prop name too generic. rename into trackingData
  size: PropTypes.oneOf([
    'secondary',
    'small',
  ]),
  element: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.element,
  ]),
  color: PropTypes.oneOf([
    'dark',
    'light',
  ]),
  asButton: PropTypes.oneOfType([
    PropTypes.oneOf([
      'S',
      'SM',
      'M',
      'ML',
      'L',
    ]),
    PropTypes.bool,
  ]),
  withArrow: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  withoutLabel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  utilities: PropTypes.array,
  target: PropTypes.oneOf(['_blank', '_self']),
  isActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
  noHover: PropTypes.bool,
  ariaLabel: PropTypes.string,
};

export const linkShape = PropTypes.shape(linkPropTypes);
export const linkList = PropTypes.arrayOf(linkShape);

export const copyPropTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.array,
  ]).isRequired,
  element: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.element,
  ]),
  size: PropTypes.oneOf([
    'default',
    'secondary',
    'tertiary',
    'small',
  ]),
  postfix: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.array,
  ]),
  embedded: PropTypes.bool,
  inverted: PropTypes.bool,
  uppercase: PropTypes.bool,
  condensed: PropTypes.bool,
  raw: PropTypes.bool,
  utitilies: PropTypes.array,
};

export const copyShape = PropTypes.shape(copyPropTypes);
