/* global window */

/**
 * @see Ticket https://jira.db-n.com/browse/OT-3200 for
 * more information about bridge calls.
 */

export const APP_CONTEXT_CONTRACT_RENEWAL = 'contractRenewal';
export const CTA_MY_PROMO_DETAILS = 'ctaMyPromoDetails';
export const CTA_MY_PROMO_CONFIRM = 'ctaMyPromoConfirm';

let hasBridgeStarted = false;

export const getBrowserInfo = () => {

  if (process.browser) {
    const ua = window.navigator.userAgent;
    const userAgentIsIphone = ua.match(/iPhone/i);
    const userAgentIsIpad = ua.match(/iPad/i);

    if (userAgentIsIpad || userAgentIsIphone) {
      const userOS = 'iOS';
      const uaIndex = ua.indexOf('OS ');
      const isWebView = !ua.match(/safari/ig);
      const userOSver = uaIndex > -1 ? ua.substr(uaIndex + 3, 3).replace('_', '.') : null;
      return { type: userOS, version: userOSver, isWebView };
    }

    const userAgentIsAndroid = ua.match(/Android/i);
    if (userAgentIsAndroid) {
      const userOS = 'Android';
      const uaIndex = ua.indexOf('Android ');
      const isWebView = !!window.Android;
      const userOSver = uaIndex > -1 ? ua.substr(uaIndex + 8, 3) : null;
      return { type: userOS, version: userOSver, isWebView };
    }
  }

  return {
    type: 'unknown',
    version: 'unknown',
    isWebView: false,
  };
};

const dispatchToAndroid = (action, data) => {
  try {
    const dataStr = JSON.stringify(data);
    window.Android[action](dataStr);
  } catch (x) {
    console.log(x);
  }
};

const dispatchToIos = (action, data) => {
  try {
    window.webkit.messageHandlers.callbackHandler.postMessage({ action, data });
  } catch (x) {
    console.log(x);
  }
};

export const dispatchToWeb = (action, data) => {
  const detail = {
    action,
    data,
  };
  window.postMessage(detail, '*');
};

// dispatchToFallback is used to test the app connection with fake reponses
let store = null;
let oldNonce = null;
let oldToken = null;
const dispatchToFallback = async (action, data) => {
  if (store) {
    window.setTimeout(() => {
      const state = store.getState();
      switch (action) {
        case 'setNonce':
          oldNonce = data.nonce;
          break;
        case 'getCredentials': {
          // fake dispatch to web
          const user = state.user;
          const cred = state.user.credentials;
          oldNonce = cred.nonce;
          oldToken = cred.token;
          const data1 = {
            token: cred.token,
            refreshToken: cred.refreshToken,
            userType: user.market,
            msisdn: cred.msisdn,
            name: user.name,
            phoneNumber: user.phoneNumber,
            nonce: cred.nonce,
            scope: user.scope ? user.scope.join(' ') : '',
          };
          dispatchToWeb('getCredentialsSuccess', data1);
          break;
        }
        case 'reauth': {
          dispatchToWeb('reauthSuccess', {
            token: oldToken,
            nonce: oldNonce,
          });

          break;
        }
        case 'init':
          dispatchToWeb('initSuccess', {});
          break;
        default:
          console.log('no mock for ', action);
          break;
      }
    }, 1000);
  }
};

export const setupMocks = (store1) => {
  store = store1;
};

// only determine dispatcher function once
let dispatcher = null;

/*
 * Returns the platform-specific dispatcher function which calls the native app
 */
const getDispatchToApp = () => {
  if (!dispatcher) {
    const env = getBrowserInfo();
    if (env.isWebView && env.type === 'iOS') {
      dispatcher = dispatchToIos;
    } else if (env.isWebView && env.type === 'Android') {
      dispatcher = dispatchToAndroid;
    } else {
      dispatcher = dispatchToFallback;
    }
  }
  return dispatcher;
};

const dispatchToApp = (action, data) => {
  getDispatchToApp()(action, data);
};

export const hasBeenStarted = () => hasBridgeStarted;

export const start = () => { hasBridgeStarted = true; };

/**
 * Represents a "js => app => js" roundtrip as a promise.
 * @param {string} appAction - the action name in the native app
 * @param {string} appPayload - the params for the native app
 * @param {string} callbackAction - the action which the native app calls in response
 * @returns {object} the payload which is passed from the native app when callbackAction is triggerd
 */
const call = (appAction, appPayload, callbackAction) =>
  new Promise((resolve) => {
    const cb = (msg) => {
      const { action, data } = msg.data;
      if (action === callbackAction) {
        window.removeEventListener('message', cb, false);
        // console.log(JSON.stringify({ msg: 'onMessage', callbackAction, data }));
        resolve(data);
      }
    };
    window.addEventListener('message', cb, false);
    // console.log(JSON.stringify({ msg: 'call bridge', appAction, appPayload }));
    dispatchToApp(appAction, appPayload);
  });

/**
 * Sends the tracking data to the app, no response required
 * @param data
 */
export const sendTrackingData = (data) => dispatchToApp('trackingData', { data });

/**
 * Sends a trigger to the app to download EECC document
 * @param data
 */
export const sendDocumentDownload = (data) => call('eeccDocument', data, 'getCredentialsSuccess');

/**
 * Sends a trigger to the app to download EECC promotion document
 * @param data
 */
export const sendPromotionDocumentDownload = (data) => call('eeccPromotionDocument', data, 'getCredentialsSuccess');

/**
 * Sends a copied text to the app
 * @param data
 */
export const setCopyText = data => dispatchToApp('copyText', { data });

/**
 * Sends a nonce to the app, no response required.
 */
export const setNonce = nonce => dispatchToApp('setNonce', { nonce });

/**
 * Tells the app that the user requested to open the url
 */
export const openUrl = url => dispatchToApp('openUrl', { url });

/**
 * Tells the app to open the requested app deep link
 */
export const openAppDeepLink = url => dispatchToApp('openAppDeepLink', { url });

/**
 * Notificates the app that the contract renewal process is successfully finished.
 * @param {tariffId} the new tariff for the user.
 */
export const contractRenewalSuccess = () =>
  dispatchToApp('contractRenewalSuccess', {});


/**
 * Notifies the app that a button / cta has been clicked.
 * @param {type} cta type.
 * @param {data} optional data object.
 */
export const cta = (type, data) =>
  dispatchToApp('cta', { type, data });

/**
 * Initialize a bridge connection and returns a promise,
 * it's just a handshake to make sure the connection is working.
 * @returns {Promise.<{}>}
 */
export const init = () => call('init', {}, 'initSuccess');

/**
 * Calls the app and returns a promise which includes the credentials to login the user.
 * @returns {Promise.<{
      token:string,
      userType:string,
      msisdn:string,
      name:string,
      phoneNumber:string,
      nonce:string,
      refreshToken:string, // filled after app call
    }>}
 */
export const getCredentials = () =>
  call('getCredentials', {}, 'getCredentialsSuccess')
  // make sure we have a refresh token, even if it's invalid
  // (to avoid strange behaviour in some places)
    .then(x => ({ refreshToken: 'reauth-via-app', ...x }));

/**
 * Calls the app and reauthenticates the current user, returns a new valid jwt and a nonce.
 * @returns {Promise.<{
     token:string,
     nonce:string,
     refreshToken:string, // filled after app call
   }>}
 */
export const reauthenticate = () =>
  call('reauth', {}, 'reauthSuccess')
  // make sure we have a refresh token, even if it's invalid
  // (to avoid strange behaviour in some places)
    .then(x => ({ refreshToken: 'reauth-via-app', ...x }));
