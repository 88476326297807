import React from 'react';
import useScroll from 'react-router-scroll/lib/useScroll';

import GlobalPageSwitcher from '../containers/global/GlobalPageSwitcher';

/**
 * Function will ensure that the page just jumps to the top if the pathname changes but
 * will ignore updates to query string
 */
const shouldScrollTop = (prevRouterProps, { location }) => (
  prevRouterProps && location.pathname !== prevRouterProps.location.pathname
);

const scroller = useScroll(shouldScrollTop);

/**
 * The middleware will handle scroll position so that the page will jump to the top
 * of a new loaded page.
 * It will also ensure that the page will not jump immediately when the change location
 * action was fired but will wait until the page is loaded, then switch its content and
 * finally will update the scroll position.
 * This is not a redux middleware but a middleware optimized to work with react-router.
 */

export default {
  renderRouterContext: (child, props) => (
    <GlobalPageSwitcher {...props}>
      {scroller.renderRouterContext(child, props)}
    </GlobalPageSwitcher>
  ),
};
